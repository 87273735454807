import React, { Dispatch, SetStateAction, forwardRef, useState } from "react";
import {
  AppBar as MuiAppBar,
  Box,
  Drawer as MuiDrawer,
  Toolbar,
  AppBarProps,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  listItemButtonClasses,
} from "@mui/material";
import { CSSObject, Theme, styled } from "@mui/material/styles";

import { images } from "assets/images";
import { colors } from "theme/colors";
import theme from "theme/muiTheming";
import { ITemplate } from "models/templates";

import Header from "./Header";
import FillableFields from "./FillableFields";
import { IFillableFieldsT, ITagsT } from "..";

const drawerWidth = 400;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": { ...openedMixin(theme) },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface ITemplateDocxProps {
  templateData: ITemplate | null;
  children: React.ReactNode;
  setFillableFieldModal: Dispatch<SetStateAction<boolean>>;
  setEditFillableFieldData: Dispatch<SetStateAction<IFillableFieldsT | null>>;
  setTagsModal: Dispatch<SetStateAction<boolean>>;
  setEditTagData: Dispatch<SetStateAction<ITagsT | null>>;
  refetch: any;
  initialTemplateDocContent: string;
  feeCalculatorAdded: boolean;
  setFeeCalculatorAdded: Dispatch<SetStateAction<boolean>>;
}

const EditorFrame = forwardRef((props: ITemplateDocxProps, ref: any) => {
  const {
    templateData,
    setFillableFieldModal,
    setEditFillableFieldData,
    setTagsModal,
    setEditTagData,
    refetch,
    initialTemplateDocContent,
    feeCalculatorAdded,
    setFeeCalculatorAdded,
  } = props;
  const [fieldsDrawerOpen, setFieldsDrawerOpen] = useState(false);

  return (
    <Box sx={{ display: " flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="secondary"
        elevation={0}
        sx={{ borderBottom: `1px solid ${colors.borderColor}` }}
      >
        <Header
          ref={ref}
          templateData={templateData}
          refetch={refetch}
          initialTemplateDocContent={initialTemplateDocContent}
          feeCalculatorAdded={feeCalculatorAdded}
        />
      </AppBar>
      <Drawer variant="permanent" anchor="left" open={fieldsDrawerOpen}>
        <Toolbar />
        <Box
          sx={{
            minHeight: "calc(100% - 65px)",
            display: "flex",
            overflowY: "auto",
          }}
        >
          <List
            sx={{
              width: `calc(${theme.spacing(7)} + 1px)`,
              [theme.breakpoints.up("sm")]: {
                width: `calc(${theme.spacing(8)} + 1px)`,
              },
              height: "calc(100% - 65px)",
              ...(fieldsDrawerOpen && { borderRight: `1px solid #e0e0e0` }),
            }}
          >
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: fieldsDrawerOpen ? "initial" : "center",
                  px: 2.5,
                  [`&.${listItemButtonClasses.selected}`]: {
                    backgroundColor: colors.secondary,
                    borderLeft: `5px solid ${colors.primary}`,
                    borderRadius: 0,
                    ":hover": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
                selected={fieldsDrawerOpen}
                onClick={() => {
                  setFieldsDrawerOpen(!fieldsDrawerOpen);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: fieldsDrawerOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {fieldsDrawerOpen ? (
                    <img
                      src={images.CONTRACT_FILLABLE_FIELDS_SELECTED}
                      alt="icon"
                    />
                  ) : (
                    <img src={images.CONTRACT_FILLABLE_FIELDS} alt="icon" />
                  )}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
          {fieldsDrawerOpen && (
            <Box
              sx={{
                width: `calc(100% - (${theme.spacing(7)} + 1px))`,
                [theme.breakpoints.up("sm")]: {
                  width: `calc(100% - (${theme.spacing(8)} + 1px))`,
                },
                height: "100%",
                overflowY: "auto",
              }}
            >
              <FillableFields
                setFieldsDrawerOpen={setFieldsDrawerOpen}
                setFillableFieldModal={setFillableFieldModal}
                setEditFillableFieldData={setEditFillableFieldData}
                setTagsModal={setTagsModal}
                setEditTagData={setEditTagData}
                ref={ref}
                feeCalculatorAdded={feeCalculatorAdded}
                setFeeCalculatorAdded={setFeeCalculatorAdded}
              />
            </Box>
          )}
        </Box>
      </Drawer>
      <Box component="main" sx={{ width: "100%", overflowX: "auto" }}>
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
});

export default EditorFrame;
