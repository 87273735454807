import { colors } from "theme/colors";

export const ManageTemplatesStyles = {
  view_container: { display: "flex", justifyContent: "flex-end", mb: 1 },
  filter_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    px: 2.5,
    py: 3.5,
  },
  text_input: { fontSize: "13px", color: colors.primaryBlack },
  temp_item_container: {
    height: "310px",
    width: "215px",
    p: 2,
    position: "relative",
    cursor: "pointer",
  },
  temp_icon: { alignSelf: "center" },
  temp_data_container: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 20,
    width: "175px",
  },
  temp_name_text: { color: colors.primary, fontSize: "16px", fontWeight: 500 },
  temp_type_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  actions_menu: {
    "& .MuiMenu-paper": { border: `1px solid ${colors.borderColor}` },
  },
  actions_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  cell_text: {
    fontSize: "13px",
    color: colors.secondaryBlack,
    fontWeight: 500,
  },
  chip_variant: { fontWeight: 500, fontSize: "13px" },
  flex_JCsb_Acenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  create_temp_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  temp_pic_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    my: 3,
  },
  temp_avatar: {
    height: "100px",
    width: "100px",
    backgroundColor: "#DBEFFF",
    mx: 4,
  },
  field_label: {
    color: colors.grey,
    fontSize: "14px",
    fontWeight: 500,
    mb: 0.75,
  },
  error_text: { fontSize: "12px", color: "#D3010E" },
  table: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      backgroundColor: `${colors.primary}10`,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: colors.primaryBlack,
      fontSize: "16px",
      fontWeight: 500,
    },
    "& .MuiDataGrid-row:hover": {
      cursor: "pointer",
    },
  },
  disabled_btn: {
    "&.Mui-disabled": {
      backgroundColor: colors.grey,
      color: "#c0c0c0",
    },
  },
  modal_footer_container: { p: 2, backgroundColor: `${colors.primary}20` },
};
