import React, { useEffect, useState } from "react";
import { Box, Dialog } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";

import { IUpdateContactFieldsNew } from "models/contacts";
import {
	GET_CONTACT_BASIC_DETAILS,
	UPDATE_CONTACT_BASIC_DETAILS,
} from "graphql/contacts";
import { useStore } from "utils/store";

import StepperComponent from "./StepperComponent";
import { setContactDetails, initialValues as startValues } from "./utils";

export const steps = [
	"Basic Details",
	"Registration Details",
	"Financials",
	"Social Accounts",
];

interface IContactInfoModalProps {
	open: boolean;
	selectedContact: string;
	handleClose: () => void;
}

const ContactInfoModal = (props: IContactInfoModalProps) => {
	const { open, handleClose, selectedContact } = props;

	const { setLoading } = useStore();

	const [activeStep, setActiveStep] = useState(0);
	const [initialValues, setInitialValues] =
		useState<IUpdateContactFieldsNew>(startValues);

	const { loading, refetch } = useQuery(GET_CONTACT_BASIC_DETAILS, {
		variables: { id: selectedContact },
		skip: !selectedContact,
		onCompleted: (data) => {
			const { getContactBasicDetails } = data;
			const { status, contactDetails } = getContactBasicDetails;
			if (status) {
				setContactDetails(contactDetails, setInitialValues);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const handleDialogClose = (_: any, reason: string) => {
		if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
			handleClose();
		}
	};

	const [updateContactApi, { loading: updating }] = useMutation(
		UPDATE_CONTACT_BASIC_DETAILS,
		{}
	);

	useEffect(() => {
		setLoading(loading || updating);
	}, [loading, setLoading, updating]);

	return (
		<Dialog
			open={open}
			onClose={handleDialogClose}
			maxWidth="md"
			fullWidth
			PaperProps={{
				sx: {
					height: { xs: 600, sm: 750 },
					borderRadius: "24px",
					// overflow: "hidden"
				},
			}}
		>
			<Box
				sx={{
					height: "100%",
					overflow: "auto",
				}}
			>
				<StepperComponent
					handleClose={handleClose}
					selectedContact={selectedContact}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					initialValues={initialValues}
					updateContactApi={updateContactApi}
					refetch={refetch}
				/>
			</Box>
		</Dialog>
	);
};

export default ContactInfoModal;
