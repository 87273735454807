import { colors } from "theme/colors";

export const SettingsStyles = {
  tab_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.secondaryBlack,
    "&.Mui-selected": {
      fontWeight: 600,
    },
  },
  contentMargin: {
    ml: { xs: 0, sm: 5, md: 7, lg: 10 },
    mr: { xs: 0, sm: 5, md: 7, lg: 10 },
  },
  flex_JCcenter_Acenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  my3: { my: 3 },
  profile_pic_avatar: { width: "100px", height: "100px", mx: 4 },
  field_label: {
    color: colors.grey,
    fontSize: "14px",
    fontWeight: 500,
    mb: 0.75,
  },
  input_field: { fontSize: "14px", color: colors.primaryBlack },
  radio_btn_label: {
    fontSize: "16px",
    color: colors.primaryBlack,
    fontWeight: 500,
  },
  notification_header: {
    fontSize: "16px",
    color: colors.primaryBlack,
    fontWeight: 500,
    mt: 3,
  },
  notification_text: {
    fontSize: "14px",
    color: colors.secondaryBlack,
    fontWeight: 500,
  },
  recent_act_text: {
    fontSize: "16px",
    color: colors.primaryBlack,
    fontWeight: 500,
  },
  activity_header_text: {
    fontSize: "13px",
    color: colors.primaryBlack,
    fontWeight: 500,
    mb: 2,
  },
  timeline_container: {
    pl: 2,
    pb: 4,
    position: "relative",
    ml: 2,
  },
  timeline_dot: {
    position: "absolute",
    left: -13,
    top: -5,
  },
  flex_JCsb_Afe: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  flex: { display: "flex" },
  timeline_avatar: { width: "36px", height: "36px", mr: 1 },
  timeline_name_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.primary,
  },
  timeline_activity_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: colors.whiteGrey500,
  },
  timeline_time_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: colors.whiteGrey500,
  },
  error_text: { fontSize: "12px", color: "#D3010E" },
};
