import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { images } from "assets/images";
import theme from "theme/muiTheming";
import { colors } from "theme/colors";

const styles = {
  content_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  full_screen: { width: "100vw", height: "100vh" },
  ni_text: { color: colors.primaryBlack, fontSize: "40px", fontWeight: 500 },
  ni_desc: { color: colors.grey, fontSize: "20px", textAlign: "center" },
};

export const NoInternetPage = () => {
  const matchesXS = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box sx={[styles.content_center, styles.full_screen]}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <img
            src={images.NO_INTERNET}
            alt="no internet"
            width={matchesXS ? "" : "100%"}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <Typography sx={styles.ni_text}>No internet</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <Typography sx={styles.ni_desc}>
            Please check your network connection
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
