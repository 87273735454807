import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  FormatListBulletedOutlined,
  GridViewOutlined,
  MoreVert,
} from "@mui/icons-material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";

import { IPaginationModel } from "models/common";
import Drawer from "common/AdminDrawer";
import { GlobalLabels } from "common/AppConstants";
import Pagination from "common/Pagination";
import Table from "common/Table";
import { images } from "assets/images";
import { ComplexityMapping, TemplateStatusMapping } from "utils/constants";
import { DOWNLOAD_TEMPLATE_AS_PDF, GET_ALL_TEMPLATES } from "graphql/templates";
import { useStore } from "utils/store";
import { ITemplate } from "models/templates";
import NoDataView from "common/NoDataView";
import NoResultsFoundView from "common/NoResultsFoundView";
import { RouteNames } from "routes/routeNames";

import Filters from "./Filters";
import DeleteTemplateModal from "./DeleteTempModal";
import AddEditTemplateModal from "./AddEditTemplateModal";
import { ManageTemplatesStyles as styles } from "./styles";

interface ITemplateCardProps {
  templateData: ITemplate;
  setDeleteModal: Dispatch<SetStateAction<boolean>>;
  setSelectedTemplate: Dispatch<SetStateAction<string>>;
  setAddEditTemplate: Dispatch<SetStateAction<boolean>>;
  setSelectedTemplateName: Dispatch<SetStateAction<string>>;
  fireDownloadTemplateApi: any;
}

const TemplateCard = (props: ITemplateCardProps) => {
  const {
    templateData,
    setSelectedTemplate,
    setAddEditTemplate,
    setDeleteModal,
    setSelectedTemplateName,
    fireDownloadTemplateApi,
  } = props;

  const navigate = useNavigate();

  const [openActions, setOpenActions] = useState(false);
  const [actionsAnchor, setActionsAnchor] = useState<HTMLElement | null>(null);

  const handleActionsOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenActions(true);
    setActionsAnchor(e.currentTarget);
  };

  const handleActionsClose = () => {
    setOpenActions(false);
    setActionsAnchor(null);
  };

  return (
    <Paper
      sx={styles.temp_item_container}
      onClick={() => {
        navigate(`${RouteNames.TEMPLATE}/${templateData.id}`);
      }}
    >
      <Grid container spacing={1.5}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <img
            src={images.TEMPLATE_GRID_ICON}
            alt="template"
            style={styles.temp_icon}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={styles.flex_JCsb_Acenter}>
            <Box sx={{ width: "150px" }}>
              <Tooltip title={templateData?.name}>
                <Typography sx={styles.temp_name_text} noWrap>
                  {templateData?.name}
                </Typography>
              </Tooltip>
            </Box>
            <Box>
              <IconButton
                aria-controls={openActions ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openActions ? "true" : undefined}
                onClick={handleActionsOpen}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={actionsAnchor}
                open={openActions}
                onClose={handleActionsClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={styles.actions_menu}
                elevation={0}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTemplate(templateData.id);
                    setAddEditTemplate(true);
                    handleActionsClose();
                  }}
                  sx={styles.actions_text}
                >
                  {GlobalLabels.EDIT}
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTemplateName(templateData?.name!);
                    fireDownloadTemplateApi({
                      variables: { id: templateData?.id },
                    });
                    handleActionsClose();
                  }}
                  sx={styles.actions_text}
                >
                  {GlobalLabels.EXPORT}
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedTemplate(templateData?.id);
                    setSelectedTemplateName(templateData?.name!);
                    setDeleteModal(true);
                    handleActionsClose();
                  }}
                  sx={styles.actions_text}
                >
                  {GlobalLabels.DELETE}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Typography sx={styles.temp_type_text}>
            {templateData?.templateType?.name}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const ManageTemplates = () => {
  const { setLoading } = useStore();
  const navigate = useNavigate();

  const [view, setView] = useState(0);
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [templateCount, setTemplateCount] = useState(0);
  const [paginationModelGrid, setPaginationModelGrid] =
    useState<IPaginationModel>({
      page: 0,
      pageSize: 100,
    });

  const [paginationModelTable, setPaginationModelTable] =
    useState<IPaginationModel>({
      page: 0,
      pageSize: 100,
    });
  const [searchText, setSearchText] = useState("");
  const [addEditTemplate, setAddEditTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedTemplateName, setSelectedTemplateName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [searchTextTempType, setSearchTextTempType] = useState("");
  const [searchTextFnkId, setSearchTextFnkId] = useState("");

  const { loading, refetch } = useQuery(GET_ALL_TEMPLATES, {
    variables: {
      page:
        view === 1
          ? paginationModelGrid.page + 1
          : paginationModelTable.page + 1,
      limit:
        view === 1
          ? paginationModelGrid.pageSize
          : paginationModelTable.pageSize,
      filter: {
        ...(searchText && { name__icontains: searchText }),
        ...(searchTextTempType && {
          template_type__name__icontains: searchTextTempType,
        }),
        ...(searchTextFnkId && {
          templateId__icontains: searchTextFnkId,
        }),
      },
      ...(Boolean(sortBy) && { sorted: sortBy }),
    },
    onCompleted: (data) => {
      setLoading(false);
      const { getAllContractTemplates } = data;
      const { status, count, contractTemplates } = getAllContractTemplates;
      if (status) {
        setTemplateCount(count);
        setTemplates(contractTemplates);
      } else {
        setTemplateCount(0);
        setTemplates([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireDownloadTemplateApi, { loading: downloading }] = useLazyQuery(
    DOWNLOAD_TEMPLATE_AS_PDF,
    {
      onCompleted: (data) => {
        const { getContractTemplateAsHTML } = data;
        const { status, contractTemplate, message } = getContractTemplateAsHTML;
        if (status) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href = `data:application/pdf;base64,${contractTemplate}`;
          hiddenElement.target = "_blank";
          hiddenElement.download = `${selectedTemplateName}.pdf`;
          hiddenElement.click();
        } else {
          toast.error(message, { delay: 10 });
        }
        setSelectedTemplateName("");
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "templateId",
        headerName: "Finka ID",
        sortable: false,
        minWidth: 220,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "name",
        headerName: "Template Name",
        sortable: false,
        minWidth: 220,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "templateType",
        headerName: "Template Type",
        sortable: false,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value?.name}</Typography>
        ),
      },
      {
        field: "description",
        headerName: "Description",
        sortable: false,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography
            sx={[styles.cell_text, { width: "100%", wordBreak: "break-word" }]}
          >
            {params?.value}
          </Typography>
        ),
      },
      {
        field: "createdDate",
        headerName: "Created Date",
        headerAlign: "center",
        sortable: false,
        minWidth: 155,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {Boolean(params?.value)
              ? moment(params?.value).format("DD MMMM YYYY")
              : ""}
          </Typography>
        ),
      },
      {
        field: "modifiedDate",
        headerName: "Modified Date",
        headerAlign: "center",
        sortable: false,
        minWidth: 160,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {Boolean(params?.value)
              ? moment(params?.value).format("DD MMMM YYYY")
              : ""}
          </Typography>
        ),
      },
      {
        field: "additionalDetails",
        headerName: "Complexity",
        headerAlign: "center",
        sortable: false,
        minWidth: 135,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => {
          let complexity = "3";
          const complexityJSONStr = params?.value;
          const parsedJSON = Boolean(complexityJSONStr)
            ? JSON.parse(complexityJSONStr)
            : null;
          if (Boolean(parsedJSON)) {
            complexity = parsedJSON?.complexity;
          }
          return (
            <img
              src={(ComplexityMapping as any)[complexity].flag}
              alt="notes"
            />
          );
        },
      },
      {
        field: "templateStatus",
        headerName: "Template Status",
        headerAlign: "center",
        sortable: false,
        minWidth: 175,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Chip
            label={(TemplateStatusMapping as any)[params?.value].name}
            sx={[
              styles.chip_variant,
              {
                color: (TemplateStatusMapping as any)[params?.value].color,
                backgroundColor: (TemplateStatusMapping as any)[params?.value]
                  .bg,
              },
            ]}
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        sortable: false,
        minWidth: 110,
        flex: 1,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            showInMenu
            label="Edit"
            onClick={() => {
              setAddEditTemplate(true);
              setSelectedTemplate(params.row.id);
            }}
            sx={styles.actions_text}
          />,
          <GridActionsCellItem
            showInMenu
            label="Export"
            onClick={() => {
              setSelectedTemplateName(params.row.name);
              fireDownloadTemplateApi({ variables: { id: params.row.id } });
            }}
            sx={styles.actions_text}
          />,
          <GridActionsCellItem
            showInMenu
            label="Delete"
            onClick={() => {
              setDeleteModal(true);
              setSelectedTemplate(params.row.id);
              setSelectedTemplateName(params.row.name);
            }}
            sx={styles.actions_text}
          />,
        ],
      },
    ],
    [fireDownloadTemplateApi]
  );

  useEffect(() => {
    setPaginationModelGrid({ page: 0, pageSize: 100 });
    setPaginationModelTable({ page: 0, pageSize: 100 });
    setSearchText("");
    setSortBy("");
  }, [view]);

  useEffect(() => {
    setLoading(downloading);
  }, [downloading, setLoading]);

  return (
    <Drawer>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={styles.view_container}>
            <IconButton onClick={() => setView(0)}>
              <FormatListBulletedOutlined
                color={view === 0 ? "primary" : "inherit"}
              />
            </IconButton>
            <IconButton onClick={() => setView(1)}>
              <GridViewOutlined color={view === 1 ? "primary" : "inherit"} />
            </IconButton>
          </Box>
          <Box sx={styles.filter_container}>
            <Filters
              setAddEditTemplate={setAddEditTemplate}
              setSearchText={setSearchText}
              setSearchTextTempType={setSearchTextTempType}
              setSearchTextFnkId={setSearchTextFnkId}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          </Box>
        </Grid>
        {!loading && searchText === "" && templateCount === 0 && (
          <NoDataView
            title="No Templates found"
            subtitle="Please add a Template"
          />
        )}
        {!loading && searchText && templateCount === 0 && (
          <NoResultsFoundView />
        )}
        {!loading &&
          templateCount > 0 &&
          view === 1 &&
          templates.map((row) => (
            <Grid key={row.id} item>
              <TemplateCard
                templateData={row}
                setDeleteModal={setDeleteModal}
                setAddEditTemplate={setAddEditTemplate}
                setSelectedTemplate={setSelectedTemplate}
                fireDownloadTemplateApi={fireDownloadTemplateApi}
                setSelectedTemplateName={setSelectedTemplateName}
              />
            </Grid>
          ))}
        {!loading && templateCount > 0 && view === 1 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Pagination
              page={paginationModelGrid.page}
              pageSize={paginationModelGrid.pageSize}
              rowCount={templateCount}
              onPaginationModelChange={setPaginationModelGrid}
            />
          </Grid>
        )}
        {!loading && templateCount > 0 && view === 0 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              rows={templates}
              columns={columns}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              disableColumnMenu
              rowCount={templateCount}
              paginationModel={paginationModelTable}
              onPaginationModelChange={setPaginationModelTable}
              onRowClick={(params: GridRowParams, _, __) =>
                navigate(`${RouteNames.TEMPLATE}/${params.row.id}`)
              }
              sx={styles.table}
            />
          </Grid>
        )}
      </Grid>
      {addEditTemplate && (
        <AddEditTemplateModal
          open={addEditTemplate}
          setOpen={setAddEditTemplate}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          refetchTemplates={refetch}
        />
      )}
      {deleteModal && (
        <DeleteTemplateModal
          open={deleteModal}
          setOpen={setDeleteModal}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplateName={selectedTemplateName}
          setSelectedTemplateName={setSelectedTemplateName}
          refetchTableData={refetch}
        />
      )}
    </Drawer>
  );
};
