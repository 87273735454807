import React from "react";

import ProfilePage from "./Profile";
import Settings from "..";

export function Profile() {
  return (
    <Settings>
      <ProfilePage />
    </Settings>
  );
}
