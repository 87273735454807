import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	Avatar,
	Box,
	Grid,
	IconButton,
	Menu,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import {
	FilterAltOutlined,
	RadioButtonChecked,
	RadioButtonUnchecked,
	Share,
} from "@mui/icons-material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import Table from "common/Table";
import { useStore } from "utils/store";
import { IContact } from "models/contacts";
import { EXPORT_CONTACT_REPORT, GET_CONTACT_REPORT } from "graphql/reports";
import { BlackActionContainedButton } from "common/Buttons";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import { ReportStyles as styles } from "./styles";
import { CountryCodeArr } from "utils/constants";

function ContactReport() {
	const { setLoading } = useStore();
	const boxRef = useRef(null);
	const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
	const reportsIndex = useMemo(
		() =>
			ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
				x.hasOwnProperty("REPORTS")
			),
		[ROLE_PERMISSIONS]
	);

	const [contacts, setContacts] = useState<IContact[]>([]);
	const [contactRowCount, setContactRowCount] = useState(0);
	const [sortBy, setSortBy] = useState("full_name");
	const [openFilterMenu, setOpenFilterMenu] = useState(false);
	const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
		null
	);

	const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
		setFilterMenuAnchor(e.currentTarget);
		setOpenFilterMenu(true);
	};

	const handleFilterMenuClose = () => {
		setFilterMenuAnchor(null);
		setOpenFilterMenu(false);
	};

	const { loading, fetchMore } = useQuery(GET_CONTACT_REPORT, {
		variables: {
			limit: 50,
			page: 1,
			filter: {},
			...(Boolean(sortBy) && { sorted: sortBy }),
		},
		skip: !(
			Boolean(ROLE_PERMISSIONS) &&
			reportsIndex !== undefined &&
			ROLE_PERMISSIONS[0]?.permissions[reportsIndex]?.REPORTS
		),
		onCompleted: (data) => {
			setLoading(false);
			const { getAllContacts } = data;
			const { status, count, contacts: rawContacts } = getAllContacts;
			if (status) {
				setContactRowCount(count);
				setContacts(rawContacts);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const [getReport, { loading: exporting }] = useLazyQuery(
		EXPORT_CONTACT_REPORT,
		{
			onCompleted: (data) => {
				setLoading(false);
				const { getAllContactsReport } = data;
				if (Boolean(getAllContactsReport?.report)) {
					var hiddenElement = document.createElement("a");
					hiddenElement.href =
						"data:application/vnd.ms-excel;base64," +
						encodeURI(getAllContactsReport?.report);
					hiddenElement.target = "_blank";
					hiddenElement.download = `Contacts.xlsx`;
					hiddenElement.click();
				} else {
					toast.error("Something went wrong, please try again.", {
						delay: 10,
					});
				}
			},
			fetchPolicy: "network-only",
			nextFetchPolicy: "network-only",
		}
	);

	const columns: GridColDef[] = useMemo(
		() => [
			{
				field: "fullName",
				headerName: "Name",
				sortable: false,
				minWidth: 200,
				flex: 1,
				renderCell: (params: GridRenderCellParams<any>) => (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							width: "100%",
							wordBreak: "break-word",
						}}
					>
						{params?.row?.profile_picture ? (
							<Avatar src={params?.row?.profile_picture} />
						) : (
							<Avatar />
						)}
						<Typography sx={[styles.cell_text, { ml: 1 }]}>
							{params?.value}
						</Typography>
					</Box>
				),
			},
			{
				field: "contactDepartment",
				headerName: "Department",
				sortable: false,
				minWidth: 200,
				flex: 1,
				renderCell: (params: GridRenderCellParams<any>) => (
					<Typography sx={[styles.cell_text, { fontWeight: 600 }]}>
						{params?.value?.name || "TBA"}
					</Typography>
				),
			},
			{
				field: "profile",
				headerName: "Role",
				sortable: false,
				minWidth: 200,
				flex: 1,
				renderCell: (params: GridRenderCellParams<any>) => {
					let profileJSON = Boolean(params?.value)
						? JSON.parse(params?.value)
						: null;
					return (
						<Typography sx={styles.cell_text}>
							{Boolean(profileJSON)
								? profileJSON?.project_role || "TBA"
								: "TBA"}
						</Typography>
					);
				},
			},
			{
				field: "assignedProject",
				headerName: "Project",
				sortable: false,
				minWidth: 200,
				flex: 1,
				renderCell: (params: GridRenderCellParams<any>) => {
					return (
						<Typography sx={styles.cell_text}>
							{params?.value?.name || ""}
						</Typography>
					);
				},
			},
			{
				field: "emailId",
				headerName: "Email",
				sortable: false,
				minWidth: 330,
				flex: 1,
				renderCell: (params: GridRenderCellParams<any>) => (
					<Typography sx={styles.cell_text}>{params?.value}</Typography>
				),
			},
			{
				field: "mobile",
				headerName: "Phone",
				sortable: false,
				minWidth: 200,
				flex: 1,
				renderCell: (params: GridRenderCellParams<any>) => (
					<Typography sx={styles.cell_text}>
						{Boolean(params?.value) &&
						Boolean(params?.value?.nationalNumber)
							? `${
									CountryCodeArr.find(
										(x) => x.id === params?.value?.countryCode
									)?.name || ""
							  } ${params?.value?.nationalNumber || ""}`
							: Boolean(params?.value?.rawInput)
							? `+61 ${params?.value?.rawInput}`
							: ""}
					</Typography>
				),
			},
		],
		[]
	);

	useEffect(() => {
		const handleScroll = async () => {
			// Check if the user has scrolled to the bottom of the page with a tolerance
			const box = boxRef.current;
			const isAtBottom =
				(box as any).scrollHeight - (box as any).scrollTop <=
				(box as any).clientHeight + 100;

			if (!isAtBottom) {
				// If not at the bottom, return early
				return;
			}

			if (contacts.length < contactRowCount) {
				try {
					setLoading(true);
					await fetchMore({
						variables: {
							page: Math.ceil(contacts.length / 50) + 1,
							limit: 50,
						},
						updateQuery: (previousResult, { fetchMoreResult }) => {
							const newEntries = fetchMoreResult.getAllContacts.contacts;
							return {
								getAllContacts: {
									...fetchMoreResult?.getAllContacts,
									contacts: [...contacts, ...newEntries],
								},
							};
						},
					});
				} catch (error) {
					console.error("ERROR", error);
				} finally {
					setLoading(false);
				}
			}
		};

		// Add scroll event listener
		const box = boxRef.current;
		if (box) {
			(box as any).addEventListener("scroll", handleScroll);
		}

		// Clean up event listener on component unmount
		return () => {
			if (box) {
				(box as any).removeEventListener("scroll", handleScroll);
			}
		};
	}, [fetchMore, setLoading, contacts, contactRowCount]);

	useEffect(() => {
		setLoading(exporting || loading);
	}, [exporting, loading, setLoading]);

	return (
		<>
			<Box
				sx={{
					p: 1,
					display: { xs: "flex", sm: "none" },
					mb: 1,
				}}
			>
				<Box
					sx={[
						styles.flex_Acenter,
						{ justifyContent: "flex-end", width: "100%" },
					]}
				>
					<Box>
						<BlackActionContainedButton
							variant="contained"
							disableElevation
							startIcon={<Share color="secondary" />}
							onClick={() =>
								getReport({
									variables: {
										filter: {},
										...(Boolean(sortBy) && { sorted: sortBy }),
									},
								})
							}
							sx={{
								py: 1,
								px: 2,
								fontSize: "14px",
								borderRadius: "8px",
							}}
						>
							Export
						</BlackActionContainedButton>
					</Box>
					<Box sx={{ ml: 2 }}>
						<IconButton
							onClick={handleFilterMenuOpen}
							size="small"
							sx={{ ":hover": { backgroundColor: "#41B19931" } }}
						>
							<FilterAltOutlined
								fontSize="small"
								sx={{
									color: openFilterMenu
										? "#41B199"
										: `${colors.primaryBlack}50`,
								}}
							/>
						</IconButton>
						<Menu
							anchorEl={filterMenuAnchor}
							open={openFilterMenu}
							onClose={handleFilterMenuClose}
							elevation={1}
							sx={{ "& .MuiMenu-paper": { width: 220 } }}
						>
							<Grid container spacing={2} sx={{ p: 2 }}>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<Typography
										sx={{
											fontSize: "14px",
											color: "#00000080",
											fontWeight: 600,
										}}
									>
										Sort By
									</Typography>
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<RadioGroup
										value={sortBy}
										onChange={(e) => {
											setSortBy(e.target.value);
										}}
									>
										<Box
											sx={{ display: "flex", alignItems: "center" }}
										>
											<Radio
												value="full_name"
												size="small"
												checkedIcon={
													<RadioButtonChecked
														fontSize="small"
														sx={{ color: "#6f6f6f" }}
													/>
												}
												icon={
													<RadioButtonUnchecked
														fontSize="small"
														sx={{ color: "#6f6f6f" }}
													/>
												}
											/>
											<Typography
												sx={{
													color: "#00000070",
													fontSize: "14px",
												}}
											>
												Name (A-Z)
											</Typography>
										</Box>
										<Box
											sx={{ display: "flex", alignItems: "center" }}
										>
											<Radio
												value="-full_name"
												size="small"
												checkedIcon={
													<RadioButtonChecked
														fontSize="small"
														sx={{ color: "#6f6f6f" }}
													/>
												}
												icon={
													<RadioButtonUnchecked
														fontSize="small"
														sx={{ color: "#6f6f6f" }}
													/>
												}
											/>
											<Typography
												sx={{
													color: "#00000070",
													fontSize: "14px",
												}}
											>
												Name (Z-A)
											</Typography>
										</Box>
									</RadioGroup>
								</Grid>
							</Grid>
						</Menu>
					</Box>
				</Box>
			</Box>
			<Box
				ref={boxRef}
				sx={{
					height: "calc(100vh - 170px)",
					overflow: "auto",
					mx: -3,
					mb: -3,
				}}
			>
				<Table
					rows={contacts}
					columns={columns}
					showCellVerticalBorder={false}
					showColumnVerticalBorder={false}
					disableColumnMenu
					sx={{
						...styles.table,
						"& .MuiDataGrid-columnHeaders": {
							border: "none",
							backgroundColor: "#B6BCC320",
						},
						"& .MuiDataGrid-row": {
							borderBottom: "1px #e6e6e6 solid",
							borderRadius: 0,
						},
						"& .MuiDataGrid-row:hover": {
							borderBottom: "transparent",
						},
						borderTop: "1px #e6e6e6 solid",
						borderRadius: 0,
					}}
					hideFooter
				/>
				<Box
					sx={{
						position: "absolute",
						top: { xs: 160, sm: 123, md: 105 },
						right: 30,
					}}
				>
					<Box
						sx={[
							styles.flex_Acenter,
							{ display: { xs: "none", sm: "flex" } },
						]}
					>
						<Box>
							<BlackActionContainedButton
								variant="contained"
								disableElevation
								startIcon={<Share color="secondary" />}
								onClick={() =>
									getReport({
										variables: {
											filter: {},
											...(Boolean(sortBy) && { sorted: sortBy }),
										},
									})
								}
								sx={{
									py: 1,
									px: 2,
									fontSize: "14px",
									borderRadius: "8px",
								}}
							>
								Export
							</BlackActionContainedButton>
						</Box>
						<Box sx={{ ml: 2 }}>
							<IconButton
								onClick={handleFilterMenuOpen}
								size="small"
								sx={{ ":hover": { backgroundColor: "#41B19931" } }}
							>
								<FilterAltOutlined
									fontSize="small"
									sx={{
										color: openFilterMenu
											? "#41B199"
											: `${colors.primaryBlack}50`,
									}}
								/>
							</IconButton>
							<Menu
								anchorEl={filterMenuAnchor}
								open={openFilterMenu}
								onClose={handleFilterMenuClose}
								elevation={1}
								sx={{ "& .MuiMenu-paper": { width: 220 } }}
							>
								<Grid container spacing={2} sx={{ p: 2 }}>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography
											sx={{
												fontSize: "14px",
												color: "#00000080",
												fontWeight: 600,
											}}
										>
											Sort By
										</Typography>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<RadioGroup
											value={sortBy}
											onChange={(e) => {
												setSortBy(e.target.value);
											}}
										>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
												}}
											>
												<Radio
													value="full_name"
													size="small"
													checkedIcon={
														<RadioButtonChecked
															fontSize="small"
															sx={{ color: "#6f6f6f" }}
														/>
													}
													icon={
														<RadioButtonUnchecked
															fontSize="small"
															sx={{ color: "#6f6f6f" }}
														/>
													}
												/>
												<Typography
													sx={{
														color: "#00000070",
														fontSize: "14px",
													}}
												>
													Name (A-Z)
												</Typography>
											</Box>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
												}}
											>
												<Radio
													value="-full_name"
													size="small"
													checkedIcon={
														<RadioButtonChecked
															fontSize="small"
															sx={{ color: "#6f6f6f" }}
														/>
													}
													icon={
														<RadioButtonUnchecked
															fontSize="small"
															sx={{ color: "#6f6f6f" }}
														/>
													}
												/>
												<Typography
													sx={{
														color: "#00000070",
														fontSize: "14px",
													}}
												>
													Name (Z-A)
												</Typography>
											</Box>
										</RadioGroup>
									</Grid>
								</Grid>
							</Menu>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
}

export default ContactReport;
