import { colors } from "theme/colors";

export const TwoFAStyles = {
  width: { width: { xs: "100%", md: "540px" } },
  login_signup_bg: { width: "100%", height: "100vh" },
  two_fa_container: {
    height: "100%",
    width: "100%",
  },
  content_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  stepper_container: { mt: 5 },
  stepper_content: { mt: 3 },
  inst_text: {
    color: colors.grey,
    fontSize: "20px",
    width: "300px",
    textAlign: "center",
  },
  code_text: {
    color: colors.grey,
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "center",
  },
  btns_container: {
    mt: 3,
    width: { xs: "250px", md: "540px" },
    display: " flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  otp_container: { marginTop: 5, marginBottom: 5 },
  otp_input: {
    fontSize: "44px",
    color: colors.primaryBlack,
    fontWeight: 600,
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    padding: 10,
    marginLeft: 8,
    marginRight: 8,
    width: 52,
  },
  change_device_container: {
    width: "390px",
    display: "flex",
    justifyContent: "flex-end",
  },
  link_text: {
    color: "#00000050",
    fontSize: "13px",
    textDecoration: "underline",
  },
};
