import { colors } from "theme/colors";

export const ProjectStyles = {
  view_container: {
    display: "flex",
    justifyContent: "flex-end",
    mb: 0.5,
  },
  filter_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    px: 2.5,
    py: 3.5,
  },
  pagination_container: { display: "flex", alignItems: "center" },
  show_text: { fontSize: "16px", color: colors.primaryBlack, mr: 1 },
  text_input: { fontSize: "14px", color: colors.primaryBlack },
  add_project_container: {
    border: `1px solid ${colors.borderColor}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "314px",
    width: "213px",
  },
  add_project_link: {
    color: colors.primary,
    fontSize: "16px",
    fontWeight: 500,
  },
  project_item_container: { cursor: "pointer", my: 1 },
  reel_icon_container: { display: "flex", justifyContent: "center" },
  project_metadata_container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    mt: 3,
  },
  project_title_text: {
    color: colors.primary,
    fontSize: "16px",
    fontWeight: 500,
  },
  project_metadata_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  project_footer_container: { position: "absolute", bottom: 20 },
  progress_bar_container: { width: "240px", mb: 1.5 },
  progress_bar: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#dedede",
  },
  progress_bar_text_container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  progress_bar_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
  },
  avatars_container: { display: "flex", justifyContent: "flex-start" },
  avatar: {
    "& .MuiAvatar-root": {
      width: "32px",
      height: "32px",
      backgroundColor: "transparent",
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      mr: 0.35,
      fontSize: "11px",
    },
  },
  action_text: {
    color: colors.secondaryBlack,
    fontSize: "14px",
  },
  actions_menu: {
    "& .MuiMenu-paper": { border: `1px solid ${colors.borderColor}` },
  },
  project_pic_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    my: 3,
  },
  reel_avatar: {
    height: "100px",
    width: "100px",
    backgroundColor: "#F5F6FF",
    mx: 4,
  },
  field_label: {
    color: `${colors.secondaryBlack}70`,
    fontSize: "13px",
    fontWeight: 600,
    mb: 0.5,
  },
  input_field: {
    backgroundColor: "#e6e6e640",
    borderRadius: "6px",
    fontSize: "13px",
    color: colors.primaryBlack,
  },
  filter_field_label: {
    color: `${colors.primaryBlack}70`,
    fontSize: "13px",
    mb: 0.75,
  },
  flex_JCsb_Acenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  add_project_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  cell_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
  },
  chip: { color: colors.primary, backgroundColor: `${colors.primary}20` },
  chip_variant: { fontSize: "13px", fontWeight: 600 },
  flex_aCenter: { display: "flex", alignItems: "center" },
  mr1: { mr: 1 },
  avatarTable: { width: "30px", height: "30px", fontSize: "13px" },
  my3: { my: 3 },
  flex_Afe: { display: "flex", alignItems: "flex-end" },
  proj_status_container: { display: "flex", mt: 1.5 },
  proj_status_label_container: {
    px: 1.5,
    height: "30px",
    borderRadius: "6px 0 0 6px",
  },
  proj_stat_icon_container: {
    height: "30px",
    borderRadius: "0px 6px 6px 0px",
    ml: 0.5,
  },
  proj_status_text: {
    color: colors.primaryBlack,
    fontSize: "16px",
    fontWeight: 500,
  },
  proj_status_label_text: {
    color: colors.secondary,
    fontSize: "13px",
    fontWeight: 500,
  },
  menu_container: {
    "& .MuiMenu-paper": { border: `1px solid ${colors.borderColor}` },
  },
  flex_Acenter: { display: "flex", alignItems: "center" },
  error_text: { fontSize: "12px", color: "#D3010E" },
  upload_file_container: {
    border: `3px dotted ${colors.borderColor}`,
    borderRadius: "4px",
    py: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  uploaded_file_container: {
    border: `1px solid ${colors.borderColor}`,
    py: 1.5,
    px: 2,
    borderRadius: "4px",
  },
  upload_text: {
    textAlign: "center",
    color: colors.primaryBlack,
    fontSize: "16px",
    my: 1,
  },
  table: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      borderRadius: "10px",
      backgroundColor: "#B6BCC320",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: `${colors.primaryBlack}70`,
      fontSize: "13px",
      fontWeight: 600,
      // textTransform: "uppercase",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#b6bcc330",
      borderRadius: "0px",
    },
  },
  disabled_btn: {
    "&.Mui-disabled": {
      backgroundColor: colors.grey,
      color: "#c0c0c0",
    },
  },
  modal_footer_container: { p: 2, backgroundColor: `${colors.primary}20` },
  proj_status_chip: { mt: 1.5, fontSize: "13px", fontWeight: 600 },
  project_name_text: {
    fontSize: "14px",
    fontWeight: 600,
    color: colors.primaryBlack,
  },
  proj_meta_data_text: {
    fontSize: "13px",
    color: `${colors.secondaryBlack}70`,
  },
  proj_img: {
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
