import { colors } from "theme/colors";

export const SignUpStyles = {
  login_signup_bg: { width: "100%", height: "100vh" },
  signup_form_container: {
    height: "100%",
    width: "100%",
  },
  content_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  stepper_container: { mt: 5 },
  stepper_content: { mt: 3 },
  textfield: { width: { xs: "250px", md: "540px" } },
  textfield_label: {
    fontSize: "16px",
    color: colors.whiteGrey500,
    fontWeight: 500,
    mb: 1,
  },
  textfield_half: { width: "258px" },
  content_center_ja: { justifyContent: "center", alignItems: "center" },
  content_end: { display: "flex", justifyContent: "flex-end" },
  pwd_icon: { color: colors.iconColor },
  strength_container: { display: "flex", mt: 1 },
  strength_bar: {
    width: { xs: "59px", md: "129px" },
    py: 0.25,
    borderRadius: "4px",
    backgroundColor: "#18B370",
  },
  mr8: { mr: { xs: "4px", md: "8px" } },
  btn: { width: { xs: "250px", md: "540px" }, mt: 2 },
  checkbox_text: { color: colors.grey, fontSize: "16px" },
};
