import React, { useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { useStore } from "utils/store";
import { SEND_SUPPORT_REQUEST } from "graphql/users";
import { BlackActionContainedButton } from "common/Buttons";
import { images } from "assets/images";

import { SupportStyles as styles } from "./styles";

interface ISupportFields {
  feedback: string;
}

function SupportForm() {
  const { setLoading } = useStore();

  const [fireSendSupportRequestApi, { loading }] = useMutation(
    SEND_SUPPORT_REQUEST,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { sendFeedback } = data;
        const { status, message } = sendFeedback;
        if (status) {
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleSendSupportRequest = async (
    values: ISupportFields,
    formikHelpers: any
  ) => {
    await fireSendSupportRequestApi({ variables: { payload: { ...values } } });
    formikHelpers.resetForm();
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Formik
      initialValues={{ feedback: "" }}
      validationSchema={Yup.object().shape({
        feedback: Yup.string().required("Please enter your query"),
      })}
      onSubmit={handleSendSupportRequest}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Grid container spacing={2}>
          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={styles.field_label}>Name</Typography>
            <TextField
              size="small"
              fullWidth
              disabled
              inputProps={{ style: styles.input_field }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={styles.field_label}>Email</Typography>
            <TextField
              size="small"
              fullWidth
              disabled
              inputProps={{ style: styles.input_field }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={styles.field_label}>Subject</Typography>
            <TextField
              size="small"
              fullWidth
              disabled
              inputProps={{ style: styles.input_field }}
            />
          </Grid> */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={styles.field_label}>
              How can we help you today?
            </Typography>
            <TextField
              id="feedback"
              value={values.feedback}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              fullWidth
              inputProps={{
                style: {
                  ...styles.input_field,
                  backgroundColor: "transparent",
                },
              }}
              multiline
              minRows={5}
              sx={{
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  backgroundColor: "#e6e6e640",
                },
              }}
            />
            {touched?.feedback && errors?.feedback && (
              <Typography sx={styles.error_text}>{errors.feedback}</Typography>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 2 }}>
              <BlackActionContainedButton
                variant="contained"
                fullWidth
                disableElevation
                startIcon={
                  <img
                    src={images.SEND_WHITE}
                    alt="send"
                    style={{ height: 20 }}
                  />
                }
                sx={{
                  borderRadius: "8px",
                  fontSize: "13px",
                  fontWeight: 600,
                  py: { xs: 1, sm: 1.5 },
                }}
                onClick={() => handleSubmit()}
              >
                Submit
              </BlackActionContainedButton>
            </Box>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default SupportForm;
