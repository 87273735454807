import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  FilterAltOutlined,
  Share,
} from "@mui/icons-material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import Table from "common/Table";
import { IArr } from "models/common";
import { EXPORT_CLEARANCE_REPORT, GET_CLEARANCE_REPORT } from "graphql/reports";
import { GET_CLEARANCE_BASISOFUSE } from "graphql/indexes";
import { useStore } from "utils/store";
import { BlackActionContainedButton } from "common/Buttons";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { IClearanceReport } from "./ScriptClearanceReport";
import { ProjectDetailsStyles as styles } from "../styles";

function ClearanceLockOffReport() {
  const projectId = useParams();
  const { setLoading } = useStore();
  const boxRef = useRef();

  const [clearances, setClearances] = useState<IClearanceReport[]>([]);
  const [clearanceCount, setClearanceCount] = useState(0);
  const [clearanceBasisOfUseArr, setClearanceBasisOfUseArr] = useState<IArr[]>(
    []
  );
  const [sortBy, setSortBy] = useState("");
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  const {} = useQuery(GET_CLEARANCE_BASISOFUSE, {
    variables: {},
    onCompleted: (data) => {
      const { clearanceBasisOfUse } = data;
      if (Boolean(clearanceBasisOfUse)) {
        setClearanceBasisOfUseArr(clearanceBasisOfUse);
      } else {
        setClearanceBasisOfUseArr([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading, fetchMore } = useQuery(GET_CLEARANCE_REPORT, {
    variables: {
      page: 1,
      limit: 50,
      filter: {
        project_id: projectId?.project_id,
      },
      ...(Boolean(sortBy) && { sorted: sortBy }),
    },
    skip: !projectId?.project_id,
    onCompleted: (data) => {
      const { getClearanceReport } = data;
      const { status, count, registerItems } = getClearanceReport;
      if (status) {
        setClearances(registerItems);
        setClearanceCount(count);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getReport, { loading: exporting }] = useLazyQuery(
    EXPORT_CLEARANCE_REPORT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { generateClearanceReport } = data;
        if (Boolean(generateClearanceReport?.report)) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href =
            "data:application/pdf;base64," +
            encodeURI(generateClearanceReport?.report);
          hiddenElement.target = "_blank";
          hiddenElement.download = `Clearance Lock-Off Report.pdf`;
          hiddenElement.click();
        } else {
          toast.error("Something went wrong, please try again.", { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Description or Name",
        minWidth: 250,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "clearanceScreengrabs",
        headerName: "Items To Be Cleared",
        type: "actions",
        headerAlign: "center",
        minWidth: 205,
        flex: 1,
        sortable: false,
        getActions: (params: GridRowParams) =>
          params?.row?.clearanceScreengrabs.map((sg: any) => (
            <GridActionsCellItem
              key={sg.id}
              showInMenu
              label={sg.filename}
              onClick={() => {
                window.open(sg.file);
              }}
              sx={[styles.actions_text, { color: colors.primary }]}
            />
          )),
      },
      {
        field: "clearanceTimecode",
        headerName: "Timecode",
        headerAlign: "center",
        minWidth: 130,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "clearanceBasisOfUse",
        headerName: "Basis Of Use",
        headerAlign: "center",
        minWidth: 170,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <Typography sx={styles.cell_text} textAlign="center">
              {params?.value
                ? clearanceBasisOfUseArr.find((x) => x.id === params?.value)
                    ?.name
                : ""}
            </Typography>
          );
        },
      },
      {
        field: "clearanceCreditObligations",
        headerName: "Credit Obligations",
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, styles.wrap]}>
            <span style={{ fontWeight: 700, color: colors.primaryBlack }}>
              {params?.value}
            </span>
            <span>
              {params?.row?.clearanceCreditObligationsDescription
                ? `, ${params?.row?.clearanceCreditObligationsDescription}`
                : ""}
            </span>
          </Typography>
        ),
      },
      {
        field: "clearanceAdPromoRestrictions",
        headerName: "Ad/Promo Restrictions",
        minWidth: 230,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, styles.wrap]}>
            <span style={{ fontWeight: 700, color: colors.primaryBlack }}>
              {params?.value}
            </span>
            <span>
              {params?.row?.clearanceIssue
                ? `, ${params?.row?.clearanceIssue}`
                : ""}
            </span>
          </Typography>
        ),
      },
      {
        field: "clearanceComments",
        headerName: "Issues/Comments",
        minWidth: 270,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      // {
      //   field: "status",
      //   headerName: "Item Status",
      //   minWidth: 185,
      //   flex: 1,
      //   sortable: false,
      //   renderCell: (params: GridRenderCellParams<any>) =>
      //     Boolean(params?.value) ? (
      //       <Chip
      //         label={(ContractStatusMapping as any)[params?.value]?.name}
      //         sx={[
      //           styles.chip_variant,
      //           {
      //             color: (ContractStatusMapping as any)[params?.value]?.color,
      //             backgroundColor: (ContractStatusMapping as any)[params?.value]
      //               ?.bg,
      //           },
      //         ]}
      //       />
      //     ) : (
      //       <></>
      //     ),
      // },
    ],
    [clearanceBasisOfUseArr]
  );

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const box = boxRef.current;
      const isAtBottom =
        (box as any).scrollHeight - (box as any).scrollTop <=
        (box as any).clientHeight + 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (clearances.length < clearanceCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(clearances.length / 50) + 1,
              limit: 50,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries =
                fetchMoreResult.getClearanceReport.registerItems;
              return {
                getClearanceReport: {
                  ...fetchMoreResult?.getClearanceReport,
                  registerItems: [...clearances, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    const box = boxRef.current;
    if (box) {
      (box as any).addEventListener("scroll", handleScroll);
    }

    // Clean up event listener on component unmount
    return () => {
      if (box) {
        (box as any).removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchMore, setLoading, clearances, clearanceCount]);

  useEffect(() => {
    setLoading(exporting || loading);
  }, [exporting, loading, setLoading]);

  const MoreActionsIcon = () => (
    <img src={images.FILE_LINK_BTN_ICON} alt="link" />
  );

  return (
    <>
      <Box
        sx={{
          p: 1,
          display: { xs: "flex", sm: "none" },
          mb: 1,
        }}
      >
        <Box
          sx={[
            styles.flex_Acenter,
            { justifyContent: "flex-end", width: "100%" },
          ]}
        >
          <Box>
            <BlackActionContainedButton
              variant="contained"
              disableElevation
              startIcon={<Share color="secondary" />}
              onClick={() =>
                getReport({
                  variables: {
                    filter: {
                      project_id: projectId?.project_id,
                    },
                    ...(Boolean(sortBy) && { sorted: sortBy }),
                    report_type: "clearance_lockoff",
                  },
                })
              }
              sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
            >
              Export
            </BlackActionContainedButton>
          </Box>
          <Box sx={{ ml: 2 }}>
            <IconButton
              onClick={handleFilterMenuOpen}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <FilterAltOutlined
                fontSize="small"
                sx={{
                  color: openFilterMenu
                    ? "#41B199"
                    : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
            <Menu
              anchorEl={filterMenuAnchor}
              open={openFilterMenu}
              onClose={handleFilterMenuClose}
              elevation={1}
              sx={{ "& .MuiMenu-paper": { width: 300 } }}
            >
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000080",
                      fontWeight: 600,
                    }}
                  >
                    Sort By
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      size="small"
                      checkedIcon={
                        <CheckBox fontSize="small" sx={{ color: "#6f6f6f" }} />
                      }
                      icon={
                        <CheckBoxOutlineBlank
                          fontSize="small"
                          sx={{ color: "#6f6f6f" }}
                        />
                      }
                      checked={
                        sortBy === "additional_details__clearance_timecode"
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSortBy("additional_details__clearance_timecode");
                        } else {
                          setSortBy("");
                        }
                      }}
                    />
                    <Typography sx={{ color: "#00000070", fontSize: "14px" }}>
                      Timecode (Start-End)
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box
        ref={boxRef}
        sx={{
          height: "calc(100vh - 245px)",
          overflow: "auto",
          mx: -3,
          mb: -3,
        }}
      >
        <Table
          rows={clearances}
          columns={columns}
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          disableColumnMenu
          sx={{
            ...styles.table,
            "& .MuiDataGrid-columnHeaders": {
              border: "none",
              backgroundColor: "#B6BCC320",
            },
            "& .MuiDataGrid-row": {
              borderBottom: "1px #e6e6e6 solid",
              borderRadius: 0,
            },
            "& .MuiDataGrid-row:hover": {
              borderBottom: "transparent",
            },
            borderTop: "1px #e6e6e6 solid",
            borderRadius: 0,
          }}
          hideFooter
          slots={{ moreActionsIcon: MoreActionsIcon }}
        />
        <Box
          sx={{
            position: "absolute",
            top: { xs: 160, sm: 195, md: 178 },
            right: 30,
          }}
        >
          <Box
            sx={[styles.flex_Acenter, { display: { xs: "none", sm: "flex" } }]}
          >
            <Box>
              <BlackActionContainedButton
                variant="contained"
                disableElevation
                startIcon={<Share color="secondary" />}
                onClick={() =>
                  getReport({
                    variables: {
                      filter: {
                        project_id: projectId?.project_id,
                      },
                      ...(Boolean(sortBy) && { sorted: sortBy }),
                      report_type: "clearance_lockoff",
                    },
                  })
                }
                sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
              >
                Export
              </BlackActionContainedButton>
            </Box>
            <Box sx={{ ml: 2 }}>
              <IconButton
                onClick={handleFilterMenuOpen}
                size="small"
                sx={{ ":hover": { backgroundColor: "#41B19931" } }}
              >
                <FilterAltOutlined
                  fontSize="small"
                  sx={{
                    color: openFilterMenu
                      ? "#41B199"
                      : `${colors.primaryBlack}50`,
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={filterMenuAnchor}
                open={openFilterMenu}
                onClose={handleFilterMenuClose}
                elevation={1}
                sx={{ "& .MuiMenu-paper": { width: 300 } }}
              >
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#00000080",
                        fontWeight: 600,
                      }}
                    >
                      Sort By
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        size="small"
                        checkedIcon={
                          <CheckBox
                            fontSize="small"
                            sx={{ color: "#6f6f6f" }}
                          />
                        }
                        icon={
                          <CheckBoxOutlineBlank
                            fontSize="small"
                            sx={{ color: "#6f6f6f" }}
                          />
                        }
                        checked={
                          sortBy === "additional_details__clearance_timecode"
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSortBy("additional_details__clearance_timecode");
                          } else {
                            setSortBy("");
                          }
                        }}
                      />
                      <Typography sx={{ color: "#00000070", fontSize: "14px" }}>
                        Timecode (Start-End)
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ClearanceLockOffReport;
