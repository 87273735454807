import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

import { images } from "assets/images";
import { ManageClientsLabels } from "common/AppConstants";
import { IArr } from "models/common";

import { ManageClientsStyles as styles } from "./styles";

interface IProps {
  setAddClientModal: Dispatch<SetStateAction<boolean>>;
  setSearchText: Dispatch<SetStateAction<string>>;
  setSearchContactNameText: Dispatch<SetStateAction<string>>;
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
}

const sortByOptions: IArr[] = [
  { id: "name", name: "Company Name (A-Z)" },
  { id: "-name", name: "Company Name (Z-A)" },
  { id: "created_date", name: "Created Date (Oldest-Newest)" },
  { id: "-created_date", name: "Created Date (Newest-Oldest)" },
];

const Filters = (props: IProps) => {
  const { setAddClientModal, setSearchText, sortBy, setSortBy } = props;

  const [searchTextFieldValue, setSearchTextFieldValue] = useState("");
  // const [searchContactNameFieldValue, setSearchContactNameFieldValue] =
  //   useState("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextFieldValue(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchText(e.target.value);
    }, 300);
  };

  // const handleSearchContactNameChange = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setSearchContactNameFieldValue(e.target.value);
  //   let timer;
  //   clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     setSearchContactNameText(e.target.value);
  //   }, 300);
  // };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Button
          variant="contained"
          startIcon={<img src={images.ADD_CONTACT_ICON} alt="add" />}
          onClick={() => setAddClientModal(true)}
        >
          {ManageClientsLabels.ADD_CLIENT}
        </Button>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12} />
      <Grid
        item
        lg={3}
        md={3}
        sm={6}
        xs={12}
        display="flex"
        justifyContent="flex-end"
      >
        <TextField
          fullWidth
          value={searchTextFieldValue}
          onChange={handleSearchChange}
          size="small"
          placeholder="Search by Company Name"
          inputProps={{ style: styles.text_input }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="small" color="inherit" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchTextFieldValue("");
                    setSearchText("");
                  }}
                >
                  <Clear fontSize="small" color="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={6} xs={12}>
        <Autocomplete
          size="small"
          fullWidth
          options={sortByOptions}
          getOptionLabel={(opt) => opt.name}
          value={
            sortByOptions.find((x) => x.id === sortBy) || { id: "", name: "" }
          }
          onChange={(_, newValue) => {
            if (newValue) {
              setSortBy(newValue.id);
            } else {
              setSortBy("");
            }
          }}
          placeholder="Sort By"
          renderOption={(props1, opt, _, __) => (
            <Typography {...props1} sx={{ fontSize: "14px" }}>
              {opt.name}
            </Typography>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              placeholder="Sort By"
              inputProps={{
                ...params.inputProps,
                style: styles.text_input,
              }}
            />
          )}
        />
      </Grid>
      {/* <Grid
        item
        lg={3}
        md={3}
        sm={6}
        xs={12}
        display="flex"
        justifyContent="flex-end"
      >
        <TextField
          fullWidth
          value={searchContactNameFieldValue}
          onChange={handleSearchContactNameChange}
          size="small"
          placeholder="Search by Contact Name"
          inputProps={{ style: styles.text_input }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="small" color="inherit" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchContactNameText("");
                    setSearchContactNameFieldValue("");
                  }}
                >
                  <Clear fontSize="small" color="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid> */}
    </Grid>
  );
};

export default Filters;
