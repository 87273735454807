import React, { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Box,
	Drawer as MuiDrawer,
	AppBar as MuiAppBar,
	Toolbar,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	CssBaseline,
	Typography,
	Divider,
	IconButton,
	Grid,
	Avatar,
	// Badge,
	Menu,
	MenuItem,
	MenuList,
} from "@mui/material";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Menu as MenuIcon } from "@mui/icons-material";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { useQuery } from "@apollo/client";

import { images } from "assets/images";
import {
	AdminMainDrawerItems,
	getAdminDrawerHeaderTitle,
	setAdminDrawerSelection,
} from "utils/drawerUtils";
import { RouteNames } from "routes/routeNames";
import { useStore } from "utils/store";
import { GET_PROFILE_PICTURE } from "graphql/users";
import { StorageConstants } from "utils/storage";
import { colors } from "theme/colors";

import LogoutModal from "./LogoutModal";
// import NotificationsMenu from "./NotificationsMenu";

const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	padding: theme.spacing(2, 0),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

interface IProps {
	children: ReactNode;
}

export default function MiniDrawer(props: IProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const { selectedDrawerItem, setSelectedDrawerItem, setLoading } = useStore();
	const [open, setOpen] = useState(true);
	const [openAvatarMenu, setOpenAvatarMenu] = useState(false);
	const [avatarAnchor, setAvatarAnchor] = useState<HTMLElement | null>(null);
	// const [openNotificationsMenu, setOpenNotificationsMenu] = useState(false);
	// const [notificationAnchor, setNotificationAnchor] =
	//   useState<HTMLElement | null>(null);
	const [openLogoutModal, setOpenLogoutModal] = useState(false);
	const [profilePicture, setProfilePicture] = useState<any>(null);

	const { loading: profilePhotoLoading } = useQuery(GET_PROFILE_PICTURE, {
		variables: {
			id: sessionStorage.getItem(StorageConstants.LOGGED_IN_USER_ID),
		},
		onCompleted: (data) => {
			const { user } = data;
			const { profilePicture } = user;
			if (Boolean(profilePicture)) {
				setProfilePicture(profilePicture);
			} else {
				setProfilePicture(null);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		setAvatarAnchor(e.currentTarget);
		setOpenAvatarMenu(true);
	};

	const handleAvatarMenuClose = () => {
		setAvatarAnchor(null);
		setOpenAvatarMenu(false);
	};

	// const handleNotificationsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
	//   setNotificationAnchor(e.currentTarget);
	//   setOpenNotificationsMenu(true);
	// };

	// const handleNotificationsMenuClose = () => {
	//   setNotificationAnchor(null);
	//   setOpenNotificationsMenu(false);
	// };

	useEffect(() => {
		setAdminDrawerSelection(location, setSelectedDrawerItem);
	}, [location, setSelectedDrawerItem]);

	useEffect(() => {
		setLoading(profilePhotoLoading);
	}, [profilePhotoLoading, setLoading]);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar elevation={0} color="secondary" position="fixed" open={open}>
				<Toolbar>
					{/* <img
            src={images.LOGO_SMALL}
            alt="logo"
            width={30}
            style={{
              marginRight: open ? 5 : 10,
              ...(open && { display: "none" }),
              marginTop: 15,
            }}
          /> */}
					{!open && (
						<IconButton
							sx={{ ml: -1.5, mt: 1 }}
							onClick={() =>
								open ? handleDrawerClose() : handleDrawerOpen()
							}
						>
							<MenuIcon sx={{ color: colors.primaryBlack }} />
						</IconButton>
					)}
					<Divider
						orientation="vertical"
						flexItem
						sx={{
							marginRight: 3.5,
							marginLeft: 1.5,
							...(open && { display: "none" }),
						}}
					/>
					<Grid container spacing={1} alignItems="center">
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<Typography
								fontSize="25px"
								fontWeight={500}
								color={colors.primaryBlack}
							>
								{getAdminDrawerHeaderTitle(selectedDrawerItem)}
							</Typography>
						</Grid>
						<Grid
							item
							lg={6}
							md={6}
							sm={6}
							xs={12}
							justifyContent="flex-end"
							alignItems="center"
							display="flex"
						>
							{/* <IconButton
                aria-controls={
                  openNotificationsMenu ? "basic-menu1" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openNotificationsMenu ? "true" : undefined}
                onClick={handleNotificationsClick}
              >
                <Badge
                  variant="dot"
                  overlap="circular"
                  sx={{
                    "& .MuiBadge-badge": { backgroundColor: colors.error },
                  }}
                >
                  <img src={images.NOTIFICATION} alt="notifications" />
                </Badge>
              </IconButton>
              <NotificationsMenu
                anchor={notificationAnchor}
                openMenu={openNotificationsMenu}
                handleClose={handleNotificationsMenuClose}
              /> */}
							<IconButton
								sx={{ ml: 1 }}
								aria-controls={
									openAvatarMenu ? "basic-menu" : undefined
								}
								aria-haspopup="true"
								aria-expanded={openAvatarMenu ? "true" : undefined}
								onClick={handleAvatarClick}
							>
								{Boolean(profilePicture) ? (
									<Avatar src={profilePicture} />
								) : (
									<Avatar />
								)}
							</IconButton>
							<Menu
								id="basic-menu"
								anchorEl={avatarAnchor}
								open={openAvatarMenu}
								onClose={handleAvatarMenuClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
							>
								<MenuList>
									<MenuItem
										onClick={() => {
											handleAvatarMenuClose();
											navigate(RouteNames.ADMIN_SETTINGS);
										}}
									>
										<ListItemIcon>
											<img src={images.PROFILE} alt="profile" />
										</ListItemIcon>
										<ListItemText
											sx={{
												color: colors.secondaryBlack,
												fontSize: "13px",
												fontWeight: 500,
											}}
										>
											Profile
										</ListItemText>
									</MenuItem>
									<Divider />
									<MenuItem
										onClick={() => {
											handleAvatarMenuClose();
											setOpenLogoutModal(true);
										}}
									>
										<ListItemIcon>
											<img src={images.LOGOUT} alt="profile" />
										</ListItemIcon>
										<ListItemText
											sx={{
												color: colors.secondaryBlack,
												fontSize: "13px",
												fontWeight: 500,
											}}
										>
											Logout
										</ListItemText>
									</MenuItem>
								</MenuList>
							</Menu>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader
					sx={{ cursor: "pointer", px: 1.5 }}
					onClick={() => navigate(RouteNames.ADMIN_DASHBOARD)}
				>
					<Box
						sx={{ display: "flex", width: "100%", alignItems: "center" }}
					>
						<IconButton
							onClick={(e) => {
								e.stopPropagation();
								if (open) {
									handleDrawerClose();
								} else {
									handleDrawerOpen();
								}
							}}
						>
							<MenuIcon />
						</IconButton>
						<Box
							sx={{
								flexGrow: 1,
								ml: 1.5,
							}}
						>
							<img src={images.LOGO} alt="logo" height="30px" />
						</Box>
					</Box>
				</DrawerHeader>
				<List
					sx={{
						height: "calc(100% - 100px)",
						overflowY: "auto",
						overflowX: "hidden",
					}}
				>
					<List>
						{AdminMainDrawerItems.map((item) => (
							<ListItem
								key={item.id}
								disablePadding
								sx={{
									display: {
										xs: "block",
										sm: item.id < 13 ? "block" : "none",
									},
								}}
							>
								<ListItemButton
									sx={{
										minHeight: 48,
										justifyContent: open ? "initial" : "center",
										px: 2.5,
									}}
									selected={selectedDrawerItem === item.id}
									onClick={() => {
										setSelectedDrawerItem(item.id);
										navigate(item.path);
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: open ? 3 : "auto",
											justifyContent: "center",
										}}
									>
										<img
											src={
												item.id === selectedDrawerItem
													? item.selectedIcon
													: item.icon
											}
											alt="icon"
											style={{ width: 22, objectFit: "cover" }}
										/>
									</ListItemIcon>
									<ListItemText sx={{ opacity: open ? 1 : 0 }}>
										<Typography
											sx={{
												fontSize: "14px",
												color: colors.primaryBlack,
												fontWeight:
													item.id === selectedDrawerItem
														? 600
														: 500,
											}}
										>
											{item.label}
										</Typography>
									</ListItemText>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</List>
			</Drawer>
			<Box component="main" sx={{ p: 3, width: "100%", overflowX: "auto" }}>
				<DrawerHeader />
				{props.children}
				<LogoutModal open={openLogoutModal} setOpen={setOpenLogoutModal} />
			</Box>
		</Box>
	);
}
