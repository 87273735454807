import moment from "moment";

import {
  ContractStatusApprovalPendingUpdateArr,
  ContractStatusArchiveUpdateArr,
  ContractStatusDraftUpdateArr,
  ContractStatusRescindedUpdateArr,
} from "./constants";

export const plurify = (value: number, unit: string) => {
  if (value === 1) {
    return unit;
  }
  return `${unit}s`;
};

export const capitalize = (str: string) => {
  let tempStr = str.toLowerCase();
  return tempStr.charAt(0).toUpperCase() + tempStr.slice(1);
};

export function assign<T extends object, K extends keyof T>(
  obj: T,
  key: K,
  val: T[K]
) {
  obj[key] = val; // okay
}

export const groupActivitiesByDate = (logs: any, setActivities: any) => {
  const groupedData = logs.reduce(function (r: any, a: any) {
    r[moment(a.historyDate).format("YYYY-MM-DD")] =
      r[moment(a.historyDate).format("YYYY-MM-DD")] || [];
    r[moment(a.historyDate).format("YYYY-MM-DD")].push(a);
    return r;
  }, Object.create(null));
  setActivities(groupedData);
};

export const fetchContractStatusUpdateArr = (currentStatus: string) => {
  if (currentStatus === "DRAFT") {
    return ContractStatusDraftUpdateArr;
  } else if (currentStatus === "APPROVAL_PENDING") {
    return ContractStatusApprovalPendingUpdateArr;
  } else if (
    currentStatus === "APPROVED" ||
    currentStatus === "PARTIALLY_EXECUTED" ||
    currentStatus === "FULLY_EXECUTED"
  ) {
    return [{ id: "RESCINDED", name: "Rescinded" }];
  } else if (currentStatus === "ARCHIVED") {
    return ContractStatusArchiveUpdateArr;
  } else {
    return ContractStatusRescindedUpdateArr;
  }
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength);
};
