import * as Yup from "yup";
import {
	IContactBasicDetails,
	IContactFinancialDetailsForm,
	IUpdateContactFieldsNew,
} from "models/contacts";
import { CountryCodeArr } from "utils/constants";

export const initialValues: IUpdateContactFieldsNew = {
	id: "",
	firstName: "",
	lastName: "",
	// fullName: "",
	contactDepartmentId: "",
	emailId: "",
	mobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
	nationality: "",
	isRestricted: false,
	shareConsent: true,
	licence: "",
	abn: "",
	wwcNumber: "",
	equityNumber: "",
	notes: "",
	unit: "",
	address: null,
	street: "",
	suburb: "",
	contactCity: "",
	contactState: "",
	contactCountry: "",
	pincode: "",
	lenderCompanyDetails: {
		companyName: "",
		spocName: "",
		spocMobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
		spocEmailId: "",
		website: "",
		unit: "",
		address: null,
		street: "",
		suburb: "",
		companyCity: "",
		companyState: "",
		companyCountry: "",
		pinCode: "",
		abn: "",
		acn: "",
		registrationNumber: "",
		publicLiabilityPolicy: "",
		provider: "",
		policyNumber: "",
		insurancePeriod: "",
		financialDetails: {
			accountName: "",
			accountNumber: "",
			accountBsb: "",
			accountSwiftCode: "",
			bankName: "",
			branchName: "",
			accountInstructions: "",
			abn: "",
			taxFileNo: "",
			haveSuperannuationAccount: "",
			superannuationFundName: "",
			memberNumber: "",
			smsfName: "",
			smsfAbn: "",
			smsfEsa: "",
			smsfFullName: "",
			smsfBankAccountName: "",
			smsfBsb: "",
			superannuationAccountNo: "",
		},
	},
	agentFirstName: "",
	agentLastName: "",
	agentEmailId: "",
	agentMobile: "",
	agencyDetails: {
		companyName: "",
		spocName: "",
		spocMobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
		spocEmailId: "",
		website: "",
		unit: "",
		address: null,
		street: "",
		suburb: "",
		companyCity: "",
		companyState: "",
		companyCountry: "",
		pinCode: "",
		abn: "",
		acn: "",
		registrationNumber: "",
		publicLiabilityPolicy: "",
		provider: "",
		policyNumber: "",
		insurancePeriod: "",
		financialDetails: {
			accountName: "",
			accountNumber: "",
			accountBsb: "",
			accountSwiftCode: "",
			bankName: "",
			branchName: "",
			accountInstructions: "",
			abn: "",
			taxFileNo: "",
			haveSuperannuationAccount: "",
			superannuationFundName: "",
			memberNumber: "",
			smsfName: "",
			smsfAbn: "",
			smsfEsa: "",
			smsfFullName: "",
			smsfBankAccountName: "",
			smsfBsb: "",
			superannuationAccountNo: "",
		},
	},
	managerFirstName: "",
	managerLastName: "",
	managerEmailId: "",
	managerMobile: "",
	managementCompanyDetails: {
		companyName: "",
		spocName: "",
		spocMobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
		spocEmailId: "",
		website: "",
		unit: "",
		address: null,
		street: "",
		suburb: "",
		companyCity: "",
		companyState: "",
		companyCountry: "",
		pinCode: "",
		abn: "",
		acn: "",
		registrationNumber: "",
		publicLiabilityPolicy: "",
		provider: "",
		policyNumber: "",
		insurancePeriod: "",
		financialDetails: {
			accountName: "",
			accountNumber: "",
			accountBsb: "",
			accountSwiftCode: "",
			bankName: "",
			branchName: "",
			accountInstructions: "",
			abn: "",
			taxFileNo: "",
			haveSuperannuationAccount: "",
			superannuationFundName: "",
			memberNumber: "",
			smsfName: "",
			smsfAbn: "",
			smsfEsa: "",
			smsfFullName: "",
			smsfBankAccountName: "",
			smsfBsb: "",
			superannuationAccountNo: "",
		},
	},
	personalBankAccountDetails: {
		accountName: "",
		accountNumber: "",
		accountBsb: "",
		accountSwiftCode: "",
		bankName: "",
		branchName: "",
		accountInstructions: "",
		abn: "",
		taxFileNo: "",
		haveSuperannuationAccount: "",
		superannuationFundName: "",
		memberNumber: "",
		smsfName: "",
		smsfAbn: "",
		smsfEsa: "",
		smsfFullName: "",
		smsfBankAccountName: "",
		smsfBsb: "",
		superannuationAccountNo: "",
	},
	profile_picture: null,
	project_role: "",
	dob: "",
	gender: "",
	food_allergies: "",
	medical_allergies: "",
	next_of_kin_contact_number: {
		countryCode: "61",
		nationalNumber: null,
		rawInput: "",
	},
	next_of_kin_name: "",
	next_of_kin_relationship: "",
	instagram: "",
	facebook: "",
	twitter: "",
	imdb: "",
};

export const setContactDetails = (contactDetails: any, setValues: any) => {
	const {
		contactDepartment,
		profile,
		profilePicture,
		lenderCompanyDetails,
		agencyDetails,
		managementCompanyDetails,
		financialDetails,
		photo,
		mobile,
		shareConsent,
		// contactCity,
		// contactState,
		// contactCountry,
		__typename,
		...restDetails
	} = contactDetails;

	const profileJSON = Boolean(profile) ? JSON.parse(profile) : null;
	const financialDetailsJSON = Boolean(financialDetails?.superannuationData)
		? JSON.parse(financialDetails?.superannuationData)
		: null;
	const lenderFinancialDetailsJSON = Boolean(
		lenderCompanyDetails?.companyFinancialDetails?.superannuationData
	)
		? JSON.parse(
				lenderCompanyDetails?.companyFinancialDetails?.superannuationData
		  )
		: null;
	const agencyFinancialDetailsJSON = Boolean(
		agencyDetails?.companyFinancialDetails?.superannuationData
	)
		? JSON.parse(agencyDetails?.companyFinancialDetails?.superannuationData)
		: null;
	const mgmtCompanyFinancialDetailsJSON = Boolean(
		managementCompanyDetails?.companyFinancialDetails?.superannuationData
	)
		? JSON.parse(
				managementCompanyDetails?.companyFinancialDetails
					?.superannuationData
		  )
		: null;

	// let parsedMobile = "";
	// if (mobile.length === 10) {
	//   const tempStr = mobile.slice(1);
	//   const areaCode = tempStr.substring(0, 3);
	//   const middle = tempStr.substring(3, 6);
	//   const last = tempStr.substring(6, 9);
	//   parsedMobile = `${areaCode} ${middle} ${last}`;
	// } else {
	//   parsedMobile = mobile;
	// }

	let emergencyMobile = {
		countryCode: "61",
		nationalNumber: null,
		rawInput: "",
	};
	if (
		Boolean(profileJSON) &&
		Boolean(profileJSON?.next_of_kin_contact_number) &&
		Boolean(profileJSON.next_of_kin_contact_number) !== null
	) {
		if (typeof profileJSON.next_of_kin_contact_number === "string") {
			const parts = profileJSON?.next_of_kin_contact_number.split(" ");
			emergencyMobile = {
				countryCode:
					CountryCodeArr.find((x) => x.name === parts[0])?.id || "61",
				nationalNumber: parts[1] || null,
				rawInput: "",
			};
		}
	}

	setValues({
		...restDetails,
		shareConsent: shareConsent !== null ? shareConsent : true,
		mobile: {
			countryCode: mobile.countryCode || "61",
			nationalNumber: mobile?.nationalNumber || null,
			rawInput: mobile?.rawInput || "",
		},
		contactDepartmentId: contactDepartment?.id || "TBA",
		project_role: Boolean(profileJSON)
			? profileJSON?.project_role || "TBA"
			: "TBA",
		dob: Boolean(profileJSON) ? profileJSON?.dob : "",
		gender: Boolean(profileJSON) ? profileJSON?.gender : "",
		food_allergies: Boolean(profileJSON) ? profileJSON?.food_allergies : "",
		medical_allergies: Boolean(profileJSON)
			? profileJSON?.medical_allergies
			: "",
		next_of_kin_contact_number: emergencyMobile,
		next_of_kin_name: Boolean(profileJSON)
			? profileJSON?.next_of_kin_name
			: "",
		next_of_kin_relationship: Boolean(profileJSON)
			? profileJSON?.next_of_kin_relationship
			: "",
		instagram: Boolean(profileJSON) ? profileJSON?.instagram : "",
		facebook: Boolean(profileJSON) ? profileJSON?.facebook : "",
		twitter: Boolean(profileJSON) ? profileJSON?.twitter : "",
		imdb: Boolean(profileJSON) ? profileJSON?.imdb : "",
		profile_picture: profilePicture,
		personalBankAccountDetails: {
			accountName: financialDetails?.accountName || "",
			accountNumber: financialDetails?.accountNumber || "",
			accountBsb: financialDetails?.accountBsb || "",
			accountSwiftCode: financialDetails?.accountSwiftCode || "",
			bankName: financialDetails?.bankName || "",
			branchName: financialDetails?.branchName || "",
			accountInstructions: financialDetails?.accountInstructions || "",
			abn: financialDetails?.abn || "",
			taxFileNo: financialDetails?.taxFileNo || "",
			haveSuperannuationAccount:
				financialDetailsJSON?.haveSuperannuationAccount || "",
			superannuationFundName:
				financialDetailsJSON?.superannuationFundName || "",
			memberNumber: financialDetailsJSON?.memberNumber || "",
			smsfName: financialDetailsJSON?.smsfName || "",
			smsfAbn: financialDetailsJSON?.smsfAbn || "",
			smsfEsa: financialDetailsJSON?.smsfEsa || "",
			smsfFullName: financialDetailsJSON?.smsfFullName || "",
			smsfBankAccountName: financialDetailsJSON?.smsfBankAccountName || "",
			smsfBsb: financialDetailsJSON?.smsfBsb || "",
			superannuationAccountNo:
				financialDetailsJSON?.superannuationAccountNo || "",
		},
		lenderCompanyDetails: {
			companyName: lenderCompanyDetails?.companyName || "",
			spocName: lenderCompanyDetails?.spocName || "",
			spocMobile: {
				countryCode: lenderCompanyDetails?.spocMobile?.countryCode || "61",
				nationalNumber:
					lenderCompanyDetails?.spocMobile?.nationalNumber || null,
				rawInput: lenderCompanyDetails?.spocMobile?.rawInput || "",
			},
			spocEmailId: lenderCompanyDetails?.spocEmailId || "",
			website: lenderCompanyDetails?.website || "",
			unit: lenderCompanyDetails?.unit || "",
			address: lenderCompanyDetails?.address || "",
			street: lenderCompanyDetails?.street || "",
			suburb: lenderCompanyDetails?.suburb || "",
			pinCode: lenderCompanyDetails?.pinCode || "",
			companyCountry: lenderCompanyDetails?.companyCountry || "",
			companyState: lenderCompanyDetails?.companyState || "",
			companyCity: lenderCompanyDetails?.companyCity || "",
			abn: lenderCompanyDetails?.abn || "",
			acn: lenderCompanyDetails?.acn || "",
			registrationNumber: lenderCompanyDetails?.registrationNumber || "",
			publicLiabilityPolicy:
				lenderCompanyDetails?.publicLiabilityPolicy || "",
			provider: lenderCompanyDetails?.provider || "",
			policyNumber: lenderCompanyDetails?.policyNumber || "",
			insurancePeriod: lenderCompanyDetails?.insurancePeriod || "",
			financialDetails: {
				accountName:
					lenderCompanyDetails?.companyFinancialDetails?.accountName || "",
				accountNumber:
					lenderCompanyDetails?.companyFinancialDetails?.accountNumber ||
					"",
				accountBsb:
					lenderCompanyDetails?.companyFinancialDetails?.accountBsb || "",
				accountSwiftCode:
					lenderCompanyDetails?.companyFinancialDetails
						?.accountSwiftCode || "",
				bankName:
					lenderCompanyDetails?.companyFinancialDetails?.bankName || "",
				branchName:
					lenderCompanyDetails?.companyFinancialDetails?.branchName || "",
				accountInstructions:
					lenderCompanyDetails?.companyFinancialDetails
						?.accountInstructions || "",
				abn: lenderCompanyDetails?.companyFinancialDetails?.abn || "",
				taxFileNo:
					lenderCompanyDetails?.companyFinancialDetails?.taxFileNo || "",
				haveSuperannuationAccount:
					lenderFinancialDetailsJSON?.haveSuperannuationAccount || "",
				superannuationFundName:
					lenderFinancialDetailsJSON?.superannuationFundName || "",
				memberNumber: lenderFinancialDetailsJSON?.memberNumber || "",
				smsfName: lenderFinancialDetailsJSON?.smsfName || "",
				smsfAbn: lenderFinancialDetailsJSON?.smsfAbn || "",
				smsfEsa: lenderFinancialDetailsJSON?.smsfEsa || "",
				smsfFullName: lenderFinancialDetailsJSON?.smsfFullName || "",
				smsfBankAccountName:
					lenderFinancialDetailsJSON?.smsfBankAccountName || "",
				smsfBsb: lenderFinancialDetailsJSON?.smsfBsb || "",
				superannuationAccountNo:
					lenderFinancialDetailsJSON?.superannuationAccountNo || "",
			},
		},
		agencyDetails: {
			companyName: agencyDetails?.companyName || "",
			spocName: agencyDetails?.spocName || "",
			spocMobile: {
				countryCode: agencyDetails?.spocMobile?.countryCode || "61",
				nationalNumber: agencyDetails?.spocMobile?.nationalNumber || null,
				rawInput: agencyDetails?.spocMobile?.rawInput || "",
			},
			spocEmailId: agencyDetails?.spocEmailId || "",
			website: agencyDetails?.website || "",
			unit: agencyDetails?.unit || "",
			address: agencyDetails?.address || "",
			street: agencyDetails?.street || "",
			suburb: agencyDetails?.suburb || "",
			pinCode: agencyDetails?.pinCode || "",
			companyCountry: agencyDetails?.companyCountry || "",
			companyState: agencyDetails?.companyState || "",
			companyCity: agencyDetails?.companyCity || "",
			abn: agencyDetails?.abn || "",
			acn: agencyDetails?.acn || "",
			registrationNumber: agencyDetails?.registrationNumber || "",
			publicLiabilityPolicy: agencyDetails?.publicLiabilityPolicy || "",
			provider: agencyDetails?.provider || "",
			policyNumber: agencyDetails?.policyNumber || "",
			insurancePeriod: agencyDetails?.insurancePeriod || "",
			financialDetails: {
				accountName:
					agencyDetails?.companyFinancialDetails?.accountName || "",
				accountNumber:
					agencyDetails?.companyFinancialDetails?.accountNumber || "",
				accountBsb:
					agencyDetails?.companyFinancialDetails?.accountBsb || "",
				accountSwiftCode:
					agencyDetails?.companyFinancialDetails?.accountSwiftCode || "",
				bankName: agencyDetails?.companyFinancialDetails?.bankName || "",
				branchName:
					agencyDetails?.companyFinancialDetails?.branchName || "",
				accountInstructions:
					agencyDetails?.companyFinancialDetails?.accountInstructions ||
					"",
				abn: agencyDetails?.companyFinancialDetails?.abn || "",
				taxFileNo: agencyDetails?.companyFinancialDetails?.taxFileNo || "",
				haveSuperannuationAccount:
					agencyFinancialDetailsJSON?.haveSuperannuationAccount || "",
				superannuationFundName:
					agencyFinancialDetailsJSON?.superannuationFundName || "",
				memberNumber: agencyFinancialDetailsJSON?.memberNumber || "",
				smsfName: agencyFinancialDetailsJSON?.smsfName || "",
				smsfAbn: agencyFinancialDetailsJSON?.smsfAbn || "",
				smsfEsa: agencyFinancialDetailsJSON?.smsfEsa || "",
				smsfFullName: agencyFinancialDetailsJSON?.smsfFullName || "",
				smsfBankAccountName:
					agencyFinancialDetailsJSON?.smsfBankAccountName || "",
				smsfBsb: agencyFinancialDetailsJSON?.smsfBsb || "",
				superannuationAccountNo:
					agencyFinancialDetailsJSON?.superannuationAccountNo || "",
			},
		},
		managementCompanyDetails: {
			companyName: managementCompanyDetails?.companyName || "",
			spocName: managementCompanyDetails?.spocName || "",
			spocMobile: {
				countryCode:
					managementCompanyDetails?.spocMobile?.countryCode || "61",
				nationalNumber:
					managementCompanyDetails?.spocMobile?.nationalNumber || null,
				rawInput: managementCompanyDetails?.spocMobile?.rawInput || "",
			},
			spocEmailId: managementCompanyDetails?.spocEmailId || "",
			website: managementCompanyDetails?.website || "",
			unit: managementCompanyDetails?.unit || "",
			address: managementCompanyDetails?.address || "",
			street: managementCompanyDetails?.street || "",
			suburb: managementCompanyDetails?.suburb || "",
			pinCode: managementCompanyDetails?.pinCode || "",
			companyCountry: managementCompanyDetails?.companyCountry || "",
			companyState: managementCompanyDetails?.companyState || "",
			companyCity: managementCompanyDetails?.companyCity || "",
			abn: managementCompanyDetails?.abn || "",
			acn: managementCompanyDetails?.acn || "",
			registrationNumber: managementCompanyDetails?.registrationNumber || "",
			publicLiabilityPolicy:
				managementCompanyDetails?.publicLiabilityPolicy || "",
			provider: managementCompanyDetails?.provider || "",
			policyNumber: managementCompanyDetails?.policyNumber || "",
			insurancePeriod: managementCompanyDetails?.insurancePeriod || "",
			financialDetails: {
				accountName:
					managementCompanyDetails?.companyFinancialDetails?.accountName ||
					"",
				accountNumber:
					managementCompanyDetails?.companyFinancialDetails
						?.accountNumber || "",
				accountBsb:
					managementCompanyDetails?.companyFinancialDetails?.accountBsb ||
					"",
				accountSwiftCode:
					managementCompanyDetails?.companyFinancialDetails
						?.accountSwiftCode || "",
				bankName:
					managementCompanyDetails?.companyFinancialDetails?.bankName ||
					"",
				branchName:
					managementCompanyDetails?.companyFinancialDetails?.branchName ||
					"",
				accountInstructions:
					managementCompanyDetails?.companyFinancialDetails
						?.accountInstructions || "",
				abn: managementCompanyDetails?.companyFinancialDetails?.abn || "",
				taxFileNo:
					managementCompanyDetails?.companyFinancialDetails?.taxFileNo ||
					"",
				haveSuperannuationAccount:
					mgmtCompanyFinancialDetailsJSON?.haveSuperannuationAccount || "",
				superannuationFundName:
					mgmtCompanyFinancialDetailsJSON?.superannuationFundName || "",
				memberNumber: mgmtCompanyFinancialDetailsJSON?.memberNumber || "",
				smsfName: mgmtCompanyFinancialDetailsJSON?.smsfName || "",
				smsfAbn: mgmtCompanyFinancialDetailsJSON?.smsfAbn || "",
				smsfEsa: mgmtCompanyFinancialDetailsJSON?.smsfEsa || "",
				smsfFullName: mgmtCompanyFinancialDetailsJSON?.smsfFullName || "",
				smsfBankAccountName:
					mgmtCompanyFinancialDetailsJSON?.smsfBankAccountName || "",
				smsfBsb: mgmtCompanyFinancialDetailsJSON?.smsfBsb || "",
				superannuationAccountNo:
					mgmtCompanyFinancialDetailsJSON?.superannuationAccountNo || "",
			},
		},
	});
};

export const initialValuesBasicDetails: IContactBasicDetails = {
	id: "",
	firstName: "",
	lastName: "",
	department: "",
	emailId: "",
	mobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
	agentFirstName: "",
	agentLastName: "",
	agentEmailId: "",
	agentMobile: "",
	managerFirstName: "",
	managerLastName: "",
	managerEmailId: "",
	managerMobile: "",
	lenderCompanyDetails: {
		companyName: "",
		registrationNumber: "",
		street: "",
		suburb: "",
		cityId: "",
		stateId: "",
		countryId: "",
		pinCode: "",
		spocName: "",
		spocMobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
		spocEmailId: "",
		publicLiabilityPolicy: "",
		website: "",
	},
	agencyDetails: {
		companyName: "",
		registrationNumber: "",
		street: "",
		suburb: "",
		cityId: "",
		stateId: "",
		countryId: "",
		pinCode: "",
		spocName: "",
		spocMobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
		spocEmailId: "",
		publicLiabilityPolicy: "",
		website: "",
	},
	managementCompanyDetails: {
		companyName: "",
		registrationNumber: "",
		street: "",
		suburb: "",
		cityId: "",
		stateId: "",
		countryId: "",
		pinCode: "",
		spocName: "",
		spocMobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
		spocEmailId: "",
		publicLiabilityPolicy: "",
		website: "",
	},
	profile_picture: null,
	isRestricted: true,
	shareConsent: true,
	project_role: "",
	driver_license: "",
	suburb: "",
	street: "",
	cityId: "",
	stateId: "",
	countryId: "",
	pincode: "",
	website: "",
	food_allergies: "",
	medical_allergies: "",
	next_of_kin_contact_number: {
		countryCode: "61",
		nationalNumber: null,
		rawInput: "",
	},
	next_of_kin_name: "",
	next_of_kin_relationship: "",
	profile: null,
};

export const setBasicDetailsState = (
	contactDetails: any,
	setBasicDetails: any,
	setSelectedCountry: any,
	setSelectedState: any,
	setSelectedLenderCountry: any,
	setSelectedLenderState: any,
	setSelectedAgencyCountry: any,
	setSelectedAgencyState: any,
	setSelectedMgmtCountry: any,
	setSelectedMgmtState: any
) => {
	const {
		__typename: __typename1,
		lenderCompanyDetails,
		agencyDetails,
		managementCompanyDetails,
		profilePicture,
		agentEmailId,
		agentFirstName,
		agentLastName,
		agentMobile,
		managerEmailId,
		managerFirstName,
		managerLastName,
		managerMobile,
		pincode,
		profile,
		contactCity,
		contactState,
		contactCountry,
		...restBasicDetails
	} = contactDetails;

	let parsedProfile = null;
	if (Boolean(profile)) {
		parsedProfile = JSON.parse(profile);
	}

	setSelectedCountry(contactCountry?.id || "");
	setSelectedState(contactState?.id || "");

	let lenderCompanyDetailsF = {
		companyName: "",
		registrationNumber: "",
		street: "",
		suburb: "",
		cityId: "",
		stateId: "",
		countryId: "",
		pinCode: "",
		spocName: "",
		spocMobile: "",
		spocEmailId: "",
		publicLiabilityPolicy: "",
		website: "",
	};
	if (Boolean(lenderCompanyDetails)) {
		const {
			__typename: __typename2,
			companyCity: lenderCity,
			companyState: lenderState,
			companyCountry: lenderCountry,
			...restLenderCompDetails
		} = lenderCompanyDetails;
		lenderCompanyDetailsF = {
			...restLenderCompDetails,
			countryId: lenderCountry?.id || "",
			stateId: lenderState?.id || "",
			cityId: lenderCity?.id || "",
		};
		setSelectedLenderCountry(lenderCountry?.id || "");
		setSelectedLenderState(lenderState?.id || "");
	}
	let agencyDetailsF = {
		companyName: "",
		registrationNumber: "",
		street: "",
		suburb: "",
		cityId: "",
		stateId: "",
		countryId: "",
		pinCode: "",
		spocName: "",
		spocMobile: "",
		spocEmailId: "",
		publicLiabilityPolicy: "",
		website: "",
	};
	if (Boolean(agencyDetails)) {
		const {
			__typename: __typename3,
			companyCity: agencyCity,
			companyState: agencyState,
			companyCountry: agencyCountry,
			...restAgencyDetails
		} = agencyDetails;
		agencyDetailsF = {
			...restAgencyDetails,
			countryId: agencyCountry?.id || "",
			stateId: agencyState?.id || "",
			cityId: agencyCity?.id || "",
		};
		setSelectedAgencyCountry(agencyCountry?.id || "");
		setSelectedAgencyState(agencyState?.id || "");
	}
	let mgmtCompanyDetailsF = {
		companyName: "",
		registrationNumber: "",
		street: "",
		suburb: "",
		cityId: "",
		stateId: "",
		countryId: "",
		pinCode: "",
		spocName: "",
		spocMobile: "",
		spocEmailId: "",
		publicLiabilityPolicy: "",
		website: "",
	};
	if (Boolean(managementCompanyDetails)) {
		const {
			__typename: __typename4,
			companyCity: mgmtCity,
			companyState: mgmtState,
			companyCountry: mgmtCountry,
			...restMgmtCompDetails
		} = managementCompanyDetails;
		mgmtCompanyDetailsF = {
			...restMgmtCompDetails,
			countryId: mgmtCountry?.id || "",
			stateId: mgmtState?.id || "",
			cityId: mgmtCity?.id || "",
		};
		setSelectedMgmtCountry(mgmtCountry?.id || "");
		setSelectedMgmtState(mgmtState?.id || "");
	}

	let emergencyMobile = {
		countryCode: "61",
		nationalNumber: null,
		rawInput: "",
	};
	if (
		Boolean(parsedProfile) &&
		Boolean(parsedProfile?.next_of_kin_contact_number) &&
		Boolean(parsedProfile.next_of_kin_contact_number) !== null
	) {
		if (typeof parsedProfile.next_of_kin_contact_number === "string") {
			const parts = parsedProfile?.next_of_kin_contact_number.split(" ");
			emergencyMobile = {
				countryCode:
					CountryCodeArr.find((x) => x.name === parts[0])?.id || "61",
				nationalNumber: parts[1] || null,
				rawInput: "",
			};
		}
	}

	setBasicDetails({
		...restBasicDetails,
		agentEmailId: agentEmailId || "",
		agentFirstName: agentFirstName || "",
		agentLastName: agentLastName || "",
		agentMobile: agentMobile || "",
		managerEmailId: managerEmailId || "",
		managerFirstName: managerFirstName || "",
		managerLastName: managerLastName || "",
		managerMobile: managerMobile || "",
		pincode: pincode || "",
		lenderCompanyDetails: { ...lenderCompanyDetailsF },
		agencyDetails: { ...agencyDetailsF },
		managementCompanyDetails: { ...mgmtCompanyDetailsF },
		profile_picture: profilePicture,
		profile: parsedProfile,
		project_role: parsedProfile?.project_role || "",
		driver_license: parsedProfile?.driver_license || "",
		website: parsedProfile?.website || "",
		food_allergies: parsedProfile?.food_allergies || "",
		medical_allergies: parsedProfile?.medical_allergies || "",
		next_of_kin_contact_number: emergencyMobile,
		next_of_kin_name: parsedProfile?.next_of_kin_name || "",
		next_of_kin_relationship: parsedProfile?.next_of_kin_relationship || "",
		countryId: contactCountry?.id || "",
		stateId: contactState?.id || "",
		cityId: contactCity?.id || "",
	});
};

export const basicDetailsValidationSchema = Yup.object().shape({
	firstName: Yup.string().required("Please enter your first name"),
	lastName: Yup.string().required("Please enter your last name"),
	project_role: Yup.string().required("Please mention the project role"),
	emailId: Yup.string()
		.required("Please enter the email id")
		.email("Please enter a valid email"),
	// mobile: Yup.string()
	//   .required("Please enter the contact number")
	//   .matches(/^[0-9]{10}$/, "Contact number must be of 10 digits"),
	pincode: Yup.string().matches(/^[0-9]{4}$/, "Post Code must be of 4 digits"),
	website: Yup.string().matches(
		/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
		"Please enter a valid url"
	),
	//   next_of_kin_contact_number: Yup.string().matches(
	//     /^[0-9]{10}$/,
	//     "Contact number must be of 10 digits"
	//   ),
	agentMobile: Yup.string().matches(
		/^[0-9]{10}$/,
		"Contact number must be of 10 digits"
	),
	agentEmailId: Yup.string().email("Please enter a valid email"),
	managerMobile: Yup.string().matches(
		/^[0-9]{10}$/,
		"Contact number must be of 10 digits"
	),
	managerEmailId: Yup.string().email("Please enter a valid email"),
	lenderCompanyDetails: Yup.object().shape({
		pinCode: Yup.string().matches(
			/^[0-9]{4}$/,
			"Post Code must be of 4 digits"
		),
		spocMobile: Yup.string().matches(
			/^[0-9]{10}$/,
			"Contact number must be of 10 digits"
		),
		spocEmailId: Yup.string().email("Please enter a valid email"),
		website: Yup.string().matches(
			/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
			"Please enter a valid url"
		),
		registrationNumber: Yup.string().matches(
			/^[0-9]{11}$/,
			"ABN must be of 11 digits"
		),
	}),
	agencyDetails: Yup.object().shape({
		pinCode: Yup.string().matches(
			/^[0-9]{4}$/,
			"Post Code must be of 4 digits"
		),
		spocMobile: Yup.string().matches(
			/^[0-9]{10}$/,
			"Contact number must be of 10 digits"
		),
		spocEmailId: Yup.string().email("Please enter a valid email"),
		website: Yup.string().matches(
			/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
			"Please enter a valid url"
		),
		registrationNumber: Yup.string().matches(
			/^[0-9]{11}$/,
			"ABN must be of 11 digits"
		),
	}),
	managementCompanyDetails: Yup.object().shape({
		pinCode: Yup.string().matches(
			/^[0-9]{4}$/,
			"Post Code must be of 4 digits"
		),
		spocMobile: Yup.string().matches(
			/^[0-9]{10}$/,
			"Contact number must be of 10 digits"
		),
		spocEmailId: Yup.string().email("Please enter a valid email"),
		website: Yup.string().matches(
			/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
			"Please enter a valid url"
		),
		registrationNumber: Yup.string().matches(
			/^[0-9]{11}$/,
			"ABN must be of 11 digits"
		),
	}),
});

export const initialValuesProjects = (projectId: string) => {
	return [
		{
			projectId: projectId,
			role: "",
			artistTypeId: "",
			artistClassId: "",
			characterName: "",
		},
	];
};

export const setProjectDetailsState = (projects: any, setProjects: any) => {
	let formattedProjects: any = [];
	for (let i = 0; i < projects.length; i++) {
		const {
			__typename: __typename1,
			id: id1,
			project,
			artistClass,
			artistType,
			...rest
		} = projects[i];
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { __typename: __typename2, id } = project;
		formattedProjects.push({
			...rest,
			projectId: id,
			artistClassId: artistClass?.id || "",
			artistTypeId: artistType?.id || "",
		});
	}
	setProjects([...formattedProjects]);
};

export const projectsValidationSchema = Yup.object().shape({
	projects: Yup.array()
		.of(
			Yup.object().shape({
				projectId: Yup.string(),
				role: Yup.string(),
				artistTypeId: Yup.string(),
				artistClassId: Yup.string(),
				characterName: Yup.string(),
			})
		)
		.min(1, "Please add atleast one project"),
});

export const validateProjectsArr = (projects: any, setProjectErrors: any) => {
	let errors: string[] = [];
	for (let i = 0; i < projects.length; i++) {
		errors.push("");
	}
	for (let i = 0; i < projects.length; i++) {
		if (projects[i].projectId === "") {
			errors[i] = "Please select a project";
		}
	}
	setProjectErrors(errors);
	return errors;
};

export const validateProjectErrors = (errors: string[]) => {
	let flag = 0;
	for (let i = 0; i < errors.length; i++) {
		if (errors[i]) {
			flag = 1;
			break;
		}
	}
	return flag === 0;
};

export const initialValuesFinancialDetails: IContactFinancialDetailsForm = {
	personalBankAccountDetails: {
		accountNumber: "",
		accountName: "",
		accountBsb: "",
		bankName: "",
		abn: "",
		superannuationAccountNo: "",
		superannuationFundName: "",
		taxFileNo: "",
		accountSwiftCode: "",
		accountInstructions: "",
	},
	lenderBankAccountDetails: {
		accountNumber: "",
		accountName: "",
		accountBsb: "",
		bankName: "",
		abn: "",
		superannuationAccountNo: "",
		superannuationFundName: "",
		taxFileNo: "",
		accountSwiftCode: "",
		accountInstructions: "",
	},
	agencyBankAccountDetails: {
		accountNumber: "",
		accountName: "",
		accountBsb: "",
		bankName: "",
		abn: "",
		superannuationAccountNo: "",
		superannuationFundName: "",
		taxFileNo: "",
		accountSwiftCode: "",
		accountInstructions: "",
	},
	managementBankAccountDetails: {
		accountNumber: "",
		accountName: "",
		accountBsb: "",
		bankName: "",
		abn: "",
		superannuationAccountNo: "",
		superannuationFundName: "",
		taxFileNo: "",
		accountSwiftCode: "",
		accountInstructions: "",
	},
};

export const setFinancialDetailsState = (
	financialDetails: any,
	setFinancialDetails: any
) => {
	const {
		personalBankAccountDetails,
		lenderBankAccountDetails,
		agencyBankAccountDetails,
		managementBankAccountDetails,
	} = financialDetails;

	let personalBankAccountDetailsF = {
		accountNumber: "",
		accountName: "",
		accountBsb: "",
		bankName: "",
		abn: "",
		superannuationAccountNo: "",
		superannuationFundName: "",
		taxFileNo: "",
	};
	if (Boolean(personalBankAccountDetails)) {
		const {
			__typename: __typename1,
			id: id1,
			...restPersonalBankDetails
		} = personalBankAccountDetails;
		personalBankAccountDetailsF = restPersonalBankDetails;
	}
	let lenderBankAccountDetailsF = {
		accountNumber: "",
		accountName: "",
		accountBsb: "",
		bankName: "",
		abn: "",
		superannuationAccountNo: "",
		superannuationFundName: "",
		taxFileNo: "",
	};
	if (Boolean(lenderBankAccountDetails)) {
		const {
			__typename: __typename2,
			id: id2,
			...restLenderBankDetails
		} = lenderBankAccountDetails;
		lenderBankAccountDetailsF = restLenderBankDetails;
	}
	let agencyBankAccountDetailsF = {
		accountNumber: "",
		accountName: "",
		accountBsb: "",
		bankName: "",
		abn: "",
		superannuationAccountNo: "",
		superannuationFundName: "",
		taxFileNo: "",
	};
	if (Boolean(agencyBankAccountDetails)) {
		const {
			__typename: __typename3,
			id: id3,
			...restAgencyBankDetails
		} = agencyBankAccountDetails;
		agencyBankAccountDetailsF = restAgencyBankDetails;
	}
	let managementBankAccountDetailsF = {
		accountNumber: "",
		accountName: "",
		accountBsb: "",
		bankName: "",
		abn: "",
		superannuationAccountNo: "",
		superannuationFundName: "",
		taxFileNo: "",
	};
	if (Boolean(managementBankAccountDetails)) {
		const {
			__typename: __typename4,
			id: id4,
			...restManagementBankDetails
		} = managementBankAccountDetails;
		managementBankAccountDetailsF = restManagementBankDetails;
	}
	setFinancialDetails({
		personalBankAccountDetails: personalBankAccountDetailsF,
		lenderBankAccountDetails: lenderBankAccountDetailsF,
		agencyBankAccountDetails: agencyBankAccountDetailsF,
		managementBankAccountDetails: managementBankAccountDetailsF,
	});
};

export const financialsValidationSchema = Yup.object().shape({
	personalBankAccountDetails: Yup.object().shape({
		accountNumber: Yup.string(),
		accountName: Yup.string(),
		accountBsb: Yup.string(),
		bankName: Yup.string(),
		abn: Yup.string().matches(/^[0-9]{11}$/, "Must be 11 digits"),
		superannuationAccountNo: Yup.string(),
		superannuationFundName: Yup.string(),
		taxFileNo: Yup.string().matches(/^[0-9]{9}$/, "Must be 9 digits"),
	}),
	lenderBankAccountDetails: Yup.object().shape({
		accountNumber: Yup.string(),
		accountName: Yup.string(),
		accountBsb: Yup.string(),
		bankName: Yup.string(),
		abn: Yup.string().matches(/^[0-9]{11}$/, "Must be 11 digits"),
		superannuationAccountNo: Yup.string(),
		superannuationFundName: Yup.string(),
		taxFileNo: Yup.string().matches(/^[0-9]{9}$/, "Must be 9 digits"),
	}),
	agencyBankAccountDetails: Yup.object().shape({
		accountNumber: Yup.string(),
		accountName: Yup.string(),
		accountBsb: Yup.string(),
		bankName: Yup.string(),
		abn: Yup.string().matches(/^[0-9]{11}$/, "Must be 11 digits"),
		superannuationAccountNo: Yup.string(),
		superannuationFundName: Yup.string(),
		taxFileNo: Yup.string().matches(/^[0-9]{9}$/, "Must be 9 digits"),
	}),
	managementBankAccountDetails: Yup.object().shape({
		accountNumber: Yup.string(),
		accountName: Yup.string(),
		accountBsb: Yup.string(),
		bankName: Yup.string(),
		abn: Yup.string().matches(/^[0-9]{11}$/, "Must be 11 digits"),
		superannuationAccountNo: Yup.string(),
		superannuationFundName: Yup.string(),
		taxFileNo: Yup.string().matches(/^[0-9]{9}$/, "Must be 9 digits"),
	}),
});
