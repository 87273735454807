import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Box, Dialog, Grid, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { DELETE_INDEX, DELETE_INDEX_ITEM } from "graphql/indexes";
import { IModalProps } from "models/common";
import { useStore } from "utils/store";
import { NewOutlinedBtn, NewRedAlertContainedButton } from "common/Buttons";
import { images } from "assets/images";
import { colors } from "theme/colors";

interface IDeleteIndexItemModalProps extends IModalProps {
  selectedIndexItem: string;
  setSelectedIndexItem: Dispatch<SetStateAction<string>>;
  selectedIndexItemName: string;
  setSelectedIndexItemName: Dispatch<SetStateAction<string>>;
  refetchTableData: any;
  nestedIdArr: string[];
  isRegisterItem: boolean;
  setIsRegisterItem: Dispatch<SetStateAction<boolean>>;
}

const DeleteIndexItemModal = (props: IDeleteIndexItemModalProps) => {
  const {
    open,
    setOpen,
    selectedIndexItem,
    setSelectedIndexItem,
    selectedIndexItemName,
    setSelectedIndexItemName,
    refetchTableData,
    nestedIdArr,
    isRegisterItem,
    setIsRegisterItem,
  } = props;

  const params = useParams();
  const { setLoading } = useStore();

  const handleClose = () => {
    setSelectedIndexItem("");
    setSelectedIndexItemName("");
    setIsRegisterItem(false);
    setOpen(false);
  };

  const [fireDeleteIndexItemApi, { loading }] = useMutation(DELETE_INDEX_ITEM, {
    onCompleted: (data) => {
      setLoading(false);
      const { deleteRegisterItems } = data;
      const { status, message } = deleteRegisterItems;
      if (status) {
        refetchTableData();
        toast.success(message, { delay: 10 });
      } else {
        toast.error(message, { delay: 10 });
      }
      handleClose();
    },
  });

  const [fireDeleteIndexApi, { loading: deletingIndex }] = useMutation(
    DELETE_INDEX,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { deleteRegisters } = data;
        const { status, message } = deleteRegisters;
        if (status) {
          refetchTableData();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
        handleClose();
      },
    }
  );

  const handleDelete = () => {
    if (isRegisterItem) {
      fireDeleteIndexApi({
        variables: {
          payload: { project_id: params?.project_id, ids: [selectedIndexItem] },
        },
      });
    } else {
      fireDeleteIndexItemApi({
        variables: {
          payload: {
            project_id: params?.project_id,
            register_id: nestedIdArr[nestedIdArr.length - 1],
            ids: [selectedIndexItem],
          },
        },
      });
    }
  };

  useEffect(() => {
    setLoading(deletingIndex || loading);
  }, [deletingIndex, loading, setLoading]);

  const AlertImage = () => (
    <img src={images.ALERT_NEW} alt="alert" height={22} />
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "24px", p: 3, width: "465px" } }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={images.ALERT_NEW}
              sx={{ backgroundColor: "#ED1C2420" }}
              slots={{ img: AlertImage }}
            />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              color: colors.primaryBlack,
              textAlign: "center",
            }}
          >
            {`Delete ${selectedIndexItemName}?`}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{ fontSize: "13px", textAlign: "center", color: "#00000080" }}
          >
            Once <span style={{ fontWeight: 600 }}>deleted</span>, the{" "}
            {isRegisterItem ? "document folder" : "document item"} cannot be
            recovered.
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <NewOutlinedBtn
                variant="outlined"
                sx={{
                  color: colors.primaryBlack,
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleClose}
              >
                Cancel
              </NewOutlinedBtn>
            </Box>
            <Box sx={{ ml: 2 }}>
              <NewRedAlertContainedButton
                sx={{
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleDelete}
              >
                Delete
              </NewRedAlertContainedButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteIndexItemModal;
