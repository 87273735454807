import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";

import { ICreateContactFieldsNew } from "models/contacts";
import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import PhoneNumberInput from "common/PhoneNumberInput";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface IEmergencyFormProps {
  formikBag: FormikProps<ICreateContactFieldsNew>;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

function EmergencyForm(props: IEmergencyFormProps) {
  const { formikBag, setActiveSection } = props;
  const { values, handleChange, setFieldValue, handleBlur, touched, errors } =
    formikBag;

  const isValidArrStep1 = (errors: any) => {
    const basicErrs: any = errors;
    return ["next_of_kin_contact_number"].filter((x: string) => basicErrs[x]);
  };

  return (
    <Box
      sx={{
        pt: { xs: 2, sm: 14 },
        px: 4,
        pb: 1.5,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <Typography sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}>
            Emergency & Medical Details
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>
            Emergency Contact Full Name
          </Typography>
          <TextField
            id="next_of_kin_name"
            value={values.next_of_kin_name}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>
            Emergency Contact Phone Number
          </Typography>
          <PhoneNumberInput
            value={values.next_of_kin_contact_number.nationalNumber}
            onChange={(e) => {
              setFieldValue(
                "next_of_kin_contact_number.nationalNumber",
                e.target.value
              );
            }}
            onBlur={handleBlur("next_of_kin_contact_number.nationalNumber")}
            countryCode={values.next_of_kin_contact_number.countryCode}
            setCountryCode={(code: string) =>
              setFieldValue("next_of_kin_contact_number.countryCode", code)
            }
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>
            Emergency Contact Relationship
          </Typography>
          <TextField
            id="next_of_kin_relationship"
            value={values.next_of_kin_relationship}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Food Allergies</Typography>
          <TextField
            id="food_allergies"
            value={values.food_allergies}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Medical Allergies</Typography>
          <TextField
            id="medical_allergies"
            value={values.medical_allergies}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
            <OutlinedBtn
              size="small"
              sx={{
                borderRadius: "20px",
                fontSize: "13px",
                fontWeight: 600,
                width: "120px",
                color: "#000",
              }}
              disableElevation
              disableRipple
              onClick={() => setActiveSection(3)}
            >
              Back
            </OutlinedBtn>
            <BlackActionContainedButton
              variant="contained"
              size="small"
              sx={{
                borderRadius: "20px",
                ml: 1,
                fontSize: "13px",
                fontWeight: 600,
                width: "120px",
              }}
              disableElevation
              disableRipple
              disabled={isValidArrStep1(errors).length > 0}
              onClick={() => setActiveSection(5)}
            >
              Next
            </BlackActionContainedButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EmergencyForm;
