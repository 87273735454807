import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid } from "@mui/material";

import { IUpdateContactFieldsNew } from "models/contacts";

import ManagementLeftSteps from "./ManagementLeftSteps";
import ManagementDetailsForm from "./ManagementDetailsForm";
import RegistrationForm from "./RegistrationForm";
import BankDetailsForm from "./BankDetailsForm";

interface IManagementFormProps {
  selectedContact: string;
  setActiveStep: Dispatch<SetStateAction<number>>;
  activeSection: number;
  setActiveSection: Dispatch<SetStateAction<number>>;
  setPrevActiveSection: Dispatch<SetStateAction<number>>;
  handleClose: () => void;
  initialValues: IUpdateContactFieldsNew;
  updateContactApi: any;
  refetch: any;
}

function ManagementForm(props: IManagementFormProps) {
  const {
    setActiveStep,
    activeSection,
    setActiveSection,
    setPrevActiveSection,
    handleClose,
    initialValues,
    updateContactApi,
    refetch,
  } = props;

  return (
    <Box sx={{ height: "100%" }}>
      <Grid container>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={3}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Box sx={{ pt: 3 }}>
            <ManagementLeftSteps
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
          </Box>
        </Grid>
        <Grid item lg={9} md={9} sm={9} xs={12}>
          <Box
            sx={{
              borderLeft: { xs: "none", sm: "1.5px #e6e6e6 solid" },
              height: { xs: "546.02px", sm: "696.02px" },
            }}
          >
            {activeSection === 0 && (
              <ManagementDetailsForm
                initialValues={initialValues}
                updateContactApi={updateContactApi}
                refetch={refetch}
                setActiveSection={setActiveSection}
                setActiveStep={setActiveStep}
                setPrevActiveSection={setPrevActiveSection}
              />
            )}
            {activeSection === 1 && (
              <RegistrationForm
                initialValues={initialValues}
                updateContactApi={updateContactApi}
                refetch={refetch}
                setActiveSection={setActiveSection}
              />
            )}
            {activeSection === 2 && (
              <BankDetailsForm
                initialValues={initialValues}
                updateContactApi={updateContactApi}
                refetch={refetch}
                setActiveSection={setActiveSection}
                handleClose={handleClose}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ManagementForm;
