import React, { useState } from "react";
import {
	Box,
	InputAdornment,
	Menu,
	MenuItem,
	TextField,
	TextFieldProps,
	Typography,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

import { CountryCodeArr } from "utils/constants";
import { colors } from "theme/colors";

function PhoneNumberInput(
	props: TextFieldProps & {
		backgroundColor?: string;
		countryCode: string;
		setCountryCode: any;
	}
) {
	const {
		backgroundColor = "#e6e6e640",
		countryCode,
		setCountryCode,
		...restProps
	} = props;

	const [openCountryMenu, setOpenCountryMenu] = useState(false);
	const [countryMenuAnchor, setCountryMenuAnchor] =
		useState<HTMLElement | null>(null);

	const handleCountryClick = (e: React.MouseEvent<HTMLElement>) => {
		setCountryMenuAnchor(e.currentTarget);
		setOpenCountryMenu(true);
	};

	const handleCountryMenuClose = () => {
		setCountryMenuAnchor(null);
		setOpenCountryMenu(false);
	};

	// const getDigitOnlyPhone = (value: any) =>
	//   value.toString().replace(/\D/g, "").substring(0, 10);

	// const getFormattedPhone = (value: any) => {
	//   if (!value) return "";

	//   const phone = getDigitOnlyPhone(value);
	//   const areaCode = phone.substring(0, 4);
	//   const middle = phone.substring(4, 7);
	//   const last = phone.substring(7, 10);

	//   let formattedPhone = "";
	//   if (phone.length > 7) {
	//     formattedPhone = `${areaCode} ${middle} ${last}`;
	//   } else if (phone.length > 4) {
	//     formattedPhone = `${areaCode} ${middle}`;
	//   } else if (phone.length > 0) {
	//     formattedPhone = `${areaCode}`;
	//   }

	//   return formattedPhone;
	// };

	return (
		<TextField
			{...restProps}
			value={props.value}
			onChange={(e) => {
				if (!isNaN(e.target.value as unknown as number)) {
					props.onChange && props.onChange(e);
				}
			}}
			size="small"
			// placeholder="0444 444 444"
			fullWidth
			inputProps={{
				style: {
					backgroundColor: backgroundColor,
					borderRadius: "6px",
					fontSize: "13px",
					color: colors.primaryBlack,
					paddingLeft: "8px",
					borderTopLeftRadius: "0px",
					borderBottomLeftRadius: "0px",
				},
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start" sx={{ mr: 0 }}>
						<Box
							sx={{
								backgroundColor: backgroundColor,
								borderTopLeftRadius: "6px",
								borderBottomLeftRadius: "6px",
								py: "3px",
								pl: 2,
								ml: -1.75,
								display: "flex",
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={handleCountryClick}
						>
							<img
								src={
									CountryCodeArr.find((x) => x.id === countryCode)
										?.flag
								}
								alt="flag"
								height={30}
							/>
							<Typography sx={{ ml: 1, fontSize: "13px" }}>
								{CountryCodeArr.find((x) => x.id === countryCode)?.name}
							</Typography>
							<ArrowDropDown fontSize="small" />
						</Box>
						<Menu
							anchorEl={countryMenuAnchor}
							open={openCountryMenu}
							onClose={handleCountryMenuClose}
							sx={{
								"& .MuiMenu-paper": {
									borderRadius: "4px",
								},
							}}
							elevation={1}
						>
							{CountryCodeArr.map((code) => (
								<MenuItem
									key={code.id}
									onClick={() => {
										setCountryCode(code.id);
										handleCountryMenuClose();
									}}
									sx={{ display: "flex", alignItems: "center" }}
								>
									<img src={code.flag} alt="flag" height={30} />
									<Typography sx={{ ml: 1, fontSize: "13px" }}>
										{code.name}
									</Typography>
								</MenuItem>
							))}
						</Menu>
					</InputAdornment>
				),
			}}
		/>
	);
}

export default PhoneNumberInput;
