import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import { images } from "assets/images";

import { AdminDashboardStyles as styles } from "./styles";
import { ITypeCount } from ".";

const imageMap: any = {
  Feature: images.NDA,
  "TV Series": images.E_CONTRACTS,
  Telemovie: images.VARIETY_SHOW_ICON,
  Documentary: images.VERBAL_CONTRACTS,
  TVC: images.VOID_CONTRACTS,
  Other: images.VARIETY_SHOW_ICON,
};

interface Props {
  stats: ITypeCount[];
}
function ProjectTypeVsCount(props: Props) {
  const { stats } = props;

  return (
    <Grid container spacing={3}>
      {stats.map((stat, i) => (
        <Grid
          key={i + 1}
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={[styles.flex_Acenter]}>
            <img src={imageMap[stat.type]} alt="stat-icon" />
            <Typography sx={[styles.project_type_text, { ml: 1 }]}>
              {stat.type}
            </Typography>
          </Box>
          <Typography sx={[styles.project_type_text]}>{stat.count}</Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export default ProjectTypeVsCount;
