import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useQuery } from "@apollo/client";

import Drawer from "common/ClientDrawer";
import { IProject } from "models/projects";
import { GET_DASHBOARD_PROJECTS } from "graphql/projects";
import { useStore } from "utils/store";
import { IPaginationModel } from "models/common";
import { NewGreenPrimaryContainedButton } from "common/Buttons";
import { RouteNames } from "routes/routeNames";
import theme from "theme/muiTheming";
import { useProjectCreateModal } from "hooks/useCreateProjectModal";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import RecentProjects from "./RecentProjects";
import RecentActivities from "./RecentActivities";
import KeyProjectDates from "./KeyProjectDates";

export const Dashboard = () => {
  const { setLoading } = useStore();
  const matchesXs = useMediaQuery(theme.breakpoints.only("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.only("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.only("md"));

  const [recentProjects, setRecentProjects] = useState<IProject[]>([]);
  const [paginationModel, setPaginationModel] = useState<IPaginationModel>({
    page: 0,
    pageSize: 4,
  });
  const [count, setCount] = useState(0);

  const navigate = useNavigate();
  const projectCreateModal = useProjectCreateModal();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const dashboardIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("DASHBOARD")
      ),
    [ROLE_PERMISSIONS]
  );
  const projectEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("PROJECT_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );
  const settingsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("SETTINGS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const settingsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("SETTINGS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const { loading } = useQuery(GET_DASHBOARD_PROJECTS, {
    variables: {
      limit:
        matchesMd || matchesSm ? 2 : matchesXs ? 1 : paginationModel.pageSize,
      page: paginationModel.page + 1,
      sorted: "name",
    },
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      dashboardIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[dashboardIndex]?.DASHBOARD
    ),
    onCompleted: (data) => {
      setLoading(false);
      const { projects: rawProjectsResponse } = data;
      const { status, projects: rawProjects, count } = rawProjectsResponse;
      if (status) {
        setCount(count);
        setRecentProjects(rawProjects);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Drawer>
      {!loading && recentProjects.length === 0 ? (
        ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]?.PROJECT_EDIT ? (
          <Box
            sx={{
              height: `calc(100vh - 125px)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                minHeight: "300px",
                minWidth: { xs: "100%", sm: "650px" },
                p: 2,
                backgroundColor: "#BFBDBC22",
                borderRadius: " 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: " column",
              }}
            >
              <img
                src={images.CREATE_PROJECT}
                alt="CREATE PROJECT"
                style={{ width: 25 }}
              />
              <Typography
                sx={{
                  my: 2,
                  fontSize: "30px",
                  color: colors.primaryBlack,
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Create Your First Project
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: colors.primaryBlack,
                  textAlign: "center",
                }}
              >
                Your dashboard is empty! Get started with your first project.
              </Typography>
              <NewGreenPrimaryContainedButton
                sx={{
                  width: 260,
                  fontSize: "14px",
                  mt: 5,
                  borderRadius: "24px",
                  py: 1.25,
                }}
                onClick={() => {
                  projectCreateModal.onOpen();
                  navigate(RouteNames.PROJECTS);
                }}
              >
                New Project
              </NewGreenPrimaryContainedButton>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              height: `calc(100vh - 115px)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "200px",
                width: "550px",
                backgroundColor: "#BFBDBC22",
                borderRadius: " 24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: " column",
              }}
            >
              <img
                src={images.PROJECTS_SELECTED}
                alt="agent"
                style={{ width: 25 }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  color: colors.primaryBlack,
                  my: 2,
                  fontWeight: 600,
                }}
              >
                Its empty here!
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: `${colors.primaryBlack}70`,
                }}
              >
                Please reach out to your admin for access to create a project.
              </Typography>
            </Box>
          </Box>
        )
      ) : (
        <></>
      )}
      {!loading && recentProjects.length > 0 && (
        <Grid container spacing={8}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <RecentProjects
              recentProjects={recentProjects}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              count={count}
            />
          </Grid>
          {Boolean(ROLE_PERMISSIONS) &&
          settingsEditIndex !== undefined &&
          settingsViewIndex !== undefined &&
          (ROLE_PERMISSIONS[0]?.permissions[settingsViewIndex]?.SETTINGS_VIEW ||
            ROLE_PERMISSIONS[0]?.permissions[settingsEditIndex]
              ?.SETTINGS_EDIT) ? (
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mx: 5 }}>
              <Grid container spacing={8}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <RecentActivities />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <KeyProjectDates recentProjects={recentProjects} />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mx: 5 }}>
              <Grid container spacing={8}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <KeyProjectDates recentProjects={recentProjects} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Drawer>
  );
};
