import React, {
	Dispatch,
	SetStateAction,
	forwardRef,
	useEffect,
	useState,
} from "react";
import {
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { useQuery } from "@apollo/client";

import { IArr, IModalProps } from "models/common";
import { useTemplates } from "hooks/useTemplates";
import { GET_RESOLVERS } from "graphql/meta";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import { IFillableFieldsT } from ".";

const styles = {
	flex_JCsb_Acenter: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	modal_header_text: {
		color: colors.primaryBlack,
		fontSize: "20px",
		fontWeight: 500,
	},
	field_label: {
		color: colors.grey,
		fontSize: "14px",
		fontWeight: 500,
		mb: 0.75,
	},
	text_input: { fontSize: "14px", color: colors.primaryBlack },
	error_text: { fontSize: "12px", color: "#D3010E" },
	disabled_btn: {
		"&.Mui-disabled": {
			backgroundColor: colors.grey,
			color: "#c0c0c0",
		},
	},
	modal_footer_container: { p: 2, backgroundColor: `${colors.primary}20` },
};

interface IResolver {
	id: string;
	name: string;
	multiple: boolean;
	fieldType: string;
	resolveTo: string;
}

const fieldTypeArrInit = [
	{ id: "text", name: "Text" },
	{ id: "number", name: "Number" },
	{ id: "percentage", name: "Percentage" },
	{ id: "date", name: "Date" },
	{ id: "currency", name: "Currency" },
	// { id: "fees", name: "Fee Calculator" },
	{ id: "sign", name: "Signature" },
];

const fieldTypeArrRes = [
	{ id: "text", name: "Text" },
	{ id: "number", name: "Number" },
	{ id: "percentage", name: "Percentage" },
	{ id: "date", name: "Date" },
	{ id: "image", name: "Image" },
	{ id: "currency", name: "Currency" },
	// { id: "fees", name: "Fee Calculator" },
	{ id: "sign", name: "Signature" },
	{ id: "table", name: "Table" },
];

interface IFillableFieldsAddEdit extends IFillableFieldsT {}

interface IFillableFieldModalProps extends IModalProps {
	editFillableFieldData: IFillableFieldsT | null;
	setEditFillableFieldData: Dispatch<SetStateAction<IFillableFieldsT | null>>;
}

const FillableFieldModal = forwardRef(
	(props: IFillableFieldModalProps, ref: any) => {
		const { open, setOpen, editFillableFieldData, setEditFillableFieldData } =
			props;

		const { setLoading } = useStore();
		const { fillableFields, setFillableFields } = useTemplates();

		const [initialValues, setInitialValues] = useState<IFillableFieldsT>({
			id: "",
			fieldName: "",
			fieldDescription: "",
			fieldType: "",
			fieldValue: "",
			fieldResolution: "",
			fieldMultiple: false,
			resolveTo: "",
		});
		const [resolvers, setResolvers] = useState<IResolver[]>([]);
		const [fieldTypeArr, setFieldTypeArr] =
			useState<IArr[]>(fieldTypeArrInit);

		const { loading } = useQuery(GET_RESOLVERS, {
			onCompleted: (data) => {
				const { config: outerConfig } = data;
				const { config } = outerConfig;
				const { value } = config;
				let jsonValues = JSON.parse(value);
				setResolvers(
					jsonValues.map((j: any) => {
						return {
							id: j.id,
							name: j.display_name,
							multiple: j.is_multiple,
							fieldType: j.field_type,
							resolveTo: j.resolve_to,
						};
					})
				);
			},
			fetchPolicy: "network-only",
			nextFetchPolicy: "network-only",
		});

		const handleClose = () => {
			setEditFillableFieldData(null);
			setOpen(false);
		};

		const handleDialogClose = (_: any, reason: string) => {
			if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
				handleClose();
			}
		};

		const handleSaveField = (values: IFillableFieldsAddEdit) => {
			if (Boolean(editFillableFieldData)) {
				let tempFields = [...fillableFields];
				const index = tempFields.findIndex(
					(x) => x.id === editFillableFieldData?.id!
				);
				tempFields[index] = { ...values };
				setFillableFields([...tempFields]);
				const element = ref.current.dom.get(editFillableFieldData?.id!);
				element.innerText = `{*${values.fieldName.toLowerCase()}*}`;
			} else {
				const id = uuid();
				if (values.fieldType === "sign") {
					ref.current.insertContent(
						`<table style="width:250px; height:50px; table-layout:fixed"><tr><td id=${id} style='border-width:0px; vertical-align:center; text-align:center; color:transparent; word-wrap: break-word; font-size:2px'>{*${values.fieldName.toLowerCase()}*}</td></tr></table>`
					);
				} else if (values.fieldType === "image") {
					ref.current.insertContent(
						`<img src="https://cdn.icon-icons.com/icons2/3446/PNG/512/account_profile_user_avatar_icon_219236.png" id=${id} height="100" width="100">`
					);
				} else if (values.fieldType === "table") {
					ref.current.insertContent(
						`<table id=${id} style="table-layout:fixed; border:1px black solid; min-width:300px">
							<tr>
								<td style='border:1px black solid; vertical-align:center; text-align:center; word-wrap: break-word; font-family:arial; font-size:10pt; font-weight:bold;'>Rights</td>
								<td style='border:1px black solid; vertical-align:center; text-align:center; word-wrap: break-word; font-family:arial; font-size:10pt; font-weight:bold;'>Loading %</td>
							</tr>
							<tr>
								<td style='border:1px black solid; vertical-align:center; word-wrap: break-word; font-family:arial; font-size:10pt;'></td>
								<td style='border:1px black solid; vertical-align:center; word-wrap: break-word; font-family:arial; font-size:10pt;'></td>
							</tr>
							<tr>
								<td style='border:1px black solid; vertical-align:center; word-wrap: break-word; font-family:arial; font-size:10pt;'></td>
								<td style='border:1px black solid; vertical-align:center; word-wrap: break-word; font-family:arial; font-size:10pt;'></td>
							</tr>
						</table>`
					);
				} else {
					ref.current.insertContent(
						`<span id=${id}>{*${values.fieldName.toLowerCase()}*}</span>`
					);
				}
				const tempFields = [...fillableFields];
				tempFields.push({ ...values, id: id });
				setFillableFields([...tempFields]);
			}
			handleClose();
		};

		useEffect(() => {
			if (Boolean(editFillableFieldData)) {
				setInitialValues(editFillableFieldData!);
			}
		}, [editFillableFieldData]);

		useEffect(() => {
			setLoading(loading);
		}, [loading, setLoading]);

		return (
			<Formik
				initialValues={initialValues}
				validationSchema={Yup.object().shape({
					fieldName: Yup.string().required("Please enter the name"),
					fieldDescription: Yup.string().required(
						"Please enter the description"
					),
					fieldType: Yup.string().required("Please select a type"),
				})}
				onSubmit={handleSaveField}
				enableReinitialize
			>
				{({
					values,
					handleChange,
					setFieldValue,
					handleBlur,
					errors,
					touched,
					isValid,
					handleSubmit,
				}) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Dialog
								open={open}
								onClose={handleDialogClose}
								fullWidth
								maxWidth="sm"
							>
								<DialogTitle>
									<Box sx={styles.flex_JCsb_Acenter}>
										<Typography sx={styles.modal_header_text}>
											{Boolean(editFillableFieldData)
												? "Edit Field"
												: "Add Field"}
										</Typography>
										<IconButton onClick={handleClose}>
											<Clear />
										</IconButton>
									</Box>
								</DialogTitle>
								<DialogContent dividers>
									<Grid container spacing={2}>
										{/* <Fragment>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography sx={styles.field_label}>Field</Typography>
                        <Autocomplete
                          id="id"
                          value={
                            fillableFields.find((x) => x.id === values.id) || {
                              id: "",
                              fieldName: "",
                              fieldDescription: "",
                              fieldType: "",
                              fieldValue: "",
                            }
                          }
                          onChange={(_, newValue) => {
                            if (Boolean(newValue)) {
                              setFieldValue("addNew", false);
                              setFieldValue("id", newValue?.id);
                              setFieldValue("fieldName", newValue?.fieldName);
                              setFieldValue(
                                "fieldDescription",
                                newValue?.fieldDescription
                              );
                              setFieldValue("fieldType", newValue?.fieldType);
                              setFieldValue("fieldValue", newValue?.fieldValue);
                            } else {
                              setFieldValue("id", "");
                              setFieldValue("fieldName", "");
                              setFieldValue("fieldDescription", "");
                              setFieldValue("fieldType", "");
                              setFieldValue("fieldValue", "");
                            }
                          }}
                          onBlur={handleBlur}
                          size="small"
                          options={fillableFields}
                          getOptionLabel={(option) => option.fieldName}
                          sx={styles.text_input}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                style: styles.text_input,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Divider textAlign="center">OR</Divider>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setFieldValue("addNew", true);
                            setFieldValue("id", "");
                            setFieldValue("fieldName", "");
                            setFieldValue("fieldDescription", "");
                            setFieldValue("fieldType", "");
                            setFieldValue("fieldValue", "");
                          }}
                        >
                          Add New
                        </Button>
                      </Grid>
                    </Fragment> */}
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Typography sx={styles.field_label}>
												Field name{" "}
												<span style={{ color: "#D3010E" }}>*</span>
											</Typography>
											<TextField
												id="fieldName"
												value={values.fieldName}
												onChange={(e) => {
													setFieldValue(
														"fieldName",
														e.target.value
													);
												}}
												onBlur={handleBlur}
												size="small"
												fullWidth
												inputProps={{ style: styles.text_input }}
											/>
											{touched?.fieldName && errors?.fieldName && (
												<Typography sx={styles.error_text}>
													{errors.fieldName}
												</Typography>
											)}
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Typography sx={styles.field_label}>
												Field description{" "}
												<span style={{ color: "#D3010E" }}>*</span>
											</Typography>
											<TextField
												id="fieldDescription"
												value={values.fieldDescription}
												onChange={handleChange}
												onBlur={handleBlur}
												size="small"
												fullWidth
												inputProps={{ style: styles.text_input }}
											/>
											{touched?.fieldDescription &&
												errors?.fieldDescription && (
													<Typography sx={styles.error_text}>
														{errors.fieldDescription}
													</Typography>
												)}
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Typography sx={styles.field_label}>
												Field resolution
											</Typography>
											<Autocomplete
												id="fieldResolution"
												value={
													resolvers.find(
														(x) =>
															`${x.id}${x.resolveTo}` ===
															`${values.fieldResolution}${values.resolveTo}`
													) || {
														id: "",
														name: "",
														multiple: false,
														fieldType: "",
														resolveTo: "",
													}
												}
												onChange={(_, newValue) => {
													setFieldTypeArr(fieldTypeArrRes);
													setFieldValue(
														"fieldResolution",
														newValue.id
													);
													setFieldValue(
														"fieldMultiple",
														newValue.multiple
													);
													setFieldValue(
														"fieldType",
														newValue.fieldType
													);
													setFieldValue(
														"resolveTo",
														newValue.resolveTo
													);
												}}
												onBlur={handleBlur}
												disableClearable
												size="small"
												options={resolvers}
												getOptionLabel={(option) => option.name}
												sx={styles.text_input}
												renderInput={(params) => (
													<TextField
														{...params}
														size="small"
														fullWidth
														inputProps={{
															...params.inputProps,
															style: styles.text_input,
														}}
													/>
												)}
											/>
											{touched?.fieldResolution &&
												errors?.fieldResolution && (
													<Typography sx={styles.error_text}>
														{errors.fieldResolution}
													</Typography>
												)}
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Typography sx={styles.field_label}>
												Field type{" "}
												<span style={{ color: "#D3010E" }}>*</span>
											</Typography>
											<Autocomplete
												id="fieldType"
												value={
													fieldTypeArr.find(
														(x) => x.id === values.fieldType
													) || {
														id: "",
														name: "",
													}
												}
												onChange={(_, newValue) =>
													setFieldValue("fieldType", newValue.id)
												}
												disabled={Boolean(values.fieldResolution)}
												onBlur={handleBlur}
												disableClearable
												size="small"
												options={
													values.fieldResolution
														? fieldTypeArrRes
														: fieldTypeArr
												}
												getOptionLabel={(option) => option.name}
												sx={styles.text_input}
												renderInput={(params) => (
													<TextField
														{...params}
														size="small"
														fullWidth
														inputProps={{
															...params.inputProps,
															style: styles.text_input,
														}}
													/>
												)}
											/>
											{touched?.fieldType && errors?.fieldType && (
												<Typography sx={styles.error_text}>
													{errors.fieldType}
												</Typography>
											)}
										</Grid>
										{/* {!values.addNew && Object.keys(errors).length > 0 && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography sx={styles.error_text}>
                          Please select a field or add a new field
                        </Typography>
                      </Grid>
                    )} */}
									</Grid>
								</DialogContent>
								<DialogActions sx={styles.modal_footer_container}>
									<Button
										type="submit"
										variant="contained"
										sx={styles.disabled_btn}
										onClick={() => handleSubmit()}
										disabled={!isValid}
									>
										Save
									</Button>
								</DialogActions>
							</Dialog>
						</Form>
					);
				}}
			</Formik>
		);
	}
);

export default FillableFieldModal;
