import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

import { StorageConstants } from "utils/storage";
import { useStore } from "utils/store";
import { VERIFY_TWO_FA } from "graphql/users";
import { RouteNames } from "routes/routeNames";
import theme from "theme/muiTheming";
import { images } from "assets/images";
import { colors } from "theme/colors";

import ConfirmModal from "./ConfirmModal";
import { TwoFAStyles as styles } from "./styles";
import "./styles.css";

const TwoFAForm = () => {
	const { setLoading } = useStore();
	const navigate = useNavigate();
	const matchesXs = useMediaQuery(theme.breakpoints.down("sm"));

	const [otp, setOtp] = useState("");
	const [confirmModal, setConfirmModal] = useState(false);

	const [verifyTwoFA, { loading }] = useMutation(VERIFY_TWO_FA, {
		onCompleted: (data) => {
			setLoading(false);
			const { twoFA } = data;
			const { status, token, permissions } = twoFA;
			if (status.status) {
				sessionStorage.setItem(StorageConstants.TOKEN, token);
				sessionStorage.setItem(
					StorageConstants.ACCESS_PERMISSIONS,
					permissions
				);
				navigate(RouteNames.TWO_FA_SUCCESS);
			} else {
				toast.error(status.error, { delay: 10 });
			}
		},
	});

	useEffect(() => {
		setLoading(loading);
	}, [loading, setLoading]);

	useEffect(() => {
		const handleSubmit = () => {
			verifyTwoFA({ variables: { payload: { token: otp } } });
		};
		if (otp.length === 6) {
			handleSubmit();
		}
	}, [otp, otp.length, verifyTwoFA]);

	return (
		<Box
			sx={[
				styles.content_center,
				{
					width: { xs: "100%", sm: "75%", md: "48%" },
					py: { xs: 4, md: 0 },
					overflowY: { xs: "auto", sm: "hidden" },
					px: { xs: 4, sm: 8, md: 0 },
					backgroundColor: { xs: "#fff", md: "none" },
					borderRadius: { xs: "15px", md: 0 },
					mx: { xs: 4, md: 0 },
				},
			]}
		>
			<Grid container spacing={4}>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={styles.content_center}
				>
					<Box sx={[styles.width, styles.content_center]}>
						<img src={images.LOGO} alt="logo" height="30px" />
					</Box>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Grid container spacing={4}>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={styles.content_center}
						>
							<Box sx={[styles.width, styles.content_center]}>
								<Typography
									sx={{
										fontSize: "22px",
										fontWeight: 600,
										color: "#00000080",
										textAlign: "center",
									}}
								>
									Enter Authentication Code
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={styles.content_center}
						>
							<Box sx={[styles.width, styles.content_center]}>
								<Typography
									sx={{
										fontSize: "14px",
										color: "#00000080",
										textAlign: "center",
									}}
								>
									Enter the 6-digit authentication code generated by
									your authentication app
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							xs={12}
							sm={12}
							sx={styles.content_center}
						>
							<Box sx={[styles.width, styles.content_center]}>
								<OtpInput
									shouldAutoFocus
									value={otp}
									onChange={setOtp}
									numInputs={6}
									renderInput={(props) => (
										<input {...props} className="otp-input" />
									)}
									containerStyle={styles.otp_container}
									inputStyle={
										matchesXs
											? {
													fontSize: "30px",
													color: colors.primaryBlack,
													fontWeight: 600,
													border: `1px solid ${colors.borderColor}`,
													borderRadius: "8px",
													padding: 5,
													marginLeft: 4,
													marginRight: 4,
													width: 35,
											  }
											: styles.otp_input
									}
								/>
							</Box>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							xs={12}
							sm={12}
							sx={styles.content_center}
						>
							<Box sx={[styles.change_device_container, { mt: -3 }]}>
								<Box
									sx={{ cursor: "pointer" }}
									onClick={() => {
										setConfirmModal(true);
									}}
								>
									<Typography sx={styles.link_text}>
										Change 2FA Device
									</Typography>
								</Box>
							</Box>
						</Grid>
						{confirmModal && (
							<ConfirmModal
								open={confirmModal}
								setOpen={setConfirmModal}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default TwoFAForm;
