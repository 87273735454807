export const GlobalLabels = {
  OR: "OR",
  NEXT: "Next",
  SUCCESS: "Success!",
  FAIL: "Failed!",
  TRY_AGAIN: "Try Again",
  SHOW: "Show",
  GO_BACK: "Go back",
  VERIFY: "Verify",
  CONTINUE: "Continue",
  AND: "and",
  YES: "Yes",
  NO: "No",
  EDIT: "Edit",
  DELETE: "Delete",
  STATUS: "Status",
  EXPORT: "Export",
  VIEW: "View",
  SHARE: "Share",
  CLEAR: "Clear",
  CANCEL: "Cancel",
  INFO: "Information",
  APPROVE: "Approve",
  REJECT: "Reject",
  CREATE: "Create",
  ADDRESS: "Address",
  STREET: "Street",
  TOWN: "Town",
  CITY: "City",
  STATE: "State",
  COUNTRY: "Country",
  SUBURB: "Suburb",
  PINCODE: "Post Code",
  ENABLE: "Enable",
  DISABLE: "Disable",
  FILTER: "Filter",
  CLOSE: "Close",
  REMINDER: "Reminder",
  SEARCH: "Search",
  UPDATE: "Update",
  SAVE_CHANGES: "Save Changes",
  INVITE: "Invite",
  BACK: "Back",
  DONE: "Done",
  ADD: "Add",
  UPLOAD: "Upload",
  DOWNLOAD: "Download",
};

export const LoginLabels = {
  LOGIN: "Login",
  EMAIL: "Email Id",
  PWD: "Password",
  FORGOT_PWD: "Forgot your password?",
  GOOGLE: "Google",
  MICROSOFT: "Microsoft",
  NO_ACCOUNT: "Don’t have an account?",
  SIGN_UP: "Sign up",
  LOGOUT: "Logout",
  LOGIN_TO_ACC: "Login to your account",
};

export const TwoFALabels = {
  SCAN_INST: "Open Google Authenticator App and scan this QR code.",
  MANUAL_CODE_INST: "Or enter the following code manually:",
  CODE_ENTER_INST:
    "Enter the six-digit code from your Google Authenticator App.",
};

export const SignUpLabels = {
  ABOUT_CMP: {
    NAME: "Company Name",
    ABN_CODE: "ABN",
    SITE: "Company Website (optional)",
    NO_OF_EMP: "Number of Employees",
    EMP_CNCT: "Employees Managing Contracts",
    INDUSTRY: "Industry",
  },
  ABOUT_YOU: {
    NAME: "Full name",
    EMAIL: "Email Id",
    MOBILE: "Contact Number",
    DESG: "Designation",
  },
  SET_PWD: {
    PWD: "Enter Password",
    CONFIRM_PWD: "Re-type Password",
    STRENGTH: "Strength",
  },
  CONDITIONS: {
    TWO_FA: "Enable Two-factor authentication.",
    AGREE: "I agree to FINKA's",
    TC: "Terms & Condition",
    PRIVACY: "Privacy Policy",
  },
};

export const ForgotPwdLabels = {
  EMAIL: "Email Id",
  FORGOT_PWD: "Forgot your password?",
  VERIFY_CODE: "Verification Code",
  SENT_TO_EMAIL: "We have to sent the code verification to your email",
};

export const ResetPwdLabels = {
  RESET_PWD: "Reset your password",
  NEW_PWD: "New Password",
  CONFIRM_PWD: "Confirm New Password",
  SUCCESS: "Password Changed!",
};

export const SetPasswordLabels = {
  SET_PWD: "Set Password",
  NEW_PWD: "New Password",
  CONFIRM_PWD: "Confirm New Password",
};

export const ClientDashboardLabels = {
  CONTRACT_CREATED: "Contracts Created",
  TOTAL_CNCT_VALUE: "Contract Value",
  PROJECT_STATUS: "Project Status",
  CALENDAR: "Calendar",
  TEMPLATE_USAGE: "Template Usage",
  RENEWALS: "Renewals",
  PROJ_CATEGORY: "Project Category",
  RECENT_PROJ: "Recent Projects",
  RECENT_ACT: "Recent Activities",
  RECENT_CNCT: "Recent Contracts",
  KEY_PROJ_DATES: "Key Project Dates",
};

export const ClientProjectLabels = {
  ADD: "Add Projects",
  EDIT_PROJ: "Edit Project Details",
  CREATE: "Create Project",
  TOTAL_CNCTS: "Total Contracts",
  CNCT: "Contract",
  DEL_PROJ: "Delete Project",
  ADMINS: "Admins",
  FILTER: {
    CATEGORY: "Category",
    CAT_PLACEHOLDER: "Choose Project Category",
    TYPE: "Type",
    TYPE_PLACEHOLDER: "Choose Project Type",
  },
};

export const ClientProjectDetailsLabels = {
  CNCTS: "Contracts",
  TABS: {
    PROJ_DEETS: "Project Details",
    BASIC_DEETS: "Details",
    COMP_DETAILS: "Company",
    KEY_PERSONNEL: "Key Personnel",
    KEY_PROJECT_DATES: "Project Dates",
    PROJ_CONTACTS: "Contacts",
    CNCTS: "Contracts",
    CLEARNACE: "Clearances",
    DELIVERY: "Delivery",
    REPORT: "Reports",
    PROJ_USERS: "Project Users",
    PROJ_SETTINGS: "Project Settings",
    RIGHTS_BUY: "Rights Buy",
    REGISTERS: "Registers",
    DOC_REPO: "Documents",
    INDEXES: "Indexes",
    ISSUE_DASH: "Issues",
    AGENTS: "Agents",
  },
  CREATE_CNCT: "Create Contracts",
  ADD_CNCT: "Add Contracts",
  UPLOAD_CNCT: "Upload Contracts",
  CNCT_NAME: "Contract Name",
  IN_HOUSE: "In-house",
  THIRD_PARTY: "Third-party",
  PROJ_FORM: {
    PROJ_STATUS: "Projects Status",
    PROJ_NAME: "Project Name",
    PROJ_NUM: "Project Number",
    DESC: "Description",
    CREATE_DATE: "Creation Date",
    EPISODE_NUM: "Episodes",
    EPISODE_DURATION: "Episode Duration",
    PROJ_TYPE: "Project Type",
    PROJ_CATEGORY: "Project Category",
    CAT_DESC: "Category Description",
    CAT_LOADING: "Category Loading",
    CAT_LOADING_ADD: "Category Loading additional inputs%",
    COLLECTIVE_AGREE_CAST: "Collective Agreement Cast",
    COLLECTIVE_AGREE_CREW: "Collective Agreement Crew",
    PROJ_START_DATE: "Project Start Date",
    PROJ_END_DATE: "Project End Date",
    SHOOT_DAYS_NUM: "Shoot Days",
    FILMING_DATE: "Filming Date",
    FILMING_LOCATION: "Filming Location",
    PROJ_MATERIALS: "Project Materials",
  },
  KEY_PER_FORM: {
    DIRECTOR: "Director",
    PRODUCER: "Producer",
    WRITER: "Writer",
    LINE_PRODUCER: "Line Producer",
    EXEC_PRODUCER: "Executive Producer",
    FULL_NAME: "Full Name",
    EMAIL: "Email Id",
  },
  USER_FORM: {
    FULL_NAME: "Full Name",
    ROLE: "Role",
    EMAIL: "Email Id",
    PHONE: "Phone Number",
  },
  SETTINGS_FORM: { CURRENCY: "Currency", DATE_FORMAT: "Date Format" },
  COMP_FORM: {
    COMP_NAME: "Company Name",
    REG_TYPE: "ACN/Company",
    REG_NUM: "ABN",
    PLACE_INCORP: "Place of Incorporation",
    ADR: "Address",
    TOWN: "Town",
    CONTACT_NAME: "Contact Name",
    CONTACT_EMAIL: "Contact Email",
    NAME: "Full Name",
    EMAIL: "Email Id",
    AUTHORIZED_SIGN: "Authorized Signatory",
    SOLE_DIRECTOR: "Sole Director",
    DIRECTORS: "Directors",
  },
  PROJ_DATES_FORM: {
    START_DATE: "Start Date",
    END_DATE: "End Date",
    LOCATION: "Location",
    START_PRE_PROD: "Start of Pre-production",
    START_PRINCIPAL_PHOTO: "Start of Principal Photography",
    START_POST_PROD: "Start of Post-production",
    START_ROUGH_CUT: "Start of Rough Cut",
    START_FINE_CUT: "Start of Fine Cut",
    COMPLETION_DATE: "Completion Date",
    DELIVERY_DATE: "Delivery Date",
  },
  RIGHTS_FORM: {
    RIGHTS_AGG: "Rights in Aggregate",
    RIGHTS_EX_AGG: "Rights ex Aggregate",
    RIGHT_AWARD: "Right Award",
    RIGHT_NAME: "Right Name",
    RIGHT_DESC: "Right Description",
    AGG_INCLUSION: "Aggregate inclusion",
    RIGHT: "Right",
  },
  ADD_USER: "Add User",
  ADD_DATE_LOCATION: "Add date and location",
  CNCT_FILTERS: {
    ARTIST_TYPE: "Artist Type",
    ARTIST_TYPE_PLACEHOLDER: "Choose artist type",
    CNCT_TYPE: "Contract Type",
    CNCT_TYPE_PLACEHOLDER: "Choose contract type",
    STATUS: "Status",
    STATUS_PLACEHOLDER: "Choose status",
    COMPLEXITY: "Complexity",
    COMPLEXITY_PLACEHOLDER: "Choose complexity",
  },
  CNCT_FORM: {
    CNCT_TYPE: "Contract Type",
    CNCT_NAME: "Contract Name",
    COUNTER_PARTY: "Party Name",
    CATEGORY: "Category",
    DEPT: "Departments",
    TEMPLATE: "Template",
    TEMPLATE_TYPE: "Template Type",
    COMPLEXITY: "Complexity",
  },
  DEL_CNCT: "Delete Contract!",
  FILMING_DATE_LOC: "Filming Dates and Location",
  PROJ_DATES: "Project Dates",
  ADD_RIGHT_AWARD: "Add Right Award",
  AGGREGATE: "Aggregate",
  USER: "User",
  REGISTER_LIST: "Register List",
  REGISTER_LIST_NAME: "Register List Name",
  REGISTER_LIST_ITEM: "Register List Item",
};

export const ClientContactLabels = {
  INFO: "Information",
  ADD_CONTACT: "Add Contacts",
  EDIT_CONTACT: "Edit Contact",
  ADD_TEAM: "Add Team",
  PEOPLE: "People",
  TEAM: "Your Team",
  MEMBERS: "Members",
  ADD_MEMBER: "Add Member",
  CREATE_CONTACT: "Create Contact",
  CREATE_TEAM: "Create Team",
  PERSONAL: "Personal",
  LENDER_COMP: "Contractor Company",
  AGENT: "Agent",
  AGENCY: "Agency",
  MANAGER: "Manager",
  MGMT_COMP: "Management Company",
  DETAILS: "Details",
  BANK_ACC_DETAILS: "Bank Account Details",
  CONTACT_FORM: {
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    DEPT: "Department",
    CATEGORY: "Category",
    EMAIL: "Email Id",
    MOBILE: "Contact Number",
    PROJ: "Project",
    PROJ_DEETS: "Project Details",
    ADD_PROJ: "Add Project",
    DEL_PROJ: "Delete Project",
    PROJ_NAME: "Project Name",
    PROJ_ROLE: "Project Role",
    ARTIST_TYPE: "Artist Type",
    CHAR_NAME: "Character Name",
    ARTIST_CLASS: "Artist Class",
    WWC_NO: "WWC Number",
    EQUITY_NO: "Equity Number",
    ACC_NAME: "Account Name",
    BANK_NAME: "Bank Name",
    ACC_NO: "Account Number",
    BSB: "BSB",
    SWIFT: "Bank Account SWIFT Code",
    INSTRUCTIONS: "Bank Account Instructions",
    ABN: "ABN",
    SUPER_ACC_NO: "Superannuation Account No",
    SUPER_FUND_NAME: "Superannuation Fund Name",
    TAX_FILE_NO: "Tax File No",
    SOCIAL_ACC: "Social Account",
    COMP_NAME: "Company Name",
    REG_NUM: "ABN",
    CONTACT_NAME: "Contact Name",
    CONTACT_NUM: "Contact Number",
    CONTACT: "Contact",
    PUBLIC_LIABILITY_POLICY: "Public Liability Policy",
    COMP_WEBSITE: "Company Website",
    FULL_NAME: "Full Name",
    DRIVER_LICENSE: "Driver's License",
    ADDRESS: "Address",
    WEBSITE: "Website",
    FOOD_ALLERGIES: "Food Allergies",
    MEDICAL_ALLERGIES: "Medical Allergies",
    NEXT_TO_KIN: "Next of Kin",
    RELATION: "Relationship",
    NATIONALITY: "Nationality",
    NOTES: "Notes",
    INSTAGRAM: "Instagram",
    FACEBOOK: "Facebook",
    TWITTER: "Twitter",
    IMDB: "IMDb Link",
  },
  TEAM_FORM: {
    TEAM_MATES: "Teammates",
    TEAM_NAME: "Team Name",
    PROJ_NAME: "Project Name",
    DEPT: "Department",
  },
  FILTERS: {
    PROJ: "Project",
    PROJ_PLACEHOLDER: "Choose project",
    DEPT: "Department",
    DEPT_PLACEHOLDER: "Choose department",
  },
};

export const ClientReportLabels = {
  TABS: {
    STATUS: "Status",
    CNCT_TYPE: "Contract Type",
    DEPT: "Department",
    APPROVAL: "Approval",
    RENEWAL: "Renewal",
    CONTACT: "Contact Report",
    RESTRICTED_CONTACT: "Restricted Contact Report",
    CONTRACT_STATUS: "Contract Status Report",
  },
};

export const ClientUsersLabels = {
  ADMIN: "Admin",
  ADD_ADMIN: "Add Admin",
  VIEW_PERMISSION: "View Permissions",
  ADMIN_FORM: {
    FULL_NAME: "Full Name",
    ROLE: "Role",
    EMAIL: "Email Id",
    PHONE: "Contact Number",
    PROJ: "Project",
  },
};

export const ClientSettingsLabels = {
  TABS: {
    PROFILE: "Profile",
    NOTIFICATIONS: "Notifications",
    ACTIVITY_LOGS: "Activity",
    USERS: "Users",
  },
  PROFILE_FIELDS: {
    FULL_NAME: "Full Name",
    ROLE: "Role",
    EMAIL: "Email Id",
    USERNAME: "Username",
    MOBILE: "Contact Number",
    COMP_NAME: "Company Name",
    COMP_SITE: "Company Website",
    NO_OF_EMP: "Number of Employees",
    EMPS_MANAGING_CNCT: "Employees Managing Contracts",
    INDUSTRY: "Industry",
    ABN_CODE: "ABN",
    ABOUT: "About",
    LANGUAGE: "Language",
    WEEK_START_ON: "Week Start On",
    TIME_FORMAT: "Time Format",
    DATE_FORMAT: "Date Format",
  },
  HOURS12: "12 Hours",
  HOURS24: "24 Hours",
  DDMMYYY: "DD/MM/YY",
  MMDDYY: "MM/DD/YY",
  YYMMDD: "YY/MM/DD",
  EMAIL_NOTI: {
    TITLE: "Email Notifications",
    ACTIVITY_UPDATES: "Activity Updates",
    MENTIONS_ONLY: "Mentions only",
    ASSIGNED_NEW_PROJ: "Assigned a new project",
    PROJ_DUE_TODAY: "Project due today",
    PROJ_UPDATES: "Project updates",
    NOTI_TRIGGER: "Notifications & Triggers",
  },
  BROWSER_NOTI: {
    TITLE: "Browser Notifications",
    PROJ_UPDATES: "Project updates",
    MENTIONS_ONLY: "Mentions only",
    ASSIGNED_NEW_PROJ: "Assigned a new project",
    COLL_INVITE: "Collaboration invite",
  },
};

export const AdminDashboardLabels = {
  CLIENTS: "Clients",
  TEMPLATE_TYPE: "Template Type",
  NAME: "Name",
  COUNT: "Count",
  CLIENTS_PENDING_APPROVALS: "Clients - Pending Approvals",
  VERIFY: "Verify Client",
  PROJECTSTATUS_VS_COUNT: "Project Status vs Count",
  PROJECTTYPE_VS_COUNT: "Project Type vs Count",
  TOTAL_CLIENTS: "Total Clients",
  PROJECTTYPE_VS_PROJECT_STATUS: "Project Type vs Project Status",
};

export const ManageClientsLabels = {
  ADD_CLIENT: "Add Client",
  CREATE_CLIENT: "Create Client",
  RE_APPROVE: "Reapprove",
  CLIENT_FORM: {
    COMP_NAME: "Company Name",
    ABN_CODE: "ABN/ACN",
    COMP_SITE: "Company Website",
    NO_OF_EMP: "Number of Employees",
    EMP_MANAGE_CNCT: "Employees Managing Contracts",
    INDUSTRY: "Industry",
    FULL_NAME: "Full Name",
    DESIGNATION: "Designation",
    EMAIL: "Email Id",
    MOBILE: "Contact Number",
  },
};

export const ManageTemplatesLabels = {
  CREATE_TEMP: "Create Template",
  DEL_TEMP: "Delete!",
  ENABLE_TEMP: "Enable Template",
  DISABLE_TEMP: "Disable Template",
  TEMP_FORM: {
    TEMP_NAME: "Template Name",
    TEMP_TYPE: "Template Type",
    COMPLEXITY: "Complexity",
  },
};

export const ManageAdminsLabels = {
  ADD_ADMIN: "Add Admin",
  ADMIN_FORM: {
    FULL_NAME: "Full Name",
    DEPT: "Department",
    EMAIL: "Email Id",
    MOBILE: "Contact Number",
  },
};

export const AdminRoleManagementLabels = {
  ROLE: "Role",
  FORM: {
    NAME: "Name",
    MODULE: "Module",
    PERMISSION: "Permission",
    READ: "Read",
    WRITE: "Read & Write",
  },
};

export const ContractDocxLabels = {
  FILLABLE_FIELDS: "Fillable Fields & Tags",
  OVERALL_FIELDS: "Overall Fields",
  OVERALL_TAGS: "Overall Tags",
  PRE_PROD: "Pre Production",
  PROD: "Production",
  POST_PROD: "Post Production",
  DELIVERED: "Delivered",
  PRE_PROD_FIELDS: {
    FILM_START_DATE: "Film Start Date",
    FILM_END_DATE: "Film End Date",
  },
  DUPLICATE: "Duplicate Contract",
  RENAME: "Rename Contract",
  DELETE: "Delete Contract",
  TEMPLATE_NAME: "Template name",
  CNCT_INFO: "Contract Information",
  CNCT_INFO_FIELDS: {
    CNCT_NAME: "Contract Name",
    ARTIST_NAME: "Artist Name",
    ARTIST_TYPE: "Artist Type",
    CREATED_DATE: "Created Date",
    MODIFIED_DATE: "Modified Date",
    RENEWAL_DATE: "Renewal Date",
  },
  EDIT_CNCT_BTN_TEXT: "Edit Contract Info",
  STATUS: "Status",
  VERSION_HISTORY: "Version History",
  NOTES: "Notes",
  COLLAB: "Collaboration",
  INVITE: "Invite User",
  MEMBERS: "Members",
};
