import { colors } from "theme/colors";

export const ManageClientsStyles = {
  filter_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    px: 2.5,
    py: 3.5,
  },
  text_input: { fontSize: "13px", color: colors.primaryBlack },
  table: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      backgroundColor: `${colors.primary}10`,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: colors.primaryBlack,
      fontSize: "16px",
      fontWeight: 500,
    },
  },
  cell_text: {
    fontSize: "13px",
    color: colors.secondaryBlack,
    fontWeight: 500,
  },
  chip: { color: colors.primary, backgroundColor: `${colors.primary}20` },
  modal_header_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  modal_btn: { fontSize: "16px", fontWeight: 500 },
  info_label_text: { color: colors.grey, fontSize: "13px", fontWeight: 500 },
  info_text: { color: colors.primaryBlack, fontSize: "16px", fontWeight: 500 },
  ml1: { ml: 1 },
  flex_JCsb_Acenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flex_Acenter: {
    display: "flex",
    alignItems: "center",
  },
  error_text: { fontSize: "12px", color: "#D3010E" },
  actions_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  disabled_btn: {
    "&.Mui-disabled": {
      backgroundColor: colors.grey,
      color: "#c0c0c0",
    },
  },
  modal_footer_container: { p: 2, backgroundColor: `${colors.primary}20` },
};
