import React from "react";
import { Box, Dialog, Typography } from "@mui/material";

import { IModalProps } from "models/common";
import { OutlinedBtn, RedContainedBtn } from "common/Buttons";
import { colors } from "theme/colors";
import { images } from "assets/images";

interface IStatusChangeWarningModalProps extends IModalProps {
  OnYes: () => void;
}

const styles = {
  alert_img: { width: "80px", alignSelf: " center", paddingTop: 40 },
  success_text: {
    textAlign: "center",
    fontSize: "24px",
    color: colors.primaryBlack,
    fontWeight: 600,
    py: 2,
  },
  success_desc: {
    textAlign: "center",
    fontSize: "18px",
    color: colors.secondaryBlack,
    pb: 2,
    px: 4,
  },
  actions_container: {
    display: "flex",
    justifyContent: "center",
    pb: 3,
    px: 4,
    mt: 3,
  },
  btn: { fontSize: "16px", width: "45%", fontWeight: 500 },
  noBtn: { color: colors.secondaryBlack },
};

function StatusChangeWarningModal(props: IStatusChangeWarningModalProps) {
  const { open, setOpen, OnYes } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <img src={images.ALERT_RED} alt="alert" style={styles.alert_img} />
      <Typography sx={styles.success_text}>ALERT</Typography>
      <Typography sx={styles.success_desc}>
        Please ensure to fill the signatory details and check for any other
        empty fields before updating the status.
        <br />
        Do you want continue with status update?
      </Typography>
      <Box sx={styles.actions_container}>
        <RedContainedBtn
          variant="contained"
          sx={[styles.btn, { mr: 1 }]}
          onClick={handleClose}
        >
          No
        </RedContainedBtn>
        <OutlinedBtn
          variant="outlined"
          sx={[styles.btn, { ml: 1 }]}
          onClick={OnYes}
        >
          Yes
        </OutlinedBtn>
      </Box>
    </Dialog>
  );
}

export default StatusChangeWarningModal;
