import { StorageConstants } from "utils/storage";
import { RouteNames } from "routes/routeNames";

export const getPermissions = () => {
  const tempPermissionJSON = sessionStorage.getItem(
    StorageConstants.ACCESS_PERMISSIONS
  );
  if (Boolean(tempPermissionJSON)) {
    const parsedPermissionJSON = JSON.parse(tempPermissionJSON!);
    if (Boolean(parsedPermissionJSON)) {
      return parsedPermissionJSON;
    }
    return [
      {
        permissions: [
          { DASHBOARD: false },
          { REPORTS: false },
          { INTEGRATIONS_VIEW: false },
          { INTEGRATIONS_EDIT: false },
          { SETTINGS_VIEW: false },
          { SETTINGS_EDIT: false },
          { AGENTS_VIEW: false },
          { AGENTS_EDIT: false },
          { PROJECT_VIEW: false },
          { PROJECT_EDIT: false },
          { CONTACTS_VIEW: false },
          { CONTACTS_EDIT: false },
          { FOLDERS_VIEW: false },
          { FOLDERS_EDIT: false },
          { CLEARANCE_VIEW: false },
          { CLEARANCE_EDIT: false },
          { DELIVERY_VIEW: false },
          { DELIVERY_EDIT: false },
          { PROJECT_REPORTS: false },
          { ISSUES_VIEW: false },
          { ISSUES_EDIT: false },
        ],
        folder_restrictions: null,
        my_issues_restrictions: {
          my_issues: { view: false, edit: false },
          all_issues: { view: false, edit: false },
        },
      },
    ];
  } else {
    return [
      {
        permissions: [
          { DASHBOARD: false },
          { REPORTS: false },
          { INTEGRATIONS_VIEW: false },
          { INTEGRATIONS_EDIT: false },
          { SETTINGS_VIEW: false },
          { SETTINGS_EDIT: false },
          { AGENTS_VIEW: false },
          { AGENTS_EDIT: false },
          { PROJECT_VIEW: false },
          { PROJECT_EDIT: false },
          { CONTACTS_VIEW: false },
          { CONTACTS_EDIT: false },
          { FOLDERS_VIEW: false },
          { FOLDERS_EDIT: false },
          { CLEARANCE_VIEW: false },
          { CLEARANCE_EDIT: false },
          { DELIVERY_VIEW: false },
          { DELIVERY_EDIT: false },
          { PROJECT_REPORTS: false },
          { ISSUES_VIEW: false },
          { ISSUES_EDIT: false },
        ],
        folder_restrictions: null,
        my_issues_restrictions: {
          my_issues: { view: false, edit: false },
          all_issues: { view: false, edit: false },
        },
      },
    ];
  }
};

export const getPermissionsToken = (permissions: any) => {
  if (Boolean(permissions)) {
    const parsedPermissionJSON = JSON.parse(permissions!);
    if (Boolean(parsedPermissionJSON)) {
      return parsedPermissionJSON;
    }
    return [
      {
        permissions: [
          { DASHBOARD: false },
          { REPORTS: false },
          { INTEGRATIONS_VIEW: false },
          { INTEGRATIONS_EDIT: false },
          { SETTINGS_VIEW: false },
          { SETTINGS_EDIT: false },
          { AGENTS_VIEW: false },
          { AGENTS_EDIT: false },
          { PROJECT_VIEW: false },
          { PROJECT_EDIT: false },
          { CONTACTS_VIEW: false },
          { CONTACTS_EDIT: false },
          { FOLDERS_VIEW: false },
          { FOLDERS_EDIT: false },
          { CLEARANCE_VIEW: false },
          { CLEARANCE_EDIT: false },
          { DELIVERY_VIEW: false },
          { DELIVERY_EDIT: false },
          { PROJECT_REPORTS: false },
          { ISSUES_VIEW: false },
          { ISSUES_EDIT: false },
        ],
      },
    ];
  } else {
    return [
      {
        permissions: [
          { DASHBOARD: false },
          { REPORTS: false },
          { INTEGRATIONS_VIEW: false },
          { INTEGRATIONS_EDIT: false },
          { SETTINGS_VIEW: false },
          { SETTINGS_EDIT: false },
          { AGENTS_VIEW: false },
          { AGENTS_EDIT: false },
          { PROJECT_VIEW: false },
          { PROJECT_EDIT: false },
          { CONTACTS_VIEW: false },
          { CONTACTS_EDIT: false },
          { FOLDERS_VIEW: false },
          { FOLDERS_EDIT: false },
          { CLEARANCE_VIEW: false },
          { CLEARANCE_EDIT: false },
          { DELIVERY_VIEW: false },
          { DELIVERY_EDIT: false },
          { PROJECT_REPORTS: false },
          { ISSUES_VIEW: false },
          { ISSUES_EDIT: false },
        ],
      },
    ];
  }
};

export const getClientSideRedirectionRoute = (permissions: any) => {
  const dashboardIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("DASHBOARD")
  );
  if (
    dashboardIndex > -1 &&
    permissions[0]?.permissions[dashboardIndex]?.DASHBOARD
  ) {
    return RouteNames.DASHBOARD;
  } else {
    const projectViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("PROJECT_VIEW")
    );
    const projectEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("PROJECT_EDIT")
    );
    const contactsViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("CONTACTS_VIEW")
    );
    const contactsEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("CONTACTS_EDIT")
    );
    const foldersViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("FOLDERS_VIEW")
    );
    const foldersEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("FOLDERS_EDIT")
    );
    const clearanceViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("CLEARANCE_VIEW")
    );
    const clearanceEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("CLEARANCE_EDIT")
    );
    const deliveryViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("DELIVERY_VIEW")
    );
    const deliveryEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("DELIVERY_EDIT")
    );
    const projectReportsIndex = permissions[0]?.permissions.findIndex(
      (x: any) => x.hasOwnProperty("PROJECT_REPORTS")
    );
    const issuesViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("ISSUES_VIEW")
    );
    const issuesEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("ISSUES_EDIT")
    );
    const globalReportsIndex = permissions[0]?.permissions.findIndex((x: any) =>
      x.hasOwnProperty("REPORTS")
    );
    const integrationsViewIndex = permissions[0]?.permissions.findIndex(
      (x: any) => x.hasOwnProperty("INTEGRATIONS_VIEW")
    );
    const integrationsEditIndex = permissions[0]?.permissions.findIndex(
      (x: any) => x.hasOwnProperty("INTEGRATIONS_EDIT")
    );
    if (
      permissions[0]?.permissions[projectViewIndex]?.PROJECT_VIEW ||
      permissions[0]?.permissions[projectEditIndex]?.PROJECT_EDIT ||
      permissions[0]?.permissions[contactsViewIndex]?.CONTACTS_VIEW ||
      permissions[0]?.permissions[contactsEditIndex]?.CONTACTS_EDIT ||
      permissions[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW ||
      permissions[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT ||
      permissions[0]?.permissions[clearanceViewIndex]?.CLEARANCE_VIEW ||
      permissions[0]?.permissions[clearanceEditIndex]?.CLEARANCE_EDIT ||
      permissions[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW ||
      permissions[0]?.permissions[deliveryEditIndex]?.DELIVERY_EDIT ||
      permissions[0]?.permissions[projectReportsIndex]?.PROJECT_REPORTS ||
      permissions[0]?.permissions[issuesViewIndex]?.ISSUES_VIEW ||
      permissions[0]?.permissions[issuesEditIndex]?.ISSUES_EDIT
    ) {
      return RouteNames.PROJECTS;
    } else if (permissions[0]?.permissions[globalReportsIndex]?.REPORTS) {
      return RouteNames.REPORTS;
    } else if (
      permissions[0]?.permissions[integrationsViewIndex]?.INTEGRATIONS_VIEW ||
      permissions[0]?.permissions[integrationsEditIndex]?.INTEGRATIONS_EDIT
    ) {
      return RouteNames.INTEGRATIONS;
    } else {
      return `${RouteNames.SETTINGS}/profile`;
    }
  }
};

export const checkProjectAccess = (permissions: any) => {
  const projectViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("PROJECT_VIEW")
  );
  const projectEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("PROJECT_EDIT")
  );
  const contactsViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("CONTACTS_VIEW")
  );
  const contactsEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("CONTACTS_EDIT")
  );
  const foldersViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("FOLDERS_VIEW")
  );
  const foldersEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("FOLDERS_EDIT")
  );
  const clearanceViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("CLEARANCE_VIEW")
  );
  const clearanceEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("CLEARANCE_EDIT")
  );
  const deliveryViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("DELIVERY_VIEW")
  );
  const deliveryEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("DELIVERY_EDIT")
  );
  const projectReportsIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("PROJECT_REPORTS")
  );
  const issuesViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("ISSUES_VIEW")
  );
  const issuesEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("ISSUES_EDIT")
  );

  return (
    permissions[0]?.permissions[projectViewIndex]?.PROJECT_VIEW ||
    permissions[0]?.permissions[projectEditIndex]?.PROJECT_EDIT ||
    permissions[0]?.permissions[contactsViewIndex]?.CONTACTS_VIEW ||
    permissions[0]?.permissions[contactsEditIndex]?.CONTACTS_EDIT ||
    permissions[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW ||
    permissions[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT ||
    permissions[0]?.permissions[clearanceViewIndex]?.CLEARANCE_VIEW ||
    permissions[0]?.permissions[clearanceEditIndex]?.CLEARANCE_EDIT ||
    permissions[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW ||
    permissions[0]?.permissions[deliveryEditIndex]?.DELIVERY_EDIT ||
    permissions[0]?.permissions[projectReportsIndex]?.PROJECT_REPORTS ||
    permissions[0]?.permissions[issuesViewIndex]?.ISSUES_VIEW ||
    permissions[0]?.permissions[issuesEditIndex]?.ISSUES_EDIT
  );
};

export const getProjectRedirection = (permissions: any) => {
  const projectViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("PROJECT_VIEW")
  );
  const projectEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("PROJECT_EDIT")
  );
  const contactsViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("CONTACTS_VIEW")
  );
  const contactsEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("CONTACTS_EDIT")
  );
  const foldersViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("FOLDERS_VIEW")
  );
  const foldersEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("FOLDERS_EDIT")
  );
  const clearanceViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("CLEARANCE_VIEW")
  );
  const clearanceEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("CLEARANCE_EDIT")
  );
  const deliveryViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("DELIVERY_VIEW")
  );
  const deliveryEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("DELIVERY_EDIT")
  );
  const projectReportsIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("PROJECT_REPORTS")
  );
  const issuesViewIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("ISSUES_VIEW")
  );
  const issuesEditIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("ISSUES_EDIT")
  );

  if (
    permissions[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW ||
    permissions[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT
  ) {
    return "/indexes";
  } else if (
    permissions[0]?.permissions[projectViewIndex]?.PROJECT_VIEW ||
    permissions[0]?.permissions[projectEditIndex]?.PROJECT_EDIT
  ) {
    return "/basic-details";
  } else if (
    permissions[0]?.permissions[contactsViewIndex]?.CONTACTS_VIEW ||
    permissions[0]?.permissions[contactsEditIndex]?.CONTACTS_EDIT
  ) {
    return "/project-contacts";
  } else if (
    permissions[0]?.permissions[clearanceViewIndex]?.CLEARANCE_VIEW ||
    permissions[0]?.permissions[clearanceEditIndex]?.CLEARANCE_EDIT
  ) {
    return "/clearances";
  } else if (
    permissions[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW ||
    permissions[0]?.permissions[deliveryEditIndex]?.DELIVERY_EDIT
  ) {
    return "/delivery-checklist";
  } else if (
    permissions[0]?.permissions[projectReportsIndex]?.PROJECT_REPORTS
  ) {
    return "/reports";
  } else if (
    permissions[0]?.permissions[issuesViewIndex]?.ISSUES_VIEW ||
    permissions[0]?.permissions[issuesEditIndex]?.ISSUES_EDIT
  ) {
    return "/issue-dashboard";
  }
};
