import React from "react";
import { Box, Grid } from "@mui/material";

import { images } from "assets/images";

import SuccessAcknowledgement from "./SuccessAcknowledgement";
import { TwoFASuccessStyles as styles } from "./styles";

export const TwoFASuccess = () => {
  return (
    <Grid container sx={{ overflow: { xs: "auto", sm: "hidden" } }}>
      <Grid
        item
        lg={4.5}
        md={4.5}
        sm={6}
        xs={12}
        display={{ xs: "none", md: "block" }}
      >
        <Box sx={{ height: "100vh", width: "100%" }}>
          <img
            src={images.LOGIN_SIGNUP_BG}
            alt="Continue With Mail"
            style={styles.login_signup_bg}
          />
        </Box>
      </Grid>
      <Grid
        item
        lg={7.5}
        md={7.5}
        sm={6}
        xs={12}
        display={{ xs: "none", md: "block" }}
      >
        <Box sx={[styles.two_fa_container, styles.content_center]}>
          <SuccessAcknowledgement />
        </Box>
      </Grid>
      <Grid
        item
        lg={4.5}
        md={4.5}
        sm={12}
        xs={12}
        display={{ xs: "none", sm: "block", md: "none" }}
      >
        <Box sx={{ height: "100vh", width: "100%" }}>
          <img
            src={images.LOGIN_SIGNUP_BG}
            alt="Continue With Mail"
            style={{ ...styles.login_signup_bg, opacity: 0.3 }}
          />
        </Box>
        <Box
          sx={[
            styles.two_fa_container,
            styles.content_center,
            { position: "absolute", top: 0 },
          ]}
        >
          <SuccessAcknowledgement />
        </Box>
      </Grid>
      <Grid
        item
        lg={4.5}
        md={4.5}
        sm={12}
        xs={12}
        display={{ xs: "block", sm: "none" }}
      >
        <Box sx={[styles.content_center, { minHeight: "100vh" }]}>
          <SuccessAcknowledgement />
        </Box>
      </Grid>
    </Grid>
  );
};
