import { create } from "zustand";

interface IClientSettingsTabs {
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
}

export const useClientSettingsTabs = create<IClientSettingsTabs>((set) => ({
  selectedTab: 0,
  setSelectedTab: (tab: number) => set({ selectedTab: tab }),
}));
