import React from "react";
import { Box, Grid } from "@mui/material";

import { images } from "assets/images";

import VerificationCode from "./VerificationCode";
import { ForgotPwdStyles as styles } from "./styles";

export const ForgotPwdVerifyOtp = () => {
  return (
    <Grid container>
      <Grid item lg={4} md={4} sm={6} display={{ xs: "none", sm: "block" }}>
        <img
          src={images.FORGOT_PWD_BG}
          alt="Continue With Mail"
          style={styles.forgot_pwd_bg}
        />
      </Grid>
      <Grid item lg={8} md={8} sm={6} xs={12}>
        <Box sx={[styles.forgot_pwd_form_container, styles.content_center]}>
          <VerificationCode />
        </Box>
      </Grid>
    </Grid>
  );
};
