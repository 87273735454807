import { gql } from "@apollo/client";

export const CREATE_INDEX = gql`
  mutation createRegister($payload: RegisterCreateInput!) {
    createRegister(payload: $payload) {
      status
      message
    }
  }
`;

export const GET_ALL_INDEXES = gql`
  query getRegisters(
    $filter: RegistersFilterInput!
    $limit: Int
    $page: Int
    $sorted: String
    $is_clearance: Boolean
  ) {
    getRegisters(
      filter: $filter
      limit: $limit
      page: $page
      sorted: $sorted
      is_clearance: $is_clearance
    ) {
      status
      message
      count
      registers {
        id
        name
        status
        createdDate
        modifiedDate
        templateTypes {
          id
          name
        }
        items
      }
    }
  }
`;

export const GET_INDEX_DETAILS = gql`
  query getRegister($payload: RegisterDetailInput!) {
    getRegister(payload: $payload) {
      status
      message
      register {
        id
        name
        templateTypes {
          id
          name
        }
        isClearance
      }
    }
  }
`;

export const UPDATE_INDEX = gql`
  mutation updateRegister($payload: RegisterUpdateInput!) {
    updateRegister(payload: $payload) {
      status
      message
    }
  }
`;

export const DELETE_INDEX = gql`
  mutation deleteRegisters($payload: RegisterDeleteInput!) {
    deleteRegisters(payload: $payload) {
      status
      message
    }
  }
`;

export const CREATE_INDEX_ITEM = gql`
  mutation createRegisterItem(
    $payload: RegisterItemCreateInput!
    $clearance_screengrabs: [Upload]
  ) {
    createRegisterItem(
      payload: $payload
      clearance_screengrabs: $clearance_screengrabs
    ) {
      status
      message
    }
  }
`;

export const GET_ALL_INDEX_ITEMS = gql`
  query getRegisterItems(
    $filter: RegistersItemsFilterInput!
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getRegisterItems(
      filter: $filter
      limit: $limit
      page: $page
      sorted: $sorted
    ) {
      status
      message
      count
      registerItems {
        id
        name
        description
        itemType
        document {
          id
          documentStatus
          isUpload
          fileName
        }
        isRegister
        modifiedDate
        status
        additionalDetails
        clearanceScreengrabs {
          id
          file
          filename
        }
      }
    }
  }
`;

export const GET_INDEX_ITEM_DETAILS = gql`
  query getRegisterItem($payload: RegisterItemDetailInput!) {
    getRegisterItem(payload: $payload) {
      status
      message
      registerItem {
        id
        name
        description
        itemType
        additionalDetails
        isClearance
        clearanceScreengrabs {
          id
          file
          filename
        }
        startDate
        comments
      }
    }
  }
`;

export const UPDATE_INDEX_ITEM = gql`
  mutation updateRegisterItem(
    $payload: RegisterItemUpdateInput!
    $clearance_screengrabs: [Upload]
  ) {
    updateRegisterItem(
      payload: $payload
      clearance_screengrabs: $clearance_screengrabs
    ) {
      status
      message
    }
  }
`;

export const DELETE_INDEX_ITEM = gql`
  mutation deleteRegisterItems($payload: RegisterItemDeleteInput!) {
    deleteRegisterItems(payload: $payload) {
      status
      message
    }
  }
`;

export const UPSERT_INDEX_ITEM_DOCUMENT = gql`
  mutation linkRegisterItemDocument(
    $payload: LinkItemDocumentInput!
    $file: Upload
  ) {
    linkRegisterItemDocument(payload: $payload, file: $file) {
      status
      message
      id
    }
  }
`;

export const GET_INDEXES_ARR = gql`
  query getRegisters(
    $filter: RegistersFilterInput!
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getRegisters(filter: $filter, limit: $limit, page: $page, sorted: $sorted) {
      status
      message
      registers {
        id
        name
      }
    }
  }
`;

export const GET_INDEX_ITEM_NAME = gql`
  query getRegisterItem($payload: RegisterItemDetailInput!) {
    getRegisterItem(payload: $payload) {
      status
      registerItem {
        id
        name
      }
    }
  }
`;

export const GET_CLEARANCE_CATEGORY = gql`
  query clearanceCategories {
    clearanceCategories {
      id
      name
    }
  }
`;

export const GET_CLEARANCE_SUBCATEGORY = gql`
  query clearanceSubCategories {
    clearanceSubCategories {
      id
      name
    }
  }
`;

export const GET_CLEARANCE_BASISOFUSE = gql`
  query clearanceBasisOfUse {
    clearanceBasisOfUse {
      id
      name
    }
  }
`;

export const GET_ZIPPED_FILE = gql`
  query getRegisterZip($payload: RegisterZipInput!) {
    getRegisterZip(payload: $payload) {
      status
      message
    }
  }
`;

export const GET_ACCOUNTING_REPORT = gql`
  query accountingReport($filter: AccountingReportFilterInput, $type: String) {
    accountingReport(filter: $filter, type: $type) {
      status
      message
    }
  }
`;
