import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

import { ManageTemplatesLabels } from "common/AppConstants";
import { IArr } from "models/common";
import { images } from "assets/images";

import { ManageTemplatesStyles as styles } from "./styles";

interface IProps {
  setAddEditTemplate: Dispatch<SetStateAction<boolean>>;
  setSearchText: Dispatch<SetStateAction<string>>;
  setSearchTextTempType: Dispatch<SetStateAction<string>>;
  setSearchTextFnkId: Dispatch<SetStateAction<string>>;
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
}

const sortByOptions: IArr[] = [
  { id: "name", name: "Template Name (A-Z)" },
  { id: "-name", name: "Template Name (Z-A)" },
  { id: "template_type__name", name: "Template Type (A-Z)" },
  { id: "-template_type__name", name: "Template Type (Z-A)" },
  { id: "created_date", name: "Created Date (Oldest-Newest)" },
  { id: "-created_date", name: "Created Date (Newest-Oldest)" },
];

const Filters = (props: IProps) => {
  const {
    setAddEditTemplate,
    setSearchText,
    setSearchTextTempType,
    setSearchTextFnkId,
    sortBy,
    setSortBy,
  } = props;

  const [searchTextFieldValue, setSearchTextFieldValue] = useState("");
  const [searchTextFieldValueTempType, setSearchTextFieldValueTempType] =
    useState("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextFieldValue(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchText(e.target.value);
      setSearchTextFnkId(e.target.value);
    }, 300);
  };

  const handleSearchTempTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTextFieldValueTempType(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchTextTempType(e.target.value);
    }, 300);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={4.8} md={3} sm={6} xs={12}>
        <Button
          variant="contained"
          startIcon={<img src={images.ADD_TEMPLATE_ICON} alt="add_project" />}
          onClick={() => setAddEditTemplate(true)}
        >
          {ManageTemplatesLabels.CREATE_TEMP}
        </Button>
      </Grid>
      {/* <Grid item lg={2.4} md={3} sm={4} xs={12}>
        <TextField
          fullWidth
          value={searchTextFieldValueFnkId}
          onChange={handleSearchFnkIdChange}
          size="small"
          placeholder="Search by Finka ID"
          inputProps={{ style: styles.text_input }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="small" color="inherit" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchTextFieldValueFnkId("");
                    setSearchTextFnkId("");
                  }}
                >
                  <Clear fontSize="small" color="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid> */}
      <Grid item lg={2.4} md={3} sm={6} xs={12}>
        <TextField
          fullWidth
          value={searchTextFieldValue}
          onChange={handleSearchChange}
          size="small"
          placeholder="Search by Template Id/Name"
          inputProps={{ style: styles.text_input }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="small" color="inherit" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchTextFieldValue("");
                    setSearchText("");
                    setSearchTextFnkId("");
                  }}
                >
                  <Clear fontSize="small" color="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item lg={2.4} md={3} sm={6} xs={12}>
        <TextField
          fullWidth
          value={searchTextFieldValueTempType}
          onChange={handleSearchTempTypeChange}
          size="small"
          placeholder="Search by Template Type"
          inputProps={{ style: styles.text_input }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="small" color="inherit" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchTextFieldValueTempType("");
                    setSearchTextTempType("");
                  }}
                >
                  <Clear fontSize="small" color="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item lg={2.4} md={3} sm={6} xs={12}>
        <Autocomplete
          size="small"
          fullWidth
          options={sortByOptions}
          getOptionLabel={(opt) => opt.name}
          value={
            sortByOptions.find((x) => x.id === sortBy) || { id: "", name: "" }
          }
          onChange={(_, newValue) => {
            if (newValue) {
              setSortBy(newValue.id);
            } else {
              setSortBy("");
            }
          }}
          placeholder="Sort By"
          renderOption={(props1, opt, _, __) => (
            <Typography {...props1} sx={{ fontSize: "14px" }}>
              {opt.name}
            </Typography>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              placeholder="Sort By"
              inputProps={{
                ...params.inputProps,
                style: styles.text_input,
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Filters;
