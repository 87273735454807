import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  menuItemClasses,
} from "@mui/material";
import {
  ArrowDropDown,
  CheckOutlined,
  Close,
  ErrorOutlineOutlined,
  FiberManualRecord,
  Link,
  ModeEditOutlineOutlined,
  Send,
} from "@mui/icons-material";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";

import { IChecklistItem } from "models/checklists";
import {
  GET_CHECKLIST_ITEM_DETAILS,
  LINK_DR_TO_CHECKLIST_ITEM,
  UPDATE_CHECKLIST_ITEM,
} from "graphql/checklist";
import { DOWNLOAD_CONTRACT_AS_PDF, GET_CONTRACT_FILE } from "graphql/contracts";
import { BlackActionContainedButton } from "common/Buttons";
import { useStore } from "utils/store";
import {
  ChecklistItemStatusArr,
  ChecklistItemStatusMapping,
} from "utils/constants";
import { GET_ZIPPED_FILE } from "graphql/indexes";
import { truncateText } from "utils/helpers";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { ISelectedChecklistItem } from "./DeliveryChecklistItem";
import ViewLinkedDRItemsModal from "./ViewLinkedDRItemsModal";
import RaiseIssueModal from "./RaiseIssueModal";
import ExportFeedbackModal from "./ExportFeedbackModal";
import DeleteChecklistItemModal from "./DeleteModal";
import { ProjectDetailsStyles as styles } from "../styles";

interface IChecklistItemDetails {
  id: string;
  setLinkDRModal: Dispatch<SetStateAction<boolean>>;
  setSelectedLinkDRItems: Dispatch<SetStateAction<any>>;
  refetch: any;
  setSelectedChecklistItem: Dispatch<
    SetStateAction<ISelectedChecklistItem | null>
  >;
}

function ChecklistItemDetails(props: IChecklistItemDetails) {
  const {
    id,
    setLinkDRModal,
    setSelectedLinkDRItems,
    refetch: refetchTableData,
    setSelectedChecklistItem,
  } = props;

  const params = useParams();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const deliveryViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("DELIVERY_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const deliveryEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("DELIVERY_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );
  const issuesEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("ISSUES_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );
  const foldersViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [editMode, setEditMode] = useState(false);
  const [checklistItemDetails, setChecklistItemDetails] =
    useState<IChecklistItem>({
      id: "",
      name: "",
      description: "",
      levelNo: "",
      note: "",
      itemStatus: "",
      status: 0,
      createdDate: "",
      modifiedDate: "",
      completed: 0,
      pending: 0,
      isFolder: false,
      documents: [],
    });
  const [viewDRLinkedItemsModal, setViewDRLinkedItemsModal] = useState(false);
  const [raiseIssueModal, setRaiseIssueModal] = useState(false);
  const [openStatusMenu, setOpenStatusMenu] = useState(false);
  const [statusMenuAnchor, setStatusMenuAnchor] = useState<HTMLElement | null>(
    null
  );
  const [readMoreDescription, setReadMoreDescription] = useState(true);
  const [exportFeedbackModal, setExportFeedbackModal] = useState(false);
  const [exportFeedbackMessage, setExportFeedbackMessage] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const handleStatusMenuOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    setStatusMenuAnchor(e.currentTarget);
    setOpenStatusMenu(true);
  };

  const handleStatusMenuClose = () => {
    setStatusMenuAnchor(null);
    setOpenStatusMenu(false);
  };

  const { loading, refetch } = useQuery(GET_CHECKLIST_ITEM_DETAILS, {
    variables: {
      id: id,
      project_id: params?.project_id,
      checklist_id: params?.checklist_id,
    },
    skip:
      !params?.project_id ||
      !params?.checklist_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        deliveryViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
      ),
    onCompleted: (data) => {
      const { getChecklistItem } = data;
      const { status, checklistItem } = getChecklistItem;
      if (status) {
        const {
          id,
          name,
          description,
          levelNo,
          note,
          itemStatus,
          status,
          createdDate,
          modifiedDate,
          completed,
          pending,
          isFolder,
          documents,
        } = checklistItem;
        setChecklistItemDetails({
          id: id || "",
          name: name || "",
          description: description || "",
          levelNo: levelNo || "",
          note: note || "",
          itemStatus: itemStatus || "PENDING",
          status: status || 0,
          createdDate: createdDate || "",
          modifiedDate: modifiedDate || "",
          completed: completed || 0,
          pending: pending || 0,
          isFolder: isFolder,
          documents: documents || [],
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireUpdateChecklistItemApi, { loading: updating }] = useMutation(
    UPDATE_CHECKLIST_ITEM,
    {
      onCompleted: (data) => {
        const { updateChecklistItem } = data;
        const { status, message } = updateChecklistItem;
        setEditMode(false);
        if (status) {
          refetch();
          refetchTableData();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const [fireLinkApi, { loading: linking }] = useMutation(
    LINK_DR_TO_CHECKLIST_ITEM,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { linkRegisterToChecklist } = data;
        const { status, message } = linkRegisterToChecklist;
        if (status) {
          refetch();
          refetchTableData();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const [previewDocumentPDF, { loading: gettingPDFPreview }] = useLazyQuery(
    DOWNLOAD_CONTRACT_AS_PDF,
    {
      onCompleted: (data) => {
        const { getContractAsPDF } = data;
        const { status, contract } = getContractAsPDF;
        if (status) {
          if (Boolean(contract)) {
            var base64String = contract;
            var binaryString = atob(base64String);
            var byteArray = new Uint8Array(binaryString.length);
            for (var i = 0; i < binaryString.length; i++) {
              byteArray[i] = binaryString.charCodeAt(i);
            }
            var blob = new Blob([byteArray], { type: "application/pdf" });
            var url = URL.createObjectURL(blob);
            window.open(url);
          }
        } else {
          toast.error("Something went wrong, please try again", { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [getDocumentFile, { loading: gettingFile }] = useLazyQuery(
    GET_CONTRACT_FILE,
    {
      onCompleted: (data) => {
        const { getDocumentFile } = data;
        const {
          status,
          message,
          file,
          fileExists,
          signedFile,
          document: rawDocument,
        } = getDocumentFile;
        if (status) {
          if (fileExists) {
            if (rawDocument?.isUpload) {
              var hiddenElement = document.createElement("a");
              hiddenElement.href = file;
              hiddenElement.target = "_blank";
              hiddenElement.download = `${rawDocument?.name}.pdf`;
              hiddenElement.click();
            } else {
              if (signedFile) {
                var hiddenElement1 = document.createElement("a");
                hiddenElement1.href = signedFile;
                hiddenElement1.target = "_blank";
                hiddenElement1.download = `${rawDocument?.name}.pdf`;
                hiddenElement1.click();
              } else {
                previewDocumentPDF({ variables: { id: rawDocument?.id } });
              }
            }
          } else {
            toast.error("No File Found", { delay: 10 });
          }
        } else {
          toast.error(message, { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [getZippedFile, { loading: generatingZip }] = useLazyQuery(
    GET_ZIPPED_FILE,
    {
      onCompleted: (data) => {
        const { getRegisterZip } = data;
        const { status, message } = getRegisterZip;
        if (status) {
          setExportFeedbackMessage(message);
          setExportFeedbackModal(true);
        } else {
          toast.error(message, { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const handleUpdateFolderDetails = (values: IChecklistItem) => {
    const { id, name, description, levelNo, itemStatus } = values;
    fireUpdateChecklistItemApi({
      variables: {
        payload: {
          projectId: params?.project_id,
          checklistId: params?.checklist_id,
          id,
          name,
          description,
          levelNo,
          itemStatus,
        },
      },
    });
  };

  useEffect(() => {
    setEditMode(false);
    setReadMoreDescription(true);
  }, [id]);

  useEffect(() => {
    setLoading(
      gettingFile ||
        gettingPDFPreview ||
        generatingZip ||
        linking ||
        loading ||
        updating
    );
  }, [
    generatingZip,
    gettingFile,
    gettingPDFPreview,
    linking,
    loading,
    setLoading,
    updating,
  ]);

  return (
    <Formik
      initialValues={checklistItemDetails}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required("Please enter the item name")
          .matches(/^[^/]*$/, "Item name cannot contain '/'"),
      })}
      onSubmit={handleUpdateFolderDetails}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        handleSubmit,
      }) => {
        return (
          <Box sx={{ p: 2.5 }}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {editMode ? (
                    <Box sx={styles.flex_Acenter}>
                      <TextField
                        id="levelNo"
                        value={values.levelNo}
                        onChange={handleChange}
                        size="small"
                        inputProps={{
                          style: {
                            ...styles.input_field,
                            backgroundColor: "#fff",
                          },
                        }}
                        sx={{
                          width: 70,
                          "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            borderRadius: "8px",
                          },
                        }}
                      />
                      <TextField
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                        inputProps={{
                          style: {
                            ...styles.input_field,
                            backgroundColor: "#fff",
                          },
                        }}
                        sx={{
                          ml: 0.5,
                          "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            borderRadius: "8px",
                          },
                        }}
                      />
                      <Box
                        component="div"
                        sx={{
                          backgroundColor: Boolean(values.itemStatus)
                            ? (ChecklistItemStatusMapping as any)[
                                values.itemStatus
                              ]?.color
                            : "#00000020",
                          py: 0.75,
                          borderRadius: "20px",
                          width: "120px",
                          pl: 1,
                          ml: 1,
                          display: { xs: "none", md: "flex" },
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleStatusMenuOpen}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            color: colors.secondary,
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          {Boolean(values.itemStatus)
                            ? (ChecklistItemStatusMapping as any)[
                                values.itemStatus
                              ]?.name
                            : "NA"}
                        </Typography>
                        <ArrowDropDown color="secondary" />
                      </Box>
                      <Menu
                        open={openStatusMenu}
                        anchorEl={statusMenuAnchor}
                        onClose={handleStatusMenuClose}
                        elevation={0}
                        sx={{
                          "& .MuiMenu-paper": {
                            mt: 1,
                            border: "1px #00000030 solid",
                            borderRadius: "15px",
                          },
                        }}
                      >
                        {ChecklistItemStatusArr.map((status) => (
                          <MenuItem
                            key={status.id}
                            onClick={() => {
                              setFieldValue("itemStatus", status.id);
                              handleStatusMenuClose();
                            }}
                            selected={status.id === values.itemStatus}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              [`&.${menuItemClasses.selected}`]: {
                                backgroundColor: "#41B19931",
                                "&:hover": { backgroundColor: "#41B19995" },
                              },
                              "&:hover": { backgroundColor: "#B6BCC330" },
                            }}
                          >
                            <FiberManualRecord
                              sx={{
                                color: Boolean(status.id)
                                  ? (ChecklistItemStatusMapping as any)[
                                      status.id
                                    ]?.color
                                  : "#00000020",
                                fontSize: 20,
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: colors.secondaryBlack,
                                ml: 1,
                              }}
                            >
                              {status.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "90%",
                        display: "flex",
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        sx={{ flexWrap: "nowrap" }}
                      >
                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: colors.primaryBlack,
                              fontWeight: 600,
                              wordBreak: "break-word",
                            }}
                          >
                            {Boolean(values?.levelNo)
                              ? `${values?.levelNo} - ${values?.name}`
                              : values?.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sx={{ display: { xs: "none", md: "block" } }}
                        >
                          <Box
                            sx={{
                              backgroundColor: Boolean(values.itemStatus)
                                ? (ChecklistItemStatusMapping as any)[
                                    values.itemStatus
                                  ]?.color
                                : "#00000020",
                              py: 0.75,
                              borderRadius: "20px",
                              width: "105px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "13px",
                                color: colors.secondary,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              {Boolean(values.itemStatus)
                                ? (ChecklistItemStatusMapping as any)[
                                    values.itemStatus
                                  ]?.name
                                : "NA"}
                            </Typography>
                          </Box>
                        </Grid>
                        {Boolean(ROLE_PERMISSIONS) &&
                          issuesEditIndex !== undefined &&
                          ROLE_PERMISSIONS[0]?.permissions[issuesEditIndex]
                            ?.ISSUES_EDIT && (
                            <Grid
                              item
                              sx={{ display: { xs: "none", md: "flex" } }}
                            >
                              <Box>
                                <BlackActionContainedButton
                                  size="small"
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    borderRadius: "20px",
                                    width: "115px",
                                  }}
                                  startIcon={
                                    <ErrorOutlineOutlined
                                      fontSize="small"
                                      color="secondary"
                                    />
                                  }
                                  onClick={() => setRaiseIssueModal(true)}
                                >
                                  Raise Issue
                                </BlackActionContainedButton>
                              </Box>
                            </Grid>
                          )}
                      </Grid>
                    </Box>
                  )}
                  <Box>
                    {Boolean(ROLE_PERMISSIONS) &&
                    deliveryEditIndex !== undefined &&
                    ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
                      ?.DELIVERY_EDIT ? (
                      editMode ? (
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleSubmit();
                            // setEditMode(false);
                          }}
                          // sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                        >
                          <CheckOutlined
                            sx={{ fontSize: 18, color: "#909090" }}
                          />
                        </IconButton>
                      ) : (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            size="small"
                            onClick={() => setEditMode(true)}
                            // sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                          >
                            <ModeEditOutlineOutlined
                              sx={{ fontSize: 18, color: "#909090" }}
                            />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => setDeleteModal(true)}
                            // sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                          >
                            <img
                              src={images.DELETE_ICON_NEW}
                              alt="delete"
                              height={15}
                            />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => setSelectedChecklistItem(null)}
                            // sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                          >
                            <Close sx={{ fontSize: 18, color: "#909090" }} />
                          </IconButton>
                        </Box>
                      )
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => setSelectedChecklistItem(null)}
                        // sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                      >
                        <Close sx={{ fontSize: 18, color: "#909090" }} />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Grid>
              {editMode && touched?.name && (
                <Typography sx={[styles.error_text, { ml: 2.5 }]}>
                  {errors?.name}
                </Typography>
              )}
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: { xs: "flex", md: "none" },
                  justifyContent: editMode ? "flex-end" : "space-between",
                }}
              >
                {!editMode &&
                  Boolean(ROLE_PERMISSIONS) &&
                  issuesEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[issuesEditIndex]
                    ?.ISSUES_EDIT && (
                    <Box>
                      <BlackActionContainedButton
                        size="small"
                        sx={{
                          fontSize: "13px",
                          fontWeight: 600,
                          borderRadius: "20px",
                          width: "115px",
                          mr: 1,
                        }}
                        startIcon={
                          <ErrorOutlineOutlined
                            fontSize="small"
                            color="secondary"
                          />
                        }
                        onClick={() => setRaiseIssueModal(true)}
                      >
                        Raise Issue
                      </BlackActionContainedButton>
                    </Box>
                  )}
                <Box
                  component="div"
                  sx={{
                    backgroundColor: Boolean(values.itemStatus)
                      ? (ChecklistItemStatusMapping as any)[values.itemStatus]
                          ?.color
                      : "#00000020",
                    py: 0.75,
                    borderRadius: "20px",
                    width: "120px",
                    px: 1,
                    display: { xs: "flex", md: "none" },
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={editMode ? handleStatusMenuOpen : () => {}}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: colors.secondary,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {Boolean(values.itemStatus)
                      ? (ChecklistItemStatusMapping as any)[values.itemStatus]
                          ?.name
                      : "NA"}
                  </Typography>
                  {editMode && <ArrowDropDown color="secondary" />}
                </Box>
                <Menu
                  open={openStatusMenu}
                  anchorEl={statusMenuAnchor}
                  onClose={handleStatusMenuClose}
                  elevation={0}
                  sx={{
                    "& .MuiMenu-paper": {
                      mt: 1,
                      border: "1px #00000030 solid",
                      borderRadius: "15px",
                    },
                  }}
                >
                  {ChecklistItemStatusArr.map((status) => (
                    <MenuItem
                      key={status.id}
                      onClick={() => {
                        setFieldValue("itemStatus", status.id);
                        handleStatusMenuClose();
                      }}
                      selected={status.id === values.itemStatus}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        [`&.${menuItemClasses.selected}`]: {
                          backgroundColor: "#41B19931",
                          "&:hover": { backgroundColor: "#41B19995" },
                        },
                        "&:hover": { backgroundColor: "#B6BCC330" },
                      }}
                    >
                      <FiberManualRecord
                        sx={{
                          color: Boolean(status.id)
                            ? (ChecklistItemStatusMapping as any)[status.id]
                                ?.color
                            : "#00000020",
                          fontSize: 20,
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: colors.secondaryBlack,
                          ml: 1,
                        }}
                      >
                        {status.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {editMode ? (
                  <TextField
                    id="description"
                    value={values.description}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    inputProps={{
                      style: {
                        ...styles.input_field,
                        backgroundColor: "#fff",
                      },
                    }}
                    multiline
                    minRows={3}
                    maxRows={3}
                    sx={{
                      "& .MuiInputBase-root.MuiOutlinedInput-root": {
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                      },
                    }}
                  />
                ) : (
                  <Box sx={{ px: 1.5, display: "flex" }}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: colors.primaryBlack,
                        textAlign: "justify",
                        wordBreak: "break-word",
                      }}
                    >
                      {readMoreDescription
                        ? truncateText(values.description, 300)
                        : values.description}
                      {readMoreDescription &&
                        values.description.length > 300 && (
                          <span
                            style={{ cursor: "pointer", color: "#41B199" }}
                            onClick={() =>
                              setReadMoreDescription(!readMoreDescription)
                            }
                          >
                            &nbsp;read more
                          </span>
                        )}
                      {!readMoreDescription &&
                        values.description.length > 300 && (
                          <span
                            style={{ cursor: "pointer", color: "#41B199" }}
                            onClick={() =>
                              setReadMoreDescription(!readMoreDescription)
                            }
                          >
                            &nbsp;read less
                          </span>
                        )}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ px: 1.5 }}>
                  <Grid
                    container
                    spacing={1}
                    sx={{ flexWrap: "nowrap" }}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box sx={styles.flex_Acenter}>
                        <Typography
                          fontSize="12px"
                          fontWeight={600}
                          sx={{ color: `${colors.primaryBlack}70` }}
                        >
                          File(s):
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box
                        sx={{
                          cursor:
                            Boolean(checklistItemDetails?.documents) &&
                            checklistItemDetails.documents.length === 0
                              ? "default"
                              : "pointer",
                        }}
                        onClick={(e) => {
                          if (
                            Boolean(checklistItemDetails?.documents) &&
                            checklistItemDetails.documents.length > 0
                          ) {
                            if (checklistItemDetails.documents.length === 1) {
                              if (
                                checklistItemDetails.documents[0].type ===
                                "REGISTER"
                              ) {
                                setViewDRLinkedItemsModal(true);
                              } else {
                                if (
                                  Boolean(
                                    checklistItemDetails.documents[0].documentId
                                  )
                                ) {
                                  getDocumentFile({
                                    variables: {
                                      payload: {
                                        documentId:
                                          checklistItemDetails.documents[0]
                                            ?.documentId,
                                      },
                                    },
                                  });
                                } else {
                                  toast.error("No File Found", { delay: 10 });
                                }
                              }
                            } else if (
                              checklistItemDetails.documents.length > 1
                            ) {
                              setViewDRLinkedItemsModal(true);
                            }
                          }
                        }}
                      >
                        {Boolean(checklistItemDetails?.documents) &&
                          checklistItemDetails.documents.length > 0 && (
                            <Typography
                              fontSize="12px"
                              fontWeight={700}
                              sx={{
                                color: colors.primaryBlack,
                                textDecoration: "underline",
                              }}
                            >
                              {Boolean(checklistItemDetails?.documents) &&
                              checklistItemDetails.documents.length > 1
                                ? "See All Attached Files"
                                : checklistItemDetails?.documents.length === 1
                                ? checklistItemDetails?.documents[0]?.name
                                : ""}
                            </Typography>
                          )}
                        {Boolean(checklistItemDetails?.documents) &&
                          checklistItemDetails.documents.length === 0 && (
                            <Typography
                              fontSize="12px"
                              fontWeight={700}
                              sx={{
                                color: `${colors.primaryBlack}50`,
                              }}
                            >
                              No Files Attached
                            </Typography>
                          )}
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {Boolean(ROLE_PERMISSIONS) &&
                          deliveryEditIndex !== undefined &&
                          ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
                            ?.DELIVERY_EDIT && (
                            <Tooltip
                              title={
                                Boolean(ROLE_PERMISSIONS) &&
                                foldersViewIndex !== undefined &&
                                ROLE_PERMISSIONS[0]?.permissions[
                                  foldersViewIndex
                                ]?.FOLDERS_VIEW
                                  ? "Attach files/folders"
                                  : "Not permitted"
                              }
                            >
                              <span>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSelectedLinkDRItems(
                                      Boolean(
                                        checklistItemDetails?.documents
                                      ) &&
                                        checklistItemDetails?.documents
                                          ?.length > 0
                                        ? checklistItemDetails?.documents.map(
                                            (x) => ({
                                              type: x.type,
                                              contentId: x.id,
                                            })
                                          )
                                        : []
                                    );
                                    setLinkDRModal(true);
                                  }}
                                  disabled={
                                    !(
                                      Boolean(ROLE_PERMISSIONS) &&
                                      foldersViewIndex !== undefined &&
                                      ROLE_PERMISSIONS[0]?.permissions[
                                        foldersViewIndex
                                      ]?.FOLDERS_VIEW
                                    )
                                  }
                                >
                                  <Link
                                    sx={{
                                      color: `#909090`,
                                      fontSize: 18,
                                    }}
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        {Boolean(ROLE_PERMISSIONS) &&
                        deliveryEditIndex !== undefined &&
                        ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
                          ?.DELIVERY_EDIT ? (
                          Boolean(checklistItemDetails?.documents) &&
                          checklistItemDetails?.documents?.length > 0 && (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  fireLinkApi({
                                    variables: {
                                      payload: {
                                        id: checklistItemDetails?.id,
                                        projectId: params?.project_id,
                                        documents: [],
                                      },
                                    },
                                  });
                                }}
                              >
                                <img
                                  src={images.DELETE_ICON_NEW}
                                  alt="delete"
                                  height={13}
                                />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  if (
                                    checklistItemDetails.documents.length === 1
                                  ) {
                                    if (
                                      checklistItemDetails.documents[0].type ===
                                      "REGISTER"
                                    ) {
                                      getZippedFile({
                                        variables: {
                                          payload: {
                                            projectId: params?.project_id,
                                            checklistItemId:
                                              checklistItemDetails?.id,
                                          },
                                        },
                                      });
                                    } else {
                                      if (
                                        Boolean(
                                          checklistItemDetails.documents[0]
                                            .documentId
                                        )
                                      ) {
                                        getDocumentFile({
                                          variables: {
                                            payload: {
                                              documentId:
                                                checklistItemDetails
                                                  .documents[0].documentId,
                                            },
                                          },
                                        });
                                      } else {
                                        toast.error("No File Found", {
                                          delay: 10,
                                        });
                                      }
                                    }
                                  } else {
                                    getZippedFile({
                                      variables: {
                                        payload: {
                                          projectId: params?.project_id,
                                          checklistItemId:
                                            checklistItemDetails?.id,
                                        },
                                      },
                                    });
                                  }
                                }}
                              >
                                <img
                                  src={images.DOWNLOAD_ICON_NEW}
                                  alt="download"
                                  height={13}
                                />
                              </IconButton>
                            </>
                          )
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() => {
                              if (checklistItemDetails.documents.length === 1) {
                                if (
                                  checklistItemDetails.documents[0].type ===
                                  "REGISTER"
                                ) {
                                  getZippedFile({
                                    variables: {
                                      payload: {
                                        projectId: params?.project_id,
                                        checklistItemId:
                                          checklistItemDetails?.id,
                                      },
                                    },
                                  });
                                } else {
                                  if (
                                    Boolean(
                                      checklistItemDetails.documents[0]
                                        .documentId
                                    )
                                  ) {
                                    getDocumentFile({
                                      variables: {
                                        payload: {
                                          documentId:
                                            checklistItemDetails.documents[0]
                                              .documentId,
                                        },
                                      },
                                    });
                                  } else {
                                    toast.error("No File Found", {
                                      delay: 10,
                                    });
                                  }
                                }
                              } else {
                                getZippedFile({
                                  variables: {
                                    payload: {
                                      projectId: params?.project_id,
                                      checklistItemId: checklistItemDetails?.id,
                                    },
                                  },
                                });
                              }
                            }}
                          >
                            <img
                              src={images.DOWNLOAD_ICON_NEW}
                              alt="download"
                              height={13}
                            />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{ px: 1.5, display: "flex", alignItems: "center", mt: 2 }}
                >
                  <Box sx={{ flexGrow: 1, mr: 6 }}>
                    <Divider />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 600,
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Comments
                  </Typography>
                  <Box sx={{ flexGrow: 1, ml: 6 }}>
                    <Divider />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  {Boolean(ROLE_PERMISSIONS) &&
                  deliveryEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
                    ?.DELIVERY_EDIT ? (
                    <Typography
                      sx={{
                        textAlign: "right",
                        fontSize: "12px",
                        color: `${colors.secondaryBlack}40`,
                      }}
                    >
                      {`Last updated: ${
                        values.modifiedDate
                          ? moment(values.modifiedDate).format("DD MMMM YYYY")
                          : ""
                      }`}
                    </Typography>
                  ) : values?.note ? (
                    <Typography
                      sx={{
                        textAlign: "right",
                        fontSize: "12px",
                        color: `${colors.secondaryBlack}40`,
                      }}
                    >
                      {`Last updated: ${
                        values.modifiedDate
                          ? moment(values.modifiedDate).format("DD MMMM YYYY")
                          : ""
                      }`}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {Boolean(ROLE_PERMISSIONS) &&
                  deliveryEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
                    ?.DELIVERY_EDIT ? (
                    <TextField
                      id="note"
                      value={values.note}
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          ...styles.input_field,
                          backgroundColor: "#fff",
                        },
                      }}
                      multiline
                      minRows={values.note ? 2 : 1}
                      maxRows={4}
                      placeholder="Write a comment..."
                      sx={{
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                      InputProps={{
                        ...(values.note && {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{
                                  backgroundColor: colors.primaryBlack,
                                  "&:hover": {
                                    backgroundColor: `${colors.primaryBlack}80`,
                                  },
                                }}
                                onClick={() => {
                                  fireUpdateChecklistItemApi({
                                    variables: {
                                      payload: {
                                        projectId: params?.project_id,
                                        checklistId: params?.checklist_id,
                                        id,
                                        note: values.note,
                                      },
                                    },
                                  });
                                }}
                              >
                                <Send color="secondary" sx={{ fontSize: 18 }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }),
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: colors.primaryBlack,
                        wordBreak: "break-word",
                      }}
                    >
                      {values.note}
                    </Typography>
                  )}
                </Box>
              </Grid>
              {viewDRLinkedItemsModal && (
                <ViewLinkedDRItemsModal
                  open={viewDRLinkedItemsModal}
                  setOpen={setViewDRLinkedItemsModal}
                  linkedDRItems={checklistItemDetails?.documents || []}
                />
              )}
              {raiseIssueModal && (
                <RaiseIssueModal
                  open={raiseIssueModal}
                  setOpen={setRaiseIssueModal}
                />
              )}
              {exportFeedbackModal && (
                <ExportFeedbackModal
                  open={exportFeedbackModal}
                  setOpen={setExportFeedbackModal}
                  message={exportFeedbackMessage}
                  setMessage={setExportFeedbackMessage}
                />
              )}
              {deleteModal && (
                <DeleteChecklistItemModal
                  open={deleteModal}
                  setOpen={setDeleteModal}
                  selectedChecklistItem={checklistItemDetails?.id}
                  selectedChecklistItemName={checklistItemDetails?.name}
                  setSelectedChecklistItem={setSelectedChecklistItem}
                  refetch={refetchTableData}
                />
              )}
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
}

export default ChecklistItemDetails;
