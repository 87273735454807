import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";

import { IModalProps } from "models/common";
import AgencyLeftSteps from "./AgencyLeftSteps";
import { IAgentFields } from "models/agents";
import { GET_AGENT_DETAILS } from "graphql/agents";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import AgencyDetailsForm from "./AgencyDetailsForm";
import RegistrationForm from "./RegistrationForm";
import BankDetailsForm from "./BankDetailsForm";
import { setAgentDetails, initialValues as startValues } from "./utils";

interface IEditAgentModalProps extends IModalProps {
  selectedAgentId: string;
  setSelectedAgentId: Dispatch<SetStateAction<string>>;
  refetch: any;
}

function EditAgentModal(props: IEditAgentModalProps) {
  const { open, setOpen, selectedAgentId, setSelectedAgentId, refetch } = props;

  const { setLoading } = useStore();

  const [activeSection, setActiveSection] = useState(0);
  const [initialValues, setInitialValues] = useState<IAgentFields>(startValues);
  const [profilePicture, setProfilePicture] = useState<any>(null);

  const handleClose = () => {
    setOpen(false);
    setSelectedAgentId("");
    refetch();
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [
    getAgentDetails,
    { loading: gettingDetails, refetch: refetchAgentDetails },
  ] = useLazyQuery(GET_AGENT_DETAILS, {
    onCompleted: (data) => {
      const { getAgentDetails } = data;
      const { status, agentDetails } = getAgentDetails;
      if (status) {
        setAgentDetails(agentDetails, setInitialValues);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (selectedAgentId) {
      getAgentDetails({ variables: { id: selectedAgentId } });
    }
  }, [getAgentDetails, selectedAgentId]);

  useEffect(() => {
    setProfilePicture(initialValues.profile_picture);
  }, [initialValues.profile_picture]);

  useEffect(() => {
    setLoading(gettingDetails);
  }, [gettingDetails, setLoading]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: { xs: 600, sm: 785 },
          borderRadius: "24px",
          // overflow: "hidden"
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            py: 2,
            backgroundColor: "#B6BCC325",
            position: "relative",
            px: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                width: 22,
                height: 22,
                mr: 0.5,
                backgroundColor: colors.primaryBlack,
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: colors.secondary,
                }}
              >
                1
              </Typography>
            </Avatar>
            <Typography
              textAlign="center"
              sx={{
                fontSize: "13px",
                color: colors.primaryBlack,
                fontWeight: 600,
              }}
            >
              Agency
            </Typography>
          </Box>
          <Box sx={{ position: "absolute", top: 10, right: 10 }}>
            <IconButton size="small" onClick={handleClose}>
              <Close sx={{ color: "#00000055" }} fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, overflow: "auto" }}>
          <Box sx={{ height: "100%" }}>
            <Grid container>
              <Grid
                item
                lg={3}
                md={3}
                sm={3}
                xs={3}
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <Box sx={{ pt: 3 }}>
                  <AgencyLeftSteps
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                    profilePicture={profilePicture}
                    setProfilePicture={setProfilePicture}
                  />
                </Box>
              </Grid>
              <Grid item lg={9} md={9} sm={9} xs={12}>
                <Box
                  sx={{
                    borderLeft: { xs: "none", sm: "1.5px #e6e6e6 solid" },
                    height: { xs: "546.02px", sm: "731.02px" },
                  }}
                >
                  {activeSection === 0 && (
                    <AgencyDetailsForm
                      setActiveSection={setActiveSection}
                      profilePicture={profilePicture}
                      initialValues={initialValues}
                      refetch={refetchAgentDetails}
                      setProfilePicture={setProfilePicture}
                    />
                  )}
                  {activeSection === 1 && (
                    <RegistrationForm
                      setActiveSection={setActiveSection}
                      profilePicture={profilePicture}
                      initialValues={initialValues}
                      refetch={refetchAgentDetails}
                    />
                  )}
                  {activeSection === 2 && (
                    <BankDetailsForm
                      setActiveSection={setActiveSection}
                      profilePicture={profilePicture}
                      initialValues={initialValues}
                      refetch={refetchAgentDetails}
                      handleClose={handleClose}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default EditAgentModal;
