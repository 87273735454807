import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import moment from "moment";

import { ContractDocxLabels } from "common/AppConstants";
import DatePicker from "common/DatePickerInput";
import { useThirdPartyContract } from "hooks/useThirdPartyContract";
import { getPermissions } from "permissions/utils";
import IFillableFields from "hooks/useThirdPartyContract";
import { colors } from "theme/colors";

import { ContractDocxStyles as styles } from "./styles";
import AddContactModal from "./AddContactModal";
import AddAgentModal from "./AddAgentModal";
import { IContract } from "../ContractDocEditor";
import IExtractedContact from ".";

// interface ITagProps {
//   item: ITags;
//   index: number;
// }

// const tagColors = ["#FEFEC8", "#D3FED3", "#FFDCDC", "#DDD9FF", "#E7E7E7"];

// const TagItem = forwardRef((props: ITagProps, ref: any) => {
//   const { item, index } = props;

//   const { tags, setTags } = useThirdPartyContract();
//   const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
//   const foldersEditIndex = useMemo(
//     () =>
//       ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
//         x.hasOwnProperty("FOLDERS_EDIT")
//       ),
//     [ROLE_PERMISSIONS]
//   );

//   const [expanded, setExpanded] = useState(false);
//   const [tagValue, setTagValue] = useState("");

//   useEffect(() => {
//     if (Boolean(ref)) {
//       const element = ref.current.dom.get(item.id!);
//       if (Boolean(element)) {
//         setTagValue(element.innerText);
//       }
//     }
//   }, [item.id, ref]);

//   return (
//     <Box>
//       <Box
//         sx={[styles.flex_Acenter, { cursor: "pointer" }]}
//         onClick={() => {
//           setExpanded(!expanded);
//           const element = ref.current.dom.get(item.id!);
//           if (!expanded) {
//             element.style.backgroundColor = tagColors[index % 5];
//             element.style.padding = "5px";
//             element.scrollIntoView({
//               behavior: "smooth",
//               block: "start",
//             });
//           } else {
//             element.style.backgroundColor = "transparent";
//             element.style.padding = "0px";
//           }
//         }}
//       >
//         <Typography
//           sx={[
//             styles.field_label,
//             styles.text_wrap,
//             { fontSize: "14px", width: "90%" },
//           ]}
//         >
//           {item.tagName}
//         </Typography>
//         {expanded ? (
//           <KeyboardArrowUp sx={{ ml: 1 }} color="primary" />
//         ) : (
//           <KeyboardArrowDown sx={{ ml: 1 }} color="primary" />
//         )}
//       </Box>
//       {!expanded && (
//         <Typography
//           noWrap
//           sx={[
//             styles.field_label,
//             { fontSize: "10px", textOverflow: "ellipsis", mt: 0.75 },
//           ]}
//         >
//           {item.preview}
//         </Typography>
//       )}
//       {expanded && (
//         <TextField
//           size="small"
//           fullWidth
//           value={tagValue}
//           onChange={(e) => {
//             setTagValue(e.target.value);
//             const element = ref.current.dom.get(item.id!);
//             element.innerText = e.target.value;
//             const tempTags = [...tags];
//             tempTags[index].preview = e.target.value;
//             setTags([...tempTags]);
//           }}
//           onFocus={() => {
//             const element = ref.current.dom.get(item.id!);
//             element.style.backgroundColor = tagColors[index % 5];
//             element.style.padding = "5px";
//             element.scrollIntoView({
//               behavior: "smooth",
//               block: "start",
//             });
//           }}
//           onBlur={() => {
//             const element = ref.current.dom.get(item.id!);
//             element.style.backgroundColor = "transparent";
//             element.style.padding = "0px";
//           }}
//           multiline
//           inputProps={{ style: styles.text_input }}
//           sx={{ mt: 0.75 }}
//           disabled={
//             !(
//               Boolean(ROLE_PERMISSIONS) &&
//               foldersEditIndex !== undefined &&
//               ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT
//             )
//           }
//         />
//       )}
//     </Box>
//   );
// });

interface IFillableFieldItem {
  item: IFillableFields;
  index: number;
  // setFeeCalculatorModal: Dispatch<SetStateAction<boolean>>;
  // selectedSigners: ISignerArr[];
  // setSelectedSigners: Dispatch<SetStateAction<ISignerArr[]>>;
  contractData: IContract | null;
  refetch: any;
  currency: string;
}

const FillableFieldItem = (props: IFillableFieldItem) => {
  const {
    item,
    index,
    // selectedSigners,
    // setSelectedSigners,
    contractData,
  } = props;

  const { fillableFields, setFillableFields } = useThirdPartyContract();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const foldersEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  // const [signersArr, setSignersArr] = useState<ISignerArr[]>([]);
  // const [multiPersonArr, setMultiPersonArr] = useState<IPerson[]>([]);

  const editable = useMemo(() => {
    return (
      contractData?.documentStatus === "FULLY_EXECUTED" ||
      contractData?.documentStatus === "PARTIALLY_EXECUTED" ||
      contractData?.documentStatus === "APPROVED" ||
      contractData?.documentStatus === "RESCINDED" ||
      contractData?.documentStatus === "ARCHIVED" ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        foldersEditIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT
      )
    );
  }, [ROLE_PERMISSIONS, contractData?.documentStatus, foldersEditIndex]);

  // const { loading: gettingArr } = useQuery(GET_DOCUMENT_SIGNERS_ARR, {
  //   variables: {
  //     document_id: params?.contract_id,
  //     project_id: params?.project_id,
  //   },
  //   skip: !Boolean(params?.contract_id) || !Boolean(params?.project_id),
  //   onCompleted: (data) => {
  //     const { getDocumentSigners } = data;
  //     const { status, fields } = getDocumentSigners;
  //     if (status) {
  //       const parsedJSON = Boolean(fields) ? JSON.parse(fields) : null;
  //       if (Boolean(parsedJSON)) {
  //         setSignersArr(parsedJSON);
  //       }
  //     }
  //   },
  //   fetchPolicy: "network-only",
  //   nextFetchPolicy: "network-only",
  // });

  // useEffect(() => {
  //   let tempSigners: any = [];
  //   if (contractData) {
  //     const additionalDetailsJSON = contractData?.additionalDetails;
  //     const parsedAdditionalDetailsJSON = Boolean(additionalDetailsJSON)
  //       ? JSON.parse(additionalDetailsJSON!)
  //       : null;
  //     if (Boolean(parsedAdditionalDetailsJSON)) {
  //       if (parsedAdditionalDetailsJSON?.SIGNERS) {
  //         tempSigners = [...parsedAdditionalDetailsJSON?.SIGNERS];
  //       }
  //     }
  //   }
  //   setSelectedSigners([...tempSigners]);
  // }, [contractData, setSelectedSigners]);

  // useEffect(() => {
  //   if (
  //     item.fieldMultiple &&
  //     Boolean(item.fieldArr) &&
  //     item.field_value !== "Not Provided"
  //   ) {
  //     setMultiPersonArr(item.fieldArr);
  //   }
  // }, [item.fieldArr, item.fieldMultiple, item.field_value]);

  // useEffect(() => {
  //   setLoading(gettingArr);
  // }, [gettingArr, setLoading]);

  return (
    <Fragment>
      {(item.field_type === "text" ||
        item.field_type === "date" ||
        item.field_type === "number" ||
        item.field_type === "currency" ||
        item.field_type === "percentage") && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={[styles.field_label, { fontSize: "14px" }]} noWrap>
            {item.display_name}
          </Typography>
          {(item.field_type === "text" ||
            item.field_type === "number" ||
            item.field_type === "currency" ||
            item.field_type === "percentage") && (
            <TextField
              disabled={editable}
              size="small"
              fullWidth
              type={item.field_type === "text" ? "text" : "number"}
              value={
                item.field_type === "text"
                  ? item.field_value
                  : !Boolean(item.field_value) ||
                    item.field_value === "Not Provided"
                  ? ""
                  : parseFloat(item.field_value.replaceAll(" ", ""))
              }
              onChange={(e) => {
                // const element = ref.current.dom.get(item.id!);
                // if (item.field_type === "currency") {
                //   element.innerText = `${currency}${e.target.value}`;
                // } else if (item.field_type === "percentage") {
                //   element.innerText = `${e.target.value}%`;
                // } else {
                //   element.innerText = e.target.value;
                // }
                const tempFields = [...fillableFields];
                tempFields[index].field_value = e.target.value;
                setFillableFields([...tempFields]);
              }}
              // placeholder={item.fieldDescription}
              // onFocus={() => {
              //   const element = ref.current.dom.get(item.id!);
              //   element.style.backgroundColor = `#FFFF00`;
              //   element.style.padding = "5px";
              //   element.scrollIntoView({
              //     behavior: "smooth",
              //     block: "start",
              //   });
              // }}
              // onBlur={() => {
              //   const element = ref.current.dom.get(item.id!);
              //   element.style.backgroundColor = `transparent`;
              //   element.style.padding = "0px";
              // }}
              inputProps={{ style: styles.text_input }}
            />
          )}
          {item.field_type === "date" && (
            <DatePicker
              disabled={editable}
              textValue={
                Boolean(item.field_value) && item.field_value !== "Not Provided"
                  ? moment(new Date(item.field_value)).format("DD MMMM YYYY")
                  : ""
              }
              value={
                Boolean(item.field_value) ? new Date(item.field_value) : null
              }
              onChange={(newValue: any) => {
                // const element = ref.current.dom.get(item.id!);
                // element.innerText = moment(newValue).format("DD MMMM YYYY");
                const tempFields = [...fillableFields];
                tempFields[index].field_value =
                  moment(newValue).format("YYYY-MM-DD");
                setFillableFields([...tempFields]);
              }}
              onOpen={() => {}}
              onCloseCustom={() => {}}
              // onOpen={() => {
              //   const element = ref.current.dom.get(item.id!);
              //   element.style.backgroundColor = `#FFFF00`;
              //   element.style.padding = "5px";
              //   element.scrollIntoView({
              //     behavior: "smooth",
              //     block: "start",
              //   });
              // }}
              // onCloseCustom={() => {
              //   const element = ref.current.dom.get(item.id!);
              //   element.style.backgroundColor = `transparent`;
              //   element.style.padding = "0px";
              // }}
            />
          )}
        </Grid>
      )}
      {/* {item.field_type === "fees" && !item.fieldMultiple && (
        <Fragment>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Box sx={[styles.flex_Acenter, styles.text_wrap]}>
              <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
                {item.display_name}
              </Typography>
              <Typography sx={[styles.text_input, { ml: 1 }]}>
                {item.field_value === "" || item.field_value === "Not Provided"
                  ? ""
                  : `${currency}${parseFloat(
                      item.field_value
                    ).toLocaleString()}`}
              </Typography>
            </Box>
          </Grid>
          {contractData?.documentStatus !== "FULLY_EXECUTED" &&
            contractData?.documentStatus !== "PARTIALLY_EXECUTED" &&
            contractData?.documentStatus !== "APPROVED" &&
            contractData?.documentStatus !== "RESCINDED" && (
              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                xs={4}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  size="small"
                  onClick={() => {
                    const element = ref.current.dom.get(item.id);
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                    setFeeFieldId(item.id);
                    // handleUpdate();
                    // setFeeCalculatorModal(true);
                  }}
                >
                  Calculate
                </Button>
              </Grid>
            )}
        </Fragment>
      )} */}
      {/* {item.field_type === "sign" && !item.fieldMultiple && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={[styles.field_label, { fontSize: "14px" }]} noWrap>
            {item.display_name}
          </Typography>
          <Autocomplete
            disabled={editable}
            size="small"
            fullWidth
            options={signersArr}
            disableClearable
            value={
              signersArr.length > 0
                ? signersArr.find(
                    (x: any) => x.anchor_string === item.field_value
                  ) || {
                    signer_email: "",
                    signer_name: "",
                    anchor_string: "",
                    user_type: "",
                  }
                : {
                    signer_email: "",
                    signer_name: "",
                    anchor_string: "",
                    user_type: "",
                  }
            }
            onChange={(_, newValue) => {
              let tempSigners = [...selectedSigners];
              const index1 = tempSigners.findIndex(
                (x: any) => x.anchor_string === item.field_value
              );
              if (index1 > -1) {
                tempSigners[index1] = newValue;
              } else {
                tempSigners.push(newValue);
              }
              setSelectedSigners([...tempSigners]);

              const tempFields = [...fillableFields];
              tempFields[index].field_value = newValue.anchor_string;
              setFillableFields([...tempFields]);

              const element = ref.current.dom.get(item.id!);
              element.innerText = `${newValue.anchor_string}`;
            }}
            onFocus={() => {
              const element = ref.current.dom.get(item.id!);
              element.style.backgroundColor = `#FFFF00`;
              element.style.padding = "5px";
              element.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
            onBlur={() => {
              const element = ref.current.dom.get(item.id!);
              element.style.backgroundColor = `transparent`;
              element.style.padding = "0px";
            }}
            getOptionLabel={(opt) => opt.signer_name}
            renderOption={(props1, opt, _, __) => (
              <li
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                {...props1}
              >
                <Typography textAlign="center">{opt.signer_name}</Typography>
                <Typography
                  sx={[styles.field_label, { fontSize: "13px" }]}
                >{`(${opt.user_type})`}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: styles.text_input,
                }}
              />
            )}
          />
        </Grid>
      )} */}
      {/* {item.fieldMultiple && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
            {item.display_name}
          </Typography>
          <Autocomplete
            disabled={editable}
            size="small"
            fullWidth
            options={multiPersonArr}
            disableClearable
            value={
              multiPersonArr.length > 0
                ? multiPersonArr.find(
                    (x: any) => x.name === item.field_value
                  ) || {
                    name: "",
                    email: "",
                  }
                : {
                    name: "",
                    email: "",
                  }
            }
            onChange={(_, newValue) => {
              const tempFields = [...fillableFields];
              tempFields[index].field_value = newValue.name;
              setFillableFields([...tempFields]);
              const element = ref.current.dom.get(item.id!);
              element.innerText =
                Boolean(item.resolveTo) && item.resolveTo === "email"
                  ? `${newValue.email}`
                  : `${newValue.name}`;
            }}
            onFocus={() => {
              const element = ref.current.dom.get(item.id!);
              element.style.backgroundColor = `#FFFF00`;
              element.style.padding = "5px";
              element.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
            onBlur={() => {
              const element = ref.current.dom.get(item.id!);
              element.style.backgroundColor = `transparent`;
              element.style.padding = "0px";
            }}
            getOptionLabel={(opt) => opt.name}
            renderOption={(props1, opt, _, __) => (
              <li
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
                {...props1}
              >
                <Typography textAlign="center">{opt.name}</Typography>
                <Typography
                  sx={[styles.field_label, { fontSize: "13px" }]}
                >{`(${opt.email})`}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  style: styles.text_input,
                }}
              />
            )}
          />
        </Grid>
      )} */}
      {item.field_type === "image" && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={[styles.field_label, { fontSize: "14px" }]}>
            {item.display_name}
          </Typography>
        </Grid>
      )}
    </Fragment>
  );
};

interface IProps {
  setFieldsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  contractData: IContract | null;
  refetch: any;
  currency: string;
  extractedContacts: IExtractedContact[];
}

const FillableFields = (props: IProps) => {
  const {
    setFieldsDrawerOpen,
    contractData,
    refetch,
    currency,
    extractedContacts,
  } = props;

  const { fillableFields } = useThirdPartyContract();

  const [selectedContactData, setSelectedContactData] =
    useState<IExtractedContact | null>(null);
  const [addContactModal, setAddContactModal] = useState(false);
  const [addAgentModal, setAddAgentModal] = useState(false);
  const [addContactMenuOpen, setAddContactMenuOpen] = useState(false);
  const [addContactMenuAnchor, setAddContactMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleAddContactMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAddContactMenuAnchor(e.currentTarget);
    setAddContactMenuOpen(true);
  };

  const handleAddContactMenuClose = () => {
    setAddContactMenuAnchor(null);
    setAddContactMenuOpen(false);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={styles.drawer_container}
      alignItems="center"
    >
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography sx={styles.drawer_header_text}>
          Fillable Fields & Contacts
        </Typography>
        <IconButton size="small" onClick={() => setFieldsDrawerOpen(false)}>
          <Close fontSize="small" />
        </IconButton>
      </Grid>
      {/* {feeCalculatorAdded && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={[styles.flex_Acenter, { justifyContent: "space-between" }]}>
            <Typography
              sx={[
                styles.field_label,
                { fontSize: "16px", mr: 1, fontWeight: 600 },
              ]}
            >
              Fee Calculator
            </Typography>
            <Button
              size="small"
              onClick={() => {
                if (
                  Boolean(ROLE_PERMISSIONS) &&
                  foldersEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]
                    ?.FOLDERS_EDIT
                ) {
                  handleUpdate();
                }
                setFeeCalculatorModal(true);
              }}
            >
              Calculate
            </Button>
          </Box>
        </Grid>
      )} */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_Acenter}>
          <Typography
            sx={[
              styles.field_label,
              { fontSize: "16px", mr: 1, fontWeight: 600 },
            ]}
          >
            {ContractDocxLabels.OVERALL_FIELDS}
          </Typography>
          <Chip sx={styles.chip} label={fillableFields.length} size="small" />
        </Box>
      </Grid>
      {fillableFields
        .filter((x) => x.display_name !== "contacts")
        .map((item, index) => (
          <FillableFieldItem
            key={item.id}
            item={item}
            index={index}
            // setFeeCalculatorModal={setFeeCalculatorModal}
            // selectedSigners={selectedSigners}
            // setSelectedSigners={setSelectedSigners}
            contractData={contractData}
            refetch={refetch}
            currency={currency}
          />
        ))}
      {/* <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_Acenter}>
          <Typography
            sx={[
              styles.field_label,
              { fontSize: "16px", mr: 1, fontWeight: 600 },
            ]}
          >
            {ContractDocxLabels.OVERALL_TAGS}
          </Typography>
          <Chip sx={styles.chip} label={tags.length} size="small" />
        </Box>
      </Grid>
      {tags.map((item, index) => (
        <Grid item lg={12} md={12} sm={12} xs={12} key={item.id}>
          <TagItem item={item} index={index} ref={ref} />
        </Grid>
      ))} */}
      {/* {feeCalculatorModal && (
        <FeeCalculatorModal
          open={feeCalculatorModal}
          setOpen={setFeeCalculatorModal}
          feeFieldId={feeFieldId}
          contractData={contractData}
          refetch={refetch}
          ref={ref}
        />
      )} */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_Acenter}>
          <Typography
            sx={[
              styles.field_label,
              { fontSize: "16px", mr: 1, fontWeight: 600 },
            ]}
          >
            Contacts
          </Typography>
          <Chip
            sx={styles.chip}
            label={extractedContacts.length}
            size="small"
          />
        </Box>
      </Grid>
      {extractedContacts.map((item, index) => (
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          key={`${item.first_name}${index}`}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ flexGrow: 1, mr: 0.5 }}>
              <Typography sx={[styles.field_label, { fontSize: "14px" }]}>{`${
                item?.first_name || ""
              } ${item?.last_name || ""}`}</Typography>
            </Box>
            <Box>
              <Button
                size="small"
                disableElevation
                disableRipple
                startIcon={<Add color="primary" fontSize="small" />}
                onClick={(e) => {
                  setSelectedContactData(item);
                  // setAddContactModal(true);
                  handleAddContactMenuOpen(e);
                }}
              >
                Add
              </Button>
              <Menu
                anchorEl={addContactMenuAnchor}
                open={addContactMenuOpen}
                onClose={handleAddContactMenuClose}
                sx={{
                  "& .MuiMenu-paper": {
                    border: "1px #00000030 solid",
                    borderRadius: "8px",
                    width: "120px",
                  },
                }}
                elevation={0}
              >
                <MenuList disablePadding>
                  <MenuItem
                    onClick={() => {
                      setAddContactModal(true);
                      handleAddContactMenuClose();
                    }}
                  >
                    <Typography
                      sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                    >
                      Contact
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAddAgentModal(true);
                      handleAddContactMenuClose();
                    }}
                  >
                    <Typography
                      sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                    >
                      Agent
                    </Typography>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Box>
        </Grid>
      ))}
      {addContactModal && (
        <AddContactModal
          open={addContactModal}
          handleClose={() => setAddContactModal(false)}
          selectedContactData={selectedContactData}
        />
      )}
      {addAgentModal && (
        <AddAgentModal
          open={addAgentModal}
          setOpen={setAddAgentModal}
          selectedContactData={selectedContactData}
        />
      )}
    </Grid>
  );
};

export default FillableFields;
