import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CheckBox,
  ChevronRight,
  Close,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { BlackActionContainedButton } from "common/Buttons";
import { GET_ALL_INDEXES, GET_ALL_INDEX_ITEMS } from "graphql/indexes";
import { LINK_DR_TO_CHECKLIST_ITEM } from "graphql/checklist";
import { IArr, IModalProps } from "models/common";
import { IIndex, IIndexItem } from "models/indexes";
import { useStore } from "utils/store";
import { images } from "assets/images";
import { colors } from "theme/colors";

interface ILinkDRModalProps extends IModalProps {
  refetch: any;
  id: string;
  selectedLinkDRItems: any;
}

function LinkDRModal(props: ILinkDRModalProps) {
  const {
    open,
    setOpen,
    refetch,
    id: checklistItemId,
    selectedLinkDRItems: initialSelectedLinkDRItems,
  } = props;

  const params = useParams();
  const { setLoading } = useStore();

  const [level1Folders, setLevel1Folders] = useState<IIndex[]>([]);
  const [nestedFolderArr, setNestedFolderArr] = useState<IArr[]>([]);
  const [nestedItemsArr, setNestedItemsArr] = useState<IIndexItem[]>([]);
  const [selectedLinkDRItems, setSelectedLinkDRItems] = useState<any>([]);

  const { loading: gettingLevel1Folders } = useQuery(GET_ALL_INDEXES, {
    variables: {
      filter: {
        project_id: params?.project_id,
      },
      sorted: "name",
    },
    skip: !params?.project_id,
    onCompleted: (data) => {
      setLoading(false);
      const { getRegisters } = data;
      const { status, registers } = getRegisters;
      if (status) {
        setLevel1Folders(registers);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: gettingNestedLevels } = useQuery(GET_ALL_INDEX_ITEMS, {
    variables: {
      filter: {
        project_id: params?.project_id,
        register_id: nestedFolderArr[nestedFolderArr.length - 1]?.id,
      },
      sorted: "name",
    },
    skip: !params?.project_id || nestedFolderArr.length === 0,
    onCompleted: (data) => {
      setLoading(false);
      const { getRegisterItems } = data;
      const { status, registerItems } = getRegisterItems;
      if (status) {
        setNestedItemsArr(registerItems);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireLinkApi, { loading: linking }] = useMutation(
    LINK_DR_TO_CHECKLIST_ITEM,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { linkRegisterToChecklist } = data;
        const { status, message } = linkRegisterToChecklist;
        handleClose();
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  useEffect(() => {
    setLoading(gettingLevel1Folders || gettingNestedLevels || linking);
  }, [gettingLevel1Folders, gettingNestedLevels, linking, setLoading]);

  useEffect(() => {
    if (Boolean(initialSelectedLinkDRItems)) {
      setSelectedLinkDRItems(initialSelectedLinkDRItems);
    }
  }, [initialSelectedLinkDRItems]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: "24px",
          overflow: "hidden",
        },
      }}
    >
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              py: 2,
              backgroundColor: "#B6BCC325",
              position: "relative",
              px: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  fontSize: "13px",
                  color: colors.primaryBlack,
                  fontWeight: 600,
                }}
              >
                Select Files/Folders
              </Typography>
            </Box>
          </Box>
          <Box sx={{ position: "absolute", top: 10, right: 10 }}>
            <IconButton size="small" onClick={handleClose}>
              <Close sx={{ color: "#00000055" }} fontSize="small" />
            </IconButton>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              height: "80vh",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
              <Grid container sx={{ height: "100%" }}>
                <Grid item lg={4} md={5} sm={5} xs={6} sx={{ height: "100%" }}>
                  <Box
                    sx={{
                      pt: 2,
                      borderRight: "1px #e6e6e6 solid",
                      overflow: "auto",
                      height: "100%",
                    }}
                  >
                    <Grid container>
                      {level1Folders.map((folder) => (
                        <Grid
                          item
                          key={folder.id}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              // backgroundColor:
                              //   selectedLinkDRItems.length > 0 &&
                              //   selectedLinkDRItems.some(
                              //     (x: any) => x.contentId === folder.id
                              //   )
                              //     ? "#41B19931"
                              //     : "transparent",
                              ":hover": {
                                backgroundColor:
                                  // selectedLinkDRItems.length > 0 &&
                                  // selectedLinkDRItems.some(
                                  //   (x: any) => x.contentId === folder.id
                                  // )
                                  //   ? "#41B19995":
                                  "#B6BCC330",
                              },
                            }}
                          >
                            <Checkbox
                              size="small"
                              sx={{ mr: -1, mt: 0.3 }}
                              checkedIcon={
                                <CheckBox
                                  fontSize="small"
                                  sx={{ color: "#41B199" }}
                                />
                              }
                              checked={
                                selectedLinkDRItems.length > 0 &&
                                selectedLinkDRItems.some(
                                  (x: any) => x.contentId === folder.id
                                )
                              }
                              onChange={(e) => {
                                const tempArr = [...selectedLinkDRItems];
                                if (tempArr.length > 0) {
                                  const isPresentIndex = tempArr.findIndex(
                                    (x) => x.contentId === folder.id
                                  );
                                  if (isPresentIndex > -1) {
                                    tempArr.splice(isPresentIndex, 1);
                                  } else {
                                    tempArr.push({
                                      type: "REGISTER",
                                      contentId: folder.id,
                                    });
                                  }
                                } else {
                                  tempArr.push({
                                    type: "REGISTER",
                                    contentId: folder.id,
                                  });
                                }
                                setSelectedLinkDRItems([...tempArr]);
                              }}
                            />
                            <Box
                              component="div"
                              sx={{
                                cursor: " pointer",
                                py: 1,
                                px: 2,
                                display: "flex",
                                alignItems: "center",
                                flexGrow: 1,
                                userSelect: "none",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setNestedFolderArr([
                                  { id: folder.id, name: folder.name },
                                ]);
                              }}
                            >
                              <img
                                src={images.FOLDER_NEW}
                                alt="folder"
                                style={{ height: 18 }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  color: colors.primaryBlack,
                                  ml: 1,
                                }}
                                noWrap
                              >
                                {folder.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item lg={8} md={7} sm={7} xs={6} sx={{ height: "100%" }}>
                  <Box
                    sx={{
                      pt: 2,
                      px: 1,
                      overflow: "auto",
                      height: "100%",
                    }}
                  >
                    <Box sx={{ height: "100%" }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        {nestedFolderArr.map((x, index) => (
                          <Box
                            key={x.id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ...(index <= nestedFolderArr.length - 1 && {
                                ml: 1,
                              }),
                            }}
                          >
                            <Box
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                const tempArr = [...nestedFolderArr];
                                const index = tempArr.findIndex(
                                  (y) => x.id === y.id
                                );
                                if (index > -1) {
                                  tempArr.splice(index + 1);
                                }
                                setNestedFolderArr([...tempArr]);
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  color: colors.primaryBlack,
                                  fontWeight: 600,
                                }}
                              >
                                {x.name}
                              </Typography>
                            </Box>
                            {index !== nestedFolderArr.length - 1 && (
                              <ChevronRight
                                sx={{
                                  color: "#00000055",
                                  ml: 1,
                                }}
                              />
                            )}
                          </Box>
                        ))}
                      </Box>
                      {nestedFolderArr.length > 0 &&
                        nestedItemsArr.length === 0 && (
                          <Box sx={{ height: "85%" }}>
                            <Box
                              sx={{
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <ErrorOutlineOutlined
                                  sx={{
                                    color: `${colors.primaryBlack}55`,
                                    fontSize: 30,
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    color: `${colors.primaryBlack}55`,
                                    ml: 1,
                                  }}
                                >
                                  No items
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      <Grid container>
                        {nestedItemsArr.length > 0 &&
                          nestedItemsArr.map((item) => (
                            <Grid
                              item
                              key={item.id}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  // backgroundColor:
                                  //   selectedLinkDRItems.length > 0 &&
                                  //   (selectedLinkDRItems.some(
                                  //     (x: any) => x.contentId === item.id
                                  //   ) ||
                                  //     selectedLinkDRItems.some((obj1: any) => {
                                  //       return nestedFolderArr.some(
                                  //         (obj2) => obj1.contentId === obj2.id
                                  //       );
                                  //     }))
                                  //     ? "#41B19931"
                                  //     : "transparent",
                                  ":hover": {
                                    backgroundColor:
                                      // selectedLinkDRItems.length > 0 &&
                                      // (selectedLinkDRItems.some(
                                      //   (x: any) => x.contentId === item.id
                                      // ) ||
                                      //   selectedLinkDRItems.some((obj1: any) => {
                                      //     return nestedFolderArr.some(
                                      //       (obj2) => obj1.contentId === obj2.id
                                      //     );
                                      //   }))
                                      //   ? "#41B19995" :
                                      "#B6BCC330",
                                  },
                                }}
                              >
                                <Checkbox
                                  size="small"
                                  sx={{ mr: -1, mt: 0.3 }}
                                  checkedIcon={
                                    selectedLinkDRItems.some((obj1: any) => {
                                      return nestedFolderArr.some(
                                        (obj2) => obj1.contentId === obj2.id
                                      );
                                    }) ? (
                                      <CheckBox
                                        fontSize="small"
                                        sx={{ color: "#00000070" }}
                                      />
                                    ) : (
                                      <CheckBox
                                        fontSize="small"
                                        sx={{ color: "#41B199" }}
                                      />
                                    )
                                  }
                                  checked={
                                    selectedLinkDRItems.length > 0 &&
                                    (selectedLinkDRItems.some(
                                      (x: any) => x.contentId === item.id
                                    ) ||
                                      selectedLinkDRItems.some((obj1: any) => {
                                        return nestedFolderArr.some(
                                          (obj2) => obj1.contentId === obj2.id
                                        );
                                      }))
                                  }
                                  disabled={selectedLinkDRItems.some(
                                    (obj1: any) => {
                                      return nestedFolderArr.some(
                                        (obj2) => obj1.contentId === obj2.id
                                      );
                                    }
                                  )}
                                  onChange={() => {
                                    const tempArr = [...selectedLinkDRItems];
                                    if (tempArr.length > 0) {
                                      const isPresentIndex = tempArr.findIndex(
                                        (x) => x.contentId === item.id
                                      );
                                      if (isPresentIndex > -1) {
                                        tempArr.splice(isPresentIndex, 1);
                                      } else {
                                        tempArr.push({
                                          type: item?.isRegister
                                            ? "REGISTER"
                                            : "REGISTERITEM",
                                          contentId: item.id,
                                        });
                                      }
                                    } else {
                                      tempArr.push({
                                        type: item?.isRegister
                                          ? "REGISTER"
                                          : "REGISTERITEM",
                                        contentId: item.id,
                                      });
                                    }
                                    setSelectedLinkDRItems([...tempArr]);
                                  }}
                                />
                                <Box
                                  key={item.id}
                                  component="div"
                                  sx={{
                                    cursor: item?.isRegister
                                      ? "pointer"
                                      : "default",
                                    py: 1,
                                    px: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    flexGrow: 1,
                                    userSelect: "none",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (item?.isRegister) {
                                      const tempArr = [...nestedFolderArr];
                                      tempArr.push({
                                        id: item.id,
                                        name: item.name,
                                      });
                                      setNestedFolderArr([...tempArr]);
                                    }
                                  }}
                                >
                                  {item.isRegister ? (
                                    <img
                                      src={images.FOLDER_NEW}
                                      alt="folder"
                                      style={{ width: 18 }}
                                    />
                                  ) : item.itemType === "CONTRACTS" ||
                                    item.itemType === "DOCUMENT" ? (
                                    <img
                                      src={images.CONTRACT_ICON_NEW}
                                      alt="folder"
                                      style={{ width: 18 }}
                                    />
                                  ) : (
                                    <img
                                      src={images.NEW_DOC_NEW}
                                      alt="folder"
                                      style={{
                                        width: 18,
                                        height: 18,
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      color: colors.primaryBlack,
                                      ml: 1,
                                    }}
                                    noWrap
                                  >
                                    {item.name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                width: "100%",
                py: 1,
                px: 1.5,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <BlackActionContainedButton
                size="small"
                disableElevation
                disableRipple
                sx={{ borderRadius: "20px", width: "120px" }}
                onClick={() => {
                  fireLinkApi({
                    variables: {
                      payload: {
                        id: checklistItemId,
                        projectId: params?.project_id,
                        documents: [...selectedLinkDRItems],
                      },
                    },
                  });
                }}
              >
                Save
              </BlackActionContainedButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default LinkDRModal;
