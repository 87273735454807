import React, { Dispatch, SetStateAction } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";

import { colors } from "theme/colors";

interface IIndividualLeftStepsProps {
  profilePicture: any;
  setProfilePicture: Dispatch<SetStateAction<any>>;
  activeSection: number;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

const sections = [
  "Personal Details",
  "Registration",
  "Bank Details",
  "Superannuation",
  "Emergency",
  "Socials",
];

function IndividualLeftSteps(props: IIndividualLeftStepsProps) {
  const { activeSection, setActiveSection, profilePicture } = props;

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Box component="div">
            {Boolean(profilePicture) ? (
              <Avatar
                sx={{ height: 55, width: 55 }}
                src={
                  typeof profilePicture === "string"
                    ? profilePicture
                    : URL.createObjectURL(profilePicture)
                }
              />
            ) : (
              <Avatar
                sx={{
                  height: 55,
                  width: 55,
                }}
              />
            )}
          </Box>
        </Box>
      </Grid>
      {sections.map((section, index) => (
        <Grid key={section} item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Box
              component="div"
              onClick={() => setActiveSection(index)}
              sx={{
                py: 1,
                borderRadius: "10px",
                backgroundColor:
                  activeSection === index ? "#41B19931" : "transparent",
                minWidth: 150,
                cursor: "pointer",
                ":hover": {
                  backgroundColor:
                    activeSection === index ? "#41B19995" : "#B6BCC325",
                },
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  fontSize: "13px",
                  color: colors.primaryBlack,
                  ...(activeSection === index && { fontWeight: 600 }),
                }}
              >
                {section}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default IndividualLeftSteps;
