import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { colors } from "theme/colors";

interface IProps {
  textValue: string;
  fullWidth?: boolean;
  onChange: (newDate: Date) => void;
  value: Date | null;
  disableFuture?: boolean;
  disablePast?: boolean;
}

const CustomDatePicker = (props: IProps) => {
  const { textValue, fullWidth, onChange, value, disablePast, disableFuture } =
    props;
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <TextField
      value={textValue}
      disabled
      size="small"
      sx={{
        ...(fullWidth === undefined && { width: "220px" }),
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: colors.primaryBlack,
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
      {...(fullWidth !== undefined && { fullWidth })}
      inputProps={{ style: { fontSize: "14px", color: colors.primaryBlack } }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                {...(disableFuture !== undefined && { disableFuture })}
                {...(disablePast !== undefined && { disablePast })}
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
                value={value}
                onChange={(value) => {
                  if (value) {
                    onChange && onChange(value);
                  }
                }}
                PopperProps={{ anchorEl: anchorEl }}
                renderInput={({
                  ref,
                  inputProps,
                  disabled,
                  onChange,
                  value,
                  ...other
                }) => (
                  <div ref={ref}>
                    <input
                      style={{ display: "none" }}
                      onChange={onChange}
                      disabled={disabled}
                      {...inputProps}
                    />
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        handleClick(e);
                        setOpenDatePicker(true);
                      }}
                    >
                      <CalendarTodayOutlined fontSize="small" />
                    </IconButton>
                  </div>
                )}
              />
            </LocalizationProvider>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomDatePicker;
