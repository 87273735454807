import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
	AppBar as MuiAppBar,
	Box,
	Drawer as MuiDrawer,
	Toolbar,
	AppBarProps,
	CssBaseline,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	listItemButtonClasses,
	IconButton,
} from "@mui/material";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import { v4 as uuid } from "uuid";

import { GET_CONTRACT_FILE, GET_PROJ_DEETS_CURRENCY } from "graphql/contracts";
import { getPermissions } from "permissions/utils";
import { useStore } from "utils/store";
import { CurrencyMapping } from "utils/constants";
import { useThirdPartyContract } from "hooks/useThirdPartyContract";
import { images } from "assets/images";
import { colors } from "theme/colors";
import theme from "theme/muiTheming";
import { IPhoneNumber } from "models/common";

import Header from "./Header";
import FillableFields from "./FillableFields";
import { IContract } from "../ContractDocEditor";

const drawerWidth = 400;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": { ...openedMixin(theme) },
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

// const ActionsDrawerItems = [
//   {
//     id: 1,
//     title: "View contract information",
//     icon: images.CONTRACT_INFO,
//     selectedIcon: images.CONTRACT_INFO_SEELCTED,
//   },
//   // {
//   //   id: 2,
//   //   title: "Reminder",
//   //   icon: images.CONTRACT_REMINDER,
//   //   selectedIcon: images.CONTRACT_REMINDER_SELECTED,
//   // },
//   {
//     id: 3,
//     title: "Status",
//     icon: images.CONTRACT_STATUS,
//     selectedIcon: images.CONTRACT_STATUS_SELECTED,
//   },
//   {
//     id: 4,
//     title: "Version History",
//     icon: images.CONTRACT_VERSION_HISTORY,
//     selectedIcon: images.CONTRACT_VERSION_HISTORY_SEELCTED,
//   },
//   {
//     id: 5,
//     title: "Notes",
//     icon: images.CONTRACT_NOTES,
//     selectedIcon: images.CONTRACT_NOTES_SELECTED,
//   },
//   // {
//   //   id: 6,
//   //   title: "Track Changes",
//   //   icon: images.CONTRACT_TRACK_CHANGES,
//   //   selectedIcon: images.CONTRACT_TRACK_CHANGES_SELECTED,
//   // },
//   {
//     id: 7,
//     title: "Collaboration",
//     icon: images.CONTRACT_COLLABRATION,
//     selectedIcon: images.CONTRACT_COLLABRATION_SELECTED,
//   },
//   // {
//   //   id: 8,
//   //   title: "Comments",
//   //   icon: images.CONTRACT_COMMENTS,
//   //   selectedIcon: images.CONTRACT_COMMENTS_SELECTED,
//   // },
// ];

export default interface IExtractedContact {
	first_name: string;
	last_name: string;
	mobile: IPhoneNumber;
	address: string;
	role: string;
	email: string;
}

export const PdfContractFillableFields = () => {
	const params = useParams();
	const { setLoading } = useStore();
	const { setFillableFields } = useThirdPartyContract();
	const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
	const foldersViewIndex = useMemo(
		() =>
			ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
				x.hasOwnProperty("FOLDERS_VIEW")
			),
		[ROLE_PERMISSIONS]
	);

	const [contractData, setContractData] = useState<IContract | null>(null);
	const [currency, setCurrency] = useState("");
	const [fieldsDrawerOpen, setFieldsDrawerOpen] = useState(true);
	const [extractedContacts, setExtractedContacts] = useState<
		IExtractedContact[]
	>([]);

	const { loading, refetch } = useQuery(GET_CONTRACT_FILE, {
		variables: { payload: { documentId: params?.contract_id } },
		skip:
			!params?.contract_id ||
			!(
				Boolean(ROLE_PERMISSIONS) &&
				foldersViewIndex !== undefined &&
				ROLE_PERMISSIONS[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW
			),
		onCompleted: (data) => {
			setLoading(false);
			const { getDocumentFile } = data;
			const { status, document, file, fileExists, signedFile } =
				getDocumentFile;
			if (status) {
				setContractData({ ...document, file, fileExists, signedFile });
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const { loading: gettingCurrency } = useQuery(GET_PROJ_DEETS_CURRENCY, {
		variables: { id: params?.project_id },
		skip:
			!params?.project_id ||
			!(
				Boolean(ROLE_PERMISSIONS) &&
				foldersViewIndex !== undefined &&
				ROLE_PERMISSIONS[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW
			),
		onCompleted: (data) => {
			const { project } = data;
			const { status, project: rawProject } = project;
			if (status && Boolean(rawProject)) {
				let currencyUnit = "AUD";
				if (rawProject?.currency) {
					currencyUnit = rawProject.currency;
				}

				setCurrency((CurrencyMapping as any)[currencyUnit]);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	// const { loading: gettingFields, refetch: refetchFields } = useQuery(
	//   GET_FIELDS_FOR_THIRD_PARTY_DOCUMENT,
	//   {
	//     variables: {
	//       project_id: params?.project_id,
	//       document_id: params?.contract_id,
	//     },
	//     onCompleted: (data) => {
	//       const { getFieldsForThirdPartyDocument } = data;
	//       const { status, fields } = getFieldsForThirdPartyDocument;
	//       if (status) {
	//         if (Boolean(fields)) {
	//           let fieldsJSON = JSON.parse(fields);
	//           if (Boolean(fieldsJSON)) {
	//             setFillableFields(
	//               fieldsJSON.map((x: any) => ({
	//                 ...x,
	//                 id: Boolean(x.id) ? x.id : uuid(),
	//               }))
	//             );
	//             for (let i = 0; i < fieldsJSON.length; i = i + 1) {
	//               if (fieldsJSON[i].display_name === "contacts") {
	//                 setExtractedContacts(fieldsJSON[i].field_value);
	//                 break;
	//               }
	//             }
	//           } else {
	//             setFillableFields([]);
	//           }
	//         }
	//       }
	//     },
	//     fetchPolicy: "network-only",
	//     nextFetchPolicy: "network-only",
	//   }
	// );

	useEffect(() => {
		if (Boolean(contractData) && Boolean(contractData?.additionalDetails)) {
			let fieldsJSON = JSON.parse(contractData?.additionalDetails!);
			if (Boolean(fieldsJSON)) {
				const fillFields = Boolean(fieldsJSON?.fillable_fields)
					? fieldsJSON?.fillable_fields
					: [];
				setFillableFields(
					fillFields.map((x: any) => ({
						...x,
						id: Boolean(x.id) ? x.id : uuid(),
					}))
				);
				for (let i = 0; i < fillFields.length; i = i + 1) {
					if (fillFields[i].display_name === "contacts") {
						setExtractedContacts(fillFields[i].field_value);
						break;
					}
				}
			} else {
				setFillableFields([]);
			}
		}
	}, [contractData, setFillableFields]);

	useEffect(() => {
		setLoading(gettingCurrency || loading);
	}, [gettingCurrency, loading, setLoading]);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				color="secondary"
				elevation={0}
				sx={{ borderBottom: `1px solid ${colors.borderColor}` }}
			>
				<Header
					contractData={contractData}
					refetch={() => {
						refetch();
					}}
					//   selectedSigners={selectedSigners}
					//   initialContractDocContent={initialContractDocContent}
				/>
			</AppBar>
			<Drawer variant="permanent" anchor="left" open={fieldsDrawerOpen}>
				<Toolbar />
				<Box
					sx={{
						minHeight: "calc(100% - 65px)",
						display: "flex",
						overflowY: "auto",
					}}
				>
					<List
						sx={{
							width: `calc(${theme.spacing(7)} + 1px)`,
							[theme.breakpoints.up("sm")]: {
								width: `calc(${theme.spacing(8)} + 1px)`,
							},
							...(fieldsDrawerOpen && {
								borderRight: `1px solid #e0e0e0`,
							}),
						}}
					>
						<ListItem disablePadding>
							{fieldsDrawerOpen && (
								<ListItemButton
									sx={{
										minHeight: 48,
										justifyContent: fieldsDrawerOpen
											? "initial"
											: "center",
										px: 2.5,
										[`&.${listItemButtonClasses.selected}`]: {
											backgroundColor: colors.secondary,
											borderLeft: `5px solid ${colors.primary}`,
											borderRadius: 0,
											":hover": {
												backgroundColor: "transparent",
											},
										},
									}}
									selected={fieldsDrawerOpen}
									onClick={() => {
										setFieldsDrawerOpen(!fieldsDrawerOpen);
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: fieldsDrawerOpen ? 3 : "auto",
											justifyContent: "center",
										}}
									>
										{fieldsDrawerOpen ? (
											<img
												src={
													images.CONTRACT_FILLABLE_FIELDS_SELECTED
												}
												alt="icon"
											/>
										) : (
											<img
												src={images.CONTRACT_FILLABLE_FIELDS}
												alt="icon"
											/>
										)}
									</ListItemIcon>
								</ListItemButton>
							)}
							{!fieldsDrawerOpen && (
								<Box sx={{ minHeight: 48, px: 1.25 }}>
									<IconButton
										onClick={() => {
											setFieldsDrawerOpen(!fieldsDrawerOpen);
										}}
										size="large"
										sx={{
											":hover": { backgroundColor: "#41B19931" },
										}}
									>
										<img
											src={images.CONTRACT_FILLABLE_FIELDS_SELECTED}
											alt="icon"
											style={{ width: 18 }}
										/>
									</IconButton>
								</Box>
							)}
						</ListItem>
					</List>
					{fieldsDrawerOpen && (
						<Box
							sx={{
								width: `calc(100% - (${theme.spacing(7)} + 1px))`,
								[theme.breakpoints.up("sm")]: {
									width: `calc(100% - (${theme.spacing(8)} + 1px))`,
								},
								height: "100%",
								overflowY: "auto",
							}}
						>
							<FillableFields
								setFieldsDrawerOpen={setFieldsDrawerOpen}
								contractData={contractData}
								refetch={() => {
									refetch();
								}}
								currency={currency}
								extractedContacts={extractedContacts}
							/>
						</Box>
					)}
				</Box>
			</Drawer>
			<Box component="main" sx={{ width: "100%", overflowX: "auto" }}>
				<Toolbar />
				<iframe
					src={contractData?.file}
					title="Test"
					style={{
						width: "calc(100vw - 68px)",
						height: "calc(100vh - 64px)",
					}}
				/>
			</Box>
			{/* <Drawer variant="permanent" anchor="right" open={actionsDrawerOpen}>
        <Toolbar />
        <Box
          sx={{
            minHeight: "calc(100% - 65px)",
            display: "flex",
          }}
        >
          {actionsDrawerOpen && (
            <Box
              sx={{
                width: `calc(100% - (${theme.spacing(7)} + 1px))`,
                [theme.breakpoints.up("sm")]: {
                  width: `calc(100% - (${theme.spacing(8)} + 1px))`,
                },
                height: "100%",
                overflowY: "auto",
              }}
            >
              {selectedActionsDrawerItem === 1 && (
                <ContractInfo
                  setActionsDrawerOpen={setActionsDrawerOpen}
                  contractData={contractData}
                  refetch={refetch}
                />
              )}
              {selectedActionsDrawerItem === 3 && (
                <StatusRecord setActionsDrawerOpen={setActionsDrawerOpen} />
              )}
              {selectedActionsDrawerItem === 4 && (
                <VersionHistory setActionsDrawerOpen={setActionsDrawerOpen} />
              )}
              {selectedActionsDrawerItem === 5 && (
                <Notes setActionsDrawerOpen={setActionsDrawerOpen} />
              )}
            </Box>
          )}
          <List
            sx={{
              width: `calc(${theme.spacing(7)} + 1px)`,
              [theme.breakpoints.up("sm")]: {
                width: `calc(${theme.spacing(8)} + 1px)`,
              },
              height: "100%",
              ...(actionsDrawerOpen && { borderLeft: `1px solid #e0e0e0` }),
            }}
          >
            {ActionsDrawerItems.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: actionsDrawerOpen ? "initial" : "center",
                    px: 2.5,
                    [`&.${listItemButtonClasses.selected}`]: {
                      backgroundColor: colors.secondary,
                      borderLeft: `5px solid ${colors.primary}`,
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                  selected={item.id === selectedActionsDrawerItem}
                  onClick={() => {
                    setSelectedActionsDrawerItem(item.id);
                    if (item.id !== 7) {
                      if (
                        actionsDrawerOpen &&
                        selectedActionsDrawerItem === item.id
                      ) {
                        setSelectedActionsDrawerItem(null);
                        setActionsDrawerOpen(!actionsDrawerOpen);
                      } else {
                        setActionsDrawerOpen(true);
                      }
                    } else {
                      setActionsDrawerOpen(false);
                      setCollabrationModal(true);
                    }
                  }}
                >
                  <Tooltip title={item.title}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          selectedActionsDrawerItem === item.id
                            ? item.selectedIcon
                            : item.icon
                        }
                        alt="icon"
                      />
                    </ListItemIcon>
                  </Tooltip>
                </ListItemButton>
              </ListItem>
            ))}
            {Boolean(contractData?.signedFile) && (
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: actionsDrawerOpen ? "initial" : "center",
                    px: 2.5,
                    [`&.${listItemButtonClasses.selected}`]: {
                      backgroundColor: colors.secondary,
                      borderLeft: `5px solid ${colors.primary}`,
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                  onClick={() => {
                    var hiddenElement = document.createElement("a");
                    hiddenElement.href = contractData?.signedFile!;
                    hiddenElement.target = "_blank";
                    hiddenElement.download = `${contractData?.name}.pdf`;
                    hiddenElement.click();
                  }}
                >
                  <Tooltip title="Signed Document">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                      }}
                    >
                      <PictureAsPdf sx={{ color: colors.error }} />
                    </ListItemIcon>
                  </Tooltip>
                </ListItemButton>
              </ListItem>
            )}
          </List>
          {collabrationModal && (
            <CollabrationModal
              open={collabrationModal}
              setOpen={setCollabrationModal}
            />
          )}
        </Box>
      </Drawer> */}
		</Box>
	);
};
