import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  menuItemClasses,
} from "@mui/material";
import { FiberManualRecord, Share } from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IChecklist } from "models/checklists";
import { EXPORT_DELIVERY_REPORT } from "graphql/reports";
import { useStore } from "utils/store";
import { EXPORT_CHECKLIST_ZIP } from "graphql/checklist";
import { images } from "assets/images";
import { colors } from "theme/colors";

import ExportFeedbackModal from "./ExportFeedbackModal";
import { ProjectDetailsStyles as styles } from "../styles";
import {
  BlackActionContainedButton,
  NewGreenPrimaryContainedButton,
} from "common/Buttons";

interface IMetricesProps {
  checklistDetails: IChecklist | null;
  barGraphData: any;
}

function Metrices(props: IMetricesProps) {
  const { barGraphData } = props;

  const params = useParams();
  const { setLoading } = useStore();

  const [openReportMenu, setOpenReportMenu] = useState(false);
  const [reportMEnuAnchor, setReportMEnuAnchor] = useState<HTMLElement | null>(
    null
  );
  const [exportFeedbackModal, setExportFeedbackModal] = useState(false);
  const [exportMessage, setExportMessage] = useState("");

  const [getDeliveryReport, { loading: generatingReport }] = useLazyQuery(
    EXPORT_DELIVERY_REPORT,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [exportDeliveryZip, { loading: exporting }] = useLazyQuery(
    EXPORT_CHECKLIST_ZIP,
    {
      onCompleted: (data) => {
        const { getChecklistZip } = data;
        const { status, message } = getChecklistZip;
        if (status) {
          setExportFeedbackModal(true);
          setExportMessage(message);
        } else {
          toast.error(message, { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const handleReportMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setReportMEnuAnchor(e.currentTarget);
    setOpenReportMenu(true);
  };

  const handleReportMenuClose = () => {
    setReportMEnuAnchor(null);
    setOpenReportMenu(false);
  };

  useEffect(() => {
    setLoading(exporting || generatingReport);
  }, [exporting, generatingReport, setLoading]);

  return (
    <Box sx={{ border: "1px transparent solid", mt: -3.5 }}>
      <Grid container spacing={2} alignItems="center">
        {/* <Grid item>
        <Box
          sx={{
            backgroundColor: "#B6BCC320",
            px: 1.5,
            py: 0.5,
            borderRadius: "15px",
            height: 60,
            display: "flex",
            // justifyContent: "center",
            // flexDirection: "column",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: colors.primaryBlack,
              fontSize: "11px",
              fontWeight: 600,
            }}
          >
            Project Delivery Date
          </Typography>
          <Box
            sx={[
              styles.flex_Acenter,
              { justifyContent: "center", flexGrow: 1 },
            ]}
          >
            <img src={images.FILM_REEL_DARK} width={15} alt="reel" />
            <Typography
              sx={{
                color: colors.primaryBlack,
                fontSize: "11px",
                ml: 0.75,
              }}
            >
              {Boolean(checklistDetails?.deliveryDate)
                ? moment(checklistDetails?.deliveryDate).format("DD MMMM YYYY")
                : ""}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Box
          sx={{
            backgroundColor: "#B6BCC320",
            py: 0.5,
            px: 1.5,
            borderRadius: "15px",
            height: 60,
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            // flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: colors.primaryBlack,
              fontSize: "11px",
              fontWeight: 600,
            }}
          >
            Post-production Dates
          </Typography>
          {Boolean(postProdDate) && (
            <Box sx={[styles.flex_Acenter, { position: "relative" }]}>
              <FiberManualRecord sx={{ fontSize: 9, color: "#d6d6d6" }} />
              <Typography
                sx={{
                  color: colors.primaryBlack,
                  fontSize: "11px",
                  ml: 0.75,
                }}
                noWrap
              >
                {Boolean(postProdDate)
                  ? moment(postProdDate).format("DD MMMM YYYY")
                  : ""}
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  height: "13px",
                  borderLeft: "1px #d6d6d6 solid",
                  top: 10,
                  left: 4,
                }}
              />
            </Box>
          )}
          {Boolean(completionDate) && (
            <Box sx={styles.flex_Acenter}>
              <FiberManualRecord sx={{ fontSize: 9, color: "#d6d6d6" }} />
              <Typography
                sx={{
                  color: colors.primaryBlack,
                  fontSize: "11px",
                  ml: 0.75,
                }}
              >
                {Boolean(completionDate)
                  ? moment(completionDate).format("DD MMMM YYYY")
                  : ""}
              </Typography>
            </Box>
          )}
          {(!Boolean(postProdDate) || !Boolean(completionDate)) && (
            <Box sx={styles.flex_Acenter}>
              <FiberManualRecord sx={{ fontSize: 8, color: "#0f0f0f80" }} />
              <Typography
                sx={{
                  color: colors.primaryBlack,
                  fontSize: "11px",
                  ml: 0.75,
                }}
              >
                {Boolean(completionDate)
                  ? moment(completionDate).format("DD MMMM YYYY")
                  : ""}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid> */}
        <Grid item flexGrow={1}>
          <Box>
            <Box
              sx={{
                // px: 1.5,
                py: 0.5,
                height: 60,
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                flexDirection: "column",
              }}
            >
              <Box sx={[styles.flex_Acenter, { width: "350px" }]}>
                <Typography
                  sx={{
                    color: colors.primaryBlack,
                    fontSize: "11px",
                    fontWeight: 600,
                    // textAlign: "center",
                    flexGrow: 1,
                  }}
                >
                  Project Delivery Status:{" "}
                  <span style={{ color: `${colors.primaryBlack}80` }}>
                    {`${
                      Boolean(barGraphData) && barGraphData?.total > 0
                        ? Math.round(
                            (barGraphData?.completed / barGraphData?.total) *
                              100
                          )
                        : "0"
                    }% Completed`}
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "20px",
                    width: 350,
                    display: "flex",
                    mb: 0.25,
                    backgroundColor: "#e6e6e6",
                    ...(Boolean(barGraphData) &&
                      barGraphData?.total === 0 && { p: 0.4 }),
                  }}
                >
                  {/* PENDING */}
                  <Box
                    sx={{
                      width:
                        Boolean(barGraphData) && barGraphData?.total > 0
                          ? `${
                              (barGraphData?.pending / barGraphData?.total) *
                              100
                            }%`
                          : "",
                      backgroundColor: "#FFA027",
                      p:
                        Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.pending / barGraphData?.total > 0
                          ? 0.4
                          : 0,
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                      ...(Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.pending === barGraphData?.total && {
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }),
                    }}
                  />
                  {/* COMPLETED */}
                  <Box
                    sx={{
                      width:
                        Boolean(barGraphData) && barGraphData?.total > 0
                          ? `${
                              (barGraphData?.completed / barGraphData?.total) *
                              100
                            }%`
                          : "",
                      backgroundColor: "#073577",
                      p:
                        Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.completed / barGraphData?.total > 0
                          ? 0.4
                          : 0,
                      ...(Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.pending / barGraphData?.total === 0 && {
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }),
                      ...(Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.pending + barGraphData?.completed ===
                          barGraphData?.total && {
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }),
                    }}
                  />
                  {/* REJECTED */}
                  <Box
                    sx={{
                      width:
                        Boolean(barGraphData) && barGraphData?.total > 0
                          ? `${
                              (barGraphData?.rejected / barGraphData?.total) *
                              100
                            }%`
                          : "",
                      backgroundColor: "#E3323B",
                      p:
                        Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.rejected / barGraphData?.total > 0
                          ? 0.4
                          : 0,
                      ...(Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.pending / barGraphData?.total +
                          barGraphData?.completed / barGraphData?.total ===
                          0 && {
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }),
                      ...(Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.pending +
                          barGraphData?.completed +
                          barGraphData?.rejected ===
                          barGraphData?.total && {
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }),
                    }}
                  />
                  {/* ACCEPTED */}
                  <Box
                    sx={{
                      width:
                        Boolean(barGraphData) && barGraphData?.total > 0
                          ? `${
                              (barGraphData?.accepted / barGraphData?.total) *
                              100
                            }%`
                          : "",
                      backgroundColor: "#409091",
                      p:
                        Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.accepted / barGraphData?.total > 0
                          ? 0.4
                          : 0,
                      ...(Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.pending / barGraphData?.total +
                          barGraphData?.completed / barGraphData?.total +
                          barGraphData?.rejected / barGraphData?.total ===
                          0 && {
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }),
                      ...(Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.pending +
                          barGraphData?.completed +
                          barGraphData?.rejected +
                          barGraphData?.accepted ===
                          barGraphData?.total && {
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }),
                    }}
                  />
                  {/* NOT REQUIRED */}
                  <Box
                    sx={{
                      width:
                        Boolean(barGraphData) && barGraphData?.total > 0
                          ? `${
                              (barGraphData?.not_required /
                                barGraphData?.total) *
                              100
                            }%`
                          : "",
                      backgroundColor: "#910C00",
                      p:
                        Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.not_required / barGraphData?.total > 0
                          ? 0.4
                          : 0,
                      ...(Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.pending / barGraphData?.total +
                          barGraphData?.completed / barGraphData?.total +
                          barGraphData?.rejected / barGraphData?.total +
                          barGraphData?.accepted / barGraphData?.total ===
                          0 && {
                          borderTopLeftRadius: "20px",
                          borderBottomLeftRadius: "20px",
                        }),
                      ...(Boolean(barGraphData) &&
                        barGraphData?.total > 0 &&
                        barGraphData?.pending +
                          barGraphData?.completed +
                          barGraphData?.rejected +
                          barGraphData?.accepted +
                          barGraphData?.not_required ===
                          barGraphData?.total && {
                          borderTopRightRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }),
                    }}
                  />
                </Box>
                <Box sx={[styles.flex_JCsb_Acenter, { width: "350px" }]}>
                  <Box sx={styles.flex_Acenter}>
                    <FiberManualRecord
                      sx={{ fontSize: 15, color: "#FFA027" }}
                    />
                    <Typography sx={{ fontSize: "10px" }}>Pending</Typography>
                  </Box>
                  <Box sx={styles.flex_Acenter}>
                    <FiberManualRecord
                      sx={{ fontSize: 15, color: "#073577" }}
                    />
                    <Typography sx={{ fontSize: "10px" }}>Completed</Typography>
                  </Box>
                  <Box sx={styles.flex_Acenter}>
                    <FiberManualRecord
                      sx={{ fontSize: 15, color: "#E3323B" }}
                    />
                    <Typography sx={{ fontSize: "10px" }}>Rejected</Typography>
                  </Box>
                  <Box sx={styles.flex_Acenter}>
                    <FiberManualRecord
                      sx={{ fontSize: 15, color: "#409091" }}
                    />
                    <Typography sx={{ fontSize: "10px" }}>Accepted</Typography>
                  </Box>
                  <Box sx={styles.flex_Acenter}>
                    <FiberManualRecord
                      sx={{ fontSize: 15, color: "#910C00" }}
                    />
                    <Typography sx={{ fontSize: "10px" }}>
                      Not Required
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* <Grid item>
        <Box
          sx={{
            backgroundColor: "#B6BCC320",
            py: 0.5,
            px: 1.5,
            borderRadius: "15px",
            height: 60,
            display: "flex",
            // justifyContent: "center",
            // flexDirection: "column",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: colors.primaryBlack,
              fontSize: "11px",
              fontWeight: 600,
            }}
          >
            For Delivery Enquires:
          </Typography>
          <Box sx={styles.flex_Acenter}>
            <Person sx={{ fontSize: 12, color: "#d6d6d6" }} />
            <Typography
              sx={{
                color: colors.primaryBlack,
                fontSize: "11px",
                ml: 0.75,
              }}
              noWrap
            >
              {checklistDetails?.clientContactName || ""}
            </Typography>
          </Box>
          <Box sx={styles.flex_Acenter}>
            <Call sx={{ fontSize: 12, color: "#d6d6d6" }} />
            <Typography
              sx={{
                color: colors.primaryBlack,
                fontSize: "11px",
                ml: 0.75,
              }}
            >
              {checklistDetails?.clientContactNumber
                ? `+61 ${checklistDetails?.clientContactNumber}`
                : ""}
            </Typography>
          </Box>
        </Box>
      </Grid> */}
        <Grid item>
          <BlackActionContainedButton
            disableElevation
            disableRipple
            sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
            startIcon={<Share fontSize="small" color="secondary" />}
            onClick={() => {
              exportDeliveryZip({
                variables: {
                  checklistId: params?.checklist_id,
                  projectId: params?.project_id,
                },
              });
            }}
          >
            Export
          </BlackActionContainedButton>
        </Grid>
        <Grid item>
          <NewGreenPrimaryContainedButton
            sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
            disableElevation
            disableRipple
            startIcon={
              <img
                src={images.DOWNLOAD_ICON}
                alt="download"
                style={{ height: 15 }}
              />
            }
            onClick={handleReportMenuOpen}
          >
            Generate Report
          </NewGreenPrimaryContainedButton>
          <Menu
            open={openReportMenu}
            onClose={handleReportMenuClose}
            anchorEl={reportMEnuAnchor}
            elevation={0}
            sx={{
              "& .MuiMenu-paper": {
                mt: 1,
                border: "1px #00000030 solid",
                borderRadius: "15px",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                getDeliveryReport({
                  variables: {
                    project_id: params?.project_id,
                    checklist_id: params?.checklist_id,
                    report_type: "internal",
                  },
                  onCompleted: (data) => {
                    setLoading(false);
                    const { generateDeliveryReport } = data;
                    if (generateDeliveryReport) {
                      const { report } = generateDeliveryReport;
                      if (Boolean(report)) {
                        var hiddenElement = document.createElement("a");
                        hiddenElement.href =
                          "data:application/pdf;base64," + encodeURI(report);
                        hiddenElement.target = "_blank";
                        hiddenElement.download = `Internal Delivery Report.pdf`;
                        hiddenElement.click();
                      } else {
                        toast.error("Something went wrong", { delay: 10 });
                      }
                    }
                  },
                });
                handleReportMenuClose();
              }}
              sx={{
                [`&.${menuItemClasses.selected}`]: {
                  backgroundColor: "#41B19931",
                  "&:hover": { backgroundColor: "#41B19995" },
                },
                "&:hover": { backgroundColor: "#B6BCC330" },
              }}
            >
              <Typography
                sx={{ fontSize: "14px", color: colors.secondaryBlack }}
              >
                Internal Delivery Report
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                getDeliveryReport({
                  variables: {
                    project_id: params?.project_id,
                    checklist_id: params?.checklist_id,
                    report_type: "external",
                  },
                  onCompleted: (data) => {
                    setLoading(false);
                    const { generateDeliveryReport } = data;
                    if (generateDeliveryReport) {
                      const { report } = generateDeliveryReport;
                      if (Boolean(report)) {
                        var hiddenElement = document.createElement("a");
                        hiddenElement.href =
                          "data:application/pdf;base64," + encodeURI(report);
                        hiddenElement.target = "_blank";
                        hiddenElement.download = `External Delivery Report.pdf`;
                        hiddenElement.click();
                      } else {
                        toast.error("Something went wrong", { delay: 10 });
                      }
                    }
                  },
                });
                handleReportMenuClose();
              }}
              sx={{
                [`&.${menuItemClasses.selected}`]: {
                  backgroundColor: "#41B19931",
                  "&:hover": { backgroundColor: "#41B19995" },
                },
                "&:hover": { backgroundColor: "#B6BCC330" },
              }}
            >
              <Typography
                sx={{ fontSize: "14px", color: colors.secondaryBlack }}
              >
                External Delivery Report
              </Typography>
            </MenuItem>
          </Menu>
        </Grid>
        {exportFeedbackModal && (
          <ExportFeedbackModal
            open={exportFeedbackModal}
            setOpen={setExportFeedbackModal}
            message={exportMessage}
            setMessage={setExportMessage}
          />
        )}
      </Grid>
    </Box>
  );
}

export default Metrices;
