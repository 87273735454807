import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Grid, Typography } from "@mui/material";

import { RouteNames } from "routes/routeNames";
import { useViewContactInfoModal } from "hooks/useViewContactInfoModal";
import { colors } from "theme/colors";

import { IContactSearchResult } from "./utils";

interface IContactSearchCardProps {
  contactData: IContactSearchResult;
  handleClose: () => void;
}

const ContactSearchCard = (props: IContactSearchCardProps) => {
  const { contactData, handleClose } = props;

  const viewContactModal = useViewContactInfoModal();
  const navigate = useNavigate();

  return (
    <Box
      sx={{ cursor: "pointer" }}
      onClick={() => {
        viewContactModal.onOpen(contactData.id);
        navigate(
          `${RouteNames.PROJECTS}/${contactData?.assignedProject?.id}/project-contacts`
        );
        handleClose();
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {contactData?.profile_picture ? (
            <Avatar
              sx={{
                width: "50px",
                height: "50px",
              }}
              src={contactData?.profile_picture}
            />
          ) : (
            <Avatar
              sx={{
                width: "50px",
                height: "50px",
                backgroundColor: "#BFBDBC22",
                color: "#e0e0e0",
              }}
            />
          )}
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography sx={{ color: colors.primaryBlack, fontSize: "14px" }}>
            {contactData?.fullName}
          </Typography>
          <Typography sx={{ color: "#00000060", fontSize: "13px" }}>
            {`${contactData?.assignedProject?.name}`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactSearchCard;
