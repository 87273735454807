import { create } from "zustand";
import { IProjectPeopleMenuItem } from "models/common";

interface IProjectPeopleMenu {
  isOpen: boolean;
  anchor: null | HTMLElement;
  selectedMenuItem: IProjectPeopleMenuItem | null;
  onOpen: (target: any) => void;
  onClose: () => void;
  onMenuItemClick: (menuItem: IProjectPeopleMenuItem | null) => void;
}

export const useProjectPeopleMenu = create<IProjectPeopleMenu>((set) => ({
  isOpen: false,
  anchor: null,
  selectedMenuItem: null,
  onOpen: (target) => set({ isOpen: true, anchor: target }),
  onClose: () => set({ isOpen: false, anchor: null }),
  onMenuItemClick: (menuItem: IProjectPeopleMenuItem | null) =>
    set({ selectedMenuItem: menuItem }),
}));
