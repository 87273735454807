import { useEffect, useState } from "react";
import {
  HashRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import AppApolloProvider from "routes/ApolloProvider";
import { RouteNames } from "routes/routeNames";
import {
  ManageAdmins,
  AdminDashboard,
  AdminSettings,
  Dashboard,
  ForgotPwd,
  Login,
  ManageClients,
  ManageTemplates,
  NoInternetPage,
  NotFoundPage,
  Projects,
  Reports,
  ResetPwd,
  ResetPwdSuccess,
  RoleManagement,
  TwoFASuccess,
  ProjectDeets,
  KeyPersonnel,
  CompanyDeets,
  ProjectContacts,
  RightsBuy,
  ProjectReports,
  DeliveryChecklist,
  LoginSuccess,
  Integrations,
  Support,
  SetPassword,
  SetPwdSuccess,
  ContactDetails,
  ContractDocEditor,
  TemplateDocEditor,
  ForgotPwdVerifyOtp,
  Indexes,
  IndexItems,
  ContactDetailsSuccess,
  DeliveryChecklistItem,
  IssueDashboard,
  Clearances,
  ClearanceItems,
  Agents,
  ActivityLogs,
  Profile,
  Users,
  TwoFACode,
  TwoFAScan,
  ContractShareDocx,
  ContractShareUpload,
  PdfContractFillableFields,
} from "pages";
import theme from "theme/muiTheming";
import BackdropLoader from "common/BackdropLoader";
import { useStore } from "utils/store";
import AuthenticatedRoute from "routes/AuthenticatedRoute";
import ProtectedRoute from "routes/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { loading } = useStore();
  const [online, setOnline] = useState(true);

  useEffect(() => {
    window.addEventListener("online", function () {
      setOnline(true);
    });

    window.addEventListener("offline", function () {
      setOnline(false);
    });
  }, []);

  if (!online) {
    return <NoInternetPage />;
  }

  return (
    <ThemeProvider theme={theme}>
      <AppApolloProvider>
        <HashRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Navigate to={RouteNames.LOGIN} />} />
            <Route path={RouteNames.LOGIN} element={<Login />} />
            <Route path={RouteNames.LOGIN_SUCCESS} element={<LoginSuccess />} />
            <Route path={RouteNames.TWO_FA_SCAN} element={<TwoFAScan />} />
            <Route path={RouteNames.TWO_FA_CODE} element={<TwoFACode />} />
            <Route
              path={RouteNames.TWO_FA_SUCCESS}
              element={<TwoFASuccess />}
            />
            {/* <Route path={RouteNames.SIGN_UP} element={<SignUpMail />} />
            <Route
              path={RouteNames.SIGN_UP_SUCCESS}
              element={<SignUpSuccess />}
            /> */}
            <Route path={RouteNames.FORGOT_PWD} element={<ForgotPwd />} />
            <Route
              path={RouteNames.FORGOT_PWD_OTP}
              element={<ForgotPwdVerifyOtp />}
            />
            <Route
              path={`${RouteNames.RESET_PASSWORD}`}
              element={<ResetPwd />}
            />
            <Route
              path={RouteNames.RESET_PASSWORD_SUCCESS}
              element={<ResetPwdSuccess />}
            />
            <Route path={RouteNames.SET_PASSWORD} element={<SetPassword />} />
            <Route
              path={RouteNames.SET_PWD_SUCCESS}
              element={<SetPwdSuccess />}
            />
            <Route
              path={RouteNames.CONTACT_DETAILS}
              element={<ContactDetails />}
            />
            <Route
              path={RouteNames.CONTACT_DETAILS_SUCCESS}
              element={<ContactDetailsSuccess />}
            />
            <Route
              path={RouteNames.CONTRACT_SHARE_DOCX}
              element={<ContractShareDocx />}
            />
            <Route
              path={RouteNames.CONTRACT_SHARE_UPLOAD}
              element={<ContractShareUpload />}
            />

            {/* CLIENT SCREENS */}
            <Route
              path={RouteNames.DASHBOARD}
              element={
                <AuthenticatedRoute permissionKeys={["DASHBOARD"]}>
                  <Dashboard />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={RouteNames.PROJECTS}
              element={
                <AuthenticatedRoute
                  permissionKeys={[
                    "PROJECT_VIEW",
                    "PROJECT_EDIT",
                    "CONTACTS_VIEW",
                    "CONTACTS_EDIT",
                    "FOLDERS_VIEW",
                    "FOLDERS_EDIT",
                    "CLEARANCE_VIEW",
                    "CLEARANCE_EDIT",
                    "DELIVERY_VIEW",
                    "DELIVERY_EDIT",
                    "PROJECT_REPORTS",
                    "ISSUES_VIEW",
                    "ISSUES_EDIT",
                  ]}
                >
                  <Projects />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/basic-details`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["PROJECT_VIEW", "PROJECT_EDIT"]}
                >
                  <ProjectDeets />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/key-personnel`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["PROJECT_VIEW", "PROJECT_EDIT"]}
                >
                  <KeyPersonnel />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/company-details`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["PROJECT_VIEW", "PROJECT_EDIT"]}
                >
                  <CompanyDeets />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/project-contacts`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["CONTACTS_VIEW", "CONTACTS_EDIT"]}
                >
                  <ProjectContacts />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/rights-buy`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["PROJECT_VIEW", "PROJECT_EDIT"]}
                >
                  <RightsBuy />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/indexes`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["FOLDERS_VIEW", "FOLDERS_EDIT"]}
                >
                  <Indexes />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/indexes/:index_id/*`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["FOLDERS_VIEW", "FOLDERS_EDIT"]}
                >
                  <IndexItems />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/clearances`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["CLEARANCE_VIEW", "CLEARANCE_EDIT"]}
                >
                  <Clearances />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/clearances/:index_id/*`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["CLEARANCE_VIEW", "CLEARANCE_EDIT"]}
                >
                  <ClearanceItems />
                </AuthenticatedRoute>
              }
            />
            {/* <Route
              path={`${RouteNames.PROJECTS}/:project_id/document-repository`}
              element={
                <AuthenticatedRoute permissionKeys={[]}>
                  <DocumentRepository />
                </AuthenticatedRoute>
              }
            /> */}
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/delivery-checklist`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["DELIVERY_VIEW", "DELIVERY_EDIT"]}
                >
                  <DeliveryChecklist />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/delivery-checklist/:checklist_id/*`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["DELIVERY_VIEW", "DELIVERY_EDIT"]}
                >
                  <DeliveryChecklistItem />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/reports`}
              element={
                <AuthenticatedRoute permissionKeys={["PROJECT_REPORTS"]}>
                  <ProjectReports />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/issue-dashboard`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["ISSUES_VIEW", "ISSUES_EDIT"]}
                >
                  <IssueDashboard />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id/issue-dashboard/:issue_id`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["ISSUES_VIEW", "ISSUES_EDIT"]}
                >
                  <IssueDashboard />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={RouteNames.AGENTS}
              element={
                <AuthenticatedRoute
                  permissionKeys={["AGENTS_VIEW", "AGENTS_EDIT"]}
                >
                  <Agents />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={RouteNames.REPORTS}
              element={
                <AuthenticatedRoute permissionKeys={["REPORTS"]}>
                  <Reports />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={RouteNames.INTEGRATIONS}
              element={
                <AuthenticatedRoute
                  permissionKeys={["INTEGRATIONS_VIEW", "INTEGRATIONS_EDIT"]}
                >
                  <Integrations />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.SETTINGS}/profile`}
              element={
                <AuthenticatedRoute permissionKeys={[]}>
                  <Profile />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.SETTINGS}/users`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["SETTINGS_VIEW", "SETTINGS_EDIT"]}
                >
                  <Users />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.SETTINGS}/activity`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["SETTINGS_VIEW", "SETTINGS_EDIT"]}
                >
                  <ActivityLogs />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={RouteNames.SUPPORT}
              element={
                <AuthenticatedRoute permissionKeys={[]}>
                  <Support />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id${RouteNames.CONTRACT}/:contract_id`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["FOLDERS_VIEW", "FOLDERS_EDIT"]}
                >
                  <ContractDocEditor />
                </AuthenticatedRoute>
              }
            />
            <Route
              path={`${RouteNames.PROJECTS}/:project_id${RouteNames.CONTRACT}-upload-pdf/:contract_id`}
              element={
                <AuthenticatedRoute
                  permissionKeys={["FOLDERS_VIEW", "FOLDERS_EDIT"]}
                >
                  <PdfContractFillableFields />
                </AuthenticatedRoute>
              }
            />

            {/* ADMIN SCREENS */}
            <Route
              path={RouteNames.ADMIN_DASHBOARD}
              element={
                <ProtectedRoute roles={["super_admin"]}>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouteNames.MANAGE_CLIENTS}
              element={
                <ProtectedRoute roles={["super_admin"]}>
                  <ManageClients />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouteNames.MANAGE_TEMPLATES}
              element={
                <ProtectedRoute roles={["super_admin"]}>
                  <ManageTemplates />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${RouteNames.TEMPLATE}/:template_id`}
              element={
                <ProtectedRoute roles={["super_admin"]}>
                  <TemplateDocEditor />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouteNames.MANAGE_ADMINS}
              element={
                <ProtectedRoute roles={["super_admin"]}>
                  <ManageAdmins />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouteNames.ROLE_MGMT}
              element={
                <ProtectedRoute roles={["super_admin"]}>
                  <RoleManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouteNames.ADMIN_SETTINGS}
              element={
                <ProtectedRoute roles={["super_admin"]}>
                  <AdminSettings />
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </HashRouter>
        <BackdropLoader loading={loading} />
        <ToastContainer
          position="bottom-left"
          hideProgressBar
          closeOnClick
          draggable={false}
          autoClose={5000}
        />
      </AppApolloProvider>
    </ThemeProvider>
  );
}

export default App;
