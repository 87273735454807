export const validateArr = (personnelArr: any, setPersonnelErrors: any) => {
  let errors: string[] = [];
  for (let i = 0; i < personnelArr.length; i++) {
    if (
      (personnelArr[i].name === "" && personnelArr[i].email !== "") ||
      (personnelArr[i].name !== "" && personnelArr[i].email === "")
    ) {
      errors[i] = "Please provide both name and email";
    }
  }
  setPersonnelErrors(errors);
  return errors;
};

export const validateArrErrors = (errors: string[]) => {
  let flag = 0;
  for (let i = 0; i < errors.length; i++) {
    if (errors[i]) {
      flag = 1;
      break;
    }
  }
  return flag === 0;
};
