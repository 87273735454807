import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Autocomplete,
	Box,
	Dialog,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import { Close, Replay } from "@mui/icons-material";
import { Formik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import { BlackActionContainedButton } from "common/Buttons";
import {
	AgeStatusArr,
	HrsWorkedArr,
	IFeeCalculatorFieldsAlone,
	IProjectDetails,
	RateTypeArr,
	feeCalculatorInitialFieldsAlone,
	feeCalculatorValidationSchemaAlone,
} from "pages/ClientScreens/ContractDocEditor/utils";
import {
	CALCULATE_FEE,
	GET_PROJ_DEETS_FEE_CALCULATOR,
} from "graphql/contracts";
import { GET_ARTIST_CLASS_ARR, GET_ARTIST_TYPE_ARR } from "graphql/meta";
import {
	CurrencyMapping,
	EngagementStatusArr,
	ProjectTypeArr,
} from "utils/constants";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface ICastFeeCalculatorProps extends IModalProps {}

function CastFeeCalculator(props: ICastFeeCalculatorProps) {
	const { open, setOpen } = props;

	const params = useParams();
	const { setLoading } = useStore();

	const [feeDetailsView, setFeeDetailsView] = useState(false);
	const [projectDetails, setProjectDetails] = useState<IProjectDetails | null>(
		null
	);
	const [artistClassArr, setArtistClassArr] = useState<string[]>([]);
	const [artistTypeArr, setArtistTypeArr] = useState<IArr[]>([]);
	const [feeDetails, setFeeDetails] = useState<any>(null);

	const { loading } = useQuery(GET_PROJ_DEETS_FEE_CALCULATOR, {
		variables: { id: params?.project_id },
		skip: !params?.project_id,
		onCompleted: (data) => {
			// setLoading(false);
			const { project } = data;
			const { status, project: rawProject, inAggregate } = project;
			if (status) {
				setProjectDetails({ ...rawProject, inAggregate });
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const { loading: loadingAT } = useQuery(GET_ARTIST_TYPE_ARR, {
		variables: {},
		onCompleted: (data) => {
			// setLoading(false);
			const { artistTypes } = data;
			const { status, artistTypes: rawArtistTypes } = artistTypes;
			if (status) {
				setArtistTypeArr(rawArtistTypes);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const { loading: loadingAC } = useQuery(GET_ARTIST_CLASS_ARR, {
		variables: {
			filter: {
				artist_type_id: parseInt(
					artistTypeArr.find((x) => x.name === "Cast")?.id!
				),
			},
		},
		skip: artistTypeArr.length === 0,
		onCompleted: (data) => {
			// setLoading(false);
			const { artistClass } = data;
			const { status, artistClass: rawArtistClasses } = artistClass;
			if (status) {
				setArtistClassArr(rawArtistClasses.map((x: any) => x.name));
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const [fireCalculateFeeApi, { loading: calculating }] = useMutation(
		CALCULATE_FEE,
		{
			onCompleted: (data) => {
				const { calculateFee } = data;
				const { status, response, message } = calculateFee;
				if (status) {
					const parsedResponse = Boolean(response)
						? JSON.parse(response)
						: null;
					setFeeDetails(parsedResponse);
					setFeeDetailsView(true);
				} else {
					toast.error(message, { delay: 10 });
				}
			},
		}
	);

	const handleCalculate = (values: IFeeCalculatorFieldsAlone) => {
		const { engagementStatusUnits, feeAsk, ...rest } = values;
		fireCalculateFeeApi({
			variables: {
				payload: {
					projectId: params?.project_id,
					engagementStatusUnits: parseInt(engagementStatusUnits as string),
					feeAsk: parseFloat(feeAsk as string),
					...rest,
				},
			},
		});
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleDialogClose = (_: any, reason: string) => {
		if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
			handleClose();
		}
	};

	useEffect(() => {
		setLoading(calculating || loading || loadingAC || loadingAT);
	}, [calculating, loading, loadingAC, loadingAT, setLoading]);

	return (
		<Formik
			initialValues={feeCalculatorInitialFieldsAlone}
			onSubmit={handleCalculate}
			validationSchema={feeCalculatorValidationSchemaAlone}
			enableReinitialize
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleBlur,
				errors,
				touched,
				isValid,
				handleSubmit,
				resetForm,
			}) => (
				<Dialog
					open={open}
					onClose={handleDialogClose}
					fullWidth
					maxWidth="md"
					PaperProps={{ sx: { borderRadius: "24px", overflow: "hidden" } }}
				>
					<Grid container>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Box
								sx={{
									py: 2,
									backgroundColor: "#B6BCC325",
									position: "relative",
									px: 4,
								}}
							>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Typography
										textAlign="center"
										sx={{
											fontSize: "13px",
											color: colors.primaryBlack,
											fontWeight: 600,
										}}
									>
										Cast Fee Calculator
									</Typography>
								</Box>
							</Box>
							<Box sx={{ position: "absolute", top: 10, right: 10 }}>
								<IconButton size="small" onClick={handleClose}>
									<Close
										sx={{ color: "#00000055" }}
										fontSize="small"
									/>
								</IconButton>
							</Box>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Box
								sx={{
									pt: 4,
									px: { xs: 2, sm: 6 },
									pb: 3,
									maxHeight: "calc(100vh - 120px)",
									overflow: "auto",
								}}
							>
								{!feeDetailsView && (
									<Grid container spacing={3}>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Box sx={{ mb: -2 }}>
												<Typography
													sx={[
														styles.field_label,
														{ color: "#000" },
													]}
												>
													Project Specifics
												</Typography>
												<Divider />
											</Box>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Project Type
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(projectDetails?.projectType)
														? ProjectTypeArr.find(
																(x) =>
																	x.id ===
																	projectDetails?.projectType
														  )?.name
														: ""
												}
												inputProps={{ style: styles.input_field }}
												disabled
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Project Category
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={projectDetails?.projectCategory}
												inputProps={{ style: styles.input_field }}
												disabled
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Collective Agreement (Cast)
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													projectDetails?.collectiveAgreementCast
												}
												inputProps={{ style: styles.input_field }}
												disabled
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Category Loading
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(projectDetails) &&
													(
														projectDetails as IProjectDetails
													).hasOwnProperty("categoryLoading")
														? (projectDetails as IProjectDetails)
																.categoryLoading
														: "0"
												}
												inputProps={{ style: styles.input_field }}
												InputProps={{
													endAdornment: (
														<InputAdornment
															position="end"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopRightRadius: "6px",
																	borderBottomRightRadius:
																		"6px",
																	py: "7px",
																	pl: 3,
																	pr: 1,
																	mr: -1.75,
																	ml: -1,
																}}
															>
																<Typography
																	sx={{ fontSize: "13px" }}
																>
																	%
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
												disabled
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Total Pre-purchased Rights
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(projectDetails?.inAggregate)
														? projectDetails?.inAggregate
														: "0"
												}
												inputProps={{ style: styles.input_field }}
												InputProps={{
													endAdornment: (
														<InputAdornment
															position="end"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopRightRadius: "6px",
																	borderBottomRightRadius:
																		"6px",
																	py: "7px",
																	pl: 3,
																	pr: 1,
																	mr: -1.75,
																	ml: -1,
																}}
															>
																<Typography
																	sx={{ fontSize: "13px" }}
																>
																	%
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
												disabled
											/>
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Box sx={{ mb: -2, mt: 2 }}>
												<Typography
													sx={[
														styles.field_label,
														{ color: "#000" },
													]}
												>
													Artist Deal Specifics
												</Typography>
												<Divider />
											</Box>
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Typography sx={styles.field_label}>
												Artist Class{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<Autocomplete
												id="castClass"
												value={
													artistClassArr.find(
														(x) => x === values.castClass
													) || ""
												}
												onBlur={handleBlur}
												onChange={(_, newValue) => {
													setFieldValue("castClass", newValue);
												}}
												sx={styles.input_field}
												size="small"
												disableClearable
												fullWidth
												options={artistClassArr}
												renderInput={(params) => (
													<TextField
														{...params}
														size="small"
														fullWidth
														inputProps={{
															...params.inputProps,
															style: {
																...styles.input_field,
																backgroundColor: "transparent",
															},
														}}
													/>
												)}
											/>
											{touched?.castClass && errors?.castClass && (
												<Typography sx={styles.error_text}>
													{errors?.castClass}
												</Typography>
											)}
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Typography sx={styles.field_label}>
												Period of Engagement{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<Box sx={{ display: "flex" }}>
												<Box>
													<TextField
														id="engagementStatusUnits"
														size="small"
														value={values.engagementStatusUnits}
														onChange={(e) => {
															const value = e.target.value;
															const isValidNumber =
																value.match(/^\d*$/); // Only allow digits

															if (isValidNumber) {
																if (
																	value === "" ||
																	!isNaN(
																		value as unknown as number
																	)
																) {
																	setFieldValue(
																		"engagementStatusUnits",
																		value
																	);
																}
															}
														}}
														onBlur={handleBlur}
														inputProps={{
															style: styles.input_field,
														}}
														sx={{ width: "120px" }}
													/>
													{touched?.engagementStatusUnits &&
														errors?.engagementStatusUnits && (
															<Typography
																sx={[
																	styles.error_text,
																	{
																		wordBreak: "break-word",
																		width: "120px",
																	},
																]}
															>
																{errors?.engagementStatusUnits}
															</Typography>
														)}
												</Box>
												<Box sx={{ ml: 2, flexGrow: 1 }}>
													<Autocomplete
														id="engagementStatus"
														value={
															EngagementStatusArr.find(
																(x) =>
																	x.key ===
																	values.engagementStatus
															) || { key: "", value: "" }
														}
														onChange={(_, newValue) => {
															setFieldValue(
																"engagementStatus",
																newValue.key
															);
														}}
														onBlur={handleBlur}
														sx={styles.input_field}
														size="small"
														disableClearable
														options={EngagementStatusArr}
														getOptionLabel={(option) =>
															option.value
														}
														renderInput={(params) => (
															<TextField
																{...params}
																size="small"
																fullWidth
																inputProps={{
																	...params.inputProps,
																	style: {
																		...styles.input_field,
																		backgroundColor:
																			"transparent",
																	},
																}}
															/>
														)}
													/>
													{touched?.engagementStatus &&
														errors?.engagementStatus && (
															<Typography sx={styles.error_text}>
																{errors?.engagementStatus}
															</Typography>
														)}
												</Box>
											</Box>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Age Status{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<Autocomplete
												id="ageStatus"
												value={
													AgeStatusArr.find(
														(x) => x === values.ageStatus
													) || ""
												}
												onChange={(_, newValue) => {
													setFieldValue("ageStatus", newValue);
												}}
												onBlur={handleBlur}
												sx={styles.input_field}
												size="small"
												disableClearable
												fullWidth
												options={AgeStatusArr}
												renderInput={(params) => (
													<TextField
														{...params}
														size="small"
														fullWidth
														inputProps={{
															...params.inputProps,
															style: {
																...styles.input_field,
																backgroundColor: "transparent",
															},
														}}
													/>
												)}
											/>
											{touched?.ageStatus && errors?.ageStatus && (
												<Typography sx={styles.error_text}>
													{errors?.ageStatus}
												</Typography>
											)}
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Hours Worked{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<Autocomplete
												id="hrsWorked"
												value={
													HrsWorkedArr.find(
														(x) => x.id === values.hrsWorked
													) || { id: "", name: "" }
												}
												onChange={(_, newValue) => {
													setFieldValue("hrsWorked", newValue.id);
												}}
												onBlur={handleBlur}
												sx={styles.input_field}
												size="small"
												disableClearable
												fullWidth
												options={HrsWorkedArr}
												getOptionLabel={(opt) => opt.name}
												renderInput={(params) => (
													<TextField
														{...params}
														size="small"
														fullWidth
														inputProps={{
															...params.inputProps,
															style: {
																...styles.input_field,
																backgroundColor: "transparent",
															},
														}}
													/>
												)}
											/>
											{touched?.hrsWorked && errors?.hrsWorked && (
												<Typography sx={styles.error_text}>
													{errors?.hrsWorked}
												</Typography>
											)}
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Rate Type{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<Autocomplete
												id="rateType"
												value={
													RateTypeArr.find(
														(x) => x.id === values.rateType
													) || {
														id: "",
														name: "",
													}
												}
												onChange={(_, newValue) => {
													setFieldValue("rateType", newValue.id);
												}}
												onBlur={handleBlur}
												sx={styles.input_field}
												size="small"
												disableClearable
												fullWidth
												options={RateTypeArr}
												getOptionLabel={(opt) => opt.name}
												renderInput={(params) => (
													<TextField
														{...params}
														size="small"
														fullWidth
														inputProps={{
															...params.inputProps,
															style: {
																...styles.input_field,
																backgroundColor: "transparent",
															},
														}}
													/>
												)}
											/>
											{touched?.rateType && errors?.rateType && (
												<Typography sx={styles.error_text}>
													{errors?.rateType}
												</Typography>
											)}
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Fee Ask{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<TextField
												id="feeAsk"
												size="small"
												fullWidth
												value={values.feeAsk}
												onChange={(e) => {
													if (
														e.target.value === "" ||
														!isNaN(
															e.target.value as unknown as number
														)
													) {
														if (e.target.value === "") {
															setFieldValue(
																"feeAsk",
																e.target.value
															);
														} else {
															setFieldValue(
																"feeAsk",
																e.target.value
															);
														}
													}
												}}
												onBlur={handleBlur}
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
											{touched?.feeAsk && errors?.feeAsk && (
												<Typography sx={styles.error_text}>
													{errors?.feeAsk}
												</Typography>
											)}
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Box sx={styles.flex_JCfe}>
												<BlackActionContainedButton
													size="small"
													variant="contained"
													sx={{
														width: "120px",
														borderRadius: "20px",
													}}
													disableElevation
													onClick={() => handleSubmit()}
													disabled={!isValid}
												>
													Calculate
												</BlackActionContainedButton>
											</Box>
										</Grid>
									</Grid>
								)}
								{feeDetailsView && (
									<Grid container spacing={3}>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Award Minimum{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													feeDetails?.award_minimum
														? parseFloat(
																feeDetails?.award_minimum
														  ).toFixed(2)
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Personal Margin{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													feeDetails?.personal_margin
														? parseFloat(
																feeDetails?.personal_margin
														  ).toFixed(2)
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												BNF{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													feeDetails?.BNF
														? parseFloat(feeDetails?.BNF).toFixed(
																2
														  )
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Total Fee{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(feeDetails) &&
													typeof feeDetails?.FEES !== "string"
														? parseFloat(
																feeDetails?.FEES
														  ).toFixed(2)
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Loading Percentage Total
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(feeDetails)
														? `${feeDetails?.LT}`
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Loading Cost Total{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(feeDetails) &&
													typeof feeDetails?.LCT !== "string"
														? parseFloat(feeDetails?.LCT).toFixed(
																2
														  )
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Composite Fee{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(feeDetails) &&
													typeof feeDetails?.composite_fee !==
														"string"
														? parseFloat(
																feeDetails?.composite_fee
														  ).toFixed(2)
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Contracted Overtime{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(feeDetails) &&
													typeof feeDetails?.OT !== "string"
														? parseFloat(feeDetails?.OT).toFixed(
																2
														  )
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Composite Fee and Overtime{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(feeDetails) &&
													typeof feeDetails?.composite_fee_ot !==
														"string"
														? parseFloat(
																feeDetails?.composite_fee_ot
														  ).toFixed(2)
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Annual Leave Amount{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(feeDetails) &&
													typeof feeDetails?.AL !== "string"
														? parseFloat(feeDetails?.AL).toFixed(
																2
														  )
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Contracted Fee per Unit{" "}
												{Boolean(projectDetails?.currency)
													? `(${projectDetails?.currency})`
													: ""}
											</Typography>
											<TextField
												id="name"
												size="small"
												fullWidth
												value={
													Boolean(feeDetails) &&
													typeof feeDetails?.contracted_fee_per_unit !==
														"string"
														? parseFloat(
																feeDetails?.contracted_fee_per_unit
														  ).toFixed(2)
														: ""
												}
												disabled
												inputProps={{ style: styles.input_field }}
												InputProps={{
													startAdornment: (
														<InputAdornment
															position="start"
															sx={{ mr: 0 }}
														>
															<Box
																sx={{
																	backgroundColor: "#e6e6e640",
																	borderTopLeftRadius: "6px",
																	borderBottomLeftRadius:
																		"6px",
																	py: "7px",
																	px: 1,
																	ml: -1.75,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography
																	sx={{
																		ml: 1,
																		fontSize: "13px",
																	}}
																>
																	{Boolean(
																		projectDetails?.currency
																	)
																		? (
																				CurrencyMapping as any
																		  )[
																				projectDetails?.currency!
																		  ]
																		: ""}
																</Typography>
															</Box>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<BlackActionContainedButton
												size="small"
												variant="contained"
												sx={{
													width: "120px",
													borderRadius: "20px",
												}}
												disableElevation
												onClick={() => {
													setFeeDetailsView(false);
													setFeeDetails(null);
													resetForm();
												}}
												startIcon={<Replay color="secondary" />}
											>
												Start Over
											</BlackActionContainedButton>
										</Grid>
									</Grid>
								)}
							</Box>
						</Grid>
					</Grid>
				</Dialog>
			)}
		</Formik>
	);
}

export default CastFeeCalculator;
