import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { CameraAlt, Check, Close } from "@mui/icons-material";
import { toast } from "react-toastify";

import { IEditProjectFields } from "models/projects";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";
import ProjectPhotoCropper from "common/ImageCropper/ProjectPhotoCropper";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { ProjectDeetsStyles as styles } from "./styles";

const leftNavOptions = [
  { id: "1", name: "Project Details" },
  { id: "2", name: "Key Dates" },
  { id: "3", name: "Settings" },
];

interface ILeftSideNavProps {
  proj: IEditProjectFields;
  selectedLeftNavOpt: string;
  setSelectedLeftNavOpt: Dispatch<SetStateAction<string>>;
  fireUpdateProjectApi: any;
  refetch: any;
}

function LeftSideNav(props: ILeftSideNavProps) {
  const {
    proj,
    selectedLeftNavOpt,
    setSelectedLeftNavOpt,
    fireUpdateProjectApi,
    refetch,
  } = props;

  const { setLoading } = useStore();

  const [openImageCropUploader, setOpenImageCropUploader] = useState(false);
  const [projectImageHovered, setProjectImageHovered] = useState(false);
  const [projectImage, setProjectImage] = useState<any>(null);
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("PROJECT_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  useEffect(() => {
    setProjectImage(proj.profile_picture);
  }, [proj.profile_picture]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            position: "relative",
          }}
          onClick={() => {
            if (
              Boolean(ROLE_PERMISSIONS) &&
              projectEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]?.PROJECT_EDIT
            ) {
              setOpenImageCropUploader(true);
            }
          }}
        >
          <Box
            component="div"
            onMouseEnter={() => {
              if (
                Boolean(ROLE_PERMISSIONS) &&
                projectEditIndex !== undefined &&
                ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                  ?.PROJECT_EDIT &&
                !projectImageHovered
              ) {
                setProjectImageHovered(true);
              }
            }}
          >
            {Boolean(projectImage) ? (
              <Box
                sx={[
                  styles.proj_img,
                  ...(Boolean(ROLE_PERMISSIONS) &&
                  projectEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                    ?.PROJECT_EDIT
                    ? [{ cursor: "pointer" }]
                    : []),
                ]}
              >
                <img
                  src={
                    typeof projectImage === "string"
                      ? projectImage
                      : URL.createObjectURL(projectImage)
                  }
                  alt="proj_image"
                  style={{
                    height: "220px",
                    width: "148.5px",
                    borderRadius: "15px",
                    objectFit: "cover",
                    aspectRatio: 0.675 / 1,
                    ...(proj?.projectStatus === 2 && {
                      filter: "grayscale(100%)",
                    }),
                  }}
                />
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={[
                    styles.proj_img,
                    {
                      backgroundColor: `#BFBDBC22`,
                      aspectRatio: 0.675 / 1,
                      borderRadius: "20px",
                      width: "148.5px",
                    },
                  ]}
                >
                  <img
                    src={images.FILM_REEL}
                    alt="reel"
                    style={{ height: 50 }}
                  />
                </Box>
              </Box>
            )}
          </Box>
          {projectImageHovered && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: 0,
              }}
            >
              <Box
                component="div"
                onMouseLeave={() => {
                  if (projectImageHovered) {
                    setProjectImageHovered(false);
                  }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={[
                      styles.proj_img,
                      {
                        aspectRatio: 0.675 / 1,
                        borderRadius: "20px",
                        backgroundColor: "#000000",
                        ...(Boolean(ROLE_PERMISSIONS) &&
                        projectEditIndex !== undefined &&
                        ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                          ?.PROJECT_EDIT
                          ? { cursor: "pointer" }
                          : {}),
                        opacity: 0.4,
                        width: "148.5px",
                      },
                    ]}
                  >
                    <CameraAlt
                      color="secondary"
                      sx={{ opacity: 1, fontSize: 40 }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {Boolean(projectImage) && typeof projectImage !== "string" && (
        <Box
          sx={{
            width: "148.5px",
            mt: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "center",
            mb: -3,
          }}
        >
          <IconButton
            size="small"
            onClick={() =>
              fireUpdateProjectApi({
                variables: {
                  payload: {
                    id: proj.id,
                  },
                  ...(Boolean(projectImage) &&
                    typeof projectImage !== "string" && {
                      file: projectImage,
                    }),
                },
                onCompleted: (data: any) => {
                  setLoading(false);
                  const { updateProject } = data;
                  const { status, message } = updateProject;
                  if (status) {
                    toast.success(message);
                    refetch();
                  } else {
                    toast.error(message);
                  }
                },
              })
            }
          >
            <Check />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => setProjectImage(proj?.profile_picture)}
          >
            <Close />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: " 148.5px" }}>
          {leftNavOptions.map((item) => (
            <Box
              key={item.id}
              sx={{
                mt: 5,
                py: 1,
                px: 2,
                borderRadius: "8px",
                cursor: "pointer",
                display: "inline-block", // Ensure the Box only takes the width of the text
                ":hover": {
                  backgroundColor:
                    selectedLeftNavOpt === item.id ? "#41B19995" : "#e6e6e670",
                },
                ...(selectedLeftNavOpt === item.id && {
                  backgroundColor: "#41B19931",
                }),
              }}
              onClick={() => {
                setSelectedLeftNavOpt(item.id);
              }}
            >
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: selectedLeftNavOpt === item.id ? 600 : 550,
                  color: colors.primaryBlack,
                }}
              >
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      {openImageCropUploader && (
        <ProjectPhotoCropper
          open={openImageCropUploader}
          setOpen={setOpenImageCropUploader}
          width={148.5}
          height={220}
          imgSrc={projectImage}
          setImgSrc={(file: any) => setProjectImage(file)}
        />
      )}
    </Box>
  );
}

export default LeftSideNav;
