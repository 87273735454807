import React, { Dispatch, SetStateAction, useState } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";

import { IUpdateContactFieldsNew } from "models/contacts";
import { colors } from "theme/colors";

import IndividualForm from "./IndividualForm";
import CompanyForm from "./CompanyForm";
import AgencyForm from "./AgencyForm";
import ManagementForm from "./ManagementForm";

interface IStepperComponentProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  selectedContact: string;
  initialValues: IUpdateContactFieldsNew;
  updateContactApi: any;
  refetch: any;
  company_id: string;
}

const steps = ["Individual", "Company", "Agency", "Management"];

function StepperComponent(props: IStepperComponentProps) {
  const {
    selectedContact,
    activeStep,
    setActiveStep,
    initialValues,
    updateContactApi,
    refetch,
    company_id,
  } = props;

  const [personalActiveSection, setPersonalActiveSection] = useState(0);
  const [companyActiveSection, setCompanyActiveSection] = useState(0);
  const [agencyActiveSection, setAgencyActiveSection] = useState(0);
  const [managementActiveSection, setManagementActiveSection] = useState(0);

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          py: 2,
          backgroundColor: "#B6BCC325",
          position: "relative",
          px: 4,
          borderRadius: "8px",
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          {steps.map((step, index) => (
            <Grid key={step} item lg={3} md={3} sm={3} xs={3}>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActiveStep(index);
                  setPersonalActiveSection(0);
                  setCompanyActiveSection(0);
                  setAgencyActiveSection(0);
                  setManagementActiveSection(0);
                }}
              >
                <Avatar
                  sx={{
                    width: 22,
                    height: 22,
                    mr: 0.5,
                    backgroundColor:
                      activeStep === index
                        ? colors.primaryBlack
                        : `${colors.primaryBlack}20`,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color:
                        activeStep === index
                          ? colors.secondary
                          : `${colors.primaryBlack}50`,
                    }}
                  >
                    {index + 1}
                  </Typography>
                </Avatar>
                <Typography
                  textAlign="center"
                  sx={{
                    fontSize: "13px",
                    color:
                      activeStep === index
                        ? colors.primaryBlack
                        : `${colors.primaryBlack}60`,
                    ...(activeStep === index && { fontWeight: 600 }),
                  }}
                >
                  {step}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              width: 22,
              height: 22,
              mr: 0.5,
              backgroundColor: colors.primaryBlack,
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                color: colors.secondary,
              }}
            >
              {activeStep + 1}
            </Typography>
          </Avatar>
          <Typography
            textAlign="center"
            sx={{
              fontSize: "13px",
              color: colors.primaryBlack,
              fontWeight: 600,
            }}
          >
            {steps[activeStep]}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
        <Box sx={{ height: "100%", overflow: "auto" }}>
          {activeStep === 0 && (
            <IndividualForm
              selectedContact={selectedContact}
              setActiveStep={setActiveStep}
              activeSection={personalActiveSection}
              setActiveSection={setPersonalActiveSection}
              initialValues={initialValues}
              updateContactApi={updateContactApi}
              refetch={refetch}
            />
          )}
          {activeStep === 1 && (
            <CompanyForm
              selectedContact={selectedContact}
              setActiveStep={setActiveStep}
              activeSection={companyActiveSection}
              setActiveSection={setCompanyActiveSection}
              setPrevActiveSection={setPersonalActiveSection}
              initialValues={initialValues}
              updateContactApi={updateContactApi}
              refetch={refetch}
            />
          )}
          {activeStep === 2 && (
            <AgencyForm
              selectedContact={selectedContact}
              setActiveStep={setActiveStep}
              activeSection={agencyActiveSection}
              setActiveSection={setAgencyActiveSection}
              setPrevActiveSection={setCompanyActiveSection}
              initialValues={initialValues}
              updateContactApi={updateContactApi}
              refetch={refetch}
              company_id={company_id}
            />
          )}
          {activeStep === 3 && (
            <ManagementForm
              selectedContact={selectedContact}
              setActiveStep={setActiveStep}
              activeSection={managementActiveSection}
              setActiveSection={setManagementActiveSection}
              setPrevActiveSection={setAgencyActiveSection}
              initialValues={initialValues}
              updateContactApi={updateContactApi}
              refetch={refetch}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default StepperComponent;
