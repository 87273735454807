import { colors } from "theme/colors";

export const AdminUsersStyles = {
  cell_text: {
    color: colors.secondaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  actions_text: {
    color: colors.primaryBlack,
    fontSize: "14px",
    fontWeight: 500,
  },
  table: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      backgroundColor: `${colors.primary}10`,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: colors.primaryBlack,
      fontSize: "16px",
      fontWeight: 500,
    },
  },
  flex_JCsb_Acenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  field_label: {
    color: colors.grey,
    fontSize: "14px",
    fontWeight: 500,
    mb: 0.75,
  },
  add_admin_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  flex_Acenter: {
    display: "flex",
    alignItems: "center",
  },
  input_field: { fontSize: "14px", color: colors.primaryBlack },
  my3: {
    my: 3,
  },
  error_text: { fontSize: "12px", color: "#D3010E" },
  flex_JCfe: { display: "flex", justifyContent: "flex-end" },
  filters_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    px: 2.5,
    py: 3.5,
  },
  header_text: {
    color: colors.primaryBlack,
    fontSize: "16px",
    fontWeight: 600,
  },
  write_chip: { color: colors.primary, backgroundColor: `${colors.primary}20` },
  read_chip: { color: "#D3010E", backgroundColor: `#D3010E20` },
  disabled_btn: {
    "&.Mui-disabled": {
      backgroundColor: colors.grey,
      color: "#c0c0c0",
    },
  },
  modal_footer_container: { p: 2, backgroundColor: `${colors.primary}20` },
};
