import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Tab, Tabs, useMediaQuery } from "@mui/material";

import Drawer from "common/ClientDrawer";
import { ClientSettingsLabels } from "common/AppConstants";
import { useClientSettingsTabs } from "hooks/useClientSettingsTabs";
import theme from "theme/muiTheming";
import { RouteNames } from "routes/routeNames";
import { getPermissions } from "permissions/utils";

import { SettingsStyles as styles } from "./styles";

interface ISettingsProps {
  children: React.ReactNode;
}

const Settings = (props: ISettingsProps) => {
  const { children } = props;

  const matchesXs = useMediaQuery(theme.breakpoints.up("sm"));
  const settingsTabs = useClientSettingsTabs();
  const navigate = useNavigate();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const settingsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("SETTINGS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const settingsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("SETTINGS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  return (
    <Drawer>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ px: { xs: 0, sm: 2, md: 4, lg: 6 } }}>
            {Boolean(ROLE_PERMISSIONS) &&
              settingsEditIndex !== undefined &&
              settingsViewIndex !== undefined &&
              (ROLE_PERMISSIONS[0]?.permissions[settingsViewIndex]
                ?.SETTINGS_VIEW ||
                ROLE_PERMISSIONS[0]?.permissions[settingsEditIndex]
                  ?.SETTINGS_EDIT) && (
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Tabs
                    value={settingsTabs.selectedTab}
                    onChange={(_, value) => {
                      settingsTabs.setSelectedTab(value);
                      if (value === 0) {
                        navigate(`${RouteNames.SETTINGS}/profile`);
                      }
                      if (value === 1) {
                        navigate(`${RouteNames.SETTINGS}/users`);
                      }
                      if (value === 2) {
                        navigate(`${RouteNames.SETTINGS}/activity`);
                      }
                    }}
                    variant={matchesXs ? "standard" : "scrollable"}
                    TabIndicatorProps={{
                      sx: { backgroundColor: "#41B19995", p: 0.25 },
                    }}
                  >
                    <Tab
                      label={ClientSettingsLabels.TABS.PROFILE}
                      sx={styles.tab_text}
                      disableRipple
                    />
                    <Tab
                      label={ClientSettingsLabels.TABS.USERS}
                      sx={styles.tab_text}
                      disableRipple
                    />
                    <Tab
                      label={ClientSettingsLabels.TABS.ACTIVITY_LOGS}
                      sx={styles.tab_text}
                      disableRipple
                    />
                  </Tabs>
                </Box>
              )}
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ px: { xs: 0, sm: 2, md: 4, lg: 6 } }}>{children}</Box>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default Settings;
