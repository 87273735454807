import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  Cached,
  CheckBox,
  CheckBoxOutlineBlank,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import Table from "common/Table";
import { useStore } from "utils/store";
import { GET_RIGHTS_BUY, UPSERT_RIGHTS_BUY } from "graphql/projects";
import { IProject, IRightBuys } from "models/projects";
import { useProjectDetailsMenu } from "hooks/useProjectDetailsMenu";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { NewGreenPrimaryContainedButton } from "common/Buttons";
import { getPermissions } from "permissions/utils";

import TopNavBar from "../TopNavBar";
import WarningModal from "./WarningModal";
import { ProjectDetailsStyles as styles } from "../styles";
import ProjectDetailsTabs, { ProjectDetailsMenuItems } from "..";

export interface IRightBuysTable extends IRightBuys {
  details: string;
}

export function RightsBuy() {
  const { setLoading } = useStore();
  const projectId = useParams();
  const projectDetailsMenu = useProjectDetailsMenu();
  const projectDetailsTab = useProjectDetailsTabs();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x?.hasOwnProperty("PROJECT_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const projectEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x?.hasOwnProperty("PROJECT_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [initialRights, setInitialRights] = useState<IRightBuysTable[]>([]);
  const [rights, setRights] = useState<IRightBuysTable[]>([]);
  const [totalRightsPurchased, setTotalRightsPurchased] = useState(0);
  const [projectDeets, setProjectDeets] = useState<IProject | null>(null);
  const [warningModal, setWarningModal] = useState(false);

  const { loading, refetch } = useQuery(GET_RIGHTS_BUY, {
    variables: { id: projectId?.project_id },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        projectViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[projectViewIndex]?.PROJECT_VIEW
      ),
    onCompleted: (data) => {
      setLoading(false);
      const { projectRights } = data;
      const {
        status,
        projectRights: rawProjectRights,
        inAggregate,
        project,
      } = projectRights;
      if (status) {
        setRights(rawProjectRights);
        setInitialRights(rawProjectRights);
        setTotalRightsPurchased(inAggregate);
        setProjectDeets(project);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireRightsIncludeApi, { loading: updating }] = useMutation(
    UPSERT_RIGHTS_BUY,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { upsertProjectRights } = data;
        const { status, message } = upsertProjectRights;
        if (status) {
          setWarningModal(false);
          refetch();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "rightsUsage",
        headerName: "Rights Usage",
        minWidth: 300,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => {
          const detailsJSON = Boolean(params?.row?.details)
            ? JSON.parse(params?.row?.details)
            : null;
          if (Boolean(detailsJSON)) {
            let checkboxChecked = false;
            for (let i = 0; i < detailsJSON.length; i = i + 1) {
              if (detailsJSON[i].aggregate_inclusion) {
                checkboxChecked = true;
                break;
              }
            }
            return (
              <Box>
                <Box sx={styles.flex_Acenter}>
                  <Checkbox
                    size="small"
                    checkedIcon={
                      <CheckBox fontSize="small" sx={{ color: "#6f6f6f" }} />
                    }
                    disabled={
                      Boolean(ROLE_PERMISSIONS) &&
                      projectEditIndex !== undefined &&
                      ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                        ?.PROJECT_EDIT
                        ? !checkboxChecked
                        : true
                    }
                    icon={
                      Boolean(ROLE_PERMISSIONS) &&
                      projectEditIndex !== undefined &&
                      ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                        ?.PROJECT_EDIT ? (
                        !checkboxChecked ? (
                          <CheckBoxOutlineBlank
                            fontSize="small"
                            sx={{ color: "#6f6f6f70" }}
                          />
                        ) : (
                          <CheckBoxOutlineBlank
                            fontSize="small"
                            sx={{ color: "#6f6f6f" }}
                          />
                        )
                      ) : (
                        <CheckBoxOutlineBlank
                          fontSize="small"
                          sx={{ color: "#6f6f6f70" }}
                        />
                      )
                    }
                    checked={checkboxChecked}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        let temp = [...detailsJSON];
                        temp = temp.map((x) => ({
                          ...x,
                          is_included: false,
                          aggregate_inclusion: false,
                        }));
                        let tempRights = [...rights];
                        const index = tempRights.findIndex(
                          (x) => x.id === params?.row?.id
                        );
                        if (index > -1) {
                          tempRights[index] = {
                            ...tempRights[index],
                            details: JSON.stringify(temp),
                            aggregateInclusion: true,
                          };
                        }
                        setRights([...tempRights]);
                      }
                    }}
                  />
                  <Typography
                    sx={[styles.cell_text, { fontWeight: 600, ml: 1 }]}
                  >
                    {params?.value}
                  </Typography>
                </Box>
                <RadioGroup
                  value={
                    detailsJSON.find((x: any) => x.aggregate_inclusion)
                      ?.title || ""
                  }
                  onChange={(e) => {
                    let temp = [...detailsJSON];
                    const index = temp.findIndex(
                      (x) => x.title === e.target.value
                    );
                    if (index > -1) {
                      temp[index] = {
                        ...temp[index],
                        is_included: true,
                        aggregate_inclusion: true,
                      };
                    }
                    for (let i = 0; i < temp.length; i = i + 1) {
                      if (i !== index) {
                        temp[i] = {
                          ...temp[i],
                          is_included: false,
                          aggregate_inclusion: false,
                        };
                      }
                    }
                    let tempRights = [...rights];
                    const index1 = tempRights.findIndex(
                      (x) => x.id === params?.row?.id
                    );
                    if (index1 > -1) {
                      tempRights[index1] = {
                        ...tempRights[index1],
                        details: JSON.stringify(temp),
                        aggregateInclusion: true,
                      };
                    }
                    setRights([...tempRights]);
                  }}
                >
                  {detailsJSON.map((x: any, index: number) => (
                    <Box key={x.title} sx={styles.flex_Acenter}>
                      <Radio
                        value={x.title}
                        size="small"
                        checkedIcon={
                          Boolean(ROLE_PERMISSIONS) &&
                          projectEditIndex !== undefined &&
                          ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                            ?.PROJECT_EDIT ? (
                            <RadioButtonChecked
                              fontSize="small"
                              sx={{ color: "#6f6f6f" }}
                            />
                          ) : (
                            <RadioButtonChecked
                              fontSize="small"
                              sx={{ color: "#6f6f6f70" }}
                            />
                          )
                        }
                        icon={
                          Boolean(ROLE_PERMISSIONS) &&
                          projectEditIndex !== undefined &&
                          ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                            ?.PROJECT_EDIT ? (
                            <RadioButtonUnchecked
                              fontSize="small"
                              sx={{ color: "#6f6f6f" }}
                            />
                          ) : (
                            <RadioButtonUnchecked
                              fontSize="small"
                              sx={{ color: "#6f6f6f70" }}
                            />
                          )
                        }
                        disabled={
                          !(
                            Boolean(ROLE_PERMISSIONS) &&
                            projectEditIndex !== undefined &&
                            ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                              ?.PROJECT_EDIT
                          )
                        }
                      />
                      <Typography sx={[styles.cell_text, { ml: 1 }]}>
                        {x.title}
                      </Typography>
                    </Box>
                  ))}
                </RadioGroup>
                <Typography
                  sx={[
                    styles.cell_text,
                    { mt: 1, color: "#00000080", ml: 1.5 },
                  ]}
                >
                  {params?.row?.notes}
                </Typography>
              </Box>
            );
          }
          return (
            <Box>
              <Box sx={styles.flex_Acenter}>
                <Checkbox
                  size="small"
                  icon={
                    Boolean(ROLE_PERMISSIONS) &&
                    projectEditIndex !== undefined &&
                    ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                      ?.PROJECT_EDIT ? (
                      <CheckBoxOutlineBlank
                        fontSize="small"
                        sx={{ color: "#6f6f6f" }}
                      />
                    ) : (
                      <CheckBoxOutlineBlank
                        fontSize="small"
                        sx={{ color: "#6f6f6f70" }}
                      />
                    )
                  }
                  checkedIcon={
                    Boolean(ROLE_PERMISSIONS) &&
                    projectEditIndex !== undefined &&
                    ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                      ?.PROJECT_EDIT ? (
                      <CheckBox fontSize="small" sx={{ color: "#6f6f6f" }} />
                    ) : (
                      <CheckBox fontSize="small" sx={{ color: "#6f6f6f70" }} />
                    )
                  }
                  disabled={
                    !(
                      Boolean(ROLE_PERMISSIONS) &&
                      projectEditIndex !== undefined &&
                      ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                        ?.PROJECT_EDIT
                    )
                  }
                  onChange={(e) => {
                    let tempRights = [...rights];
                    const index = tempRights.findIndex(
                      (x) => x.id === params?.row?.id
                    );
                    if (index > -1) {
                      tempRights[index] = {
                        ...tempRights[index],
                        aggregateInclusion: e.target.checked,
                      };
                    }
                    setRights([...tempRights]);
                  }}
                  checked={params?.row?.aggregateInclusion}
                />
                <Typography sx={[styles.cell_text, { fontWeight: 600, ml: 1 }]}>
                  {params?.value}
                </Typography>
              </Box>
              <Typography
                sx={[styles.cell_text, { mt: 1, color: "#00000080", ml: 1.5 }]}
              >
                {params?.row?.notes}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "loading",
        headerName: "Loading for Rights",
        headerAlign: "center",
        width: 200,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => {
          const detailsJSON = Boolean(params?.row?.details)
            ? JSON.parse(params?.row?.details)
            : null;
          if (Boolean(detailsJSON)) {
            return (
              <Box>
                <Typography sx={[styles.cell_text, { color: "transparent" }]}>
                  {params?.value || ""}
                </Typography>
                {detailsJSON.map((x: any, index: number) => (
                  <Box key={x.title} sx={{ mt: index === 0 ? 1 : 3.5 }}>
                    <Typography sx={styles.cell_text}>{x.loading} %</Typography>
                  </Box>
                ))}
              </Box>
            );
          }
          return (
            <Typography
              sx={styles.cell_text}
            >{`${params?.value} %`}</Typography>
          );
        },
      },
    ],
    [ROLE_PERMISSIONS, projectEditIndex, rights]
  );

  useEffect(() => {
    setLoading(loading || updating);
  }, [loading, setLoading, updating]);

  useEffect(() => {
    projectDetailsMenu.onMenuItemClick(ProjectDetailsMenuItems[3]);
    projectDetailsTab.setSelectedTab(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <TopNavBar>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              sx={{
                fontSize: "15px",
                color: "#33333395",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              {projectDeets?.collectiveAgreementCast === "ATPA/ATRRA"
                ? 'AUSTRALIAN TELEVISION REPEATS AND RESIDUALS AGREEMENT ("ATRRA")'
                : 'ACTORS FEATURE FILM COLLECTIVE AGREEMENT ("AFFCA")'}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              rows={rights}
              columns={columns}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              disableColumnMenu
              sx={{
                ...styles.table,
                "& .MuiDataGrid-row": {
                  borderBottom: "1px #B6BCC340 solid",
                },
                "& .MuiDataGrid-row:last-child": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "transparent",
                },
              }}
              hideFooter
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                mt: -2,
                backgroundColor: "#000",
                borderRadius: "10px",
                py: 2,
                pl: 4.5,
                pr: 11,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ color: "#fff", fontSize: "14px", fontWeight: 600 }}
              >
                Total Rights Purchased
              </Typography>
              <Typography
                sx={{ color: "#fff", fontSize: "14px", fontWeight: 600 }}
              >
                {`${totalRightsPurchased} %`}
              </Typography>
            </Box>
          </Grid>
          {Boolean(ROLE_PERMISSIONS) &&
            projectEditIndex !== undefined &&
            ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
              ?.PROJECT_EDIT && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ pl: 8, pr: 11 }}>
                  <NewGreenPrimaryContainedButton
                    fullWidth
                    variant="contained"
                    disableElevation
                    sx={{
                      borderRadius: "8px",
                      fontSize: "13px",
                      fontWeight: 600,
                      py: 1.75,
                    }}
                    startIcon={<Cached sx={{ color: "#000" }} />}
                    onClick={() => setWarningModal(true)}
                  >
                    Update Rights
                  </NewGreenPrimaryContainedButton>
                </Box>
              </Grid>
            )}
        </Grid>
        {warningModal && (
          <WarningModal
            open={warningModal}
            setOpen={setWarningModal}
            rights={rights}
            setRights={setRights}
            fireRightsIncludeApi={fireRightsIncludeApi}
            initialRights={initialRights}
            projectId={projectId?.project_id!}
          />
        )}
      </TopNavBar>
    </ProjectDetailsTabs>
  );
}
