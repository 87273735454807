export enum RouteNames {
  LOGIN = "/login",
  LOGIN_SUCCESS = "/terms-and-conditions",
  TWO_FA_SCAN = "/2fa-scan",
  TWO_FA_CODE = "/2fa-verify",
  TWO_FA_SUCCESS = "/2fa/verified",
  SIGN_UP = "/sign-up",
  SIGN_UP_SUCCESS = "/sign-up/success",
  FORGOT_PWD = "/forgot-pwd",
  FORGOT_PWD_OTP = "/forgot-pwd/verify-otp",
  RESET_PASSWORD = "/reset-password",
  RESET_PASSWORD_SUCCESS = "/reset-password/success",
  SET_PASSWORD = "/set-password",
  SET_PWD_SUCCESS = "/set-password/success",
  CONTACT_DETAILS = "/editcontact",
  CONTACT_DETAILS_SUCCESS = "/editcontact/success",
  DASHBOARD = "/dashboard",
  CONTACTS = "/contacts",
  PROJECTS = "/projects",
  CONTRACT = "/contract",
  TEMPLATES = "/templates",
  TEMPLATE = "/template",
  REPORTS = "/reports",
  USERS = "/users",
  AGENTS = "/agents",
  INTEGRATIONS = "/integrations",
  SETTINGS = "/settings",
  PROFILE = "/settings/profile",
  SUPPORT = "/help",
  ADMIN_DASHBOARD = "/admin-dashboard",
  MANAGE_CLIENTS = "/manage-clients",
  MANAGE_TEMPLATES = "/manage-templates",
  MANAGE_ADMINS = "/manage-admins",
  ROLE_MGMT = "/role-management",
  ADMIN_SETTINGS = "/admin-settings",
  CONTRACT_SHARE_DOCX = "/contract-inhouse",
  CONTRACT_SHARE_UPLOAD = "/contract-third-party",
}
