import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";

import { ICreateContactFieldsNew } from "models/contacts";
import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface IBankDetailsProps {
  formikBag: FormikProps<ICreateContactFieldsNew>;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

function BankDetailsForm(props: IBankDetailsProps) {
  const { formikBag, setActiveSection } = props;
  const { values, handleChange, handleBlur } = formikBag;

  return (
    <Box
      sx={{
        pt: { xs: 2, sm: 14 },
        px: 4,
        pb: 1.5,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <Typography sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}>
            Bank Details
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Bank Name</Typography>
          <TextField
            id="financialDetails.bankName"
            value={values.financialDetails.bankName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Branch</Typography>
          <TextField
            id="financialDetails.branchName"
            value={values.financialDetails.branchName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Account Name</Typography>
          <TextField
            id="financialDetails.accountName"
            value={values.financialDetails.accountName}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography sx={styles.field_label}>BSB</Typography>
          <TextField
            id="financialDetails.accountBsb"
            value={values.financialDetails.accountBsb}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Typography sx={styles.field_label}>Account Number</Typography>
          <TextField
            id="financialDetails.accountNumber"
            value={values.financialDetails.accountNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>SWIFT Code</Typography>
          <TextField
            id="financialDetails.accountSwiftCode"
            value={values.financialDetails.accountSwiftCode}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
            <OutlinedBtn
              size="small"
              sx={{
                borderRadius: "20px",
                fontSize: "13px",
                fontWeight: 600,
                width: "120px",
                color: "#000",
              }}
              disableElevation
              disableRipple
              onClick={() => setActiveSection(1)}
            >
              Back
            </OutlinedBtn>
            <BlackActionContainedButton
              variant="contained"
              size="small"
              sx={{
                borderRadius: "20px",
                ml: 1,
                fontSize: "13px",
                fontWeight: 600,
                width: "120px",
              }}
              disableElevation
              disableRipple
              onClick={() => setActiveSection(3)}
            >
              Next
            </BlackActionContainedButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BankDetailsForm;
