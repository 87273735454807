import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";

import { GET_CONTACT_DEPARTMENT } from "graphql/meta";
import { IArr } from "models/common";
import { getPermissions } from "permissions/utils";

import { ProjectDetailsStyles as styles } from "../styles";

interface IFiltersProps {
  setSearchText: Dispatch<SetStateAction<string>>;
  setEmailSearchText: Dispatch<SetStateAction<string>>;
  searchText: string;
  emailSearchText: string;
  contactDeptFilter: string;
  setContactDeptFilter: Dispatch<SetStateAction<string>>;
}

const Filters = (props: IFiltersProps) => {
  const {
    setSearchText,
    setEmailSearchText,
    searchText,
    emailSearchText,
    contactDeptFilter,
    setContactDeptFilter,
  } = props;

  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const contactsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CONTACTS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [searchTextFieldValue, setSearchTextFieldValue] = useState(searchText);
  const [emailSearchTextFieldValue, setEmailSearchTextFieldValue] =
    useState(emailSearchText);
  const [deptArr, setDeptArr] = useState<IArr[]>([]);

  const {} = useQuery(GET_CONTACT_DEPARTMENT, {
    variables: {},
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      contactsViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[contactsViewIndex]?.CONTACTS_VIEW
    ),
    onCompleted: (data) => {
      const { departments } = data;
      const { status, departments: rawDepts } = departments;
      if (status) {
        setDeptArr(rawDepts);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextFieldValue(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchText(e.target.value);
    }, 300);
  };

  const handleEmailSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailSearchTextFieldValue(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setEmailSearchText(e.target.value);
    }, 300);
  };

  return (
    <Grid container spacing={2} sx={{ p: 1.5 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={styles.filter_field_label}>Name</Typography>
        <TextField
          size="small"
          fullWidth
          value={searchTextFieldValue}
          onChange={handleSearchChange}
          placeholder="Name"
          inputProps={{ style: { fontSize: "13px" } }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={styles.filter_field_label}>Email</Typography>
        <TextField
          size="small"
          fullWidth
          value={emailSearchTextFieldValue}
          onChange={handleEmailSearchChange}
          placeholder="Email"
          inputProps={{ style: { fontSize: "13px" } }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={styles.filter_field_label}>Department</Typography>
        <Autocomplete
          id="contactDepartmentId"
          value={
            deptArr.find((x) => x.id === contactDeptFilter) || {
              id: "",
              name: "",
            }
          }
          onChange={(_, newValue) => {
            if (newValue) {
              setContactDeptFilter(newValue.id);
            } else {
              setContactDeptFilter("");
            }
          }}
          size="small"
          fullWidth
          options={deptArr}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              inputProps={{
                ...params.inputProps,
                style: {
                  fontSize: "13px",
                },
              }}
              placeholder="Department"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Filters;
