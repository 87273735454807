import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Grid,
  Menu,
  MenuItem,
  Typography,
  menuItemClasses,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { useStore } from "utils/store";
import { GET_CLIENT_ACTIVITY_LOGS, GET_USERS_ARR } from "graphql/users";
import { groupActivitiesByDate } from "utils/helpers";
import { IActivityLog } from "models/user";
import DateRangePicker from "common/DateRangePicker/DateRangePicker";
import { useClientSettingsTabs } from "hooks/useClientSettingsTabs";
import { IArr } from "models/common";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import { SettingsStyles as styles } from "./styles";
import Settings from ".";

const changeReasonMapping = {
  Create: "Created",
  Update: "Edited",
  Execute: "Executed",
  Emailed: "Emailed",
};

const sentenceMapping = {
  Create: "created a new",
  Update: "edited a",
  Execute: "executed a",
  Emailed: "emailed a",
};

const entityTypeMapping = {
  Project: "project",
  Contact: "contact",
  Document: "document",
};

interface IFilterProps {
  selectedStartDate: Date | null;
  setSelectedStartDate: Dispatch<SetStateAction<Date | null>>;
  selectedEndDate: Date | null;
  setSelectedEndDate: Dispatch<SetStateAction<Date | null>>;
  selectedUser: string;
  setSelectedUser: Dispatch<SetStateAction<string>>;
  filterError: string;
  setFilterError: Dispatch<SetStateAction<string>>;
}

const Filters = (props: IFilterProps) => {
  const {
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    selectedUser,
    setSelectedUser,
    // filterError,
    // setFilterError,
  } = props;

  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const settingsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("SETTINGS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [usersArr, setUsersArr] = useState<IArr[]>([]);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  const { loading } = useQuery(GET_USERS_ARR, {
    onCompleted: (data) => {
      const { users } = data;
      const { count, users: rawUsers } = users;
      if (count > 0) {
        setUsersArr(rawUsers.map((x: any) => ({ id: x.id, name: x.fullName })));
      } else {
        setUsersArr([]);
      }
    },
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      settingsViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[settingsViewIndex]?.SETTINGS_VIEW
    ),
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sx={{ flexGrow: 1 }}></Grid>
      <Grid item>
        <Box
          component="div"
          sx={[
            styles.flex_JCsb_Acenter,
            {
              borderRadius: "24px",
              border: `1px solid ${colors.primaryBlack}30`,
              py: 1,
              width: "120px",
              px: 2,
              cursor: "pointer",
              ":hover": {
                borderColor: colors.primaryBlack,
              },
            },
          ]}
          onClick={handleFilterMenuOpen}
        >
          <Typography sx={{ fontSize: "14px", color: `#aaaaaa` }}>
            Users
          </Typography>
          <ArrowDropDown sx={{ color: `#757575` }} />
        </Box>
        <Menu
          anchorEl={filterMenuAnchor}
          open={openFilterMenu}
          onClose={handleFilterMenuClose}
          elevation={0}
          sx={{
            "& .MuiMenu-paper": {
              width: "210px",
              mt: 1,
              border: "1px #00000030 solid",
              borderRadius: "15px",
              maxHeight: "350px",
            },
          }}
        >
          {usersArr.map((user) => (
            <MenuItem
              key={user.id}
              onClick={() => {
                if (selectedUser === user.id) {
                  setSelectedUser("");
                } else {
                  setSelectedUser(user.id);
                }
                handleFilterMenuClose();
              }}
              selected={user.id === selectedUser}
              sx={{
                [`&.${menuItemClasses.selected}`]: {
                  backgroundColor: "#41B19931",
                  "&:hover": { backgroundColor: "#41B19995" },
                },
                "&:hover": { backgroundColor: "#B6BCC330" },
              }}
            >
              <Typography
                sx={{ fontSize: "14px", color: colors.secondaryBlack }}
              >
                {user.name}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Grid>
      <Grid item>
        <DateRangePicker
          startDate={selectedStartDate}
          setStartDate={setSelectedStartDate}
          endDate={selectedEndDate}
          setEndDate={setSelectedEndDate}
        />
      </Grid>
    </Grid>
  );
};

export const ActivityLogs = () => {
  const { setLoading } = useStore();
  const settingsTabs = useClientSettingsTabs();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const settingsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("SETTINGS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [activityArr, setActivityArr] = useState<IActivityLog[]>([]);
  const [activities, setActivities] = useState<any>({});
  const [activityCount, setActivityCount] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [filterError, setFilterError] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const { loading, fetchMore } = useQuery(GET_CLIENT_ACTIVITY_LOGS, {
    variables: {
      page: 1,
      limit: 50,
      filter: {
        ...(Boolean(selectedStartDate) &&
          Boolean(selectedEndDate) &&
          !filterError && {
            historyDate__gte: moment(selectedStartDate).format("YYYY-MM-DD"),
            historyDate__lt: moment(selectedEndDate)
              .add(1, "day")
              .format("YYYY-MM-DD"),
          }),
        ...(Boolean(selectedUser) && { historyUserId: selectedUser }),
      },
    },
    onCompleted: (data) => {
      setLoading(false);
      const { getClientActivityLogs } = data;
      const { status, count, logs } = getClientActivityLogs;
      if (status) {
        setActivityCount(count);
        setActivityArr(
          logs.map((x: any) => ({
            ...x,
            additionalHistoryDetails: Boolean(x.additionalHistoryDetails)
              ? typeof x.additionalHistoryDetails === "string"
                ? Boolean(JSON.parse(x.additionalHistoryDetails))
                  ? JSON.parse(x.additionalHistoryDetails)
                  : []
                : x.additionalHistoryDetails
              : [],
          }))
        );
      }
    },
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      settingsViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[settingsViewIndex]?.SETTINGS_VIEW
    ),
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const getDateTitle = (date: any) => {
    const today = moment();
    const newDate = moment(date);
    if (today.isSame(newDate, "date")) return "Today";
    if (today.subtract(1, "day").isSame(newDate, "date")) return "Yesterday";
    return newDate.format("DD MMMM YYYY");
  };

  // useEffect(() => {
  //   const handleScroll = async () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight
  //     ) {
  //       return;
  //     }
  //     if (activityArr.length < activityCount) {
  //       try {
  //         setLoading(true);
  //         await fetchMore({
  //           variables: {
  //             page: Math.ceil(activityArr.length / 50) + 1,
  //             limit: 50,
  //           },
  //           updateQuery: (previousResult, { fetchMoreResult }) => {
  //             const newEntries = fetchMoreResult.getClientActivityLogs.logs;
  //             return {
  //               getClientActivityLogs: {
  //                 ...fetchMoreResult?.getClientActivityLogs,
  //                 logs: [...activityArr, ...newEntries],
  //               },
  //             };
  //           },
  //         });
  //       } catch (error) {
  //         console.error("ERROR", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [activityArr, activityCount, fetchMore, setLoading]);
  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (activityArr.length < activityCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(activityArr.length / 50) + 1,
              limit: 50,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.getClientActivityLogs.logs;
              return {
                getClientActivityLogs: {
                  ...fetchMoreResult?.getClientActivityLogs,
                  logs: [...activityArr, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activityArr, activityCount, fetchMore, setLoading]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    groupActivitiesByDate(activityArr, setActivities);
  }, [activityArr]);

  useEffect(() => {
    settingsTabs.setSelectedTab(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Settings>
      <Grid
        container
        spacing={2}
        sx={{ px: { xs: 0, sm: 20, md: 10, lg: 15 } }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Filters
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            filterError={filterError}
            setFilterError={setFilterError}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        </Grid>
        {Object.keys(activities).map((item) => (
          <Fragment key={item}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: 480 }}>
                  <Typography sx={styles.activity_header_text}>
                    {getDateTitle(item)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {(activities as any)[item].map(
              (act: IActivityLog, index: number) => (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  key={act.historyDate}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      sx={{
                        width: { xs: "100%", sm: 480 },
                        p: 2,
                        backgroundColor: `${colors.whiteGrey500}10`,
                        borderRadius: "15px",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        sx={{ flexWrap: "nowrap" }}
                      >
                        <Grid item>
                          {Boolean(act.changedByProfile) ? (
                            <Avatar
                              src={act.changedByProfile}
                              sx={{ width: "45px", height: "45px" }}
                            />
                          ) : (
                            <Avatar sx={{ width: "45px", height: "45px" }} />
                          )}
                        </Grid>
                        <Grid item sx={{ flexGrow: 1 }}>
                          <Box
                            sx={[
                              styles.flex,
                              { mb: 0.5, alignItems: "flex-end" },
                            ]}
                          >
                            <Typography
                              noWrap
                              sx={[
                                styles.proj_meta_data_text,
                                {
                                  color: colors.primaryBlack,
                                  width: "65%",
                                },
                              ]}
                            >
                              {`${act?.entityType} ${
                                act?.historyChangeReason === "Create" ||
                                act?.historyChangeReason === "Update" ||
                                act?.historyChangeReason === "Execute" ||
                                act?.historyChangeReason === "Emailed"
                                  ? (changeReasonMapping as any)[
                                      act?.historyChangeReason
                                    ]
                                  : act?.historyChangeReason
                              }`}
                            </Typography>
                            <Typography sx={styles.proj_meta_data_text}>
                              {moment(act?.historyDate).format("HH:mm a")}
                            </Typography>
                          </Box>
                          <Typography
                            sx={[
                              styles.proj_meta_data_text,
                              { color: colors.primaryBlack, fontSize: "14px" },
                            ]}
                          >
                            <span style={{ fontWeight: 600 }}>
                              {act?.changedBy}
                            </span>{" "}
                            {act?.historyChangeReason === "Create" ||
                            act?.historyChangeReason === "Update" ||
                            act?.historyChangeReason === "Execute" ||
                            act?.historyChangeReason === "Emailed"
                              ? (sentenceMapping as any)[
                                  act?.historyChangeReason
                                ]
                              : act?.historyChangeReason}{" "}
                            {(entityTypeMapping as any)[act?.entityType]}{" "}
                            {Boolean(act?.additionalHistoryDetails) &&
                            act.additionalHistoryDetails.length > 0
                              ? `to ${act?.additionalHistoryDetails.join(", ")}`
                              : ""}{" "}
                            - {act?.entityName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              )
            )}
          </Fragment>
        ))}
      </Grid>
    </Settings>
  );
};
