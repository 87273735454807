import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  ListItem,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  menuItemClasses,
} from "@mui/material";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";

import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";

import ContactReport from "./ContactReport";
import ContractStatusReport from "./ContractStatusReport";
import CastCrewContractStatusReport from "./CastCrewContractStatusReport";
import ChecklistReport from "./ChecklistReport";
import ScriptClearanceReport from "./ScriptClearanceReport";
import ClearanceLockOffReport from "./ClearanceLockOffReport";
import ProjectDetailsTabs from "..";

const ReportTypeArr = [
  { id: "CONTACT", name: "Contacts Report" },
  { id: "CONTRACT", name: "Contract Status Report" },
  { id: "CAST_CREW_CONTRACT", name: "Cast & Crew Contract Status Report" },
  { id: "SCRIPT_CLEARANCE", name: "Script Clearance Report" },
  { id: "CLEARANCE_LOCK_OFF", name: "Clearance Lock-off Report" },
  { id: "CHECKLIST", name: "Checklist Report" },
];

export const ProjectReports = () => {
  const projectDetailsTab = useProjectDetailsTabs();

  const [selectedReportType, setSelectedReportType] = useState("CONTACT");
  const [openReportTypeMenu, setOpenReportTypeMenu] = useState(false);
  const [reportTypeMenuAnchor, setReportTypeMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleReportTypeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setReportTypeMenuAnchor(e.currentTarget);
    setOpenReportTypeMenu(true);
  };

  const handleReportTypeMenuClose = () => {
    setReportTypeMenuAnchor(null);
    setOpenReportTypeMenu(false);
  };

  useEffect(() => {
    projectDetailsTab.setSelectedTab(5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <Grid container spacing={2.5}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ mt: 2, display: "flex", justifyContent: "center" }}
        >
          <Box sx={{ display: "inline-block" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F3EDE0",
                borderRadius: "8px",
                pl: 2,
                pr: 1,
                py: 1.25,
                width: "290px",
                border: "1px #e6e6e6 solid",
                cursor: "pointer",
              }}
              onClick={handleReportTypeClick}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography sx={{ fontSize: "14px" }}>
                  {ReportTypeArr.find((x) => x.id === selectedReportType)
                    ?.name || ""}
                </Typography>
              </Box>
              <KeyboardArrowDownOutlined
                fontSize="small"
                sx={{ color: "#000" }}
              />
            </Box>
            <Menu
              anchorEl={reportTypeMenuAnchor}
              open={openReportTypeMenu}
              onClose={handleReportTypeMenuClose}
              sx={{
                "& .MuiMenu-paper": {
                  width: "290px",
                  border: "1px #00000030 solid",
                  borderRadius: "8px",
                },
              }}
              elevation={0}
            >
              <MenuList sx={{ mx: 1 }} disablePadding>
                <ListItem sx={{ borderRadius: "6px", mb: -1 }}>
                  <Typography sx={{ fontSize: "14px", color: "#000" }}>
                    People
                  </Typography>
                </ListItem>
                {ReportTypeArr.map((rep, index) => {
                  if (index === 0) {
                    return (
                      <MenuItem
                        key={rep.id}
                        selected={selectedReportType === rep.id}
                        sx={{
                          borderRadius: "6px",
                          [`&.${menuItemClasses.selected}`]: {
                            backgroundColor: "#41B19931",
                            "&:hover": { backgroundColor: "#41B19995" },
                          },
                          "&:hover": { backgroundColor: "#B6BCC330" },
                        }}
                        onClick={() => {
                          setSelectedReportType(rep.id);
                          handleReportTypeMenuClose();
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "13px", color: "#00000080" }}
                        >
                          {rep.name}
                        </Typography>
                      </MenuItem>
                    );
                  }
                  return null;
                })}
                <ListItem sx={{ borderRadius: "6px", my: -1 }}>
                  <Typography sx={{ fontSize: "14px", color: "#000" }}>
                    Contracts
                  </Typography>
                </ListItem>
                {ReportTypeArr.map((rep, index) => {
                  if (index > 0 && index < 3) {
                    return (
                      <MenuItem
                        key={rep.id}
                        selected={selectedReportType === rep.id}
                        sx={{
                          borderRadius: "6px",
                          [`&.${menuItemClasses.selected}`]: {
                            backgroundColor: "#41B19931",
                            "&:hover": { backgroundColor: "#41B19995" },
                          },
                          "&:hover": { backgroundColor: "#B6BCC330" },
                        }}
                        onClick={() => {
                          setSelectedReportType(rep.id);
                          handleReportTypeMenuClose();
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "13px", color: "#00000080" }}
                        >
                          {rep.name}
                        </Typography>
                      </MenuItem>
                    );
                  }
                  return null;
                })}
                <ListItem sx={{ borderRadius: "6px", my: -1 }}>
                  <Typography sx={{ fontSize: "14px", color: "#000" }}>
                    Clearances
                  </Typography>
                </ListItem>
                {ReportTypeArr.map((rep, index) => {
                  if (index > 2 && index < 5) {
                    return (
                      <MenuItem
                        key={rep.id}
                        selected={selectedReportType === rep.id}
                        sx={{
                          borderRadius: "6px",
                          [`&.${menuItemClasses.selected}`]: {
                            backgroundColor: "#41B19931",
                            "&:hover": { backgroundColor: "#41B19995" },
                          },
                          "&:hover": { backgroundColor: "#B6BCC330" },
                        }}
                        onClick={() => {
                          setSelectedReportType(rep.id);
                          handleReportTypeMenuClose();
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "13px", color: "#00000080" }}
                        >
                          {rep.name}
                        </Typography>
                      </MenuItem>
                    );
                  }
                  return null;
                })}
                <ListItem sx={{ borderRadius: "6px", my: -1 }}>
                  <Typography sx={{ fontSize: "14px", color: "#000" }}>
                    Delivery
                  </Typography>
                </ListItem>
                {ReportTypeArr.map((rep, index) => {
                  if (index > 4) {
                    return (
                      <MenuItem
                        key={rep.id}
                        selected={selectedReportType === rep.id}
                        sx={{
                          borderRadius: "6px",
                          [`&.${menuItemClasses.selected}`]: {
                            backgroundColor: "#41B19931",
                            "&:hover": { backgroundColor: "#41B19995" },
                          },
                          "&:hover": { backgroundColor: "#B6BCC330" },
                        }}
                        onClick={() => {
                          setSelectedReportType(rep.id);
                          handleReportTypeMenuClose();
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "13px", color: "#00000080" }}
                        >
                          {rep.name}
                        </Typography>
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </MenuList>
            </Menu>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {selectedReportType === "CONTACT" && <ContactReport />}
          {selectedReportType === "CONTRACT" && <ContractStatusReport />}
          {selectedReportType === "CAST_CREW_CONTRACT" && (
            <CastCrewContractStatusReport />
          )}
          {selectedReportType === "CHECKLIST" && <ChecklistReport />}
          {selectedReportType === "SCRIPT_CLEARANCE" && (
            <ScriptClearanceReport />
          )}
          {selectedReportType === "CLEARANCE_LOCK_OFF" && (
            <ClearanceLockOffReport />
          )}
        </Grid>
      </Grid>
    </ProjectDetailsTabs>
  );
};
