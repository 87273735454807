import { IArr } from "models/common";

export interface IRole {
  id: string;
  name: string;
  createdDate: string;
  modifiedDate: string;
}

export interface IPermission {
  [key: string]: boolean;
}

export interface IRestrictionPermission {
  [key: string]: { view: boolean; edit: boolean };
}

export interface IRoleFields {
  name: string;
  permission: IPermission[];
  folder_restrictions: IRestrictionPermission | null;
  my_issues_restrictions: IRestrictionPermission | null;
}

export interface IRole extends IRoleFields {
  id: string;
}

export const initialRoleValues = (tempTypes: IArr[]): IRoleFields => {
  const folder_restrictions_init = tempTypes.map((x: any) => ({
    [x.id]: { view: false, edit: false },
  }));
  let folder_restrictions_init_obj = {};
  for (let i = 0; i < folder_restrictions_init.length; i = i + 1) {
    folder_restrictions_init_obj = {
      ...folder_restrictions_init_obj,
      ...folder_restrictions_init[i],
    };
  }
  return {
    name: "",
    permission: [
      { DASHBOARD: false },
      { REPORTS: false },
      { INTEGRATIONS_VIEW: false },
      { INTEGRATIONS_EDIT: false },
      { SETTINGS_VIEW: false },
      { SETTINGS_EDIT: false },
      { AGENTS_VIEW: false },
      { AGENTS_EDIT: false },
      { PROJECT_VIEW: false },
      { PROJECT_EDIT: false },
      { CONTACTS_VIEW: false },
      { CONTACTS_EDIT: false },
      { FOLDERS_VIEW: false },
      { FOLDERS_EDIT: false },
      { CLEARANCE_VIEW: false },
      { CLEARANCE_EDIT: false },
      { DELIVERY_VIEW: false },
      { DELIVERY_EDIT: false },
      { PROJECT_REPORTS: false },
      { ISSUES_VIEW: false },
      { ISSUES_EDIT: false },
    ],
    folder_restrictions: folder_restrictions_init_obj,
    my_issues_restrictions: {
      my_issues: { view: false, edit: false },
      all_issues: { view: false, edit: false },
    },
  };
};

export interface IPermissionArr {
  name: string;
  view: string;
  edit: string;
}

export const ClientLevelPermissions: IPermissionArr[] = [
  { name: "Dashboard", view: "DASHBOARD", edit: "" },
  { name: "Reports", view: "REPORTS", edit: "" },
  {
    name: "Integrations",
    view: "INTEGRATIONS_VIEW",
    edit: "INTEGRATIONS_EDIT",
  },
  {
    name: "Settings (Users & Activity)",
    view: "SETTINGS_VIEW",
    edit: "SETTINGS_EDIT",
  },
  { name: "Agents", view: "AGENTS_VIEW", edit: "AGENTS_EDIT" },
];

export const ProjectLevelPermissions: IPermissionArr[] = [
  // { name: "Project Setup", view: "PROJECT_VIEW", edit: "PROJECT_EDIT" },
  { name: "Contacts", view: "CONTACTS_VIEW", edit: "CONTACTS_EDIT" },
  { name: "Folders (ALL)", view: "FOLDERS_VIEW", edit: "FOLDERS_EDIT" },
  // { name: "Clearances", view: "CLEARANCE_VIEW", edit: "CLEARANCE_EDIT" },
  { name: "Delivery", view: "DELIVERY_VIEW", edit: "DELIVERY_EDIT" },
  { name: "Project Reports", view: "PROJECT_REPORTS", edit: "" },
  { name: "Issues", view: "ISSUES_VIEW", edit: "ISSUES_EDIT" },
];

export const IssuesChildArr: IArr[] = [
  { id: "1", name: "All Issues" },
  { id: "2", name: "My Issues" },
];

export const getCheckedValue = (
  permissionArr: IPermission[],
  module: string
) => {
  const permissionIndex = permissionArr.findIndex((x) =>
    x.hasOwnProperty(module)
  );
  if (permissionIndex > -1) {
    return permissionArr[permissionIndex][module];
  }
  return false;
};

export const setFolderRestrictions = (
  folderRestrictions: IRestrictionPermission | null,
  type: string,
  tempTypes: IArr[],
  checked: boolean,
  setFieldValue: any
) => {
  if (Boolean(folderRestrictions)) {
    let tempObj = { ...folderRestrictions };
    for (let i = 0; i < tempTypes.length; i = i + 1) {
      tempObj = {
        ...tempObj,
        [tempTypes[i].id]: {
          ...(type === "View"
            ? { ...tempObj[tempTypes[i].id], view: checked }
            : type === "Edit"
            ? {
                ...tempObj[tempTypes[i].id],
                edit: checked,
                ...(checked && { view: checked }),
              }
            : {
                ...tempObj[tempTypes[i].id],
                edit: checked,
                view: checked,
              }),
        },
      };
    }
    setFieldValue("folder_restrictions", tempObj);
  } else {
    let tempObj = {};
    for (let i = 0; i < tempTypes.length; i = i + 1) {
      tempObj = {
        ...tempObj,
        [tempTypes[i].id]: {
          ...(type === "View"
            ? { view: checked, edit: false }
            : { view: checked, edit: checked }),
        },
      };
    }
    setFieldValue("folder_restrictions", tempObj);
  }
};

export const setAllProjectSetupFalse = (
  permissions: any,
  setFieldValue: any,
  templateTypeArr: IArr[]
) => {
  const contactsViewIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("CONTACTS_VIEW")
  );
  const contactsEditIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("CONTACTS_EDIT")
  );
  const foldersViewIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("FOLDERS_VIEW")
  );
  const foldersEditIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("FOLDERS_EDIT")
  );
  const clearanceViewIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("CLEARANCE_VIEW")
  );
  const clearanceEditIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("CLEARANCE_EDIT")
  );
  const deliveryViewIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("DELIVERY_VIEW")
  );
  const deliveryEditIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("DELIVERY_EDIT")
  );
  const projectReportsIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("PROJECT_REPORTS")
  );
  const issuesViewIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("ISSUES_VIEW")
  );
  const issuesEditIndex = permissions.findIndex((x: any) =>
    x.hasOwnProperty("ISSUES_EDIT")
  );

  setFieldValue(`permission[${contactsViewIndex}]`, { CONTACTS_VIEW: false });
  setFieldValue(`permission[${contactsEditIndex}]`, { CONTACTS_EDIT: false });
  setFieldValue(`permission[${foldersViewIndex}]`, { FOLDERS_VIEW: false });
  setFieldValue(`permission[${foldersEditIndex}]`, { FOLDERS_EDIT: false });
  setFieldValue(`permission[${clearanceViewIndex}]`, { CLEARANCE_VIEW: false });
  setFieldValue(`permission[${clearanceEditIndex}]`, { CLEARANCE_EDIT: false });
  setFieldValue(`permission[${deliveryViewIndex}]`, { DELIVERY_VIEW: false });
  setFieldValue(`permission[${deliveryEditIndex}]`, { DELIVERY_EDIT: false });
  setFieldValue(`permission[${projectReportsIndex}]`, {
    PROJECT_REPORTS: false,
  });
  setFieldValue(`permission[${issuesViewIndex}]`, { ISSUES_VIEW: false });
  setFieldValue(`permission[${issuesEditIndex}]`, { ISSUES_EDIT: false });

  setFieldValue("my_issues_restrictions", {
    my_issues: { view: false, edit: false },
    all_issues: { view: false, edit: false },
  });

  let tempObj = {};
  for (let i = 0; i < templateTypeArr.length; i = i + 1) {
    tempObj = {
      ...tempObj,
      [templateTypeArr[i]["id"]]: { view: false, edit: false },
    };
  }
  setFieldValue("folder_restrictions", tempObj);
};

export const checkIfAnyTempTypeChecked = (
  folder_restrictions: IRestrictionPermission | null,
  type: string
) => {
  let flag = 0;
  const keyArr = Object.keys(folder_restrictions!);
  for (let i = 0; i < keyArr.length; i = i + 1) {
    if ((folder_restrictions as any)[keyArr[i]][type]) {
      flag = 1;
      break;
    }
  }
  return flag === 1;
};

export const checkIfAnyTempTypeUnChecked = (
  folder_restrictions: IRestrictionPermission | null,
  type: string
) => {
  let flag = 0;
  const keyArr = Object.keys(folder_restrictions!);
  for (let i = 0; i < keyArr.length; i = i + 1) {
    if (!(folder_restrictions as any)[keyArr[i]][type]) {
      flag = 1;
      break;
    }
  }
  return flag === 1;
};

export const checkIfAnyIssueChecked = (
  my_issues_restrictions: IRestrictionPermission | null,
  type: string
) => {
  let flag = 0;
  const keyArr = Object.keys(my_issues_restrictions!);
  for (let i = 0; i < keyArr.length; i = i + 1) {
    if ((my_issues_restrictions as any)[keyArr[i]][type]) {
      flag = 1;
      break;
    }
  }
  return flag === 1;
};
