import React, { useRef, useState } from "react";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
  type Crop,
} from "react-image-crop";

import { IModalProps } from "models/common";

import setCanvasPreview from "./setCanvasPreview";
import { BlackActionContainedButton, NewOutlinedBtn } from "../Buttons";
import "react-image-crop/dist/ReactCrop.css";

interface IProfilePhotoCircularCropperProps extends IModalProps {
  width: number;
  imgSrc: any;
  setImgSrc: any;
}

function ProfilePhotoCircularCropper(props: IProfilePhotoCircularCropperProps) {
  const { open, setOpen, width, imgSrc, setImgSrc } = props;

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [fileName, setFileName] = useState("");
  const [imageSource, setImageSource] = useState<any>(imgSrc);
  const [crop, setCrop] = useState<Crop>();

  const onImageLoad = (e: any) => {
    const { width: currentWidth, height: currentHeight } = e.currentTarget;
    const cropWidthInPercent = (width / currentWidth) * 100;

    const crop1 = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      1,
      currentWidth,
      currentHeight
    );
    const centeredCrop = centerCrop(crop1, currentWidth, currentHeight);
    setCrop(centeredCrop);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "24px", overflow: "hidden" } }}
      maxWidth="sm"
      fullWidth
    >
      <Box
        sx={{
          px: { xs: 2, sm: 4 },
          pt: 2,
          pb: 1.5,
          overflow: "auto",
          minHeight: "400px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Upload
                accept=".jpeg, .jpg, .png"
                beforeUpload={(file: RcFile, __) => {
                  setFileName(file?.name);
                  const reader = new FileReader();
                  reader.addEventListener("load", () => {
                    const imageElement = new Image();
                    const imageUrl = reader.result?.toString() || "";
                    imageElement.src = imageUrl;
                    setImageSource(imageUrl);
                  });
                  reader.readAsDataURL(file);
                  return Promise.resolve();
                }}
              >
                <NewOutlinedBtn
                  size="small"
                  variant="outlined"
                  sx={{ color: "#000" }}
                >
                  Choose file
                </NewOutlinedBtn>
              </Upload>
              {Boolean(imageSource) ? (
                typeof imageSource === "string" ? (
                  <Typography
                    sx={{ fontSize: "13px", ml: 1.5, color: "#00000070" }}
                  >
                    {fileName}
                  </Typography>
                ) : (
                  <></>
                )
              ) : (
                <Typography
                  sx={{ fontSize: "13px", ml: 1.5, color: "#00000070" }}
                >
                  No file chosen
                </Typography>
              )}
            </Box>
          </Box>
          <Box>
            <IconButton size="small" onClick={handleClose}>
              <Close sx={{ color: "#00000055" }} fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        {Boolean(imageSource) &&
          typeof imageSource === "string" &&
          !(imageSource as string).startsWith("https://") && (
            <Box
              sx={{
                mt: 3,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "200px",
                  }}
                >
                  <ReactCrop
                    crop={crop}
                    onChange={(c, p) => setCrop(p)}
                    aspect={1}
                    circularCrop
                  >
                    <img
                      ref={imgRef}
                      src={
                        typeof imageSource === "string"
                          ? imageSource
                          : URL.createObjectURL(imageSource)
                      }
                      alt="profile_pic"
                      style={{ maxHeight: "200px" }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <BlackActionContainedButton
                  variant="contained"
                  onClick={() => {
                    if (Boolean(imgRef?.current)) {
                      setCanvasPreview(
                        imgRef!.current as unknown as HTMLImageElement, // HTMLImageElement
                        previewCanvasRef!
                          .current as unknown as HTMLCanvasElement, // HTMLCanvasElement
                        convertToPixelCrop(
                          crop!,
                          (imgRef!.current as any).width,
                          (imgRef!.current as any).height
                        )
                      );

                      const dataUrl = (
                        previewCanvasRef!.current as any
                      ).toDataURL();
                      const arr = dataUrl.split(",");
                      const mime = arr[0].match(/:(.*?);/)[1];
                      const bstr = atob(arr[1]);
                      let n = bstr.length;
                      const u8arr = new Uint8Array(n);
                      while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                      }
                      setImgSrc(new File([u8arr], fileName, { type: mime }));
                      handleClose();
                    }
                  }}
                  sx={{ borderRadius: "20px", width: 100 }}
                  disableElevation
                >
                  Crop
                </BlackActionContainedButton>
              </Box>
            </Box>
          )}
        {Boolean(imageSource) &&
          ((typeof imageSource === "string" &&
            (imageSource as string).startsWith("https://")) ||
            typeof imageSource !== "string") && (
            <Box
              sx={{
                mt: 3,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
              }}
            >
              <img
                ref={imgRef}
                src={
                  typeof imageSource === "string"
                    ? imageSource
                    : URL.createObjectURL(imageSource)
                }
                alt="profile_pic"
                style={{ maxHeight: "200px" }}
              />
            </Box>
          )}
        {crop && (
          <canvas
            ref={previewCanvasRef}
            className="mt-4"
            style={{
              display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: 150,
              height: 150,
            }}
          />
        )}
      </Box>
    </Dialog>
  );
}

export default ProfilePhotoCircularCropper;
