import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useQuery } from "@apollo/client";

import { IArr, IModalProps } from "models/common";
import { AdminRoleManagementLabels, GlobalLabels } from "common/AppConstants";
import { GET_ROLE_DETAILS } from "graphql/roles";
import { GET_TEMPLATE_TYPES } from "graphql/templates";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import { IRoleFields, initialRoleValues } from "./utils";
import { AdminUsersStyles as styles } from "./styles";
import RolesListView from "./RolesListView";

interface IAddRoleModalProps extends IModalProps {
  selectedRole: string;
  setSelectedRole: Dispatch<SetStateAction<string>>;
}

const ViewPermissionModal = (props: IAddRoleModalProps) => {
  const { open, setOpen, selectedRole, setSelectedRole } = props;

  const { setLoading } = useStore();

  const [templateTypeArr, setTemplateTypeArr] = useState<IArr[]>([]);

  const {} = useQuery(GET_TEMPLATE_TYPES, {
    variables: {},
    onCompleted: (data) => {
      const { templateTypes } = data;
      const { status, templateTypes: rawTemplateTypes } = templateTypes;
      if (status) {
        setTemplateTypeArr(rawTemplateTypes);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [rolePermissions, setRolePermissions] = useState<IRoleFields>(
    initialRoleValues(templateTypeArr)
  );

  const { loading } = useQuery(GET_ROLE_DETAILS, {
    variables: { id: selectedRole },
    skip: !selectedRole,
    onCompleted: (data) => {
      const { role } = data;
      const { status, role: rawRole } = role;
      if (status) {
        const { name, rolePermission } = rawRole;
        const permissionJSON = Boolean(rolePermission)
          ? JSON.parse(rolePermission)
          : null;
        setRolePermissions({
          name,
          permission: Boolean(permissionJSON)
            ? permissionJSON?.permissions ||
              initialRoleValues(templateTypeArr).permission
            : initialRoleValues(templateTypeArr).permission,
          folder_restrictions: Boolean(permissionJSON)
            ? permissionJSON?.folder_restrictions ||
              initialRoleValues(templateTypeArr).folder_restrictions
            : initialRoleValues(templateTypeArr).folder_restrictions,
          my_issues_restrictions: Boolean(permissionJSON)
            ? permissionJSON?.my_issues_restrictions ||
              initialRoleValues(templateTypeArr).my_issues_restrictions
            : initialRoleValues(templateTypeArr).my_issues_restrictions,
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setOpen(false);
    setSelectedRole("");
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={styles.flex_JCsb_Acenter}>
        <Typography
          sx={styles.add_admin_text}
        >{`${GlobalLabels.VIEW} ${AdminRoleManagementLabels.ROLE}`}</Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={[styles.add_admin_text, { color: colors.primary }]}>
            {rolePermissions?.name}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              border: "1px #e6e6e6 solid",
              borderRadius: "8px",
              p: 2,
            }}
          >
            <RolesListView values={rolePermissions} />
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ViewPermissionModal;
