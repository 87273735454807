import React from "react";
import { Box, Chip, Divider, Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment";

import { images } from "assets/images";
import { IProject } from "models/projects";
import { colors } from "theme/colors";

import { DashboardStyles as styles } from "./styles";

interface IKeyProjectDatesProps {
  recentProjects: IProject[];
}

interface IKeyDatesCard {
  dateData: IProject;
}

function KeyDatesCard(props: IKeyDatesCard) {
  const { dateData } = props;
  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item>
        {dateData?.profilePicture ? (
          <Box sx={[styles.proj_img, { height: "170px" }]}>
            <img
              src={dateData?.profilePicture || images.REEL}
              alt="proj_image"
              style={{
                height: "170px",
                width: "114.75px",
                borderRadius: "15px",
                objectFit: "cover",
                aspectRatio: 0.675 / 1,
                ...(dateData?.projectStatus === 2 && {
                  filter: "grayscale(100%)",
                }),
              }}
            />
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={[
                styles.proj_img,
                {
                  height: "170px",
                  width: "114.75px",
                  backgroundColor: `#BFBDBC22`,
                  aspectRatio: 0.675 / 1,
                  borderRadius: "15px",
                },
              ]}
            >
              <img src={images.FILM_REEL} alt="reel" style={{ height: 30 }} />
            </Box>
          </Box>
        )}
      </Grid>
      <Grid item>
        <Box sx={{ width: 250 }}>
          <Tooltip title={dateData?.name}>
            <Typography
              noWrap
              sx={[
                styles.project_name_text,
                {
                  mb: 1,
                  ...(dateData?.projectStatus === 2 && {
                    color: `${colors.primaryBlack}50`,
                  }),
                },
              ]}
            >
              {dateData?.name}
            </Typography>
          </Tooltip>
          <Box sx={styles.flex_Acenter}>
            <Typography
              sx={[
                styles.proj_meta_data_text,
                {
                  width: 70,
                  color: `${colors.secondaryBlack}70`,
                  fontSize: "13px",
                },
              ]}
            >
              Start Date:
            </Typography>
            <Chip
              size="small"
              sx={[styles.start_date_chip, styles.ml1]}
              label={moment(dateData.projectStartDate).format("DD MMMM YYYY")}
            />
          </Box>
          <Box sx={[styles.flex_Acenter, { mt: 1, overflow: "auto" }]}>
            <Typography
              sx={[
                styles.proj_meta_data_text,
                {
                  width: 70,
                  color: `${colors.secondaryBlack}70`,
                  fontSize: "13px",
                },
              ]}
            >
              End Date:
            </Typography>
            <Chip
              size="small"
              sx={[styles.end_date_chip, styles.ml1]}
              label={
                dateData.projectEndDate
                  ? moment(dateData.projectEndDate).format("DD MMMM YYYY")
                  : ""
              }
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

function KeyProjectDates(props: IKeyProjectDatesProps) {
  const { recentProjects } = props;

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={[
              {
                display: "flex",
                alignItems: "center",
                width: { xs: "100%", sm: 480 },
              },
            ]}
          >
            <Box sx={{ flexGrow: 1, mr: { xs: 4, sm: 8 } }}>
              <Divider />
            </Box>
            <Typography sx={[styles.titleText, { fontWeight: 900 }]}>
              Key Dates
            </Typography>
            <Box sx={{ flexGrow: 1, ml: { xs: 4, sm: 8 } }}>
              <Divider />
            </Box>
          </Box>
        </Box>
      </Grid>
      {recentProjects.map((proj) => (
        <Grid key={proj.id} item lg={12} md={12} sm={12} xs={12}>
          <KeyDatesCard dateData={proj} />
        </Grid>
      ))}
    </Grid>
  );
}

export default KeyProjectDates;
