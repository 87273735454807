import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { colors } from "theme/colors";

export const OutlinedBtn = styled(Button)(() => ({
  border: "1px solid #00000030",
  backgroundColor: "#e6e6e6",
  ":hover": {
    backgroundColor: "#e6e6e6",
    borderColor: "#000000",
  },
}));

export const RedContainedBtn = styled(Button)(() => ({
  backgroundColor: "#D3010E",
  ":hover": { backgroundColor: "#AC000B" },
}));

export const GreenContainedBtn = styled(Button)(() => ({
  backgroundColor: "#28C76F",
  ":hover": { backgroundColor: "#1F9D57" },
}));

export const RedTextBtn = styled(Button)(() => ({
  color: "#D72C51",
  ":hover": {
    backgroundColor: "#D72C5110",
  },
}));

export const GreenTextBtn = styled(Button)(() => ({
  color: "#28C76F",
  ":hover": {
    backgroundColor: "#28C76F10",
  },
}));

export const RedChipBtn = styled(Button)(() => ({
  color: "#D72C51",
  backgroundColor: "#D72C5115",
  ":hover": {
    backgroundColor: "#D72C5110",
  },
  borderRadius: "18px",
  minWidth: "100px",
}));

export const GreenChipBtn = styled(Button)(() => ({
  color: "#28C76F",
  backgroundColor: "#28C76F20",
  ":hover": {
    backgroundColor: "#28C76F10",
  },
  borderRadius: "18px",
  minWidth: "115px",
}));

export const YellowChipBtn = styled(Button)(() => ({
  color: "#FFA500",
  backgroundColor: "#FFA50020",
  ":hover": {
    backgroundColor: "#FFA50010",
  },
  borderRadius: "18px",
  minWidth: "115px",
}));

export const ChipBtn = styled(Button)(() => ({
  borderRadius: "18px",
  minWidth: "115px",
}));

export const NewGreenPrimaryContainedButton = styled(Button)(() => ({
  backgroundColor: "#41B19931",
  color: colors.primaryBlack,
  ":hover": { backgroundColor: "#41B19995" },
}));

export const BlackActionContainedButton = styled(Button)(() => ({
  backgroundColor: colors.primaryBlack,
  color: colors.secondary,
  ":hover": { backgroundColor: colors.primaryBlack },
}));

export const BlackTextButton = styled(Button)(() => ({
  color: colors.primaryBlack,
  ":hover": { backgroundColor: `${colors.primaryBlack}10` },
}));

export const NewRedAlertContainedButton = styled(Button)(() => ({
  backgroundColor: "#DF7D8C",
  color: colors.secondary,
  ":hover": { backgroundColor: "#D45659" },
}));

export const NewPrimaryGreenTextButton = styled(Button)(() => ({
  color: "#41B19995",
  ":hover": { backgroundColor: "#41B19931" },
}));

export const NewOutlinedBtn = styled(Button)(() => ({
  border: "1px solid #D9D9D8",
  ":hover": {
    borderColor: "#000000",
    backgroundColor: "transparent",
  },
}));
