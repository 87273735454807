import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import { IUpdateContactFieldsNew } from "models/contacts";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import { CountryCodeArr } from "utils/constants";

interface ICompanyDetailsFormProps {
	initialValues: IUpdateContactFieldsNew;
	updateContactApi: any;
	refetch: any;
	setActiveSection: Dispatch<SetStateAction<number>>;
	setActiveStep: Dispatch<SetStateAction<number>>;
	setPrevActiveSection: Dispatch<SetStateAction<number>>;
}

function CompanyDetailsForm(props: ICompanyDetailsFormProps) {
	const {
		initialValues,
		setActiveSection,
		setActiveStep,
		setPrevActiveSection,
	} = props;

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				managementCompanyDetails: Yup.object().shape({
					spocEmailId: Yup.string()
						.email("Please enter a valid email")
						.nullable(),
					spocMobile: Yup.string()
						.matches(
							/^[0-9]{3}\s[0-9]{3}\s[0-9]{3}$/,
							"Contact number must be of 9 digits"
						)
						.nullable(),
					website: Yup.string()
						.matches(
							/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
							"Please enter a valid url"
						)
						.nullable(),
				}),
			})}
			onSubmit={() => {}}
			enableReinitialize
		>
			{({ values }) => (
				<Box
					sx={{
						pt: { xs: 2, sm: 14 },
						px: 4,
						pb: 1.5,
						display: "flex",
						flexDirection: "column",
						height: "100%",
					}}
				>
					<Grid container spacing={3}>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Company Name
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.managementCompanyDetails?.companyName}
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Manager Full Name
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.managementCompanyDetails?.spocName}
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Email
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.managementCompanyDetails?.spocEmailId}
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Mobile
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{Boolean(values.managementCompanyDetails.spocMobile) &&
								Boolean(
									values.managementCompanyDetails.spocMobile
										.nationalNumber
								)
									? `${
											CountryCodeArr?.find(
												(x) =>
													x.id ===
													values.managementCompanyDetails
														.spocMobile?.countryCode
											)?.name || ""
									  } ${
											values.managementCompanyDetails.spocMobile
												?.nationalNumber || ""
									  }`
									: Boolean(
											values.managementCompanyDetails.spocMobile
												.rawInput
									  ) &&
									  values.managementCompanyDetails?.spocMobile
											?.rawInput !== "+61 "
									? `+61 ${values.managementCompanyDetails.spocMobile.rawInput}`
									: ""}
							</Typography>
						</Grid>
						<Grid item lg={3} md={4} sm={5} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Unit/Suite Number
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.managementCompanyDetails?.unit || ""}
							</Typography>
						</Grid>
						<Grid item lg={9} md={8} sm={7} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Address
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{Boolean(values?.managementCompanyDetails?.address)
									? typeof values?.managementCompanyDetails
											?.address === "string"
										? values?.managementCompanyDetails?.address
										: ""
									: ""}
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Website
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.managementCompanyDetails?.website}
							</Typography>
						</Grid>
					</Grid>
					<Box sx={{ flexGrow: 1, mt: 2 }}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
							}}
						>
							<Box sx={{ flexGrow: 1 }} />
							<Box
								sx={[
									styles.flex_Acenter,
									{ justifyContent: "flex-end" },
								]}
							>
								<OutlinedBtn
									size="small"
									sx={{
										borderRadius: "20px",
										fontSize: "13px",
										fontWeight: 600,
										width: "120px",
										color: "#000",
									}}
									disableElevation
									disableRipple
									onClick={() => {
										setPrevActiveSection(2);
										setActiveStep(2);
									}}
								>
									Back
								</OutlinedBtn>
								<BlackActionContainedButton
									variant="contained"
									size="small"
									sx={{
										borderRadius: "20px",
										ml: 1,
										fontSize: "13px",
										fontWeight: 600,
										width: "120px",
									}}
									disableElevation
									disableRipple
									onClick={() => setActiveSection(1)}
								>
									Next
								</BlackActionContainedButton>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</Formik>
	);
}

export default CompanyDetailsForm;
