import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  TextField,
} from "@mui/material";
import {
  Add,
  FilterAltOutlined,
  // FormatListBulletedOutlined,
  // GridViewOutlined,
  Search,
} from "@mui/icons-material";

import { getPermissions } from "permissions/utils";
import { NewOutlinedBtn } from "common/Buttons";
import { colors } from "theme/colors";

import Filters from "./Filters";

interface IHeaderProps {
  setAddEditClearanceModal: Dispatch<SetStateAction<boolean>>;
  setSearchText: Dispatch<SetStateAction<string>>;
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
}

function Header(props: IHeaderProps) {
  const { setAddEditClearanceModal, setSearchText, sortBy, setSortBy } = props;

  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const clearanceEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CLEARANCE_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );
  const [searchTextFieldValue, setSearchTextFieldValue] = useState("");

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextFieldValue(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchText(e.target.value);
    }, 300);
  };

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {Boolean(ROLE_PERMISSIONS) &&
          clearanceEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[clearanceEditIndex]
            ?.CLEARANCE_EDIT ? (
            <NewOutlinedBtn
              disableElevation
              startIcon={<Add sx={{ color: "#000" }} />}
              sx={{
                color: "#000",
                py: 1,
                px: 2,
                fontSize: "14px",
                borderRadius: "8px",
              }}
              onClick={() => setAddEditClearanceModal(true)}
            >
              New Clearance
            </NewOutlinedBtn>
          ) : (
            <Box sx={{ height: 42, width: 150 }} />
          )}
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          {Boolean(ROLE_PERMISSIONS) &&
          clearanceEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[clearanceEditIndex]
            ?.CLEARANCE_EDIT ? (
            <NewOutlinedBtn
              disableElevation
              startIcon={<Add sx={{ color: "#000" }} />}
              sx={{
                color: "#000",
                py: 1,
                px: 2,
                fontSize: "14px",
                borderRadius: "8px",
              }}
              onClick={() => setAddEditClearanceModal(true)}
            >
              New
            </NewOutlinedBtn>
          ) : (
            <Box sx={{ height: 42, width: 83 }} />
          )}
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <IconButton
                onClick={handleFilterMenuOpen}
                size="small"
                sx={{ ":hover": { backgroundColor: "#41B19931" } }}
              >
                <FilterAltOutlined
                  fontSize="small"
                  sx={{
                    color: openFilterMenu
                      ? "#41B199"
                      : `${colors.primaryBlack}50`,
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={filterMenuAnchor}
                open={openFilterMenu}
                onClose={handleFilterMenuClose}
                elevation={1}
                sx={{ "& .MuiMenu-paper": { width: 220 } }}
              >
                <Filters sortBy={sortBy} setSortBy={setSortBy} />
              </Menu>
            </Box>
            {/* <Box sx={{ mx: 0.55 }}>
            <IconButton
              onClick={() => setView(0)}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <FormatListBulletedOutlined
                fontSize="small"
                sx={{
                  color: view === 0 ? "#41B199" : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              onClick={() => setView(1)}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <GridViewOutlined
                fontSize="small"
                sx={{
                  color: view === 1 ? "#41B199" : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
          </Box> */}
          </Box>
          <TextField
            value={searchTextFieldValue}
            onChange={handleSearchChange}
            size="small"
            sx={{ width: { xs: 170, sm: 200, md: 220 }, ml: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" sx={{ color: "#00000070" }} />
                </InputAdornment>
              ),
            }}
            inputProps={{ style: { fontSize: "13px" } }}
            placeholder="Keywords"
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Header;
