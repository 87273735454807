import { gql } from "@apollo/client";

export const GET_ROLES_ARR = gql`
  query roles {
    roles {
      status
      message
      roles {
        id
        name
      }
    }
  }
`;

export const GET_ROLE_CONFIG = gql`
  query getConfig($key: String!) {
    config(key: $key) {
      status
      message
      config {
        key
        value
      }
    }
  }
`;

export const GET_ALL_ROLES_SUPER_ADMIN = gql`
  query allRoles($limit: Int, $page: Int, $filter: RolesFilterInput) {
    allRoles(limit: $limit, page: $page, filter: $filter) {
      status
      message
      count
      roles {
        id
        name
        createdDate
        modifiedDate
      }
    }
  }
`;

export const GET_ROLE_DETAILS = gql`
  query role($id: String!) {
    role(id: $id) {
      status
      message
      role {
        id
        name
        rolePermission
      }
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation createRole($payload: createRoleInput!) {
    createRole(payload: $payload) {
      status
      message
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($payload: updateRoleInput!) {
    updateRole(payload: $payload) {
      status
      message
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteRole($id: String!) {
    deleteRole(id: $id) {
      status
      message
    }
  }
`;
