import React, { useState } from "react";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CalendarTodayOutlined } from "@mui/icons-material";

import { colors } from "theme/colors";

interface IDatePickerInputProps {
  textValue: string;
  onCloseCustom?: (() => void) | undefined;
  value: Date | null;
  onChange: (
    value: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
  onOpen: (() => void) | undefined;
  disabled?: boolean;
  disabledFuture?: boolean;
  disabledPast?: boolean;
  background?: string;
}

function DatePickerInput(props: IDatePickerInputProps) {
  const {
    textValue,
    value,
    onChange,
    onOpen,
    onCloseCustom,
    disabled: disabledP,
    disabledFuture = false,
    disabledPast = false,
    background = "#e6e6e640",
  } = props;

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (onOpen) {
      onOpen();
    }
  };

  return (
    <TextField
      value={textValue}
      disabled
      size="small"
      sx={{
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: Boolean(disabledP)
            ? "#3b3b3b45"
            : colors.primaryBlack,
        },
      }}
      fullWidth
      inputProps={{
        style: {
          backgroundColor: background,
          borderRadius: "6px",
          fontSize: "13px",
          color: Boolean(disabledP) ? "#3b3b3b45" : colors.primaryBlack,
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box
              sx={{
                backgroundColor: background,
                borderTopRightRadius: "6px",
                borderBottomRightRadius: "6px",
                py: "3px",
                pl: 3,
                mr: -1.75,
                ml: -1,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  disableFuture={disabledFuture}
                  disablePast={disabledPast}
                  open={openDatePicker}
                  onClose={() => {
                    setOpenDatePicker(false);
                    if (onCloseCustom) {
                      onCloseCustom();
                    }
                  }}
                  value={value}
                  onChange={onChange}
                  onOpen={() => {
                    setOpenDatePicker(true);
                    if (onOpen) {
                      onOpen();
                    }
                  }}
                  inputFormat="DD/MM/YYYY"
                  PopperProps={{ anchorEl: anchorEl }}
                  renderDay={(day, selectedDates, pickersDayProps) => (
                    <PickersDay
                      {...pickersDayProps}
                      sx={{
                        ...(pickersDayProps.selected && {
                          backgroundColor: "#41b19995 !important",
                          fontWeight: 600,
                        }),
                      }}
                    />
                  )}
                  renderInput={({
                    ref,
                    inputProps,
                    disabled,
                    onChange,
                    value,
                    ...other
                  }) => (
                    <div ref={ref}>
                      <input
                        style={{ display: "none" }}
                        onChange={onChange}
                        disabled={disabled}
                        {...inputProps}
                      />
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          handleClick(e);
                          setOpenDatePicker(true);
                        }}
                        disabled={disabledP !== undefined ? disabledP : false}
                      >
                        <CalendarTodayOutlined fontSize="small" />
                      </IconButton>
                    </div>
                  )}
                />
              </LocalizationProvider>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default DatePickerInput;
