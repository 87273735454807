import React, { useEffect, useState } from "react";
import {
	Avatar,
	Box,
	Button,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";

import { images } from "assets/images";
import { ClientSettingsLabels, GlobalLabels } from "common/AppConstants";
import { GET_USER_DETAILS, UPDATE_USER_DETAILS } from "graphql/users";
import { StorageConstants } from "utils/storage";
import { useStore } from "utils/store";
import { IPhoneNumber } from "models/common";
import PhoneNumberInput from "common/PhoneNumberInput";
import { CountryCodeArr } from "utils/constants";

import { SettingsStyles as styles } from "./styles";

interface IProfile {
	id: string;
	fullName: string;
	mobile: IPhoneNumber;
	email: string;
	profile_picture: any;
}

const profileInitialValues = {
	id: "",
	fullName: "",
	mobile: {
		countryCode: "61",
		nationalNumber: null,
		rawInput: "",
	},
	email: "",
	profile_picture: null,
};

const Profile = () => {
	const [userDetails, setUserDetails] =
		useState<IProfile>(profileInitialValues);
	const { setLoading } = useStore();

	const { loading, refetch } = useQuery(GET_USER_DETAILS, {
		variables: {
			id: sessionStorage.getItem(StorageConstants.LOGGED_IN_USER_ID),
		},
		onCompleted: (data) => {
			setLoading(false);
			const { user } = data;
			if (user?.profile && Boolean(user?.profile)) {
				const tempJSON = JSON.parse(user?.profile);
				if (Boolean(tempJSON?.about)) {
					setUserDetails({
						...user,
						profile_picture: user?.profilePicture || null,
						mobile: {
							countryCode: user?.mobile?.countryCode || "61",
							nationalNumber: user?.mobile?.nationalNumber || null,
							rawInput: user?.mobile?.rawInput || "",
						},
					});
				} else {
					setUserDetails({
						...user,
						profile_picture: user?.profilePicture || null,
						mobile: {
							countryCode: user?.mobile?.countryCode || "61",
							nationalNumber: user?.mobile?.nationalNumber || null,
							rawInput: user?.mobile?.rawInput || "",
						},
					});
				}
			} else {
				setUserDetails({
					...user,
					profile_picture: user?.profilePicture || null,
					mobile: {
						countryCode: user?.mobile?.countryCode || "61",
						nationalNumber: user?.mobile?.nationalNumber || null,
						rawInput: user?.mobile?.rawInput || "",
					},
				});
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const [fireUpdateUserDetailsApi, { loading: updating }] = useMutation(
		UPDATE_USER_DETAILS,
		{
			onCompleted: (data) => {
				setLoading(false);
				const { updateUser } = data;
				const { status, message } = updateUser;
				if (status) {
					toast.success(message, { delay: 10 });
					refetch();
				} else {
					toast.error(message, { delay: 10 });
				}
			},
		}
	);

	const handleUpdateProfile = (values: IProfile) => {
		const { id, fullName, mobile, profile_picture } = values;
		fireUpdateUserDetailsApi({
			variables: {
				payload: {
					id,
					fullName,
					mobile: `${
						CountryCodeArr.find((x) => x.id === mobile.countryCode)?.name
					} ${mobile.nationalNumber}`,
					...(profile_picture === null && { removeProfilePhoto: true }),
				},
				...(Boolean(profile_picture) &&
					typeof profile_picture !== "string" && {
						file: profile_picture,
					}),
			},
		});
	};

	useEffect(() => {
		setLoading(loading || updating);
	}, [loading, setLoading, updating]);

	return (
		<Formik
			initialValues={userDetails}
			validationSchema={Yup.object().shape({
				fullName: Yup.string().required("Please enter your full name"),
				mobile: Yup.object().shape({
					nationalNumber: Yup.string().required(
						"Please enter your contact number"
					),
				}),
			})}
			onSubmit={handleUpdateProfile}
			enableReinitialize={true}
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleBlur,
				errors,
				touched,
				isValid,
				handleSubmit,
			}) => (
				<Form onSubmit={handleSubmit}>
					<Grid
						container
						spacing={2}
						sx={{ px: { xs: 0, sm: 10, md: 20, lg: 30 } }}
					>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Box sx={[styles.flex_JCcenter_Acenter, styles.my3]}>
								<Upload
									accept=".jpeg, .jpg, .png"
									beforeUpload={(file: RcFile, __) => {
										setFieldValue("profile_picture", file);
										return Promise.resolve();
									}}
								>
									<IconButton>
										<img src={images.CAMERA} alt="camera" />
									</IconButton>
								</Upload>
								{Boolean(values.profile_picture) ? (
									<Avatar
										src={
											typeof values.profile_picture === "string"
												? values.profile_picture
												: URL.createObjectURL(
														values.profile_picture
												  )
										}
										sx={styles.profile_pic_avatar}
									/>
								) : (
									<Avatar sx={styles.profile_pic_avatar}>
										<Person sx={{ fontSize: "60px" }} />
									</Avatar>
								)}
								<IconButton
									onClick={() =>
										setFieldValue("profile_picture", null)
									}
								>
									<img src={images.DELETE} alt="delete" />
								</IconButton>
							</Box>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								{ClientSettingsLabels.PROFILE_FIELDS.FULL_NAME}
							</Typography>
							<TextField
								id="fullName"
								value={values.fullName}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
							{touched?.fullName && errors?.fullName && (
								<Typography sx={styles.error_text}>
									{errors.fullName}
								</Typography>
							)}
						</Grid>
						{/* <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.ROLE}
              </Typography>
              <TextField
                id="designation"
                value={values.designation}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.designation && errors?.designation && (
                <Typography sx={styles.error_text}>
                  {errors.designation}
                </Typography>
              )}
            </Grid> */}
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								{ClientSettingsLabels.PROFILE_FIELDS.EMAIL}
							</Typography>
							<TextField
								id="email"
								value={values.email}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
								disabled
							/>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								{ClientSettingsLabels.PROFILE_FIELDS.MOBILE}
							</Typography>
							<PhoneNumberInput
								value={
									values.mobile.nationalNumber !== null
										? values.mobile.nationalNumber
										: Boolean(values.mobile.rawInput)
										? values.mobile.rawInput.replaceAll(" ", "")
										: ""
								}
								onChange={(e) => {
									setFieldValue(
										"mobile.nationalNumber",
										e.target.value.toString()
									);
								}}
								onBlur={handleBlur("mobile.nationalNumber")}
								countryCode={values.mobile.countryCode}
								setCountryCode={(code: string) =>
									setFieldValue("mobile.countryCode", code)
								}
							/>
							{touched?.mobile?.nationalNumber &&
								errors?.mobile?.nationalNumber && (
									<Typography sx={styles.error_text}>
										{errors.mobile?.nationalNumber}
									</Typography>
								)}
						</Grid>
						{/* <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.COMP_NAME}
              </Typography>
              <TextField
                id="company.name"
                value={values.company?.name}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.name && errors?.company?.name && (
                <Typography sx={styles.error_text}>
                  {errors.company?.name}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.COMP_SITE}
              </Typography>
              <TextField
                id="company.website"
                value={values.company?.website}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.website && errors?.company?.website && (
                <Typography sx={styles.error_text}>
                  {errors.company?.website}
                </Typography>
              )}
            </Grid> */}
						{/* <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.NO_OF_EMP}
              </Typography>
              <TextField
                id="company.noOfEmployees"
                value={values.company?.noOfEmployees}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.noOfEmployees &&
                errors?.company?.noOfEmployees && (
                  <Typography sx={styles.error_text}>
                    {errors.company?.noOfEmployees}
                  </Typography>
                )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.EMPS_MANAGING_CNCT}
              </Typography>
              <TextField
                id="company.employeeManagingContracts"
                value={values.company?.employeeManagingContracts}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.employeeManagingContracts &&
                errors?.company?.employeeManagingContracts && (
                  <Typography sx={styles.error_text}>
                    {errors.company?.employeeManagingContracts}
                  </Typography>
                )}
            </Grid> */}
						{/* <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.INDUSTRY}
              </Typography>
              <TextField
                id="company.industry"
                value={values.company?.industry}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.industry && errors?.company?.industry && (
                <Typography sx={styles.error_text}>
                  {errors.company?.industry}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.ABN_CODE}
              </Typography>
              <TextField
                id="company.abnCode"
                value={values.company?.abnCode}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.abnCode && errors?.company?.abnCode && (
                <Typography sx={styles.error_text}>
                  {errors.company?.abnCode}
                </Typography>
              )}
            </Grid> */}
						{/* <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.ABOUT}
              </Typography>
              <TextField
                id="about"
                value={values.about}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                multiline
                minRows={4}
                inputProps={{ style: styles.input_field }}
              />
              {touched?.about && errors?.about && (
                <Typography sx={styles.error_text}>{errors.about}</Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.LANGUAGE}
              </Typography>
              <TextField
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.WEEK_START_ON}
              </Typography>
              <TextField
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.TIME_FORMAT}
              </Typography>
              <RadioGroup row>
                <RadioButtonWithLabel
                  value="12"
                  label={ClientSettingsLabels.HOURS12}
                  labelStyle={styles.radio_btn_label}
                  size="small"
                />
                <RadioButtonWithLabel
                  value="24"
                  label={ClientSettingsLabels.HOURS24}
                  labelStyle={styles.radio_btn_label}
                  size="small"
                />
              </RadioGroup>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.DATE_FORMAT}
              </Typography>
              <RadioGroup row>
                <RadioButtonWithLabel
                  value="ddmmyy"
                  label={ClientSettingsLabels.DDMMYYY}
                  labelStyle={styles.radio_btn_label}
                  size="small"
                />
                <RadioButtonWithLabel
                  value="mmddyy"
                  label={ClientSettingsLabels.MMDDYY}
                  labelStyle={styles.radio_btn_label}
                  size="small"
                />
                <RadioButtonWithLabel
                  value="yymmdd"
                  label={ClientSettingsLabels.YYMMDD}
                  labelStyle={styles.radio_btn_label}
                  size="small"
                />
              </RadioGroup>
            </Grid> */}
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							display="flex"
							justifyContent="flex-end"
						>
							<Button
								variant="contained"
								type="submit"
								disabled={updating || !isValid}
							>
								{GlobalLabels.SAVE_CHANGES}
							</Button>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default Profile;
