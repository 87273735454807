import { create } from "zustand";
import { IFillableFieldsT, ITagsT } from "pages/AdminScreens/TemplateDocEditor";

interface ITemplateStore {
  fillableFields: IFillableFieldsT[];
  setFillableFields: (arr: IFillableFieldsT[]) => void;

  tags: ITagsT[];
  setTags: (arr: ITagsT[]) => void;
}

export const useTemplates = create<ITemplateStore>((set) => ({
  fillableFields: [],
  setFillableFields: (fillableFields: IFillableFieldsT[]) =>
    set({ fillableFields }),

  tags: [],
  setTags: (tags: ITagsT[]) => set({ tags }),
}));
