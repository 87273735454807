import React, { useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";

import { clearSession, getJwtTokenDetails } from "utils/storage";
import { NotAuthorizedPage } from "pages";
import { getPermissionsToken } from "permissions/utils";

interface IAuthenticatedRouteProps {
  children: React.ReactNode;
  permissionKeys: string[];
}

function AuthenticatedRoute(props: IAuthenticatedRouteProps): any {
  const { children, permissionKeys } = props;
  const { hasExpired, roles, permissions } = getJwtTokenDetails();

  const [authorized, setAuthorized] = useState("");

  const ROLE_PERMISSIONS = useMemo(() => {
    return getPermissionsToken(permissions);
  }, [permissions]);

  const checkIfOnePermissionIsTrue = (
    permissions: any,
    permissionKeys: string[]
  ) => {
    let flag = 0;
    for (let i = 0; i < permissionKeys.length; i++) {
      const index = permissions[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty(permissionKeys[i])
      );
      if (permissions[0]?.permissions[index][permissionKeys[i]]) {
        flag = 1;
        break;
      }
    }
    return flag === 1;
  };

  useEffect(() => {
    if (hasExpired) {
      clearSession();
    }
  }, [hasExpired]);

  useEffect(() => {
    if (permissionKeys.length === 0) {
      setAuthorized("YES");
    } else if (
      roles.includes("super_admin") ||
      roles.includes("guest_user") ||
      (Boolean(permissionKeys) &&
        !checkIfOnePermissionIsTrue(ROLE_PERMISSIONS, permissionKeys!))
    ) {
      setAuthorized("NO");
    } else {
      setAuthorized("YES");
    }
  }, [ROLE_PERMISSIONS, children, permissionKeys, roles]);

  if (hasExpired) {
    return <Navigate to="/" />;
  }

  if (Boolean(authorized) && authorized === "YES") {
    return children;
  } else if (Boolean(authorized) && authorized === "NO") {
    return <NotAuthorizedPage />;
  }

  return null;
}

export default AuthenticatedRoute;
