import React, { Dispatch, SetStateAction, useMemo } from "react";
import {
  Avatar,
  Box,
  Grid,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Typography,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { colors } from "theme/colors";
import { IPaginationModel } from "models/common";

const styles = {
  footer_container: { px: 3, pt: 4, pb: 2 },
  page: {
    "& .MuiPaginationItem-page": {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
    },
    "& .Mui-selected": { color: colors.secondary },
  },
  page_count_text: {
    fontSize: "16px",
    color: colors.secondaryBlack,
    fontWeight: 500,
  },
  prev_next_icon: {
    border: `1px solid ${colors.primary}`,
    backgroundColor: "transparent",
    height: 33,
    width: 33,
  },
  page_size_container: { display: "flex", alignItems: "center" },
};

const CustomPreviousIcon = () => {
  return (
    <Avatar variant="rounded" sx={styles.prev_next_icon}>
      <ChevronLeft fontSize="small" color="primary" />
    </Avatar>
  );
};

const CustomNextIcon = () => {
  return (
    <Avatar variant="rounded" sx={styles.prev_next_icon}>
      <ChevronRight fontSize="small" color="primary" />
    </Avatar>
  );
};

interface IProps {
  rowCount: number;
  page: number;
  pageSize: number;
  onPaginationModelChange: Dispatch<SetStateAction<IPaginationModel>>;
}

const CustomPagination = (props: IProps) => {
  const { rowCount, page, pageSize, onPaginationModelChange } = props;
  const noOfPages = useMemo(
    () => Math.ceil(rowCount / pageSize),
    [pageSize, rowCount]
  );

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={styles.footer_container}
    >
      <Grid item lg={3} md={3} sm={4} xs={4}>
        <Box display="flex" alignItems="center">
          <Typography sx={styles.page_count_text}>Entries per page</Typography>
          <Select
            size="small"
            value={props.pageSize}
            sx={{ mx: 0.75 }}
            onChange={(e) =>
              onPaginationModelChange({
                page: props.page,
                pageSize: e.target.value as number,
              })
            }
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <Typography sx={styles.page_count_text}>{`${page * pageSize + 1}-${
            rowCount < (page + 1) * pageSize ? rowCount : (page + 1) * pageSize
          } of ${rowCount} entries`}</Typography>
        </Box>
      </Grid>
      <Grid
        item
        lg={9}
        md={9}
        sm={8}
        xs={8}
        display="flex"
        justifyContent="flex-end"
      >
        <Pagination
          shape="rounded"
          color="primary"
          count={noOfPages}
          page={page + 1}
          onChange={(_, value) =>
            onPaginationModelChange({
              page: value - 1,
              pageSize: props.pageSize,
            })
          }
          sx={styles.page}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: CustomPreviousIcon, next: CustomNextIcon }}
              {...item}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default CustomPagination;
