import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { useStore } from "utils/store";
import { GET_CLIENT_ACTIVITY_LOGS } from "graphql/users";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import { DashboardStyles as styles } from "./styles";
import {
  changeReasonMapping,
  entityTypeMapping,
  sentenceMapping,
} from "./utils";

interface IActivity {
  historyType: string;
  historyChangeReason: string;
  historyDate: string;
  changedBy: string;
  changedByProfile: string;
  entityName: string;
  entityType: string;
  additionalHistoryDetails: string[];
}

interface IActivityCard {
  activityData: IActivity;
}

const ActivityCard = (props: IActivityCard) => {
  const { activityData } = props;
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          width: { xs: "100%", sm: 480 },
          p: 2,
          backgroundColor: `${colors.whiteGrey500}10`,
          borderRadius: "15px",
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ flexWrap: "nowrap" }}
        >
          <Grid item>
            {Boolean(activityData.changedByProfile) ? (
              <Avatar
                src={activityData.changedByProfile}
                sx={{ width: "45px", height: "45px" }}
              />
            ) : (
              <Avatar sx={{ width: "45px", height: "45px" }} />
            )}
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Box sx={[styles.flex, { mb: 0.5, alignItems: "flex-end" }]}>
              <Typography
                noWrap
                sx={[
                  styles.proj_meta_data_text,
                  {
                    color: colors.primaryBlack,
                    width: "65%",
                  },
                ]}
              >
                {`${activityData?.entityType} ${
                  activityData?.historyChangeReason === "Create" ||
                  activityData?.historyChangeReason === "Update" ||
                  activityData?.historyChangeReason === "Execute" ||
                  activityData?.historyChangeReason === "Emailed"
                    ? (changeReasonMapping as any)[
                        activityData?.historyChangeReason
                      ]
                    : activityData?.historyChangeReason
                }`}
              </Typography>
              <Typography sx={styles.proj_meta_data_text}>
                {moment(activityData?.historyDate).fromNow()}
              </Typography>
            </Box>
            <Typography
              sx={[
                styles.proj_meta_data_text,
                { color: colors.primaryBlack, fontSize: "14px" },
              ]}
            >
              <span style={{ fontWeight: 600 }}>{activityData?.changedBy}</span>{" "}
              {activityData?.historyChangeReason === "Create" ||
              activityData?.historyChangeReason === "Update" ||
              activityData?.historyChangeReason === "Execute" ||
              activityData?.historyChangeReason === "Emailed"
                ? (sentenceMapping as any)[activityData?.historyChangeReason]
                : activityData?.historyChangeReason}{" "}
              {(entityTypeMapping as any)[activityData?.entityType]}{" "}
              {Boolean(activityData?.additionalHistoryDetails) &&
              activityData.additionalHistoryDetails.length > 0
                ? `to ${activityData?.additionalHistoryDetails.join(", ")}`
                : ""}{" "}
              - {activityData?.entityName}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

function RecentActivities() {
  const { setLoading } = useStore();

  const [activities, setActivities] = useState<IActivity[]>([]);
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const dashboardIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("DASHBOARD")
      ),
    [ROLE_PERMISSIONS]
  );

  const { loading } = useQuery(GET_CLIENT_ACTIVITY_LOGS, {
    variables: { limit: 8, page: 1 },
    onCompleted: (data) => {
      setLoading(false);
      const { getClientActivityLogs } = data;
      const { status, logs } = getClientActivityLogs;
      if (status) {
        setActivities(
          logs.map((x: any) => ({
            ...x,
            additionalHistoryDetails: Boolean(x.additionalHistoryDetails)
              ? Boolean(JSON.parse(x.additionalHistoryDetails))
                ? JSON.parse(x.additionalHistoryDetails)
                : []
              : [],
          }))
        );
      }
    },
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      dashboardIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[dashboardIndex]?.DASHBOARD
    ),
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={[
              {
                display: "flex",
                alignItems: "center",
                width: { xs: "100%", sm: 480 },
              },
            ]}
          >
            <Box sx={{ flexGrow: 1, mr: { xs: 4, sm: 8 } }}>
              <Divider />
            </Box>
            <Typography
              sx={[
                styles.project_name_text,
                { color: `${colors.primaryBlack}50` },
              ]}
            >
              Activity
            </Typography>
            <Box sx={{ flexGrow: 1, ml: { xs: 4, sm: 8 } }}>
              <Divider />
            </Box>
          </Box>
        </Box>
      </Grid>
      {activities.map((act) => (
        <Grid key={act.historyDate} item lg={12} md={12} sm={12} xs={12}>
          <ActivityCard activityData={act} />
        </Grid>
      ))}
    </Grid>
  );
}

export default RecentActivities;
