import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from "@mui/material";
import {
  CheckOutlined,
  FiberManualRecord,
  KeyboardArrowDown,
  ModeEditOutlineOutlined,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import {
  CurrencyArr,
  DateFormatArr,
  ProjectStatusMapping,
} from "utils/constants";
import { IEditProjectFields } from "models/projects";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import DeleteProjectModal from "./DeleteProjectModal";
import { ProjectDetailsStyles as styles } from "../styles";

interface ISettingsFormProps {
  initialValues: IEditProjectFields;
  projectStatus: number;
  setProjectStatus: Dispatch<SetStateAction<number>>;
  fireUpdateProjectApi: any;
  handleUpdateProj: any;
  refetch: any;
}

function SettingsForm(props: ISettingsFormProps) {
  const {
    projectStatus,
    initialValues,
    setProjectStatus,
    fireUpdateProjectApi,
    handleUpdateProj,
    refetch,
  } = props;

  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("PROJECT_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [editProjectDetails, setEditProjectDetails] = useState(false);
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);
  const [openProjStatusMenu, setOpenProjStatusMenu] = useState(false);
  const [projStatusMenuAnchor, setProjStatusMenuAnchor] =
    useState<HTMLElement | null>();

  const handleProjStatusClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpenProjStatusMenu(true);
    setProjStatusMenuAnchor(e.currentTarget);
  };

  const handleProjStatusClose = () => {
    setOpenProjStatusMenu(false);
    setProjStatusMenuAnchor(null);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: IEditProjectFields) =>
        handleUpdateProj(values, setEditProjectDetails)
      }
      validationSchema={Yup.object().shape({})}
      enableReinitialize
    >
      {({ values, setFieldValue, handleBlur, handleSubmit }) => (
        <Box>
          <Box
            sx={{
              position: "absolute",
              top: 181,
              right:
                Boolean(ROLE_PERMISSIONS) &&
                projectEditIndex !== undefined &&
                ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]?.PROJECT_EDIT
                  ? 20
                  : 45,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                sx={{
                  mr: 1.5,
                  backgroundColor: (ProjectStatusMapping as any)[projectStatus]
                    .bg,
                  color: (ProjectStatusMapping as any)[projectStatus].color,
                  borderRadius: "8px",
                  py: 1,
                  px: 2,
                  ":hover": {
                    backgroundColor: (ProjectStatusMapping as any)[
                      projectStatus
                    ].bg,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: (ProjectStatusMapping as any)[
                      projectStatus
                    ]?.bg,
                    color: (ProjectStatusMapping as any)[projectStatus]?.color,
                  },
                  fontSize: "14px",
                }}
                disableElevation
                disableRipple
                disabled={
                  !(
                    Boolean(ROLE_PERMISSIONS) &&
                    projectEditIndex !== undefined &&
                    ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                      ?.PROJECT_EDIT
                  )
                }
                onClick={handleProjStatusClick}
                endIcon={
                  Boolean(ROLE_PERMISSIONS) &&
                  projectEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                    ?.PROJECT_EDIT ? (
                    <KeyboardArrowDown
                      fontSize="small"
                      sx={{
                        color: (ProjectStatusMapping as any)[projectStatus]
                          .color,
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
              >
                {(ProjectStatusMapping as any)[projectStatus].name}
              </Button>
              <Menu
                anchorEl={projStatusMenuAnchor}
                open={openProjStatusMenu}
                onClose={handleProjStatusClose}
                sx={styles.menu_container}
                elevation={0}
              >
                <MenuList>
                  {Object.keys(ProjectStatusMapping).map((item) => (
                    <MenuItem
                      key={item}
                      onClick={() => {
                        setProjectStatus(parseInt(item));
                        fireUpdateProjectApi({
                          variables: {
                            payload: {
                              id: values.id,
                              projectStatus: parseInt(item),
                            },
                          },
                          onCompleted: (data: any) => {
                            setLoading(false);
                            const { updateProject } = data;
                            const { status, message } = updateProject;
                            if (status) {
                              toast.success(message);
                              refetch();
                            } else {
                              toast.error(message);
                            }
                          },
                        });
                        handleProjStatusClose();
                      }}
                    >
                      <ListItemIcon>
                        <FiberManualRecord
                          fontSize="small"
                          sx={{
                            color: Boolean(projectStatus)
                              ? (ProjectStatusMapping as any)[item].color
                              : "",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: `${colors.primaryBlack}80`,
                          }}
                        >
                          {(ProjectStatusMapping as any)[item].name}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              {Boolean(ROLE_PERMISSIONS) &&
              projectEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                ?.PROJECT_EDIT ? (
                editProjectDetails ? (
                  <IconButton size="small" onClick={() => handleSubmit()}>
                    <CheckOutlined
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    onClick={() => setEditProjectDetails(true)}
                  >
                    <ModeEditOutlineOutlined
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  </IconButton>
                )
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Currency</Typography>
              <Autocomplete
                id="currency"
                value={
                  CurrencyArr.find((x) => x.id === values.currency) || {
                    id: "",
                    name: "",
                  }
                }
                disabled={!editProjectDetails}
                onChange={(_, newValue) => {
                  if (Boolean(newValue)) {
                    setFieldValue(`currency`, newValue!.id);
                  } else {
                    setFieldValue("currency", "");
                  }
                }}
                onBlur={handleBlur}
                disableClearable
                size="small"
                options={CurrencyArr}
                getOptionLabel={(option) => option.name}
                sx={styles.input_field}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    onBlur={handleBlur}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        ...styles.input_field,
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Date Format</Typography>
              <Autocomplete
                id="dateFormat"
                value={
                  DateFormatArr.find((x) => x.id === values.dateFormat) || {
                    id: "",
                    name: "",
                  }
                }
                disabled={!editProjectDetails}
                onChange={(_, newValue) => {
                  setFieldValue(`dateFormat`, newValue.id);
                }}
                onBlur={handleBlur}
                disableClearable
                size="small"
                options={DateFormatArr}
                getOptionLabel={(option) => option.name}
                sx={styles.input_field}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    onBlur={handleBlur}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        ...styles.input_field,
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ mt: 3 }}>
                    <NewRedAlertContainedButton
                      variant="contained"
                      fullWidth
                      sx={{ fontWeight: 600, py: 1 }}
                      disableElevation
                      disableRipple
                      onClick={() => {
                        setDeleteProjectModal(true);
                      }}
                    >
                      Delete Project
                    </NewRedAlertContainedButton>
                  </Box>
                </Grid> */}
          </Grid>
          {deleteProjectModal && (
            <DeleteProjectModal
              open={deleteProjectModal}
              setOpen={setDeleteProjectModal}
              projectId={values.id}
              refetch={refetch}
            />
          )}
        </Box>
      )}
    </Formik>
  );
}

export default SettingsForm;
