import React, { Fragment, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import moment from "moment";

import { IIssueHistory } from "models/issues";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface IIssueHistoryItemProps {
  item: IIssueHistory;
}

function IssueHistoryItem(props: IIssueHistoryItemProps) {
  const { item } = props;

  const [openIssueDocMenu, setOpenIssueDocMenu] = useState(false);
  const [issueDocMenuAnchor, setIssueDocMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleIssueDocMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIssueDocMenuAnchor(e.currentTarget);
    setOpenIssueDocMenu(true);
  };

  const handleIssueDocMenuClose = () => {
    setIssueDocMenuAnchor(null);
    setOpenIssueDocMenu(false);
  };

  return (
    <Fragment>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Box sx={styles.flex_Acenter}>
          <Typography
            sx={[
              styles.field_label,
              { fontWeight: 600, color: colors.primaryBlack },
            ]}
          >
            {item?.createdBy?.fullName || ""}
          </Typography>
          {Boolean(item?.documents) && item?.documents?.length > 0 && (
            <>
              <IconButton
                sx={{ ml: 0.5 }}
                aria-controls={openIssueDocMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openIssueDocMenu ? "true" : undefined}
                onClick={handleIssueDocMenuOpen}
                onMouseEnter={handleIssueDocMenuOpen}
              >
                <img src={images.FILE_LINK_BTN_ICON} alt="attachment" />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={issueDocMenuAnchor}
                open={openIssueDocMenu}
                onClose={handleIssueDocMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={styles.menu_container}
                elevation={0}
                onMouseLeave={handleIssueDocMenuClose}
              >
                {Boolean(item?.documents) &&
                  item?.documents.map((doc) => (
                    <MenuItem
                      key={doc.id}
                      onClick={() => {
                        window.open(doc.document);
                      }}
                      sx={[styles.field_label, { color: colors.primary }]}
                    >
                      {doc?.documentName || "File Name Not Available"}
                    </MenuItem>
                  ))}
              </Menu>
            </>
          )}
        </Box>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        sx={[
          styles.flex_JCfe,
          {
            justifyContent: { xs: "flex-start", sm: "flex-end" },
            mt: { xs: -1, sm: 0 },
          },
        ]}
      >
        <Typography sx={[styles.field_label, { fontWeight: 500 }]}>
          {item?.createdDate
            ? moment(item?.createdDate).format("DD MMMM YYYY, HH:mm a")
            : ""}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography
          sx={[
            styles.field_label,
            {
              width: "100%",
              wordBreak: "break-word",
              color: colors.primaryBlack,
              fontWeight: 500,
            },
          ]}
        >
          {item.description}
        </Typography>
      </Grid>
    </Fragment>
  );
}

export default IssueHistoryItem;
