import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Editor as TinyMCEEditor } from "tinymce";
import { Editor } from "@tinymce/tinymce-react";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { useTemplates } from "hooks/useTemplates";
import { ITemplate } from "models/templates";
import { GET_TEMPLATE_DETAILS } from "graphql/templates";
import { useStore } from "utils/store";

import FillableFieldModal from "./FillableFieldModal";
import TagModal from "./TagModal";
import EditorFrame from "./EditorFrame";

export interface IFillableFieldsT {
  id: string;
  fieldName: string;
  fieldDescription: string;
  fieldType: string;
  fieldValue: string;
  fieldResolution: string;
  fieldMultiple: boolean;
  resolveTo: string;
}

export interface ITagsT {
  id: string;
  tagName: string;
  tagDescription: string;
  preview: string;
}

export function TemplateDocEditor() {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const { fillableFields, setFillableFields, tags, setTags } = useTemplates();
  const templateId = useParams();
  const { setLoading } = useStore();

  const [fillableFieldModal, setFillableFieldModal] = useState(false);
  const [tagsModal, setTagsModal] = useState(false);
  const [templateData, setTemplateData] = useState<ITemplate | null>(null);
  const [templateDocContent, setTemplateDocContent] = useState("<p></p>");
  const [initialTemplateDocContent, setInitialTemplateDocContent] =
    useState("");
  const [editFillableFieldData, setEditFillableFieldData] =
    useState<IFillableFieldsT | null>(null);
  const [editTagData, setEditTagData] = useState<ITagsT | null>(null);
  const [conversionLoading, setConversionLoading] = useState(false);
  const [feeCalculatorAdded, setFeeCalculatorAdded] = useState(false);

  const { loading, refetch } = useQuery(GET_TEMPLATE_DETAILS, {
    variables: { id: templateId?.template_id },
    skip: !templateId?.template_id,
    onCompleted: (data) => {
      setLoading(false);
      const { getContractTemplate } = data;
      const { status, contractTemplate } = getContractTemplate;
      if (status) {
        setTemplateData(contractTemplate);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const checkFillableFields = (value: any, editor: TinyMCEEditor) => {
    setTemplateDocContent(value);
    let tempFields = [...fillableFields];
    for (let i = 0; i < fillableFields.length; i += 1) {
      if (editor.dom.get(fillableFields[i].id) === null) {
        tempFields = tempFields.filter((x) => x.id !== fillableFields[i].id);
      }
    }
    setFillableFields([...tempFields]);

    let tempTags = [...tags];
    for (let i = 0; i < tags.length; i += 1) {
      if (editor.dom.get(tags[i].id) === null) {
        tempTags = tempTags.filter((x) => x.id !== tags[i].id);
      }
    }
    setTags([...tempTags]);
  };

  useEffect(() => {
    if (Boolean(templateData) && Boolean(templateData?.file)) {
      setConversionLoading(true);
      fetch(templateData?.file!)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text(); // Convert response to text
        })
        .then((htmlString) => {
          // Use the HTML string here
          setTemplateDocContent(htmlString);
          setInitialTemplateDocContent(htmlString);

          let parser = new DOMParser();
          const doc = parser.parseFromString(htmlString, "text/html");
          if (Boolean(doc.getElementById("fillable-fields"))) {
            const fillableFieldsJSONStr =
              doc.getElementById("fillable-fields")?.innerText;
            setFillableFields(JSON.parse(fillableFieldsJSONStr!));
          }
          if (Boolean(doc.getElementById("finka-tags"))) {
            const fillableFieldsJSONStr =
              doc.getElementById("finka-tags")?.innerText;
            setTags(JSON.parse(fillableFieldsJSONStr!));
          }
          if (Boolean(doc.getElementById("fee-calculator"))) {
            const feeCalculatorAddedJSON =
              doc.getElementById("fee-calculator")?.innerText;
            setFeeCalculatorAdded(JSON.parse(feeCalculatorAddedJSON!));
          }
        })
        .catch((error) => {
          console.error("There was a problem fetching the HTML file:", error);
          toast.error("File not loaded", { delay: 10 });
        });
      setConversionLoading(false);
    }
  }, [setFillableFields, setTags, templateData]);

  useEffect(() => {
    setLoading(conversionLoading || loading);
  }, [conversionLoading, loading, setLoading]);

  useEffect(() => {
    setFillableFields([]);
    setTags([]);
  }, [setFillableFields, setTags]);

  return (
    <EditorFrame
      ref={editorRef}
      templateData={templateData}
      setFillableFieldModal={setFillableFieldModal}
      setEditFillableFieldData={setEditFillableFieldData}
      setTagsModal={setTagsModal}
      setEditTagData={setEditTagData}
      refetch={refetch}
      initialTemplateDocContent={initialTemplateDocContent}
      feeCalculatorAdded={feeCalculatorAdded}
      setFeeCalculatorAdded={setFeeCalculatorAdded}
    >
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onInit={(evt, editor) => {
          editorRef.current = editor;
          editor.ui.registry.addButton("customFeeCalculatorButton", {
            icon: "calculator-money-icon",
            tooltip: "Add Fee Calculator",
            onAction: (_) => {
              setFeeCalculatorAdded(true);
            },
          });
          editor.ui.registry.addButton("customInsertFieldButton", {
            icon: "addTag",
            tooltip: "Add field",
            onAction: (_) => {
              setFillableFieldModal(true);
            },
          });
          editor.ui.registry.addButton("customTagButton", {
            icon: "bookmark",
            tooltip: "Add tag",
            onAction: (_) => {
              setTagsModal(true);
            },
          });
        }}
        onEditorChange={checkFillableFields}
        apiKey="gjhka7xjgv6i3e4hhrfl0xxyfm6xlptxnbkq1h4vh8ahqqw3"
        value={templateDocContent}
        init={{
          icons: "custom-icons",
          promotion: false,
          plugins:
            "preview importcss searchreplace autolink save directionality code visualblocks visualchars link image codesample table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount help charmap emoticons accordion",
          // editimage_cors_hosts: ["picsum.photos"],
          menubar: "file edit view insert format tools table help",
          toolbar:
            "customFeeCalculatorButton customInsertFieldButton customTagButton | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | lineheight outdent indent | forecolor backcolor | preview",
          // autosave_ask_before_unload: true,
          // autosave_interval: "30s",
          // autosave_prefix: "{path}{query}-{id}-",
          // autosave_restore_when_empty: false,
          // autosave_retention: "2m",
          // image_advtab: false,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          // image_list: [
          //   { title: "My page 1", value: "https://www.tiny.cloud" },
          //   { title: "My page 2", value: "http://www.moxiecode.com" },
          // ],
          // image_class_list: [
          //   { title: "None", value: "" },
          //   { title: "Some class", value: "class-name" },
          // ],
          insertdatetime_formats: [
            "%d %b %Y",
            "%d/%m/%Y",
            "%H:%M:%S",
            "%I:%M:%S %p",
          ],
          importcss_append: true,
          // file_picker_callback: (callback, value, meta) => {
          //   if (meta.filetype === "file") {
          //     callback("https://www.google.com/logos/google.jpg", {
          //       text: "My text",
          //     });
          //   }

          //   if (meta.filetype === "image") {
          //     callback("https://www.google.com/logos/google.jpg", {
          //       alt: "My alt text",
          //     });
          //   }

          //   if (meta.filetype === "media") {
          //     callback("movie.mp4", {
          //       source2: "alt.ogg",
          //       poster: "https://www.google.com/logos/google.jpg",
          //     });
          //   }
          // },
          // templates: [
          //   {
          //     title: "Input Text Field",
          //     description: "Creates a new input field",
          //     content:
          //       '<div class="mceTmpl finka finka-textfield"><input id="finka-textfield" type="text" style="font-family:Helvetica,Arial,sans-serif; font-size:14px; padding:8px;" /></div>',
          //   },
          //   {
          //     title: "Input Date Field",
          //     description: "Creates a new date field",
          //     content:
          //       '<div class="mceTmpl finka finka-datefield"><input id="finka-datefield" type="date" style="font-family:Helvetica,Arial,sans-serif; font-size:14px; padding:8px;" /></div>',
          //   },
          //   {
          //     title: "Signature Field",
          //     description: "Creates a signature field",
          //     content:
          //       '<div class="mceTmpl finka finka-signfield"><input type="file" accept="image/*" /></div>',
          //   },
          // ],
          // image_caption: true,
          noneditable_class: "mceNonEditable",
          editable_class: "mceEditable",
          toolbar_mode: "sliding",
          skin: "oxide",
          content_css: "default",
          content_style: "body { font-family:arial; font-size:10pt }",
          branding: false,
          elementpath: false,
          resize: false,
          help_accessibility: false,
          height: "calc(100vh - 65px)",
          width: "100%",
          // newline_behavior: "linebreak",
          // setup: function (editor) {
          //   editor.on("keydown", function (event) {
          //     if (
          //       event.keyCode === 32 &&
          //       !event.ctrlKey &&
          //       !event.altKey &&
          //       !event.shiftKey &&
          //       !event.metaKey
          //     ) {
          //       var selectedContent = editor.selection.getNode();
          //       console.log({ selectedContent });
          //       var closingTagIndex = selectedContent.innerHTML.indexOf("</");
          //       console.log(closingTagIndex);
          //       if (closingTagIndex !== -1) {
          //         var closingTag =
          //           selectedContent.innerHTML.substring(closingTagIndex);
          //         var newContent =
          //           closingTag + "<span id='cursor_position'></span>";
          //         editor.execCommand("mceInsertContent", false, newContent);
          //         editor.selection.select(
          //           editor.dom.select("#cursor_position")[0]
          //         );
          //         editor.selection.setContent(newContent);
          //         editor.dom.remove(editor.dom.select("#cursor_position"));
          //       }
          //     }
          //   });
          // },
        }}
      />
      {fillableFieldModal && (
        <FillableFieldModal
          open={fillableFieldModal}
          setOpen={setFillableFieldModal}
          editFillableFieldData={editFillableFieldData}
          setEditFillableFieldData={setEditFillableFieldData}
          ref={editorRef}
        />
      )}
      {tagsModal && (
        <TagModal
          open={tagsModal}
          setOpen={setTagsModal}
          ref={editorRef}
          editTagData={editTagData}
          setEditTagData={setEditTagData}
        />
      )}
    </EditorFrame>
  );
}
