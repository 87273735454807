import { IGeoCoding, IPhoneNumber } from "models/common";

export interface IAdminFields {
	id: string;
	fullName: string;
	designation: string;
	email: string;
	mobile: IPhoneNumber;
	companyName: string;
	abnCode: string;
	website: string;
	noOfEmployees: string;
	employeeManagingContracts: string;
	industry: string;
	street: string;
	city: string;
	state: string;
	country: string;
	pincode: string;
	suburb: string;
	unit: string;
	address: string | null | IGeoCoding;
}

export const initialValues: IAdminFields = {
	id: "",
	unit: "",
	address: null,
	fullName: "",
	designation: "",
	email: "",
	mobile: {
		countryCode: "61",
		nationalNumber: null,
		rawInput: "",
	},
	companyName: "",
	abnCode: "",
	website: "",
	noOfEmployees: "",
	employeeManagingContracts: "",
	industry: "",
	street: "",
	city: "",
	state: "",
	country: "",
	pincode: "",
	suburb: "",
};
