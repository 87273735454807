import React, { useState } from "react";
import { Box, Grid, Tab, Tabs, useMediaQuery } from "@mui/material";

import Drawer from "common/AdminDrawer";
import { ClientSettingsLabels } from "common/AppConstants";
import theme from "theme/muiTheming";

import Profile from "./Profile";
import ActivityLogs from "./ActivityLogs";
import { SettingsStyles as styles } from "./styles";

export const AdminSettings = () => {
  const matchesXs = useMediaQuery(theme.breakpoints.up("sm"));
  const [tabValue, setTabValue] = useState(0);

  return (
    <Drawer>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ px: { xs: 0, sm: 2, md: 4, lg: 6 } }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={(_, value) => setTabValue(value)}
                variant={matchesXs ? "standard" : "scrollable"}
              >
                <Tab
                  label={ClientSettingsLabels.TABS.PROFILE}
                  sx={styles.tab_text}
                />
                {/* <Tab
                label={ClientSettingsLabels.TABS.NOTIFICATIONS}
                sx={styles.tab_text}
              /> */}
                <Tab
                  label={ClientSettingsLabels.TABS.ACTIVITY_LOGS}
                  sx={styles.tab_text}
                />
              </Tabs>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ px: { xs: 0, sm: 2, md: 4, lg: 6 } }}>
            {tabValue === 0 && <Profile />}
            {/* {tabValue === 1 && <Notifications />} */}
            {tabValue === 1 && <ActivityLogs />}
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
};
