import { gql } from "@apollo/client";

export const GET_TEMPLATE_TYPES = gql`
  query templateTypes {
    templateTypes {
      status
      message
      templateTypes {
        id
        name
      }
    }
  }
`;

export const GET_ALL_TEMPLATES = gql`
  query getAllContractTemplates(
    $filter: ContractTemplateFilterInput!
    $page: Int
    $limit: Int
    $sorted: String
  ) {
    getAllContractTemplates(
      filter: $filter
      page: $page
      limit: $limit
      sorted: $sorted
    ) {
      status
      message
      count
      contractTemplates {
        id
        templateId
        name
        additionalDetails
        file
        templateType {
          id
          name
        }
        description
        templateStatus
        createdDate
        modifiedDate
      }
    }
  }
`;

export const GET_TEMPLATE_DETAILS = gql`
  query getContractTemplate($id: String!) {
    getContractTemplate(id: $id) {
      status
      message
      contractTemplate {
        id
        templateId
        name
        additionalDetails
        templateType {
          id
          name
        }
        file
        description
        templateStatus
      }
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation createContractTemplate(
    $payload: ContractTemplateCreateInput!
    $file: Upload!
  ) {
    createContractTemplate(payload: $payload, file: $file) {
      status
      message
      id
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation updateContractTemplate(
    $payload: ContractTemplateUpdateInput!
    $file: Upload
  ) {
    updateContractTemplate(payload: $payload, file: $file) {
      status
      message
    }
  }
`;

export const DOWNLOAD_TEMPLATE_AS_PDF = gql`
  query getContractTemplateAsHTML($id: String!) {
    getContractTemplateAsHTML(id: $id) {
      status
      message
      contractTemplate
    }
  }
`;

export const GET_TEMPLATE_ARR = gql`
  query getAllContractTemplates($filter: ContractTemplateFilterInput!) {
    getAllContractTemplates(filter: $filter) {
      status
      contractTemplates {
        id
        name
        additionalDetails
        templateType {
          id
          name
        }
      }
    }
  }
`;

export const GET_TEMPLATE_FILE = gql`
  query getContractTemplate($id: String!) {
    getContractTemplate(id: $id) {
      status
      contractTemplate {
        id
        file
      }
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation deleteContractTemplate($id: String!) {
    deleteContractTemplate(id: $id) {
      status
      message
    }
  }
`;

export const GET_TEMPLATE_EXPORT = gql`
  query getContractTemplatesExport {
    getContractTemplatesExport {
      report
    }
  }
`;
