import { create } from "zustand";

export interface IStore {
  loading: boolean;
  setLoading: (loading: boolean) => void;

  selectedDrawerItem: number;
  setSelectedDrawerItem: (selectedDrawerItem: number) => void;
}

export const useStore = create<IStore, []>((set) => ({
  loading: false,
  setLoading: (loading: boolean): void => {
    set({ loading });
  },

  selectedDrawerItem: 0,
  setSelectedDrawerItem: (selectedDrawerItem: number) => {
    set({ selectedDrawerItem });
  },
}));
