import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  FilterAltOutlined,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Share,
} from "@mui/icons-material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";

import Table from "common/Table";
import { EXPORT_CONTRACT_REPORT, GET_CONTRACT_REPORT } from "graphql/reports";
import { ContractStatusArr, ContractStatusMapping } from "utils/constants";
import { useStore } from "utils/store";
import { BlackActionContainedButton } from "common/Buttons";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface IContractReportRow {
  id: string;
  name: string;
  isFolder: boolean;
  additionalDetails: string;
  isUpload: boolean;
  documentStatus: string;
}

function ContractStatusReport() {
  const { setLoading } = useStore();
  const projectId = useParams();
  const boxRef = useRef(null);

  const [contracts, setContracts] = useState<IContractReportRow[]>([]);
  const [contractsRowCount, setContractsRowCount] = useState(0);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  const { loading, fetchMore } = useQuery(GET_CONTRACT_REPORT, {
    variables: {
      limit: 50,
      page: 1,
      filter: {
        project_id: projectId?.project_id,
        ...(Boolean(selectedFilterStatus) && {
          documentStatus: selectedFilterStatus,
        }),
      },
      ...(Boolean(sortBy) && { sorted: sortBy }),
    },
    skip: !projectId?.project_id,
    onCompleted: (data) => {
      setLoading(false);
      const { getAllContracts } = data;
      const { status, count, documents } = getAllContracts;
      if (status) {
        setContractsRowCount(count);
        setContracts(
          documents.filter((doc: IContractReportRow) => !doc.isFolder)
        );
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getReport, { loading: exporting }] = useLazyQuery(
    EXPORT_CONTRACT_REPORT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { getAllContractsReport } = data;
        if (Boolean(getAllContractsReport?.report)) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href =
            "data:application/vnd.ms-excel;base64," +
            encodeURI(getAllContractsReport?.report);
          hiddenElement.target = "_blank";
          hiddenElement.download = `Contract Status Overview - ${moment().format(
            "DD MMMM YYYY"
          )}.xlsx`;
          hiddenElement.click();
        } else {
          toast.error("Something went wrong, please try again.", { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Contract Name",
        minWidth: 220,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, styles.wrap]}>
            {params?.value}
          </Typography>
        ),
      },
      // {
      //   field: "project",
      //   headerName: "Project Name",
      //   minWidth: 200,
      //   flex: 1,
      //   sortable: false,
      //   renderCell: (params: GridRenderCellParams<any>) => (
      //     <Typography sx={styles.cell_text}>{params?.value}</Typography>
      //   ),
      // },
      {
        field: "contact",
        headerName: "Party Name",
        minWidth: 220,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {Boolean(params?.value) ? params?.value?.fullName : ""}
          </Typography>
        ),
      },
      {
        field: "documentStatus",
        headerName: "Contract Status",
        headerAlign: "center",
        minWidth: 190,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Chip
            label={(ContractStatusMapping as any)[params.value]?.name}
            sx={[
              styles.chip_variant,
              {
                color: (ContractStatusMapping as any)[params.value]?.color,
                backgroundColor: (ContractStatusMapping as any)[params.value]
                  ?.bg,
              },
            ]}
          />
        ),
      },
      {
        field: "modifiedDate",
        headerName: "Last Modified",
        headerAlign: "center",
        minWidth: 120,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {Boolean(params1?.value)
              ? moment(params1?.value).format("DD MMMM YYYY")
              : ""}
          </Typography>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const box = boxRef.current;
      const isAtBottom =
        (box as any).scrollHeight - (box as any).scrollTop <=
        (box as any).clientHeight + 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (contracts.length < contractsRowCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(contracts.length / 50) + 1,
              limit: 50,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.getAllContracts.documents;
              return {
                getAllContracts: {
                  ...fetchMoreResult?.getAllContracts,
                  documents: [...contracts, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    const box = boxRef.current;
    if (box) {
      (box as any).addEventListener("scroll", handleScroll);
    }

    // Clean up event listener on component unmount
    return () => {
      if (box) {
        (box as any).removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchMore, setLoading, contracts, contractsRowCount]);

  useEffect(() => {
    setLoading(exporting || loading);
  }, [exporting, loading, setLoading]);

  return (
    <>
      <Box
        sx={{
          p: 1,
          display: { xs: "flex", sm: "none" },
          mb: 1,
        }}
      >
        <Box
          sx={[
            styles.flex_Acenter,
            { justifyContent: "flex-end", width: "100%" },
          ]}
        >
          <Box>
            <BlackActionContainedButton
              variant="contained"
              disableElevation
              startIcon={<Share color="secondary" />}
              onClick={() =>
                getReport({
                  variables: {
                    filter: {
                      project_id: projectId?.project_id,
                      ...(Boolean(selectedFilterStatus) && {
                        documentStatus: selectedFilterStatus,
                      }),
                    },
                    ...(Boolean(sortBy) && { sorted: sortBy }),
                  },
                })
              }
              sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
            >
              Export
            </BlackActionContainedButton>
          </Box>
          <Box sx={{ ml: 2 }}>
            <IconButton
              onClick={handleFilterMenuOpen}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <FilterAltOutlined
                fontSize="small"
                sx={{
                  color: openFilterMenu
                    ? "#41B199"
                    : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
            <Menu
              anchorEl={filterMenuAnchor}
              open={openFilterMenu}
              onClose={handleFilterMenuClose}
              elevation={1}
              sx={{ "& .MuiMenu-paper": { width: 300 } }}
            >
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000080",
                      fontWeight: 600,
                      mb: 0.75,
                    }}
                  >
                    Select Status
                  </Typography>
                  <Autocomplete
                    value={
                      Boolean(selectedFilterStatus)
                        ? ContractStatusArr.find(
                            (x) => x.id === selectedFilterStatus
                          )
                        : { id: "", name: "" }
                    }
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setSelectedFilterStatus(newValue.id);
                      } else {
                        setSelectedFilterStatus("");
                      }
                    }}
                    size="small"
                    fullWidth
                    options={ContractStatusArr}
                    getOptionLabel={(option) => option.name}
                    placeholder="Select Status"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            borderRadius: "6px",
                            fontSize: "13px",
                            color: colors.primaryBlack,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000080",
                      fontWeight: 600,
                    }}
                  >
                    Sort By
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <RadioGroup
                    value={sortBy}
                    onChange={(e) => {
                      setSortBy(e.target.value);
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        value="name"
                        size="small"
                        checkedIcon={
                          <RadioButtonChecked
                            fontSize="small"
                            sx={{ color: "#6f6f6f" }}
                          />
                        }
                        icon={
                          <RadioButtonUnchecked
                            fontSize="small"
                            sx={{ color: "#6f6f6f" }}
                          />
                        }
                      />
                      <Typography sx={{ color: "#00000070", fontSize: "14px" }}>
                        Name (A-Z)
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        value="-name"
                        size="small"
                        checkedIcon={
                          <RadioButtonChecked
                            fontSize="small"
                            sx={{ color: "#6f6f6f" }}
                          />
                        }
                        icon={
                          <RadioButtonUnchecked
                            fontSize="small"
                            sx={{ color: "#6f6f6f" }}
                          />
                        }
                      />
                      <Typography sx={{ color: "#00000070", fontSize: "14px" }}>
                        Name (Z-A)
                      </Typography>
                    </Box>
                  </RadioGroup>
                </Grid>
              </Grid>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box
        ref={boxRef}
        sx={{
          height: "calc(100vh - 245px)",
          overflow: "auto",
          mx: -3,
          mb: -3,
        }}
      >
        <Table
          rows={contracts}
          columns={columns}
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          disableColumnMenu
          sx={{
            ...styles.table,
            "& .MuiDataGrid-columnHeaders": {
              border: "none",
              backgroundColor: "#B6BCC320",
            },
            "& .MuiDataGrid-row": {
              borderBottom: "1px #e6e6e6 solid",
              borderRadius: 0,
            },
            "& .MuiDataGrid-row:hover": {
              borderBottom: "transparent",
            },
            borderTop: "1px #e6e6e6 solid",
            borderRadius: 0,
          }}
          hideFooter
        />
        <Box
          sx={{
            position: "absolute",
            top: { xs: 160, sm: 195, md: 178 },
            right: 30,
          }}
        >
          <Box
            sx={[styles.flex_Acenter, { display: { xs: "none", sm: "flex" } }]}
          >
            <Box>
              <BlackActionContainedButton
                variant="contained"
                disableElevation
                startIcon={<Share color="secondary" />}
                onClick={() =>
                  getReport({
                    variables: {
                      filter: {
                        project_id: projectId?.project_id,
                        ...(Boolean(selectedFilterStatus) && {
                          documentStatus: selectedFilterStatus,
                        }),
                      },
                      ...(Boolean(sortBy) && { sorted: sortBy }),
                    },
                  })
                }
                sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
              >
                Export
              </BlackActionContainedButton>
            </Box>
            <Box sx={{ ml: 2 }}>
              <IconButton
                onClick={handleFilterMenuOpen}
                size="small"
                sx={{ ":hover": { backgroundColor: "#41B19931" } }}
              >
                <FilterAltOutlined
                  fontSize="small"
                  sx={{
                    color: openFilterMenu
                      ? "#41B199"
                      : `${colors.primaryBlack}50`,
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={filterMenuAnchor}
                open={openFilterMenu}
                onClose={handleFilterMenuClose}
                elevation={1}
                sx={{ "& .MuiMenu-paper": { width: 300 } }}
              >
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#00000080",
                        fontWeight: 600,
                        mb: 0.75,
                      }}
                    >
                      Select Status
                    </Typography>
                    <Autocomplete
                      value={
                        Boolean(selectedFilterStatus)
                          ? ContractStatusArr.find(
                              (x) => x.id === selectedFilterStatus
                            )
                          : { id: "", name: "" }
                      }
                      onChange={(_, newValue) => {
                        if (newValue) {
                          setSelectedFilterStatus(newValue.id);
                        } else {
                          setSelectedFilterStatus("");
                        }
                      }}
                      size="small"
                      fullWidth
                      options={ContractStatusArr}
                      getOptionLabel={(option) => option.name}
                      placeholder="Select Status"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              borderRadius: "6px",
                              fontSize: "13px",
                              color: colors.primaryBlack,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#00000080",
                        fontWeight: 600,
                      }}
                    >
                      Sort By
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RadioGroup
                      value={sortBy}
                      onChange={(e) => {
                        setSortBy(e.target.value);
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Radio
                          value="name"
                          size="small"
                          checkedIcon={
                            <RadioButtonChecked
                              fontSize="small"
                              sx={{ color: "#6f6f6f" }}
                            />
                          }
                          icon={
                            <RadioButtonUnchecked
                              fontSize="small"
                              sx={{ color: "#6f6f6f" }}
                            />
                          }
                        />
                        <Typography
                          sx={{ color: "#00000070", fontSize: "14px" }}
                        >
                          Name (A-Z)
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Radio
                          value="-name"
                          size="small"
                          checkedIcon={
                            <RadioButtonChecked
                              fontSize="small"
                              sx={{ color: "#6f6f6f" }}
                            />
                          }
                          icon={
                            <RadioButtonUnchecked
                              fontSize="small"
                              sx={{ color: "#6f6f6f" }}
                            />
                          }
                        />
                        <Typography
                          sx={{ color: "#00000070", fontSize: "14px" }}
                        >
                          Name (Z-A)
                        </Typography>
                      </Box>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ContractStatusReport;
