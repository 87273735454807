import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

import { images } from "assets/images";
import { ManageAdminsLabels } from "common/AppConstants";

import { ManageAdminsStyles as styles } from "./styles";

interface IProps {
  setAddAdminModal: Dispatch<SetStateAction<boolean>>;
  setSearchText: Dispatch<SetStateAction<string>>;
}

const Filters = (props: IProps) => {
  const { setAddAdminModal, setSearchText } = props;

  const [searchTextFieldValue, setSearchTextFieldValue] = useState("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextFieldValue(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchText(e.target.value);
    }, 300);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Button
          variant="contained"
          startIcon={<img src={images.ADD_PROJECT_USER} alt="add_admin" />}
          onClick={() => setAddAdminModal(true)}
        >
          {ManageAdminsLabels.ADD_ADMIN}
        </Button>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        display="flex"
        justifyContent="flex-end"
      >
        <TextField
          value={searchTextFieldValue}
          onChange={handleSearchChange}
          size="small"
          placeholder="Search"
          inputProps={{ style: styles.text_input }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search fontSize="small" color="inherit" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchTextFieldValue("");
                    setSearchText("");
                  }}
                >
                  <Clear fontSize="small" color="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Filters;
