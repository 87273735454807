import React, { Dispatch, SetStateAction, useState } from "react";
import {
	Avatar,
	Box,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { FormikProps } from "formik";

import { ICreateContactFieldsNew } from "models/contacts";
import { colors } from "theme/colors";

import IndividualForm from "./IndividualForm";
import CompanyForm from "./CompanyForm";
import AgencyForm from "./AgencyForm";
import ManagementForm from "./ManagementForm";

interface IStepperComponentProps {
	formikBag: FormikProps<ICreateContactFieldsNew>;
	handleClose: () => void;
	setNewAgent: Dispatch<SetStateAction<boolean>>;
}

const steps = ["Individual", "Company", "Agency", "Management"];

function StepperComponent(props: IStepperComponentProps) {
	const { handleClose, formikBag, setNewAgent } = props;
	const [activeStep, setActiveStep] = useState(0);
	const [personalActiveSection, setPersonalActiveSection] = useState(0);
	const [companyActiveSection, setCompanyActiveSection] = useState(0);
	const [agencyActiveSection, setAgencyActiveSection] = useState(0);
	const [managementActiveSection, setManagementActiveSection] = useState(0);

	return (
		<Box
			sx={{
				height: "100%",
				overflow: "hidden",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					py: 2,
					backgroundColor: "#B6BCC325",
					position: "relative",
					px: 4,
				}}
			>
				<Grid
					container
					spacing={1}
					sx={{ display: { xs: "none", sm: "flex" } }}
				>
					{steps.map((step, index) => (
						<Grid key={step} item lg={3} md={3} sm={3} xs={3}>
							<Box
								component="div"
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									cursor: "pointer",
								}}
								onClick={() => {
									setActiveStep(index);
									setPersonalActiveSection(0);
									setCompanyActiveSection(0);
									setAgencyActiveSection(0);
									setManagementActiveSection(0);
								}}
							>
								<Avatar
									sx={{
										width: 22,
										height: 22,
										mr: 0.5,
										backgroundColor:
											activeStep === index
												? colors.primaryBlack
												: `${colors.primaryBlack}20`,
									}}
								>
									<Typography
										sx={{
											fontSize: "12px",
											color:
												activeStep === index
													? colors.secondary
													: `${colors.primaryBlack}50`,
										}}
									>
										{index + 1}
									</Typography>
								</Avatar>
								<Typography
									textAlign="center"
									sx={{
										fontSize: "13px",
										color:
											activeStep === index
												? colors.primaryBlack
												: `${colors.primaryBlack}60`,
										...(activeStep === index && { fontWeight: 600 }),
									}}
								>
									{step}
								</Typography>
							</Box>
						</Grid>
					))}
				</Grid>
				<Box
					sx={{
						display: { xs: "flex", sm: "none" },
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Avatar
						sx={{
							width: 22,
							height: 22,
							mr: 0.5,
							backgroundColor: colors.primaryBlack,
						}}
					>
						<Typography
							sx={{
								fontSize: "12px",
								color: colors.secondary,
							}}
						>
							{activeStep + 1}
						</Typography>
					</Avatar>
					<Typography
						textAlign="center"
						sx={{
							fontSize: "13px",
							color: colors.primaryBlack,
							fontWeight: 600,
						}}
					>
						{steps[activeStep]}
					</Typography>
				</Box>
				<Box sx={{ position: "absolute", top: 14, right: 10 }}>
					<Tooltip title="Close">
						<IconButton
							onClick={handleClose}
							sx={{
								backgroundColor: `${colors.primaryBlack}20`,
								height: 25,
								width: 25,
								":hover": {
									backgroundColor: `${colors.primaryBlack}20`,
								},
							}}
						>
							<Clear sx={{ color: "#000000", fontSize: 18 }} />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
			<Box sx={{ flexGrow: 1, overflow: "auto" }}>
				<Box sx={{ height: "100%" }}>
					{activeStep === 0 && (
						<IndividualForm
							formikBag={formikBag}
							setActiveStep={setActiveStep}
							activeSection={personalActiveSection}
							setActiveSection={setPersonalActiveSection}
						/>
					)}
					{activeStep === 1 && (
						<CompanyForm
							formikBag={formikBag}
							setActiveStep={setActiveStep}
							activeSection={companyActiveSection}
							setActiveSection={setCompanyActiveSection}
							setPrevActiveSection={setPersonalActiveSection}
						/>
					)}
					{activeStep === 2 && (
						<AgencyForm
							formikBag={formikBag}
							setActiveStep={setActiveStep}
							activeSection={agencyActiveSection}
							setActiveSection={setAgencyActiveSection}
							setPrevActiveSection={setCompanyActiveSection}
							setNewAgent={setNewAgent}
						/>
					)}
					{activeStep === 3 && (
						<ManagementForm
							formikBag={formikBag}
							setActiveStep={setActiveStep}
							activeSection={managementActiveSection}
							setActiveSection={setManagementActiveSection}
							setPrevActiveSection={setAgencyActiveSection}
							handleClose={handleClose}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
}

export default StepperComponent;
