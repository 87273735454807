import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid } from "@mui/material";
import { FormikProps } from "formik";

import { ICreateContactFieldsNew } from "models/contacts";

import IndividualLeftSteps from "./IndividualLeftSteps";
import PersonalDetailsForm from "./PersonalDetailsForm";
import RegistrationForm from "./RegistrationForm";
import BankDetailsForm from "./BankDetailsForm";
import SuperannuationForm from "./SuperannuationForm";
import EmergencyForm from "./EmergencyForm";
import SocialsForm from "./SocialsForm";

interface IIndividualFormProps {
	formikBag: FormikProps<ICreateContactFieldsNew>;
	setActiveStep: Dispatch<SetStateAction<number>>;
	activeSection: number;
	setActiveSection: Dispatch<SetStateAction<number>>;
}

function IndividualForm(props: IIndividualFormProps) {
	const { formikBag, setActiveStep, activeSection, setActiveSection } = props;

	return (
		<Box sx={{ height: "100%" }}>
			<Grid container>
				<Grid
					item
					lg={3}
					md={3}
					sm={3}
					xs={3}
					sx={{ display: { xs: "none", sm: "block" } }}
				>
					<Box sx={{ pt: 3 }}>
						<IndividualLeftSteps
							activeSection={activeSection}
							setActiveSection={setActiveSection}
							formikBag={formikBag}
						/>
					</Box>
				</Grid>
				<Grid item lg={9} md={9} sm={9} xs={12}>
					<Box
						sx={{
							borderLeft: { xs: "none", sm: "1.5px #e6e6e6 solid" },
							...(activeSection === 0 ||
							(activeSection === 3 &&
								Boolean(
									formikBag.values.financialDetails
										.haveSuperannuationAccount
								) &&
								formikBag.values.financialDetails
									.haveSuperannuationAccount === "2")
								? { minHeight: "731.02px" }
								: { height: { xs: "546.02px", sm: "731.02px" } }),
						}}
					>
						{activeSection === 0 && (
							<PersonalDetailsForm
								formikBag={formikBag}
								setActiveSection={setActiveSection}
							/>
						)}
						{activeSection === 1 && (
							<RegistrationForm
								formikBag={formikBag}
								setActiveSection={setActiveSection}
							/>
						)}
						{activeSection === 2 && (
							<BankDetailsForm
								formikBag={formikBag}
								setActiveSection={setActiveSection}
							/>
						)}
						{activeSection === 3 && (
							<SuperannuationForm
								formikBag={formikBag}
								setActiveSection={setActiveSection}
							/>
						)}
						{activeSection === 4 && (
							<EmergencyForm
								formikBag={formikBag}
								setActiveSection={setActiveSection}
							/>
						)}
						{activeSection === 5 && (
							<SocialsForm
								formikBag={formikBag}
								setActiveSection={setActiveSection}
								setActiveStep={setActiveStep}
							/>
						)}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default IndividualForm;
