import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { Download, SkipNext, SkipPrevious } from "@mui/icons-material";
import queryString from "query-string";

import { GET_CONTRACT_FILE_GUEST } from "graphql/contracts";
import { ComplexityMapping, ContractStatusMapping } from "utils/constants";
import { StorageConstants } from "utils/storage";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { NewGreenPrimaryContainedButton } from "common/Buttons";
import { images } from "assets/images";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

export function ContractShareUpload() {
  const { setLoading } = useStore();
  const location = useLocation();
  const parseQueryString = queryString.parse(location.search);
  const { id, token } = parseQueryString;

  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [complexity, setComplexity] = useState("3");
  const [selectedContractStatus, setSelectedContractStatus] = useState("DRAFT");
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const { loading } = useQuery(GET_CONTRACT_FILE_GUEST, {
    variables: {
      documentId: id,
    },
    skip: !id || !Boolean(sessionStorage.getItem(StorageConstants.TOKEN)),
    onCompleted: (data) => {
      const { getDocumentFileGuest } = data;
      const { status, file, document, fileType } = getDocumentFileGuest;
      if (status) {
        setFileName(document?.name || "");
        setFileType(fileType);
        setSelectedContractStatus(document?.documentStatus!);
        const parsedJSON = Boolean(document?.template?.additionalDetails)
          ? JSON.parse(document?.template?.additionalDetails!)
          : null;
        if (Boolean(parsedJSON)) {
          setComplexity(parsedJSON.complexity);
        }
      }
      if (status && !Boolean(file)) {
        toast.error("No file found", { delay: 10 });
      }
      if (status && Boolean(file)) {
        setFileUrl(file);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (Boolean(token)) {
      sessionStorage.setItem(StorageConstants.TOKEN, token as string);
    }
  }, [token]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Toolbar
        sx={{
          borderBottom: "1px #e6e6e6 solid",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: 500,
              color: colors.primaryBlack,
            }}
          >
            {fileName}
          </Typography>
          <img
            src={(ComplexityMapping as any)[complexity].flag}
            alt="complexity"
            style={{ marginRight: 20, marginLeft: 10 }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={[
              {
                backgroundColor: (ContractStatusMapping as any)[
                  selectedContractStatus
                ].color,
                px: 1.5,
                height: "30px",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
              },
            ]}
          >
            <Typography
              sx={{
                color: colors.secondary,
                fontSize: "13px",
                fontWeight: 500,
              }}
            >
              {(ContractStatusMapping as any)[selectedContractStatus].name}
            </Typography>
          </Box>
          {/* <Box sx={{ ml: 1 }}>
            <IconButton>
              <Download />
            </IconButton>
          </Box> */}
        </Box>
      </Toolbar>
      {Boolean(fileUrl) ? (
        Boolean(fileType) && fileType.startsWith("pdf") ? (
          <iframe
            src={fileUrl}
            title="Test"
            style={{ width: "100vw", height: "calc(100vh - 64px)" }}
          />
        ) : (
          <Box
            sx={{
              cursor: "pointer",
              mt: 5,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <NewGreenPrimaryContainedButton
              sx={{
                width: "230px",
                py: 1.5,
                fontSize: "18px",
                fontWeight: 600,
              }}
              startIcon={<Download sx={{ color: "#000", fontSize: 18 }} />}
              onClick={() => window.open(fileUrl, "_blank")}
              disableElevation
            >
              Download Here
            </NewGreenPrimaryContainedButton>
          </Box>
        )
      ) : (
        <></>
      )}
      {/* <Box>
           <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
            <IconButton
              onClick={() => setPageNumber(pageNumber - 1)}
              disabled={pageNumber === 1}
            >
              <SkipPrevious />
            </IconButton>
            <Typography
              sx={{ mx: 1, fontSize: "13px" }}
            >{`Page ${pageNumber} of ${numPages}`}</Typography>
            <IconButton
              onClick={() => setPageNumber(pageNumber + 1)}
              disabled={pageNumber === numPages}
            >
              <SkipNext />
            </IconButton>
          </Box>
          <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        </Box> */}
    </Box>
  );
}
