import React, { useEffect, useMemo, useState } from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";

import { images } from "assets/images";
import Drawer from "common/AdminDrawer";
import { AdminDashboardLabels } from "common/AppConstants";
import { GET_ADMIN_DASHBOARD } from "graphql/dashboard";
import { useStore } from "utils/store";

import ProjectStatusVsCount from "./ProjectStatusVsCount";
import ProjectTypeVsCount from "./ProjectTypeVsCount";
import ProjectTypeVsStatus from "./ProjectTypeVsStatus";
import { AdminDashboardStyles as styles } from "./styles";

export interface IStatusTypeCount {
  status: string;
  type: string;
  count: number;
}

export interface IStatusCount {
  status: string;
  count: number;
}
export interface ITypeCount {
  type: string;
  count: number;
}

export interface IStatusMap {
  [status: string]: IStatusCount;
}

export interface ITypeMap {
  [type: string]: ITypeCount;
}
export const AdminDashboard = () => {
  const { setLoading } = useStore();

  const [clients, setClients] = useState(0);
  const [statusVsCount, setStatusVsCount] = useState<IStatusMap>({});
  const [typeVsCount, setTypeVsCount] = useState<ITypeMap>({});
  const [pivot, setPivot] = useState<IStatusTypeCount[]>([]);

  const timeline = useMemo(
    () => [
      "Concept",
      "Development",
      "Pre-Production",
      "Production",
      "Post-Production",
      "Delivered",
      "Archived",
    ],
    []
  );

  const calculateStatusVsCount = (combinations: IStatusTypeCount[]) => {
    const map: IStatusMap = {};
    combinations.forEach((s) => {
      let val = { status: s.status, count: s.count };
      if (map[s.status]) {
        val = map[s.status];
        val.count += s.count;
      }
      map[s.status] = val;
    });
    return map;
  };

  const calculateTypeVsCount = (combinations: IStatusTypeCount[]) => {
    const map: ITypeMap = {};

    combinations.forEach((s) => {
      let val = { type: s.type, count: s.count };

      if (map[s.type]) {
        val = map[s.type];
        val.count += s.count;
      }
      map[s.type] = val;
    });
    return map;
  };

  const { loading } = useQuery(GET_ADMIN_DASHBOARD, {
    variables: {},
    onCompleted: (data) => {
      const { getAdminDashboard } = data;
      const { clients: totalClients, projectTypeStatusCount } =
        getAdminDashboard;
      setClients(totalClients);
      const statusMap = calculateStatusVsCount(projectTypeStatusCount);
      const typeMap = calculateTypeVsCount(projectTypeStatusCount);
      setStatusVsCount(statusMap);
      setTypeVsCount(typeMap);
      setPivot(
        projectTypeStatusCount.map((s: any) => ({
          type: s.type,
          status: s.status,
          count: s.count,
        }))
      );
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Drawer>
      <Grid container spacing={4}>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Paper sx={[styles.card, styles.max_h_scroll, { p: 0 }]}>
            <Box sx={styles.total_clients_container}>
              <Box
                sx={[
                  styles.all_center,
                  {
                    p: 4,
                    flexDirection: "column",
                  },
                ]}
              >
                <img src={images.CLIENTS_ICON} alt="clients" />
                <Typography sx={styles.total_clients_text}>
                  {AdminDashboardLabels.TOTAL_CLIENTS}
                </Typography>
                <Typography sx={styles.total_clients_count_text}>
                  {clients}
                </Typography>
              </Box>
              <Box sx={styles.all_center}>
                <img src={images.CLIENT_COUNT_BG} alt="clients" />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={7} md={7} sm={6} xs={12}>
          <Paper sx={[styles.card, styles.max_h_scroll]}>
            <ProjectStatusVsCount
              stats={
                Object.getOwnPropertyNames(statusVsCount).length === 0
                  ? []
                  : timeline.map((t) => statusVsCount[t])
              }
            />
          </Paper>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Paper sx={[styles.card, { p: 0 }]}>
            <Box sx={{ p: 3 }}>
              <Typography sx={styles.titleText}>
                {AdminDashboardLabels.PROJECTTYPE_VS_COUNT}
              </Typography>
              <Divider sx={[styles.divider, { mt: 3 }]} />
            </Box>
            <Box sx={[styles.max_h_scroll, { height: "280px", px: 3, pb: 2 }]}>
              <ProjectTypeVsCount
                stats={
                  Object.getOwnPropertyNames(typeVsCount).length === 0
                    ? []
                    : Object.values(typeVsCount)
                }
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper sx={styles.card}>
            <ProjectTypeVsStatus stats={pivot} />
          </Paper>
        </Grid>
      </Grid>
    </Drawer>
  );
};
