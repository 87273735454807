import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";

import { ICreateContactFieldsNew } from "models/contacts";
import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface ISocialsFormProps {
  formikBag: FormikProps<ICreateContactFieldsNew>;
  setActiveSection: Dispatch<SetStateAction<number>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

function SocialsForm(props: ISocialsFormProps) {
  const { formikBag, setActiveSection, setActiveStep } = props;
  const { values, handleChange, handleBlur, errors, touched } = formikBag;

  const isValidArrStep1 = (errors: any) => {
    const basicErrs: any = errors;
    return ["instagram", "facebook", "twitter", "imdb"].filter(
      (x: string) => basicErrs[x]
    );
  };

  return (
    <Box
      sx={{
        pt: { xs: 2, sm: 14 },
        px: 4,
        pb: 1.5,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "block", sm: "none" } }}
        >
          <Typography sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}>
            Socials Details
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Instagram</Typography>
          <TextField
            id="instagram"
            value={values.instagram}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched.instagram && (
            <Typography sx={styles.error_text}>{errors.instagram}</Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Twitter</Typography>
          <TextField
            id="twitter"
            value={values.twitter}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched.twitter && (
            <Typography sx={styles.error_text}>{errors.twitter}</Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>Facebook</Typography>
          <TextField
            id="facebook"
            value={values.facebook}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched.facebook && (
            <Typography sx={styles.error_text}>{errors.facebook}</Typography>
          )}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.field_label}>IMDb Link/ Website</Typography>
          <TextField
            id="imdb"
            value={values.imdb}
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            fullWidth
            inputProps={{ style: styles.input_field }}
          />
          {touched.imdb && (
            <Typography sx={styles.error_text}>{errors.imdb}</Typography>
          )}
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
            <OutlinedBtn
              size="small"
              sx={{
                borderRadius: "20px",
                fontSize: "13px",
                fontWeight: 600,
                width: "120px",
                color: "#000",
              }}
              disableElevation
              disableRipple
              onClick={() => setActiveSection(4)}
            >
              Back
            </OutlinedBtn>
            <BlackActionContainedButton
              variant="contained"
              size="small"
              sx={{
                borderRadius: "20px",
                ml: 1,
                fontSize: "13px",
                fontWeight: 600,
                width: "120px",
              }}
              disableElevation
              disableRipple
              disabled={isValidArrStep1(errors).length > 0}
              onClick={() => setActiveStep(1)}
            >
              Next
            </BlackActionContainedButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SocialsForm;
