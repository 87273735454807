import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  ChevronRight,
  FiberManualRecord,
} from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import {
  ComplexityMapping,
  ContractStatusArr,
  ContractStatusMapping,
} from "utils/constants";
import { useThirdPartyContract } from "hooks/useThirdPartyContract";
import { UPDATE_DOCUMENT, UPDATE_DOCUMENT_STATUS } from "graphql/contracts";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import { ContractDocxStyles as styles } from "./styles";
import { IContract } from "../ContractDocEditor";

interface IHeaderProps {
  contractData: IContract | null;
  refetch: any;
  // selectedSigners: ISignerArr[];
  // initialContractDocContent: string;
}

const Header = (props: IHeaderProps) => {
  const {
    contractData,
    refetch,
    // refetch, selectedSigners, initialContractDocContent
  } = props;

  const navigate = useNavigate();
  const { fillableFields } = useThirdPartyContract();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const foldersEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [complexity, setComplexity] = useState("3");
  const [selectedContractStatus, setSelectedContractStatus] = useState("DRAFT");
  const [openContractStatusMenu, setOpenContractStatusMenu] = useState(false);
  const [contractStatusAnchor, setContractStatusAnchor] =
    useState<HTMLElement | null>(null);

  const handleContractStatusClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    setOpenContractStatusMenu(true);
    setContractStatusAnchor(e.currentTarget);
  };

  const handleContractStatusClose = () => {
    setOpenContractStatusMenu(false);
    setContractStatusAnchor(null);
  };

  const [fireUpdateContractStatusApi, { loading: updatingStatus }] =
    useMutation(UPDATE_DOCUMENT_STATUS, {
      onCompleted: (data) => {
        const { updateDocumentStatus } = data;
        const { status, message } = updateDocumentStatus;
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    });

  const [fireUpdateContractApi, { loading: updating }] = useMutation(
    UPDATE_DOCUMENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateDocument } = data;
        const { status, message } = updateDocument;
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleSave = () => {
    let additionalJSON = {};
    additionalJSON = Boolean(contractData?.additionalDetails)
      ? Boolean(JSON.parse(contractData?.additionalDetails!))
        ? JSON.parse(contractData?.additionalDetails!)
        : {}
      : {};
    additionalJSON = { ...additionalJSON, fillable_fields: fillableFields };
    fireUpdateContractApi({
      variables: {
        payload: {
          fetchDocumentId: contractData?.id,
          name: contractData?.name,
          isFolder: contractData?.isFolder,
          ...(Boolean(additionalJSON) && {
            additionalDetails: JSON.stringify(additionalJSON),
          }),
        },
      },
    });
  };

  useEffect(() => {
    if (Boolean(contractData)) {
      setSelectedContractStatus(contractData?.documentStatus!);
      const parsedJSON = Boolean(contractData?.template?.additionalDetails)
        ? JSON.parse(contractData?.template?.additionalDetails!)
        : null;
      if (Boolean(parsedJSON)) {
        setComplexity(parsedJSON.complexity);
      }
    }
  }, [contractData]);

  useEffect(() => {
    setLoading(updating || updatingStatus);
  }, [setLoading, updating, updatingStatus]);

  return (
    <Toolbar>
      <Box sx={styles.headerLeftContainer}>
        <IconButton
          edge="start"
          color="inherit"
          sx={styles.mr1}
          onClick={() => {
            const backUrl = sessionStorage.getItem("upload_pdf_back_url");
            sessionStorage.removeItem("upload_pdf_back_url");
            navigate(backUrl!);
          }}
        >
          <ArrowBack sx={{ color: colors.iconColor }} />
        </IconButton>
        <Typography sx={[styles.contract_name_text, styles.mr1]}>
          {contractData?.name}
        </Typography>
        <img
          src={(ComplexityMapping as any)[complexity].flag}
          alt="complexity"
          style={styles.complexity_flag}
        />
      </Box>
      <Box sx={styles.headerRightContainer}>
        <Box sx={styles.contract_status_container}>
          <Box
            sx={[
              styles.contract_status_label_container,
              styles.flex_Acenter,
              {
                backgroundColor: (ContractStatusMapping as any)[
                  selectedContractStatus
                ].color,
                ...(!(
                  Boolean(ROLE_PERMISSIONS) &&
                  foldersEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]
                    ?.FOLDERS_EDIT
                ) && { borderRadius: "20px" }),
              },
            ]}
          >
            <Typography sx={styles.contract_status_label_text}>
              {(ContractStatusMapping as any)[selectedContractStatus].name}
            </Typography>
          </Box>
          {Boolean(ROLE_PERMISSIONS) &&
            foldersEditIndex !== undefined &&
            ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]
              ?.FOLDERS_EDIT && (
              <Box
                sx={[
                  styles.contract_stat_icon_container,
                  styles.flex_Acenter,
                  {
                    backgroundColor: (ContractStatusMapping as any)[
                      selectedContractStatus
                    ].color,
                  },
                ]}
              >
                <IconButton
                  size="small"
                  aria-controls={
                    openContractStatusMenu ? "basic-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openContractStatusMenu ? "true" : undefined}
                  onClick={handleContractStatusClick}
                >
                  <ChevronRight
                    fontSize="small"
                    sx={{ color: colors.secondary }}
                  />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={contractStatusAnchor}
                  open={openContractStatusMenu}
                  onClose={handleContractStatusClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={styles.menu_container}
                  elevation={0}
                >
                  <MenuList>
                    {ContractStatusArr.map((item) => (
                      <MenuItem
                        key={item.id}
                        onClick={() => {
                          setSelectedContractStatus(item.id);
                          fireUpdateContractStatusApi({
                            variables: {
                              payload: {
                                documentId: contractData?.id,
                                documentStatus: item.id,
                              },
                            },
                          });

                          handleContractStatusClose();
                        }}
                      >
                        <ListItemIcon>
                          <FiberManualRecord
                            fontSize="small"
                            sx={{
                              color: (ContractStatusMapping as any)[item.id]
                                .color,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText sx={styles.cnct_status_menu_text}>
                          {item.name}
                        </ListItemText>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
            )}
        </Box>
        {Boolean(ROLE_PERMISSIONS) &&
          foldersEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT && (
            <Box sx={{ ml: 1 }}>
              <Button size="small" variant="outlined" onClick={handleSave}>
                Save
              </Button>
            </Box>
          )}
      </Box>
      {/* {statusChangeWarningModal && (
        <StatusChangeWarningModal
          open={statusChangeWarningModal}
          setOpen={setStatusChangeWarningModal}
          OnYes={() => {
            setSelectedContractStatus(tempCnctStatus);
            fireUpdateContractStatusApi({
              variables: {
                payload: {
                  documentId: contractData?.id,
                  documentStatus: tempCnctStatus,
                },
              },
            });
          }}
        />
      )} */}
    </Toolbar>
  );
};

export default Header;
