import React, { useEffect, useMemo, useState } from "react";
import { Grid, Link, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import moment from "moment";

import Drawer from "common/AdminDrawer";
import NoDataView from "common/NoDataView";
import NoResultsFoundView from "common/NoResultsFoundView";
import Table from "common/Table";
import SuccessModal from "common/SuccessModal";
import ErrorModal from "common/ErrorModal";
import { GlobalLabels } from "common/AppConstants";
import { useStore } from "utils/store";
import { IPaginationModel } from "models/common";
import { GET_ALL_ROLES_SUPER_ADMIN } from "graphql/roles";
import { colors } from "theme/colors";

import Filters from "./Filters";
import AddRoleModal from "./AddRoleModal";
import EditRoleModal from "./EditRoleModal";
import DeleteRoleModal from "./DeleteAdminModal";
import ViewPermissionModal from "./ViewPermissionsModal";
import { IRole } from "./utils";
import { AdminUsersStyles as styles } from "./styles";

export function RoleManagement() {
  const { setLoading } = useStore();

  const [addRoleModal, setAddRoleModal] = useState(false);
  const [editRoleModal, setEditRoleModal] = useState(false);
  const [deleteRoleModal, setDeleteRoleModal] = useState(false);
  const [paginationModel, setPaginationModel] = useState<IPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [roles, setRoles] = useState<IRole[]>([]);
  const [roleCount, setRoleCount] = useState(0);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [viewPermissionModal, setViewPermissionModal] = useState(false);

  const { loading, refetch } = useQuery(GET_ALL_ROLES_SUPER_ADMIN, {
    variables: {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      filter: {
        name__icontains: searchText,
      },
    },
    onCompleted: (data) => {
      setLoading(false);
      const { allRoles } = data;
      const { count, roles: rawRoles } = allRoles;
      setRoleCount(count);
      if (count > 0) {
        setRoles(rawRoles);
      } else {
        setRoles([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Role Name",
        minWidth: 220,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "createdDate",
        headerName: "Created At",
        headerAlign: "center",
        minWidth: 135,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {params?.value ? moment(params.value).format("DD MMMM YYYY") : ""}
          </Typography>
        ),
      },
      {
        field: "modifiedDate",
        headerName: "Modified Date",
        headerAlign: "center",
        minWidth: 160,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {params?.value ? moment(params.value).format("DD MMMM YYYY") : ""}
          </Typography>
        ),
      },
      {
        field: "rolePermission",
        headerName: "Permissions",
        headerAlign: "center",
        minWidth: 145,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Link
            component="button"
            sx={[styles.cell_text, { color: colors.primary }]}
            onClick={() => {
              setSelectedRole(params.row.id);
              setViewPermissionModal(true);
            }}
          >
            {GlobalLabels.VIEW}
          </Link>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        sortable: false,
        minWidth: 110,
        flex: 1,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            showInMenu
            label="Edit"
            onClick={() => {
              setSelectedRole(params.row.id);
              setEditRoleModal(true);
            }}
            sx={styles.actions_text}
          />,
          <GridActionsCellItem
            showInMenu
            label="Delete"
            onClick={() => {
              setSelectedRole(params.row.id);
              setSelectedRoleName(params.row.name);
              setDeleteRoleModal(true);
            }}
            sx={styles.actions_text}
          />,
        ],
      },
    ],
    []
  );

  return (
    <Drawer>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Filters
            setAddRoleModal={setAddRoleModal}
            setSearchText={setSearchText}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {!loading && !searchText && roles.length === 0 && (
            <NoDataView title="No users found" subtitle="Please add a user" />
          )}
          {!loading && searchText && roles.length === 0 && (
            <NoResultsFoundView />
          )}
          {!loading && roles.length > 0 && (
            <Table
              rows={roles}
              columns={columns}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              disableColumnMenu
              rowCount={roleCount}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              sx={styles.table}
            />
          )}
        </Grid>
        {addRoleModal && (
          <AddRoleModal
            open={addRoleModal}
            setOpen={setAddRoleModal}
            refetchTableData={refetch}
            setSuccessModal={setSuccessModal}
            setErrorModal={setErrorModal}
            setSuccessText={setSuccessText}
            setErrorText={setErrorText}
          />
        )}
        {editRoleModal && (
          <EditRoleModal
            open={editRoleModal}
            setOpen={setEditRoleModal}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            refetchTableData={refetch}
            setSuccessModal={setSuccessModal}
            setErrorModal={setErrorModal}
            setSuccessText={setSuccessText}
            setErrorText={setErrorText}
          />
        )}
        {deleteRoleModal && (
          <DeleteRoleModal
            open={deleteRoleModal}
            setOpen={setDeleteRoleModal}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            selectedRoleName={selectedRoleName}
            setSelectedRoleName={setSelectedRoleName}
            refetchTableData={refetch}
          />
        )}
        {viewPermissionModal && (
          <ViewPermissionModal
            open={viewPermissionModal}
            setOpen={setViewPermissionModal}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
          />
        )}
        {successModal && (
          <SuccessModal
            open={successModal}
            setOpen={setSuccessModal}
            successText={successText}
            onClose={() => setSuccessText("")}
          />
        )}
        {errorModal && (
          <ErrorModal
            open={errorModal}
            setOpen={setErrorModal}
            errorText={errorText}
            tryAgainBtnClick={() => setErrorText("")}
          />
        )}
      </Grid>
    </Drawer>
  );
}
