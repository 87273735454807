import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Editor as TinyMCEEditor } from "tinymce";
import { Editor } from "@tinymce/tinymce-react";

import { useContract } from "hooks/useContract";
import { useStore } from "utils/store";
import { ITemplate } from "models/templates";
import { IPerson } from "models/projects";

import EditorFrame from "./EditorFrame";
import { useQuery } from "@apollo/client";
import { GET_CONTRACT_FILE_GUEST } from "graphql/contracts";
import queryString from "query-string";
import { StorageConstants } from "utils/storage";
import { toast } from "react-toastify";

interface IContract {
  id: string;
  name: string;
  documentStatus: string;
  additionalDetails: string;
  contact: { id: string; fullName: string };
  template: ITemplate;
  isFolder: boolean;
  file: any;
  createdDate: string;
  modifiedDate: string;
  fileExists: boolean;
  signedFile: string;
}

interface IFillableFields {
  id: string;
  fieldName: string;
  fieldDescription: string;
  fieldType: string;
  fieldValue: string;
  fieldResolution: string;
  fieldMultiple: boolean;
  fieldArr: IPerson[];
  resolveTo: string;
}

export function ContractShareDocx() {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const { setLoading } = useStore();
  const location = useLocation();
  const parseQueryString = queryString.parse(location.search);
  const { id, token } = parseQueryString;
  const { fillableFields, setFillableFields, setTags } = useContract();

  const [contractDocContent, setContractDocContent] = useState("<p></p>");
  const [contractData, setContractData] = useState<IContract | null>(null);
  const [resolutionFields, setResolutionFields] = useState<IFillableFields[]>(
    []
  );
  const [conversionLoading, setConversionLoading] = useState(false);
  const [currency, setCurrency] = useState("");

  // const { loading: gettingCurrency } = useQuery(GET_PROJ_DEETS_CURRENCY, {
  //   variables: { id: params?.project_id },
  //   skip: !params?.project_id,
  //   onCompleted: (data) => {
  //     const { project } = data;
  //     const { status, project: rawProject } = project;
  //     if (status && Boolean(rawProject)) {
  //       let currencyUnit = "AUD";
  //       if (rawProject?.currency) {
  //         currencyUnit = rawProject.currency;
  //       }

  //       setCurrency((CurrencyMapping as any)[currencyUnit]);
  //     }
  //   },
  //   fetchPolicy: "network-only",
  //   nextFetchPolicy: "network-only",
  // });

  const { loading } = useQuery(GET_CONTRACT_FILE_GUEST, {
    variables: { documentId: id },
    skip: !id || !Boolean(sessionStorage.getItem(StorageConstants.TOKEN)),
    onCompleted: (data) => {
      setLoading(false);
      const { getDocumentFileGuest } = data;
      const { status, document, file, fileExists, signedFile } =
        getDocumentFileGuest;
      if (status) {
        setContractData({ ...document, file, fileExists, signedFile });
        if (Boolean(file)) {
          setConversionLoading(true);
          fetch(file!)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.text(); // Convert response to text
            })
            .then((htmlString) => {
              // Use the HTML string here
              setContractDocContent(htmlString);

              let parser = new DOMParser();
              const doc = parser.parseFromString(htmlString, "text/html");

              if (Boolean(doc.getElementById("fillable-fields"))) {
                const fillableFieldsJSONStr =
                  doc.getElementById("fillable-fields")?.innerText;
                let tempFillFields = JSON.parse(fillableFieldsJSONStr!);
                setFillableFields([...tempFillFields]);
                let tempResFields = JSON.parse(fillableFieldsJSONStr!);
                tempResFields = tempResFields.filter((x: any) =>
                  Boolean(x.fieldResolution)
                );
                setResolutionFields([...tempResFields]);
                // if (tempResFields.length > 0) {
                //   getResValues();
                // }
              }

              if (Boolean(doc.getElementById("finka-tags"))) {
                const fillableFieldsJSONStr =
                  doc.getElementById("finka-tags")?.innerText;
                setTags(JSON.parse(fillableFieldsJSONStr!));
              }
            })
            .catch((error) => {
              console.error(
                "There was a problem fetching the HTML file:",
                error
              );
              toast.error("File not loaded", { delay: 10 });
            });
          setConversionLoading(false);
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  // TODO: skip when doc status is partially executed or fully executed
  // const { loading: gettingResValues, refetch: getResValues } = useQuery(
  //   GET_RESOLUTION_VALUES,
  //   {
  //     variables: {
  //       payload: resolutionFields.map((x) => {
  //         const obj = {
  //           id: x.fieldResolution,
  //           is_multiple: x.fieldMultiple,
  //           field_type: x.fieldType,
  //         };
  //         return JSON.stringify(obj);
  //       }),
  //       project_id: params?.project_id,
  //       document_id: params?.contract_id,
  //     },
  //     skip:
  //       !Boolean(params?.project_id) ||
  //       !Boolean(params?.contract_id) ||
  //       resolutionFields.length === 0 ||
  //       contractData?.documentStatus === "FULLY_EXECUTED" ||
  //       contractData?.documentStatus === "PARTIALLY_EXECUTED" ||
  //       contractData?.documentStatus === "APPROVED" ||
  //       contractData?.documentStatus === "RESCINDED" ||
  //       contractData?.documentStatus === "ARCHIVED",
  //     onCompleted: (data) => {
  //       const { getDocumentFillableFields } = data;
  //       const { status, fields } = getDocumentFillableFields;
  //       if (status) {
  //         const parsedFields = Boolean(fields) ? JSON.parse(fields) : null;
  //         if (Boolean(parsedFields)) {
  //           let tempFields = [...resolutionFields];
  //           tempFields = tempFields.map((x) => ({
  //             ...x,
  //             fieldValue: parsedFields[x.fieldResolution],
  //           }));

  //           let tempFillFields = [...fillableFields];
  //           tempFillFields = tempFillFields.map((x) => {
  //             if (Boolean(x.fieldResolution)) {
  //               const ele = editorRef?.current?.dom.get(x.id)!;
  //               if (Boolean(ele)) {
  //                 if (!x.fieldMultiple) {
  //                   if (x.fieldType === "image") {
  //                     if (
  //                       Boolean(
  //                         tempFields.find((y) => y.id === x.id)?.fieldValue!
  //                       ) &&
  //                       tempFields.find((y) => y.id === x.id)?.fieldValue! !==
  //                         "Not Provided"
  //                     ) {
  //                       editorRef?.current?.dom?.setAttrib(
  //                         ele,
  //                         "src",
  //                         `data:application/octet-stream;base64,${tempFields.find(
  //                           (y) => y.id === x.id
  //                         )?.fieldValue!}`
  //                       );
  //                     }
  //                   } else if (x.fieldType === "currency") {
  //                     ele.innerText =
  //                       tempFields.find((y) => y.id === x.id)?.fieldValue! ===
  //                       "Not Provided"
  //                         ? ""
  //                         : `${currency}${parseFloat(
  //                             tempFields.find((y) => y.id === x.id)?.fieldValue!
  //                           ).toLocaleString()}`;
  //                   } else if (x.fieldType === "percentage") {
  //                     ele.innerText =
  //                       tempFields.find((y) => y.id === x.id)?.fieldValue! ===
  //                       "Not Provided"
  //                         ? ""
  //                         : `${parseFloat(
  //                             tempFields.find((y) => y.id === x.id)?.fieldValue!
  //                           )}%`;
  //                   } else {
  //                     ele.innerText = tempFields.find(
  //                       (y) => y.id === x.id
  //                     )?.fieldValue!;
  //                   }
  //                   return {
  //                     ...x,
  //                     fieldValue:
  //                       (x.fieldType === "currency" ||
  //                         x.fieldType === "percentage") &&
  //                       tempFields.find((y) => y.id === x.id)?.fieldValue! !==
  //                         "Not Provided"
  //                         ? parseFloat(
  //                             tempFields.find((y) => y.id === x.id)?.fieldValue!
  //                           ).toFixed(2)
  //                         : tempFields.find((y) => y.id === x.id)?.fieldValue!,
  //                   };
  //                 } else {
  //                   if (
  //                     tempFields.find((y) => y.id === x.id)?.fieldValue! ===
  //                     "Not Provided"
  //                   ) {
  //                     ele.innerText = tempFields.find(
  //                       (y) => y.id === x.id
  //                     )?.fieldValue!;
  //                   }
  //                   return {
  //                     ...x,
  //                     ...(tempFields.find((y) => y.id === x.id)?.fieldValue! ===
  //                       "" ||
  //                     tempFields.find((y) => y.id === x.id)?.fieldValue! ===
  //                       "Not Provided"
  //                       ? {
  //                           fieldValue: tempFields.find((y) => y.id === x.id)
  //                             ?.fieldValue!,
  //                           fieldArr: [],
  //                         }
  //                       : {
  //                           fieldArr: tempFields.find((y) => y.id === x.id)
  //                             ?.fieldValue! as unknown as IPerson[],
  //                         }),
  //                   };
  //                 }
  //               } else {
  //                 return { ...x };
  //               }
  //             } else {
  //               return { ...x };
  //             }
  //           });
  //           setFillableFields([...tempFillFields]);
  //         }
  //       }
  //     },
  //     fetchPolicy: "network-only",
  //     nextFetchPolicy: "network-only",
  //   }
  // );

  const checkFillableFields = (value: any, editor: TinyMCEEditor) => {
    setContractDocContent(value);
  };

  useEffect(() => {
    setLoading(conversionLoading || loading);
  }, [conversionLoading, loading, setLoading]);

  useEffect(() => {
    if (Boolean(token)) {
      sessionStorage.setItem(StorageConstants.TOKEN, token as string);
    }
  }, [token]);

  useEffect(() => {
    setFillableFields([]);
    setTags([]);
  }, [setFillableFields, setTags]);

  return (
    <EditorFrame
      // ref={editorRef}
      contractData={contractData}
      // refetch={refetch}
      // selectedSigners={selectedSigners}
      // setSelectedSigners={setSelectedSigners}
      // initialContractDocContent={initialContractDocContent}
      // currency={currency}
      // feeCalculatorAdded={feeCalculatorAdded}
    >
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        disabled={true}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onEditorChange={checkFillableFields}
        apiKey="gjhka7xjgv6i3e4hhrfl0xxyfm6xlptxnbkq1h4vh8ahqqw3"
        value={contractDocContent}
        init={{
          promotion: false,
          plugins:
            "preview importcss searchreplace autolink save directionality code visualblocks visualchars link codesample table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion",
          // editimage_cors_hosts: ["picsum.photos"],
          menubar: false,
          toolbar: false,
          // autosave_ask_before_unload: true,
          // autosave_interval: "30s",
          // autosave_prefix: "{path}{query}-{id}-",
          // autosave_restore_when_empty: false,
          // autosave_retention: "2m",
          // image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          // image_list: [
          //   { title: "My page 1", value: "https://www.tiny.cloud" },
          //   { title: "My page 2", value: "http://www.moxiecode.com" },
          // ],
          // image_class_list: [
          //   { title: "None", value: "" },
          //   { title: "Some class", value: "class-name" },
          // ],
          insertdatetime_formats: [
            "%d %b %Y",
            "%d/%m/%Y",
            "%H:%M:%S",
            "%I:%M:%S %p",
          ],
          importcss_append: true,
          // file_picker_callback: (callback, value, meta) => {
          //   if (meta.filetype === "file") {
          //     callback("https://www.google.com/logos/google.jpg", {
          //       text: "My text",
          //     });
          //   }

          //   if (meta.filetype === "image") {
          //     callback("https://www.google.com/logos/google.jpg", {
          //       alt: "My alt text",
          //     });
          //   }

          //   if (meta.filetype === "media") {
          //     callback("movie.mp4", {
          //       source2: "alt.ogg",
          //       poster: "https://www.google.com/logos/google.jpg",
          //     });
          //   }
          // },
          // templates: [
          //   {
          //     title: "Input Text Field",
          //     description: "Creates a new input field",
          //     content:
          //       '<div class="mceTmpl finka finka-textfield"><input id="finka-textfield" type="text" style="font-family:Helvetica,Arial,sans-serif; font-size:14px; padding:8px;" /></div>',
          //   },
          //   {
          //     title: "Input Date Field",
          //     description: "Creates a new date field",
          //     content:
          //       '<div class="mceTmpl finka finka-datefield"><input id="finka-datefield" type="date" style="font-family:Helvetica,Arial,sans-serif; font-size:14px; padding:8px;" /></div>',
          //   },
          //   {
          //     title: "Signature Field",
          //     description: "Creates a signature field",
          //     content:
          //       '<div class="mceTmpl finka finka-signfield"><input type="file" accept="image/*" /></div>',
          //   },
          // ],
          // image_caption: true,
          noneditable_class: "mceNonEditable",
          editable_class: "mceEditable",
          toolbar_mode: "sliding",
          skin: "oxide",
          content_css: "default",
          content_style: "body { font-family:arial; font-size:10pt }",
          branding: false,
          elementpath: false,
          resize: false,
          help_accessibility: false,
          height: "calc(100vh - 65px)",
          width: "100%",
        }}
      />
    </EditorFrame>
  );
}
