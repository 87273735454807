import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Dialog, Grid, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { CHANGE_REGISTERED_DEVICE_MFA } from "graphql/users";
import { IModalProps } from "models/common";
import { useStore } from "utils/store";
import { NewOutlinedBtn, NewRedAlertContainedButton } from "common/Buttons";
import { StorageConstants } from "utils/storage";
import { images } from "assets/images";
import { colors } from "theme/colors";

interface IConfirmModalProps extends IModalProps {}

const ConfirmModal = (props: IConfirmModalProps) => {
  const { open, setOpen } = props;

  const { setLoading } = useStore();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const [fireChangeDeviceApi, { loading }] = useMutation(
    CHANGE_REGISTERED_DEVICE_MFA,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { deleteDevice } = data;
        const { status, error } = deleteDevice;
        if (status) {
          toast.success(error, { delay: 10 });
          navigate("/");
        } else {
          toast.error(error, { delay: 10 });
        }
        handleClose();
      },
    }
  );

  const handleDelete = () => {
    fireChangeDeviceApi({
      variables: {
        id: sessionStorage.getItem(StorageConstants.LOGGED_IN_USER_ID),
      },
    });
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const AlertImage = () => (
    <img src={images.ALERT_NEW} alt="alert" height={22} />
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "24px", p: 3, width: "350px" } }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={images.ALERT_NEW}
              sx={{ backgroundColor: "#ED1C2420" }}
              slots={{ img: AlertImage }}
            />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              color: colors.primaryBlack,
              textAlign: "center",
            }}
          >
            {`Are you sure?`}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{ fontSize: "13px", textAlign: "center", color: "#00000080" }}
          >
            Once changed, you will have to login again and scan the QR code with
            your new device.
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <NewRedAlertContainedButton
                sx={{
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleDelete}
              >
                Yes
              </NewRedAlertContainedButton>
            </Box>
            <Box sx={{ ml: 2 }}>
              <NewOutlinedBtn
                variant="outlined"
                sx={{
                  color: colors.primaryBlack,
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleClose}
              >
                No
              </NewOutlinedBtn>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmModal;
