import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Dialog,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  Cancel,
  Close,
  DeleteOutlineOutlined,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import moment from "moment";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import { useStore } from "utils/store";
import {
  CREATE_INDEX_ITEM,
  GET_CLEARANCE_BASISOFUSE,
  GET_CLEARANCE_CATEGORY,
  GET_CLEARANCE_SUBCATEGORY,
  GET_INDEX_ITEM_DETAILS,
  UPDATE_INDEX_ITEM,
} from "graphql/indexes";
import { IndexItemTypeArr } from "utils/constants";
import RadioButtonWithLabel from "common/RadioButtonWithLabel";
import TimerInput from "common/TimerInput";
import DatePicker from "common/DatePickerInput";
import {
  BlackActionContainedButton,
  NewRedAlertContainedButton,
} from "common/Buttons";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { IndexItemsStyles as localStyles } from "./styles";
import { ProjectDetailsStyles as styles } from "../styles";

interface IIndexItemFields {
  itemType: string;
  name: string;
  description: string;
  startDate: string;
  comments: string;
  clearance_category?: string;
  clearance_sub_category?: string;
  clearance_script_version?: string;
  clearance_script_page?: string;
  clearance_script_scene?: string;
  clearance_description?: string;
  clearance_timecode?: string;
  clearance_basis_of_use?: string;
  clearance_tech_spec?: string;
  clearance_credit_obligations?: string;
  clearance_credit_obligations_description?: string;
  clearance_ad_promo_restrictions?: string;
  clearance_issue?: string;
  clearance_comments?: string;
  clearance_legal_review?: string;
  clearance_checked?: string;
  clearance_screengrab?: any;
}

export interface IScreengrab {
  id: string;
  file: string;
  filename: string;
}

interface IAddEditIndexItemModalProps extends IModalProps {
  selectedIndexItem: string;
  setSelectedIndexItem: Dispatch<SetStateAction<string>>;
  refetchTableData: any;
  isClearanceIndex: boolean;
  nestedIdArr: string[];
  setDeleteModal: Dispatch<SetStateAction<boolean>>;
}

const AddEditIndexItemModal = (props: IAddEditIndexItemModalProps) => {
  const {
    open,
    setOpen,
    selectedIndexItem,
    setSelectedIndexItem,
    refetchTableData,
    isClearanceIndex,
    nestedIdArr,
    setDeleteModal,
  } = props;

  const { setLoading } = useStore();
  const params = useParams();

  const [initialValues, setInitialValues] = useState<IIndexItemFields>({
    name: "",
    description: "",
    itemType: "",
    startDate: "",
    comments: "",
    clearance_category: "",
    clearance_sub_category: "",
    clearance_script_version: "",
    clearance_script_page: "",
    clearance_script_scene: "",
    clearance_description: "",
    clearance_timecode: "",
    clearance_basis_of_use: "",
    clearance_tech_spec: "",
    clearance_credit_obligations: "",
    clearance_credit_obligations_description: "",
    clearance_ad_promo_restrictions: "",
    clearance_issue: "",
    clearance_comments: "",
    clearance_legal_review: "",
    clearance_checked: "",
    clearance_screengrab: [],
  });
  const [clearanceCategoriesArr, setClearanceCategoriesArr] = useState<IArr[]>(
    []
  );
  const [clearanceSubCategoriesArr, setClearanceSubCategoriesArr] = useState<
    IArr[]
  >([]);
  const [clearanceBasisOfUseArr, setClearanceBasisOfUseArr] = useState<IArr[]>(
    []
  );
  const [previosScreengrabs, setPreviosScreengrabs] = useState<IScreengrab[]>(
    []
  );
  const [deleteScreengrabIds, setDeleteScreengrabIds] = useState<string[]>([]);
  const [openScreengrabMenu, setOpenScreengrabMenu] = useState(false);
  const [screengrabMenuAnchor, setScreengrabMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleScreengrabMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setScreengrabMenuAnchor(e.currentTarget);
    setOpenScreengrabMenu(true);
  };

  const handleScreengrabMenuClose = () => {
    setScreengrabMenuAnchor(null);
    setOpenScreengrabMenu(false);
  };

  const { loading: gettingCategories } = useQuery(GET_CLEARANCE_CATEGORY, {
    variables: {},
    skip: !isClearanceIndex,
    onCompleted: (data) => {
      const { clearanceCategories } = data;
      if (Boolean(clearanceCategories)) {
        setClearanceCategoriesArr(clearanceCategories);
      } else {
        setClearanceCategoriesArr([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: gettingSubCategories } = useQuery(
    GET_CLEARANCE_SUBCATEGORY,
    {
      variables: {},
      skip: !isClearanceIndex,
      onCompleted: (data) => {
        const { clearanceSubCategories } = data;
        if (Boolean(clearanceSubCategories)) {
          setClearanceSubCategoriesArr(clearanceSubCategories);
        } else {
          setClearanceSubCategoriesArr([]);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const { loading: gettingBasisOfUse } = useQuery(GET_CLEARANCE_BASISOFUSE, {
    variables: {},
    skip: !isClearanceIndex,
    onCompleted: (data) => {
      const { clearanceBasisOfUse } = data;
      if (Boolean(clearanceBasisOfUse)) {
        setClearanceBasisOfUseArr(clearanceBasisOfUse);
      } else {
        setClearanceBasisOfUseArr([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setSelectedIndexItem("");
    setOpen(false);
  };

  const [getIndexItemDetails, { loading: gettingDetails }] = useLazyQuery(
    GET_INDEX_ITEM_DETAILS,
    {
      onCompleted: (data) => {
        // setLoading(false);
        const { getRegisterItem } = data;
        const { status, registerItem } = getRegisterItem;
        if (status) {
          const {
            name,
            description,
            itemType,
            comments,
            startDate,
            additionalDetails,
            clearanceScreengrabs,
          } = registerItem;
          setPreviosScreengrabs(
            Boolean(clearanceScreengrabs) ? clearanceScreengrabs : []
          );
          const parsedJSON = Boolean(additionalDetails)
            ? JSON.parse(additionalDetails)
            : null;
          setInitialValues({
            name,
            description,
            itemType,
            startDate: startDate || "",
            comments: comments || "",
            clearance_category: Boolean(parsedJSON)
              ? parsedJSON?.clearance_category
              : "",
            clearance_sub_category: Boolean(parsedJSON)
              ? parsedJSON?.clearance_sub_category
              : "",
            clearance_script_version: Boolean(parsedJSON)
              ? parsedJSON?.clearance_script_version
              : "",
            clearance_script_page: Boolean(parsedJSON)
              ? parsedJSON?.clearance_script_page
              : "",
            clearance_script_scene: Boolean(parsedJSON)
              ? parsedJSON?.clearance_script_scene
              : "",
            clearance_description: Boolean(parsedJSON)
              ? parsedJSON?.clearance_description
              : "",
            clearance_timecode: Boolean(parsedJSON)
              ? parsedJSON?.clearance_timecode
              : "",
            clearance_basis_of_use: Boolean(parsedJSON)
              ? parsedJSON?.clearance_basis_of_use
              : "",
            clearance_tech_spec: Boolean(parsedJSON)
              ? parsedJSON?.clearance_tech_spec
              : "",
            clearance_credit_obligations: Boolean(parsedJSON)
              ? parsedJSON?.clearance_credit_obligations
              : "",
            clearance_credit_obligations_description: Boolean(parsedJSON)
              ? parsedJSON?.clearance_credit_obligations_description
              : "",
            clearance_ad_promo_restrictions: Boolean(parsedJSON)
              ? parsedJSON?.clearance_ad_promo_restrictions
              : "",
            clearance_issue: Boolean(parsedJSON)
              ? parsedJSON?.clearance_issue
              : "",
            clearance_comments: Boolean(parsedJSON)
              ? parsedJSON?.clearance_comments
              : "",
            clearance_legal_review: Boolean(parsedJSON)
              ? parsedJSON?.clearance_legal_review
              : "",
            clearance_checked: Boolean(parsedJSON)
              ? parsedJSON?.clearance_checked
              : "",
            clearance_screengrab: [],
          });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireCreateIndexItemApi, { loading: adding }] = useMutation(
    CREATE_INDEX_ITEM,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { createRegisterItem } = data;
        const { status, message } = createRegisterItem;
        handleClose();
        if (status) {
          toast.success(message);
          refetchTableData();
        } else {
          toast.error(message);
        }
      },
    }
  );

  const [fireUpdateIndexItemApi, { loading: updating }] = useMutation(
    UPDATE_INDEX_ITEM,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateRegisterItem } = data;
        const { status, message } = updateRegisterItem;
        handleClose();
        if (status) {
          toast.success(message);
          refetchTableData();
        } else {
          toast.error(message);
        }
      },
    }
  );

  const handleUpsert = (values: IIndexItemFields) => {
    const {
      clearance_category,
      clearance_sub_category,
      clearance_script_version,
      clearance_script_page,
      clearance_script_scene,
      clearance_description,
      clearance_timecode,
      clearance_basis_of_use,
      clearance_tech_spec,
      clearance_credit_obligations,
      clearance_credit_obligations_description,
      clearance_ad_promo_restrictions,
      clearance_issue,
      clearance_comments,
      clearance_legal_review,
      clearance_checked,
      clearance_screengrab,
      startDate,
      ...rest
    } = values;
    const additionalDetailsJSON = {
      clearance_category,
      clearance_sub_category,
      clearance_script_version,
      clearance_script_page,
      clearance_script_scene,
      clearance_description,
      clearance_timecode,
      clearance_basis_of_use,
      clearance_tech_spec,
      clearance_credit_obligations,
      clearance_credit_obligations_description,
      clearance_ad_promo_restrictions,
      clearance_issue,
      clearance_comments,
      clearance_legal_review,
      clearance_checked,
    };
    if (Boolean(selectedIndexItem)) {
      fireUpdateIndexItemApi({
        variables: {
          payload: {
            projectId: params?.project_id,
            registerId: nestedIdArr[nestedIdArr.length - 1],
            registerItemId: selectedIndexItem,
            ...rest,
            ...(Boolean(startDate) && { startDate }),
            ...(isClearanceIndex && {
              additionalDetails: JSON.stringify(additionalDetailsJSON),
            }),
            ...(isClearanceIndex &&
              deleteScreengrabIds.length > 0 && {
                deleteScreengrabs: deleteScreengrabIds,
              }),
          },
          ...(Boolean(clearance_screengrab) &&
            clearance_screengrab.length > 0 && {
              clearance_screengrabs: clearance_screengrab,
            }),
        },
      });
    } else {
      fireCreateIndexItemApi({
        variables: {
          payload: {
            projectId: params?.project_id,
            registerId: nestedIdArr[nestedIdArr.length - 1],
            ...rest,
            ...(Boolean(startDate) && { startDate }),
            ...(isClearanceIndex && {
              additionalDetails: JSON.stringify(additionalDetailsJSON),
            }),
          },
          ...(Boolean(clearance_screengrab) &&
            clearance_screengrab.length > 0 && {
              clearance_screengrabs: clearance_screengrab,
            }),
        },
      });
    }
  };

  useEffect(() => {
    if (Boolean(selectedIndexItem)) {
      getIndexItemDetails({
        variables: {
          payload: {
            projectId: params?.project_id,
            registerId: nestedIdArr[nestedIdArr.length - 1],
            registerItemId: selectedIndexItem,
          },
        },
      });
    }
  }, [getIndexItemDetails, nestedIdArr, params?.project_id, selectedIndexItem]);

  useEffect(() => {
    setLoading(
      adding ||
        gettingBasisOfUse ||
        gettingCategories ||
        gettingSubCategories ||
        gettingDetails ||
        updating
    );
  }, [
    adding,

    gettingBasisOfUse,
    gettingCategories,
    gettingDetails,
    gettingSubCategories,
    setLoading,
    updating,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleUpsert}
      validationSchema={Yup.object().shape({
        itemType: Yup.string().required("Please select a item type"),
        name: Yup.string()
          .required("Please enter the index item name")
          .matches(/^[^/]*$/, "Item name cannot contain '/'"),
      })}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        setFieldTouched,
        isValid,
        handleSubmit,
      }) => {
        return (
          <Dialog
            open={open}
            onClose={handleDialogClose}
            fullWidth
            maxWidth={isClearanceIndex ? "md" : "sm"}
            PaperProps={{ sx: { borderRadius: "24px", overflow: "hidden" } }}
          >
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    py: 2,
                    backgroundColor: "#B6BCC325",
                    position: "relative",
                    px: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{
                        fontSize: "13px",
                        color: colors.primaryBlack,
                        fontWeight: 600,
                      }}
                    >
                      Document Item
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <IconButton size="small" onClick={handleClose}>
                    <Close sx={{ color: "#00000055" }} fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    pt: 4,
                    px: { xs: 2, sm: 6 },
                    pb: 3,
                    maxHeight: "calc(100vh - 120px)",
                    overflow: "auto",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        Item Name <span style={styles.error_text}>*</span>
                      </Typography>
                      <TextField
                        id="name"
                        size="small"
                        fullWidth
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ style: styles.input_field }}
                      />
                      {touched?.name && errors?.name && (
                        <Typography sx={styles.error_text}>
                          {errors?.name}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        Item Type <span style={styles.error_text}>*</span>
                      </Typography>
                      <Autocomplete
                        disabled={Boolean(selectedIndexItem)}
                        id="itemType"
                        value={
                          IndexItemTypeArr.find((x) =>
                            values.itemType.includes(x.id)
                          ) || { id: "", name: "" }
                        }
                        onChange={(_, newValue) =>
                          setFieldValue("itemType", newValue.id)
                        }
                        sx={styles.input_field}
                        size="small"
                        disableClearable
                        fullWidth
                        options={IndexItemTypeArr}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                        )}
                      />
                      {touched?.itemType && errors?.itemType && (
                        <Typography sx={styles.error_text}>
                          {errors?.itemType}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        Description
                      </Typography>
                      <TextField
                        id="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            ...styles.input_field,
                            backgroundColor: "transparent",
                          },
                        }}
                        multiline
                        sx={{
                          "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            backgroundColor: "#e6e6e640",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        {isClearanceIndex ? "On Camera Date" : "Start Date"}
                      </Typography>
                      <DatePicker
                        textValue={
                          Boolean(values?.startDate)
                            ? moment(values.startDate).format("DD MMMM YYYY")
                            : ""
                        }
                        value={
                          Boolean(values.startDate)
                            ? new Date(values.startDate)
                            : null
                        }
                        onChange={(newValue: any) => {
                          setFieldValue(
                            "startDate",
                            moment(newValue).format("YYYY-MM-DD")
                          );
                        }}
                        onOpen={undefined}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        {isClearanceIndex ? "On Camera Comments" : "Comments"}
                      </Typography>
                      <TextField
                        id="comments"
                        value={values.comments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            ...styles.input_field,
                            backgroundColor: "transparent",
                          },
                        }}
                        multiline
                        sx={{
                          "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            backgroundColor: "#e6e6e640",
                          },
                        }}
                      />
                    </Grid>
                    {Boolean(isClearanceIndex) && (
                      <Fragment>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Category
                          </Typography>
                          <Autocomplete
                            id="clearance_category"
                            value={
                              clearanceCategoriesArr.find(
                                (x) => x.id === values.clearance_category
                              ) || {
                                id: "",
                                name: "",
                              }
                            }
                            onChange={(_, newValue) =>
                              setFieldValue("clearance_category", newValue.id)
                            }
                            onBlur={handleBlur}
                            sx={styles.input_field}
                            size="small"
                            disableClearable
                            fullWidth
                            options={clearanceCategoriesArr}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  style: {
                                    ...styles.input_field,
                                    backgroundColor: "transparent",
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Sub-Category
                          </Typography>
                          <Autocomplete
                            id="clearance_sub_category"
                            value={
                              clearanceSubCategoriesArr.find(
                                (x) => x.id === values.clearance_sub_category
                              ) || {
                                id: "",
                                name: "",
                              }
                            }
                            onChange={(_, newValue) =>
                              setFieldValue(
                                "clearance_sub_category",
                                newValue.id
                              )
                            }
                            onBlur={handleBlur}
                            sx={styles.input_field}
                            size="small"
                            disableClearable
                            fullWidth
                            options={clearanceSubCategoriesArr}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  style: {
                                    ...styles.input_field,
                                    backgroundColor: "transparent",
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Script Version
                          </Typography>
                          <TextField
                            id="clearance_script_version"
                            value={values?.clearance_script_version}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            inputProps={{ style: styles.input_field }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Script Page
                          </Typography>
                          <TextField
                            id="clearance_script_page"
                            value={values?.clearance_script_page}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            inputProps={{ style: styles.input_field }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Script Scene
                          </Typography>
                          <TextField
                            id="clearance_script_scene"
                            value={values?.clearance_script_scene}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            inputProps={{ style: styles.input_field }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Timecode
                          </Typography>
                          <TimerInput
                            value={values?.clearance_timecode || ""}
                            onChange={(value) =>
                              setFieldValue("clearance_timecode", value)
                            }
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Description
                          </Typography>
                          <TextField
                            id="clearance_description"
                            value={values?.clearance_description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            inputProps={{
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                            multiline
                            sx={{
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                backgroundColor: "#e6e6e640",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Basis of Use
                          </Typography>
                          <Autocomplete
                            id="clearance_basis_of_use"
                            value={
                              clearanceBasisOfUseArr.find(
                                (x) => x.id === values.clearance_basis_of_use
                              ) || {
                                id: "",
                                name: "",
                              }
                            }
                            onChange={(_, newValue) =>
                              setFieldValue(
                                "clearance_basis_of_use",
                                newValue.id
                              )
                            }
                            onBlur={handleBlur}
                            sx={styles.input_field}
                            size="small"
                            disableClearable
                            fullWidth
                            options={clearanceBasisOfUseArr}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  style: {
                                    ...styles.input_field,
                                    backgroundColor: "transparent",
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Tech Spec
                          </Typography>
                          <TextField
                            id="clearance_tech_spec"
                            value={values?.clearance_tech_spec}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            inputProps={{
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                            multiline
                            sx={{
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                backgroundColor: "#e6e6e640",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Credit Obligations
                          </Typography>
                          <RadioGroup
                            row
                            value={values.clearance_credit_obligations}
                            onChange={(e) =>
                              setFieldValue(
                                "clearance_credit_obligations",
                                e.target.value
                              )
                            }
                          >
                            <RadioButtonWithLabel
                              value="TBC"
                              label="TBC"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                            <RadioButtonWithLabel
                              value="YES"
                              label="Yes"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                            <RadioButtonWithLabel
                              value="NO"
                              label="No"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Credit Obligations Description
                          </Typography>
                          <TextField
                            id="clearance_credit_obligations_description"
                            value={
                              values?.clearance_credit_obligations_description
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            inputProps={{
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                            multiline
                            sx={{
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                backgroundColor: "#e6e6e640",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Ad Promo Restrictions
                          </Typography>
                          <RadioGroup
                            row
                            value={values.clearance_ad_promo_restrictions}
                            onChange={(e) =>
                              setFieldValue(
                                "clearance_ad_promo_restrictions",
                                e.target.value
                              )
                            }
                          >
                            <RadioButtonWithLabel
                              value="TBC"
                              label="TBC"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                            <RadioButtonWithLabel
                              value="YES"
                              label="Yes"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                            <RadioButtonWithLabel
                              value="NO"
                              label="No"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Ad Promo Restrictions Comments
                          </Typography>
                          <TextField
                            id="clearance_issue"
                            value={values?.clearance_issue}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            inputProps={{
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                            multiline
                            sx={{
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                backgroundColor: "#e6e6e640",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Issues/Comments
                          </Typography>
                          <TextField
                            id="clearance_comments"
                            value={values?.clearance_comments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            fullWidth
                            inputProps={{
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                            multiline
                            sx={{
                              "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                backgroundColor: "#e6e6e640",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Legal Review
                          </Typography>
                          <RadioGroup
                            row
                            value={values.clearance_legal_review}
                            onChange={(e) =>
                              setFieldValue(
                                "clearance_legal_review",
                                e.target.value
                              )
                            }
                          >
                            <RadioButtonWithLabel
                              value="TBC"
                              label="TBC"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                            <RadioButtonWithLabel
                              value="YES"
                              label="Yes"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                            <RadioButtonWithLabel
                              value="NO"
                              label="No"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Typography sx={styles.field_label}>
                            Clearance Checked
                          </Typography>
                          <RadioGroup
                            row
                            value={values.clearance_checked}
                            onChange={(e) =>
                              setFieldValue("clearance_checked", e.target.value)
                            }
                          >
                            <RadioButtonWithLabel
                              value="TBC"
                              label="TBC"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                            <RadioButtonWithLabel
                              value="YES"
                              label="Yes"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                            <RadioButtonWithLabel
                              value="NO"
                              label="No"
                              labelStyle={styles.text_input}
                              size="small"
                              checkedIcon={
                                <RadioButtonChecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                              icon={
                                <RadioButtonUnchecked
                                  fontSize="small"
                                  sx={{ color: "#6f6f6f" }}
                                />
                              }
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Box sx={styles.flex_Acenter}>
                            <Typography sx={styles.field_label}>
                              Clearance Screengrab
                            </Typography>
                            {previosScreengrabs.length > 0 && (
                              <>
                                <IconButton
                                  sx={{ ml: 0.5, mb: 0.75 }}
                                  aria-controls={
                                    openScreengrabMenu
                                      ? "basic-menu"
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={
                                    openScreengrabMenu ? "true" : undefined
                                  }
                                  onClick={handleScreengrabMenuOpen}
                                  onMouseEnter={handleScreengrabMenuOpen}
                                >
                                  <img
                                    src={images.FILE_LINK_BTN_ICON}
                                    alt="attachment"
                                  />
                                </IconButton>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={screengrabMenuAnchor}
                                  open={openScreengrabMenu}
                                  onClose={handleScreengrabMenuClose}
                                  onMouseLeave={handleScreengrabMenuClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                  sx={styles.menu_container}
                                  elevation={0}
                                >
                                  {previosScreengrabs.map((sg, index) => (
                                    <MenuItem
                                      key={sg.id}
                                      onClick={() => {
                                        window.open(sg.file);
                                      }}
                                    >
                                      <Box
                                        sx={[
                                          styles.flex_JCsb_Acenter,
                                          { width: "100%" },
                                        ]}
                                      >
                                        <Typography
                                          sx={[
                                            styles.field_label,
                                            { color: colors.primary, mb: 0 },
                                          ]}
                                        >
                                          {sg?.filename ||
                                            `File Name not Available ${sg.id}`}
                                        </Typography>
                                        <IconButton
                                          size="small"
                                          sx={{ ml: 1 }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            let tempSG = [
                                              ...previosScreengrabs,
                                            ];
                                            tempSG = tempSG.filter(
                                              (x) => x.id !== sg.id
                                            );
                                            setPreviosScreengrabs([...tempSG]);
                                            let tempSGD = [
                                              ...deleteScreengrabIds,
                                            ];
                                            tempSGD.push(sg.id);
                                            setDeleteScreengrabIds([
                                              ...tempSGD,
                                            ]);
                                          }}
                                        >
                                          <DeleteOutlineOutlined
                                            fontSize="small"
                                            sx={{ color: "#FF1640" }}
                                          />
                                        </IconButton>
                                      </Box>
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </>
                            )}
                          </Box>
                          <Upload
                            beforeUpload={(_, files: RcFile[]) => {
                              if (Boolean(values.clearance_screengrab)) {
                                let tempFiles: RcFile[] = [
                                  ...values.clearance_screengrab,
                                ];
                                tempFiles = [...files, ...tempFiles];
                                setFieldValue(
                                  "clearance_screengrab",
                                  tempFiles
                                );
                              } else {
                                setFieldValue("clearance_screengrab", files);
                              }
                              return Promise.resolve();
                            }}
                            multiple
                            accept="image/*"
                          >
                            <Box sx={localStyles.upload_file_container}>
                              <img src={images.UPLOAD} alt="upload" />
                              <Typography sx={styles.upload_text}>
                                Drag & drop, or{" "}
                                <span style={{ color: colors.primary }}>
                                  browse
                                </span>{" "}
                                your files
                              </Typography>
                              {/* <Typography
                            sx={[
                              styles.upload_text,
                              { fontSize: "13px", color: colors.grey },
                            ]}
                          >
                            Supported clearance_screengrab type:{" "}
                            <span style={{ color: `${colors.primary}90` }}>
                              .PDF
                            </span>
                          </Typography> */}
                            </Box>
                          </Upload>
                        </Grid>
                        {Boolean(values.clearance_screengrab) &&
                          values.clearance_screengrab.map(
                            (cFile: RcFile, index: number) => (
                              <Grid
                                key={cFile.uid}
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                <Box
                                  sx={[
                                    styles.flex_JCsb_Acenter,
                                    localStyles.uploaded_file_container,
                                  ]}
                                >
                                  <Box sx={styles.flex_Acenter}>
                                    {/* <img src={images.PDF} alt="pdf" /> */}
                                    <Typography sx={styles.ml1}>
                                      {cFile ? (cFile as any)["name"] : ""}
                                    </Typography>
                                  </Box>
                                  <IconButton
                                    onClick={() => {
                                      let tempFiles = [
                                        ...values.clearance_screengrab,
                                      ];
                                      tempFiles.splice(index, 1);
                                      setFieldValue("clearance_screengrab", [
                                        ...tempFiles,
                                      ]);
                                    }}
                                  >
                                    <Cancel sx={{ color: "#FF1640" }} />
                                  </IconButton>
                                </Box>
                              </Grid>
                            )
                          )}
                      </Fragment>
                    )}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {!Boolean(selectedIndexItem) && (
                        <Box sx={styles.flex_JCfe}>
                          <BlackActionContainedButton
                            variant="contained"
                            sx={{
                              borderRadius: "20px",
                              ml: 1,
                              fontSize: "13px",
                              fontWeight: 600,
                              width: "120px",
                            }}
                            disableElevation
                            disableRipple
                            onClick={() => handleSubmit()}
                          >
                            Save
                          </BlackActionContainedButton>
                        </Box>
                      )}
                      {Boolean(selectedIndexItem) && (
                        <Box sx={[styles.flex_JCsb, { alignItems: "center" }]}>
                          <NewRedAlertContainedButton
                            sx={{
                              fontWeight: 600,
                              fontSize: "13px",
                              borderRadius: "20px",
                              width: "120px",
                            }}
                            onClick={() => {
                              setOpen(false);
                              setDeleteModal(true);
                            }}
                          >
                            Delete
                          </NewRedAlertContainedButton>
                          <BlackActionContainedButton
                            variant="contained"
                            sx={{
                              borderRadius: "20px",
                              ml: 1,
                              fontSize: "13px",
                              fontWeight: 600,
                              width: "120px",
                            }}
                            disableElevation
                            disableRipple
                            onClick={() => handleSubmit()}
                          >
                            Save
                          </BlackActionContainedButton>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default AddEditIndexItemModal;
