import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  AttachFile,
  Cancel,
  Close,
  FiberManualRecord,
} from "@mui/icons-material";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import {
  IssueStatusMapping,
  IssuePriorityArr,
  IssueRelatedToArr,
} from "utils/constants";
import {
  IIssue,
  IIssueHistory,
  IStatusHistory,
  IUpdateIssueFields,
} from "models/issues";
import {
  GET_ISSUE_DETAILS,
  GET_ISSUE_TYPES,
  GET_PROJECT_USERS,
  GIVE_REPLY_TO_ISSUE,
} from "graphql/issues";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import IssueHistoryItem from "./IssueHistoryItem";
import { IssueDashboardStyles as localStyles } from "./styles";
import { ProjectDetailsStyles as styles } from "../styles";
import { BlackActionContainedButton } from "common/Buttons";

interface IUArr {
  id: string;
  fullName: string;
}

interface IViewIssueModalProps extends IModalProps {
  selectedIssue: string;
  setSelectedIssue: Dispatch<SetStateAction<string>>;
  refetch: any;
  refetchMetrics: any;
}

function ViewIssueModal(props: IViewIssueModalProps) {
  const {
    open,
    setOpen,
    selectedIssue,
    setSelectedIssue,
    refetch: refetchTableData,
    refetchMetrics,
  } = props;

  const projectId = useParams();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const AllIssuesEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("ISSUES_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [historyView, setHistoryView] = useState(0);
  const [issueDetails, setIssueDetails] = useState<IIssue | null>(null);
  const [issueHistoryArr, setIssueHistoryArr] = useState<IIssueHistory[]>([]);
  const [statusHistoryArr, setStatusHistoryArr] = useState<IStatusHistory[]>(
    []
  );
  const [issueTypeArr, setIssueTypeArr] = useState<IArr[]>([]);
  const [assignedToArr, setAssignedToArr] = useState<IUArr[]>([]);

  const { loading: loadingTypes } = useQuery(GET_ISSUE_TYPES, {
    onCompleted: (data) => {
      const { issueTypes } = data;
      if (Boolean(issueTypes)) {
        setIssueTypeArr(issueTypes);
      } else {
        setIssueTypeArr([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: loadingUsers } = useQuery(GET_PROJECT_USERS, {
    variables: { project_id: projectId?.project_id },
    skip: !projectId?.project_id,
    onCompleted: (data) => {
      const { getProjectUsers } = data;
      const { status, projectUsers } = getProjectUsers;
      if (status) {
        setAssignedToArr(projectUsers);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: gettingDetails, refetch } = useQuery(GET_ISSUE_DETAILS, {
    variables: { project_id: projectId?.project_id, issue_id: selectedIssue },
    skip: !projectId?.project_id || !selectedIssue,
    onCompleted: (data) => {
      setLoading(false);
      const { issueDetails } = data;
      const { status, issueDetails: rawIssueDetails } = issueDetails;
      if (status) {
        const { issueHistory, statusHistory, ...rest } = rawIssueDetails;
        setIssueDetails(rest);
        setIssueHistoryArr(issueHistory);
        setStatusHistoryArr(
          statusHistory.map((x: any) => ({
            id: x.historyDate,
            ...x,
          }))
        );
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setSelectedIssue("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireReplyToIssueApi, { loading: replying }] = useMutation(
    GIVE_REPLY_TO_ISSUE,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { addIssueComment } = data;
        const { status, message } = addIssueComment;
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
          refetchTableData();
          refetchMetrics();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleSendReply = (
    values: IUpdateIssueFields,
    actions: FormikHelpers<IUpdateIssueFields>
  ) => {
    if (
      Boolean(ROLE_PERMISSIONS) &&
      AllIssuesEditIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]?.ISSUES_EDIT
    ) {
      const { files, ...rest } = values;
      fireReplyToIssueApi({
        variables: {
          payload: {
            projectId: projectId?.project_id,
            issueId: selectedIssue,
            ...rest,
          },
          ...(files.length > 0 && { documents: files }),
        },
      });
      actions.resetForm();
    } else {
      handleClose();
    }
  };

  const getDateTitle = (date: any) => {
    const today = moment();
    const newDate = moment(date);
    if (today.isSame(newDate, "date"))
      return `Today, ${moment(date).format("hh:mm a")}`;
    if (today.subtract(1, "day").isSame(newDate, "date"))
      return `Yesterday, ${moment(date).format("hh:mm a")}`;
    return newDate.format("DD MMMM YYYY, hh:mm a");
  };

  useEffect(() => {
    setLoading(gettingDetails || loadingTypes || loadingUsers || replying);
  }, [gettingDetails, loadingTypes, loadingUsers, replying, setLoading]);

  return (
    <Formik
      initialValues={{
        comments: "",
        assignedTo: issueDetails?.assignedTo?.id || "",
        files: [],
      }}
      onSubmit={handleSendReply}
      validationSchema={
        Boolean(ROLE_PERMISSIONS) &&
        AllIssuesEditIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]?.ISSUES_EDIT
          ? Yup.object().shape({
              comments: Yup.string().required("Please enter your reply"),
              assignedTo: Yup.string().required("Please select an assignee"),
            })
          : Yup.object().shape({})
      }
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: { borderRadius: "24px", overflow: "hidden" },
          }}
        >
          <Box
            sx={{
              py: 2,
              backgroundColor: "#B6BCC325",
              position: "relative",
              px: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  fontSize: "13px",
                  color: colors.primaryBlack,
                  fontWeight: 600,
                }}
              >
                Issue Details
              </Typography>
            </Box>
          </Box>
          <Box sx={{ position: "absolute", top: 10, right: 10 }}>
            <IconButton size="small" onClick={handleClose}>
              <Close sx={{ color: "#00000055" }} fontSize="small" />
            </IconButton>
          </Box>
          <Box
            sx={{
              pt: 3,
              px: 4,
              pb: 2,
              height: 600,
              overflow: "auto",
            }}
          >
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>Project Name</Typography>
                <TextField
                  value={issueDetails?.project?.name || ""}
                  size="small"
                  fullWidth
                  disabled
                  inputProps={{ style: styles.input_field }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>
                  Issue Related to
                </Typography>
                <Autocomplete
                  disabled
                  size="small"
                  fullWidth
                  value={
                    IssueRelatedToArr.find(
                      (x) => x.id === issueDetails?.issueRelatedTo
                    ) || { id: "", name: "" }
                  }
                  options={IssueRelatedToArr}
                  getOptionLabel={(opt) => opt.name}
                  sx={styles.input_field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          ...styles.input_field,
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>Issue Type</Typography>
                <Autocomplete
                  disabled
                  size="small"
                  fullWidth
                  value={
                    issueTypeArr.find(
                      (x) => x.id === issueDetails?.issueType?.id
                    ) || { id: "", name: "" }
                  }
                  options={issueTypeArr}
                  getOptionLabel={(opt) => opt.name}
                  sx={styles.input_field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          ...styles.input_field,
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>Raised by</Typography>
                <TextField
                  value={issueDetails?.createdBy?.fullName || ""}
                  size="small"
                  fullWidth
                  disabled
                  inputProps={{ style: styles.input_field }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>Assigned to</Typography>
                <Autocomplete
                  id="assignedTo"
                  size="small"
                  fullWidth
                  disabled={
                    !(
                      Boolean(ROLE_PERMISSIONS) &&
                      AllIssuesEditIndex !== undefined &&
                      ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]
                        ?.ISSUES_EDIT
                    )
                  }
                  value={
                    assignedToArr.find((x) => x.id === values.assignedTo) || {
                      id: "",
                      fullName: "",
                    }
                  }
                  disableClearable
                  onChange={(_, newValue) => {
                    setFieldValue("assignedTo", newValue.id);
                  }}
                  options={assignedToArr}
                  getOptionLabel={(opt) => opt.fullName}
                  onBlur={handleBlur}
                  sx={styles.input_field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          ...styles.input_field,
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  )}
                />
                {touched?.assignedTo && errors?.assignedTo && (
                  <Typography sx={styles.error_text}>
                    {errors?.assignedTo}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>Priority</Typography>
                <Autocomplete
                  size="small"
                  disabled
                  fullWidth
                  value={
                    IssuePriorityArr.find(
                      (x) => x.id === issueDetails?.priority
                    ) || { id: "", name: "" }
                  }
                  options={IssuePriorityArr}
                  getOptionLabel={(opt) => opt.name}
                  sx={styles.input_field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          ...styles.input_field,
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider sx={[styles.divider, { my: 1 }]} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#e6e6e670",
                      borderRadius: "20px",
                    }}
                  >
                    <Grid container>
                      <Grid item>
                        <Box
                          sx={{
                            ...(historyView === 0 && {
                              backgroundColor: "#fff",
                            }),
                            ...(historyView === 0 && {
                              border: "1px #D9D9D8 solid",
                            }),
                            borderRadius: { xs: "18px", sm: "20px" },
                            px: { xs: 1.5, sm: 3 },
                            py: 1,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setHistoryView(0);
                          }}
                        >
                          <Typography
                            sx={{
                              ...(historyView === 0 && {
                                fontWeight: 600,
                              }),
                              fontSize: { xs: "13px", sm: "14px" },
                              textAlign: "center",
                            }}
                          >
                            Issue History
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          sx={{
                            ...(historyView === 1 && {
                              backgroundColor: "#fff",
                            }),
                            ...(historyView === 1 && {
                              border: "1px #D9D9D8 solid",
                            }),
                            borderRadius: { xs: "18px", sm: "20px" },
                            px: { xs: 1.5, sm: 3 },
                            py: 1,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setHistoryView(1);
                          }}
                        >
                          <Typography
                            sx={{
                              ...(historyView === 1 && {
                                fontWeight: 600,
                              }),
                              fontSize: { xs: "13px", sm: "14px" },
                              textAlign: "center",
                            }}
                          >
                            Status History
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ width: "100%", mt: 3 }}>
                    {historyView === 0 && (
                      <>
                        <Grid container spacing={0.25}>
                          {issueHistoryArr.map((i, index) => (
                            <Fragment key={i.id}>
                              <IssueHistoryItem item={i} />
                              {index !== issueHistoryArr.length - 1 && (
                                <Divider />
                              )}
                            </Fragment>
                          ))}
                        </Grid>
                        {Boolean(ROLE_PERMISSIONS) &&
                          AllIssuesEditIndex !== undefined &&
                          ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]
                            ?.ISSUES_EDIT && (
                            <Box sx={{ mt: 1.5 }}>
                              <TextField
                                id="comments"
                                value={values.comments}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                minRows={3}
                                maxRows={3}
                                placeholder="Enter your reply..."
                                inputProps={{
                                  style: {
                                    ...styles.input_field,
                                    backgroundColor: "transparent",
                                  },
                                }}
                                multiline
                                sx={{
                                  "& .MuiInputBase-root.MuiOutlinedInput-root":
                                    {
                                      backgroundColor: "#e6e6e640",
                                    },
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Upload
                                        beforeUpload={(_, files: RcFile[]) => {
                                          if (values.files.length > 0) {
                                            let tempFiles: RcFile[] = [
                                              ...values.files,
                                            ];
                                            tempFiles = [
                                              ...files,
                                              ...tempFiles,
                                            ];
                                            setFieldValue("files", tempFiles);
                                          } else {
                                            setFieldValue("files", files);
                                          }
                                          return Promise.resolve();
                                        }}
                                        multiple
                                      >
                                        <IconButton>
                                          <AttachFile fontSize="small" />
                                        </IconButton>
                                      </Upload>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {touched?.comments && errors?.comments && (
                                <Typography sx={styles.error_text}>
                                  {errors?.comments}
                                </Typography>
                              )}
                            </Box>
                          )}
                        {Boolean(values.files) &&
                          values?.files!.map((file: RcFile, index: number) => (
                            <Box sx={{ mt: 1.5 }}>
                              <Box
                                sx={[
                                  styles.flex_JCsb_Acenter,
                                  localStyles.uploaded_file_container,
                                ]}
                              >
                                <Box sx={styles.flex_Acenter}>
                                  {/* <img src={images.PDF} alt="pdf" /> */}
                                  <Typography sx={styles.ml1}>
                                    {file ? (file as any)["name"] : ""}
                                  </Typography>
                                </Box>
                                <IconButton
                                  onClick={() => {
                                    let tempFiles = [...values.files];
                                    tempFiles.splice(index, 1);
                                    setFieldValue("files", [...tempFiles]);
                                  }}
                                >
                                  <Cancel sx={{ color: "#FF1640" }} />
                                </IconButton>
                              </Box>
                            </Box>
                          ))}
                      </>
                    )}
                    {historyView === 1 && (
                      <Box sx={styles.mt2}>
                        {statusHistoryArr.map((status: any, index: number) => (
                          <Box
                            key={status.id}
                            sx={[
                              localStyles.timeline_container,
                              {
                                borderLeft:
                                  index === statusHistoryArr.length - 1
                                    ? "3px dotted transparent"
                                    : `3px dotted #4F67ED50`,
                              },
                            ]}
                          >
                            <FiberManualRecord
                              sx={[
                                localStyles.timeline_dot,
                                {
                                  color: (IssueStatusMapping as any)[
                                    status.status
                                  ]?.color,
                                },
                              ]}
                            />
                            <Box>
                              <Typography sx={localStyles.timeline_status_text}>
                                {
                                  (IssueStatusMapping as any)[status.status]
                                    ?.name
                                }
                              </Typography>
                              <Typography sx={localStyles.timeline_time_text}>
                                {getDateTitle(status.historyDate)}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              {historyView === 0 && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <BlackActionContainedButton
                      variant="contained"
                      size="small"
                      sx={{
                        borderRadius: "20px",
                        fontSize: "13px",
                        fontWeight: 600,
                        width: "120px",
                      }}
                      disableElevation
                      disableRipple
                      disabled={
                        Boolean(ROLE_PERMISSIONS) &&
                        AllIssuesEditIndex !== undefined &&
                        ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]
                          ?.ISSUES_EDIT
                          ? !isValid
                          : false
                      }
                      onClick={() => handleSubmit()}
                    >
                      {Boolean(ROLE_PERMISSIONS) &&
                      AllIssuesEditIndex !== undefined &&
                      ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]
                        ?.ISSUES_EDIT
                        ? "Send Reply"
                        : "Close"}
                    </BlackActionContainedButton>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Dialog>
      )}
    </Formik>
  );
}

export default ViewIssueModal;
