import React, { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  ChevronRight,
  Download,
  FiberManualRecord,
} from "@mui/icons-material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import {
  ComplexityMapping,
  TemplateStatusArr,
  TemplateStatusMapping,
} from "utils/constants";
import { useTemplates } from "hooks/useTemplates";
import { ITemplate } from "models/templates";
import { DOWNLOAD_TEMPLATE_AS_PDF, UPDATE_TEMPLATE } from "graphql/templates";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import { ContractDocxStyles as styles } from "./styles";

interface IHeaderProps {
  templateData: ITemplate | null;
  refetch: any;
  initialTemplateDocContent: string;
  feeCalculatorAdded: boolean;
}

const Header = forwardRef((props: IHeaderProps, ref: any) => {
  const {
    templateData,
    refetch,
    initialTemplateDocContent,
    feeCalculatorAdded,
  } = props;

  const navigate = useNavigate();
  const { fillableFields, setFillableFields, tags, setTags } = useTemplates();
  const { setLoading } = useStore();

  const [complexity, setComplexity] = useState("3");
  const [selectedContractStatus, setSelectedContractStatus] = useState(11);
  const [openContractStatusMenu, setOpenContractStatusMenu] = useState(false);
  const [contractStatusAnchor, setContractStatusAnchor] =
    useState<HTMLElement | null>(null);

  const handleContractStatusClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    setOpenContractStatusMenu(true);
    setContractStatusAnchor(e.currentTarget);
  };

  const handleContractStatusClose = () => {
    setOpenContractStatusMenu(false);
    setContractStatusAnchor(null);
  };

  const [fireUpdateTemplateApi, { loading: updating }] = useMutation(
    UPDATE_TEMPLATE,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateContractTemplate } = data;
        const { status, message } = updateContractTemplate;
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
          // navigate(RouteNames.MANAGE_TEMPLATES);
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleSave = () => {
    if (Boolean(ref?.current?.dom.get("fillable-fields"))) {
      const element = ref?.current?.dom.get("fillable-fields")!;
      let tempFields = [...fillableFields];
      for (let i = 0; i < tempFields.length; i = i + 1) {
        const ele = ref?.current?.dom.get(tempFields[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
      if (Boolean(element.innerText) && tempFields.length === 0) {
        const sourceCodeFF = JSON.parse(element.innerText);
        tempFields = [...sourceCodeFF];
        setFillableFields([...tempFields]);
      }
      element.innerText = JSON.stringify(tempFields);
    } else {
      for (let i = 0; i < fillableFields.length; i = i + 1) {
        const ele = ref?.current?.dom.get(fillableFields[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
      ref?.current?.dom.add(
        ref?.current?.getBody(),
        "div",
        {
          style: "display:none;",
          id: "fillable-fields",
        },
        JSON.stringify(fillableFields)
      );
    }

    if (Boolean(ref?.current?.dom.get("finka-tags"))) {
      const element = ref?.current?.dom.get("finka-tags")!;
      let tempTags = [...tags];
      for (let i = 0; i < tempTags.length; i = i + 1) {
        const ele = ref?.current?.dom.get(tempTags[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
      if (Boolean(element.innerText) && tempTags.length === 0) {
        const sourceCodeT = JSON.parse(element.innerText);
        tempTags = [...sourceCodeT];
        setTags([...tempTags]);
      }
      element.innerText = JSON.stringify(tags);
    } else {
      for (let i = 0; i < tags.length; i = i + 1) {
        const ele = ref?.current?.dom.get(tags[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
      ref?.current?.dom.add(
        ref?.current?.getBody(),
        "div",
        {
          style: "display:none;",
          id: "finka-tags",
        },
        JSON.stringify(tags)
      );
    }

    if (Boolean(ref?.current?.dom?.get("fee-calculator"))) {
      const element = ref?.current?.dom.get("fee-calculator")!;
      element.innerText = JSON.stringify(feeCalculatorAdded);
    } else {
      ref?.current?.dom.add(
        ref?.current?.getBody(),
        "div",
        {
          style: "display:none;",
          id: "fee-calculator",
        },
        JSON.stringify(feeCalculatorAdded)
      );
    }

    const tables = ref.current.dom.select("table");
    const pageBreaks = ref.current.dom.select(".mce-pagebreak");

    for (let i = 0; i < tables.length; i += 1) {
      ref.current.dom.setStyle(tables[i], "page-break-inside", "avoid");
    }

    for (var i = 0; i < pageBreaks.length; i++) {
      var pageBreak = pageBreaks[i];
      var divWrapper = document.createElement("div");
      divWrapper.setAttribute("style", "page-break-before: always;");
      pageBreak.parentNode.insertBefore(divWrapper, pageBreak);
      divWrapper.appendChild(pageBreak);
    }

    const wrapDiv = ref.current.dom.select(".wrap_font");
    let afterWrappingFont = ref.current.getContent();
    if (wrapDiv.length === 0) {
      afterWrappingFont = `<div class="wrap_font" style="font-family:arial; font-size:10pt">${ref.current.getContent()}</div>`;
    }

    const blob = new Blob([afterWrappingFont], { type: "text/html" });
    const file = new File([blob], `${templateData?.name}.html`, {
      type: "text/html",
    });

    fireUpdateTemplateApi({
      variables: {
        payload: {
          id: templateData?.id,
          name: templateData?.name,
          templateStatus: selectedContractStatus,
          templateId: templateData?.templateId,
        },
        file: file,
      },
    });
  };

  const [fireDownloadTemplateApi, { loading: downloading }] = useLazyQuery(
    DOWNLOAD_TEMPLATE_AS_PDF,
    {
      onCompleted: (data) => {
        const { getContractTemplateAsHTML } = data;
        const { status, contractTemplate, message } = getContractTemplateAsHTML;
        if (status) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href = `data:application/pdf;base64,${contractTemplate}`;
          hiddenElement.target = "_blank";
          hiddenElement.download = `${templateData?.name}.pdf`;
          hiddenElement.click();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const handleDownload = () => {
    fireDownloadTemplateApi({ variables: { id: templateData?.id } });
  };

  useEffect(() => {
    if (Boolean(templateData)) {
      setSelectedContractStatus(templateData?.templateStatus!);
      const parsedJSON = Boolean(templateData?.additionalDetails)
        ? JSON.parse(templateData?.additionalDetails!)
        : null;
      if (Boolean(parsedJSON)) {
        setComplexity(parsedJSON.complexity);
      }
    }
  }, [templateData]);

  useEffect(() => {
    setLoading(downloading || updating);
  }, [downloading, setLoading, updating]);

  return (
    <Toolbar>
      <Box sx={styles.headerLeftContainer}>
        <IconButton
          edge="start"
          color="inherit"
          sx={styles.mr1}
          onClick={() => {
            if (ref.current.getContent() === initialTemplateDocContent) {
              navigate(-1);
            } else {
              if (
                window.confirm(
                  "ALERT!\n\nAre you sure you want to leave?\nAll unsaved changes will be lost."
                )
              ) {
                navigate(-1);
              }
            }
          }}
        >
          <ArrowBack sx={{ color: colors.iconColor }} />
        </IconButton>
        <Box sx={styles.mr1}>
          <Typography sx={styles.contract_name_text}>
            {templateData?.name}{" "}
            <span style={{ color: colors.grey, fontSize: "18px" }}>
              {templateData?.templateId ? `(${templateData?.templateId})` : ""}
            </span>
          </Typography>
        </Box>
        <img
          src={(ComplexityMapping as any)[complexity].flag}
          alt="complexity"
          style={styles.complexity_flag}
        />
      </Box>
      <Box sx={styles.headerRightContainer}>
        <Box sx={styles.contract_status_container}>
          <Box
            sx={[
              styles.contract_status_label_container,
              styles.flex_Acenter,
              {
                backgroundColor: (TemplateStatusMapping as any)[
                  selectedContractStatus
                ].color,
              },
            ]}
          >
            <Typography sx={styles.contract_status_label_text}>
              {(TemplateStatusMapping as any)[selectedContractStatus].name}
            </Typography>
          </Box>
          <Box
            sx={[
              styles.contract_stat_icon_container,
              styles.flex_Acenter,
              {
                backgroundColor: (TemplateStatusMapping as any)[
                  selectedContractStatus
                ].color,
              },
            ]}
          >
            <IconButton
              size="small"
              aria-controls={openContractStatusMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openContractStatusMenu ? "true" : undefined}
              onClick={handleContractStatusClick}
            >
              <ChevronRight fontSize="small" sx={{ color: colors.secondary }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={contractStatusAnchor}
              open={openContractStatusMenu}
              onClose={handleContractStatusClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={styles.menu_container}
              elevation={0}
            >
              <MenuList>
                {TemplateStatusArr.map((item) => (
                  <MenuItem
                    key={item.id}
                    onClick={() => {
                      setSelectedContractStatus(item.id);
                      fireUpdateTemplateApi({
                        variables: {
                          payload: {
                            id: templateData?.id,
                            name: templateData?.name,
                            templateStatus: item.id,
                            templateId: templateData?.templateId,
                          },
                        },
                      });
                      handleContractStatusClose();
                    }}
                  >
                    <ListItemIcon>
                      <FiberManualRecord
                        fontSize="small"
                        sx={{
                          color: (TemplateStatusMapping as any)[item.id].color,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText sx={styles.cnct_status_menu_text}>
                      {item.name}
                    </ListItemText>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>
        </Box>
        <Box sx={{ ml: 1 }}>
          <Button size="small" variant="outlined" onClick={handleSave}>
            Save
          </Button>
        </Box>
        <Box sx={{ ml: 1 }}>
          <IconButton onClick={handleDownload}>
            <Download color="primary" />
          </IconButton>
        </Box>
      </Box>
    </Toolbar>
  );
});

export default Header;
