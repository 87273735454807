import React, { useEffect, useMemo, useState } from "react";
import { Box, Chip, Grid, Link, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";

import Drawer from "common/AdminDrawer";
import SuccessModal from "common/SuccessModal";
import ErrorModal from "common/ErrorModal";
import { GlobalLabels } from "common/AppConstants";
import Table from "common/Table";
import { IPaginationModel } from "models/common";
import { useStore } from "utils/store";
import { GET_CLIENTS } from "graphql/clients";
import { IClient } from "models/clients";
import NoDataView from "common/NoDataView";
import NoResultsFoundView from "common/NoResultsFoundView";
import { colors } from "theme/colors";

import Filters from "./Filters";
import AddClientModal from "./AddClientModal";
import EditClientModal from "./EditClientModal";
import InformationModal from "./InformationModal";
import DeleteModal from "./DeleteModal";
import { ManageClientsStyles as styles } from "./styles";

export const ManageClients = () => {
  const { setLoading } = useStore();

  const [clients, setClients] = useState<IClient[]>([]);
  const [clientCount, setClientCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState<IPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [informationModal, setInformationModal] = useState(false);
  const [addClientModal, setAddClientModal] = useState(false);
  const [editClientModal, setEditClientModal] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchContactNameText, setSearchContactNameText] = useState("");
  const [sortBy, setSortBy] = useState("");

  const { loading, refetch } = useQuery(GET_CLIENTS, {
    variables: {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      filter: {
        ...(Boolean(searchText) && { name__icontains: searchText }),
        // ...(Boolean(searchContactNameText) && {
        //   user_set__full_name__icontains: searchContactNameText,
        // }),
      },
      ...(Boolean(sortBy) && { sorted: sortBy }),
    },
    onCompleted: (data) => {
      setLoading(false);
      const { getCompanies } = data;
      const { status, count, companies } = getCompanies;
      if (status) {
        setClients(companies);
        setClientCount(count);
      } else {
        setClients([]);
        setClientCount(0);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Company Name",
        sortable: false,
        minWidth: 220,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          return <Typography sx={styles.cell_text}>{params?.value}</Typography>;
        },
      },
      {
        field: "industry",
        headerName: "Industry",
        sortable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <Typography sx={styles.cell_text}>{params?.value?.name}</Typography>
          );
        },
      },
      {
        field: "state",
        headerName: "State",
        sortable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          return <Typography sx={styles.cell_text}>{params?.value}</Typography>;
        },
      },
      {
        field: "client.fullName",
        headerName: "Contact Name",
        sortable: false,
        minWidth: 220,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <Typography sx={styles.cell_text}>
              {params?.row?.client?.fullName}
            </Typography>
          );
        },
      },
      {
        field: "client.designation",
        headerName: "Designation",
        headerAlign: "center",
        sortable: false,
        minWidth: 180,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) =>
          Boolean(params?.row?.client?.designation) ? (
            <Chip label={params?.row?.client?.designation} sx={styles.chip} />
          ) : null,
      },
      {
        field: "client.email",
        headerName: "Email Id",
        sortable: false,
        minWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <Typography
              sx={[
                styles.cell_text,
                {
                  width: "100%",
                  wordBreak: "break-word",
                },
              ]}
            >
              {params?.row?.client?.email}
            </Typography>
          );
        },
      },
      {
        field: "information",
        headerName: "Client Details",
        headerAlign: "center",
        sortable: false,
        minWidth: 150,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Link
            component="button"
            sx={[styles.cell_text, { color: colors.primary }]}
            onClick={() => {
              setSelectedClient(params.row.id);
              setInformationModal(true);
            }}
          >
            {GlobalLabels.VIEW}
          </Link>
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        sortable: false,
        minWidth: 110,
        flex: 1,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            showInMenu
            label="Edit"
            onClick={() => {
              setSelectedClient(params.row.id);
              setEditClientModal(true);
            }}
            sx={styles.actions_text}
          />,
          // <GridActionsCellItem
          //   showInMenu
          //   label="Delete"
          //   onClick={() => {
          //     setSelectedClient(params.row.client.id);
          //     setSelectedClientName(params.row.client.fullName);
          //     setDeleteModal(true);
          //   }}
          //   sx={styles.actions_text}
          // />,
        ],
      },
    ],
    [setInformationModal]
  );

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Drawer>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={styles.filter_container}>
            <Filters
              setAddClientModal={setAddClientModal}
              setSearchText={setSearchText}
              setSearchContactNameText={setSearchContactNameText}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          </Box>
        </Grid>
        {!loading &&
          !searchText &&
          !searchContactNameText &&
          clientCount === 0 && (
            <NoDataView
              title="No Clients Found"
              subtitle="Please add a client"
            />
          )}
        {!loading &&
          (searchText || searchContactNameText) &&
          clientCount === 0 && <NoResultsFoundView />}
        {!loading && clientCount > 0 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Table
              rows={clients}
              columns={columns}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              disableColumnMenu
              rowCount={clientCount}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              sx={styles.table}
            />
          </Grid>
        )}
      </Grid>
      {informationModal && (
        <InformationModal
          open={informationModal}
          setOpen={setInformationModal}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
        />
      )}
      {addClientModal && (
        <AddClientModal
          open={addClientModal}
          setOpen={setAddClientModal}
          refetchTableData={refetch}
          setSuccessModal={setSuccessModal}
          setErrorModal={setErrorModal}
          setSuccessText={setSuccessText}
          setErrorText={setErrorText}
        />
      )}
      {editClientModal && (
        <EditClientModal
          open={editClientModal}
          setOpen={setEditClientModal}
          refetchTableData={refetch}
          setSuccessModal={setSuccessModal}
          setErrorModal={setErrorModal}
          setSuccessText={setSuccessText}
          setErrorText={setErrorText}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
        />
      )}
      {successModal && (
        <SuccessModal
          open={successModal}
          setOpen={setSuccessModal}
          successText={successText}
        />
      )}
      {errorModal && (
        <ErrorModal
          open={errorModal}
          setOpen={setErrorModal}
          errorText={errorText}
          tryAgainBtnClick={() => {
            setErrorText("");
          }}
        />
      )}
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          setOpen={setDeleteModal}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          selectedClientName={selectedClientName}
          setSelectedClientName={setSelectedClientName}
          refetchTableData={refetch}
        />
      )}
    </Drawer>
  );
};
