import { colors } from "theme/colors";

export const ContractDocxStyles = {
  headerLeftContainer: { display: "flex", alignItems: "center", flexGrow: 1 },
  mr1: { mr: 1 },
  mt1: { mt: 1 },
  contract_name_text: {
    fontSize: "22px",
    fontWeight: 500,
    color: colors.primaryBlack,
  },
  complexity_flag: { marginRight: 20, marginLeft: 10 },
  chip: { color: colors.primary, backgroundColor: `${colors.primary}20` },
  headerRightContainer: { display: "flex", alignItems: "center" },
  avatar: {
    "& .MuiAvatar-root": {
      width: "32px",
      height: "32px",
      backgroundColor: "transparent",
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      mr: 0.35,
      fontSize: "11px",
    },
  },
  contract_status_container: { display: "flex" },
  contract_status_label_container: {
    px: 1.5,
    height: "30px",
    borderRadius: "20px",
  },
  contract_stat_icon_container: {
    height: "30px",
    borderRadius: "0px 6px 6px 0px",
    ml: 0.5,
  },
  contract_status_label_text: {
    color: colors.secondary,
    fontSize: "13px",
    fontWeight: 500,
  },
  menu_container: {
    "& .MuiMenu-paper": { border: `1px solid ${colors.borderColor}` },
  },
  flex_Acenter: { display: "flex", alignItems: "center" },
  cnct_status_menu_text: {
    color: colors.grey,
    fontSize: "14px",
    fontWeight: 500,
  },
  action_menu_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  drawer_container: { p: 2 },
  drawer_header_text: {
    fontSize: "20px",
    fontWeight: 500,
    color: colors.primaryBlack,
  },
  field_label: {
    color: colors.grey,
    fontWeight: 500,
    fontSize: "13px",
  },
  text_input: { fontSize: "14px", color: colors.primaryBlack },
  accordion_container: {
    borderTop: `1px solid ${colors.borderColor}`,
    borderBottom: `1px solid ${colors.borderColor}`,
    mt: 2,
  },
  modal_header_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  flex_JCsb_Acenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modal_btn: { fontSize: "14px", fontWeight: 500 },
  ml1: { ml: 1 },
  right_drawer_direction: { justifyContent: "flex-end" },
  template_name_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.primaryBlack,
    mb: 0.5,
  },
  cnct_info_label_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.grey,
  },
  cnct_info_value_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.primary,
  },
  text_wrap: { whiteSpace: "normal", wordBreak: "break-word" },
  mt2: { mt: 2 },
  edit_cnct_btn: { position: "absolute", bottom: 20, width: "300px" },
  timeline_container: {
    pl: 2,
    pb: 4,
    position: "relative",
    ml: 2,
  },
  timeline_dot: {
    position: "absolute",
    left: -13,
    top: -5,
  },
  timeline_status_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.primaryBlack,
  },
  timeline_status_desc_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: colors.primaryBlack,
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
  timeline_time_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: "#5B5C60",
  },
  member_name_text: { fontSize: "16px", color: colors.primaryBlack },
  member_email_text: { fontSize: "13px", color: "#6F7074" },
  resctriction_text: {
    fontSIze: "16px",
    fontWeight: 500,
    color: colors.primaryBlack,
  },
  flex_JCfe: { display: "flex", justifyContent: "flex-end" },
  flex_JCsb_Afe: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  flex: { display: "flex" },
  timeline_avatar: { width: "36px", height: "36px", mr: 1 },
  timeline_name_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.primary,
  },
  timeline_activity_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: colors.whiteGrey500,
  },
  overflow_text: {
    width: "100%",
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
  error_text: { fontSize: "12px", color: "#D3010E" },
  disabled_btn: {
    "&.Mui-disabled": {
      backgroundColor: colors.grey,
      color: "#c0c0c0",
    },
  },
  modal_footer_container: { p: 2, backgroundColor: `${colors.primary}20` },
};
