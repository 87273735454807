import { create } from "zustand";

interface IViewContactModal {
  open: boolean;
  onOpen: (contactId: string) => void;
  onClose: () => void;
  contactId: string;
}

export const useViewContactInfoModal = create<IViewContactModal>((set) => ({
  open: false,
  onOpen: (contactId) => set({ open: true, contactId: contactId }),
  onClose: () => set({ open: false, contactId: "" }),
  contactId: "",
}));
