import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";

import { IArr, IModalProps } from "models/common";
import { useStore } from "utils/store";
import { GET_ROLE_DETAILS, UPDATE_ROLE } from "graphql/roles";
import { GET_TEMPLATE_TYPES } from "graphql/templates";

import RolesList from "./RolesList";
import {
  IRoleFields,
  checkIfAnyIssueChecked,
  checkIfAnyTempTypeChecked,
  initialRoleValues,
} from "./utils";
import { AdminUsersStyles as styles } from "./styles";

interface IEditRoleModalProps extends IModalProps {
  selectedRole: string;
  setSelectedRole: Dispatch<SetStateAction<string>>;
  refetchTableData: any;
  setSuccessModal: Dispatch<SetStateAction<boolean>>;
  setErrorModal: Dispatch<SetStateAction<boolean>>;
  setSuccessText: Dispatch<SetStateAction<string>>;
  setErrorText: Dispatch<SetStateAction<string>>;
}

function EditRoleModal(props: IEditRoleModalProps) {
  const {
    open,
    setOpen,
    selectedRole,
    setSelectedRole,
    refetchTableData,
    setSuccessModal,
    setErrorModal,
    setSuccessText,
    setErrorText,
  } = props;

  const { setLoading } = useStore();

  const [templateTypeArr, setTemplateTypeArr] = useState<IArr[]>([]);

  const {} = useQuery(GET_TEMPLATE_TYPES, {
    variables: {},
    onCompleted: (data) => {
      const { templateTypes } = data;
      const { status, templateTypes: rawTemplateTypes } = templateTypes;
      if (status) {
        setTemplateTypeArr(rawTemplateTypes);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [initialValues, setInitialValues] = useState<IRoleFields>(
    initialRoleValues(templateTypeArr)
  );

  const { loading } = useQuery(GET_ROLE_DETAILS, {
    variables: { id: selectedRole },
    skip: !selectedRole,
    onCompleted: (data) => {
      const { role } = data;
      const { status, role: rawRole } = role;
      if (status) {
        const { name, rolePermission } = rawRole;
        const permissionJSON = Boolean(rolePermission)
          ? JSON.parse(rolePermission)
          : null;
        setInitialValues({
          name,
          permission:
            Boolean(permissionJSON) && Boolean(permissionJSON?.permissions)
              ? permissionJSON?.permissions
              : initialRoleValues(templateTypeArr).permission,
          folder_restrictions:
            Boolean(permissionJSON) &&
            Boolean(permissionJSON?.folder_restrictions)
              ? permissionJSON?.folder_restrictions
              : initialRoleValues(templateTypeArr).folder_restrictions,
          my_issues_restrictions:
            Boolean(permissionJSON) &&
            Boolean(permissionJSON?.my_issues_restrictions)
              ? permissionJSON?.my_issues_restrictions
              : initialRoleValues(templateTypeArr).my_issues_restrictions,
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => setOpen(false);

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireUpdateRoleApi, { loading: adding }] = useMutation(UPDATE_ROLE, {
    onCompleted: (data) => {
      setLoading(false);
      const { updateRole } = data;
      const { status, message } = updateRole;
      handleClose();
      setSelectedRole("");
      if (status) {
        setSuccessText(message);
        setSuccessModal(true);
        refetchTableData();
      } else {
        setErrorText(message);
        setErrorModal(true);
      }
    },
  });

  const handleUpdateRole = (values: IRoleFields) => {
    const { name, permission, folder_restrictions, my_issues_restrictions } =
      values;

    let permissionPayload = [...permission];
    const foldersViewIndex = permissionPayload.findIndex((x: any) =>
      x.hasOwnProperty("FOLDERS_VIEW")
    );
    const foldersEditIndex = permissionPayload.findIndex((x: any) =>
      x.hasOwnProperty("FOLDERS_EDIT")
    );
    const issuesViewIndex = permissionPayload.findIndex((x: any) =>
      x.hasOwnProperty("ISSUES_VIEW")
    );
    const issuesEditIndex = permissionPayload.findIndex((x: any) =>
      x.hasOwnProperty("ISSUES_EDIT")
    );

    permissionPayload[foldersViewIndex] = {
      FOLDERS_VIEW: Boolean(folder_restrictions)
        ? checkIfAnyTempTypeChecked(folder_restrictions, "view")
        : false,
    };
    permissionPayload[foldersEditIndex] = {
      FOLDERS_EDIT: Boolean(folder_restrictions)
        ? checkIfAnyTempTypeChecked(folder_restrictions, "edit")
        : false,
    };
    permissionPayload[issuesViewIndex] = {
      ISSUES_VIEW: Boolean(my_issues_restrictions)
        ? checkIfAnyIssueChecked(my_issues_restrictions, "view")
        : false,
    };
    permissionPayload[issuesEditIndex] = {
      ISSUES_EDIT: Boolean(my_issues_restrictions)
        ? checkIfAnyIssueChecked(my_issues_restrictions, "edit")
        : false,
    };

    const permissionJSON = {
      permissions: [...permissionPayload],
      ...(Boolean(folder_restrictions) &&
        (permissionPayload[foldersViewIndex]?.FOLDERS_VIEW ||
          permissionPayload[foldersEditIndex]?.FOLDERS_EDIT) && {
          folder_restrictions,
        }),
      ...(Boolean(my_issues_restrictions) &&
        (permissionPayload[issuesViewIndex]?.ISSUES_VIEW ||
          permissionPayload[issuesEditIndex]?.ISSUES_EDIT) && {
          my_issues_restrictions,
        }),
    };
    fireUpdateRoleApi({
      variables: {
        payload: {
          id: selectedRole,
          name,
          permissions: JSON.stringify(permissionJSON),
        },
      },
    });
  };

  useEffect(() => {
    setLoading(adding || loading);
  }, [adding, loading, setLoading]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleUpdateRole}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Please enter the name of the role"),
      })}
      enableReinitialize
    >
      {(formikBag) => (
        <Dialog open={open} onClose={handleDialogClose} maxWidth="md" fullWidth>
          <DialogTitle sx={styles.flex_JCsb_Acenter}>
            <Typography sx={styles.add_admin_text}>Edit Role</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography sx={styles.field_label}>Role Name</Typography>
                <TextField
                  id="name"
                  value={formikBag.values.name}
                  onChange={formikBag.handleChange}
                  onBlur={formikBag.handleBlur}
                  size="small"
                  fullWidth
                  inputProps={{ style: styles.input_field }}
                />
                {formikBag.touched?.name && formikBag.errors?.name && (
                  <Typography sx={styles.error_text}>
                    {formikBag.errors?.name}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    border: "1px #e6e6e6 solid",
                    borderRadius: "8px",
                    p: 2,
                  }}
                >
                  <RolesList formikBag={formikBag} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={styles.modal_footer_container}>
            <Button
              variant="contained"
              sx={{ width: "120px" }}
              onClick={() => formikBag.handleSubmit()}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

export default EditRoleModal;
