import { colors } from "theme/colors";

export const ReportStyles = {
  tab_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.secondaryBlack,
    "&.Mui-selected": {
      fontWeight: 600,
    },
  },
  filters_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    px: 2.5,
    py: 3.5,
  },
  text_input: { fontSize: "14px", color: colors.primaryBlack },
  cell_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
  },
  flex_Acenter: {
    display: "flex",
    alignItems: "center",
  },
  action_text: {
    color: colors.secondaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  chip_variant: { fontWeight: 500, fontSize: "13px" },
  flex_ACenter: { display: "flex", alignItems: "center" },
  table_avatar: {
    height: "32px",
    width: "32px",
    backgroundColor: `${colors.primary}20`,
    mr: 1,
  },
  wrap: { width: "100%", wordBreak: "break-word" },
  table: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      borderRadius: "10px",
      backgroundColor: "#B6BCC320",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: `${colors.primaryBlack}70`,
      fontSize: "13px",
      fontWeight: 600,
      // textTransform: "uppercase",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#b6bcc330",
      borderRadius: "0px",
    },
  },
};
