import { gql } from "@apollo/client";

export const GET_COUNTRIES = gql`
  query countries {
    countries {
      id
      name
    }
  }
`;

export const GET_STATES_BY_COUNTRY_ID = gql`
  query states($country: String!) {
    states(country: $country) {
      id
      name
    }
  }
`;

export const GET_CITIES_BY_STATE_ID = gql`
  query cities($state: String!) {
    cities(state: $state) {
      id
      name
    }
  }
`;

export const GET_RESOLVERS = gql`
  query getConfig {
    config(key: "CONTRACT_MASTER_DATA_MAPPING") {
      status
      message
      config {
        key
        value
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries {
    industries {
      id
      name
    }
  }
`;

export const GET_ARTIST_TYPE_ARR = gql`
  query getArtistTypes {
    artistTypes {
      status
      message
      artistTypes {
        id
        name
      }
    }
  }
`;

export const GET_ARTIST_CLASS_ARR = gql`
  query artistClass($filter: ArtistClassFilterInput!) {
    artistClass(filter: $filter) {
      status
      message
      artistClass {
        name
      }
    }
  }
`;

export const GET_ADDRESS_AUTOCOMPLETE = gql`
  query autocompleteAddress($filter: String) {
    autocompleteAddress(filter: $filter) {
      streetName
      streetNumber
      municipality
      municipalitySubdivision
      country
      countrySubdivision
      freeformAddress
      postalCode
    }
  }
`;

export const GET_CONTACT_DEPARTMENT = gql`
  query departments {
    departments {
      status
      message
      departments {
        id
        name
      }
    }
  }
`;
