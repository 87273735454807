import React, { Dispatch, SetStateAction, forwardRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowDropDown, ChevronLeft, ChevronRight } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import moment from "moment";

import { colors } from "theme/colors";

import "react-datepicker/dist/react-datepicker.css";
import "./CustomDateRangePicker.css";

interface IDateRangePickerProps {
  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
}

function DateRangePicker(props: IDateRangePickerProps) {
  const { startDate, setStartDate, endDate, setEndDate } = props;

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <Box
      sx={{
        cursor: "pointer",
        width: "120px",
        border: `1px ${colors.primaryBlack}30 solid`,
        borderRadius: "24px",
        py: 0.75,
        pl: 2,
        pr: 1,
        ":hover": {
          borderColor: colors.primaryBlack,
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onClick={onClick}
    >
      <Typography sx={{ fontSize: "14px", color: "#aaaaaa" }}>
        Period
      </Typography>
      <ArrowDropDown sx={{ color: "#757575" }} />
    </Box>
  ));

  return (
    <DatePicker
      onChange={onChange}
      customInput={<CustomInput />}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      shouldCloseOnSelect={false}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pl: 2,
            pr: 1,
          }}
        >
          <Box>
            <Typography
              sx={{ fontSize: "14px", color: "#00000090", fontWeight: 600 }}
            >
              {moment(date).format("MMMM YYYY")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton size="small" onClick={decreaseMonth}>
              <ChevronLeft fontSize="small" />
            </IconButton>
            <IconButton size="small" sx={{ ml: 0.5 }} onClick={increaseMonth}>
              <ChevronRight fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      )}
    />
  );
}

export default DateRangePicker;
