import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import { GET_EMIALS_ARR, SEND_CONTRACT } from "graphql/contracts";
import { BlackActionContainedButton } from "common/Buttons";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface ISendEmail {
  toEmails: string[];
  ccEmails: string[];
  subject: string;
  body: string;
}

interface IEmailArr extends IArr {
  emailId: string;
}

interface ISendDocumentModalProps extends IModalProps {
  setSelectedIndexItem: Dispatch<SetStateAction<string>>;
  reUploadDocId: string;
  setReuploadDocId: Dispatch<SetStateAction<string>>;
}

function SendDocumentModal(props: ISendDocumentModalProps) {
  const {
    open,
    setOpen,
    setSelectedIndexItem,
    reUploadDocId,
    setReuploadDocId,
  } = props;

  const { setLoading } = useStore();
  const params = useParams();

  const [emailArr, setEmailArr] = useState<IEmailArr[]>([]);

  const handleClose = () => {
    setSelectedIndexItem("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const {} = useQuery(GET_EMIALS_ARR, {
    variables: {
      project_id: params?.project_id,
    },
    skip: !params?.project_id,
    onCompleted: (data) => {
      const { getUsersForShareDocument } = data;
      const { status, users } = getUsersForShareDocument;
      if (status) {
        setEmailArr(
          users.map((x: any) => ({
            id: x.id,
            name: x.name,
            emailId: x.email,
          }))
        );
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireSendDocumentApi, { loading: sending }] = useMutation(
    SEND_CONTRACT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { shareDocument } = data;
        const { status, message } = shareDocument;
        handleClose();
        if (status) {
          setReuploadDocId("");
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const getOneError = (errors: any) => {
    const index = errors.findIndex((x: any) => Boolean(x));
    return errors[index];
  };

  useEffect(() => {
    setLoading(sending);
  }, [sending, setLoading]);

  return (
    <Formik
      initialValues={{
        toEmails: [],
        ccEmails: [],
        subject: "",
        body: "",
      }}
      onSubmit={(values: ISendEmail) => {
        const { ccEmails, body, ...rest } = values;
        fireSendDocumentApi({
          variables: {
            payload: {
              documentId: reUploadDocId,
              projectId: params?.project_id,
              ...rest,
              ...(ccEmails.length > 0 && { ccEmails }),
              ...(Boolean(body) && { body }),
            },
          },
        });
      }}
      validationSchema={Yup.object().shape({
        subject: Yup.string().required("Please mention the subject"),
        toEmails: Yup.array()
          .of(Yup.string().email("Please enter valid email"))
          .min(1, "Please provide atleast one recepient email"),
        ccEmails: Yup.array()
          .of(Yup.string().email("Please enter valid email"))
          .when("toEmails", (toEmails, schema) => {
            return schema.test({
              name: "no-duplicate-emails",
              message: "CC emails must not contain emails already in To emails",
              test: (ccEmails) => {
                const intersection = ccEmails!.filter((email) =>
                  toEmails[0].includes(email)
                );
                return intersection.length === 0;
              },
            });
          }),
        // .test(
        //   'no-duplicate-emails',
        //   'CC emails must not contain emails already in To emails',
        //   (val) => {
        //     const { toEmails } = this.parent;
        //     const intersection = val!.filter(email => toEmails.includes(email));
        //     return intersection.length === 0;
        //   }
        // ),
      })}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullWidth
          maxWidth={"sm"}
          PaperProps={{ sx: { borderRadius: "24px" } }}
        >
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  py: 2,
                  backgroundColor: "#B6BCC325",
                  position: "relative",
                  px: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      fontSize: "13px",
                      color: colors.primaryBlack,
                      fontWeight: 600,
                    }}
                  >
                    Send Email
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                <IconButton size="small" onClick={handleClose}>
                  <Close sx={{ color: "#00000055" }} fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ pt: 4, px: 6, pb: 3 }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item lg={2} md={2} sm={3} xs={3}>
                    <Typography sx={styles.field_label}>
                      To <span style={styles.error_text}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item lg={10} md={10} sm={9} xs={9}>
                    <Autocomplete
                      multiple
                      freeSolo
                      size="small"
                      options={emailArr}
                      value={values.toEmails}
                      onChange={(_, newValues) => {
                        setFieldValue(
                          "toEmails",
                          newValues.map((x) =>
                            typeof x === "string" ? x : x.emailId
                          )
                        );
                      }}
                      getOptionLabel={(opt) =>
                        typeof opt === "string" ? opt : opt.emailId
                      }
                      getOptionDisabled={(option) =>
                        values.ccEmails.some((x) => x === option.emailId)
                      }
                      sx={styles.input_field}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              ...styles.input_field,
                              backgroundColor: "transparent",
                            },
                          }}
                        />
                      )}
                      renderOption={(props1, opt, _, __) => (
                        <li
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          {...props1}
                        >
                          <Typography textAlign="center">{opt.name}</Typography>
                          <Typography
                            sx={[styles.field_label, { fontSize: "13px" }]}
                          >{`(${opt.emailId})`}</Typography>
                        </li>
                      )}
                    />
                    {Boolean(errors?.toEmails) &&
                    typeof errors?.toEmails === "string" ? (
                      <Typography sx={styles.error_text}>
                        {errors?.toEmails as string}
                      </Typography>
                    ) : errors?.toEmails && errors.toEmails.length > 0 ? (
                      <Typography sx={styles.error_text}>
                        {getOneError(errors?.toEmails)}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item lg={2} md={2} sm={3} xs={3} sx={{ mt: 2 }}>
                    <Typography sx={styles.field_label}>Cc</Typography>
                  </Grid>
                  <Grid item lg={10} md={10} sm={9} xs={9} sx={{ mt: 2 }}>
                    <Autocomplete
                      multiple
                      freeSolo
                      size="small"
                      options={emailArr}
                      value={values.ccEmails}
                      onChange={(_, newValues) => {
                        setFieldValue(
                          "ccEmails",
                          newValues.map((x) =>
                            typeof x === "string" ? x : x.emailId
                          )
                        );
                      }}
                      getOptionLabel={(opt) =>
                        typeof opt === "string" ? opt : opt.emailId
                      }
                      getOptionDisabled={(option) =>
                        values.toEmails.some((x) => x === option.emailId)
                      }
                      sx={styles.input_field}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              ...styles.input_field,
                              backgroundColor: "transparent",
                            },
                          }}
                        />
                      )}
                      renderOption={(props1, opt, _, __) => (
                        <li
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                          {...props1}
                        >
                          <Typography textAlign="center">{opt.name}</Typography>
                          <Typography
                            sx={[styles.field_label, { fontSize: "13px" }]}
                          >{`(${opt.emailId})`}</Typography>
                        </li>
                      )}
                    />
                    {Boolean(errors?.ccEmails) &&
                    typeof errors?.ccEmails === "string" ? (
                      <Typography sx={styles.error_text}>
                        {errors?.ccEmails as string}
                      </Typography>
                    ) : errors?.ccEmails && errors.ccEmails.length > 0 ? (
                      <Typography sx={styles.error_text}>
                        {getOneError(errors?.ccEmails)}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item lg={2} md={2} sm={3} xs={3} sx={{ mt: 2 }}>
                    <Typography sx={styles.field_label}>
                      Subject <span style={styles.error_text}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item lg={10} md={10} sm={9} xs={9} sx={{ mt: 2 }}>
                    <TextField
                      id="subject"
                      size="small"
                      fullWidth
                      value={values.subject}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.subject && errors?.subject && (
                      <Typography sx={styles.error_text}>
                        {errors?.subject}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                    <TextField
                      id="body"
                      value={values.body}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      size="small"
                      minRows={5}
                      inputProps={{
                        style: {
                          ...styles.input_field,
                          backgroundColor: "transparent",
                        },
                      }}
                      multiline
                      sx={{
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          backgroundColor: "#e6e6e640",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                    <Box sx={styles.flex_JCfe}>
                      <BlackActionContainedButton
                        size="small"
                        variant="contained"
                        sx={{
                          borderRadius: "20px",
                          ml: 1,
                          fontSize: "13px",
                          fontWeight: 600,
                          width: "120px",
                        }}
                        disableElevation
                        disableRipple
                        onClick={() => handleSubmit()}
                      >
                        Send
                      </BlackActionContainedButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Formik>
  );
}

export default SendDocumentModal;
