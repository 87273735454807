import * as Yup from "yup";

export interface IFeeCalculatorFields {
  artistType: string;
  employmentStatus: string;
  engagementStatus: string;
  engagementStatusUnits: number;
  castClass: string;
  ageStatus: string;
  hrsWorked: string;
  rateType: string;
  feeAsk: number;
}

export interface IFeeCalculatorFieldsAlone {
  artistType: string;
  employmentStatus: string;
  engagementStatus: string;
  engagementStatusUnits: number | string;
  castClass: string;
  ageStatus: string;
  hrsWorked: string;
  rateType: string;
  feeAsk: number | string;
}

export const feeCalculatorInitialFields = {
  artistType: "Cast",
  employmentStatus: "PAYG",
  engagementStatus: "",
  engagementStatusUnits: 1,
  castClass: "",
  ageStatus: "Adult",
  hrsWorked: "ORDINARY TIME",
  rateType: "AWARD RATE",
  feeAsk: 0,
};

export const feeCalculatorInitialFieldsAlone = {
  artistType: "Cast",
  employmentStatus: "PAYG",
  engagementStatus: "",
  engagementStatusUnits: "",
  castClass: "",
  ageStatus: "",
  hrsWorked: "",
  rateType: "",
  feeAsk: "",
};

export const feeCalculatorValidationSchema = Yup.object().shape({
  artistType: Yup.string().required("Please select an artist type"),
  engagementStatus: Yup.string().required(
    "Please select the engagement status"
  ),
  engagementStatusUnits: Yup.number()
    .required("Please enter the engagement status units")
    .typeError("Please enter the engagement status units")
    .min(1, "Must be greater or equal to 1"),
  employmentStatus: Yup.string().when("artistType", ([artistType], schema) => {
    return artistType === "Cast" || artistType === "Crew"
      ? schema.required("Please select an employment status")
      : schema;
  }),
  castClass: Yup.string().when("artistType", ([artistType], schema) => {
    return artistType === "Cast" || artistType === "Crew"
      ? schema.required("Please select a artist class")
      : schema;
  }),
  feeAsk: Yup.number()
    .required("Please enter the fee ask")
    .typeError("Please enter the fee ask")
    .min(0, "Cannot be a negative value"),
});

export const feeCalculatorValidationSchemaAlone = Yup.object().shape({
  castClass: Yup.string().required("Please select a artist class"),
  engagementStatus: Yup.string().required(
    "Please select the engagement status"
  ),
  engagementStatusUnits: Yup.number()
    .required("Please enter the engagement status units")
    .typeError("Please enter the engagement status units")
    .min(1, "Must be greater or equal to 1"),
  ageStatus: Yup.string().required("Please select an age status"),
  hrsWorked: Yup.string().required("Please select hours worked"),
  rateType: Yup.string().required("Please select the rate type"),
  feeAsk: Yup.number()
    .required("Please enter the fee ask")
    .typeError("Please enter the fee ask")
    .min(0, "Cannot be a negative value"),
});

export interface ISignerArr {
  signer_email: string;
  signer_name: string;
  anchor_string: string;
  user_type: string;
}

export interface IProjectDetails {
  id: string;
  name: string;
  projectType: string;
  projectCategory: string;
  categoryLoading: number;
  collectiveAgreementCast: string;
  collectiveAgreementCrew: string;
  currency: string;
  inAggregate: number;
}

export const AgeStatusArr = ["Juvenile", "Adult"];

export const HrsWorkedArr = [
  { id: "ORDINARY TIME", name: "Ordinary Time" },
  { id: "OVERTIME", name: "Overtime" },
];

export const RateTypeArr = [
  { id: "AWARD RATE", name: "Award Rate" },
  { id: "OPTIONAL RATE", name: "Optional" },
];
