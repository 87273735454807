import React, { useMemo } from "react";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Typography,
} from "@mui/material";
import {
  CheckBoxOutlineBlankOutlined,
  ChevronLeft,
  ChevronRight,
  MoreVert,
} from "@mui/icons-material";
import { colors } from "theme/colors";

interface IProps extends DataGridProps {}

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    rowCount: number;
    page: number;
    pageSize: number;
    onPaginationModelChange: any;
  }
}

const styles = {
  table_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
  },
  table: {
    "& .MuiDataGrid-columnHeaders": { border: "none" },
    "& .MuiDataGrid-columnHeader": { py: 2, px: 3 },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: colors.whiteGrey500,
      fontSize: "16px",
      fontWeight: 500,
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-cell": {
      py: 2,
      px: 3,
      border: "none",
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none",
    },
  },
  footer_container: { px: 3, pt: 4, pb: 2 },
  page: {
    "& .MuiPaginationItem-page": {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
    },
    "& .Mui-selected": { color: colors.secondary },
  },
  page_count_text: {
    fontSize: "16px",
    color: colors.secondaryBlack,
    fontWeight: 500,
  },
  prev_next_icon: {
    border: `1px solid ${colors.primary}`,
    backgroundColor: "transparent",
    height: 33,
    width: 33,
  },
  page_size_container: { display: "flex", alignItems: "center" },
};

const CustomPreviousIcon = () => {
  return (
    <Avatar variant="rounded" sx={styles.prev_next_icon}>
      <ChevronLeft fontSize="small" color="primary" />
    </Avatar>
  );
};

const CustomNextIcon = () => {
  return (
    <Avatar variant="rounded" sx={styles.prev_next_icon}>
      <ChevronRight fontSize="small" color="primary" />
    </Avatar>
  );
};

const CustomFooter = (props: any) => {
  const { rowCount, page, pageSize, onPaginationModelChange } = props;
  const noOfPages = useMemo(
    () => Math.ceil(rowCount / pageSize),
    [pageSize, rowCount]
  );
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={styles.footer_container}
    >
      <Grid item lg={5} md={5} sm={6} xs={12}>
        <Box display="flex" alignItems="center">
          <Typography sx={styles.page_count_text}>Entries per page</Typography>
          <Select
            size="small"
            value={props.pageSize}
            sx={{ mx: 0.75 }}
            onChange={(e) =>
              onPaginationModelChange({
                page: props.page,
                pageSize: parseInt(e.target.value),
              })
            }
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <Typography sx={styles.page_count_text}>{`${page * pageSize + 1}-${
            rowCount < (page + 1) * pageSize ? rowCount : (page + 1) * pageSize
          } of ${rowCount} entries`}</Typography>
        </Box>
      </Grid>
      <Grid
        item
        lg={7}
        md={7}
        sm={6}
        xs={12}
        display="flex"
        justifyContent="flex-end"
      >
        <Pagination
          shape="rounded"
          color="primary"
          count={noOfPages}
          page={page + 1}
          onChange={(_, value) =>
            onPaginationModelChange({
              page: value - 1,
              pageSize: props.pageSize,
            })
          }
          sx={styles.page}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: CustomPreviousIcon, next: CustomNextIcon }}
              {...item}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

const CustomCheckbox = (props: any) => (
  <Checkbox
    icon={<CheckBoxOutlineBlankOutlined color="primary" />}
    {...props}
  />
);

const CustomMoreActionsIcon = () => (
  <MoreVert sx={{ color: "#00000055" }} fontSize="small" />
);

const CommonTable = (props: IProps) => {
  const {
    sx: customSx,
    rowCount,
    paginationModel,
    onPaginationModelChange,
    slots: customSlots,
    slotProps: customSlotProps,
    ...rest
  } = props;

  return (
    <DataGrid
      {...rest}
      getRowHeight={() => "auto"}
      sx={{
        ...styles.table,
        ...styles.table_container,
        ...(customSx !== undefined && { ...customSx }),
      }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      hideFooterSelectedRowCount
      disableRowSelectionOnClick
      slots={{
        footer: CustomFooter,
        baseCheckbox: CustomCheckbox,
        moreActionsIcon: CustomMoreActionsIcon,
        ...customSlots,
      }}
      paginationMode="client"
      autoHeight={true}
      slotProps={{
        footer: {
          rowCount: rowCount,
          page: paginationModel?.page,
          pageSize: paginationModel?.pageSize,
          onPaginationModelChange: onPaginationModelChange,
        },
        ...customSlotProps,
      }}
    />
  );
};

export default CommonTable;
