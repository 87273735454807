import React from "react";
import { Box, Typography } from "@mui/material";

import { images } from "assets/images";

const styles = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: { xs: 3, sm: 5, lg: 10 },
  },
  mt2: { mt: 2 },
  textAlignCenter: { textAlign: "center" },
  oops_text: { fontSize: "35px", color: "#000000", fontWeight: 500 },
  inst_text: { color: "#71717A", fontSize: "20px", fontWeight: 500 },
};

interface INoDataViewProps {
  title: string;
  subtitle: string;
}

function NoDataView(props: INoDataViewProps) {
  const { title, subtitle } = props;

  return (
    <Box sx={styles.container}>
      <Box>
        <img src={images.NO_DATA} alt="no results" />
        <Typography sx={[styles.mt2, styles.textAlignCenter, styles.oops_text]}>
          {title}
        </Typography>
        <Typography sx={[styles.mt2, styles.textAlignCenter, styles.inst_text]}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}

export default NoDataView;
