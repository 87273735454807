import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Dialog } from "@mui/material";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { CREATE_CONTACT } from "graphql/contacts";
import { CREATE_AGENT } from "graphql/agents";
import { useStore } from "utils/store";
import { ICreateContactFieldsNew } from "models/contacts";
import { getPermissions } from "permissions/utils";

import StepperComponent from "./StepperComponent";
import {
	createAddContactPayload,
	initialValues as initValues,
	validationSchema,
} from "./utils";
import IExtractedContact from "..";
import { CountryCodeArr } from "utils/constants";

interface IAddContactModalProps {
	open: boolean;
	handleClose: () => void;
	selectedContactData: IExtractedContact | null;
}

const AddContactModal = (props: IAddContactModalProps) => {
	const { open, handleClose, selectedContactData } = props;

	const { setLoading } = useStore();
	const projectId = useParams();
	const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
	const agentsEditIndex = useMemo(
		() =>
			ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
				x.hasOwnProperty("AGENTS_EDIT")
			),
		[ROLE_PERMISSIONS]
	);

	const [initialValues, setInitialValues] = useState<ICreateContactFieldsNew>(
		initValues(projectId?.project_id!)
	);
	const [newAgent, setNewAgent] = useState(false);
	const [agencyDetailsValue, setAgencyDetailsValue] = useState<any>(null);

	const handleDialogClose = (_: any, reason: string) => {
		if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
			handleClose();
		}
	};

	const [fireCreateAgentApi, { loading: creating }] = useMutation(
		CREATE_AGENT,
		{
			onCompleted: (data) => {
				setLoading(false);
				const { createAgent } = data;
				const { status, message } = createAgent;
				if (status) {
					setAgencyDetailsValue(null);
					toast.success(message, { delay: 10 });
				} else {
					toast.error(message, { delay: 10 });
				}
			},
		}
	);

	const [fireCreateContactApi, { loading }] = useMutation(CREATE_CONTACT, {
		onCompleted: (data) => {
			setLoading(false);
			const { createContact } = data;
			const { status, message } = createContact;
			handleClose();
			if (status) {
				if (
					Boolean(ROLE_PERMISSIONS) &&
					agentsEditIndex !== undefined &&
					ROLE_PERMISSIONS[0]?.permissions[agentsEditIndex]?.AGENTS_EDIT &&
					newAgent &&
					Boolean(agencyDetailsValue)
				) {
					const {
						address: agencyAddress,
						financialDetails,
						spocMobile,
						...restAgencyDetails
					} = agencyDetailsValue;
					const {
						haveSuperannuationAccount: agencyHave,
						smsfAbn,
						smsfBankAccountName,
						smsfBsb,
						smsfEsa,
						smsfFullName,
						smsfName,
						...restAgencyFinancialDetails
					} = financialDetails;
					fireCreateAgentApi({
						variables: {
							payload: {
								...(typeof agencyAddress !== "string" &&
									agencyAddress !== null && {
										address: agencyAddress?.freeformAddress,
										companyCountry: agencyAddress?.country || "",
										companyState:
											agencyAddress?.countrySubdivision || "",
										companyCity: agencyAddress?.municipality || "",
										suburb:
											agencyAddress?.municipalitySubdivision || "",
										pinCode: agencyAddress?.postalCode || "",
										street: agencyAddress?.streetName || "",
									}),
								financialDetails: { ...restAgencyFinancialDetails },
								...(Boolean(spocMobile?.nationalNumber) && {
									spocMobile: `${
										CountryCodeArr.find(
											(x) => x.id === spocMobile.countryCode
										)?.name
									} ${spocMobile.nationalNumber}`,
								}),
								...restAgencyDetails,
							},
						},
					});
				}
				toast.success(message);
			} else {
				toast.error(message);
			}
		},
	});

	const handleCreateContact = (values: ICreateContactFieldsNew) => {
		const { profile_picture, agencyDetails } = values;
		const contactPayload = createAddContactPayload(values, newAgent);
		if (newAgent) {
			setAgencyDetailsValue(agencyDetails);
		}
		// if (newAgent) {
		//   const {
		//     haveSuperannuationAccount: agencyHave,
		//     ...restAgencyFinancialDetails
		//   } = financialDetails;
		//   fireCreateAgentApi({
		//     variables: {
		//       payload: {
		//         ...(typeof agencyAddress !== "string" &&
		//           agencyAddress !== null && {
		//             address: agencyAddress?.freeformAddress,
		//             companyCountry: agencyAddress?.country || "",
		//             companyState: agencyAddress?.countrySubdivision || "",
		//             companyCity: agencyAddress?.municipality || "",
		//             suburb: agencyAddress?.municipalitySubdivision || "",
		//             street: agencyAddress?.streetName || "",
		//             pinCode: agencyAddress?.postalCode || "",
		//           }),
		//         financialDetails: { ...restAgencyFinancialDetails },
		//         ...restAgencyDetails,
		//       },
		//     },
		//   });
		// }
		fireCreateContactApi({
			variables: {
				payload: { ...contactPayload },
				...(Boolean(profile_picture) && {
					profile_picture: profile_picture,
				}),
				shareForm: false,
			},
		});
	};

	useEffect(() => {
		if (Boolean(selectedContactData)) {
			setInitialValues({
				...initialValues,
				firstName: selectedContactData?.first_name || "",
				lastName: selectedContactData?.last_name || "",
				mobile: {
					countryCode: selectedContactData?.mobile?.countryCode || "61",
					nationalNumber:
						selectedContactData?.mobile?.nationalNumber || null,
					rawInput: selectedContactData?.mobile?.rawInput || "",
				},
				project_role: selectedContactData?.role || "",
				emailId: selectedContactData?.email || "",
				address: selectedContactData?.address || "",
			});
		}
	}, [initialValues, selectedContactData]);

	useEffect(() => {
		setLoading(creating || loading);
	}, [creating, loading, setLoading]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleCreateContact}
			enableReinitialize
		>
			{(formikBag) => (
				<Dialog
					open={open}
					onClose={handleDialogClose}
					maxWidth="md"
					fullWidth
					PaperProps={{
						sx: {
							height: { xs: 600, sm: 785 },
							borderRadius: "24px",
							// overflow: "hidden"
						},
					}}
				>
					<Box
						sx={{
							height: "100%",
							overflow: "auto",
						}}
					>
						<StepperComponent
							formikBag={formikBag}
							handleClose={handleClose}
							setNewAgent={setNewAgent}
						/>
					</Box>
				</Dialog>
			)}
		</Formik>
	);
};

export default AddContactModal;
