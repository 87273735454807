import React, { useMemo, useState } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  menuItemClasses,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import moment from "moment";

import { IArr } from "models/common";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { ISelectedChecklistItem, IWeekDueArr } from "./DeliveryChecklistItem";
import { ProjectDetailsStyles as styles } from "../styles";

interface IWeekDueComponentProps {
  params1: GridRenderCellParams<any>;
  selectedChecklistItem: ISelectedChecklistItem | null;
  fireUpdateChecklistItemApi: any;
  params: any;
  weekDueArr: IWeekDueArr[];
}

export const WeekDueComponent = (props: IWeekDueComponentProps) => {
  const {
    params1,
    selectedChecklistItem,
    fireUpdateChecklistItemApi,
    params,
    weekDueArr,
  } = props;

  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const deliveryEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("DELIVERY_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [openWeekDueMenu, setOpenWeekDueMenu] = useState(false);
  const [weekDueMenuAnchor, setWeekDueMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleWeekDueMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setWeekDueMenuAnchor(e.currentTarget);
    setOpenWeekDueMenu(true);
  };

  const handleWeekDueMenuClose = () => {
    setWeekDueMenuAnchor(null);
    setOpenWeekDueMenu(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#e6e6e6",
          ...(Boolean(ROLE_PERMISSIONS) &&
          deliveryEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]?.DELIVERY_EDIT
            ? { cursor: "pointer" }
            : {}),
          width: Boolean(selectedChecklistItem) ? 130 : 145,
          borderRadius: "20px",
          p: 0.75,
          ":hover": { backgroundColor: "#d6d6d6" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={(e: any) => {
          if (
            Boolean(ROLE_PERMISSIONS) &&
            deliveryEditIndex !== undefined &&
            ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]?.DELIVERY_EDIT
          ) {
            e.stopPropagation();
            handleWeekDueMenuOpen(e);
          }
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            sx={{
              fontWeight: 600,
              color: "#000000",
              fontSize: "13px",
              textAlign: "center",
            }}
          >
            {Boolean(params1?.value)
              ? Boolean(selectedChecklistItem)
                ? `Wk ${params1?.value}`
                : `Week ${params1?.value}`
              : "Due"}
          </Typography>
        </Box>
        {Boolean(ROLE_PERMISSIONS) &&
          deliveryEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
            ?.DELIVERY_EDIT && (
            <ArrowDropDown fontSize="small" sx={{ color: "#000000" }} />
          )}
      </Box>
      <Menu
        anchorEl={weekDueMenuAnchor}
        open={openWeekDueMenu}
        onClose={handleWeekDueMenuClose}
        elevation={0}
        sx={{
          "& .MuiMenu-paper": {
            mt: 1,
            border: "1px #00000030 solid",
            borderRadius: "15px",
            maxHeight: "400px",
            minWidth: "200px",
          },
        }}
      >
        {weekDueArr.length === 0 && (
          <MenuItem disabled>
            <Typography sx={[styles.wrap, { fontSize: "13px" }]}>
              Please specify the post production dates
            </Typography>
          </MenuItem>
        )}
        {weekDueArr.map((week) => (
          <MenuItem
            key={week.id}
            onClick={() => {
              fireUpdateChecklistItemApi({
                variables: {
                  payload: {
                    projectId: params?.project_id,
                    checklistId: params?.checklist_id,
                    id: params1?.row?.id,
                    weekDue: parseInt(week.id),
                  },
                },
              });
              handleWeekDueMenuClose();
            }}
            selected={week.id.toString() === params1?.value?.toString()}
            sx={{
              display: "flex",
              alignItems: "center",
              [`&.${menuItemClasses.selected}`]: {
                backgroundColor: "#41B19931",
                "&:hover": { backgroundColor: "#41B19995" },
              },
              "&:hover": { backgroundColor: "#B6BCC330" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: colors.primaryBlack,
                  ml: 1,
                  fontWeight: 600,
                }}
                textAlign="center"
              >
                {`Week ${week.id}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: `${colors.primaryBlack}80`,
                  ml: 1,
                }}
              >{`Starting ${moment(week.date).format("DD MMMM")}`}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface IAssignToComponentProps {
  params1: GridRenderCellParams<any>;
  fireUpdateChecklistItemApi: any;
  params: any;
  usersArr: IArr[];
}

export const AssignToComponent = (props: IAssignToComponentProps) => {
  const { params1, fireUpdateChecklistItemApi, params, usersArr } = props;

  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const deliveryEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("DELIVERY_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [openAssignToMenu, setOpenAssignToMenu] = useState(false);
  const [assignToMenuAnchor, setAssignToMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleAssignToMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAssignToMenuAnchor(e.currentTarget);
    setOpenAssignToMenu(true);
  };

  const handleAssignToMenuClose = () => {
    setAssignToMenuAnchor(null);
    setOpenAssignToMenu(false);
  };

  return (
    <Box sx={{ width: 230, display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: "90%" }}>
        <Box
          sx={[
            // styles.wrap,
            styles.flex_JCsb_Acenter,
            {
              ...(Boolean(ROLE_PERMISSIONS) &&
              deliveryEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]?.DELIVERY_EDIT
                ? { cursor: "pointer" }
                : {}),
              px: 2,
              py: 0.5,
              ":hover": { borderRadius: 20, backgroundColor: "#e6e6e6" },
            },
          ]}
          onClick={(e: any) => {
            if (
              Boolean(ROLE_PERMISSIONS) &&
              deliveryEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]?.DELIVERY_EDIT
            ) {
              e.stopPropagation();
              handleAssignToMenuOpen(e);
            }
          }}
        >
          <Box sx={[styles.flex_Acenter, { width: "90%" }]}>
            <img src={images.ACCOUNT_ICON_NEW} alt="account" width={18} />
            <Typography noWrap sx={[styles.cell_text, { ml: 1, mr: 0.5 }]}>
              {Boolean(params1?.value) ? params1?.value?.fullName : "Assign"}
            </Typography>
          </Box>
          {Boolean(ROLE_PERMISSIONS) &&
            deliveryEditIndex !== undefined &&
            ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
              ?.DELIVERY_EDIT && <ArrowDropDown sx={{ color: "#000000" }} />}
        </Box>
      </Box>
      <Menu
        anchorEl={assignToMenuAnchor}
        open={openAssignToMenu}
        onClose={handleAssignToMenuClose}
        elevation={0}
        sx={{
          "& .MuiMenu-paper": {
            mt: 1,
            border: "1px #00000030 solid",
            borderRadius: "15px",
            maxHeight: "400px",
            minWidth: "200px",
          },
        }}
      >
        {usersArr.length === 0 && (
          <MenuItem disabled>
            <Typography sx={[styles.wrap, { fontSize: "13px" }]}>
              Please assign this project to a user
            </Typography>
          </MenuItem>
        )}
        {usersArr.map((user) => (
          <MenuItem
            key={user.id}
            onClick={() => {
              fireUpdateChecklistItemApi({
                variables: {
                  payload: {
                    projectId: params?.project_id,
                    checklistId: params?.checklist_id,
                    id: params1?.row?.id,
                    assignedToId: user.id,
                  },
                },
              });
              handleAssignToMenuClose();
            }}
            selected={user.id === params1?.value?.id}
            sx={{
              display: "flex",
              alignItems: "center",
              [`&.${menuItemClasses.selected}`]: {
                backgroundColor: "#41B19931",
                "&:hover": { backgroundColor: "#41B19995" },
              },
              "&:hover": { backgroundColor: "#B6BCC330" },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: colors.primaryBlack,
                  ml: 1,
                }}
                textAlign="center"
              >
                {user.name}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
