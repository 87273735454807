import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { AddCircle, Search } from "@mui/icons-material";
import { useQuery } from "@apollo/client";

import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { useStore } from "utils/store";
import { IIndex } from "models/indexes";
import { GET_ALL_INDEXES } from "graphql/indexes";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import Header from "./Header";
import AddEditClearanceModal from "./AddEditClearanceModal";
import ClearanceCard from "./ClearanceCard";
import DeleteIndexModal from "../Indexes/DeleteIndexModal";
import ProjectDetailsTabs from "..";
import { images } from "assets/images";

export function Clearances() {
  const projectDetailsTabs = useProjectDetailsTabs();

  const projectId = useParams();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const clearanceViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CLEARANCE_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const clearanceEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CLEARANCE_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [indexes, setIndexes] = useState<IIndex[]>([]);
  const [indexCount, setIndexCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [addEditIndexModal, setAddEditIndexModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedIndexName, setSelectedIndexName] = useState("");
  const [sortBy, setSortBy] = useState("name");

  const { loading, refetch, fetchMore } = useQuery(GET_ALL_INDEXES, {
    variables: {
      is_clearance: true,
      limit: 40,
      page: 1,
      filter: {
        project_id: projectId?.project_id,
        ...(Boolean(searchText) && { name__icontains: searchText }),
      },
      ...(Boolean(sortBy) && { sorted: sortBy }),
    },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        clearanceViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[clearanceViewIndex]?.CLEARANCE_VIEW
      ),
    onCompleted: (data) => {
      setLoading(false);
      const { getRegisters } = data;
      const { status, registers, count } = getRegisters;
      if (status) {
        setIndexes(registers);
        setIndexCount(count);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (indexes.length < indexCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(indexes.length / 40) + 1,
              limit: 40,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.getRegisters.registers;
              return {
                getRegisters: {
                  ...fetchMoreResult?.getRegisters,
                  registers: [...indexes, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchMore, setLoading, indexes, indexCount]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    projectDetailsTabs.setSelectedTab(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <Grid
        container
        spacing={2.5}
        justifyContent={{ xs: "center", lg: "flex-start" }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2.25 }}>
          <Header
            setAddEditClearanceModal={setAddEditIndexModal}
            setSearchText={setSearchText}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </Grid>
        {!loading && !searchText && indexes.length === 0 ? (
          Boolean(ROLE_PERMISSIONS) &&
          clearanceEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[clearanceEditIndex]
            ?.CLEARANCE_EDIT ? (
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAddEditIndexModal(true);
                }}
              >
                <Box
                  sx={{
                    border: "3px #BFBDBC dotted",
                    borderRadius: "10px",
                    backgroundColor: `#BFBDBC22`,
                    flexDirection: "column",
                    px: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "130.5px",
                    width: "220px",
                  }}
                >
                  <AddCircle sx={{ color: "#BFBDBC", fontSize: 45, mb: 2 }} />
                  <Typography
                    sx={{
                      color: `#BFBDBC`,
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                    textAlign="center"
                  >
                    Add Clearance
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100vh - 270px)",
                }}
              >
                <Box
                  sx={{
                    height: "200px",
                    width: "550px",
                    backgroundColor: "#BFBDBC22",
                    borderRadius: " 24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: " column",
                  }}
                >
                  <img src={images.FOLDER_NEW_BLACK} alt="folder" width={25} />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.primaryBlack,
                      my: 2,
                      fontWeight: 600,
                    }}
                  >
                    Its empty here!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Please reach out to your admin for access to create a
                    clearance folder.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )
        ) : (
          <></>
        )}
        {!loading && searchText && indexes.length === 0 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Search
                sx={{ color: `${colors.primaryBlack}30`, fontSize: 80 }}
              />
              <Typography
                sx={{
                  mt: 2,
                  color: `${colors.primaryBlack}30`,
                  fontSize: "32px",
                  fontWeight: 500,
                }}
                textAlign="center"
              >
                No results found
              </Typography>
            </Box>
          </Grid>
        )}
        {!loading &&
          indexes.length > 0 &&
          indexes.map((item) => (
            <Grid
              item
              lg={3}
              md={4}
              sm={4}
              xs={12}
              key={item.id}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "flex-start" },
              }}
            >
              <ClearanceCard
                data={item}
                setSelectedIndex={setSelectedIndex}
                setSelectedIndexName={setSelectedIndexName}
                setAddEditIndexModal={setAddEditIndexModal}
                setDeleteModal={setDeleteModal}
              />
            </Grid>
          ))}
        {addEditIndexModal && (
          <AddEditClearanceModal
            open={addEditIndexModal}
            setOpen={setAddEditIndexModal}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            refetchTableData={refetch}
          />
        )}
        {deleteModal && (
          <DeleteIndexModal
            open={deleteModal}
            setOpen={setDeleteModal}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            selectedIndexName={selectedIndexName}
            setSelectedIndexName={setSelectedIndexName}
            refetch={refetch}
          />
        )}
      </Grid>
    </ProjectDetailsTabs>
  );
}
