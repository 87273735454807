import { Location } from "react-router-dom";

import { RouteNames } from "routes/routeNames";
import { checkProjectAccess } from "permissions/utils";
import { images } from "assets/images";

export const ClientMainDrawerItems = [
  {
    id: 0,
    label: "Dashboard",
    icon: images.DASHBOARD,
    selectedIcon: images.DASHBOARD_SELECTED,
    path: RouteNames.DASHBOARD,
  },
  {
    id: 1,
    label: "Projects",
    icon: images.PROJECTS,
    selectedIcon: images.PROJECTS_SELECTED,
    path: RouteNames.PROJECTS,
  },
  // {
  //   id: 2,
  //   label: "Agents",
  //   icon: images.AGENTS,
  //   selectedIcon: images.AGENTS_SELECTED,
  //   path: RouteNames.AGENTS,
  // },
  {
    id: 3,
    label: "Reports",
    icon: images.REPORTS,
    selectedIcon: images.REPORTS_SELECTED,
    path: RouteNames.REPORTS,
  },
  {
    id: 4,
    label: "Integrations",
    icon: images.INTEGRATIONS,
    selectedIcon: images.INTEGRATIONS_SELECTED,
    path: RouteNames.INTEGRATIONS,
  },
  {
    id: 5,
    label: "Settings",
    icon: images.SETTINGS,
    selectedIcon: images.SETTINGS_SELECTED,
    path: `${RouteNames.SETTINGS}/profile`,
  },
  {
    id: 6,
    label: "Help",
    icon: images.SUPPORT,
    selectedIcon: images.SUPPORT_SELECTED,
    path: RouteNames.SUPPORT,
  },
];

export const setClientDrawerSelection = (
  location: Location,
  setSelectedDrawerItem: (selectedDrawerItem: number) => void
) => {
  for (let i = 0; i < ClientMainDrawerItems.length; i += 1) {
    if (
      ClientMainDrawerItems[i].path === location.pathname ||
      location.pathname.includes(ClientMainDrawerItems[i].path)
    ) {
      setSelectedDrawerItem(ClientMainDrawerItems[i].id);
      break;
    }
  }
};

export const getClientDrawerHeaderTitle = (selectedDrawerItem: number) => {
  for (let i = 0; i < ClientMainDrawerItems.length; i += 1) {
    if (ClientMainDrawerItems[i].id === selectedDrawerItem) {
      return ClientMainDrawerItems[i].label;
    }
  }
  return "";
};

export const ClientDrawerItemsAccessPermission = (permissions: any) => {
  const dashboardIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("DASHBOARD")
  );
  const globalReportsIndex = permissions[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("REPORTS")
  );
  const integrationsViewIndex = permissions[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("INTEGRATIONS_VIEW")
  );
  const integrationsEditIndex = permissions[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("INTEGRATIONS_EDIT")
  );
  return [
    ...(dashboardIndex > -1 &&
    permissions[0]?.permissions[dashboardIndex]?.DASHBOARD
      ? [
          {
            id: 0,
            label: "Dashboard",
            icon: images.DASHBOARD,
            selectedIcon: images.DASHBOARD_SELECTED,
            path: RouteNames.DASHBOARD,
          },
        ]
      : []),
    ...(checkProjectAccess(permissions)
      ? [
          {
            id: 1,
            label: "Projects",
            icon: images.PROJECTS,
            selectedIcon: images.PROJECTS_SELECTED,
            path: RouteNames.PROJECTS,
          },
        ]
      : []),
    ...(permissions[0]?.permissions[globalReportsIndex]?.REPORTS
      ? [
          {
            id: 3,
            label: "Reports",
            icon: images.REPORTS,
            selectedIcon: images.REPORTS_SELECTED,
            path: RouteNames.REPORTS,
          },
        ]
      : []),
    ...(permissions[0]?.permissions[integrationsViewIndex]?.INTEGRATIONS_VIEW ||
    permissions[0]?.permissions[integrationsEditIndex]?.INTEGRATIONS_EDIT
      ? [
          {
            id: 4,
            label: "Integrations",
            icon: images.INTEGRATIONS,
            selectedIcon: images.INTEGRATIONS_SELECTED,
            path: RouteNames.INTEGRATIONS,
          },
        ]
      : []),
    {
      id: 5,
      label: "Settings",
      icon: images.SETTINGS,
      selectedIcon: images.SETTINGS_SELECTED,
      path: `${RouteNames.SETTINGS}/profile`,
    },
    {
      id: 6,
      label: "Help",
      icon: images.SUPPORT,
      selectedIcon: images.SUPPORT_SELECTED,
      path: RouteNames.SUPPORT,
    },
  ];
};

export const AdminMainDrawerItems = [
  {
    id: 0,
    label: "Dashboard",
    icon: images.DASHBOARD,
    selectedIcon: images.DASHBOARD_SELECTED,
    path: RouteNames.ADMIN_DASHBOARD,
  },
  {
    id: 1,
    label: "Manage Clients",
    icon: images.MANAGE_CLIENTS,
    selectedIcon: images.MANAGE_CLIENTS_SELECTED,
    path: RouteNames.MANAGE_CLIENTS,
  },
  {
    id: 2,
    label: "Manage Templates",
    icon: images.TEMPLATES,
    selectedIcon: images.TEMPLATES_SELECTED,
    path: RouteNames.MANAGE_TEMPLATES,
  },
  {
    id: 3,
    label: "Manage Admins",
    icon: images.ACCESS,
    selectedIcon: images.ACCESS_SELECTED,
    path: RouteNames.MANAGE_ADMINS,
  },
  {
    id: 4,
    label: "Role Management",
    icon: images.ROLE_MGMT,
    selectedIcon: images.ROLE_MGMT_SELECTED,
    path: RouteNames.ROLE_MGMT,
  },
  {
    id: 5,
    label: "Settings",
    icon: images.SETTINGS,
    selectedIcon: images.SETTINGS_SELECTED,
    path: RouteNames.ADMIN_SETTINGS,
  },
];

export const setAdminDrawerSelection = (
  location: Location,
  setSelectedDrawerItem: (selectedDrawerItem: number) => void
) => {
  for (let i = 0; i < AdminMainDrawerItems.length; i += 1) {
    if (
      AdminMainDrawerItems[i].path === location.pathname ||
      location.pathname.includes(AdminMainDrawerItems[i].path)
    ) {
      setSelectedDrawerItem(AdminMainDrawerItems[i].id);
      break;
    }
  }
};

export const getAdminDrawerHeaderTitle = (selectedDrawerItem: number) => {
  for (let i = 0; i < AdminMainDrawerItems.length; i += 1) {
    if (AdminMainDrawerItems[i].id === selectedDrawerItem) {
      return AdminMainDrawerItems[i].label;
    }
  }
  return "";
};
