import { gql } from "@apollo/client";

export const GET_ADMIN_DASHBOARD = gql`
  query getAdminDashboard {
  getAdminDashboard{
    status
    message
    clients
    projectTypeStatusCount {
        count
        type
        status
    }
  }
}
`;
