import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { LocationOnOutlined } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import PhoneNumberInput from "common/PhoneNumberInput";
import { IUpdateContactFieldsNew } from "models/contacts";
import { IGeoCoding } from "models/common";
import { GET_ADDRESS_AUTOCOMPLETE } from "graphql/meta";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import { useStore } from "utils/store";
import { CountryCodeArr } from "utils/constants";

interface ICompanyDetailsFormProps {
	initialValues: IUpdateContactFieldsNew;
	updateContactApi: any;
	refetch: any;
	setActiveSection: Dispatch<SetStateAction<number>>;
	setActiveStep: Dispatch<SetStateAction<number>>;
	setPrevActiveSection: Dispatch<SetStateAction<number>>;
}

function CompanyDetailsForm(props: ICompanyDetailsFormProps) {
	const {
		initialValues,
		updateContactApi,
		refetch,
		setActiveSection,
		setActiveStep,
		setPrevActiveSection,
	} = props;

	const { setLoading } = useStore();

	const [addressField, setAddressField] = useState<IGeoCoding | string | null>(
		null
	);
	const [addressArr, setAddressArr] = useState<IGeoCoding[]>([]);
	const [addressSearchText, setAddressSearchText] = useState("");

	const [getGeoCodeAddress] = useLazyQuery(GET_ADDRESS_AUTOCOMPLETE, {
		onCompleted: (data) => {
			const { autocompleteAddress } = data;
			setAddressArr(autocompleteAddress);
			if (
				autocompleteAddress.length > 0 &&
				Boolean(addressField) &&
				typeof addressField === "string"
			) {
				setAddressField(autocompleteAddress[0]);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const handleSubmitManagementForm = (values: IUpdateContactFieldsNew) => {
		const { id, managementCompanyDetails } = values;
		const {
			address,
			abn,
			acn,
			financialDetails,
			spocMobile,
			...restDetails
		} = managementCompanyDetails;

		updateContactApi({
			variables: {
				payload: {
					id,
					managementCompanyDetails: {
						...restDetails,
						...(Boolean(spocMobile?.nationalNumber) && {
							spocMobile: `${
								CountryCodeArr.find(
									(x) => x.id === spocMobile.countryCode
								)?.name
							} ${spocMobile.nationalNumber}`,
						}),
						...(typeof address !== "string" &&
							address !== null && {
								address: address?.freeformAddress,
								companyCountry: address?.country || "",
								companyState: address?.countrySubdivision || "",
								companyCity: address?.municipality || "",
								suburb: address?.municipalitySubdivision || "",
								street: address?.streetName || "",
								pinCode: address?.postalCode || "",
							}),
					},
				},
			},
			onCompleted: (data: any) => {
				setLoading(false);
				const { updateContactBasicDetails } = data;
				const { status, message } = updateContactBasicDetails;
				if (status) {
					refetch();
					toast.success(message, { delay: 10 });
					setActiveSection(1);
				} else {
					toast.error(message, { delay: 10 });
				}
			},
		});
	};

	useEffect(() => {
		setAddressField(initialValues.managementCompanyDetails.address);
	}, [initialValues.address, initialValues.managementCompanyDetails.address]);

	useEffect(() => {
		if (addressSearchText) {
			getGeoCodeAddress({ variables: { filter: addressSearchText } });
		}
	}, [addressSearchText, getGeoCodeAddress]);

	useEffect(() => {
		if (Boolean(addressField) && typeof addressField === "string") {
			setAddressSearchText(addressField);
		}
	}, [addressField]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				managementCompanyDetails: Yup.object().shape({
					spocEmailId: Yup.string()
						.email("Please enter a valid email")
						.nullable(),
					// spocMobile: Yup.string()
					//   .matches(
					//     /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
					//     "Contact number must be of 10 digits"
					//   )
					//   .nullable(),
					website: Yup.string()
						.matches(
							/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
							"Please enter a valid url"
						)
						.nullable(),
				}),
			})}
			onSubmit={handleSubmitManagementForm}
			enableReinitialize
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleBlur,
				errors,
				touched,
				handleSubmit,
				isValid,
			}) => (
				<Box
					sx={{
						pt: { xs: 2, sm: 14 },
						px: 4,
						pb: 1.5,
						display: "flex",
						flexDirection: "column",
						height: "100%",
					}}
				>
					<Grid container spacing={3}>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Company Name
							</Typography>
							<TextField
								id="managementCompanyDetails.companyName"
								value={values.managementCompanyDetails.companyName}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Manager Full Name
							</Typography>
							<TextField
								id="managementCompanyDetails.spocName"
								value={values.managementCompanyDetails.spocName}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>Email</Typography>
							<TextField
								id="managementCompanyDetails.spocEmailId"
								value={values.managementCompanyDetails.spocEmailId}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
							{touched?.managementCompanyDetails?.spocEmailId &&
								errors?.managementCompanyDetails?.spocEmailId && (
									<Typography sx={styles.error_text}>
										{errors?.managementCompanyDetails?.spocEmailId}
									</Typography>
								)}
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>Mobile</Typography>
							<PhoneNumberInput
								value={
									values.managementCompanyDetails?.spocMobile
										?.nationalNumber !== null
										? values.managementCompanyDetails?.spocMobile
												?.nationalNumber
										: Boolean(
												values.managementCompanyDetails?.spocMobile
													?.rawInput
										  ) &&
										  values.managementCompanyDetails?.spocMobile
												?.rawInput !== "+61 "
										? values.managementCompanyDetails?.spocMobile?.rawInput.replaceAll(
												" ",
												""
										  )
										: ""
								}
								onChange={(e) => {
									setFieldValue(
										"managementCompanyDetails.spocMobile.nationalNumber",
										e.target.value
									);
								}}
								onBlur={handleBlur(
									"managementCompanyDetails.spocMobile.nationalNumber"
								)}
								countryCode={
									values.managementCompanyDetails.spocMobile
										.countryCode
								}
								setCountryCode={(code: string) =>
									setFieldValue(
										"managementCompanyDetails.spocMobile.countryCode",
										code
									)
								}
							/>
							{/* {touched?.managementCompanyDetails?.spocMobile &&
                errors?.managementCompanyDetails?.spocMobile && (
                  <Typography sx={styles.error_text}>
                    {errors?.managementCompanyDetails?.spocMobile}
                  </Typography>
                )} */}
						</Grid>
						<Grid item lg={3} md={4} sm={5} xs={12}>
							<Typography sx={styles.field_label}>
								Unit/Suite Number
							</Typography>
							<TextField
								id="managementCompanyDetails.unit"
								value={values.managementCompanyDetails.unit}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
						</Grid>
						<Grid item lg={9} md={8} sm={7} xs={12}>
							<Typography sx={styles.field_label}>Address</Typography>
							<Autocomplete
								id="managementCompanyDetails.address"
								getOptionLabel={(option) => option.freeformAddress}
								value={
									Boolean(values.managementCompanyDetails.address)
										? typeof values.managementCompanyDetails
												.address !== "string"
											? values.managementCompanyDetails.address!
											: {
													streetNumber: "",
													streetName: "",
													municipalitySubdivision: "",
													municipality: "",
													countrySubdivision: "",
													postalCode: "",
													country: "",
													freeformAddress: "",
											  }
										: undefined
								}
								onChange={(event, newValue) => {
									setFieldValue(
										"managementCompanyDetails.address",
										newValue
									);
								}}
								inputValue={addressSearchText}
								onInputChange={(event: any, newInputValue) => {
									if (Boolean(event?.target)) {
										setAddressSearchText(newInputValue);
									}
								}}
								options={addressArr}
								popupIcon={
									<LocationOnOutlined
										fontSize="small"
										sx={{ color: "#00000050" }}
									/>
								}
								sx={[
									styles.input_field,
									{
										"& .MuiAutocomplete-popupIndicator": {
											transform: "none",
										},
									},
								]}
								size="small"
								disableClearable
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										size="small"
										fullWidth
										inputProps={{
											...params.inputProps,
											style: {
												...styles.input_field,
												backgroundColor: "transparent",
											},
										}}
									/>
								)}
								noOptionsText={
									<Typography
										sx={{ fontSize: "13px", color: "#0f0f0f80" }}
									>
										{addressSearchText ? "No match found" : ""}
									</Typography>
								}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>Website</Typography>
							<TextField
								id="managementCompanyDetails.website"
								value={values.managementCompanyDetails.website}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
							{touched?.managementCompanyDetails?.website &&
								errors?.managementCompanyDetails?.website && (
									<Typography sx={styles.error_text}>
										{errors?.managementCompanyDetails?.website}
									</Typography>
								)}
						</Grid>
					</Grid>
					<Box sx={{ flexGrow: 1, mt: 2 }}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
							}}
						>
							<Box sx={{ flexGrow: 1 }} />
							<Box
								sx={[
									styles.flex_Acenter,
									{ justifyContent: "flex-end" },
								]}
							>
								<OutlinedBtn
									size="small"
									sx={{
										borderRadius: "20px",
										fontSize: "13px",
										fontWeight: 600,
										width: "120px",
										color: "#000",
									}}
									disableElevation
									disableRipple
									onClick={() => {
										setPrevActiveSection(2);
										setActiveStep(2);
									}}
								>
									Back
								</OutlinedBtn>
								<BlackActionContainedButton
									variant="contained"
									size="small"
									sx={{
										borderRadius: "20px",
										ml: 1,
										fontSize: "13px",
										fontWeight: 600,
										width: "120px",
									}}
									disableElevation
									disableRipple
									disabled={!isValid}
									onClick={() => handleSubmit()}
								>
									Save and Next
								</BlackActionContainedButton>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</Formik>
	);
}

export default CompanyDetailsForm;
