import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	Autocomplete,
	Box,
	Button,
	Grid,
	IconButton,
	InputAdornment,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	MenuList,
	TextField,
	Typography,
} from "@mui/material";
import {
	CheckOutlined,
	FiberManualRecord,
	KeyboardArrowDown,
	ModeEditOutlineOutlined,
} from "@mui/icons-material";
import { FormikProps } from "formik";
import { toast } from "react-toastify";
import moment from "moment";

import {
	CurrencyArr,
	DateFormatArr,
	ProjectCategoryArr,
	ProjectStatusMapping,
	ProjectTypeArr,
} from "utils/constants";
import { IEditProjectFields } from "models/projects";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";
import { NewOutlinedBtn } from "common/Buttons";
import DatePickerInput from "common/DatePickerInput";
import ProjectPhotoCropper from "common/ImageCropper/ProjectPhotoCropper";
import { images } from "assets/images";
import { colors } from "theme/colors";

import WarningModal from "./WarningModal";
import { ProjectDetailsStyles as styles } from "../styles";

interface IProjectDetailsFormFormikProps {
	formikBag: FormikProps<IEditProjectFields>;
	projectStatus: number;
	setProjectStatus: Dispatch<SetStateAction<number>>;
	fireUpdateProjectApi: any;
	refetch: any;
	closeModalState: boolean;
	setCloseModalState: Dispatch<SetStateAction<boolean>>;
	editModeState: boolean;
	setEditModeState: Dispatch<SetStateAction<boolean>>;
}

function ProjectDetailsFormFormik(props: IProjectDetailsFormFormikProps) {
	const {
		formikBag,
		projectStatus,
		setProjectStatus,
		refetch,
		fireUpdateProjectApi,
		closeModalState,
		setCloseModalState,
		editModeState,
		setEditModeState,
	} = props;
	const {
		values,
		handleChange,
		setFieldValue,
		handleBlur,
		setFieldTouched,
		errors,
		touched,
		handleSubmit,
		resetForm,
		validateForm,
	} = formikBag;

	const { setLoading } = useStore();
	const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
	const projectEditIndex = useMemo(
		() =>
			ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
				x.hasOwnProperty("PROJECT_EDIT")
			),
		[ROLE_PERMISSIONS]
	);

	const [warningModal, setWarningModal] = useState(false);
	const [openImageCropUploader, setOpenImageCropUploader] = useState(false);
	const [editProjectDetails, setEditProjectDetails] = useState(false);
	const [openProjStatusMenu, setOpenProjStatusMenu] = useState(false);
	const [projStatusMenuAnchor, setProjStatusMenuAnchor] =
		useState<HTMLElement | null>();

	const handleProjStatusClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		setOpenProjStatusMenu(true);
		setProjStatusMenuAnchor(e.currentTarget);
	};

	const handleProjStatusClose = () => {
		setOpenProjStatusMenu(false);
		setProjStatusMenuAnchor(null);
	};

	useEffect(() => {
		if (editModeState) {
			setEditProjectDetails(false);
		}
	}, [editModeState]);

	useEffect(() => {
		if (closeModalState) {
			setWarningModal(false);
		}
	}, [closeModalState]);

	return (
		<Box>
			<Box
				sx={{
					position: "absolute",
					top: 181,
					right:
						Boolean(ROLE_PERMISSIONS) &&
						projectEditIndex !== undefined &&
						ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
							?.PROJECT_EDIT
							? 20
							: 45,
					display: { xs: "none", md: "block" },
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Button
						variant="contained"
						sx={{
							mr: 1.5,
							backgroundColor: (ProjectStatusMapping as any)[
								projectStatus
							].bg,
							color: (ProjectStatusMapping as any)[projectStatus].color,
							borderRadius: "8px",
							py: 1,
							px: 2,
							":hover": {
								backgroundColor: (ProjectStatusMapping as any)[
									projectStatus
								].bg,
							},
							"&.Mui-disabled": {
								backgroundColor: (ProjectStatusMapping as any)[
									projectStatus
								].bg,
								color: (ProjectStatusMapping as any)[projectStatus]
									.color,
							},
							fontSize: "14px",
						}}
						disableElevation
						disableRipple
						disabled={
							!(
								Boolean(ROLE_PERMISSIONS) &&
								projectEditIndex !== undefined &&
								ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
									?.PROJECT_EDIT
							)
						}
						onClick={handleProjStatusClick}
						endIcon={
							Boolean(ROLE_PERMISSIONS) &&
							projectEditIndex !== undefined &&
							ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
								?.PROJECT_EDIT ? (
								<KeyboardArrowDown
									fontSize="small"
									sx={{
										color: (ProjectStatusMapping as any)[
											projectStatus
										].color,
									}}
								/>
							) : null
						}
					>
						{(ProjectStatusMapping as any)[projectStatus].name}
					</Button>
					<Menu
						anchorEl={projStatusMenuAnchor}
						open={openProjStatusMenu}
						onClose={handleProjStatusClose}
						sx={styles.menu_container}
						elevation={0}
					>
						<MenuList>
							{Object.keys(ProjectStatusMapping).map((item) => (
								<MenuItem
									key={item}
									onClick={() => {
										setProjectStatus(parseInt(item));
										fireUpdateProjectApi({
											variables: {
												payload: {
													id: values.id,
													projectStatus: parseInt(item),
												},
											},
											onCompleted: (data: any) => {
												setLoading(false);
												const { updateProject } = data;
												const { status, message } = updateProject;
												if (status) {
													toast.success(message);
													refetch();
												} else {
													toast.error(message);
												}
											},
										});
										handleProjStatusClose();
									}}
								>
									<ListItemIcon>
										<FiberManualRecord
											fontSize="small"
											sx={{
												color: Boolean(projectStatus)
													? (ProjectStatusMapping as any)[item]
															.color
													: "",
											}}
										/>
									</ListItemIcon>
									<ListItemText>
										<Typography
											sx={{
												fontSize: "14px",
												color: `${colors.primaryBlack}80`,
											}}
										>
											{(ProjectStatusMapping as any)[item].name}
										</Typography>
									</ListItemText>
								</MenuItem>
							))}
						</MenuList>
					</Menu>
					{Boolean(ROLE_PERMISSIONS) &&
					projectEditIndex !== undefined &&
					ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
						?.PROJECT_EDIT ? (
						editProjectDetails ? (
							<IconButton
								size="small"
								onClick={() => {
									validateForm().then((validationErrors) => {
										if (Object.keys(validationErrors).length === 0) {
											setWarningModal(true);
											setCloseModalState(false);
										}
									});
								}}
							>
								<CheckOutlined
									fontSize="small"
									sx={{ color: "#00000055" }}
								/>
							</IconButton>
						) : (
							<IconButton
								size="small"
								onClick={() => {
									setEditProjectDetails(true);
									setEditModeState(false);
								}}
							>
								<ModeEditOutlineOutlined
									fontSize="small"
									sx={{ color: "#00000055" }}
								/>
							</IconButton>
						)
					) : (
						<></>
					)}
				</Box>
			</Box>
			<Grid container spacing={4}>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
								alignItems: "center",
								position: "relative",
							}}
						>
							<Box component="div">
								{Boolean(values?.profile_picture) ? (
									<Box
										sx={{
											height: "220px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<img
											src={
												typeof values?.profile_picture === "string"
													? values?.profile_picture
													: URL.createObjectURL(
															values?.profile_picture
													  )
											}
											alt="proj_image"
											style={{
												height: "220px",
												width: "148.5px",
												borderRadius: "15px",
												objectFit: "cover",
												aspectRatio: 0.675 / 1,
												...(values?.projectStatus === 2 && {
													filter: "grayscale(100%)",
												}),
											}}
										/>
									</Box>
								) : (
									<Box
										sx={{ display: "flex", justifyContent: "center" }}
									>
										<Box
											sx={[
												{
													height: "220px",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												},
												{
													backgroundColor: `#BFBDBC22`,
													aspectRatio: 0.675 / 1,
													borderRadius: "20px",
													width: "148.5px",
												},
											]}
										>
											<img
												src={images.FILM_REEL}
												alt="reel"
												style={{ height: 50 }}
											/>
										</Box>
									</Box>
								)}
							</Box>
							{Boolean(ROLE_PERMISSIONS) &&
								projectEditIndex !== undefined &&
								ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
									?.PROJECT_EDIT &&
								editProjectDetails && (
									<Box
										sx={{ position: "absolute", top: 10, right: 10 }}
									>
										<IconButton
											sx={{ backgroundColor: "#fff", opacity: 0.8 }}
											size="small"
											onClick={() => setOpenImageCropUploader(true)}
										>
											<ModeEditOutlineOutlined fontSize="small" />
										</IconButton>
									</Box>
								)}
						</Box>
						<Box
							sx={{
								mt: 1,
								ml: 1.5,
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Button
								variant="contained"
								sx={{
									backgroundColor: (ProjectStatusMapping as any)[
										projectStatus
									].bg,
									color: (ProjectStatusMapping as any)[projectStatus]
										.color,
									borderRadius: "8px",
									py: 1,
									px: 2,
									":hover": {
										backgroundColor: (ProjectStatusMapping as any)[
											projectStatus
										].bg,
									},
									"&.Mui-disabled": {
										backgroundColor: (ProjectStatusMapping as any)[
											projectStatus
										].bg,
										color: (ProjectStatusMapping as any)[
											projectStatus
										].color,
									},
									fontSize: "14px",
								}}
								disableElevation
								disableRipple
								disabled={
									!(
										Boolean(ROLE_PERMISSIONS) &&
										projectEditIndex !== undefined &&
										ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
											?.PROJECT_EDIT
									)
								}
								onClick={handleProjStatusClick}
								endIcon={
									Boolean(ROLE_PERMISSIONS) &&
									projectEditIndex !== undefined &&
									ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
										?.PROJECT_EDIT ? (
										<KeyboardArrowDown
											fontSize="small"
											sx={{
												color: (ProjectStatusMapping as any)[
													projectStatus
												].color,
											}}
										/>
									) : null
								}
							>
								{(ProjectStatusMapping as any)[projectStatus].name}
							</Button>
							{Boolean(ROLE_PERMISSIONS) &&
							projectEditIndex !== undefined &&
							ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
								?.PROJECT_EDIT ? (
								editProjectDetails ? (
									<NewOutlinedBtn
										sx={{ mt: 1, color: "#333", fontSize: "13px" }}
										onClick={() => {
											validateForm().then((validationErrors) => {
												if (
													Object.keys(validationErrors).length ===
													0
												) {
													setWarningModal(true);
													setCloseModalState(false);
												}
											});
										}}
										endIcon={
											<CheckOutlined
												sx={{ color: "#33333395", height: "17px" }}
											/>
										}
									>
										Save Details
									</NewOutlinedBtn>
								) : (
									<NewOutlinedBtn
										sx={{ mt: 1, color: "#333", fontSize: "13px" }}
										onClick={() => {
											setEditProjectDetails(true);
											setEditModeState(false);
										}}
										endIcon={
											<ModeEditOutlineOutlined
												sx={{ color: "#33333395", height: "17px" }}
											/>
										}
									>
										Edit Details
									</NewOutlinedBtn>
								)
							) : (
								<></>
							)}
						</Box>
						<Menu
							anchorEl={projStatusMenuAnchor}
							open={openProjStatusMenu}
							onClose={handleProjStatusClose}
							sx={styles.menu_container}
							elevation={0}
						>
							<MenuList>
								{Object.keys(ProjectStatusMapping).map((item) => (
									<MenuItem
										key={item}
										onClick={() => {
											setProjectStatus(parseInt(item));
											fireUpdateProjectApi({
												variables: {
													payload: {
														id: values.id,
														projectStatus: parseInt(item),
													},
												},
												onCompleted: (data: any) => {
													setLoading(false);
													const { updateProject } = data;
													const { status, message } =
														updateProject;
													if (status) {
														toast.success(message);
														refetch();
													} else {
														toast.error(message);
													}
												},
											});
											handleProjStatusClose();
										}}
									>
										<ListItemIcon>
											<FiberManualRecord
												fontSize="small"
												sx={{
													color: Boolean(projectStatus)
														? (ProjectStatusMapping as any)[item]
																.color
														: "",
												}}
											/>
										</ListItemIcon>
										<ListItemText>
											<Typography
												sx={{
													fontSize: "14px",
													color: `${colors.primaryBlack}80`,
												}}
											>
												{(ProjectStatusMapping as any)[item].name}
											</Typography>
										</ListItemText>
									</MenuItem>
								))}
							</MenuList>
						</Menu>
					</Box>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<Typography sx={styles.field_label}>
						Project Name{" "}
						{editProjectDetails && (
							<span style={styles.error_text}>*</span>
						)}
					</Typography>
					<TextField
						id="name"
						value={values.name}
						onChange={handleChange}
						onBlur={handleBlur}
						size="small"
						fullWidth
						disabled={!editProjectDetails}
						inputProps={{ style: styles.input_field }}
					/>
					{touched?.name && errors?.name && (
						<Typography sx={styles.error_text}>{errors.name}</Typography>
					)}
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<Typography sx={styles.field_label}>Project Number</Typography>
					<TextField
						id="projectNumber"
						value={values.projectNumber}
						onChange={handleChange}
						onBlur={handleBlur}
						size="small"
						fullWidth
						disabled={!editProjectDetails}
						inputProps={{ style: styles.input_field }}
					/>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Typography sx={styles.field_label}>
						Project Description
					</Typography>
					<TextField
						id="description"
						value={values.description}
						onChange={handleChange}
						onBlur={handleBlur}
						size="small"
						fullWidth
						inputProps={{
							style: {
								...styles.input_field,
								backgroundColor: "transparent",
							},
						}}
						multiline
						sx={{
							"& .MuiInputBase-root.MuiOutlinedInput-root": {
								backgroundColor: "#e6e6e640",
							},
						}}
						disabled={!editProjectDetails}
					/>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<Typography sx={styles.field_label}>Project Type</Typography>
					<Autocomplete
						id="projectType"
						value={
							ProjectTypeArr.find(
								(x) => x.id === values.projectType
							) || {
								id: "",
								name: "",
							}
						}
						disabled
						onChange={(_, newValue) => {
							setFieldValue(`projectType`, newValue.id);
							setFieldValue("collectiveAgreementCrew", "MPPCA");
							if (newValue.id !== "FEATURE") {
								setFieldValue("projectCategory", "NA");
								setFieldValue(
									"categoryDescription",
									"Not subject to a category loading"
								);
								setFieldValue("collectiveAgreementCast", "ATPA/ATRRA");
							} else {
								setFieldValue("projectCategory", "");
								setFieldValue("categoryDescription", "");
								setFieldValue("collectiveAgreementCast", "AFFCA");
							}
						}}
						onBlur={handleBlur}
						disableClearable
						size="small"
						options={ProjectTypeArr}
						getOptionLabel={(option) => option.name}
						sx={styles.input_field}
						renderInput={(params) => (
							<TextField
								{...params}
								size="small"
								fullWidth
								onBlur={handleBlur}
								inputProps={{
									...params.inputProps,
									style: {
										...styles.input_field,
										backgroundColor: "transparent",
									},
								}}
							/>
						)}
					/>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<Typography sx={styles.field_label}>
						Project Category{" "}
						{editProjectDetails &&
							Boolean(values.projectType) &&
							values.projectType === "FEATURE" && (
								<span style={styles.error_text}>*</span>
							)}
					</Typography>
					<Autocomplete
						id="projectCategory"
						value={
							ProjectCategoryArr.find(
								(x) => x.id === values.projectCategory
							) || {
								id: "",
								name: "",
								description: "",
							}
						}
						onChange={(_, newValue) => {
							setFieldValue(`projectCategory`, newValue.id);
							setFieldValue("categoryDescription", newValue.description);
						}}
						disabled={
							!editProjectDetails ||
							(Boolean(values.projectType) &&
								values.projectType !== "FEATURE")
						}
						onBlur={handleBlur}
						disableClearable
						size="small"
						options={
							Boolean(values?.projectType)
								? values?.projectType === "FEATURE"
									? ProjectCategoryArr.filter((x) => x.id !== "NA")
									: ProjectCategoryArr
								: []
						}
						getOptionLabel={(option) => option.name}
						sx={styles.input_field}
						renderInput={(params) => (
							<TextField
								{...params}
								size="small"
								fullWidth
								onBlur={handleBlur}
								inputProps={{
									...params.inputProps,
									style: {
										...styles.input_field,
										backgroundColor: "transparent",
									},
								}}
							/>
						)}
					/>
					{touched?.projectCategory && errors?.projectCategory && (
						<Typography sx={styles.error_text}>
							{errors.projectCategory}
						</Typography>
					)}
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Typography sx={styles.field_label}>
						Category Description
					</Typography>
					<TextField
						id="categoryDescription"
						value={values.categoryDescription}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled
						size="small"
						fullWidth
						inputProps={{
							style: {
								...styles.input_field,
								backgroundColor: "transparent",
							},
						}}
						multiline
						sx={{
							"& .MuiInputBase-root.MuiOutlinedInput-root": {
								backgroundColor: "#e6e6e640",
							},
						}}
					/>
				</Grid>
				{Boolean(values.projectType) &&
					values.projectType === "FEATURE" && (
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Category Loading{" "}
								{editProjectDetails && (
									<span style={styles.error_text}>*</span>
								)}
							</Typography>
							<TextField
								id="categoryLoading"
								value={values.categoryLoading}
								onChange={(e) => {
									const value = e.target.value;
									const isValidNumber = /^\d*\.?\d{0,2}$/.test(value); // Allow integers or decimals up to 2 places
									if (isValidNumber) {
										setFieldValue("categoryLoading", value); // Store as string for precision while typing
									}
								}}
								disabled={!editProjectDetails}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Box
												sx={{
													backgroundColor: "#e6e6e640",
													borderTopRightRadius: "6px",
													borderBottomRightRadius: "6px",
													py: "5px",
													pl: 3,
													pr: 1.5,
													mr: -1.75,
													ml: -1,
												}}
											>
												<Typography>%</Typography>
											</Box>
										</InputAdornment>
									),
								}}
							/>
							{touched?.categoryLoading && errors?.categoryLoading && (
								<Typography sx={styles.error_text}>
									{errors.categoryLoading}
								</Typography>
							)}
						</Grid>
					)}
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<Typography sx={styles.field_label}>
						Collective Agreement Cast
					</Typography>
					<TextField
						id="collectiveAgreementCast"
						value={values.collectiveAgreementCast}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled
						size="small"
						fullWidth
						inputProps={{ style: styles.input_field }}
					/>
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<Typography sx={styles.field_label}>
						Collective Agreement Crew
					</Typography>
					<TextField
						id="collectiveAgreementCrew"
						value={values.collectiveAgreementCrew}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled
						size="small"
						fullWidth
						inputProps={{ style: styles.input_field }}
					/>
				</Grid>
				{Boolean(values.projectType) &&
					values.projectType !== "FEATURE" && (
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<Typography sx={styles.field_label}>
								Number of episodes{" "}
								{editProjectDetails && (
									<span style={styles.error_text}>*</span>
								)}
							</Typography>
							<TextField
								id="episodes"
								value={values.episodes}
								onChange={(e) => {
									const value = e.target.value;
									const isValidNumber = value.match(/^\d*$/); // Only allow digits
									if (isValidNumber) {
										if (value === "") {
											setFieldValue("episodes", "");
										}
										if (
											value !== "" &&
											!isNaN(value as unknown as number)
										) {
											setFieldValue("episodes", parseFloat(value));
										}
									}
								}}
								onBlur={handleBlur}
								size="small"
								fullWidth
								disabled={!editProjectDetails}
								inputProps={{ style: styles.input_field }}
							/>
							{touched?.episodes && errors?.episodes && (
								<Typography sx={styles.error_text}>
									{errors.episodes}
								</Typography>
							)}
						</Grid>
					)}
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<Typography sx={styles.field_label}>
						{`${
							Boolean(values.projectType) &&
							values.projectType !== "FEATURE"
								? "Duration of each episode"
								: "Duration"
						} (in minutes)`}{" "}
						{editProjectDetails && (
							<span style={styles.error_text}>*</span>
						)}
					</Typography>
					<TextField
						id="episodeDuration"
						value={values.episodeDuration}
						onChange={(e) => {
							const value = e.target.value;
							const isValidNumber = value.match(/^\d*$/); // Only allow digits
							if (isValidNumber) {
								if (value === "") {
									setFieldValue("episodeDuration", "");
								}
								if (
									value !== "" &&
									!isNaN(value as unknown as number)
								) {
									setFieldValue("episodeDuration", parseFloat(value));
								}
							}
						}}
						onBlur={handleBlur}
						size="small"
						fullWidth
						disabled={!editProjectDetails}
						inputProps={{ style: styles.input_field }}
					/>
					{touched?.episodeDuration && errors?.episodeDuration && (
						<Typography sx={styles.error_text}>
							{errors.episodeDuration}
						</Typography>
					)}
				</Grid>
				<Grid item lg={6} md={6} sm={6} xs={12}>
					<Typography sx={styles.field_label}>Filming Location</Typography>
					<TextField
						id="filmingLocation"
						value={values.filmingLocation}
						onChange={handleChange}
						onBlur={handleBlur}
						size="small"
						fullWidth
						disabled={!editProjectDetails}
						inputProps={{ style: styles.input_field }}
					/>
				</Grid>
				<Grid
					item
					lg={
						Boolean(values.projectType) &&
						values.projectType === "FEATURE"
							? 12
							: 6
					}
					md={
						Boolean(values.projectType) &&
						values.projectType === "FEATURE"
							? 12
							: 6
					}
					sm={
						Boolean(values.projectType) &&
						values.projectType === "FEATURE"
							? 12
							: 6
					}
					xs={12}
				>
					<Typography sx={styles.field_label}>Shoot Days</Typography>
					<TextField
						id="shootDays"
						value={values.shootDays}
						onChange={(e) => {
							const value = e.target.value;
							const isValidNumber = value.match(/^\d*$/); // Only allow digits
							if (isValidNumber) {
								if (value === "") {
									setFieldValue("shootDays", "");
								}
								if (
									value !== "" &&
									!isNaN(value as unknown as number)
								) {
									setFieldValue("shootDays", parseFloat(value));
								}
							}
						}}
						onBlur={handleBlur}
						size="small"
						fullWidth
						disabled={!editProjectDetails}
						inputProps={{ style: styles.input_field }}
					/>
					{touched?.shootDays && errors?.shootDays && (
						<Typography sx={styles.error_text}>
							{errors.shootDays}
						</Typography>
					)}
				</Grid>
				{(values.projectType === "FEATURE" ||
					values.projectType === "TV_SERIES" ||
					values.projectType === "TELEMOVIE") &&
					values?.script && (
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>Script</Typography>
							<TextField
								id="filmingLocation"
								value={values?.scriptName || ""}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								disabled
								inputProps={{ style: styles.input_field }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Box
												sx={{
													backgroundColor: "#e6e6e640",
													borderTopRightRadius: "6px",
													borderBottomRightRadius: "6px",
													py: "3px",
													pl: 3,
													pr: 1,
													mr: -1.75,
													ml: -1,
												}}
											>
												<IconButton
													size="small"
													onClick={() =>
														window.open(values.script, "_blank")
													}
												>
													<img
														src={images.DOWNLOAD_ICON_NEW}
														alt="download"
														height={15}
													/>
												</IconButton>
											</Box>
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					)}
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>
						Start Date{" "}
						{editProjectDetails && (
							<span style={styles.error_text}>*</span>
						)}
					</Typography>
					<DatePickerInput
						disabled={!editProjectDetails}
						textValue={
							Boolean(values.projectStartDate)
								? moment(values.projectStartDate).format("DD MMMM YYYY")
								: ""
						}
						value={
							Boolean(values.projectStartDate)
								? new Date(values.projectStartDate)
								: null
						}
						onChange={(newValue: any) => {
							setFieldTouched("projectStartDate", true);
							setFieldValue(
								"projectStartDate",
								moment(newValue).format("YYYY-MM-DD")
							);
						}}
						onOpen={() => setFieldTouched("projectStartDate", true)}
						onCloseCustom={() => {}}
					/>
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>
						End Date{" "}
						{editProjectDetails && (
							<span style={styles.error_text}>*</span>
						)}
					</Typography>
					<DatePickerInput
						disabled={!editProjectDetails}
						textValue={
							Boolean(values.projectEndDate)
								? moment(values.projectEndDate).format("DD MMMM YYYY")
								: ""
						}
						value={
							Boolean(values.projectEndDate)
								? new Date(values.projectEndDate)
								: null
						}
						onChange={(newValue: any) => {
							setFieldTouched("projectEndDate", true);
							setFieldValue(
								"projectEndDate",
								moment(newValue).format("YYYY-MM-DD")
							);
						}}
						onOpen={() => setFieldTouched("projectEndDate", true)}
						onCloseCustom={() => {}}
					/>
					{touched?.projectEndDate && errors?.projectEndDate && (
						<Typography sx={styles.error_text}>
							{errors.projectEndDate}
						</Typography>
					)}
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>
						Start of Pre-production
					</Typography>
					<DatePickerInput
						disabled={!editProjectDetails}
						textValue={
							Boolean(values.startPreProduction)
								? moment(values.startPreProduction).format(
										"DD MMMM YYYY"
								  )
								: ""
						}
						value={
							Boolean(values.startPreProduction)
								? new Date(values.startPreProduction as string)
								: null
						}
						onChange={(newValue: any) => {
							setFieldTouched("startPreProduction", true);
							setFieldValue(
								"startPreProduction",
								moment(newValue).format("YYYY-MM-DD")
							);
						}}
						onOpen={() => setFieldTouched("startPreProduction", true)}
						onCloseCustom={() => {}}
					/>
					{touched?.startPreProduction && errors?.startPreProduction && (
						<Typography sx={styles.error_text}>
							{errors.startPreProduction}
						</Typography>
					)}
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>
						Start of Principal Photography
					</Typography>
					<DatePickerInput
						disabled={!editProjectDetails}
						textValue={
							Boolean(values.startPrincipalPhotography)
								? moment(values.startPrincipalPhotography).format(
										"DD MMMM YYYY"
								  )
								: ""
						}
						value={
							Boolean(values.startPrincipalPhotography)
								? new Date(values.startPrincipalPhotography as string)
								: null
						}
						onChange={(newValue: any) => {
							setFieldTouched("startPrincipalPhotography", true);
							setFieldValue(
								"startPrincipalPhotography",
								moment(newValue).format("YYYY-MM-DD")
							);
						}}
						onOpen={() =>
							setFieldTouched("startPrincipalPhotography", true)
						}
						onCloseCustom={() => {}}
					/>
					{touched?.startPrincipalPhotography &&
						errors?.startPrincipalPhotography && (
							<Typography sx={styles.error_text}>
								{errors.startPrincipalPhotography}
							</Typography>
						)}
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>
						Start of Rough Cut
					</Typography>
					<DatePickerInput
						disabled={!editProjectDetails}
						textValue={
							Boolean(values.startRoughCut)
								? moment(values.startRoughCut).format("DD MMMM YYYY")
								: ""
						}
						value={
							Boolean(values.startRoughCut)
								? new Date(values.startRoughCut as string)
								: null
						}
						onChange={(newValue: any) => {
							setFieldTouched("startRoughCut", true);
							setFieldValue(
								"startRoughCut",
								moment(newValue).format("YYYY-MM-DD")
							);
						}}
						onOpen={() => setFieldTouched("startRoughCut", true)}
						onCloseCustom={() => {}}
					/>
					{touched?.startRoughCut && errors?.startRoughCut && (
						<Typography sx={styles.error_text}>
							{errors.startRoughCut}
						</Typography>
					)}
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>
						Start of Fine Cut
					</Typography>
					<DatePickerInput
						disabled={!editProjectDetails}
						textValue={
							Boolean(values.startFineCut)
								? moment(values.startFineCut).format("DD MMMM YYYY")
								: ""
						}
						value={
							Boolean(values.startFineCut)
								? new Date(values.startFineCut as string)
								: null
						}
						onChange={(newValue: any) => {
							setFieldTouched("startFineCut", true);
							setFieldValue(
								"startFineCut",
								moment(newValue).format("YYYY-MM-DD")
							);
						}}
						onOpen={() => setFieldTouched("startFineCut", true)}
						onCloseCustom={() => {}}
					/>
					{touched?.startFineCut && errors?.startFineCut && (
						<Typography sx={styles.error_text}>
							{errors.startFineCut}
						</Typography>
					)}
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>
						Start of Post-production
					</Typography>
					<DatePickerInput
						disabled={!editProjectDetails}
						textValue={
							Boolean(values.startPostProduction)
								? moment(values.startPostProduction).format(
										"DD MMMM YYYY"
								  )
								: ""
						}
						value={
							Boolean(values.startPostProduction)
								? new Date(values.startPostProduction as string)
								: null
						}
						onChange={(newValue: any) => {
							setFieldTouched("startPostProduction", true);
							setFieldValue(
								"startPostProduction",
								moment(newValue).format("YYYY-MM-DD")
							);
						}}
						onOpen={() => setFieldTouched("startPostProduction", true)}
						onCloseCustom={() => {}}
					/>
					{touched?.startPostProduction && errors?.startPostProduction && (
						<Typography sx={styles.error_text}>
							{errors.startPostProduction}
						</Typography>
					)}
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>Completion Date</Typography>
					<DatePickerInput
						disabled={!editProjectDetails}
						textValue={
							Boolean(values.completionDate)
								? moment(values.completionDate).format("DD MMMM YYYY")
								: ""
						}
						value={
							Boolean(values.completionDate)
								? new Date(values.completionDate as string)
								: null
						}
						onChange={(newValue: any) => {
							setFieldTouched("completionDate", true);
							setFieldValue(
								"completionDate",
								moment(newValue).format("YYYY-MM-DD")
							);
						}}
						onOpen={() => setFieldTouched("completionDate", true)}
						onCloseCustom={() => {}}
					/>
					{touched?.completionDate && errors?.completionDate && (
						<Typography sx={styles.error_text}>
							{errors.completionDate}
						</Typography>
					)}
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>Delivery Date</Typography>
					<DatePickerInput
						disabled={!editProjectDetails}
						textValue={
							Boolean(values.deliveryDate)
								? moment(values.deliveryDate).format("DD MMMM YYYY")
								: ""
						}
						value={
							Boolean(values.deliveryDate)
								? new Date(values.deliveryDate!)
								: null
						}
						onChange={(newValue: any) => {
							setFieldTouched("deliveryDate", true);
							setFieldValue(
								"deliveryDate",
								moment(newValue).format("YYYY-MM-DD")
							);
						}}
						onOpen={() => setFieldTouched("deliveryDate", true)}
						onCloseCustom={() => {}}
					/>
					{touched?.deliveryDate && errors?.deliveryDate && (
						<Typography sx={styles.error_text}>
							{errors.deliveryDate}
						</Typography>
					)}
				</Grid>
				<Grid
					item
					lg={6}
					md={6}
					sm={6}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>Release/ TX Date</Typography>
					<DatePickerInput
						disabled={!editProjectDetails}
						textValue={
							Boolean(values.filmingDate)
								? moment(values.filmingDate).format("DD MMMM YYYY")
								: ""
						}
						value={
							Boolean(values.filmingDate)
								? new Date(values.filmingDate)
								: null
						}
						onChange={(newValue: any) => {
							setFieldTouched("filmingDate", true);
							setFieldValue(
								"filmingDate",
								moment(newValue).format("YYYY-MM-DD")
							);
						}}
						onOpen={() => setFieldTouched("filmingDate", true)}
						onCloseCustom={() => {}}
					/>
					{touched?.filmingDate && errors?.filmingDate && (
						<Typography sx={styles.error_text}>
							{errors.filmingDate}
						</Typography>
					)}
				</Grid>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>Currency</Typography>
					<Autocomplete
						id="currency"
						value={
							CurrencyArr.find((x) => x.id === values.currency) || {
								id: "",
								name: "",
							}
						}
						disabled={!editProjectDetails}
						onChange={(_, newValue) => {
							if (Boolean(newValue)) {
								setFieldValue(`currency`, newValue!.id);
							} else {
								setFieldValue("currency", "");
							}
						}}
						onBlur={handleBlur}
						disableClearable
						size="small"
						options={CurrencyArr}
						getOptionLabel={(option) => option.name}
						sx={styles.input_field}
						renderInput={(params) => (
							<TextField
								{...params}
								size="small"
								fullWidth
								onBlur={handleBlur}
								inputProps={{
									...params.inputProps,
									style: {
										...styles.input_field,
										backgroundColor: "transparent",
									},
								}}
							/>
						)}
					/>
				</Grid>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ display: { xs: "block", md: "none" } }}
				>
					<Typography sx={styles.field_label}>Date Format</Typography>
					<Autocomplete
						id="dateFormat"
						value={
							DateFormatArr.find((x) => x.id === values.dateFormat) || {
								id: "",
								name: "",
							}
						}
						disabled={!editProjectDetails}
						onChange={(_, newValue) => {
							setFieldValue(`dateFormat`, newValue.id);
						}}
						onBlur={handleBlur}
						disableClearable
						size="small"
						options={DateFormatArr}
						getOptionLabel={(option) => option.name}
						sx={styles.input_field}
						renderInput={(params) => (
							<TextField
								{...params}
								size="small"
								fullWidth
								onBlur={handleBlur}
								inputProps={{
									...params.inputProps,
									style: {
										...styles.input_field,
										backgroundColor: "transparent",
									},
								}}
							/>
						)}
					/>
				</Grid>
			</Grid>
			{warningModal && (
				<WarningModal
					open={warningModal}
					setOpen={setWarningModal}
					handleSubmit={handleSubmit}
					resetForm={resetForm}
				/>
			)}
			{openImageCropUploader && (
				<ProjectPhotoCropper
					open={openImageCropUploader}
					setOpen={setOpenImageCropUploader}
					width={148.5}
					height={220}
					imgSrc={values?.profile_picture}
					setImgSrc={(file: any) => setFieldValue("profile_picture", file)}
				/>
			)}
		</Box>
	);
}

export default ProjectDetailsFormFormik;
