import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

interface IFiltersProps {
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
}

function Filters(props: IFiltersProps) {
  const { sortBy, setSortBy } = props;

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography
          sx={{ fontSize: "14px", color: "#00000080", fontWeight: 600 }}
        >
          Sort By
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <RadioGroup
          value={sortBy}
          onChange={(e) => {
            setSortBy(e.target.value);
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Radio
              value="name"
              size="small"
              checkedIcon={
                <RadioButtonChecked
                  fontSize="small"
                  sx={{ color: "#6f6f6f" }}
                />
              }
              icon={
                <RadioButtonUnchecked
                  fontSize="small"
                  sx={{ color: "#6f6f6f" }}
                />
              }
            />
            <Typography sx={{ color: "#00000070", fontSize: "14px" }}>
              Name (A-Z)
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Radio
              value="-name"
              size="small"
              checkedIcon={
                <RadioButtonChecked
                  fontSize="small"
                  sx={{ color: "#6f6f6f" }}
                />
              }
              icon={
                <RadioButtonUnchecked
                  fontSize="small"
                  sx={{ color: "#6f6f6f" }}
                />
              }
            />
            <Typography sx={{ color: "#00000070", fontSize: "14px" }}>
              Name (Z-A)
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Radio
              value="additional_details__clearance_script_scene"
              size="small"
              checkedIcon={
                <RadioButtonChecked
                  fontSize="small"
                  sx={{ color: "#6f6f6f" }}
                />
              }
              icon={
                <RadioButtonUnchecked
                  fontSize="small"
                  sx={{ color: "#6f6f6f" }}
                />
              }
            />
            <Typography sx={{ color: "#00000070", fontSize: "14px" }}>
              Scene (Start-End)
            </Typography>
          </Box>
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

export default Filters;
