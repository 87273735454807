import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Dialog, Grid, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { IModalProps } from "models/common";
import { DELETE_PROJECTs, UPDATE_PROJECT } from "graphql/projects";
import { useStore } from "utils/store";
import { RouteNames } from "routes/routeNames";
import { NewOutlinedBtn, NewRedAlertContainedButton } from "common/Buttons";
import { images } from "assets/images";
import { colors } from "theme/colors";

interface IDeleteModalProps extends IModalProps {
  projectId: string;
  refetch: any;
}

const DeleteProjectModal = (props: IDeleteModalProps) => {
  const { open, setOpen, projectId, refetch } = props;

  const { setLoading } = useStore();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const [fireUpdateProjectApi, { loading: updating }] = useMutation(
    UPDATE_PROJECT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateProject } = data;
        const { status, message } = updateProject;
        handleClose();
        if (status) {
          toast.success(message);
          refetch();
        } else {
          toast.error(message);
        }
      },
    }
  );

  const [fireDeleteProjectApi, { loading }] = useMutation(DELETE_PROJECTs, {
    onCompleted: (data) => {
      setLoading(false);
      const { deleteProject } = data;
      const { status, message } = deleteProject;
      handleClose();
      if (status) {
        toast.success(message);
        navigate(RouteNames.PROJECTS);
      } else {
        toast.error(message);
      }
    },
  });

  const handleDelete = () => {
    fireDeleteProjectApi({ variables: { ids: [projectId] } });
  };

  useEffect(() => {
    setLoading(loading || updating);
  }, [loading, setLoading, updating]);

  const AlertImage = () => (
    <img src={images.ALERT_NEW} alt="alert" height={22} />
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "24px", p: 3, width: "465px" } }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={images.ALERT_NEW}
              sx={{ backgroundColor: "#ED1C2420" }}
              slots={{ img: AlertImage }}
            />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              color: colors.primaryBlack,
              textAlign: "center",
            }}
          >
            Delete Project?
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{ fontSize: "13px", textAlign: "justify", color: "#00000080" }}
          >
            Once <span style={{ fontWeight: 600 }}>deleted</span>, the project
            and all associated data cannot be recovered.
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{ fontSize: "13px", textAlign: "justify", color: "#00000080" }}
          >
            Alternatively, you can{" "}
            <span style={{ fontWeight: 600 }}>archive</span> the project, and
            preserve your data and restore it later.
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <NewOutlinedBtn
                variant="outlined"
                sx={{
                  color: colors.primaryBlack,
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleClose}
              >
                Cancel
              </NewOutlinedBtn>
            </Box>
            <Box sx={{ ml: 2 }}>
              <NewRedAlertContainedButton
                sx={{
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleDelete}
              >
                Delete
              </NewRedAlertContainedButton>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "13px",
              textAlign: "center",
              color: "#00000080",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              fireUpdateProjectApi({
                variables: {
                  payload: {
                    id: projectId,
                    projectStatus: 2,
                  },
                },
              })
            }
          >
            Archive Project
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteProjectModal;
