import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { Close, Person } from "@mui/icons-material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";

import { IModalProps, IPhoneNumber } from "models/common";
import { images } from "assets/images";
import { GlobalLabels, ManageAdminsLabels } from "common/AppConstants";
import { useStore } from "utils/store";
import { CountryCodeArr } from "utils/constants";
import { ADD_ADMIN, GET_ADMIN_DETAILS, UPDATE_ADMIN } from "graphql/admin";
import PhoneNumberInput from "common/PhoneNumberInput";

import { ManageAdminsStyles as styles } from "./styles";

interface IAdminFields {
	id: string;
	fullName: string;
	email: string;
	mobile: IPhoneNumber;
	profile_picture: any;
}

interface IAddEditAdminModalProps extends IModalProps {
	selectedAdmin: string;
	setSelectedAdmin: Dispatch<SetStateAction<string>>;
	setSuccessModal: Dispatch<SetStateAction<boolean>>;
	setErrorModal: Dispatch<SetStateAction<boolean>>;
	setSuccessText: Dispatch<SetStateAction<string>>;
	setErrorText: Dispatch<SetStateAction<string>>;
	refetchTableData: any;
}

const AddEditAdminModal = (props: IAddEditAdminModalProps) => {
	const {
		open,
		setOpen,
		selectedAdmin,
		setSelectedAdmin,
		setSuccessModal,
		setErrorModal,
		setSuccessText,
		setErrorText,
		refetchTableData,
	} = props;

	const { setLoading } = useStore();

	const [initialValues, setInitialValues] = useState<IAdminFields>({
		id: "",
		fullName: "",
		mobile: {
			countryCode: "61",
			nationalNumber: null,
			rawInput: "",
		},
		email: "",
		profile_picture: null,
	});

	const modalTitle = Boolean(selectedAdmin)
		? `${GlobalLabels.EDIT} Admin`
		: ManageAdminsLabels.ADD_ADMIN;
	const btnTitle = Boolean(selectedAdmin)
		? GlobalLabels.UPDATE
		: `${GlobalLabels.CREATE} Admin`;

	const handleClose = () => {
		setSelectedAdmin("");
		setOpen(false);
	};

	const handleDialogClose = (_: any, reason: string) => {
		if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
			handleClose();
		}
	};

	const [getAdminDetails, { loading: gettingDetails }] = useLazyQuery(
		GET_ADMIN_DETAILS,
		{
			onCompleted: (data) => {
				setLoading(false);
				const { user } = data;
				if (Boolean(user)) {
					const { id, fullName, mobile, email, profilePicture } = user;
					setInitialValues({
						id,
						fullName,
						email,
						mobile: {
							countryCode: mobile?.countryCode || "61",
							nationalNumber: mobile?.nationalNumber || null,
							rawInput: mobile?.rawInput || "",
						},
						profile_picture: Boolean(profilePicture)
							? profilePicture
							: null,
					});
				}
			},
			fetchPolicy: "network-only",
			nextFetchPolicy: "network-only",
		}
	);

	const [fireAddAdminApi, { loading }] = useMutation(ADD_ADMIN, {
		onCompleted: (data) => {
			setLoading(false);
			const { createUser } = data;
			const { status, message } = createUser;
			handleClose();
			if (status) {
				setSuccessText(message);
				setSuccessModal(true);
				refetchTableData();
			} else {
				setErrorText(message);
				setErrorModal(true);
			}
		},
	});

	const [fireUpdateAdminApi, { loading: updating }] = useMutation(
		UPDATE_ADMIN,
		{
			onCompleted: (data) => {
				setLoading(false);
				const { updateUser } = data;
				const { status, message } = updateUser;
				handleClose();
				if (status) {
					setSuccessText(message);
					setSuccessModal(true);
					refetchTableData();
				} else {
					setErrorText(message);
					setErrorModal(true);
				}
			},
		}
	);

	const handleUpsertAdmin = (values: IAdminFields) => {
		const { id, profile_picture, email, mobile, ...rest } = values;

		if (Boolean(selectedAdmin)) {
			fireUpdateAdminApi({
				variables: {
					payload: {
						id,
						mobile: `${
							CountryCodeArr.find((x) => x.id === mobile.countryCode)
								?.name
						} ${mobile.nationalNumber}`,
						...rest,
						...(profile_picture === null && { removeProfilePhoto: true }),
					},
					...(Boolean(profile_picture) &&
						typeof profile_picture !== "string" && {
							file: profile_picture,
						}),
				},
			});
		} else {
			fireAddAdminApi({
				variables: {
					payload: {
						userDetails: {
							mobile: `${
								CountryCodeArr.find((x) => x.id === mobile.countryCode)
									?.name
							} ${mobile.nationalNumber}`,
							email: email.trim(),
							...rest,
							roles: ["super_admin"],
						},
					},
					...(Boolean(profile_picture) &&
						typeof profile_picture !== "string" && {
							file: profile_picture,
						}),
				},
			});
		}
	};

	useEffect(() => {
		if (Boolean(selectedAdmin)) {
			getAdminDetails({ variables: { id: selectedAdmin } });
		}
	}, [getAdminDetails, selectedAdmin]);

	useEffect(() => {
		setLoading(gettingDetails || loading || updating);
	}, [gettingDetails, loading, setLoading, updating]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				fullName: Yup.string().required("Please enter your full name"),
				email: Yup.string()
					.required("Please enter your email address")
					.email("Please enter a valid email address"),
				mobile: Yup.object().shape({
					nationalNumber: Yup.string().required(
						"Please enter your contact number"
					),
				}),
			})}
			onSubmit={handleUpsertAdmin}
			enableReinitialize
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleBlur,
				errors,
				touched,
				isValid,
				handleSubmit,
			}) => {
				return (
					<Dialog open={open} onClose={handleDialogClose}>
						<DialogTitle sx={styles.flex_JCsb_Acenter}>
							<Typography sx={styles.add_admin_text}>
								{modalTitle}
							</Typography>
							<IconButton onClick={handleClose}>
								<Close />
							</IconButton>
						</DialogTitle>
						<DialogContent dividers>
							<Form onSubmit={handleSubmit}>
								<Grid container spacing={4} sx={{ px: 3, pb: 3 }}>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Box sx={styles.profile_pic_container}>
											{/* <IconButton>
                    <img src={images.CAMERA} alt="camera" />
                  </IconButton>
                  <Avatar sx={styles.profile_pic_avatar}></Avatar>
                  <IconButton>
                    <img src={images.DELETE} alt="delete" />
                  </IconButton> */}
											<Box>
												<Upload
													accept=".jpeg, .jpg, .png"
													beforeUpload={(file: RcFile, __) => {
														setFieldValue(
															"profile_picture",
															file
														);
														return Promise.resolve();
													}}
												>
													<IconButton>
														<img
															src={images.CAMERA}
															alt="camera"
														/>
													</IconButton>
												</Upload>
											</Box>
											{Boolean(values.profile_picture) ? (
												<Avatar
													sx={styles.profile_pic_avatar}
													src={
														typeof values.profile_picture ===
														"string"
															? values.profile_picture
															: URL.createObjectURL(
																	values.profile_picture
															  )
													}
												/>
											) : (
												<Avatar sx={styles.profile_pic_avatar}>
													<Person sx={{ fontSize: "50px" }} />
												</Avatar>
											)}
											<Box>
												<IconButton
													onClick={() =>
														setFieldValue("profile_picture", null)
													}
												>
													<img src={images.DELETE} alt="delete" />
												</IconButton>
											</Box>
										</Box>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											{ManageAdminsLabels.ADMIN_FORM.FULL_NAME}{" "}
											<span style={{ color: "#D3010E" }}>*</span>
										</Typography>
										<TextField
											id="fullName"
											value={values.fullName}
											onChange={handleChange}
											onBlur={handleBlur}
											size="small"
											fullWidth
											inputProps={{ style: styles.text_input }}
										/>
										{touched?.fullName && errors?.fullName && (
											<Typography sx={styles.error_text}>
												{errors?.fullName}
											</Typography>
										)}
									</Grid>
									<Grid item lg={6} md={6} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											{ManageAdminsLabels.ADMIN_FORM.EMAIL}{" "}
											<span style={{ color: "#D3010E" }}>*</span>
										</Typography>
										<TextField
											id="email"
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
											size="small"
											fullWidth
											disabled={Boolean(selectedAdmin)}
											inputProps={{ style: styles.text_input }}
										/>
										{touched?.email && errors?.email && (
											<Typography sx={styles.error_text}>
												{errors?.email}
											</Typography>
										)}
									</Grid>
									<Grid item lg={6} md={6} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											{ManageAdminsLabels.ADMIN_FORM.MOBILE}{" "}
											<span style={{ color: "#D3010E" }}>*</span>
										</Typography>
										<PhoneNumberInput
											value={
												values.mobile.nationalNumber !== null
													? values.mobile.nationalNumber
													: Boolean(values.mobile.rawInput)
													? values.mobile.rawInput.replaceAll(
															" ",
															""
													  )
													: ""
											}
											onChange={(e) => {
												setFieldValue(
													"mobile.nationalNumber",
													e.target.value.toString()
												);
											}}
											onBlur={handleBlur("mobile.nationalNumber")}
											countryCode={values.mobile.countryCode}
											setCountryCode={(code: string) =>
												setFieldValue("mobile.countryCode", code)
											}
										/>
										{touched?.mobile?.nationalNumber &&
											errors?.mobile?.nationalNumber && (
												<Typography sx={styles.error_text}>
													{errors?.mobile?.nationalNumber}
												</Typography>
											)}
									</Grid>
								</Grid>
							</Form>
						</DialogContent>
						<DialogActions sx={styles.modal_footer_container}>
							<Button
								type="submit"
								variant="contained"
								disabled={loading || updating || !isValid}
								sx={styles.disabled_btn}
								onClick={() => handleSubmit()}
							>
								{btnTitle}
							</Button>
						</DialogActions>
					</Dialog>
				);
			}}
		</Formik>
	);
};

export default AddEditAdminModal;
