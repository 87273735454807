import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import {
  CheckOutlined,
  FiberManualRecord,
  KeyboardArrowDown,
  ModeEditOutlineOutlined,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";

import { ProjectStatusMapping } from "utils/constants";
import { IEditProjectFields } from "models/projects";
import DatePickerInput from "common/DatePickerInput";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface IKeyDatesFormProps {
  initialValues: IEditProjectFields;
  projectStatus: number;
  setProjectStatus: Dispatch<SetStateAction<number>>;
  fireUpdateProjectApi: any;
  handleUpdateProj: any;
  refetch: any;
}

function KeyDatesForm(props: IKeyDatesFormProps) {
  const {
    projectStatus,
    initialValues,
    setProjectStatus,
    fireUpdateProjectApi,
    handleUpdateProj,
    refetch,
  } = props;

  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("PROJECT_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [editProjectDetails, setEditProjectDetails] = useState(false);
  const [openProjStatusMenu, setOpenProjStatusMenu] = useState(false);
  const [projStatusMenuAnchor, setProjStatusMenuAnchor] =
    useState<HTMLElement | null>();

  const handleProjStatusClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpenProjStatusMenu(true);
    setProjStatusMenuAnchor(e.currentTarget);
  };

  const handleProjStatusClose = () => {
    setOpenProjStatusMenu(false);
    setProjStatusMenuAnchor(null);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: IEditProjectFields) =>
        handleUpdateProj(values, setEditProjectDetails)
      }
      validationSchema={Yup.object().shape({
        projectStartDate: Yup.string().required(
          "Please select the project start date"
        ),
        projectEndDate: Yup.string()
          .required("Please select the project end date")
          .when("projectStartDate", (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "End Date should be after Start Date",
              test: (endDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(endDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(endDate);
                }
                return true;
              },
            });
          }),
        filmingDate: Yup.string().when(
          "projectStartDate",
          (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "Release/ TX Date should be after Start Date",
              test: (filmDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(filmDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(filmDate);
                }
                return true;
              },
            });
          }
        ),
        startPreProduction: Yup.string().when(
          "projectStartDate",
          (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "Pre Production Date should be after Start Date",
              test: (filmDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(filmDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(filmDate);
                }
                return true;
              },
            });
          }
        ),
        startPrincipalPhotography: Yup.string().when(
          "projectStartDate",
          (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "Principal Photography Date should be after Start Date",
              test: (filmDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(filmDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(filmDate);
                }
                return true;
              },
            });
          }
        ),
        startPostProduction: Yup.string().when(
          "projectStartDate",
          (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "Post Production Date should be after Start Date",
              test: (filmDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(filmDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(filmDate);
                }
                return true;
              },
            });
          }
        ),
        startRoughCut: Yup.string().when(
          "projectStartDate",
          (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "Rough Cut Date should be after Start Date",
              test: (filmDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(filmDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(filmDate);
                }
                return true;
              },
            });
          }
        ),
        startFineCut: Yup.string().when(
          "projectStartDate",
          (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "Fine Cut Date should be after Start Date",
              test: (filmDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(filmDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(filmDate);
                }
                return true;
              },
            });
          }
        ),
        completionDate: Yup.string()
          .when("projectStartDate", (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "Completion Date should be after Start Date",
              test: (filmDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(filmDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(filmDate);
                }
                return true;
              },
            });
          })
          .when("startPostProduction", (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "Completion Date should be after Post Production Date",
              test: (filmDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(filmDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(filmDate);
                }
                return true;
              },
            });
          }),
      })}
      enableReinitialize
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        handleSubmit,
      }) => (
        <Box>
          <Box
            sx={{
              position: "absolute",
              top: 181,
              right:
                Boolean(ROLE_PERMISSIONS) &&
                projectEditIndex !== undefined &&
                ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]?.PROJECT_EDIT
                  ? 20
                  : 45,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                sx={{
                  mr: 1.5,
                  backgroundColor: (ProjectStatusMapping as any)[projectStatus]
                    .bg,
                  color: (ProjectStatusMapping as any)[projectStatus].color,
                  borderRadius: "8px",
                  py: 1,
                  px: 2,
                  ":hover": {
                    backgroundColor: (ProjectStatusMapping as any)[
                      projectStatus
                    ].bg,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: (ProjectStatusMapping as any)[
                      projectStatus
                    ]?.bg,
                    color: (ProjectStatusMapping as any)[projectStatus]?.color,
                  },
                  fontSize: "14px",
                }}
                disableElevation
                disableRipple
                disabled={
                  !(
                    Boolean(ROLE_PERMISSIONS) &&
                    projectEditIndex !== undefined &&
                    ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                      ?.PROJECT_EDIT
                  )
                }
                onClick={handleProjStatusClick}
                endIcon={
                  Boolean(ROLE_PERMISSIONS) &&
                  projectEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                    ?.PROJECT_EDIT ? (
                    <KeyboardArrowDown
                      fontSize="small"
                      sx={{
                        color: (ProjectStatusMapping as any)[projectStatus]
                          .color,
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
              >
                {(ProjectStatusMapping as any)[projectStatus].name}
              </Button>
              <Menu
                anchorEl={projStatusMenuAnchor}
                open={openProjStatusMenu}
                onClose={handleProjStatusClose}
                sx={styles.menu_container}
                elevation={0}
              >
                <MenuList>
                  {Object.keys(ProjectStatusMapping).map((item) => (
                    <MenuItem
                      key={item}
                      onClick={() => {
                        setProjectStatus(parseInt(item));
                        fireUpdateProjectApi({
                          variables: {
                            payload: {
                              id: values.id,
                              projectStatus: parseInt(item),
                            },
                          },
                          onCompleted: (data: any) => {
                            setLoading(false);
                            const { updateProject } = data;
                            const { status, message } = updateProject;
                            if (status) {
                              toast.success(message);
                              refetch();
                            } else {
                              toast.error(message);
                            }
                          },
                        });
                        handleProjStatusClose();
                      }}
                    >
                      <ListItemIcon>
                        <FiberManualRecord
                          fontSize="small"
                          sx={{
                            color: Boolean(projectStatus)
                              ? (ProjectStatusMapping as any)[item].color
                              : "",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: `${colors.primaryBlack}80`,
                          }}
                        >
                          {(ProjectStatusMapping as any)[item].name}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              {Boolean(ROLE_PERMISSIONS) &&
              projectEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                ?.PROJECT_EDIT ? (
                editProjectDetails ? (
                  <IconButton size="small" onClick={() => handleSubmit()}>
                    <CheckOutlined
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    onClick={() => setEditProjectDetails(true)}
                  >
                    <ModeEditOutlineOutlined
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  </IconButton>
                )
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                Start Date{" "}
                {editProjectDetails && <span style={styles.error_text}>*</span>}
              </Typography>
              <DatePickerInput
                disabled={!editProjectDetails}
                textValue={
                  Boolean(values.projectStartDate)
                    ? moment(values.projectStartDate).format("DD MMMM YYYY")
                    : ""
                }
                value={
                  Boolean(values.projectStartDate)
                    ? new Date(values.projectStartDate)
                    : null
                }
                onChange={(newValue: any) => {
                  setFieldTouched("projectStartDate", true);
                  setFieldValue(
                    "projectStartDate",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                onOpen={() => setFieldTouched("projectStartDate", true)}
                onCloseCustom={() => {}}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                End Date{" "}
                {editProjectDetails && <span style={styles.error_text}>*</span>}
              </Typography>
              <DatePickerInput
                disabled={!editProjectDetails}
                textValue={
                  Boolean(values.projectEndDate)
                    ? moment(values.projectEndDate).format("DD MMMM YYYY")
                    : ""
                }
                value={
                  Boolean(values.projectEndDate)
                    ? new Date(values.projectEndDate)
                    : null
                }
                onChange={(newValue: any) => {
                  setFieldTouched("projectEndDate", true);
                  setFieldValue(
                    "projectEndDate",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                onOpen={() => setFieldTouched("projectEndDate", true)}
                onCloseCustom={() => {}}
              />
              {touched?.projectEndDate && errors?.projectEndDate && (
                <Typography sx={styles.error_text}>
                  {errors.projectEndDate}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                Start of Pre-production
              </Typography>
              <DatePickerInput
                disabled={!editProjectDetails}
                textValue={
                  Boolean(values.startPreProduction)
                    ? moment(values.startPreProduction).format("DD MMMM YYYY")
                    : ""
                }
                value={
                  Boolean(values.startPreProduction)
                    ? new Date(values.startPreProduction as string)
                    : null
                }
                onChange={(newValue: any) => {
                  setFieldTouched("startPreProduction", true);
                  setFieldValue(
                    "startPreProduction",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                onOpen={() => setFieldTouched("startPreProduction", true)}
                onCloseCustom={() => {}}
              />
              {touched?.startPreProduction && errors?.startPreProduction && (
                <Typography sx={styles.error_text}>
                  {errors.startPreProduction}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                Start of Principal Photography
              </Typography>
              <DatePickerInput
                disabled={!editProjectDetails}
                textValue={
                  Boolean(values.startPrincipalPhotography)
                    ? moment(values.startPrincipalPhotography).format(
                        "DD MMMM YYYY"
                      )
                    : ""
                }
                value={
                  Boolean(values.startPrincipalPhotography)
                    ? new Date(values.startPrincipalPhotography as string)
                    : null
                }
                onChange={(newValue: any) => {
                  setFieldTouched("startPrincipalPhotography", true);
                  setFieldValue(
                    "startPrincipalPhotography",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                onOpen={() =>
                  setFieldTouched("startPrincipalPhotography", true)
                }
                onCloseCustom={() => {}}
              />
              {touched?.startPrincipalPhotography &&
                errors?.startPrincipalPhotography && (
                  <Typography sx={styles.error_text}>
                    {errors.startPrincipalPhotography}
                  </Typography>
                )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                Start of Rough Cut
              </Typography>
              <DatePickerInput
                disabled={!editProjectDetails}
                textValue={
                  Boolean(values.startRoughCut)
                    ? moment(values.startRoughCut).format("DD MMMM YYYY")
                    : ""
                }
                value={
                  Boolean(values.startRoughCut)
                    ? new Date(values.startRoughCut as string)
                    : null
                }
                onChange={(newValue: any) => {
                  setFieldTouched("startRoughCut", true);
                  setFieldValue(
                    "startRoughCut",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                onOpen={() => setFieldTouched("startRoughCut", true)}
                onCloseCustom={() => {}}
              />
              {touched?.startRoughCut && errors?.startRoughCut && (
                <Typography sx={styles.error_text}>
                  {errors.startRoughCut}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>Start of Fine Cut</Typography>
              <DatePickerInput
                disabled={!editProjectDetails}
                textValue={
                  Boolean(values.startFineCut)
                    ? moment(values.startFineCut).format("DD MMMM YYYY")
                    : ""
                }
                value={
                  Boolean(values.startFineCut)
                    ? new Date(values.startFineCut as string)
                    : null
                }
                onChange={(newValue: any) => {
                  setFieldTouched("startFineCut", true);
                  setFieldValue(
                    "startFineCut",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                onOpen={() => setFieldTouched("startFineCut", true)}
                onCloseCustom={() => {}}
              />
              {touched?.startFineCut && errors?.startFineCut && (
                <Typography sx={styles.error_text}>
                  {errors.startFineCut}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                Start of Post-production
              </Typography>
              <DatePickerInput
                disabled={!editProjectDetails}
                textValue={
                  Boolean(values.startPostProduction)
                    ? moment(values.startPostProduction).format("DD MMMM YYYY")
                    : ""
                }
                value={
                  Boolean(values.startPostProduction)
                    ? new Date(values.startPostProduction as string)
                    : null
                }
                onChange={(newValue: any) => {
                  setFieldTouched("startPostProduction", true);
                  setFieldValue(
                    "startPostProduction",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                onOpen={() => setFieldTouched("startPostProduction", true)}
                onCloseCustom={() => {}}
              />
              {touched?.startPostProduction && errors?.startPostProduction && (
                <Typography sx={styles.error_text}>
                  {errors.startPostProduction}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>Completion Date</Typography>
              <DatePickerInput
                disabled={!editProjectDetails}
                textValue={
                  Boolean(values.completionDate)
                    ? moment(values.completionDate).format("DD MMMM YYYY")
                    : ""
                }
                value={
                  Boolean(values.completionDate)
                    ? new Date(values.completionDate as string)
                    : null
                }
                onChange={(newValue: any) => {
                  setFieldTouched("completionDate", true);
                  setFieldValue(
                    "completionDate",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                onOpen={() => setFieldTouched("completionDate", true)}
                onCloseCustom={() => {}}
              />
              {touched?.completionDate && errors?.completionDate && (
                <Typography sx={styles.error_text}>
                  {errors.completionDate}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>Delivery Date</Typography>
              <DatePickerInput
                disabled={!editProjectDetails}
                textValue={
                  Boolean(values.deliveryDate)
                    ? moment(values.deliveryDate).format("DD MMMM YYYY")
                    : ""
                }
                value={
                  Boolean(values.deliveryDate)
                    ? new Date(values.deliveryDate!)
                    : null
                }
                onChange={(newValue: any) => {
                  setFieldTouched("deliveryDate", true);
                  setFieldValue(
                    "deliveryDate",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                onOpen={() => setFieldTouched("deliveryDate", true)}
                onCloseCustom={() => {}}
              />
              {touched?.deliveryDate && errors?.deliveryDate && (
                <Typography sx={styles.error_text}>
                  {errors.deliveryDate}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>Release/ TX Date</Typography>
              <DatePickerInput
                disabled={!editProjectDetails}
                textValue={
                  Boolean(values.filmingDate)
                    ? moment(values.filmingDate).format("DD MMMM YYYY")
                    : ""
                }
                value={
                  Boolean(values.filmingDate)
                    ? new Date(values.filmingDate)
                    : null
                }
                onChange={(newValue: any) => {
                  setFieldTouched("filmingDate", true);
                  setFieldValue(
                    "filmingDate",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                onOpen={() => setFieldTouched("filmingDate", true)}
                onCloseCustom={() => {}}
              />
              {touched?.filmingDate && errors?.filmingDate && (
                <Typography sx={styles.error_text}>
                  {errors.filmingDate}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
}

export default KeyDatesForm;
