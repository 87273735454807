import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import { Close, FiberManualRecord } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { ContractDocxLabels } from "common/AppConstants";
import { GET_VERSION_HISTORY } from "graphql/contracts";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import { ContractDocxStyles as styles } from "./styles";

interface IUser {
  fullName: string;
  profile_picture: any;
}

interface IVersionHistory {
  id: string;
  documentId: string;
  historyUserId: IUser;
  historyDate: string;
  historyChangeReason: string;
}

interface IProps {
  setActionsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

function VersionHistory(props: IProps) {
  const { setActionsDrawerOpen } = props;

  const params = useParams();
  const { setLoading } = useStore();

  const [history, setHistory] = useState<IVersionHistory[]>([
    {
      id: "1",
      documentId: params?.contract_id!,
      historyDate: "Current Version",
      historyUserId: { fullName: "", profile_picture: null },
      historyChangeReason: "",
    },
  ]);

  const { loading } = useQuery(GET_VERSION_HISTORY, {
    variables: {
      document_id: params?.contract_id,
      project_id: params?.project_id,
    },
    onCompleted: (data) => {
      const { getVersionHistory } = data;
      const { status, history: rawHistory } = getVersionHistory;
      if (status) {
        if (rawHistory.length > 0) {
          let tempHistory = [
            ...rawHistory.map((x: any) => ({
              ...x,
              historyUserId: {
                ...x.historyUserId,
                profile_picture: x.historyUserId.profilePicture,
              },
            })),
          ];
          tempHistory[0] = {
            ...tempHistory[0],
            historyDate: "Current Version",
            historyUserId: { fullName: "", profile_picture: null },
          };
          setHistory([...tempHistory]);
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const getDateTitle = (date: any) => {
    const today = moment();
    const newDate = moment(date);
    if (today.isSame(newDate, "date"))
      return `Today, ${moment(date).format("hh:mm a")}`;
    if (today.subtract(1, "day").isSame(newDate, "date"))
      return `Yesterday, ${moment(date).format("hh:mm a")}`;
    return newDate.format("DD MMMM YYYY, hh:mm a");
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={3} sx={styles.drawer_container}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_JCsb_Acenter}>
          <Typography sx={styles.drawer_header_text}>
            {ContractDocxLabels.VERSION_HISTORY}
          </Typography>
          <IconButton size="small" onClick={() => setActionsDrawerOpen(false)}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.mt2}>
        {history.map((his, index) => (
          <Box
            key={his.id}
            sx={[
              styles.timeline_container,
              {
                borderLeft:
                  index === history.length - 1
                    ? `3px dotted transparent`
                    : `3px dotted ${colors.whiteGrey400}70`,
              },
            ]}
          >
            <FiberManualRecord color="primary" sx={styles.timeline_dot} />
            <Box sx={{ ml: 1 }}>
              <Typography sx={[styles.timeline_name_text, { fontWeight: 600 }]}>
                {his.historyDate === "Current Version"
                  ? his.historyDate
                  : getDateTitle(his.historyDate)}
              </Typography>
              {his.historyDate !== "Current Version" && (
                <Box sx={[styles.flex_Acenter, { mt: 1.5 }]}>
                  {Boolean(his.historyUserId.profile_picture) ? (
                    <Avatar
                      sx={styles.timeline_avatar}
                      src={his.historyUserId.profile_picture}
                    />
                  ) : (
                    <Avatar sx={styles.timeline_avatar} />
                  )}
                  <Typography
                    sx={[
                      styles.timeline_name_text,
                      { color: colors.primaryBlack, fontSize: "14px" },
                    ]}
                  >
                    {his.historyUserId.fullName}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}

export default VersionHistory;
