import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  companyName: Yup.string().required("Please enter the company name"),
  registrationType: Yup.string()
    .required("Please enter the ACN")
    .matches(/^[0-9]{9}$/, "ACN must be 9 digits"),
  registrationNumber: Yup.string()
    .required("Please enter the ABN")
    .matches(/^[0-9]{11}$/, "ABN must be 11 digits"),
  placeOfIncorporation: Yup.string().required(
    "Please enter the place of incorporation"
  ),
  // postCode: Yup.string().matches(/^[0-9]{4}$/, "Post Code must be of 4 digits"),
  contactName: Yup.string().required("Please enter the contact name"),
  email: Yup.string()
    .required("Please enter the contact email")
    .email("Please enter a valid email"),
  soleDirector: Yup.object().when(
    "multipleDirectors",
    ([multipleDirectors], schema) => {
      return multipleDirectors
        ? schema.shape({
            name: Yup.string(),
            email: Yup.string().email("Please enter a valid email id"),
          })
        : schema.shape({
            name: Yup.string().required("Please enter the name"),
            email: Yup.string()
              .required("Please enter the email")
              .email("Please enter a valid email id"),
          });
    }
  ),
  directors: Yup.array().when(
    "multipleDirectors",
    ([multipleDirectors], schema) => {
      return multipleDirectors
        ? schema
            .of(
              Yup.object().shape({
                name: Yup.string().required("Please enter the name"),
                email: Yup.string()
                  .required("Please enter the email")
                  .email("Please enter a valid email id"),
              })
            )
            .min(2, "Please add atleast 2 directors")
        : schema;
    }
  ),
  authorizedSignatory: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Please enter the name"),
        email: Yup.string()
          .required("Please enter the email")
          .email("Please enter a valid email id"),
      })
    )
    .min(1, "Please add atleast 1 authorized signatory"),
});

export const validateArr = (arr: any, setArrErrors: any) => {
  let errors: string[] = [];
  const emailRE = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  for (let i = 0; i < arr.length; i++) {
    errors.push("");
  }
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].name === "" || arr[i].email === "") {
      errors[i] = "Please provide both name and email";
    }
    if (Boolean(arr[i].email) && !emailRE.test(arr[i].email)) {
      errors[i] = "Please enter a valid email id";
    }
  }
  setArrErrors(errors);
  return errors;
};

export const validateErrors = (errors: string[]) => {
  let flag = 0;
  for (let i = 0; i < errors.length; i++) {
    if (errors[i]) {
      flag = 1;
      break;
    }
  }
  return flag === 0;
};
