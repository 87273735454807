import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  CheckBoxOutlineBlankOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { FormikProps } from "formik";

import CheckboxWithLabel from "common/CheckboxWithLabel";
import { IArr } from "models/common";
import { GET_TEMPLATE_TYPES } from "graphql/templates";
import { colors } from "theme/colors";

import {
  ClientLevelPermissions,
  IPermissionArr,
  IRoleFields,
  ProjectLevelPermissions,
  checkIfAnyTempTypeUnChecked,
  getCheckedValue,
  setAllProjectSetupFalse,
  setFolderRestrictions,
} from "./utils";
import { AdminUsersStyles as styles } from "./styles";

interface IAccordionProps {
  module: IPermissionArr;
  formikBag: FormikProps<IRoleFields>;
  templateTypeArr: IArr[];
}

function Accordion(props: IAccordionProps) {
  const { module, formikBag, templateTypeArr } = props;
  const { values, setFieldValue } = formikBag;

  const [expanded, setExpanded] = useState(
    module.name === "Issues" ? true : false
  );
  const [issuesRadioValue, setIssuesRadioValue] = useState("all_issues");

  useEffect(() => {
    if (
      Boolean(values.my_issues_restrictions) &&
      (values?.my_issues_restrictions?.all_issues?.view ||
        values?.my_issues_restrictions?.all_issues?.edit)
    ) {
      setIssuesRadioValue("all_issues");
    } else if (
      Boolean(values.my_issues_restrictions) &&
      (values?.my_issues_restrictions?.my_issues?.view ||
        values?.my_issues_restrictions?.my_issues?.edit)
    ) {
      setIssuesRadioValue("my_issues");
    }
  }, [values.my_issues_restrictions]);

  return (
    <Fragment>
      <Box
        sx={{
          cursor: "pointer",
          width: "100%",
          display: "flex",
          alignItems: "center",
          pl: 6,
          pt: 2,
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Box sx={{ display: "flex", alignItems: "center", ml: -6 }}>
            <IconButton>
              {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Typography sx={[styles.actions_text, { color: colors.primary }]}>
              {module.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          {module.name === "Folders (ALL)" ? (
            <Box sx={[styles.flex_Acenter, { ml: -1 }]}>
              <CheckboxWithLabel
                disabled={
                  Boolean(values.folder_restrictions)
                    ? !checkIfAnyTempTypeUnChecked(
                        values.folder_restrictions,
                        "edit"
                      )
                    : false
                }
                label="Read"
                sx={{ mr: 1 }}
                labelStyle={styles.actions_text}
                onClick={(e) => e.stopPropagation()}
                icon={
                  !Boolean(module.view) ? (
                    <CheckBoxOutlineBlankOutlined />
                  ) : (
                    <CheckBoxOutlineBlankOutlined color="primary" />
                  )
                }
                checked={
                  Boolean(values.folder_restrictions)
                    ? !checkIfAnyTempTypeUnChecked(
                        values.folder_restrictions,
                        "view"
                      )
                    : false
                }
                onChange={(e) => {
                  setFolderRestrictions(
                    values.folder_restrictions,
                    "View",
                    templateTypeArr,
                    e.target.checked,
                    setFieldValue
                  );
                  const clearanceIndex = values.permission.findIndex((x) =>
                    x.hasOwnProperty("CLEARANCE_VIEW")
                  );
                  setFieldValue(`permission[${clearanceIndex}]`, {
                    CLEARANCE_VIEW: e.target.checked,
                  });
                }}
              />
              <CheckboxWithLabel
                label="Read & Write"
                labelStyle={styles.actions_text}
                onClick={(e) => e.stopPropagation()}
                icon={
                  !Boolean(module.edit) ? (
                    <CheckBoxOutlineBlankOutlined />
                  ) : (
                    <CheckBoxOutlineBlankOutlined color="primary" />
                  )
                }
                checked={
                  Boolean(values.folder_restrictions)
                    ? !checkIfAnyTempTypeUnChecked(
                        values.folder_restrictions,
                        "edit"
                      )
                    : false
                }
                onChange={(e) => {
                  setFolderRestrictions(
                    values.folder_restrictions,
                    "Edit",
                    templateTypeArr,
                    e.target.checked,
                    setFieldValue
                  );

                  const clearanceEditIndex = values.permission.findIndex((x) =>
                    x.hasOwnProperty("CLEARANCE_EDIT")
                  );
                  setFieldValue(`permission[${clearanceEditIndex}]`, {
                    CLEARANCE_EDIT: e.target.checked,
                  });

                  if (e.target.checked) {
                    setFolderRestrictions(
                      values.folder_restrictions,
                      "ViewEdit",
                      templateTypeArr,
                      e.target.checked,
                      setFieldValue
                    );

                    const clearanceViewIndex = values.permission.findIndex(
                      (x) => x.hasOwnProperty("CLEARANCE_VIEW")
                    );
                    setFieldValue(`permission[${clearanceViewIndex}]`, {
                      CLEARANCE_VIEW: e.target.checked,
                    });
                  }
                }}
              />
            </Box>
          ) : (
            <Box sx={{ ml: -1 }}>
              <RadioGroup
                row
                onClick={(e) => {
                  e.stopPropagation();
                }}
                value={issuesRadioValue}
                onChange={(e) => {
                  setIssuesRadioValue(e.target.value);
                  setExpanded(true);
                  setFieldValue("my_issues_restrictions", {
                    all_issues: { view: false, edit: false },
                    my_issues: { view: false, edit: false },
                  });
                }}
              >
                <FormControlLabel
                  value="all_issues"
                  control={
                    <Radio icon={<RadioButtonUnchecked color="primary" />} />
                  }
                  label={
                    <Typography sx={styles.actions_text}>All Issues</Typography>
                  }
                />
                <FormControlLabel
                  value="my_issues"
                  control={
                    <Radio icon={<RadioButtonUnchecked color="primary" />} />
                  }
                  label={
                    <Typography sx={styles.actions_text}>My Issues</Typography>
                  }
                />
              </RadioGroup>
            </Box>
          )}
        </Grid>
      </Box>
      {expanded &&
        module.name === "Folders (ALL)" &&
        templateTypeArr.map((tempType) => (
          <Box
            key={tempType.id}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              pl: 6,
              pt: 2,
            }}
          >
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={[styles.actions_text, { fontSize: "13px" }]}>
                  {tempType.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box sx={[styles.flex_Acenter, { ml: -1 }]}>
                <CheckboxWithLabel
                  disabled={
                    !Boolean(module.view) ||
                    (Boolean(values.folder_restrictions) &&
                      values.folder_restrictions![`${tempType.id}`]?.edit)
                  }
                  label="Read"
                  sx={{ mr: 1 }}
                  labelStyle={{ ...styles.actions_text }}
                  icon={
                    !Boolean(module.view) ? (
                      <CheckBoxOutlineBlankOutlined />
                    ) : (
                      <CheckBoxOutlineBlankOutlined color="primary" />
                    )
                  }
                  checked={
                    Boolean(values.folder_restrictions)
                      ? values.folder_restrictions![`${tempType.id}`]?.view ||
                        false
                      : false
                  }
                  onChange={(e) => {
                    setFieldValue(
                      "folder_restrictions",
                      Boolean(values.folder_restrictions)
                        ? {
                            ...values.folder_restrictions,
                            [tempType.id]: {
                              ...values.folder_restrictions![tempType.id],
                              view: e.target.checked,
                            },
                          }
                        : {
                            [tempType.id]: {
                              view: e.target.checked,
                              edit: false,
                            },
                          }
                    );
                    if (tempType.name === "Clearances") {
                      const clearanceIndex = values.permission.findIndex((x) =>
                        x.hasOwnProperty("CLEARANCE_VIEW")
                      );
                      setFieldValue(`permission[${clearanceIndex}]`, {
                        CLEARANCE_VIEW: e.target.checked,
                      });
                    }
                    if (
                      getCheckedValue(values.permission, module.view) &&
                      !e.target.checked
                    ) {
                      const permissionIndex = values.permission.findIndex((x) =>
                        x.hasOwnProperty(module.view)
                      );
                      setFieldValue(`permission[${permissionIndex}]`, {
                        [module.view]: e.target.checked,
                      });
                    }
                  }}
                />
                <CheckboxWithLabel
                  disabled={!Boolean(module.edit)}
                  label="Read & Write"
                  labelStyle={{ ...styles.actions_text }}
                  icon={
                    !Boolean(module.edit) ? (
                      <CheckBoxOutlineBlankOutlined />
                    ) : (
                      <CheckBoxOutlineBlankOutlined color="primary" />
                    )
                  }
                  checked={
                    Boolean(values.folder_restrictions)
                      ? values.folder_restrictions![`${tempType.id}`]?.edit ||
                        false
                      : false
                  }
                  onChange={(e) => {
                    setFieldValue(
                      "folder_restrictions",
                      Boolean(values.folder_restrictions)
                        ? {
                            ...values.folder_restrictions,
                            [tempType.id]: {
                              ...values.folder_restrictions![tempType.id],
                              edit: e.target.checked,
                              ...(e.target.checked && {
                                view: e.target.checked,
                              }),
                            },
                          }
                        : {
                            [tempType.id]: {
                              edit: e.target.checked,
                              view: e.target.checked,
                            },
                          }
                    );
                    if (tempType.name === "Clearances") {
                      const clearanceIndex = values.permission.findIndex((x) =>
                        x.hasOwnProperty("CLEARANCE_EDIT")
                      );
                      setFieldValue(`permission[${clearanceIndex}]`, {
                        CLEARANCE_EDIT: e.target.checked,
                      });
                    }
                    if (
                      getCheckedValue(values.permission, module.edit) &&
                      !e.target.checked
                    ) {
                      const permissionIndex = values.permission.findIndex((x) =>
                        x.hasOwnProperty(module.edit)
                      );
                      setFieldValue(`permission[${permissionIndex}]`, {
                        [module.edit]: e.target.checked,
                      });
                    }
                  }}
                />
              </Box>
            </Grid>
          </Box>
        ))}
      {expanded && module.name === "Issues" && (
        <Fragment>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              pl: 6,
              pt: 2,
            }}
          >
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={[styles.actions_text, { fontSize: "13px" }]}>
                  {issuesRadioValue === "my_issues"
                    ? "My Issues"
                    : "All Issues"}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              {issuesRadioValue === "all_issues" ? (
                <Box sx={[styles.flex_Acenter, { ml: -1 }]}>
                  <CheckboxWithLabel
                    label="Read"
                    sx={{ mr: 1 }}
                    labelStyle={styles.actions_text}
                    disabled={values.my_issues_restrictions?.all_issues?.edit}
                    icon={
                      !Boolean(module.view) ? (
                        <CheckBoxOutlineBlankOutlined />
                      ) : (
                        <CheckBoxOutlineBlankOutlined color="primary" />
                      )
                    }
                    checked={values.my_issues_restrictions?.all_issues?.view}
                    onChange={(e) => {
                      setFieldValue("my_issues_restrictions", {
                        ...values.my_issues_restrictions,
                        all_issues: {
                          ...values?.my_issues_restrictions?.all_issues,
                          view: e.target.checked,
                        },
                      });
                    }}
                  />
                  <CheckboxWithLabel
                    label="Read & Write"
                    labelStyle={styles.actions_text}
                    icon={
                      !Boolean(module.edit) ? (
                        <CheckBoxOutlineBlankOutlined
                          sx={{ color: "#d6d6d6" }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankOutlined color="primary" />
                      )
                    }
                    checked={values.my_issues_restrictions?.all_issues?.edit}
                    onChange={(e) => {
                      setFieldValue(`my_issues_restrictions`, {
                        ...values.my_issues_restrictions,
                        all_issues: {
                          edit: e.target.checked,
                          view: true,
                        },
                      });
                    }}
                  />
                </Box>
              ) : (
                <Box sx={[styles.flex_Acenter, { ml: -1 }]}>
                  <CheckboxWithLabel
                    label="Read"
                    sx={{ mr: 1 }}
                    labelStyle={styles.actions_text}
                    disabled={values.my_issues_restrictions?.my_issues?.edit}
                    icon={
                      !Boolean(module.view) ? (
                        <CheckBoxOutlineBlankOutlined />
                      ) : (
                        <CheckBoxOutlineBlankOutlined color="primary" />
                      )
                    }
                    checked={values.my_issues_restrictions?.my_issues?.view}
                    onChange={(e) => {
                      setFieldValue("my_issues_restrictions", {
                        ...values.my_issues_restrictions,
                        my_issues: {
                          ...values?.my_issues_restrictions?.my_issues,
                          view: e.target.checked,
                        },
                      });
                    }}
                  />
                  <CheckboxWithLabel
                    label="Read & Write"
                    labelStyle={styles.actions_text}
                    icon={
                      !Boolean(module.edit) ? (
                        <CheckBoxOutlineBlankOutlined
                          sx={{ color: "#d6d6d6" }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankOutlined color="primary" />
                      )
                    }
                    checked={values.my_issues_restrictions?.my_issues?.edit}
                    onChange={(e) => {
                      setFieldValue(`my_issues_restrictions`, {
                        ...values.my_issues_restrictions,
                        my_issues: {
                          edit: e.target.checked,
                          view: true,
                        },
                      });
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
}

interface IRolesListProps {
  formikBag: FormikProps<IRoleFields>;
}

function RolesList(props: IRolesListProps) {
  const { values, setFieldValue } = props.formikBag;

  const [templateTypeArr, setTemplateTypeArr] = useState<IArr[]>([]);

  const {} = useQuery(GET_TEMPLATE_TYPES, {
    variables: {},
    onCompleted: (data) => {
      const { templateTypes } = data;
      const { status, templateTypes: rawTemplateTypes } = templateTypes;
      if (status) {
        setTemplateTypeArr(rawTemplateTypes);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const checkProjectSetupChecked = (permissions: any) => {
    const projectViewIndex = permissions.findIndex((x: any) =>
      x.hasOwnProperty("PROJECT_VIEW")
    );
    const projectEditIndex = permissions.findIndex((x: any) =>
      x.hasOwnProperty("PROJECT_EDIT")
    );

    if (
      permissions[projectViewIndex]?.PROJECT_VIEW ||
      permissions[projectEditIndex]?.PROJECT_EDIT
    ) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={styles.header_text}>
          Client Level Permissions
        </Typography>
      </Grid>
      {ClientLevelPermissions.map((module) => (
        <Fragment key={module.name}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box sx={{ pl: 3 }}>
              <Typography sx={[styles.actions_text, { color: colors.primary }]}>
                {module.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box sx={styles.flex_Acenter}>
              <CheckboxWithLabel
                label="Read"
                sx={{ mr: 1 }}
                labelStyle={styles.actions_text}
                disabled={
                  !Boolean(module.view) ||
                  getCheckedValue(values.permission, module.edit)
                }
                icon={
                  !Boolean(module.view) ? (
                    <CheckBoxOutlineBlankOutlined />
                  ) : (
                    <CheckBoxOutlineBlankOutlined color="primary" />
                  )
                }
                checked={getCheckedValue(values.permission, module.view)}
                onChange={(e) => {
                  const permissionIndex = values.permission.findIndex((x) =>
                    x.hasOwnProperty(module.view)
                  );

                  setFieldValue(`permission[${permissionIndex}]`, {
                    [module.view]: e.target.checked,
                  });
                }}
              />
              <CheckboxWithLabel
                label="Read & Write"
                labelStyle={styles.actions_text}
                disabled={!Boolean(module.edit)}
                icon={
                  !Boolean(module.edit) ? (
                    <CheckBoxOutlineBlankOutlined sx={{ color: "#d6d6d6" }} />
                  ) : (
                    <CheckBoxOutlineBlankOutlined color="primary" />
                  )
                }
                checked={getCheckedValue(values.permission, module.edit)}
                onChange={(e) => {
                  const permissionIndex = values.permission.findIndex((x) =>
                    x.hasOwnProperty(module.edit)
                  );
                  const permissionViewIndex = values.permission.findIndex((x) =>
                    x.hasOwnProperty(module.view)
                  );

                  setFieldValue(`permission[${permissionIndex}]`, {
                    [module.edit]: e.target.checked,
                  });
                  if (Boolean(module.edit) && e.target.checked) {
                    setFieldValue(`permission[${permissionViewIndex}]`, {
                      [module.view]: e.target.checked,
                    });
                  }
                }}
              />
            </Box>
          </Grid>
        </Fragment>
      ))}
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Typography sx={styles.header_text}>
          Project Level Permissions
        </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box sx={{ pl: 3 }}>
          <Typography sx={[styles.actions_text, { color: colors.primary }]}>
            Project Setup
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box sx={styles.flex_Acenter}>
          <CheckboxWithLabel
            label="Read"
            sx={{ mr: 1 }}
            labelStyle={styles.actions_text}
            disabled={getCheckedValue(values.permission, "PROJECT_EDIT")}
            icon={<CheckBoxOutlineBlankOutlined color="primary" />}
            checked={getCheckedValue(values.permission, "PROJECT_VIEW")}
            onChange={(e) => {
              const permissionIndex = values.permission.findIndex((x) =>
                x.hasOwnProperty("PROJECT_VIEW")
              );
              const permissionEditIndex = values.permission.findIndex((x) =>
                x.hasOwnProperty("PROJECT_EDIT")
              );

              setFieldValue(`permission[${permissionIndex}]`, {
                PROJECT_VIEW: e.target.checked,
              });

              if (
                !e.target.checked &&
                !values.permission[permissionEditIndex]?.PROJECT_EDIT
              ) {
                setAllProjectSetupFalse(
                  values.permission,
                  setFieldValue,
                  templateTypeArr
                );
              }
            }}
          />
          <CheckboxWithLabel
            label="Read & Write"
            labelStyle={styles.actions_text}
            icon={<CheckBoxOutlineBlankOutlined color="primary" />}
            checked={getCheckedValue(values.permission, "PROJECT_EDIT")}
            onChange={(e) => {
              const permissionIndex = values.permission.findIndex((x) =>
                x.hasOwnProperty("PROJECT_EDIT")
              );
              const permissionViewIndex = values.permission.findIndex((x) =>
                x.hasOwnProperty("PROJECT_VIEW")
              );

              setFieldValue(`permission[${permissionIndex}]`, {
                PROJECT_EDIT: e.target.checked,
              });
              if (e.target.checked) {
                setFieldValue(`permission[${permissionViewIndex}]`, {
                  PROJECT_VIEW: e.target.checked,
                });
              }
              if (
                !e.target.checked &&
                !values.permission[permissionViewIndex]?.PROJECT_VIEW
              ) {
                setAllProjectSetupFalse(
                  values.permission,
                  setFieldValue,
                  templateTypeArr
                );
              }
            }}
          />
        </Box>
      </Grid>
      {checkProjectSetupChecked(values.permission) &&
        ProjectLevelPermissions.map((module) =>
          module.name !== "Folders (ALL)" && module.name !== "Issues" ? (
            <Fragment key={module.name}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Box sx={{ pl: 3 }}>
                  <Typography
                    sx={[styles.actions_text, { color: colors.primary }]}
                  >
                    {module.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Box sx={styles.flex_Acenter}>
                  <CheckboxWithLabel
                    label="Read"
                    sx={{ mr: 1 }}
                    labelStyle={styles.actions_text}
                    disabled={
                      !Boolean(module.view) ||
                      getCheckedValue(values.permission, module.edit)
                    }
                    icon={
                      !Boolean(module.view) ? (
                        <CheckBoxOutlineBlankOutlined />
                      ) : (
                        <CheckBoxOutlineBlankOutlined color="primary" />
                      )
                    }
                    checked={getCheckedValue(values.permission, module.view)}
                    onChange={(e) => {
                      const permissionIndex = values.permission.findIndex((x) =>
                        x.hasOwnProperty(module.view)
                      );

                      setFieldValue(`permission[${permissionIndex}]`, {
                        [module.view]: e.target.checked,
                      });
                    }}
                  />
                  <CheckboxWithLabel
                    label="Read & Write"
                    labelStyle={styles.actions_text}
                    disabled={!Boolean(module.edit)}
                    icon={
                      !Boolean(module.edit) ? (
                        <CheckBoxOutlineBlankOutlined />
                      ) : (
                        <CheckBoxOutlineBlankOutlined color="primary" />
                      )
                    }
                    checked={getCheckedValue(values.permission, module.edit)}
                    onChange={(e) => {
                      const permissionIndex = values.permission.findIndex((x) =>
                        x.hasOwnProperty(module.edit)
                      );
                      const permissionViewIndex = values.permission.findIndex(
                        (x) => x.hasOwnProperty(module.view)
                      );

                      setFieldValue(`permission[${permissionIndex}]`, {
                        [module.edit]: e.target.checked,
                      });
                      if (Boolean(module.edit) && e.target.checked) {
                        setFieldValue(`permission[${permissionViewIndex}]`, {
                          [module.view]: e.target.checked,
                        });
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Fragment>
          ) : (
            <Accordion
              key={module.name}
              module={module}
              formikBag={props.formikBag}
              templateTypeArr={templateTypeArr}
            />
          )
        )}
    </Grid>
  );
}

export default RolesList;
