import React, { Dispatch, SetStateAction } from "react";
import {
	Box,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { RadioButtonChecked } from "@mui/icons-material";
import { FormikProps } from "formik";

import { ICreateContactFieldsNew } from "models/contacts";
import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface ISuperannuationFormProps {
	formikBag: FormikProps<ICreateContactFieldsNew>;
	setActiveSection: Dispatch<SetStateAction<number>>;
}

function SuperannuationForm(props: ISuperannuationFormProps) {
	const { formikBag, setActiveSection } = props;
	const { values, handleChange, setFieldValue, handleBlur, touched, errors } =
		formikBag;

	const handleRadioChange = (e: any) => {
		setFieldValue(
			"financialDetails.haveSuperannuationAccount",
			e.target.value
		);
		if (e.target.value === "1") {
			setFieldValue("financialDetails.smsfName", "");
			setFieldValue("financialDetails.smsfAbn", "");
			setFieldValue("financialDetails.smsfEsa", "");
			setFieldValue("financialDetails.smsfFullName", "");
			setFieldValue("financialDetails.smsfBankAccountName", "");
			setFieldValue("financialDetails.smsfBsb", "");
			setFieldValue("financialDetails.superannuationAccountNo", "");
			setFieldValue("financialDetails.superannuationFundName", "");
			setFieldValue("financialDetails.memberNumber", "");
		}
		if (e.target.value === "2") {
			setFieldValue(
				"financialDetails.superannuationFundName",
				"Self managed super fund"
			);
			setFieldValue(
				"financialDetails.memberNumber",
				"Self managed super fund"
			);
		}
		if (e.target.value === "3") {
			setFieldValue("financialDetails.smsfName", "");
			setFieldValue("financialDetails.smsfAbn", "");
			setFieldValue("financialDetails.smsfEsa", "");
			setFieldValue("financialDetails.smsfFullName", "");
			setFieldValue("financialDetails.smsfBankAccountName", "");
			setFieldValue("financialDetails.smsfBsb", "");
			setFieldValue("financialDetails.superannuationAccountNo", "");
			setFieldValue(
				"financialDetails.superannuationFundName",
				"Employer's default super fund"
			);
			setFieldValue(
				"financialDetails.memberNumber",
				"Employer's default super fund"
			);
		}
	};

	return (
		<Box
			sx={{
				pt: { xs: 2, sm: 14 },
				px: 4,
				pb: 1.5,
				display: "flex",
				flexDirection: "column",
				height: "100%",
			}}
		>
			<Grid container spacing={3}>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ display: { xs: "block", sm: "none" } }}
				>
					<Typography
						sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}
					>
						Superannuation Details
					</Typography>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Typography sx={styles.field_label}>
						Do you have a Superannuation Account?
					</Typography>
					<RadioGroup
						value={values.financialDetails.haveSuperannuationAccount}
						onChange={(e) => handleRadioChange(e)}
					>
						<FormControlLabel
							value="1"
							control={
								<Radio
									size="small"
									checkedIcon={
										<RadioButtonChecked sx={{ color: "#6f6f6f" }} />
									}
								/>
							}
							label={
								<Typography
									sx={[
										styles.field_label,
										{ fontWeight: 400, color: "#00000080" },
									]}
								>
									Yes, I know my superannuation fund details
								</Typography>
							}
							sx={{ mt: -0.5 }}
						/>
						<FormControlLabel
							value="2"
							control={
								<Radio
									size="small"
									checkedIcon={
										<RadioButtonChecked sx={{ color: "#6f6f6f" }} />
									}
								/>
							}
							label={
								<Typography
									sx={[
										styles.field_label,
										{ fontWeight: 400, color: "#00000080" },
									]}
								>
									I have a self managed super fund (SMSF)
								</Typography>
							}
							sx={{ mt: -1 }}
						/>
						<FormControlLabel
							value="3"
							control={
								<Radio
									size="small"
									checkedIcon={
										<RadioButtonChecked sx={{ color: "#6f6f6f" }} />
									}
								/>
							}
							label={
								<Typography
									sx={[
										styles.field_label,
										{ fontWeight: 400, color: "#00000080" },
									]}
								>
									No, use my employer's default super fund
								</Typography>
							}
							sx={{ mt: -1 }}
						/>
					</RadioGroup>
				</Grid>
				{Boolean(values.financialDetails.haveSuperannuationAccount) &&
					values.financialDetails.haveSuperannuationAccount === "1" && (
						<>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography sx={styles.field_label}>
									Enter your super fund product name or USI
								</Typography>
								<TextField
									id="financialDetails.superannuationFundName"
									value={
										values.financialDetails.superannuationFundName
									}
									onChange={handleChange}
									onBlur={handleBlur}
									size="small"
									fullWidth
									inputProps={{ style: styles.input_field }}
								/>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography sx={styles.field_label}>
									Member Number
								</Typography>
								<TextField
									id="financialDetails.memberNumber"
									value={values.financialDetails.memberNumber}
									onChange={handleChange}
									onBlur={handleBlur}
									size="small"
									fullWidth
									inputProps={{ style: styles.input_field }}
								/>
							</Grid>
						</>
					)}
				{Boolean(values.financialDetails.haveSuperannuationAccount) &&
					values.financialDetails.haveSuperannuationAccount === "2" && (
						<>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography sx={styles.field_label}>
									SMSF Name
								</Typography>
								<TextField
									id="financialDetails.smsfName"
									value={values.financialDetails.smsfName}
									onChange={handleChange}
									onBlur={handleBlur}
									size="small"
									fullWidth
									inputProps={{ style: styles.input_field }}
								/>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography sx={styles.field_label}>
									SMSF Australian Business Number (ABN)
								</Typography>
								<TextField
									id="financialDetails.smsfAbn"
									value={values.financialDetails.smsfAbn}
									onChange={(e) => {
										if (!isNaN(e.target.value as unknown as number)) {
											if (e.target.value.length <= 11) {
												setFieldValue(
													"financialDetails.smsfAbn",
													e.target.value
												);
											}
										}
									}}
									onBlur={handleBlur}
									size="small"
									fullWidth
									inputProps={{ style: styles.input_field }}
								/>
								{touched?.financialDetails?.smsfAbn && (
									<Typography sx={styles.error_text}>
										{errors?.financialDetails?.smsfAbn}
									</Typography>
								)}
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography sx={styles.field_label}>
									SMSF Electronic Service Address (ESA) <br />{" "}
									<span
										style={{
											fontSize: "11px",
											fontWeight: 400,
											fontStyle: "italic",
											marginTop: -10,
										}}
									>
										An ESA is used so the fund can receive electronic
										messages and payments from your employer using
										SuperStream. You can find your ESA by contacting
										your SMSF messaging provider or through your SMSF
										administrator, tax agent, accountant or bank.
									</span>
								</Typography>
								<TextField
									id="financialDetails.smsfEsa"
									value={values.financialDetails.smsfEsa}
									onChange={handleChange}
									onBlur={handleBlur}
									size="small"
									fullWidth
									inputProps={{ style: styles.input_field }}
								/>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography sx={styles.field_label}>
									Full Name (as it apperas on your account) <br />{" "}
									<span
										style={{
											fontSize: "11px",
											fontWeight: 400,
											fontStyle: "italic",
											marginTop: -10,
										}}
									>
										This must match the name shown on your super
										account. This may be your current name, or a
										previous name.
									</span>
								</Typography>
								<TextField
									id="financialDetails.smsfFullName"
									value={values.financialDetails.smsfFullName}
									onChange={handleChange}
									onBlur={handleBlur}
									size="small"
									fullWidth
									inputProps={{ style: styles.input_field }}
								/>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Typography
									sx={[
										styles.field_label,
										{ textDecoration: "underline" },
									]}
								>
									SMSF Bank Account Details
								</Typography>
								<Grid container spacing={1.5}>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											Bank Account Name
										</Typography>
										<TextField
											id="financialDetails.smsfBankAccountName"
											value={
												values.financialDetails.smsfBankAccountName
											}
											onChange={handleChange}
											onBlur={handleBlur}
											size="small"
											fullWidth
											inputProps={{ style: styles.input_field }}
										/>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											BSB Code
										</Typography>
										<TextField
											id="financialDetails.smsfBsb"
											value={values.financialDetails.smsfBsb}
											onChange={(e) => {
												if (
													!isNaN(
														e.target.value as unknown as number
													)
												) {
													if (e.target.value.length <= 6) {
														setFieldValue(
															"financialDetails.smsfBsb",
															e.target.value
														);
													}
												}
											}}
											onBlur={handleBlur}
											size="small"
											fullWidth
											inputProps={{ style: styles.input_field }}
										/>
										{touched?.financialDetails?.smsfBsb && (
											<Typography sx={styles.error_text}>
												{errors?.financialDetails?.smsfBsb}
											</Typography>
										)}
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											Account Number
										</Typography>
										<TextField
											id="financialDetails.superannuationAccountNo"
											value={
												values.financialDetails
													.superannuationAccountNo
											}
											onChange={(e) => {
												if (
													!isNaN(
														e.target.value as unknown as number
													)
												) {
													if (e.target.value.length <= 10) {
														setFieldValue(
															"financialDetails.superannuationAccountNo",
															e.target.value
														);
													}
												}
											}}
											onBlur={handleBlur}
											size="small"
											fullWidth
											inputProps={{ style: styles.input_field }}
										/>
										{touched?.financialDetails
											?.superannuationAccountNo && (
											<Typography sx={styles.error_text}>
												{
													errors?.financialDetails
														?.superannuationAccountNo
												}
											</Typography>
										)}
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
			</Grid>
			<Box sx={{ flexGrow: 1, mt: 2 }}>
				<Box
					sx={{ display: "flex", flexDirection: "column", height: "100%" }}
				>
					<Box sx={{ flexGrow: 1 }} />
					<Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
						<OutlinedBtn
							size="small"
							sx={{
								borderRadius: "20px",
								fontSize: "13px",
								fontWeight: 600,
								width: "120px",
								color: "#000",
							}}
							disableElevation
							disableRipple
							onClick={() => setActiveSection(2)}
						>
							Back
						</OutlinedBtn>
						<BlackActionContainedButton
							variant="contained"
							size="small"
							sx={{
								borderRadius: "20px",
								ml: 1,
								fontSize: "13px",
								fontWeight: 600,
								width: "120px",
							}}
							disableElevation
							disableRipple
							onClick={() => setActiveSection(4)}
						>
							Next
						</BlackActionContainedButton>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default SuperannuationForm;
