import React, { useEffect, useState } from "react";
import {
	Autocomplete,
	Box,
	Dialog,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@mui/material";
import {
	CameraAlt,
	CancelOutlined,
	Close,
	Info,
	ModeEditOutlineOutlined,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import moment from "moment";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { toast } from "react-toastify";

import DatePickerInput from "common/DatePickerInput";
import { useStore } from "utils/store";
import { ProjectCategoryArr, ProjectTypeArr } from "utils/constants";
import { IAddProjectFields } from "models/projects";
import { CREATE_PROJECT } from "graphql/projects";
import { BlackActionContainedButton } from "common/Buttons";
import ProjectPhotoCropper from "common/ImageCropper/ProjectPhotoCropper";
import { images } from "assets/images";
import theme from "theme/muiTheming";
import { colors } from "theme/colors";

import { ProjectStyles as styles } from "./styles";

interface IAddProjectModalProps {
	open: boolean;
	handleClose: () => void;
	refetchProjects: any;
}

const AddProjectModal = (props: IAddProjectModalProps) => {
	const { open, handleClose, refetchProjects } = props;

	const { setLoading } = useStore();
	const matchesSm = useMediaQuery(theme.breakpoints.only("sm"));

	// const [scriptError, setScriptError] = useState("");
	const [openImageCropUploader, setOpenImageCropUploader] = useState(false);
	const [projectImageHovered, setProjectImageHovered] = useState(false);

	const handleDialogClose = (_: any, reason: string) => {
		if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
			handleClose();
		}
	};

	const [fireCreateProjectApi, { loading }] = useMutation(CREATE_PROJECT, {
		onCompleted: (data) => {
			setLoading(false);
			const { createProject } = data;
			const { status, message } = createProject;
			handleClose();
			if (status) {
				toast.success(message);
				refetchProjects();
			} else {
				toast.error(message);
			}
		},
	});

	const handleCreateProj = (values: IAddProjectFields) => {
		const {
			episodes,
			episodeDuration,
			categoryLoading,
			shootDays,
			categoryLoadingInputs,
			projectStartDate,
			projectEndDate,
			filmingDate,
			profile_picture,
			script,
			projectType,
			...rest
		} = values;
		if (
			projectType === "DOCUMENTARY" ||
			projectType === "TVC" ||
			projectType === "OTHER"
		) {
			// setScriptError("");
			fireCreateProjectApi({
				variables: {
					payload: {
						...rest,
						...(episodes !== "" && {
							episodes: parseInt(episodes as string),
						}),
						...(episodeDuration !== "" && {
							episodeDuration: parseInt(episodeDuration as string),
						}),
						...(categoryLoading !== "" && {
							categoryLoading: parseFloat(categoryLoading as string),
						}),
						...(shootDays !== "" && {
							shootDays: parseInt(shootDays as string),
						}),
						...(filmingDate !== "" && { filmingDate }),
						...(projectStartDate !== "" && { projectStartDate }),
						...(projectEndDate !== "" && { projectEndDate }),
						categoryLoadingInputs: JSON.stringify(categoryLoadingInputs),
						projectType: projectType,
					},
					...(Boolean(profile_picture) &&
						typeof profile_picture !== "string" && {
							file: profile_picture,
						}),
				},
			});
		} else {
			// if (Boolean(script)) {
			// setScriptError("");
			fireCreateProjectApi({
				variables: {
					payload: {
						...rest,
						...(episodes !== "" && {
							episodes: parseInt(episodes as string),
						}),
						...(episodeDuration !== "" && {
							episodeDuration: parseInt(episodeDuration as string),
						}),
						...(categoryLoading !== "" && {
							categoryLoading: parseFloat(categoryLoading as string),
						}),
						...(shootDays !== "" && {
							shootDays: parseInt(shootDays as string),
						}),
						...(filmingDate !== "" && { filmingDate }),
						...(projectStartDate !== "" && { projectStartDate }),
						...(projectEndDate !== "" && { projectEndDate }),
						categoryLoadingInputs: JSON.stringify(categoryLoadingInputs),
						projectType: projectType,
					},
					...(Boolean(profile_picture) &&
						typeof profile_picture !== "string" && {
							file: profile_picture,
						}),
					...(Boolean(script) && { script: script }),
				},
			});
			// } else {
			//   setScriptError("Please upload a script file");
			// }
		}
	};

	useEffect(() => {
		setLoading(loading);
	}, [loading, setLoading]);

	return (
		<Formik
			initialValues={{
				name: "",
				projectNumber: "",
				description: "",
				episodes: "",
				episodeDuration: "",
				projectType: "",
				projectCategory: "",
				categoryDescription: "",
				categoryLoading: "",
				categoryLoadingInputs: "",
				collectiveAgreementCast: "",
				collectiveAgreementCrew: "",
				projectStartDate: "",
				projectEndDate: "",
				shootDays: "",
				filmingDate: "",
				filmingLocation: "",
				projectMaterials: "",
				profile_picture: null,
				script: null,
			}}
			validationSchema={Yup.object().shape({
				name: Yup.string().required("Please enter the project name"),
				projectCategory: Yup.string().required(
					"Please select the project category"
				),
				projectType: Yup.string().required("Please select a project type"),
				projectStartDate: Yup.string().required(
					"Please select the project start date"
				),
				projectEndDate: Yup.string()
					.required("Please select the project end date")
					.when("projectStartDate", (startDate, schema) => {
						return schema.test({
							name: "start_date end_date test",
							message: "End Date should be after Start Date",
							test: (endDate) => {
								if (
									Boolean(startDate) &&
									startDate.length > 0 &&
									Boolean(endDate)
								) {
									const newStartDate = startDate[0];
									return moment(newStartDate).isBefore(endDate);
								}
								return true;
							},
						});
					}),
				filmingDate: Yup.string().when(
					"projectStartDate",
					(startDate, schema) => {
						return schema.test({
							name: "start_date end_date test",
							message: "Release/ TX Date should be after Start Date",
							test: (filmDate) => {
								if (
									Boolean(startDate) &&
									startDate.length > 0 &&
									Boolean(filmDate)
								) {
									const newStartDate = startDate[0];
									return moment(newStartDate).isBefore(filmDate);
								}
								return true;
							},
						});
					}
				),
				episodes: Yup.string()
					.required("Please enter the number of episodes")
					.test("negative test", "Please enter a positive value", (val) =>
						Boolean(val)
							? val !== "" && parseInt(val as string) > 0
							: true
					),
				episodeDuration: Yup.string()
					.required("Please enter the duration of each episodes")
					.test("negative test", "Please enter a positive value", (val) =>
						Boolean(val)
							? val !== "" && parseInt(val as string) > 0
							: true
					),
				shootDays: Yup.string().test(
					"negative test",
					"Please enter a positive value",
					(val) =>
						Boolean(val)
							? val !== "" && parseInt(val as string) > 0
							: true
				),
				categoryLoading: Yup.string()
					.required("Please enter the category loading")
					.test("negative test", "Please enter a positive value", (val) =>
						Boolean(val)
							? val !== "" && parseInt(val as string) >= 0
							: true
					),
			})}
			onSubmit={handleCreateProj}
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleBlur,
				setFieldTouched,
				errors,
				touched,
				isValid,
				handleSubmit,
			}) => {
				return (
					<Dialog
						open={open}
						onClose={handleDialogClose}
						fullWidth
						maxWidth="md"
						PaperProps={{
							sx: { borderRadius: "24px", overflow: "hidden" },
						}}
					>
						<Grid container>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<Box
									sx={{
										py: 2,
										backgroundColor: "#B6BCC325",
										position: "relative",
										px: 4,
									}}
								>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Typography
											textAlign="center"
											sx={{
												fontSize: "13px",
												color: colors.primaryBlack,
												fontWeight: 600,
											}}
										>
											Create Project
										</Typography>
									</Box>
								</Box>
								<Box sx={{ position: "absolute", top: 10, right: 10 }}>
									<IconButton size="small" onClick={handleClose}>
										<Close
											sx={{ color: "#00000055" }}
											fontSize="small"
										/>
									</IconButton>
								</Box>
							</Grid>
							<Grid
								item
								lg={3}
								md={3}
								sm={3}
								xs={3}
								sx={{ display: { xs: "none", sm: "block" } }}
							>
								<Grid container spacing={3}>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Box
											sx={{
												mt: 3,
												display: "flex",
												justifyContent: "center",
											}}
										>
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													flexDirection: "column",
													alignItems: "center",
													position: "relative",
												}}
												onClick={() => {
													if (!matchesSm) {
														setOpenImageCropUploader(true);
													}
												}}
											>
												<Box
													component="div"
													onMouseEnter={() => {
														if (!projectImageHovered) {
															setProjectImageHovered(true);
														}
													}}
												>
													{Boolean(values.profile_picture) ? (
														<Box
															sx={{
																cursor: "pointer",
																height: "220px",
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
															}}
														>
															<img
																src={
																	typeof values.profile_picture ===
																	"string"
																		? values.profile_picture
																		: URL.createObjectURL(
																				values.profile_picture!
																		  )
																}
																alt="proj_image"
																style={{
																	height: "220px",
																	width: "148.5px",
																	borderRadius: "15px",
																	objectFit: "cover",
																	aspectRatio: 0.675 / 1,
																}}
															/>
														</Box>
													) : (
														<Box
															sx={{
																display: "flex",
																justifyContent: "center",
															}}
														>
															<Box
																sx={{
																	backgroundColor: `#BFBDBC22`,
																	aspectRatio: 0.675 / 1,
																	borderRadius: "20px",
																	height: "220px",
																	width: "148.5px",
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																}}
															>
																<img
																	src={images.FILM_REEL}
																	alt="reel"
																	style={{ height: 50 }}
																/>
															</Box>
														</Box>
													)}
												</Box>
												{projectImageHovered && (
													<Box
														sx={{
															display: "flex",
															justifyContent: "center",
															position: "absolute",
															top: 0,
														}}
													>
														<Box
															component="div"
															onMouseLeave={() => {
																if (projectImageHovered) {
																	setProjectImageHovered(
																		false
																	);
																}
															}}
														>
															<Box
																sx={{
																	display: "flex",
																	justifyContent: "center",
																}}
															>
																<Box
																	sx={{
																		aspectRatio: 0.675 / 1,
																		borderRadius: "20px",
																		backgroundColor:
																			"#000000",
																		cursor: "pointer",
																		opacity: 0.4,
																		height: "220px",
																		width: "148.5px",
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																	}}
																>
																	<CameraAlt
																		color="secondary"
																		sx={{
																			opacity: 1,
																			fontSize: 40,
																		}}
																	/>
																</Box>
															</Box>
														</Box>
													</Box>
												)}
												<Box
													sx={{
														position: "absolute",
														top: 10,
														right: 10,
														display: {
															xs: "none",
															sm: "block",
															md: "none",
														},
													}}
												>
													<IconButton
														sx={{
															backgroundColor: "#fff",
															opacity: 0.8,
														}}
														size="small"
														onClick={() =>
															setOpenImageCropUploader(true)
														}
													>
														<ModeEditOutlineOutlined fontSize="small" />
													</IconButton>
												</Box>
											</Box>
										</Box>
									</Grid>
									{(values.projectType === "FEATURE" ||
										values.projectType === "TV_SERIES" ||
										values.projectType === "TELEMOVIE") && (
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
												}}
											>
												<Box sx={{ width: "148.5px" }}>
													{Boolean(values.script) ? (
														<Box
															sx={{
																display: "flex",
																alignItems: "center",
															}}
														>
															<Box sx={{ flexGrow: 1 }}>
																<Typography
																	sx={[
																		styles.field_label,
																		{
																			fontWeight: 400,
																			width: "100%",
																			wordBreak:
																				"break-word",
																		},
																	]}
																>
																	{values.script
																		? (values?.script as any)[
																				"name"
																		  ]
																		: ""}
																</Typography>
															</Box>
															<Upload
																beforeUpload={(
																	file: RcFile,
																	__
																) => {
																	// setScriptError("");
																	setFieldValue(
																		"script",
																		file
																	);
																	return Promise.resolve();
																}}
																accept=".fdx"
															>
																<IconButton
																	sx={{
																		height: 20,
																		width: 20,
																	}}
																>
																	<ModeEditOutlineOutlined
																		sx={{ fontSize: 15 }}
																	/>
																</IconButton>
															</Upload>
															<IconButton
																sx={{ height: 20, width: 20 }}
																onClick={() =>
																	setFieldValue("script", null)
																}
															>
																<CancelOutlined
																	sx={{ fontSize: 15 }}
																/>
															</IconButton>
														</Box>
													) : (
														<Upload
															beforeUpload={(
																file: RcFile,
																__
															) => {
																// setScriptError("");
																setFieldValue("script", file);
																return Promise.resolve();
															}}
															accept=".fdx"
														>
															<Box
																sx={{
																	height: "220px",
																	borderRadius: "20px",
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																	flexDirection: "column",
																	px: 1.25,
																	py: 1.5,
																	cursor: "pointer",
																	backgroundColor: "#41B19920",
																	position: "relative",
																}}
															>
																<img
																	src={
																		images.UPLOAD_SCRIPT_NEW
																	}
																	alt="upload"
																	style={{ height: 35 }}
																/>
																<Typography
																	sx={[
																		styles.field_label,
																		{
																			fontWeight: 400,
																			textAlign: "center",
																			mt: 4,
																			fontSize: "12px",
																		},
																	]}
																>
																	Drop your{" "}
																	<span
																		style={{
																			color: "#41B199",
																		}}
																	>
																		Script
																	</span>{" "}
																	here or{" "}
																	<span
																		style={{
																			textDecoration:
																				"underline",
																		}}
																	>
																		click to upload
																	</span>
																</Typography>
																<Box
																	sx={{
																		position: "absolute",
																		top: 10,
																		right: 10,
																	}}
																>
																	<Tooltip
																		title={
																			<Box
																				sx={{
																					width: "150px",
																					borderRadius:
																						"15px",
																				}}
																			>
																				<Typography
																					sx={{
																						fontSize:
																							"12px",
																						color: "#fff",
																					}}
																				>
																					Organise your
																					document folder
																					structure based
																					on your project's
																					characters.
																				</Typography>
																				<Typography
																					sx={{
																						fontSize:
																							"12px",
																						color: "#fff",
																						mt: 1.5,
																						fontWeight: 600,
																					}}
																				>
																					*Only .fdx
																					supported.
																				</Typography>
																			</Box>
																		}
																	>
																		<Info
																			sx={{
																				fontSize: "18px",
																				color: "#525D5D",
																				cursor: "pointer",
																			}}
																		/>
																	</Tooltip>
																</Box>
															</Box>
														</Upload>
													)}
												</Box>
											</Box>
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid
								item
								lg={9}
								md={9}
								sm={9}
								xs={12}
								sx={{
									borderLeft: {
										xs: "none",
										sm: "1.5px #e6e6e6 solid",
									},
								}}
							>
								<Box
									sx={{
										pt: 3,
										px: 4,
										pb: 2,
										maxHeight: "calc(100vh - 120px)",
										overflow: "auto",
									}}
								>
									<Grid container spacing={3}>
										<Grid
											item
											lg={6}
											md={6}
											sm={6}
											xs={12}
											sx={{ display: { xs: "block", sm: "none" } }}
										>
											{(values.projectType === "FEATURE" ||
												values.projectType === "TV_SERIES" ||
												values.projectType === "TELEMOVIE") && (
												<>
													{Boolean(values.script) ? (
														<Box
															sx={{
																display: "flex",
																alignItems: "center",
																border: "1px #e6e6e6 solid",
																px: 1.5,
																py: 1,
																borderRadius: "6px",
															}}
														>
															<Box sx={{ flexGrow: 1 }}>
																<Typography
																	sx={[
																		styles.field_label,
																		{
																			fontWeight: 400,
																			width: "100%",
																			wordBreak:
																				"break-word",
																		},
																	]}
																>
																	{values.script
																		? (values?.script as any)[
																				"name"
																		  ]
																		: ""}
																</Typography>
															</Box>
															<Upload
																beforeUpload={(
																	file: RcFile,
																	__
																) => {
																	// setScriptError("");
																	setFieldValue(
																		"script",
																		file
																	);
																	return Promise.resolve();
																}}
																accept=".fdx"
															>
																<IconButton
																	sx={{
																		height: 20,
																		width: 20,
																	}}
																>
																	<ModeEditOutlineOutlined
																		sx={{ fontSize: 15 }}
																	/>
																</IconButton>
															</Upload>
															<IconButton
																sx={{ height: 20, width: 20 }}
																onClick={() =>
																	setFieldValue("script", null)
																}
															>
																<CancelOutlined
																	sx={{ fontSize: 15 }}
																/>
															</IconButton>
														</Box>
													) : (
														<Upload
															beforeUpload={(
																file: RcFile,
																__
															) => {
																// setScriptError("");
																setFieldValue("script", file);
																return Promise.resolve();
															}}
															accept=".fdx"
														>
															<Box
																sx={{
																	py: 2,
																	px: 1.5,
																	backgroundColor: "#41B19920",
																	borderRadius: "8px",
																	cursor: "pointer",
																}}
															>
																<Typography
																	sx={[
																		styles.field_label,
																		{
																			fontWeight: 400,
																			textAlign: "center",
																			fontSize: "14px",
																		},
																	]}
																>
																	Drop your{" "}
																	<span
																		style={{
																			color: "#41B199",
																		}}
																	>
																		Script
																	</span>{" "}
																	here or{" "}
																	<span
																		style={{
																			textDecoration:
																				"underline",
																		}}
																	>
																		click to upload
																	</span>
																</Typography>
																<Typography
																	sx={{
																		fontSize: "12px",
																		color: "#00000070",
																		mt: 2,
																	}}
																>
																	Organise your document folder
																	structure based on your
																	project's characters.
																</Typography>
																<Typography
																	sx={{
																		fontSize: "12px",
																		color: "#00000070",
																		fontWeight: 600,
																	}}
																>
																	*Only .fdx supported.
																</Typography>
															</Box>
														</Upload>
													)}
												</>
											)}
										</Grid>
										<Grid
											item
											lg={12}
											md={12}
											sm={12}
											xs={12}
											sx={{ display: { xs: "block", sm: "none" } }}
										>
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
												}}
											>
												<Box
													sx={{
														display: "flex",
														justifyContent: "center",
														flexDirection: "column",
														alignItems: "center",
														position: "relative",
													}}
												>
													<Box component="div">
														{Boolean(values?.profile_picture) ? (
															<Box
																sx={{
																	height: "220px",
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																}}
															>
																<img
																	src={
																		typeof values?.profile_picture ===
																		"string"
																			? values?.profile_picture
																			: URL.createObjectURL(
																					values?.profile_picture!
																			  )
																	}
																	alt="proj_image"
																	style={{
																		height: "220px",
																		width: "148.5px",
																		borderRadius: "15px",
																		objectFit: "cover",
																		aspectRatio: 0.675 / 1,
																	}}
																/>
															</Box>
														) : (
															<Box
																sx={{
																	display: "flex",
																	justifyContent: "center",
																}}
															>
																<Box
																	sx={[
																		{
																			height: "220px",
																			display: "flex",
																			justifyContent:
																				"center",
																			alignItems: "center",
																		},
																		{
																			backgroundColor: `#BFBDBC22`,
																			aspectRatio: 0.675 / 1,
																			borderRadius: "20px",
																			width: "148.5px",
																		},
																	]}
																>
																	<img
																		src={images.FILM_REEL}
																		alt="reel"
																		style={{ height: 50 }}
																	/>
																</Box>
															</Box>
														)}
													</Box>
													<Box
														sx={{
															position: "absolute",
															top: 10,
															right: 10,
														}}
													>
														<IconButton
															sx={{
																backgroundColor: "#fff",
																opacity: 0.8,
															}}
															size="small"
															onClick={() =>
																setOpenImageCropUploader(true)
															}
														>
															<ModeEditOutlineOutlined fontSize="small" />
														</IconButton>
													</Box>
												</Box>
											</Box>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Project Name{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<TextField
												id="name"
												value={values.name}
												onChange={handleChange}
												onBlur={handleBlur}
												size="small"
												fullWidth
												inputProps={{ style: styles.input_field }}
											/>
											{touched?.name && errors?.name && (
												<Typography sx={styles.error_text}>
													{errors.name}
												</Typography>
											)}
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Project Number
											</Typography>
											<TextField
												id="projectNumber"
												value={values.projectNumber}
												onChange={handleChange}
												onBlur={handleBlur}
												size="small"
												fullWidth
												inputProps={{ style: styles.input_field }}
											/>
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Typography sx={styles.field_label}>
												Project Description
											</Typography>
											<TextField
												id="description"
												value={values.description}
												onChange={handleChange}
												onBlur={handleBlur}
												size="small"
												fullWidth
												inputProps={{
													style: {
														...styles.input_field,
														backgroundColor: "transparent",
													},
												}}
												multiline
												sx={{
													"& .MuiInputBase-root.MuiOutlinedInput-root":
														{
															backgroundColor: "#e6e6e640",
														},
												}}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Project Type{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<Autocomplete
												id="projectType"
												value={
													ProjectTypeArr.find(
														(x) => x.id === values.projectType
													) || {
														id: "",
														name: "",
													}
												}
												onChange={(_, newValue) => {
													setFieldValue(
														`projectType`,
														newValue.id
													);
													setFieldValue(
														"collectiveAgreementCrew",
														"MPPCA"
													);
													if (newValue.id !== "FEATURE") {
														setFieldValue(
															"projectCategory",
															"NA"
														);
														setFieldValue(
															"categoryDescription",
															"Not subject to a category loading"
														);
														setFieldValue(
															"collectiveAgreementCast",
															"ATPA/ATRRA"
														);
														setFieldValue("categoryLoading", "0");
														setFieldValue("episodes", "");
													} else {
														setFieldValue("projectCategory", "");
														setFieldValue(
															"categoryDescription",
															""
														);
														setFieldValue(
															"collectiveAgreementCast",
															"AFFCA"
														);
														setFieldValue("episodes", "1");
														setFieldValue("categoryLoading", "");
													}
												}}
												onBlur={handleBlur}
												disableClearable
												size="small"
												options={ProjectTypeArr}
												getOptionLabel={(option) => option.name}
												sx={styles.input_field}
												renderInput={(params) => (
													<TextField
														{...params}
														size="small"
														fullWidth
														onBlur={handleBlur}
														inputProps={{
															...params.inputProps,
															style: {
																...styles.input_field,
																backgroundColor: "transparent",
															},
														}}
													/>
												)}
											/>
											{touched?.projectType &&
												errors?.projectType && (
													<Typography sx={styles.error_text}>
														{errors.projectType}
													</Typography>
												)}
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Project Category{" "}
												{Boolean(values.projectType) &&
													values.projectType === "FEATURE" && (
														<span style={styles.error_text}>
															*
														</span>
													)}
											</Typography>
											<Autocomplete
												id="projectCategory"
												value={
													ProjectCategoryArr.find(
														(x) => x.id === values.projectCategory
													) || {
														id: "",
														name: "",
														description: "",
													}
												}
												onChange={(_, newValue) => {
													setFieldValue(
														`projectCategory`,
														newValue.id
													);
													setFieldValue(
														"categoryDescription",
														newValue.description
													);
												}}
												disabled={
													Boolean(values.projectType) &&
													values.projectType !== "FEATURE"
												}
												onBlur={handleBlur}
												disableClearable
												size="small"
												options={
													Boolean(values?.projectType)
														? values?.projectType === "FEATURE"
															? ProjectCategoryArr.filter(
																	(x) => x.id !== "NA"
															  )
															: ProjectCategoryArr
														: []
												}
												getOptionLabel={(option) => option.name}
												sx={styles.input_field}
												renderInput={(params) => (
													<TextField
														{...params}
														size="small"
														fullWidth
														onBlur={handleBlur}
														inputProps={{
															...params.inputProps,
															style: {
																...styles.input_field,
																backgroundColor: "transparent",
															},
														}}
													/>
												)}
											/>
											{Boolean(values.projectType) &&
												touched?.projectCategory &&
												errors?.projectCategory && (
													<Typography sx={styles.error_text}>
														{errors.projectCategory}
													</Typography>
												)}
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Typography sx={styles.field_label}>
												Category Description
											</Typography>
											<TextField
												id="categoryDescription"
												value={values.categoryDescription}
												onChange={handleChange}
												onBlur={handleBlur}
												disabled
												size="small"
												fullWidth
												inputProps={{
													style: {
														...styles.input_field,
														backgroundColor: "transparent",
													},
												}}
												multiline
												sx={{
													"& .MuiInputBase-root.MuiOutlinedInput-root":
														{
															backgroundColor: "#e6e6e640",
														},
												}}
											/>
										</Grid>
										{Boolean(values.projectType) &&
											values.projectType === "FEATURE" && (
												<Grid item lg={12} md={12} sm={12} xs={12}>
													<Typography sx={styles.field_label}>
														Category Loading{" "}
														<span style={styles.error_text}>
															*
														</span>
													</Typography>
													<TextField
														id="categoryLoading"
														value={values.categoryLoading}
														onChange={(e) => {
															const value = e.target.value;
															const isValidNumber =
																/^\d*\.?\d{0,2}$/.test(value); // Allow integers or decimals up to 2 places
															if (isValidNumber) {
																setFieldValue(
																	"categoryLoading",
																	value
																); // Store as string for precision while typing
															}
														}}
														onBlur={handleBlur}
														size="small"
														fullWidth
														inputProps={{
															style: styles.input_field,
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Box
																		sx={{
																			backgroundColor:
																				"#e6e6e640",
																			borderTopRightRadius:
																				"6px",
																			borderBottomRightRadius:
																				"6px",
																			py: "5px",
																			pl: 3,
																			pr: 1.5,
																			mr: -1.75,
																			ml: -1,
																		}}
																	>
																		<Typography>%</Typography>
																	</Box>
																</InputAdornment>
															),
														}}
													/>
													{touched?.categoryLoading &&
														errors?.categoryLoading && (
															<Typography sx={styles.error_text}>
																{errors.categoryLoading}
															</Typography>
														)}
												</Grid>
											)}
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Collective Agreement Cast
											</Typography>
											<TextField
												id="collectiveAgreementCast"
												value={values.collectiveAgreementCast}
												onChange={handleChange}
												onBlur={handleBlur}
												disabled
												size="small"
												fullWidth
												inputProps={{ style: styles.input_field }}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Collective Agreement Crew
											</Typography>
											<TextField
												id="collectiveAgreementCrew"
												value={values.collectiveAgreementCrew}
												onChange={handleChange}
												onBlur={handleBlur}
												disabled
												size="small"
												fullWidth
												inputProps={{ style: styles.input_field }}
											/>
										</Grid>
										{Boolean(values.projectType) &&
											values.projectType !== "FEATURE" && (
												<Grid item lg={6} md={6} sm={6} xs={12}>
													<Typography sx={styles.field_label}>
														Number of episodes{" "}
														<span style={styles.error_text}>
															*
														</span>
													</Typography>
													<TextField
														id="episodes"
														value={values.episodes}
														onChange={(e) => {
															const value = e.target.value;
															const isValidNumber =
																value.match(/^\d*$/); // Only allow digits
															if (isValidNumber) {
																if (value === "") {
																	setFieldValue(
																		"episodes",
																		""
																	);
																}
																if (
																	value !== "" &&
																	!isNaN(
																		value as unknown as number
																	)
																) {
																	setFieldValue(
																		"episodes",
																		parseFloat(value)
																	);
																}
															}
														}}
														onBlur={handleBlur}
														size="small"
														fullWidth
														inputProps={{
															style: styles.input_field,
														}}
													/>
													{touched?.episodes &&
														errors?.episodes && (
															<Typography sx={styles.error_text}>
																{errors.episodes}
															</Typography>
														)}
												</Grid>
											)}
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												{`${
													Boolean(values.projectType) &&
													values.projectType !== "FEATURE"
														? "Duration of each episode"
														: "Duration"
												} (in minutes)`}{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<TextField
												id="episodeDuration"
												value={values.episodeDuration}
												onChange={(e) => {
													const value = e.target.value;
													const isValidNumber =
														value.match(/^\d*$/); // Only allow digits
													if (isValidNumber) {
														if (value === "") {
															setFieldValue(
																"episodeDuration",
																""
															);
														}
														if (
															value !== "" &&
															!isNaN(value as unknown as number)
														) {
															setFieldValue(
																"episodeDuration",
																parseFloat(value)
															);
														}
													}
												}}
												onBlur={handleBlur}
												size="small"
												fullWidth
												inputProps={{ style: styles.input_field }}
											/>
											{touched?.episodeDuration &&
												errors?.episodeDuration && (
													<Typography sx={styles.error_text}>
														{errors.episodeDuration}
													</Typography>
												)}
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Filming Location
											</Typography>
											<TextField
												id="filmingLocation"
												value={values.filmingLocation}
												onChange={handleChange}
												onBlur={handleBlur}
												size="small"
												fullWidth
												inputProps={{ style: styles.input_field }}
											/>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Shoot Days
											</Typography>
											<TextField
												id="shootDays"
												value={values.shootDays}
												onChange={(e) => {
													const value = e.target.value;
													const isValidNumber =
														value.match(/^\d*$/); // Only allow digits
													if (isValidNumber) {
														if (value === "") {
															setFieldValue("shootDays", "");
														}
														if (
															value !== "" &&
															!isNaN(value as unknown as number)
														) {
															setFieldValue(
																"shootDays",
																parseFloat(value)
															);
														}
													}
												}}
												onBlur={handleBlur}
												size="small"
												fullWidth
												inputProps={{ style: styles.input_field }}
											/>
											{touched?.shootDays && errors?.shootDays && (
												<Typography sx={styles.error_text}>
													{errors.shootDays}
												</Typography>
											)}
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Project Start Date{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<DatePickerInput
												textValue={
													Boolean(values.projectStartDate)
														? moment(
																values.projectStartDate
														  ).format("DD MMMM YYYY")
														: ""
												}
												value={
													Boolean(values.projectStartDate)
														? new Date(values.projectStartDate)
														: null
												}
												onChange={(newValue: any) => {
													setFieldTouched(
														"projectStartDate",
														true
													);
													setFieldValue(
														"projectStartDate",
														moment(newValue).format("YYYY-MM-DD")
													);
												}}
												onOpen={() =>
													setFieldTouched("projectStartDate", true)
												}
												onCloseCustom={() => {}}
											/>
											{touched?.projectStartDate &&
												errors?.projectStartDate && (
													<Typography sx={styles.error_text}>
														{errors.projectStartDate}
													</Typography>
												)}
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Project End Date{" "}
												<span style={styles.error_text}>*</span>
											</Typography>
											<DatePickerInput
												textValue={
													Boolean(values.projectEndDate)
														? moment(
																values.projectEndDate
														  ).format("DD MMMM YYYY")
														: ""
												}
												value={
													Boolean(values.projectEndDate)
														? new Date(values.projectEndDate)
														: null
												}
												onChange={(newValue: any) => {
													setFieldTouched("projectEndDate", true);
													setFieldValue(
														"projectEndDate",
														moment(newValue).format("YYYY-MM-DD")
													);
												}}
												onOpen={() =>
													setFieldTouched("projectEndDate", true)
												}
												onCloseCustom={() => {}}
											/>
											{touched?.projectEndDate &&
												errors?.projectEndDate && (
													<Typography sx={styles.error_text}>
														{errors.projectEndDate}
													</Typography>
												)}
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<Typography sx={styles.field_label}>
												Release/ TX Date
											</Typography>
											<DatePickerInput
												textValue={
													Boolean(values.filmingDate)
														? moment(values.filmingDate).format(
																"DD MMMM YYYY"
														  )
														: ""
												}
												value={
													Boolean(values.filmingDate)
														? new Date(values.filmingDate)
														: null
												}
												onChange={(newValue: any) => {
													setFieldTouched("filmingDate", true);
													setFieldValue(
														"filmingDate",
														moment(newValue).format("YYYY-MM-DD")
													);
												}}
												onOpen={() =>
													setFieldTouched("filmingDate", true)
												}
												onCloseCustom={() => {}}
											/>
											{touched?.filmingDate &&
												errors?.filmingDate && (
													<Typography sx={styles.error_text}>
														{errors.filmingDate}
													</Typography>
												)}
										</Grid>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<Box
												sx={{
													display: "flex",
													justifyContent: "flex-end",
												}}
											>
												<BlackActionContainedButton
													variant="contained"
													size="small"
													sx={{
														borderRadius: "20px",
														fontSize: "13px",
														fontWeight: 600,
														width: "120px",
													}}
													disableElevation
													disableRipple
													disabled={!isValid}
													onClick={() => handleSubmit()}
												>
													Create
												</BlackActionContainedButton>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Grid>
							{openImageCropUploader && (
								<ProjectPhotoCropper
									open={openImageCropUploader}
									setOpen={setOpenImageCropUploader}
									width={148.5}
									height={220}
									imgSrc={values?.profile_picture}
									setImgSrc={(file: any) =>
										setFieldValue("profile_picture", file)
									}
								/>
							)}
						</Grid>
					</Dialog>
				);
			}}
		</Formik>
	);
};

export default AddProjectModal;
