import React, { useEffect, useState } from "react";
import {
	Avatar,
	Box,
	Dialog,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { IModalProps } from "models/common";
import AgencyLeftSteps from "./AgencyLeftSteps";
import { IAgentFields } from "models/agents";
import { CREATE_AGENT } from "graphql/agents";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import AgencyDetailsForm from "./AgencyDetailsForm";
import RegistrationForm from "./RegistrationForm";
import BankDetailsForm from "./BankDetailsForm";
import { initialValues } from "./utils";
import { CountryCodeArr } from "utils/constants";

interface IAddAgentModalProps extends IModalProps {
	refetch: any;
}

function AddAgentModal(props: IAddAgentModalProps) {
	const { open, setOpen, refetch } = props;

	const { setLoading } = useStore();

	const [activeSection, setActiveSection] = useState(0);

	const handleClose = () => {
		setOpen(false);
	};

	const handleDialogClose = (_: any, reason: string) => {
		if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
			handleClose();
		}
	};

	const [fireCreateAgentApi, { loading: creating }] = useMutation(
		CREATE_AGENT,
		{
			onCompleted: (data) => {
				setLoading(false);
				const { createAgent } = data;
				const { status, message } = createAgent;
				handleClose();
				if (status) {
					refetch();
					toast.success(message, { delay: 10 });
				} else {
					toast.error(message, { delay: 10 });
				}
			},
		}
	);

	const handleCreateAgent = (values: IAgentFields) => {
		const {
			id,
			address,
			financialDetails,
			companyCity,
			companyCountry,
			companyState,
			pinCode,
			suburb,
			street,
			profile_picture,
			spocMobile,
			...rest
		} = values;
		const {
			haveSuperannuationAccount: agencyHave,
			smsfAbn,
			smsfBankAccountName,
			smsfBsb,
			smsfEsa,
			smsfFullName,
			smsfName,
			...restAgencyFinancialDetails
		} = financialDetails;

		fireCreateAgentApi({
			variables: {
				payload: {
					...(typeof address !== "string" &&
						address !== null && {
							address: address?.freeformAddress,
							companyCountry: address?.country || "",
							companyState: address?.countrySubdivision || "",
							companyCity: address?.municipality || "",
							suburb: address?.municipalitySubdivision || "",
							pinCode: address?.postalCode || "",
							street: address?.streetName || "",
						}),
					financialDetails: { ...restAgencyFinancialDetails },
					spocMobile: `${
						CountryCodeArr.find((x) => x.id === spocMobile.countryCode)
							?.name
					} ${spocMobile?.nationalNumber}`,
					...rest,
				},
				...(Boolean(profile_picture) &&
					typeof profile_picture !== "string" && { profile_picture }),
			},
		});
	};

	useEffect(() => {
		setLoading(creating);
	}, [creating, setLoading]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				companyName: Yup.string().required("Please enter the agency name"),
				spocName: Yup.string().required("Please enter the agent name"),
				spocEmailId: Yup.string()
					.required("Please enter the agent email")
					.email("Please enter a valid email"),
				spocMobile: Yup.object().shape({
					nationalNumber: Yup.string().required(
						"Please enter the agent contact number"
					),
				}),
				website: Yup.string()
					.matches(
						/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /,
						"Please enter a valid url"
					)
					.nullable(),
				abn: Yup.string()
					.matches(/^[0-9]{11}$/, "ABN must be 11 digits")
					.nullable(),
				acn: Yup.string()
					.matches(/^[0-9]{9}$/, "ACN must be 9 digits")
					.nullable(),
			})}
			onSubmit={handleCreateAgent}
			enableReinitialize
		>
			{(formikBag) => (
				<Dialog
					open={open}
					onClose={handleDialogClose}
					maxWidth="md"
					fullWidth
					PaperProps={{
						sx: {
							height: { xs: 600, sm: 785 },
							borderRadius: "24px",
							// overflow: "hidden"
						},
					}}
				>
					<Box
						sx={{
							height: "100%",
							overflow: "hidden",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								py: 2,
								backgroundColor: "#B6BCC325",
								position: "relative",
								px: 4,
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Avatar
									sx={{
										width: 22,
										height: 22,
										mr: 0.5,
										backgroundColor: colors.primaryBlack,
									}}
								>
									<Typography
										sx={{
											fontSize: "12px",
											color: colors.secondary,
										}}
									>
										1
									</Typography>
								</Avatar>
								<Typography
									textAlign="center"
									sx={{
										fontSize: "13px",
										color: colors.primaryBlack,
										fontWeight: 600,
									}}
								>
									Agency
								</Typography>
							</Box>
							<Box sx={{ position: "absolute", top: 10, right: 10 }}>
								<IconButton size="small" onClick={handleClose}>
									<Close
										sx={{ color: "#00000055" }}
										fontSize="small"
									/>
								</IconButton>
							</Box>
						</Box>
						<Box sx={{ flexGrow: 1, overflow: "auto" }}>
							<Box sx={{ height: "100%" }}>
								<Grid container>
									<Grid
										item
										lg={3}
										md={3}
										sm={3}
										xs={3}
										sx={{ display: { xs: "none", sm: "block" } }}
									>
										<Box sx={{ pt: 3 }}>
											<AgencyLeftSteps
												activeSection={activeSection}
												setActiveSection={setActiveSection}
												formikBag={formikBag}
											/>
										</Box>
									</Grid>
									<Grid item lg={9} md={9} sm={9} xs={12}>
										<Box
											sx={{
												borderLeft: {
													xs: "none",
													sm: "1.5px #e6e6e6 solid",
												},
												height: { xs: "546.02px", sm: "731.02px" },
											}}
										>
											{activeSection === 0 && (
												<AgencyDetailsForm
													formikBag={formikBag}
													setActiveSection={setActiveSection}
												/>
											)}
											{activeSection === 1 && (
												<RegistrationForm
													formikBag={formikBag}
													setActiveSection={setActiveSection}
												/>
											)}
											{activeSection === 2 && (
												<BankDetailsForm
													formikBag={formikBag}
													setActiveSection={setActiveSection}
												/>
											)}
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Box>
				</Dialog>
			)}
		</Formik>
	);
}

export default AddAgentModal;
