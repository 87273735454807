import React, { Dispatch, SetStateAction } from "react";
import { Avatar, Box, Dialog, Grid, Typography } from "@mui/material";
import { Share } from "@mui/icons-material";

import { IModalProps } from "models/common";
import { NewGreenPrimaryContainedButton } from "common/Buttons";
import { colors } from "theme/colors";

interface IExportFeedbackModalProps extends IModalProps {
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
}

const ExportFeedbackModal = (props: IExportFeedbackModalProps) => {
  const { open, setOpen, message, setMessage } = props;

  const handleClose = () => {
    setOpen(false);
    setMessage("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "24px", p: 3, width: "465px" } }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              sx={{ backgroundColor: "#40909130", height: 45, width: 45 }}
            >
              <Share sx={{ color: "#00000080", fontSize: 24 }} />
            </Avatar>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              color: colors.primaryBlack,
              textAlign: "center",
            }}
          >
            Export Requested
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{ fontSize: "13px", textAlign: "center", color: "#00000080" }}
          >
            {message}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NewGreenPrimaryContainedButton
              variant="contained"
              size="small"
              sx={{
                fontWeight: 600,
                fontSize: "13px",
                borderRadius: "20px",
                width: "120px",
                color: "#000",
              }}
              disableElevation
              onClick={handleClose}
            >
              Close
            </NewGreenPrimaryContainedButton>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ExportFeedbackModal;
