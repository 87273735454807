import React, { useState } from "react";
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  menuItemClasses,
} from "@mui/material";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";

import Drawer from "common/ClientDrawer";

import ContactReport from "./ContactReport";
import ContractStatusReport from "./ContractStatusReport";

const ReportTypeArr = [
  { id: "CONTACT", name: "Contacts Report" },
  { id: "CONTRACT", name: "Contract Status Report" },
];

export const Reports = () => {
  const [selectedReportType, setSelectedReportType] = useState("CONTACT");
  const [openReportTypeMenu, setOpenReportTypeMenu] = useState(false);
  const [reportTypeMenuAnchor, setReportTypeMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleReportTypeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setReportTypeMenuAnchor(e.currentTarget);
    setOpenReportTypeMenu(true);
  };

  const handleReportTypeMenuClose = () => {
    setReportTypeMenuAnchor(null);
    setOpenReportTypeMenu(false);
  };
  return (
    <Drawer>
      <Grid container spacing={2.5}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ mt: 2.3, display: "flex", justifyContent: "center" }}
        >
          <Box sx={{ display: "inline-block" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F3EDE0",
                borderRadius: "8px",
                pl: 2,
                pr: 1,
                py: 1.25,
                width: "290px",
                border: "1px #e6e6e6 solid",
                cursor: "pointer",
              }}
              onClick={handleReportTypeClick}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography sx={{ fontSize: "14px" }}>
                  {ReportTypeArr.find((x) => x.id === selectedReportType)
                    ?.name || ""}
                </Typography>
              </Box>
              <KeyboardArrowDownOutlined
                fontSize="small"
                sx={{ color: "#000" }}
              />
            </Box>
            <Menu
              anchorEl={reportTypeMenuAnchor}
              open={openReportTypeMenu}
              onClose={handleReportTypeMenuClose}
              sx={{
                "& .MuiMenu-paper": {
                  width: "290px",
                  border: "1px #00000030 solid",
                  borderRadius: "8px",
                },
              }}
              elevation={0}
            >
              <MenuList sx={{ mx: 1 }} disablePadding>
                {ReportTypeArr.map((rep) => (
                  <MenuItem
                    key={rep.id}
                    selected={selectedReportType === rep.id}
                    sx={{
                      borderRadius: "6px",
                      [`&.${menuItemClasses.selected}`]: {
                        backgroundColor: "#41B19931",
                        "&:hover": { backgroundColor: "#41B19995" },
                      },
                      "&:hover": { backgroundColor: "#B6BCC330" },
                    }}
                    onClick={() => {
                      setSelectedReportType(rep.id);
                      handleReportTypeMenuClose();
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", color: "#000" }}>
                      {rep.name}
                    </Typography>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {selectedReportType === "CONTACT" && <ContactReport />}
          {selectedReportType === "CONTRACT" && <ContractStatusReport />}
        </Grid>
      </Grid>
    </Drawer>
  );
};
