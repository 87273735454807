import React, { useEffect, useState } from "react";
import { Box, Grid, InputBase, Typography } from "@mui/material";

import { colors } from "theme/colors";

const styles = {
  input_field: {
    fontSize: "14px",
    color: colors.primaryBlack,
  },
};

interface ITimerInputProps {
  value: string;
  onChange: (value: string) => void;
}

function TimerInput(props: ITimerInputProps) {
  const { value, onChange } = props;

  const [focus, setFocus] = useState(false);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [miliseconds, setMiliseconds] = useState("");

  useEffect(() => {
    if (Boolean(value)) {
      const splitParts = value.split(":");
      setHours(splitParts[0]);
      setMinutes(splitParts[1]);
      setSeconds(splitParts[2]);
      setMiliseconds(splitParts[3]);
    }
  }, [value]);

  return (
    <Box
      sx={{
        width: "100%",
        p: focus ? 0.45 : 0.5,
        border: `1px ${focus ? colors.primaryBlack : "#e8ecee"} solid`,
        borderRadius: "4px",
        ":hover": { borderColor: colors.primaryBlack },
        ...(focus && { borderWidth: "2px" }),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#e6e6e640",
      }}
    >
      <Grid container spacing={0.25}>
        <Grid item lg={2.625} md={2.625} sm={2.625} xs={2.625}>
          <InputBase
            size="small"
            inputProps={{
              style: {
                ...styles.input_field,
                textAlign: "center",
                verticalAlign: "center",
              },
            }}
            placeholder="hh"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            value={hours}
            onChange={(e) => {
              const value = e.target.value;
              const isValidNumber = value.match(/^\d*$/); // Only allow digits

              if (isValidNumber) {
                if (value === "" || !isNaN(value as unknown as number)) {
                  if (value === "") {
                    setHours(value);
                    onChange(`:${minutes}:${seconds}:${miliseconds}`);
                  } else if (parseInt(value) >= 0 && parseInt(value) < 100) {
                    setHours(value);
                    onChange(`${value}:${minutes}:${seconds}:${miliseconds}`);
                  }
                }
              }
            }}
          />
        </Grid>
        <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
          <Typography>:</Typography>
        </Grid>
        <Grid item lg={2.625} md={2.625} sm={2.625} xs={2.625}>
          <InputBase
            size="small"
            inputProps={{
              style: {
                ...styles.input_field,
                textAlign: "center",
                verticalAlign: "center",
              },
            }}
            placeholder="mm"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            value={minutes}
            onChange={(e) => {
              const value = e.target.value;
              const isValidNumber = value.match(/^\d*$/); // Only allow digits

              if (isValidNumber) {
                if (value === "" || !isNaN(value as unknown as number)) {
                  if (value === "") {
                    setMinutes(value);
                    onChange(`${hours}::${seconds}:${miliseconds}`);
                  } else if (parseInt(value) >= 0 && parseInt(value) < 60) {
                    setMinutes(value);
                    onChange(`${hours}:${value}:${seconds}:${miliseconds}`);
                  }
                }
              }
            }}
          />
        </Grid>
        <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
          <Typography>:</Typography>
        </Grid>
        <Grid item lg={2.625} md={2.625} sm={2.625} xs={2.625}>
          <InputBase
            size="small"
            inputProps={{
              style: {
                ...styles.input_field,
                textAlign: "center",
                verticalAlign: "center",
              },
            }}
            placeholder="ss"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            value={seconds}
            onChange={(e) => {
              const value = e.target.value;
              const isValidNumber = value.match(/^\d*$/); // Only allow digits

              if (isValidNumber) {
                if (value === "" || !isNaN(value as unknown as number)) {
                  if (value === "") {
                    setSeconds(value);
                    onChange(`${hours}:${minutes}::${miliseconds}`);
                  } else if (parseInt(value) >= 0 && parseInt(value) < 60) {
                    setSeconds(value);
                    onChange(`${hours}:${minutes}:${value}:${miliseconds}`);
                  }
                }
              }
            }}
          />
        </Grid>
        <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
          <Typography>:</Typography>
        </Grid>
        <Grid item lg={2.625} md={2.625} sm={2.625} xs={2.625}>
          <InputBase
            size="small"
            inputProps={{
              style: {
                ...styles.input_field,
                textAlign: "center",
                verticalAlign: "center",
              },
            }}
            placeholder="ms"
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            value={miliseconds}
            onChange={(e) => {
              const value = e.target.value;
              const isValidNumber = value.match(/^\d*$/); // Only allow digits

              if (isValidNumber) {
                if (value === "" || !isNaN(value as unknown as number)) {
                  if (value === "") {
                    setMiliseconds(value);
                    onChange(`${hours}:${minutes}:${seconds}:`);
                  } else if (parseInt(value) >= 0 && parseInt(value) < 60) {
                    setMiliseconds(value);
                    onChange(`${hours}:${minutes}:${seconds}:${value}`);
                  }
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default TimerInput;
