import { create } from "zustand";

interface IProjectDetailsTabs {
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
}

export const useProjectDetailsTabs = create<IProjectDetailsTabs>((set) => ({
  selectedTab: 0,
  setSelectedTab: (tab: number) => set({ selectedTab: tab }),
}));
