import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";

import { IModalProps } from "models/common";
import { OutlinedBtn } from "common/Buttons";
import { GlobalLabels } from "common/AppConstants";
import { colors } from "theme/colors";
import { useMutation } from "@apollo/client";
import { SHARE_CONTACT_LINK } from "graphql/contacts";
import { useStore } from "utils/store";
import { toast } from "react-toastify";

const styles = {
  alert_img: { width: "80px", alignSelf: " center", paddingTop: 40 },
  delete_text: {
    textAlign: "center",
    fontSize: "24px",
    color: colors.primaryBlack,
    fontWeight: 600,
    py: 2,
  },
  delete_desc: {
    textAlign: "center",
    fontSize: "18px",
    color: colors.secondaryBlack,
    pb: 2,
    px: 4,
  },
  actions_container: {
    display: "flex",
    justifyContent: "space-between",
    pt: 2,
    pb: 4,
    px: 4,
  },
  btn: { fontSize: "16px", width: "45%", fontWeight: 500 },
  noBtn: { color: colors.secondaryBlack },
};

interface IShareModalProps extends IModalProps {
  selectedContact: string;
  setSelectedContact: Dispatch<SetStateAction<string>>;
  selectedContactEmail: string;
  setSelectedContactEmail: Dispatch<SetStateAction<string>>;
}

function ShareModal(props: IShareModalProps) {
  const {
    open,
    setOpen,
    selectedContact,
    setSelectedContact,
    selectedContactEmail,
    setSelectedContactEmail,
  } = props;

  const { setLoading } = useStore();

  const [fireShareContactLinkApi, { loading }] = useMutation(
    SHARE_CONTACT_LINK,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { shareContactForm } = data;
        const { status, message } = shareContactForm;
        if (status) {
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
        handleClose();
      },
    }
  );

  const handleClose = () => {
    setSelectedContact("");
    setSelectedContactEmail("");
    setOpen(false);
  };

  const handleShare = () => {
    fireShareContactLinkApi({ variables: { contactId: selectedContact } });
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ pt: 4 }}>
        <Typography sx={styles.delete_desc}>
          {"A link will be sent to"}{" "}
          <span
            style={{ color: colors.primary }}
          >{`"${selectedContactEmail}"`}</span>{" "}
          {"for them to fill the details."}
        </Typography>
        <Box sx={styles.actions_container}>
          <OutlinedBtn
            variant="outlined"
            sx={[styles.btn, styles.noBtn]}
            onClick={handleClose}
          >
            {GlobalLabels.CANCEL}
          </OutlinedBtn>
          <Button variant="contained" sx={styles.btn} onClick={handleShare}>
            Share Link
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default ShareModal;
