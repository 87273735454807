import { create } from "zustand";

interface IProjectCreateModal {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const useProjectCreateModal = create<IProjectCreateModal>((set) => ({
  open: false,
  onOpen: () => set({ open: true }),
  onClose: () => set({ open: false }),
}));
