import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { useProjectDetailsMenu } from "hooks/useProjectDetailsMenu";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { RouteNames } from "routes/routeNames";

import { ProjectDetailsMenuItems } from ".";

interface ITopNavBarProps {
  children: React.ReactNode;
}

function TopNavBar(props: ITopNavBarProps) {
  const navigate = useNavigate();
  const projectId = useParams();
  const projectDetailsMenu = useProjectDetailsMenu();
  const projectDetailsTabs = useProjectDetailsTabs();

  return (
    <Box
      sx={{
        mt: 2.75,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#e6e6e670",
          borderRadius: "20px",
        }}
      >
        <Grid container>
          {ProjectDetailsMenuItems.map((item) => (
            <Grid item key={item.id}>
              <Box
                sx={{
                  ...(projectDetailsMenu.selectedMenuItem.id === item.id && {
                    backgroundColor: "#fff",
                  }),
                  ...(projectDetailsMenu.selectedMenuItem.id === item.id && {
                    border: "1px #D9D9D8 solid",
                  }),
                  borderRadius: { xs: "18px", sm: "20px" },
                  px: { xs: 1.5, sm: 3 },
                  py: 1,
                  cursor: "pointer",
                }}
                onClick={() => {
                  projectDetailsTabs.setSelectedTab(0);
                  projectDetailsMenu.onMenuItemClick(item);
                  navigate(
                    `${RouteNames.PROJECTS}/${projectId.project_id}/${item.path}`
                  );
                }}
              >
                <Typography
                  sx={{
                    ...(projectDetailsMenu.selectedMenuItem.id === item.id && {
                      fontWeight: 600,
                    }),
                    fontSize: { xs: "13px", sm: "14px" },
                    textAlign: "center",
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ width: "100%", mt: 3 }}>{props.children}</Box>
    </Box>
  );
}

export default TopNavBar;
