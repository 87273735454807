import React, { forwardRef } from "react";
import {
  AppBar as MuiAppBar,
  Box,
  Drawer as MuiDrawer,
  Toolbar,
  AppBarProps,
  CssBaseline,
} from "@mui/material";
import { CSSObject, Theme, styled } from "@mui/material/styles";

import { colors } from "theme/colors";

import Header from "./Header";
import { ITemplate } from "models/templates";

interface IContract {
  id: string;
  name: string;
  documentStatus: string;
  additionalDetails: string;
  contact: { id: string; fullName: string };
  template: ITemplate;
  isFolder: boolean;
  file: any;
  createdDate: string;
  modifiedDate: string;
  fileExists: boolean;
  signedFile: string;
}

// import Header from "./Header";
// import FillableFields from "./FillableFields";
// import ContractInfo from "./ContractInfo";
// import StatusRecord from "./StatusRecord";
// import VersionHistory from "./VersionHistory";
// import Notes from "./Notes";
// import CollabrationModal from "./CollabrationModal";
// import { ISignerArr } from "../utils";
// import { IContract } from "..";

// const drawerWidth = 400;

// const openedMixin = (theme: Theme): CSSObject => ({
//   width: drawerWidth,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
// });

// const closedMixin = (theme: Theme): CSSObject => ({
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowX: "hidden",
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up("sm")]: {
//     width: `calc(${theme.spacing(8)} + 1px)`,
//   },
// });

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": { ...openedMixin(theme) },
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": closedMixin(theme),
//   }),
// }));

// const ActionsDrawerItems = [
//   {
//     id: 1,
//     title: "View contract information",
//     icon: images.CONTRACT_INFO,
//     selectedIcon: images.CONTRACT_INFO_SEELCTED,
//   },
//   {
//     id: 2,
//     title: "Reminder",
//     icon: images.CONTRACT_REMINDER,
//     selectedIcon: images.CONTRACT_REMINDER_SELECTED,
//   },
//   {
//     id: 3,
//     title: "Status",
//     icon: images.CONTRACT_STATUS,
//     selectedIcon: images.CONTRACT_STATUS_SELECTED,
//   },
//   {
//     id: 4,
//     title: "Version History",
//     icon: images.CONTRACT_VERSION_HISTORY,
//     selectedIcon: images.CONTRACT_VERSION_HISTORY_SEELCTED,
//   },
//   {
//     id: 5,
//     title: "Notes",
//     icon: images.CONTRACT_NOTES,
//     selectedIcon: images.CONTRACT_NOTES_SELECTED,
//   },
//   {
//     id: 6,
//     title: "Track Changes",
//     icon: images.CONTRACT_TRACK_CHANGES,
//     selectedIcon: images.CONTRACT_TRACK_CHANGES_SELECTED,
//   },
//   {
//     id: 7,
//     title: "Collaboration",
//     icon: images.CONTRACT_COLLABRATION,
//     selectedIcon: images.CONTRACT_COLLABRATION_SELECTED,
//   },
//   {
//     id: 8,
//     title: "Comments",
//     icon: images.CONTRACT_COMMENTS,
//     selectedIcon: images.CONTRACT_COMMENTS_SELECTED,
//   },
// ];

interface IContractDocxProps {
  children: React.ReactNode;
  contractData: IContract | null;
  // refetch: any;
  // selectedSigners: ISignerArr[];
  // setSelectedSigners: Dispatch<SetStateAction<ISignerArr[]>>;
  // initialContractDocContent: string;
  // currency: string;
  // feeCalculatorAdded: boolean;
}

const EditorFrame = forwardRef((props: IContractDocxProps, ref: any) => {
  const {
    contractData,
    // refetch,
    // selectedSigners,
    // setSelectedSigners,
    // initialContractDocContent,
    // currency,
    // feeCalculatorAdded,
  } = props;

  // const [fieldsDrawerOpen, setFieldsDrawerOpen] = useState(false);
  // const [actionsDrawerOpen, setActionsDrawerOpen] = useState(false);
  // const [selectedActionsDrawerItem, setSelectedActionsDrawerItem] = useState<
  //   number | null
  // >(null);
  // const [collabrationModal, setCollabrationModal] = useState(false);

  return (
    <Box sx={{ display: " flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="secondary"
        elevation={0}
        sx={{ borderBottom: `1px solid ${colors.borderColor}` }}
      >
        <Header
          // ref={ref}
          contractData={contractData}
          // refetch={refetch}
          // selectedSigners={selectedSigners}
          // initialContractDocContent={initialContractDocContent}
        />
      </AppBar>
      {/* <Drawer variant="permanent" anchor="left" open={fieldsDrawerOpen}>
        <Toolbar />
        <Box
          sx={{
            minHeight: "calc(100% - 65px)",
            display: "flex",
            overflowY: "auto",
          }}
        >
          <List
            sx={{
              width: `calc(${theme.spacing(7)} + 1px)`,
              [theme.breakpoints.up("sm")]: {
                width: `calc(${theme.spacing(8)} + 1px)`,
              },
              ...(fieldsDrawerOpen && { borderRight: `1px solid #e0e0e0` }),
            }}
          >
            <ListItem disablePadding>
              {fieldsDrawerOpen && (
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: fieldsDrawerOpen ? "initial" : "center",
                    px: 2.5,
                    [`&.${listItemButtonClasses.selected}`]: {
                      backgroundColor: colors.secondary,
                      borderLeft: `5px solid ${colors.primary}`,
                      borderRadius: 0,
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                  selected={fieldsDrawerOpen}
                  onClick={() => {
                    setFieldsDrawerOpen(!fieldsDrawerOpen);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: fieldsDrawerOpen ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {fieldsDrawerOpen ? (
                      <img
                        src={images.CONTRACT_FILLABLE_FIELDS_SELECTED}
                        alt="icon"
                      />
                    ) : (
                      <img src={images.CONTRACT_FILLABLE_FIELDS} alt="icon" />
                    )}
                  </ListItemIcon>
                </ListItemButton>
              )}
              {!fieldsDrawerOpen && (
                <Box sx={{ minHeight: 48, px: 1.25 }}>
                  <IconButton
                    onClick={() => {
                      setFieldsDrawerOpen(!fieldsDrawerOpen);
                    }}
                    size="large"
                    sx={{
                      ":hover": { backgroundColor: "#41B19931" },
                    }}
                  >
                    <img
                      src={images.CONTRACT_FILLABLE_FIELDS_SELECTED}
                      alt="icon"
                      style={{ width: 18 }}
                    />
                  </IconButton>
                </Box>
              )}
            </ListItem>
          </List>
          {fieldsDrawerOpen && (
            <Box
              sx={{
                width: `calc(100% - (${theme.spacing(7)} + 1px))`,
                [theme.breakpoints.up("sm")]: {
                  width: `calc(100% - (${theme.spacing(8)} + 1px))`,
                },
                height: "100%",
                overflowY: "auto",
              }}
            >
              <FillableFields
                setFieldsDrawerOpen={setFieldsDrawerOpen}
                contractData={contractData}
                selectedSigners={selectedSigners}
                setSelectedSigners={setSelectedSigners}
                ref={ref}
                refetch={refetch}
                currency={currency}
                feeCalculatorAdded={feeCalculatorAdded}
              />
            </Box>
          )}
        </Box>
      </Drawer> */}
      <Box component="main" sx={{ width: "100%", overflowX: "auto" }}>
        <Toolbar />
        {props.children}
      </Box>
      {/* <Drawer variant="permanent" anchor="right" open={actionsDrawerOpen}>
        <Toolbar />
        <Box
          sx={{
            minHeight: "calc(100% - 65px)",
            display: "flex",
          }}
        >
          {actionsDrawerOpen && (
            <Box
              sx={{
                width: `calc(100% - (${theme.spacing(7)} + 1px))`,
                [theme.breakpoints.up("sm")]: {
                  width: `calc(100% - (${theme.spacing(8)} + 1px))`,
                },
                height: "100%",
                overflowY: "auto",
              }}
            >
              {selectedActionsDrawerItem === 1 && (
                <ContractInfo
                  setActionsDrawerOpen={setActionsDrawerOpen}
                  contractData={contractData}
                  refetch={refetch}
                />
              )}
              {selectedActionsDrawerItem === 3 && (
                <StatusRecord setActionsDrawerOpen={setActionsDrawerOpen} />
              )}
              {selectedActionsDrawerItem === 4 && (
                <VersionHistory setActionsDrawerOpen={setActionsDrawerOpen} />
              )}
              {selectedActionsDrawerItem === 5 && (
                <Notes setActionsDrawerOpen={setActionsDrawerOpen} />
              )}
            </Box>
          )}
          <List
            sx={{
              width: `calc(${theme.spacing(7)} + 1px)`,
              [theme.breakpoints.up("sm")]: {
                width: `calc(${theme.spacing(8)} + 1px)`,
              },
              height: "100%",
              ...(actionsDrawerOpen && { borderLeft: `1px solid #e0e0e0` }),
            }}
          >
            {ActionsDrawerItems.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: actionsDrawerOpen ? "initial" : "center",
                    px: 2.5,
                    [`&.${listItemButtonClasses.selected}`]: {
                      backgroundColor: colors.secondary,
                      borderLeft: `5px solid ${colors.primary}`,
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                  selected={item.id === selectedActionsDrawerItem}
                  onClick={() => {
                    setSelectedActionsDrawerItem(item.id);
                    if (item.id !== 7) {
                      if (
                        actionsDrawerOpen &&
                        selectedActionsDrawerItem === item.id
                      ) {
                        setSelectedActionsDrawerItem(null);
                        setActionsDrawerOpen(!actionsDrawerOpen);
                      } else {
                        setActionsDrawerOpen(true);
                      }
                    } else {
                      setActionsDrawerOpen(false);
                      setCollabrationModal(true);
                    }
                  }}
                >
                  <Tooltip title={item.title}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          selectedActionsDrawerItem === item.id
                            ? item.selectedIcon
                            : item.icon
                        }
                        alt="icon"
                      />
                    </ListItemIcon>
                  </Tooltip>
                </ListItemButton>
              </ListItem>
            ))}
            {Boolean(contractData?.signedFile) && (
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: actionsDrawerOpen ? "initial" : "center",
                    px: 2.5,
                    [`&.${listItemButtonClasses.selected}`]: {
                      backgroundColor: colors.secondary,
                      borderLeft: `5px solid ${colors.primary}`,
                      ":hover": {
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                  onClick={() => {
                    var hiddenElement = document.createElement("a");
                    hiddenElement.href = contractData?.signedFile!;
                    hiddenElement.target = "_blank";
                    hiddenElement.download = `${contractData?.name}.pdf`;
                    hiddenElement.click();
                  }}
                >
                  <Tooltip title="Signed Document">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: "center",
                      }}
                    >
                      <PictureAsPdf sx={{ color: colors.error }} />
                    </ListItemIcon>
                  </Tooltip>
                </ListItemButton>
              </ListItem>
            )}
          </List>
          {collabrationModal && (
            <CollabrationModal
              open={collabrationModal}
              setOpen={setCollabrationModal}
            />
          )}
        </Box>
      </Drawer> */}
    </Box>
  );
});

export default EditorFrame;
