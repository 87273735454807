import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  ChevronRight,
  Download,
  FiberManualRecord,
} from "@mui/icons-material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { ComplexityMapping, ContractStatusMapping } from "utils/constants";
import { useContract } from "hooks/useContract";
import {
  DOWNLOAD_CONTRACT_AS_PDF,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_STATUS,
} from "graphql/contracts";
import { useStore } from "utils/store";
import { RouteNames } from "routes/routeNames";
import { IArr } from "models/common";
import { fetchContractStatusUpdateArr } from "utils/helpers";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import StatusChangeWarningModal from "./StatusChangeWarningModal";
import { ContractDocxStyles as styles } from "./styles";
import { ISignerArr } from "../utils";
import { IContract } from "..";

interface IHeaderProps {
  contractData: IContract | null;
  refetch: any;
  selectedSigners: ISignerArr[];
  initialContractDocContent: string;
}

const Header = forwardRef((props: IHeaderProps, ref: any) => {
  const { contractData, refetch, selectedSigners, initialContractDocContent } =
    props;

  const navigate = useNavigate();
  const params = useParams();
  const { fillableFields, tags } = useContract();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const foldersEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [complexity, setComplexity] = useState("3");
  const [selectedContractStatus, setSelectedContractStatus] = useState("DRAFT");
  const [tempCnctStatus, setTempCnctStatus] = useState("");
  const [statusChangeWarningModal, setStatusChangeWarningModal] =
    useState(false);
  const [openContractStatusMenu, setOpenContractStatusMenu] = useState(false);
  const [contractStatusAnchor, setContractStatusAnchor] =
    useState<HTMLElement | null>(null);
  const [cnctStatusUpdateArr, setCnctStatusUpdateArr] = useState<IArr[]>([]);

  const handleContractStatusClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    setCnctStatusUpdateArr(
      fetchContractStatusUpdateArr(contractData?.documentStatus!)
    );
    setOpenContractStatusMenu(true);
    setContractStatusAnchor(e.currentTarget);
  };

  const handleContractStatusClose = () => {
    setOpenContractStatusMenu(false);
    setContractStatusAnchor(null);
  };

  const [fireUpdateContractStatusApi, { loading: updatingStatus }] =
    useMutation(UPDATE_DOCUMENT_STATUS, {
      onCompleted: (data) => {
        const { updateDocumentStatus } = data;
        const { status, message } = updateDocumentStatus;
        setStatusChangeWarningModal(false);
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    });

  const [fireUpdateContractApi, { loading: updating }] = useMutation(
    UPDATE_DOCUMENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateDocument } = data;
        const { status, message } = updateDocument;
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleSave = () => {
    if (Boolean(ref?.current?.dom.get("fillable-fields"))) {
      const element = ref?.current?.dom.get("fillable-fields")!;
      element.innerText = JSON.stringify(fillableFields);
      for (let i = 0; i < fillableFields.length; i = i + 1) {
        const ele = ref?.current?.dom.get(fillableFields[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
    } else {
      for (let i = 0; i < fillableFields.length; i = i + 1) {
        const ele = ref?.current?.dom.get(fillableFields[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
      ref?.current?.dom.add(
        ref?.current?.getBody(),
        "div",
        {
          style: "display:none;",
          id: "fillable-fields",
        },
        JSON.stringify(fillableFields)
      );
    }

    if (Boolean(ref?.current?.dom.get("finka-tags"))) {
      const element = ref?.current?.dom.get("finka-tags")!;
      element.innerText = JSON.stringify(tags);
      for (let i = 0; i < tags.length; i = i + 1) {
        const ele = ref?.current?.dom.get(tags[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
    } else {
      for (let i = 0; i < tags.length; i = i + 1) {
        const ele = ref?.current?.dom.get(tags[i].id)!;
        if (Boolean(ele)) {
          ele.style.backgroundColor = "transparent";
          ele.style.padding = "0px";
        }
      }
      ref?.current?.dom.add(
        ref?.current?.getBody(),
        "div",
        {
          style: "display:none;",
          id: "finka-tags",
        },
        JSON.stringify(tags)
      );
    }

    const blob = new Blob([ref.current.getContent()], { type: "text/html" });
    const file = new File([blob], `${contractData?.name}.html`, {
      type: "text/html",
    });

    let additionalDetails: any = null;
    if (selectedSigners.length > 0) {
      const additionalDetailsJSON = contractData?.additionalDetails;
      const parsedAdditionalDetailsJSON = Boolean(additionalDetailsJSON)
        ? JSON.parse(additionalDetailsJSON!)
        : null;
      if (Boolean(parsedAdditionalDetailsJSON)) {
        additionalDetails = {
          ...parsedAdditionalDetailsJSON,
          SIGNERS: selectedSigners,
        };
      } else {
        additionalDetails = { SIGNERS: selectedSigners };
      }
    }

    fireUpdateContractApi({
      variables: {
        payload: {
          fetchDocumentId: contractData?.id,
          name: contractData?.name,
          isFolder: contractData?.isFolder,
          ...(Boolean(additionalDetails) && {
            additionalDetails: JSON.stringify(additionalDetails),
          }),
        },
        file: file,
      },
    });
  };

  const [fireDownloadContractApi, { loading: downloading }] = useLazyQuery(
    DOWNLOAD_CONTRACT_AS_PDF,
    {
      onCompleted: (data) => {
        const { getContractAsPDF } = data;
        const { status, contract, message } = getContractAsPDF;
        if (status) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href = `data:application/pdf;base64,${contract}`;
          hiddenElement.target = "_blank";
          hiddenElement.download = `${contractData?.name}.pdf`;
          hiddenElement.click();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const handleDownload = () => {
    if (
      contractData?.documentStatus === "PARTIALLY_EXECUTED" ||
      contractData?.documentStatus === "FULLY_EXECUTED"
    ) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = contractData?.signedFile;
      hiddenElement.target = "_blank";
      hiddenElement.download = `${contractData?.name}.pdf`;
      hiddenElement.click();
    } else {
      fireDownloadContractApi({ variables: { id: contractData?.id } });
    }
  };

  useEffect(() => {
    if (Boolean(contractData)) {
      setSelectedContractStatus(contractData?.documentStatus!);
      const parsedJSON = Boolean(contractData?.template?.additionalDetails)
        ? JSON.parse(contractData?.template?.additionalDetails!)
        : null;
      if (Boolean(parsedJSON)) {
        setComplexity(parsedJSON.complexity);
      }
    }
  }, [contractData]);

  useEffect(() => {
    setLoading(downloading || updating || updatingStatus);
  }, [downloading, setLoading, updating, updatingStatus]);

  return (
    <Toolbar>
      <Box sx={styles.headerLeftContainer}>
        <IconButton
          edge="start"
          color="inherit"
          sx={styles.mr1}
          onClick={() => {
            if (ref.current.getContent() === initialContractDocContent) {
              if (Boolean(sessionStorage.getItem("redirect_url"))) {
                navigate(
                  `${RouteNames.PROJECTS}/${params?.project_id}/indexes`
                );
                sessionStorage.removeItem("redirect_url");
              } else {
                navigate(-1);
              }
            } else {
              if (
                window.confirm(
                  "ALERT!\n\nAre you sure you want to leave?\nAll unsaved changes will be lost."
                )
              ) {
                navigate(-1);
              }
            }
          }}
        >
          <ArrowBack sx={{ color: colors.iconColor }} />
        </IconButton>
        <Typography sx={[styles.contract_name_text, styles.mr1]}>
          {contractData?.name}
        </Typography>
        <img
          src={(ComplexityMapping as any)[complexity].flag}
          alt="complexity"
          style={styles.complexity_flag}
        />
      </Box>
      <Box sx={styles.headerRightContainer}>
        <Box sx={styles.contract_status_container}>
          <Box
            sx={[
              styles.contract_status_label_container,
              styles.flex_Acenter,
              {
                backgroundColor: (ContractStatusMapping as any)[
                  selectedContractStatus
                ].color,
                ...(!(
                  Boolean(ROLE_PERMISSIONS) &&
                  foldersEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]
                    ?.FOLDERS_EDIT
                ) && { borderRadius: "20px" }),
              },
            ]}
          >
            <Typography sx={styles.contract_status_label_text}>
              {(ContractStatusMapping as any)[selectedContractStatus].name}
            </Typography>
          </Box>
          {Boolean(ROLE_PERMISSIONS) &&
            foldersEditIndex !== undefined &&
            ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]
              ?.FOLDERS_EDIT && (
              <Box
                sx={[
                  styles.contract_stat_icon_container,
                  styles.flex_Acenter,
                  {
                    backgroundColor: (ContractStatusMapping as any)[
                      selectedContractStatus
                    ].color,
                  },
                ]}
              >
                <IconButton
                  size="small"
                  aria-controls={
                    openContractStatusMenu ? "basic-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openContractStatusMenu ? "true" : undefined}
                  onClick={handleContractStatusClick}
                >
                  <ChevronRight
                    fontSize="small"
                    sx={{ color: colors.secondary }}
                  />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={contractStatusAnchor}
                  open={openContractStatusMenu}
                  onClose={handleContractStatusClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={styles.menu_container}
                  elevation={0}
                >
                  <MenuList>
                    {cnctStatusUpdateArr.map((item) => (
                      <MenuItem
                        key={item.id}
                        onClick={() => {
                          if (item.id === "APPROVED") {
                            setTempCnctStatus(item.id);
                            setStatusChangeWarningModal(true);
                          } else {
                            setSelectedContractStatus(item.id);
                            fireUpdateContractStatusApi({
                              variables: {
                                payload: {
                                  documentId: contractData?.id,
                                  documentStatus: item.id,
                                },
                              },
                            });
                          }
                          handleContractStatusClose();
                        }}
                      >
                        <ListItemIcon>
                          <FiberManualRecord
                            fontSize="small"
                            sx={{
                              color: (ContractStatusMapping as any)[item.id]
                                .color,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText sx={styles.cnct_status_menu_text}>
                          {item.name}
                        </ListItemText>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
            )}
        </Box>
        {Boolean(ROLE_PERMISSIONS) &&
          foldersEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT && (
            <Box sx={{ ml: 1 }}>
              <Button size="small" variant="outlined" onClick={handleSave}>
                Save
              </Button>
            </Box>
          )}
        {contractData?.fileExists && (
          <Box sx={{ ml: 1 }}>
            <IconButton onClick={handleDownload}>
              <Download color="primary" />
            </IconButton>
          </Box>
        )}
      </Box>
      {statusChangeWarningModal && (
        <StatusChangeWarningModal
          open={statusChangeWarningModal}
          setOpen={setStatusChangeWarningModal}
          OnYes={() => {
            setSelectedContractStatus(tempCnctStatus);
            fireUpdateContractStatusApi({
              variables: {
                payload: {
                  documentId: contractData?.id,
                  documentStatus: tempCnctStatus,
                },
              },
            });
          }}
        />
      )}
    </Toolbar>
  );
});

export default Header;
