import { colors } from "theme/colors";

export const LoginStyles = {
  login_signup_bg: {
    width: "100%",
    height: "100%",
  },
  login_form_container: {
    height: "100%",
    width: "100%",
  },
  content_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title_text: {
    fontSize: "25px",
    fontWeight: 600,
    textAlign: "center",
    color: colors.primaryBlack,
  },
  textfield_container: { mt: 3 },
  textfield: { width: { xs: "100%", md: "540px" } },
  forgot_pwd_container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  input_field: {
    backgroundColor: "#e6e6e640",
    borderRadius: "6px",
    fontSize: "14px",
    color: colors.primaryBlack,
  },
  textfield_label: {
    fontSize: "16px",
    color: colors.whiteGrey500,
    fontWeight: 500,
    mb: 1,
  },
  forgot_pwd: {
    fontSize: "12px",
    color: "#a6a6a6",
    cursor: "pointer",
    fontWeight: 500,
  },
  pwd_icon: { color: colors.iconColor },
  login_btn: {
    width: { xs: "100%", md: "540px" },
    fontSize: "14px",
    // fontWeight: 600,
    // borderRadius: "24px",
    py: 1.25,
  },
  social_btn: {
    width: { xs: "45%", md: "258px" },
    fontSize: " 16px",
    color: colors.primaryBlack,
  },
  divider: { color: colors.whiteGrey500, my: 3 },
  no_account_text: { color: colors.secondaryBlack, fontSize: "14px" },
  title_container: { mt: 5 },
  error_text: { fontSize: "12px", color: "#D3010E" },
};
