import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";

import { IModalProps } from "models/common";
import { useTemplates } from "hooks/useTemplates";
import { colors } from "theme/colors";

import { ITagsT } from ".";

const styles = {
  flex_JCsb_Acenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modal_header_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  field_label: {
    color: colors.grey,
    fontSize: "14px",
    fontWeight: 500,
    mb: 0.75,
  },
  text_input: { fontSize: "14px", color: colors.primaryBlack },
  error_text: { fontSize: "12px", color: "#D3010E" },
  disabled_btn: {
    "&.Mui-disabled": {
      backgroundColor: colors.grey,
      color: "#c0c0c0",
    },
  },
  modal_footer_container: { p: 2, backgroundColor: `${colors.primary}20` },
};

interface ITagModalProps extends IModalProps {
  editTagData: ITagsT | null;
  setEditTagData: Dispatch<SetStateAction<ITagsT | null>>;
}

const TagModal = forwardRef((props: ITagModalProps, ref: any) => {
  const { open, setOpen, editTagData, setEditTagData } = props;

  const { tags, setTags } = useTemplates();
  const [initialValues, setInitialValues] = useState<ITagsT>({
    id: "",
    tagName: "",
    tagDescription: "",
    preview: "",
  });

  const handleClose = () => {
    setEditTagData(null);
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const handleSaveTag = (values: ITagsT) => {
    if (Boolean(editTagData)) {
      let tempTags = [...tags];
      const index = tempTags.findIndex((x) => x.id === editTagData?.id!);
      tempTags[index] = { ...values };
      setTags([...tempTags]);
    } else {
      const id = uuid();
      const content = ref.current.selection.getContent();
      ref.current.selection.setContent(`<div id=${id}>${content}</div>`);
      const tempTags = [...tags];
      tempTags.push({ ...values, id: id, preview: content });
      setTags([...tempTags]);
    }
    handleClose();
  };

  useEffect(() => {
    if (Boolean(editTagData)) {
      setInitialValues(editTagData!);
    }
  }, [editTagData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        tagName: Yup.string().required("Please enter the name"),
        tagDescription: Yup.string().required("Please enter the description"),
      })}
      onSubmit={handleSaveTag}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Dialog
              open={open}
              onClose={handleDialogClose}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle>
                <Box sx={styles.flex_JCsb_Acenter}>
                  <Typography sx={styles.modal_header_text}>
                    {Boolean(editTagData) ? "Edit Tag" : "Add Tag"}
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <Clear />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      Tag name <span style={{ color: "#D3010E" }}>*</span>
                    </Typography>
                    <TextField
                      id="tagName"
                      value={values.tagName}
                      onChange={(e) => {
                        setFieldValue("tagName", e.target.value);
                      }}
                      onBlur={handleBlur}
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.text_input }}
                    />
                    {touched?.tagName && errors?.tagName && (
                      <Typography sx={styles.error_text}>
                        {errors.tagName}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      Tag description{" "}
                      <span style={{ color: "#D3010E" }}>*</span>
                    </Typography>
                    <TextField
                      id="tagDescription"
                      value={values.tagDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.text_input }}
                    />
                    {touched?.tagDescription && errors?.tagDescription && (
                      <Typography sx={styles.error_text}>
                        {errors.tagDescription}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={styles.modal_footer_container}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => handleSubmit()}
                  disabled={!isValid}
                  sx={styles.disabled_btn}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
});

export default TagModal;
