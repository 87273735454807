import { colors } from "theme/colors";

export const SetPasswordStyles = {
  setPwd_bg: { width: "100%", height: "100%" },
  setPwd_form_container: {
    height: "100%",
    width: "100%",
  },
  content_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title_text: {
    fontSize: "25px",
    fontWeight: 600,
    textAlign: "center",
    color: colors.primaryBlack,
  },
  textfield_container: { mt: 5 },
  textfield: { width: { xs: "100%", md: "540px" } },
  textfield_label: {
    fontSize: "14px",
    color: "#00000070",
    fontWeight: 500,
    mb: 0.75,
  },
  setPwd_btn: {
    width: { xs: "100%", md: "540px" },
    fontSize: "14px",
    // fontWeight: 600,
    // borderRadius: "24px",
    py: 1.25,
  },
  pwd_icon: { color: colors.iconColor },
  title_container: { mt: 5 },
  error_text: { fontSize: "12px", color: "#D3010E" },
  border_container: {
    border: `1px solid ${colors.whiteGrey400}60`,
    borderRadius: "6px",
    width: { xs: "100%", md: "540px" },
    mt: 1.5,
  },
  inst_text: { fontSize: "13px", fontWeight: 500, color: colors.iconColor },
  input_field: {
    backgroundColor: "#e6e6e640",
    borderRadius: "6px",
    fontSize: "14px",
    color: colors.primaryBlack,
  },
};
