import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { ModeEditOutlineOutlined } from "@mui/icons-material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useLazyQuery, useQuery } from "@apollo/client";

import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { useStore } from "utils/store";
import { IIndexItemClearance } from "models/indexes";
import { IArr } from "models/common";
import {
  GET_ALL_INDEX_ITEMS,
  GET_CLEARANCE_CATEGORY,
  GET_CLEARANCE_SUBCATEGORY,
  GET_INDEX_DETAILS,
} from "graphql/indexes";
import { RouteNames } from "routes/routeNames";
import { GET_CONTRACT_FILE } from "graphql/contracts";
import Table from "pages/ClientScreens/ProjectDetails/IndexItems/StyledTable";
import { capitalize } from "utils/helpers";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import Header from "./Header";
import AddEditIndexItemModal from "../IndexItems/AddEditIndexItemModal";
import DeleteIndexItemModal from "../IndexItems/DeleteModal";
import AddEditFolderModal from "../IndexItems/AddEditFolderModal";
import { IndexItemsStyles as indexStyles } from "../IndexItems/styles";
import { ProjectDetailsStyles as styles } from "../styles";
import ProjectDetailsTabs from "..";

export function ClearanceItems() {
  const projectDetailsTabs = useProjectDetailsTabs();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { setLoading } = useStore();
  const boxRef = useRef(null);
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const clearanceViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CLEARANCE_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const clearanceEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CLEARANCE_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [indexItems, setIndexItems] = useState<IIndexItemClearance[]>([]);
  const [indexItemCount, setIndexItemCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [addEditIndexItemModal, setAddEditIndexItemModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedIndexItem, setSelectedIndexItem] = useState("");
  const [selectedIndexItemName, setSelectedIndexItemName] = useState("");
  const [indexTempTypes, setIndexTempTypes] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState("name");
  const [isClearanceIndex, setIsClearanceIndex] = useState(false);
  const [nestedIdArr, setNestedIdArr] = useState<string[]>([]);
  const [breadCrumbArr, setBreadCrumbArr] = useState<IArr[]>([]);
  const [addEditFolderModal, setAddEditFolderModal] = useState(false);
  const [counter, setCounter] = useState(1);
  const [isRegisterItem, setIsRegisterItem] = useState(false);
  const [clearanceCategoryArr, setClearanceCategoryArr] = useState<IArr[]>([]);
  const [clearanceSubCategoryArr, setClearanceSubCategoryArr] = useState<
    IArr[]
  >([]);
  const [openFileLink, setOpenFileLink] = useState(false);

  useEffect(() => {
    setNestedIdArr([]);
    setBreadCrumbArr([]);
    setCounter(1);
    const fullString = location?.pathname;
    const substring = "clearances/";
    const position = fullString.indexOf(substring);
    const registerIds = fullString.substring(position + substring.length);
    setNestedIdArr(registerIds.split("/"));
  }, [location]);

  const [getNestedFolderName, { loading: gettingTitle }] = useLazyQuery(
    GET_INDEX_DETAILS,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { getRegister } = data;
        const { status, register } = getRegister;
        if (status) {
          const { id, name, templateTypes, isClearance } = register;
          setIsClearanceIndex(isClearance);
          setIndexTempTypes(templateTypes.map((x: any) => x.id));
          const tempArr = [...breadCrumbArr];
          tempArr.push({ id, name });
          setBreadCrumbArr([...tempArr]);
          if (nestedIdArr.length > 1 && counter < nestedIdArr.length) {
            getNestedFolderName({
              variables: {
                payload: {
                  projectId: params?.project_id,
                  registerId: nestedIdArr[counter],
                },
              },
            });
          }
          setCounter(counter + 1);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (
      nestedIdArr.length > 0 &&
      counter === 1 &&
      Boolean(ROLE_PERMISSIONS) &&
      clearanceViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[clearanceViewIndex]?.CLEARANCE_VIEW
    ) {
      getNestedFolderName({
        variables: {
          payload: {
            projectId: params?.project_id,
            registerId: nestedIdArr[0],
          },
        },
      });
    }
  }, [counter, getNestedFolderName, nestedIdArr, params?.project_id]);

  const { loading: gettingCategories } = useQuery(GET_CLEARANCE_CATEGORY, {
    variables: {},
    skip:
      !isClearanceIndex ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        clearanceViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[clearanceViewIndex]?.CLEARANCE_VIEW
      ),
    onCompleted: (data) => {
      const { clearanceCategories } = data;
      if (Boolean(clearanceCategories)) {
        setClearanceCategoryArr(clearanceCategories);
      } else {
        setClearanceCategoryArr([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: gettingSubCategories } = useQuery(
    GET_CLEARANCE_SUBCATEGORY,
    {
      variables: {},
      skip:
        !isClearanceIndex ||
        !(
          Boolean(ROLE_PERMISSIONS) &&
          clearanceViewIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[clearanceViewIndex]?.CLEARANCE_VIEW
        ),
      onCompleted: (data) => {
        const { clearanceSubCategories } = data;
        if (Boolean(clearanceSubCategories)) {
          setClearanceSubCategoryArr(clearanceSubCategories);
        } else {
          setClearanceSubCategoryArr([]);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const { loading, refetch, fetchMore } = useQuery(GET_ALL_INDEX_ITEMS, {
    variables: {
      page: 1,
      limit: 50,
      filter: {
        project_id: params?.project_id,
        register_id:
          nestedIdArr?.length > 0
            ? nestedIdArr[nestedIdArr.length - 1]
            : params?.index_id,
        name__icontains: searchText,
      },
      ...(Boolean(sortBy) && { sorted: sortBy }),
    },
    skip:
      !params?.project_id ||
      !params?.index_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        clearanceViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[clearanceViewIndex]?.CLEARANCE_VIEW
      ),
    onCompleted: (data) => {
      setLoading(false);
      const { getRegisterItems } = data;
      const { status, count, registerItems } = getRegisterItems;
      if (status) {
        setIndexItemCount(count);
        setIndexItems(
          registerItems.map((x: any) => {
            const tempJSON = Boolean(x?.additionalDetails)
              ? JSON.parse(x?.additionalDetails)
              : null;
            return {
              ...x,
              clearance_category: Boolean(tempJSON)
                ? tempJSON?.clearance_category
                : "",
              clearance_sub_category: Boolean(tempJSON)
                ? tempJSON?.clearance_sub_category
                : "",
              clearance_script_version: Boolean(tempJSON)
                ? tempJSON?.clearance_script_version
                : "",
              clearance_script_page: Boolean(tempJSON)
                ? tempJSON?.clearance_script_page
                : "",
              clearance_script_scene: Boolean(tempJSON)
                ? tempJSON?.clearance_script_scene
                : "",
              clearance_description: Boolean(tempJSON)
                ? tempJSON?.clearance_description
                : "",
              clearance_timecode: Boolean(tempJSON)
                ? tempJSON?.clearance_timecode
                : "",
              clearance_basis_of_use: Boolean(tempJSON)
                ? tempJSON?.clearance_basis_of_use
                : "",
              clearance_tech_spec: Boolean(tempJSON)
                ? tempJSON?.clearance_tech_spec
                : "",
              clearance_credit_obligations: Boolean(tempJSON)
                ? tempJSON?.clearance_credit_obligations
                : "",
              clearance_credit_obligations_description: Boolean(tempJSON)
                ? tempJSON?.clearance_credit_obligations_description
                : "",
              clearance_ad_promo_restrictions: Boolean(tempJSON)
                ? tempJSON?.clearance_ad_promo_restrictions
                : "",
              clearance_issue: Boolean(tempJSON)
                ? tempJSON?.clearance_issue
                : "",
              clearance_comments: Boolean(tempJSON)
                ? tempJSON?.clearance_comments
                : "",
              clearance_legal_review: Boolean(tempJSON)
                ? tempJSON?.clearance_legal_review
                : "",
              clearance_checked: Boolean(tempJSON)
                ? tempJSON?.clearance_checked
                : "",
              clearance_screengrab: [],
            };
          })
        );
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getUploadedFile, { loading: gettingUploadedFile }] = useLazyQuery(
    GET_CONTRACT_FILE,
    {
      onCompleted: (data) => {
        // setLoading(false);
        const { getDocumentFile } = data;
        const { status, file } = getDocumentFile;
        if (status && openFileLink) {
          window.open(file, "_blank");
        }
        setOpenFileLink(false);
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        minWidth: 220,
        flex: 1,
        sortable: false,
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Box sx={styles.flex_Acenter}>
            {params1?.row?.isRegister ? (
              <img src={images.FOLDER_NEW} alt="index" style={{ height: 20 }} />
            ) : params1?.row?.itemType === "BLANK" ? (
              <img src={images.NEW_DOC_NEW} style={{ width: 18 }} alt="blank" />
            ) : Boolean(params1?.row?.document) ? (
              params1?.row?.itemType === "DOCUMENT" ? (
                <img
                  src={images.DOCUMENT_PLACEHOLDER_NEW}
                  style={{ width: 19 }}
                  alt="pdf"
                />
              ) : (
                <img
                  src={images.CONTRACT_PLACEHOLDER_NEW}
                  style={{ width: 19 }}
                  alt="pdf"
                />
              )
            ) : (
              <img src={images.NEW_DOC_NEW} style={{ width: 18 }} alt="blank" />
            )}
            <Typography
              sx={[styles.cell_text, { ml: 1, wordBreak: "break-word" }]}
            >
              {params1?.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: "clearance_script_scene",
        headerName: "Scene No.",
        headerAlign: "center",
        minWidth: 240,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Typography
            sx={[
              indexStyles.cell_text,
              {
                ...(params1?.row?.document &&
                  params1?.row.document.documentStatus === "ARCHIVED" && {
                    color: colors.whiteGrey400,
                  }),
              },
            ]}
          >
            {params1?.value}
          </Typography>
        ),
      },
      {
        field: "description",
        headerName: "Description",
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Typography
            sx={[
              indexStyles.cell_text,
              indexStyles.wrap,
              {
                ...(params1?.row?.document &&
                  params1?.row.document.documentStatus === "ARCHIVED" && {
                    color: colors.whiteGrey400,
                  }),
              },
            ]}
          >
            {params1?.value}
          </Typography>
        ),
      },
      {
        field: "itemType",
        headerName: "Item Type",
        headerAlign: "center",
        minWidth: 125,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Typography
            sx={[
              indexStyles.cell_text,
              {
                ...(params1?.row?.document &&
                  params1?.row.document.documentStatus === "ARCHIVED" && {
                    color: colors.whiteGrey400,
                  }),
              },
            ]}
          >
            {params1?.row?.isRegister ? "" : capitalize(params1?.value)}
          </Typography>
        ),
      },
      {
        field: "clearance_category",
        headerName: "Category",
        headerAlign: "center",
        minWidth: 210,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Typography
            sx={[
              indexStyles.cell_text,
              {
                ...(params1?.row?.document &&
                  params1?.row.document.documentStatus === "ARCHIVED" && {
                    color: colors.whiteGrey400,
                  }),
              },
            ]}
          >
            {params1?.value
              ? clearanceCategoryArr.find((x) => x.id === params1?.value)?.name
              : ""}
          </Typography>
        ),
      },
      {
        field: "clearance_sub_category",
        headerName: "Sub-Category",
        headerAlign: "center",
        minWidth: 240,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Typography
            sx={[
              indexStyles.cell_text,
              {
                ...(params1?.row?.document &&
                  params1?.row.document.documentStatus === "ARCHIVED" && {
                    color: colors.whiteGrey400,
                  }),
              },
            ]}
          >
            {params1?.value
              ? clearanceSubCategoryArr.find((x) => x.id === params1?.value)
                  ?.name
              : ""}
          </Typography>
        ),
      },
      {
        field: "clearance_checked",
        headerName: "Checked",
        headerAlign: "center",
        minWidth: 200,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params1: GridRenderCellParams<any>) => (
          <Typography
            sx={[
              indexStyles.cell_text,
              {
                ...(params1?.row?.document &&
                  params1?.row.document.documentStatus === "ARCHIVED" && {
                    color: colors.whiteGrey400,
                  }),
              },
            ]}
          >
            {params1?.value
              ? params1?.value === "TBC"
                ? params1?.value
                : capitalize(params1?.value)
              : ""}
          </Typography>
        ),
      },
      // {
      //   field: "status",
      //   headerName: "Item Status",
      //   headerAlign: "center",
      //   minWidth: 185,
      //   flex: 1,
      //   sortable: false,
      //   align: "center",
      //   renderCell: (params1: GridRenderCellParams<any>) => (
      //     <>
      //       {params1?.row?.isRegister ? (
      //         <></>
      //       ) : (
      //         <Chip
      //           sx={[
      //             indexStyles.chip_variant,
      //             {
      //               ...(params1?.row?.document &&
      //               params1?.row.document.documentStatus === "ARCHIVED"
      //                 ? {
      //                     backgroundColor: `${colors.grey}20`,
      //                   }
      //                 : params1?.value === "APPROVAL_PENDING"
      //                 ? { color: "#D72C51", backgroundColor: "#F8E8EC" }
      //                 : { color: "#18B370", backgroundColor: "#E0F9EE" }),
      //             },
      //           ]}
      //           label={
      //             params1.value === "APPROVAL_PENDING"
      //               ? "Pending"
      //               : capitalize(params1?.value)
      //           }
      //         />
      //       )}
      //     </>
      //   ),
      // },
      ...(Boolean(ROLE_PERMISSIONS) &&
      clearanceEditIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[clearanceEditIndex]?.CLEARANCE_EDIT
        ? [
            {
              field: "actions",
              type: "actions",
              headerName: "",
              sortable: false,
              width: 90,
              getActions: (params1: GridRowParams) =>
                params1?.row?.document &&
                params1?.row.document.documentStatus === "ARCHIVED"
                  ? []
                  : [
                      <GridActionsCellItem
                        icon={
                          <ModeEditOutlineOutlined
                            sx={{ color: "#a6a6a6", fontSize: 17 }}
                          />
                        }
                        label={
                          params1?.row?.isRegister ? "Edit Folder" : "Edit Item"
                        }
                        onClick={() => {
                          setSelectedIndexItem(params1.row.id);
                          setSelectedIndexItemName(params1?.row?.name);
                          if (params1?.row?.isRegister) {
                            setIsRegisterItem(true);
                            setAddEditFolderModal(true);
                          } else {
                            setAddEditIndexItemModal(true);
                          }
                        }}
                        sx={indexStyles.actions_text}
                      />,
                    ],
            },
          ]
        : []),
    ],
    [
      ROLE_PERMISSIONS,
      clearanceCategoryArr,
      clearanceEditIndex,
      clearanceSubCategoryArr,
    ]
  );

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const box = boxRef.current;
      const isAtBottom =
        (box as any).scrollHeight - (box as any).scrollTop <=
        (box as any).clientHeight + 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (indexItems.length < indexItemCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(indexItems.length / 50) + 1,
              limit: 50,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.getRegisterItems.registerItems;
              return {
                getRegisterItems: {
                  ...fetchMoreResult?.getRegisterItems,
                  registerItems: [...indexItems, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    const box = boxRef.current;
    if (box) {
      (box as any).addEventListener("scroll", handleScroll);
    }

    // Clean up event listener on component unmount
    return () => {
      if (box) {
        (box as any).removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchMore, setLoading, indexItems, indexItemCount]);

  useEffect(() => {
    setLoading(
      gettingCategories ||
        gettingTitle ||
        gettingSubCategories ||
        gettingUploadedFile ||
        loading
    );
  }, [
    gettingCategories,
    gettingSubCategories,
    gettingTitle,
    gettingUploadedFile,
    loading,
    setLoading,
  ]);

  useEffect(() => {
    projectDetailsTabs.setSelectedTab(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <Grid container spacing={2.5}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Header
            setAddEditIndexItemModal={setAddEditIndexItemModal}
            setSearchText={setSearchText}
            sortBy={sortBy}
            setSortBy={setSortBy}
            setAddEditFolderModal={setAddEditFolderModal}
            nestedIdArr={nestedIdArr}
            breadCrumbArr={breadCrumbArr}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            ref={boxRef}
            sx={{
              height: "calc(100vh - 245px)",
              overflow: "auto",
              position: "relative",
              mx: -3,
              mb: -3,
            }}
          >
            {indexItems.length === 0 &&
            !(
              Boolean(ROLE_PERMISSIONS) &&
              clearanceEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[clearanceEditIndex]
                ?.CLEARANCE_EDIT
            ) ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    height: "200px",
                    width: "550px",
                    backgroundColor: "#BFBDBC22",
                    borderRadius: " 24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: " column",
                  }}
                >
                  <img src={images.NEW_DOC_NEW} alt="folder" width={25} />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.primaryBlack,
                      my: 2,
                      fontWeight: 600,
                    }}
                  >
                    Its empty here!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Please reach out to your admin for access to create a item.
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Table
                rows={indexItems}
                columns={columns}
                showCellVerticalBorder={false}
                showColumnVerticalBorder={false}
                disableColumnMenu
                onRowClick={(params1: GridRowParams, _, __) => {
                  if (params1?.row?.isRegister) {
                    navigate(`${location?.pathname}/${params1?.row?.id}`);
                  }
                  if (Boolean(params1?.row?.document)) {
                    if (params1?.row?.document?.isUpload) {
                      setOpenFileLink(true);
                      getUploadedFile({
                        variables: {
                          payload: { documentId: params1?.row?.document?.id },
                        },
                      });
                    } else {
                      navigate(
                        `${RouteNames.PROJECTS}/${params?.project_id}${RouteNames.CONTRACT}/${params1?.row?.document?.id}`
                      );
                    }
                  }
                }}
                sx={{
                  ...styles.table,
                  "& .MuiDataGrid-columnHeaders": {
                    border: "none",
                    backgroundColor: "#B6BCC320",
                  },
                  "& .MuiDataGrid-row": {
                    borderBottom: "1px #e6e6e6 solid",
                    cursor: "pointer",
                    borderRadius: 0,
                  },
                  borderTop: "1px #e6e6e6 solid",
                  borderRadius: 0,
                }}
                hideFooter
              />
            )}
          </Box>
        </Grid>
        {addEditIndexItemModal && (
          <AddEditIndexItemModal
            open={addEditIndexItemModal}
            setOpen={setAddEditIndexItemModal}
            selectedIndexItem={selectedIndexItem}
            setSelectedIndexItem={setSelectedIndexItem}
            refetchTableData={refetch}
            isClearanceIndex={isClearanceIndex}
            nestedIdArr={nestedIdArr}
            setDeleteModal={setDeleteModal}
          />
        )}
        {addEditFolderModal && (
          <AddEditFolderModal
            open={addEditFolderModal}
            setOpen={setAddEditFolderModal}
            selectedIndexItem={selectedIndexItem}
            setSelectedIndexItem={setSelectedIndexItem}
            nestedIdArr={nestedIdArr}
            indexTempTypes={indexTempTypes}
            refetchTableData={refetch}
            setDeleteModal={setDeleteModal}
          />
        )}
        {deleteModal && (
          <DeleteIndexItemModal
            open={deleteModal}
            setOpen={setDeleteModal}
            selectedIndexItem={selectedIndexItem}
            setSelectedIndexItem={setSelectedIndexItem}
            selectedIndexItemName={selectedIndexItemName}
            setSelectedIndexItemName={setSelectedIndexItemName}
            refetchTableData={refetch}
            nestedIdArr={nestedIdArr}
            isRegisterItem={isRegisterItem}
            setIsRegisterItem={setIsRegisterItem}
          />
        )}
      </Grid>
    </ProjectDetailsTabs>
  );
}
