import React, { useEffect, useMemo, useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { AdminDashboardLabels } from "common/AppConstants";
import Table from "common/Table";
import { IPaginationModel } from "models/common";
import { assign } from "utils/helpers";
import { colors } from "theme/colors";

import { AdminDashboardStyles as styles } from "./styles";
import { IStatusTypeCount } from ".";

interface Props {
  stats: IStatusTypeCount[];
}

interface ITableRow {
  id: number;
  proj_type: string;
  concept: number;
  development: number;
  pre_production: number;
  production: number;
  post_production: number;
  delivered: number;
  archived: number;
  total_projs: number;
  avg: number;
}

let conceptAttr: keyof ITableRow = "concept";
let developmentAttr: keyof ITableRow = "development";
let preProductionAttr: keyof ITableRow = "pre_production";
let productionAttr: keyof ITableRow = "production";
let postProductionAttr: keyof ITableRow = "post_production";
let deliveredAttr: keyof ITableRow = "delivered";
let archivedAttr: keyof ITableRow = "archived";

const colTypeMap: any = {
  Concept: conceptAttr,
  Development: developmentAttr,
  "Pre-Production": preProductionAttr,
  Production: productionAttr,
  "Post-Production": postProductionAttr,
  Delivered: deliveredAttr,
  Archived: archivedAttr,
};

interface ITableRowMap {
  [type: string]: ITableRow;
}

function ProjectTypeVsStatus(props: Props) {
  const [paginationModel, setPaginationModel] = useState<IPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const { stats } = props;
  const [rows, setRows] = useState<ITableRow[]>([]);

  // const convertToSnakeCase = (val: string) => {
  //   return val.toLowerCase().replace("-", "_");
  // };

  useEffect(() => {
    const typeMap: ITableRowMap = {};
    let index = 0;
    stats.forEach((s) => {
      const type = s.type;
      // const statusKey = convertToSnakeCase(s.status);

      let singleRow = {
        id: index + 1,
        proj_type: type,
        concept: 0,
        development: 0,
        pre_production: 0,
        production: 0,
        post_production: 0,
        delivered: 0,
        archived: 0,
        total_projs: 0,
        avg: 0,
      };
      if (typeMap[type]) {
        singleRow = typeMap[type];
      } else {
        index = index + 1;
      }

      const typeIndex = colTypeMap[s.status];
      if (typeIndex) {
        let count =
          (singleRow[typeIndex as keyof ITableRow] as number) + s.count;
        singleRow.total_projs += s.count;
        assign(singleRow, typeIndex as keyof ITableRow, count);
      }

      typeMap[type] = singleRow;
    });
    setRows(Object.values(typeMap));
  }, [stats]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "proj_type",
        headerName: "Project Type",
        sortable: false,
        minWidth: 145,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "concept",
        headerName: "Concept",
        headerAlign: "center",
        sortable: false,
        minWidth: 120,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "development",
        headerName: "Development",
        headerAlign: "center",
        sortable: false,
        minWidth: 150,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "pre_production",
        headerName: "Pre Production",
        headerAlign: "center",
        sortable: false,
        minWidth: 170,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "production",
        headerName: "Production",
        headerAlign: "center",
        sortable: false,
        minWidth: 150,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "post_production",
        headerName: "Post Production",
        headerAlign: "center",
        sortable: false,
        minWidth: 180,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "delivered",
        headerName: "Delivered",
        headerAlign: "center",
        sortable: false,
        minWidth: 125,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "archived",
        headerName: "Archived",
        headerAlign: "center",
        sortable: false,
        minWidth: 120,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "total_projs",
        headerName: "Total Projects",
        headerAlign: "center",
        sortable: false,
        minWidth: 160,
        flex: 1,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, { color: colors.primary }]}>
            {params?.value}
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={styles.titleText}>
          {AdminDashboardLabels.PROJECTTYPE_VS_PROJECT_STATUS}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider sx={styles.divider} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Table
          rows={rows}
          columns={columns}
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          disableColumnMenu
          rowCount={rows.length}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sx={styles.table}
          hideFooter
        />
      </Grid>
    </Grid>
  );
}

export default ProjectTypeVsStatus;
