import { colors } from "theme/colors";

export const ResetPwdStyles = {
  forgot_pwd_bg: { width: "100%", height: "100vh" },
  forgot_pwd_form_container: {
    height: "100%",
    width: "100%",
  },
  content_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form_container: { mt: 5, mb: 3 },
  reset_pwd_text: {
    color: colors.primaryBlack,
    fontSize: "25px",
    fontWeight: 600,
  },
  textfield: { width: { xs: "250px", md: "540px" } },
  textfield_label: {
    fontSize: "16px",
    color: colors.whiteGrey500,
    fontWeight: 500,
    mb: 1,
  },
  pwd_icon: { color: colors.iconColor },
  continue_btn: {
    width: { xs: "250px", md: "540px" },
    fontSize: "20px",
    mt: 3,
  },
  back_btn: {
    width: { xs: "250px", md: "540px" },
    fontSize: "16px",
  },
  error_text: { fontSize: "12px", color: "#D3010E" },
};
