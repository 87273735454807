import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Tab, Tabs, useMediaQuery } from "@mui/material";

import Drawer from "common/ClientDrawer";
import { ClientProjectDetailsLabels } from "common/AppConstants";
import { IProjectDetailsMenuItem, IProjectPeopleMenuItem } from "models/common";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { RouteNames } from "routes/routeNames";
import { getPermissions } from "permissions/utils";
import theme from "theme/muiTheming";

import { ProjectDetailsStyles as styles } from "./styles";

export const ProjectDetailsMenuItems: IProjectDetailsMenuItem[] = [
  {
    id: "0",
    name: ClientProjectDetailsLabels.TABS.BASIC_DEETS,
    path: "basic-details",
  },
  {
    id: "1",
    name: ClientProjectDetailsLabels.TABS.KEY_PERSONNEL,
    path: "key-personnel",
  },
  {
    id: "2",
    name: ClientProjectDetailsLabels.TABS.COMP_DETAILS,
    path: "company-details",
  },
  {
    id: "3",
    name: ClientProjectDetailsLabels.TABS.RIGHTS_BUY,
    path: "rights-buy",
  },
];

export const ProjectPeopleMenuItems: IProjectPeopleMenuItem[] = [
  {
    id: "0",
    name: ClientProjectDetailsLabels.TABS.PROJ_CONTACTS,
    path: "project-contacts",
  },
  {
    id: "1",
    name: ClientProjectDetailsLabels.TABS.AGENTS,
    path: "agents",
  },
];

interface IProjectDetailsTabs {
  children: React.ReactNode;
}

const ProjectDetailsTabs = (props: IProjectDetailsTabs) => {
  const { children } = props;

  const matchesMd = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const projectId = useParams();
  const projectDetailsTabs = useProjectDetailsTabs();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectViewIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("PROJECT_VIEW")
  );
  const projectEditIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("PROJECT_EDIT")
  );
  const contactsViewIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("CONTACTS_VIEW")
  );
  const contactsEditIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("CONTACTS_EDIT")
  );
  const foldersViewIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("FOLDERS_VIEW")
  );
  const foldersEditIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("FOLDERS_EDIT")
  );
  const clearanceViewIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("CLEARANCE_VIEW")
  );
  const clearanceEditIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("CLEARANCE_EDIT")
  );
  const deliveryViewIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("DELIVERY_VIEW")
  );
  const deliveryEditIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("DELIVERY_EDIT")
  );
  const projectReportsIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex(
    (x: any) => x.hasOwnProperty("PROJECT_REPORTS")
  );
  const issuesViewIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("ISSUES_VIEW")
  );
  const issuesEditIndex = ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
    x.hasOwnProperty("ISSUES_EDIT")
  );
  // const projectPeopleMenu = useProjectPeopleMenu();
  // const projectDetailsMenu = useProjectDetailsMenu();

  // const handleProjDeetsMenuClick = (e: any) => {
  //   projectDetailsMenu.onOpen(e.currentTarget);
  // };

  // const handleProjDeetsMenuClose = () => {
  //   projectDetailsMenu.onClose();
  // };

  // const handleProjPeopleMenuClick = (e: any) => {
  //   projectPeopleMenu.onOpen(e.currentTarget);
  // };

  // const handleProjPeopleMenuClose = () => {
  //   projectPeopleMenu.onClose();
  // };

  useEffect(() => {}, []);

  return (
    <Drawer>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Tabs
              scrollButtons="auto"
              value={projectDetailsTabs.selectedTab}
              onChange={(e, value) => {
                if (value === 0) {
                  // e.stopPropagation();
                  // handleProjDeetsMenuClick(e);
                  // projectPeopleMenu.onMenuItemClick(null);
                  navigate(
                    `${RouteNames.PROJECTS}/${projectId.project_id}/basic-details`
                  );
                } else if (value === 1) {
                  // e.stopPropagation();
                  // handleProjPeopleMenuClick(e);
                  // projectPeopleMenu.onMenuItemClick(null);
                  navigate(
                    `${RouteNames.PROJECTS}/${projectId.project_id}/project-contacts`
                  );
                } else if (value === 2) {
                  // projectPeopleMenu.onMenuItemClick(null);
                  navigate(
                    `${RouteNames.PROJECTS}/${projectId.project_id}/indexes`
                  );
                } else if (value === 3) {
                  // projectPeopleMenu.onMenuItemClick(null);
                  navigate(
                    `${RouteNames.PROJECTS}/${projectId.project_id}/clearances`
                  );
                } else if (value === 4) {
                  // projectPeopleMenu.onMenuItemClick(null);
                  navigate(
                    `${RouteNames.PROJECTS}/${projectId.project_id}/delivery-checklist`
                  );
                } else if (value === 5) {
                  // projectPeopleMenu.onMenuItemClick(null);
                  navigate(
                    `${RouteNames.PROJECTS}/${projectId.project_id}/reports`
                  );
                } else if (value === 6) {
                  // projectPeopleMenu.onMenuItemClick(null);
                  navigate(
                    `${RouteNames.PROJECTS}/${projectId.project_id}/issue-dashboard`
                  );
                }
              }}
              variant={matchesMd ? "scrollable" : "standard"}
              TabIndicatorProps={{
                sx: { backgroundColor: "#41B19995", p: 0.25 },
              }}
            >
              {/* <Tab
                label={
                  <Box sx={[styles.flex_Acenter, { pl: 1 }]}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight:
                          projectDetailsTabs.selectedTab === 0 ? 600 : 400,
                        color: colors.primaryBlack,
                      }}
                    >
                      {projectDetailsMenu.selectedMenuItem.name}
                    </Typography>
                    <KeyboardArrowDown fontSize="small" sx={{ ml: 1 }} />
                  </Box>
                }
                sx={styles.tab_text}
                onClick={handleProjDeetsMenuClick}
              />
              <Tab
                label={
                  <Box sx={[styles.flex_Acenter, { pl: 1.5 }]}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight:
                          projectDetailsTabs.selectedTab === 1 ? 600 : 400,
                        color: colors.primaryBlack,
                      }}
                    >
                      People
                    </Typography>
                    <KeyboardArrowDown fontSize="small" sx={{ ml: 1 }} />
                  </Box>
                }
                sx={styles.tab_text}
                onClick={handleProjPeopleMenuClick}
              /> */}
              {(ROLE_PERMISSIONS[0]?.permissions[projectViewIndex]
                ?.PROJECT_VIEW ||
                ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                  ?.PROJECT_EDIT) && (
                <Tab label="Project" sx={styles.tab_text} value={0} />
              )}
              {(ROLE_PERMISSIONS[0]?.permissions[contactsViewIndex]
                ?.CONTACTS_VIEW ||
                ROLE_PERMISSIONS[0]?.permissions[contactsEditIndex]
                  ?.CONTACTS_EDIT) && (
                <Tab label="Contacts" sx={styles.tab_text} value={1} />
              )}
              {(ROLE_PERMISSIONS[0]?.permissions[foldersViewIndex]
                ?.FOLDERS_VIEW ||
                ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]
                  ?.FOLDERS_EDIT) && (
                <Tab
                  label={ClientProjectDetailsLabels.TABS.DOC_REPO}
                  sx={styles.tab_text}
                  value={2}
                  onClick={() =>
                    navigate(
                      `${RouteNames.PROJECTS}/${projectId.project_id}/indexes`
                    )
                  }
                />
              )}
              {(ROLE_PERMISSIONS[0]?.permissions[clearanceViewIndex]
                ?.CLEARANCE_VIEW ||
                ROLE_PERMISSIONS[0]?.permissions[clearanceEditIndex]
                  ?.CLEARANCE_EDIT) && (
                <Tab
                  label={ClientProjectDetailsLabels.TABS.CLEARNACE}
                  sx={styles.tab_text}
                  value={3}
                  onClick={() =>
                    navigate(
                      `${RouteNames.PROJECTS}/${projectId.project_id}/clearances`
                    )
                  }
                />
              )}
              {(ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]
                ?.DELIVERY_VIEW ||
                ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
                  ?.DELIVERY_EDIT) && (
                <Tab
                  label={ClientProjectDetailsLabels.TABS.DELIVERY}
                  sx={styles.tab_text}
                  value={4}
                  onClick={() =>
                    navigate(
                      `${RouteNames.PROJECTS}/${projectId.project_id}/delivery-checklist`
                    )
                  }
                />
              )}
              {ROLE_PERMISSIONS[0]?.permissions[projectReportsIndex]
                ?.PROJECT_REPORTS && (
                <Tab
                  label={ClientProjectDetailsLabels.TABS.REPORT}
                  sx={styles.tab_text}
                  value={5}
                />
              )}
              {(ROLE_PERMISSIONS[0]?.permissions[issuesViewIndex]
                ?.ISSUES_VIEW ||
                ROLE_PERMISSIONS[0]?.permissions[issuesEditIndex]
                  ?.ISSUES_EDIT) && (
                <Tab
                  label={ClientProjectDetailsLabels.TABS.ISSUE_DASH}
                  sx={styles.tab_text}
                  value={6}
                />
              )}
              {/* <Menu
                anchorEl={projectDetailsMenu.anchor}
                open={projectDetailsMenu.isOpen}
                onClose={handleProjDeetsMenuClose}
                sx={{
                  "& .MuiMenu-paper": {
                    minWidth: "150px",
                    border: "1px #00000030 solid",
                    borderRadius: "15px",
                    mt: 1,
                  },
                }}
                elevation={0}
              >
                {ProjectDetailsMenuItems.map((item) => (
                  <MenuItem
                    key={item.id}
                    sx={{
                      [`&.${menuItemClasses.selected}`]: {
                        backgroundColor: "#41B19931",
                        "&:hover": { backgroundColor: "#41B19995" },
                      },
                      "&:hover": { backgroundColor: "#B6BCC330" },
                    }}
                    onClick={() => {
                      projectDetailsTabs.setSelectedTab(0);
                      projectDetailsMenu.onMenuItemClick(item);
                      navigate(
                        `${RouteNames.PROJECTS}/${projectId.project_id}/${item.path}`
                      );
                      handleProjDeetsMenuClose();
                    }}
                    selected={
                      item.id === projectDetailsMenu.selectedMenuItem.id
                    }
                  >
                    <Typography
                      sx={{ fontSize: "14px", color: colors.secondaryBlack }}
                    >
                      {item.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu> */}
              {/* <Menu
                anchorEl={projectPeopleMenu.anchor}
                open={projectPeopleMenu.isOpen}
                onClose={handleProjPeopleMenuClose}
                sx={{
                  "& .MuiMenu-paper": {
                    minWidth: "150px",
                    border: "1px #00000030 solid",
                    borderRadius: "15px",
                    mt: 1,
                  },
                }}
                elevation={0}
              >
                {ProjectPeopleMenuItems.map((item) => (
                  <MenuItem
                    key={item.id}
                    sx={{
                      [`&.${menuItemClasses.selected}`]: {
                        backgroundColor: "#41B19931",
                        "&:hover": { backgroundColor: "#41B19995" },
                      },
                      "&:hover": { backgroundColor: "#B6BCC330" },
                    }}
                    onClick={() => {
                      projectDetailsTabs.setSelectedTab(1);
                      projectPeopleMenu.onMenuItemClick(item);
                      navigate(
                        `${RouteNames.PROJECTS}/${projectId.project_id}/${item.path}`
                      );
                      handleProjPeopleMenuClose();
                    }}
                    selected={
                      item.id === projectPeopleMenu?.selectedMenuItem?.id
                    }
                  >
                    <Typography
                      sx={{ fontSize: "14px", color: colors.secondaryBlack }}
                    >
                      {item.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu> */}
            </Tabs>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {children}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default ProjectDetailsTabs;
