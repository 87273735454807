import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
	mutation loginUser($payload: LoginInput!) {
		loginUser(payload: $payload) {
			status
			error
			token
			me {
				id
			}
			device
			confirmed
		}
	}
`;

export const VERIFY_TWO_FA = gql`
	mutation twoFA($payload: TwoFAInput!) {
		twoFA(payload: $payload) {
			token
			permissions
			status {
				status
				error
			}
		}
	}
`;

export const CHANGE_REGISTERED_DEVICE_MFA = gql`
	mutation deleteDevice($id: String!) {
		deleteDevice(id: $id) {
			status
			error
		}
	}
`;

export const FORGOT_PWD = gql`
	mutation resetPassword($payload: ResetPasswordRequest) {
		resetPasswordRequest(payload: $payload) {
			status
			error
		}
	}
`;

export const VERIFY_OTP_FORGOT_PWD = gql`
	mutation verifyOtp($payload: VerifiyOTPInput!) {
		verifyOtp(payload: $payload) {
			status
			error
			token
			me {
				id
			}
			device
			confirmed
		}
	}
`;

export const SET_PASSWORD = gql`
	mutation setPassword($payload: SetPasswordInput!) {
		setPassword(payload: $payload) {
			status
			error
		}
	}
`;

export const GET_USERS = gql`
	query users(
		$filter: UserFilterInput
		$page: Int
		$limit: Int
		$sorted: String
	) {
		users(filter: $filter, page: $page, limit: $limit, sorted: $sorted) {
			count
			users {
				id
				fullName
				username
				mobile {
					countryCode
					nationalNumber
					rawInput
				}
				dob
				gender
				email
				address
				profile
				designation
				roles
				company {
					id
					name
					website
					noOfEmployees
					industry {
						id
						name
					}
					abnCode
				}
				lastLogin
				createdBy {
					id
					fullName
				}
				createdDate
				profilePicture
			}
		}
	}
`;

export const GET_USER_DETAILS = gql`
	query user($id: String!) {
		user(id: $id) {
			id
			fullName
			username
			mobile {
				countryCode
				nationalNumber
				rawInput
			}
			dob
			gender
			email
			address
			profile
			designation
			roles
			company {
				id
				name
				website
				noOfEmployees
				industry {
					id
					name
				}
				abnCode
			}
			lastLogin
			createdBy {
				id
				fullName
			}
			createdDate
			projects {
				id
				name
			}
			profilePicture
		}
	}
`;

export const UPDATE_USER_DETAILS = gql`
	mutation updateUser($payload: UserUpdateInput!, $file: Upload) {
		updateUser(payload: $payload, file: $file) {
			status
			message
		}
	}
`;

export const CREATE_USER = gql`
	mutation createUser($payload: createUserInput!, $file: Upload) {
		createUser(payload: $payload, file: $file) {
			status
			message
		}
	}
`;

export const DELETE_USER = gql`
	mutation deleteUser($ids: [String!]) {
		deleteUser(ids: $ids) {
			status
			error
		}
	}
`;

export const GET_PROFILE_PICTURE = gql`
	query user($id: String!) {
		user(id: $id) {
			id
			fullName
			profilePicture
		}
	}
`;

export const GET_ADMIN_ACTIVITY_LOGS = gql`
	query activityLogs(
		$filter: ActivityLogsFilterInput
		$limit: Int
		$page: Int
	) {
		getActivityLogs(filter: $filter, page: $page, limit: $limit) {
			status
			logs {
				historyType
				historyChangeReason
				historyDate
				changedBy
				changedByProfile
			}
		}
	}
`;

export const GET_CLIENT_ACTIVITY_LOGS = gql`
	query getClientActivityLogs(
		$filter: ClientActivityLogsFilterInput
		$limit: Int
		$page: Int
	) {
		getClientActivityLogs(filter: $filter, page: $page, limit: $limit) {
			status
			message
			count
			logs {
				historyType
				historyChangeReason
				historyDate
				changedBy
				changedByProfile
				entityName
				entityType
				additionalHistoryDetails
			}
		}
	}
`;

export const GET_USERS_ARR = gql`
	query users(
		$filter: UserFilterInput
		$page: Int
		$limit: Int
		$sorted: String
	) {
		users(filter: $filter, page: $page, limit: $limit, sorted: $sorted) {
			count
			users {
				id
				fullName
				email
			}
		}
	}
`;

export const SEND_SUPPORT_REQUEST = gql`
	mutation sendFeedback($payload: FeedbackInput!) {
		sendFeedback(payload: $payload) {
			status
			message
		}
	}
`;

export const UPDATE_PROFILE = gql`
	mutation updateProfile($payload: UserUpdateInput!, $file: Upload) {
		updateProfile(payload: $payload, file: $file) {
			status
			message
		}
	}
`;
