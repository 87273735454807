import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import { IUpdateContactFieldsNew } from "models/contacts";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import { useStore } from "utils/store";
import { RouteNames } from "routes/routeNames";

interface IBankDetailsProps {
  initialValues: IUpdateContactFieldsNew;
  updateContactApi: any;
  refetch: any;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

function BankDetailsForm(props: IBankDetailsProps) {
  const { initialValues, updateContactApi, refetch, setActiveSection } = props;

  const { setLoading } = useStore();
  const naviagte = useNavigate();

  const handleSubmitManagementForm = (values: IUpdateContactFieldsNew) => {
    const { id, managementCompanyDetails } = values;
    const { companyName, financialDetails } = managementCompanyDetails;

    const {
      haveSuperannuationAccount: mgmtHave,
      smsfAbn,
      smsfBankAccountName,
      smsfBsb,
      smsfEsa,
      smsfFullName,
      smsfName,
      ...restMgmtFinancialDetails
    } = financialDetails;

    updateContactApi({
      variables: {
        payload: {
          id,
          managementCompanyDetails: {
            companyName,
            financialDetails: { ...restMgmtFinancialDetails },
          },
        },
      },
      onCompleted: (data: any) => {
        setLoading(false);
        const { updateContactBasicDetails } = data;
        const { status, message } = updateContactBasicDetails;
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
          naviagte(RouteNames.CONTACT_DETAILS_SUCCESS);
        }
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({})}
      onSubmit={handleSubmitManagementForm}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Box
          sx={{
            pt: { xs: 2, sm: 14 },
            px: 4,
            pb: 1.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <Typography
                sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}
              >
                Bank Details
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Bank Name</Typography>
              <TextField
                id="managementCompanyDetails.financialDetails.bankName"
                value={
                  values.managementCompanyDetails.financialDetails.bankName
                }
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Branch</Typography>
              <TextField
                id="managementCompanyDetails.financialDetails.branchName"
                value={
                  values.managementCompanyDetails.financialDetails.branchName
                }
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>Account Name</Typography>
              <TextField
                id="managementCompanyDetails.financialDetails.accountName"
                value={
                  values.managementCompanyDetails.financialDetails.accountName
                }
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>BSB</Typography>
              <TextField
                id="managementCompanyDetails.financialDetails.accountBsb"
                value={
                  values.managementCompanyDetails.financialDetails.accountBsb
                }
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>Account Number</Typography>
              <TextField
                id="managementCompanyDetails.financialDetails.accountNumber"
                value={
                  values.managementCompanyDetails.financialDetails.accountNumber
                }
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>SWIFT Code</Typography>
              <TextField
                id="managementCompanyDetails.financialDetails.accountSwiftCode"
                value={
                  values.managementCompanyDetails.financialDetails
                    .accountSwiftCode
                }
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
                <OutlinedBtn
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                    color: "#000",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(1)}
                >
                  Back
                </OutlinedBtn>
                <BlackActionContainedButton
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    ml: 1,
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => handleSubmit()}
                >
                  Save
                </BlackActionContainedButton>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

export default BankDetailsForm;
