import React, { useEffect, useState } from "react";
import {
	Avatar,
	Box,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { CameraAlt, ModeEditOutlineOutlined } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { GET_USER_DETAILS, UPDATE_PROFILE } from "graphql/users";
import { StorageConstants } from "utils/storage";
import PhoneNumberInput from "common/PhoneNumberInput";
import { useClientSettingsTabs } from "hooks/useClientSettingsTabs";
import { NewGreenPrimaryContainedButton } from "common/Buttons";
import { useStore } from "utils/store";
import ProfilePhotoCircularCropper from "common/ImageCropper/ProfilePhotoCircularCropper";
import { IPhoneNumber } from "models/common";

import { SettingsStyles as styles } from "../styles";
import { CountryCodeArr } from "utils/constants";

interface IProfile {
	id: string;
	fullName: string;
	mobile: IPhoneNumber;
	email: string;
	profile_picture: any;
}

const profileInitialValues = {
	id: "",
	fullName: "",
	mobile: { countryCode: "61", nationalNumber: null, rawInput: "" },
	email: "",
	profile_picture: null,
};

const Profile = () => {
	const { setLoading } = useStore();
	const settingsTab = useClientSettingsTabs();

	const [userDetails, setUserDetails] =
		useState<IProfile>(profileInitialValues);
	const [openImageCropUploader, setOpenImageCropUploader] = useState(false);
	const [profilePictureHovered, setProfilePictureHovered] = useState(false);

	const { loading, refetch } = useQuery(GET_USER_DETAILS, {
		variables: {
			id: sessionStorage.getItem(StorageConstants.LOGGED_IN_USER_ID),
		},
		onCompleted: (data) => {
			setLoading(false);
			const { user } = data;
			const { company, mobile, ...restDetails } = user;
			let parsedMobile = "";
			if (mobile.length === 10) {
				const tempStr = mobile.slice(1);
				const areaCode = tempStr.substring(0, 3);
				const middle = tempStr.substring(3, 6);
				const last = tempStr.substring(6, 9);
				parsedMobile = `${areaCode} ${middle} ${last}`;
			} else {
				parsedMobile = mobile;
			}

			if (user?.profile && Boolean(user?.profile)) {
				const tempJSON = JSON.parse(user?.profile);
				if (Boolean(tempJSON?.about)) {
					setUserDetails({
						...restDetails,
						mobile: {
							countryCode: mobile?.countryCode || "61",
							nationalNumber: mobile?.nationalNumber || null,
							rawInput: mobile?.rawInput || "",
						},
						profile_picture: user?.profilePicture || null,
					});
				} else {
					setUserDetails({
						...restDetails,
						mobile: {
							countryCode: mobile?.countryCode || "61",
							nationalNumber: mobile?.nationalNumber || null,
							rawInput: mobile?.rawInput || "",
						},
						profile_picture: user?.profilePicture || null,
					});
				}
			} else {
				setUserDetails({
					...restDetails,
					mobile: {
						countryCode: mobile?.countryCode || "61",
						nationalNumber: mobile?.nationalNumber || null,
						rawInput: mobile?.rawInput || "",
					},
					profile_picture: user?.profilePicture || null,
				});
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const [fireUpdateUserDetailsApi, { loading: updating }] = useMutation(
		UPDATE_PROFILE,
		{
			onCompleted: (data) => {
				setLoading(false);
				const { updateProfile } = data;
				const { status, message } = updateProfile;
				if (status) {
					toast.success(message, { delay: 10 });
					refetch();
				} else {
					toast.error(message, { delay: 10 });
				}
			},
		}
	);

	const handleUpdateProfile = (values: IProfile) => {
		const { id, fullName, mobile, profile_picture } = values;
		fireUpdateUserDetailsApi({
			variables: {
				payload: {
					id,
					fullName,
					mobile: `${
						CountryCodeArr.find((x) => x.id === mobile.countryCode)?.name
					} ${mobile?.nationalNumber}`,
					...(profile_picture === null && { removeProfilePhoto: true }),
				},
				...(Boolean(profile_picture) &&
					typeof profile_picture !== "string" && {
						file: profile_picture,
					}),
			},
		});
	};

	useEffect(() => {
		setLoading(loading || updating);
	}, [loading, setLoading, updating]);

	useEffect(() => {
		settingsTab.setSelectedTab(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Formik
			initialValues={userDetails}
			validationSchema={Yup.object().shape({
				fullName: Yup.string().required("Please enter your full name"),
				mobile: Yup.object().shape({
					nationalNumber: Yup.string().required(
						"Please enter your contact number"
					),
				}),
			})}
			onSubmit={handleUpdateProfile}
			enableReinitialize={true}
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleBlur,
				errors,
				touched,
				isValid,
				handleSubmit,
			}) => (
				<Form onSubmit={handleSubmit}>
					<Grid
						container
						spacing={2}
						sx={{ px: { xs: 0, sm: 10, md: 20, lg: 30 } }}
					>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Box
								sx={{
									display: { xs: "none", md: "flex" },
									justifyContent: "center",
									position: "relative",
								}}
								onClick={() => setOpenImageCropUploader(true)}
							>
								<Box
									component="div"
									onMouseEnter={() => {
										if (!profilePictureHovered) {
											setProfilePictureHovered(true);
										}
									}}
								>
									{values?.profile_picture ? (
										<Avatar
											sx={{
												height: 100,
												width: 100,
												cursor: "pointer",
											}}
											src={
												typeof values?.profile_picture === "string"
													? values?.profile_picture
													: URL.createObjectURL(
															values?.profile_picture
													  )
											}
										/>
									) : (
										<Avatar
											sx={{
												height: 100,
												width: 100,
												cursor: "pointer",
											}}
										/>
									)}
								</Box>
								{profilePictureHovered && (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											position: "absolute",
											top: 0,
										}}
									>
										<Box
											component="div"
											onMouseLeave={() => {
												if (profilePictureHovered) {
													setProfilePictureHovered(false);
												}
											}}
										>
											<Avatar
												sx={{
													height: 100,
													width: 100,
													backgroundColor: "#000000",
													cursor: "pointer",
													opacity: 0.4,
												}}
											>
												<CameraAlt
													color="secondary"
													sx={{ opacity: 1 }}
												/>
											</Avatar>
										</Box>
									</Box>
								)}
							</Box>
							<Box
								sx={{
									display: { xs: "flex", md: "none" },
									justifyContent: "center",
									position: "relative",
								}}
							>
								{values?.profile_picture ? (
									<Avatar
										sx={{
											height: 100,
											width: 100,
											cursor: "pointer",
										}}
										src={
											typeof values?.profile_picture === "string"
												? values?.profile_picture
												: URL.createObjectURL(
														values?.profile_picture
												  )
										}
									/>
								) : (
									<Avatar
										sx={{
											height: 100,
											width: 100,
											cursor: "pointer",
										}}
									/>
								)}
								<Box sx={{ position: "absolute", bottom: -10 }}>
									<IconButton
										size="small"
										sx={{
											backgroundColor: "#fff",
											border: "1px #e6e6e6 solid",
											height: 25,
											width: 25,
										}}
										onClick={() => setOpenImageCropUploader(true)}
									>
										<ModeEditOutlineOutlined sx={{ fontSize: 15 }} />
									</IconButton>
								</Box>
							</Box>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>Name</Typography>
							<TextField
								id="fullName"
								value={values.fullName}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
							{touched?.fullName && errors?.fullName && (
								<Typography sx={styles.error_text}>
									{errors.fullName}
								</Typography>
							)}
						</Grid>
						{/* <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.ROLE}
              </Typography>
              <TextField
                id="designation"
                value={values.designation}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.designation && errors?.designation && (
                <Typography sx={styles.error_text}>
                  {errors.designation}
                </Typography>
              )}
            </Grid> */}
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<Typography sx={styles.field_label}>Email</Typography>
							<TextField
								id="email"
								value={values.email}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
								disabled
							/>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<Typography sx={styles.field_label}>Mobile</Typography>
							<PhoneNumberInput
								value={
									values.mobile.nationalNumber !== null
										? values.mobile.nationalNumber
										: Boolean(values.mobile.rawInput)
										? values.mobile.rawInput.replaceAll(" ", "")
										: ""
								}
								onChange={(e) => {
									setFieldValue(
										"mobile.nationalNumber",
										e.target.value.toString()
									);
								}}
								onBlur={handleBlur("mobile.nationalNumber")}
								backgroundColor="#e6e6e640"
								countryCode={values.mobile.countryCode}
								setCountryCode={(code: string) =>
									setFieldValue("mobile.countryCode", code)
								}
							/>
							{touched?.mobile?.nationalNumber &&
								errors?.mobile?.nationalNumber && (
									<Typography sx={styles.error_text}>
										{errors.mobile?.nationalNumber}
									</Typography>
								)}
						</Grid>
						{/* <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.COMP_NAME}
              </Typography>
              <TextField
                id="company.name"
                value={values.company?.name}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.name && errors?.company?.name && (
                <Typography sx={styles.error_text}>
                  {errors.company?.name}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.COMP_SITE}
              </Typography>
              <TextField
                id="company.website"
                value={values.company?.website}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.website && errors?.company?.website && (
                <Typography sx={styles.error_text}>
                  {errors.company?.website}
                </Typography>
              )}
            </Grid> */}
						{/* <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.NO_OF_EMP}
              </Typography>
              <TextField
                id="company.noOfEmployees"
                value={values.company?.noOfEmployees}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.noOfEmployees &&
                errors?.company?.noOfEmployees && (
                  <Typography sx={styles.error_text}>
                    {errors.company?.noOfEmployees}
                  </Typography>
                )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.EMPS_MANAGING_CNCT}
              </Typography>
              <TextField
                id="company.employeeManagingContracts"
                value={values.company?.employeeManagingContracts}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.employeeManagingContracts &&
                errors?.company?.employeeManagingContracts && (
                  <Typography sx={styles.error_text}>
                    {errors.company?.employeeManagingContracts}
                  </Typography>
                )}
            </Grid> */}
						{/* <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.INDUSTRY}
              </Typography>
              <TextField
                id="company.industry"
                value={values.company?.industry}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.industry && errors?.company?.industry && (
                <Typography sx={styles.error_text}>
                  {errors.company?.industry}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.ABN_CODE}
              </Typography>
              <TextField
                id="company.abnCode"
                value={values.company?.abnCode}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.company?.abnCode && errors?.company?.abnCode && (
                <Typography sx={styles.error_text}>
                  {errors.company?.abnCode}
                </Typography>
              )}
            </Grid> */}
						{/* <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.ABOUT}
              </Typography>
              <TextField
                id="about"
                value={values.about}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                multiline
                minRows={4}
                inputProps={{ style: styles.input_field }}
              />
              {touched?.about && errors?.about && (
                <Typography sx={styles.error_text}>{errors.about}</Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.LANGUAGE}
              </Typography>
              <TextField
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.WEEK_START_ON}
              </Typography>
              <TextField
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.TIME_FORMAT}
              </Typography>
              <RadioGroup row>
                <RadioButtonWithLabel
                  value="12"
                  label={ClientSettingsLabels.HOURS12}
                  labelStyle={styles.radio_btn_label}
                  size="small"
                />
                <RadioButtonWithLabel
                  value="24"
                  label={ClientSettingsLabels.HOURS24}
                  labelStyle={styles.radio_btn_label}
                  size="small"
                />
              </RadioGroup>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                {ClientSettingsLabels.PROFILE_FIELDS.DATE_FORMAT}
              </Typography>
              <RadioGroup row>
                <RadioButtonWithLabel
                  value="ddmmyy"
                  label={ClientSettingsLabels.DDMMYYY}
                  labelStyle={styles.radio_btn_label}
                  size="small"
                />
                <RadioButtonWithLabel
                  value="mmddyy"
                  label={ClientSettingsLabels.MMDDYY}
                  labelStyle={styles.radio_btn_label}
                  size="small"
                />
                <RadioButtonWithLabel
                  value="yymmdd"
                  label={ClientSettingsLabels.YYMMDD}
                  labelStyle={styles.radio_btn_label}
                  size="small"
                />
              </RadioGroup>
            </Grid> */}
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							display="flex"
							justifyContent="flex-end"
						>
							<NewGreenPrimaryContainedButton
								type="submit"
								size="small"
								variant="contained"
								disableElevation
								sx={{
									fontWeight: 600,
									fontSize: "13px",
									width: "120px",
									borderRadius: "8px",
								}}
							>
								Save
							</NewGreenPrimaryContainedButton>
						</Grid>
						{openImageCropUploader && (
							<ProfilePhotoCircularCropper
								open={openImageCropUploader}
								setOpen={setOpenImageCropUploader}
								width={100}
								imgSrc={values?.profile_picture}
								setImgSrc={(file: any) =>
									setFieldValue("profile_picture", file)
								}
							/>
						)}
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default Profile;
