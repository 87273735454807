import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import { NewGreenPrimaryContainedButton } from "common/Buttons";
import { images } from "assets/images";

import { SetPwdSuccessStyles as styles } from "./styles";

const SuccessAcknowledgement = () => {
	const navigate = useNavigate();

	return (
		<Box
			sx={[
				styles.content_center,
				{
					width: { xs: "100%", sm: "75%", md: "48%" },
					py: { xs: 4, md: 0 },
					px: { xs: 4, sm: 8, md: 0 },
					backgroundColor: { xs: "#fff", md: "none" },
					borderRadius: { xs: "15px", md: 0 },
					mx: { xs: 4, md: 0 },
				},
			]}
		>
			<Grid container spacing={3}>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Box sx={styles.content_center}>
						<img src={images.LOGO} alt="logo" height="30px" />
					</Box>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Box sx={styles.content_center}>
						<CheckCircle sx={{ fontSize: 60, color: "#41B19970" }} />
					</Box>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Typography sx={[styles.success_text, { textAlign: "center" }]}>
						Password Reset Successful
					</Typography>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2 }}>
					<Typography sx={styles.congrats_text}>
						You can now login with your new password.
					</Typography>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<NewGreenPrimaryContainedButton
						disableElevation
						variant="contained"
						size="small"
						sx={styles.login_btn}
						onClick={() => navigate("/")}
					>
						Log in
					</NewGreenPrimaryContainedButton>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SuccessAcknowledgement;
