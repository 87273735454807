import { colors } from "theme/colors";

export const IssueDashboardStyles = {
  metric_card: {
    boxShadow: "1px 1px 8px #d3d3d3",
    borderRadius: "8px",
  },
  card_header: {
    px: 3,
    py: 2,
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  header_text: { color: colors.secondary, fontSize: "18px", fontWeight: 600 },
  metric_text: { fontWeight: 600 },
  metric_value_text: { fontSize: "14px", color: colors.grey },
  countdown_text: { ml: 1 },
  timeline_container: {
    pl: 2,
    pb: 4,
    position: "relative",
    ml: 2,
  },
  timeline_dot: {
    position: "absolute",
    left: -13,
    top: -5,
  },
  timeline_status_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.primaryBlack,
  },
  timeline_status_desc_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: colors.primaryBlack,
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
  timeline_time_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: "#5B5C60",
  },
  upload_file_container: {
    border: `3px dotted ${colors.borderColor}`,
    borderRadius: "4px",
    py: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  uploaded_file_container: {
    border: `1px solid ${colors.borderColor}`,
    py: 1.5,
    px: 2,
    borderRadius: "4px",
  },
};
