import { colors } from "theme/colors";

export const IndexItemsStyles = {
  flex_Acenter: {
    display: "flex",
    alignItems: "center",
  },
  index_title_text: {
    fontSize: "20px",
    color: colors.primaryBlack,
    fontWeight: 500,
  },
  cell_text: {
    color: colors.secondaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  chip_variant: { fontWeight: 500, fontSize: "13px" },
  actions_text: {
    color: colors.secondaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  filters_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    px: 2.5,
    py: 3.5,
  },
  text_input: { fontSize: "14px", color: colors.primaryBlack },
  flex_JCfe: { display: "flex", justifyContent: "flex-end" },
  table: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      backgroundColor: `${colors.primary}10`,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: colors.primaryBlack,
      fontSize: "16px",
      fontWeight: 500,
    },
  },
  wrap: { width: "100%", wordBreak: "break-word" },
  structure: {
    border: `1px solid #D9D9D9`,
    borderRadius: "12px",
  },
  header_container: {
    backgroundColor: "#F9F9F9",
    px: 3,
    height: "64px",
    borderBottom: `1px solid #D9D9D9`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  content_container: { height: { xs: "auto", md: "650px" }, overflow: "auto" },
  contract_container: { borderBottom: `1px solid #D9D9D9`, px: 2, py: 0.5 },
  upload_file_container: {
    border: `3px dotted ${colors.borderColor}`,
    borderRadius: "4px",
    py: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  uploaded_file_container: {
    border: `1px solid ${colors.borderColor}`,
    py: 1.5,
    px: 2,
    borderRadius: "4px",
  },
  dropdown_item: { fontSize: "14px", color: colors.primaryBlack },
  custom_chip: { px: 2, py: 1, borderRadius: "20px", cursor: "pointer" },
  chip_text: { fontSize: "13px" },
  menu_container: {
    "& .MuiMenu-paper": { border: `1px solid ${colors.borderColor}` },
  },
  field_label: {
    color: colors.grey,
    fontSize: "14px",
    fontWeight: 500,
  },
};
