import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import moment from "moment";
import { useQuery } from "@apollo/client";

import { ContractDocxLabels } from "common/AppConstants";
import { GET_PROJ_DEETS_FEE_CALCULATOR } from "graphql/contracts";
import { ProjectTypeArr } from "utils/constants";
import { useStore } from "utils/store";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { ContractDocxStyles as styles } from "./styles";
import { IContract } from "..";

interface IProjectDetails {
  id: string;
  name: string;
  projectType: string;
}

interface IProps {
  setActionsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  contractData: IContract | null;
  refetch: any;
}

function ContractInfo(props: IProps) {
  const { setActionsDrawerOpen, contractData } = props;

  const params = useParams();
  const { setLoading } = useStore();

  // const [editCnctModal, setEditCnctModal] = useState(false);
  const [projectDetails, setProjectDetails] = useState<IProjectDetails | null>(
    null
  );

  const { loading } = useQuery(GET_PROJ_DEETS_FEE_CALCULATOR, {
    variables: { id: params?.project_id },
    skip: !params?.project_id,
    onCompleted: (data) => {
      // setLoading(false);
      const { project } = data;
      const { status, project: rawProject } = project;
      if (status) {
        setProjectDetails({ ...rawProject });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={3} sx={styles.drawer_container}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_JCsb_Acenter}>
          <Typography sx={styles.drawer_header_text}>
            {ContractDocxLabels.CNCT_INFO}
          </Typography>
          <IconButton size="small" onClick={() => setActionsDrawerOpen(false)}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={[styles.flex_Acenter, styles.mt2]}>
          <img src={images.TEMPLATE_GRID_ICON} alt="template" height="80px" />
          <Box sx={styles.ml1}>
            <Typography
              sx={[
                styles.template_name_text,
                { fontSize: "20px", fontWeight: 580 },
              ]}
            >
              {contractData?.name}
            </Typography>
            <Typography
              sx={[
                styles.template_name_text,
                { color: colors.primary2, fontSize: "14px" },
              ]}
            >
              {`${projectDetails?.name} (${
                ProjectTypeArr.find((x) => x.id === projectDetails?.projectType)
                  ?.name
              })`}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={5} md={5} sm={5} xs={5}>
        <Typography
          sx={[styles.cnct_info_label_text, styles.text_wrap, styles.mt2]}
        >
          Party Name
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} xs={7}>
        <Typography
          sx={[styles.cnct_info_value_text, styles.text_wrap, styles.mt2]}
        >
          {Boolean(contractData?.contact)
            ? contractData?.contact?.fullName
            : ""}
        </Typography>
      </Grid>
      <Grid item lg={5} md={5} sm={5} xs={5}>
        <Typography
          sx={[styles.cnct_info_label_text, styles.text_wrap, styles.mt2]}
        >
          Template Name
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} xs={7}>
        <Typography
          sx={[styles.cnct_info_value_text, styles.text_wrap, styles.mt2]}
        >
          {Boolean(contractData?.template) ? contractData?.template?.name : ""}
        </Typography>
      </Grid>
      <Grid item lg={5} md={5} sm={5} xs={5}>
        <Typography
          sx={[styles.cnct_info_label_text, styles.text_wrap, styles.mt2]}
        >
          Template Type
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} xs={7}>
        <Typography
          sx={[styles.cnct_info_value_text, styles.text_wrap, styles.mt2]}
        >
          {Boolean(contractData?.template)
            ? Boolean(contractData?.template?.templateType)
              ? contractData?.template?.templateType?.name
              : ""
            : ""}
        </Typography>
      </Grid>
      <Grid item lg={5} md={5} sm={5} xs={5}>
        <Typography
          sx={[styles.cnct_info_label_text, styles.text_wrap, styles.mt2]}
        >
          {ContractDocxLabels.CNCT_INFO_FIELDS.CREATED_DATE}
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} xs={7}>
        <Typography
          sx={[styles.cnct_info_value_text, styles.text_wrap, styles.mt2]}
        >
          {Boolean(contractData?.createdDate)
            ? moment(contractData?.createdDate).format("DD MMMM YYYY")
            : ""}
        </Typography>
      </Grid>
      <Grid item lg={5} md={5} sm={5} xs={5}>
        <Typography
          sx={[styles.cnct_info_label_text, styles.text_wrap, styles.mt2]}
        >
          {ContractDocxLabels.CNCT_INFO_FIELDS.MODIFIED_DATE}
        </Typography>
      </Grid>
      <Grid item lg={7} md={7} sm={7} xs={7}>
        <Typography
          sx={[styles.cnct_info_value_text, styles.text_wrap, styles.mt2]}
        >
          {Boolean(contractData?.createdDate)
            ? moment(contractData?.createdDate).format("DD MMMM YYYY")
            : ""}
        </Typography>
      </Grid>
      {/* <Grid item lg={12} md={12} sm={12} xs={12}>
        <Button
          variant="contained"
          sx={styles.edit_cnct_btn}
          onClick={() => setEditCnctModal(true)}
        >
          {ContractDocxLabels.EDIT_CNCT_BTN_TEXT}
        </Button>
      </Grid>
      {editCnctModal && (
        <EditCnctModal
          open={editCnctModal}
          setOpen={setEditCnctModal}
          contractData={contractData}
          refetch={refetch}
        />
      )} */}
    </Grid>
  );
}

export default ContractInfo;
