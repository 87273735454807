import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from "@mui/material";
import { DeleteOutline, ModeEditOutline, MoreVert } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { IContact } from "models/contacts";
import { SHARE_CONTACT_LINK } from "graphql/contacts";
import { useStore } from "utils/store";
import { images } from "assets/images";
import { colors } from "theme/colors";
import { getPermissions } from "permissions/utils";

interface IContactCardProps {
  contact: IContact;
  setSelectedContactId: Dispatch<SetStateAction<string>>;
  setEditContactModal: Dispatch<SetStateAction<boolean>>;
  setDeleteContactModal: Dispatch<SetStateAction<boolean>>;
  setContactInfoModal: Dispatch<SetStateAction<boolean>>;
  setSelectedContactName: Dispatch<SetStateAction<string>>;
}

function ContactCard(props: IContactCardProps) {
  const {
    contact,
    setSelectedContactId,
    setContactInfoModal,
    setEditContactModal,
    setDeleteContactModal,
    setSelectedContactName,
  } = props;

  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const contactsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CONTACTS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [actionsMenuAnchor, setActionsMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleActionsMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActionsMenuAnchor(e.currentTarget);
    setOpenActionsMenu(true);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchor(null);
    setOpenActionsMenu(false);
  };

  const projectRole = useMemo(() => {
    const profileJSON = Boolean(contact?.profile)
      ? JSON.parse(contact?.profile)
      : null;
    if (Boolean(profileJSON)) {
      return profileJSON?.project_role || "TBA";
    }
    return "TBA";
  }, [contact?.profile]);

  const [fireShareContactLinkApi, { loading }] = useMutation(
    SHARE_CONTACT_LINK,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { shareContactForm } = data;
        const { status, message } = shareContactForm;
        handleActionsMenuClose();
        if (status) {
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Box
      sx={{
        my: 1,
        display: "flex",
        justifyContent: { xs: "center", md: "flex-start" },
      }}
    >
      <Box
        component="div"
        sx={{
          cursor: "pointer",
          height: 210,
          width: 168,
          aspectRatio: 0.8 / 1,
          backgroundColor: "#B6BCC320",
          borderRadius: "15px",
          p: 2,
          position: "relative",
          ":hover": {
            backgroundColor: `${colors.primaryBlack}15`,
          },
        }}
        onClick={() => {
          setSelectedContactId(contact.id);
          setContactInfoModal(true);
        }}
      >
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {contact?.profile_picture ? (
                <Avatar
                  sx={{ height: 70, width: 70 }}
                  src={contact?.profile_picture}
                />
              ) : (
                <Avatar sx={{ height: 70, width: 70 }} />
              )}
            </Box>
            {Boolean(ROLE_PERMISSIONS) &&
              contactsEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[contactsEditIndex]
                ?.CONTACTS_EDIT && (
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleActionsMenuOpen(e);
                    }}
                  >
                    <MoreVert fontSize="small" sx={{ color: "#00000055" }} />
                  </IconButton>
                </Box>
              )}
            <Menu
              anchorEl={actionsMenuAnchor}
              open={openActionsMenu}
              onClose={handleActionsMenuClose}
              elevation={1}
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <MenuList disablePadding>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    fireShareContactLinkApi({
                      variables: { contactId: contact.id },
                    });
                  }}
                >
                  <ListItemIcon>
                    <img src={images.SHARE_ICON} alt="share" width={18} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                    >
                      Share
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedContactId(contact.id);
                    setEditContactModal(true);
                    handleActionsMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <ModeEditOutline
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                    >
                      Edit
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedContactId(contact.id);
                    setSelectedContactName(contact.fullName);
                    setDeleteContactModal(true);
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutline
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                    >
                      Delete
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Menu>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 1.5 }}>
            <Tooltip title={contact.fullName}>
              <Typography
                noWrap
                textAlign="center"
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: colors.primaryBlack,
                }}
              >
                {contact.fullName}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Chip
              label={contact?.contactDepartment?.name || "TBA"}
              sx={{
                fontSize: "12px",
                backgroundColor: "#333333",
                color: colors.secondary,
                fontWeight: 700,
                width: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1.5 }}>
          <Tooltip title={projectRole}>
            <Typography
              noWrap
              textAlign="center"
              sx={{
                fontSize: "13px",
                color: colors.primaryBlack,
              }}
            >
              {projectRole}
            </Typography>
          </Tooltip>
        </Grid>
      </Box>
    </Box>
  );
}

export default ContactCard;
