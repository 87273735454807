import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Close, MoreVert } from "@mui/icons-material";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import type { Identifier, XYCoord } from "dnd-core";

import { ContractDocxLabels, GlobalLabels } from "common/AppConstants";
import { useTemplates } from "hooks/useTemplates";
import { useStore } from "utils/store";

import { ContractDocxStyles as styles } from "./styles";
import { IFillableFieldsT, ITagsT } from "..";

interface ITagProps {
  item: ITagsT;
  index: number;
  setTagsModal: Dispatch<SetStateAction<boolean>>;
  setEditTagData: Dispatch<SetStateAction<ITagsT | null>>;
  selectedT: string;
  moveT: any;
}

const tagColors = ["#FEFEC8", "#D3FED3", "#FFDCDC", "#DDD9FF", "#E7E7E7"];

const TagItem = forwardRef((props: ITagProps, ref: any) => {
  const { item, index, setTagsModal, setEditTagData, selectedT, moveT } = props;

  const { tags, setTags } = useTemplates();
  const { loading } = useStore();

  const [openActions, setOpenActions] = useState(false);
  const [actionsAnchor, setActionsAnchor] = useState<HTMLElement | null>(null);

  const handleActionsOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenActions(true);
    setActionsAnchor(e.currentTarget);
  };

  const handleActionsClose = () => {
    setOpenActions(false);
    setActionsAnchor(null);
  };

  useEffect(() => {
    const element = ref.current.dom.get(item.id!);
    if (Boolean(element)) {
      if (selectedT === item.id!) {
        element.style.backgroundColor = tagColors[index % 5];
        element.style.padding = "5px";
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else {
        element.style.backgroundColor = "transparent";
        element.style.padding = "0px";
      }
    }
  }, [index, item.id, ref, selectedT]);

  const ref1 = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    any,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "tag",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref1.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref1.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveT(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: "tag",
    item: () => {
      return { id: item.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref1));

  if (loading) {
    return null;
  }

  return (
    <Grid container ref={ref1} data-handler-id={handlerId}>
      <Grid item lg={10} md={10} sm={10} xs={10}>
        <Typography sx={[styles.field_label, { fontSize: "14px" }]} noWrap>
          {item.tagName}
        </Typography>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        sm={2}
        xs={2}
        display="flex"
        justifyContent="flex-end"
      >
        <IconButton
          size="small"
          aria-controls={openActions ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openActions ? "true" : undefined}
          onClick={handleActionsOpen}
        >
          <MoreVert fontSize="small" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={actionsAnchor}
          open={openActions}
          onClose={handleActionsClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={styles.actions_menu}
          elevation={0}
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setEditTagData(item);
              setTagsModal(true);
              handleActionsClose();
            }}
            sx={styles.actions_text}
          >
            {GlobalLabels.EDIT}
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              let tempTags = [...tags];
              tempTags.splice(index, 1);
              setTags([...tempTags]);
              handleActionsClose();
            }}
            sx={styles.actions_text}
          >
            {GlobalLabels.DELETE}
          </MenuItem>
        </Menu>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
        <Typography sx={[styles.field_label, { fontSize: "11px" }]}>
          {`(${item.tagDescription})`}
        </Typography>
      </Grid>
    </Grid>
  );
});

interface IFillableFieldItem {
  item: IFillableFieldsT;
  index: number;
  setFillableFieldModal: Dispatch<SetStateAction<boolean>>;
  setEditFillableFieldData: Dispatch<SetStateAction<IFillableFieldsT | null>>;
  selectedFF: string;
  moveFF: any;
}

const FillableFieldItem = forwardRef((props: IFillableFieldItem, ref: any) => {
  const {
    item,
    index,
    setFillableFieldModal,
    setEditFillableFieldData,
    selectedFF,
    moveFF,
  } = props;

  const { setFillableFields, fillableFields } = useTemplates();
  const { loading } = useStore();

  const [openActions, setOpenActions] = useState(false);
  const [actionsAnchor, setActionsAnchor] = useState<HTMLElement | null>(null);

  const handleActionsOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenActions(true);
    setActionsAnchor(e.currentTarget);
  };

  const handleActionsClose = () => {
    setOpenActions(false);
    setActionsAnchor(null);
  };

  useEffect(() => {
    const element = ref.current.dom.get(item.id!);
    if (Boolean(element)) {
      if (selectedFF === item.id!) {
        element.style.backgroundColor = `#FFFF00`;
        element.style.padding = "5px";
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else {
        element.style.backgroundColor = "transparent";
        element.style.padding = "0px";
      }
    }
  }, [item.id, ref, selectedFF]);

  const ref1 = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    any,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "fill-field",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref1.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref1.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveFF(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: "fill-field",
    item: () => {
      return { id: item.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref1));

  if (loading) return null;

  return (
    <Grid container ref={ref1} data-handler-id={handlerId}>
      <Grid item lg={10} md={10} sm={10} xs={10}>
        <Typography sx={[styles.field_label, { fontSize: "14px" }]} noWrap>
          {item.fieldName}
        </Typography>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        sm={2}
        xs={2}
        display="flex"
        justifyContent="flex-end"
      >
        <IconButton
          size="small"
          aria-controls={openActions ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openActions ? "true" : undefined}
          onClick={handleActionsOpen}
        >
          <MoreVert fontSize="small" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={actionsAnchor}
          open={openActions}
          onClose={handleActionsClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={styles.actions_menu}
          elevation={0}
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setEditFillableFieldData(item);
              setFillableFieldModal(true);
              handleActionsClose();
            }}
            sx={styles.actions_text}
          >
            {GlobalLabels.EDIT}
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              let tempFields = [...fillableFields];
              tempFields.splice(index, 1);
              setFillableFields([...tempFields]);
              ref.current.dom.remove(item.id);
              handleActionsClose();
            }}
            sx={styles.actions_text}
          >
            {GlobalLabels.DELETE}
          </MenuItem>
        </Menu>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
        <Typography sx={[styles.field_label, { fontSize: "11px" }]}>
          {`(${item.fieldDescription})`}
        </Typography>
      </Grid>
    </Grid>
  );
});

interface IProps {
  setFieldsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setFillableFieldModal: Dispatch<SetStateAction<boolean>>;
  setEditFillableFieldData: Dispatch<SetStateAction<IFillableFieldsT | null>>;
  setTagsModal: Dispatch<SetStateAction<boolean>>;
  setEditTagData: Dispatch<SetStateAction<ITagsT | null>>;
  feeCalculatorAdded: boolean;
  setFeeCalculatorAdded: Dispatch<SetStateAction<boolean>>;
}

const FillableFields = forwardRef((props: IProps, ref: any) => {
  const {
    setFieldsDrawerOpen,
    setFillableFieldModal,
    setEditFillableFieldData,
    setTagsModal,
    setEditTagData,
    feeCalculatorAdded,
    setFeeCalculatorAdded,
  } = props;

  const { fillableFields, setFillableFields, tags, setTags } = useTemplates();

  const [selectedFF, setSelectedFF] = useState("");
  const [selectedT, setSelectedT] = useState("");
  const [openActions, setOpenActions] = useState(false);
  const [actionsAnchor, setActionsAnchor] = useState<HTMLElement | null>(null);

  const handleActionsOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenActions(true);
    setActionsAnchor(e.currentTarget);
  };

  const handleActionsClose = () => {
    setOpenActions(false);
    setActionsAnchor(null);
  };

  const moveFF = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const tempFillableFields = [...fillableFields]; // Create a shallow copy of prevCards

      // Remove the dragged card from its original position and store it
      const draggedCard = tempFillableFields.splice(dragIndex, 1)[0];

      // Insert the dragged card at the hoverIndex
      tempFillableFields.splice(hoverIndex, 0, draggedCard);

      setFillableFields([...tempFillableFields]);
    },
    [fillableFields, setFillableFields]
  );

  const moveT = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const tempTags = [...tags]; // Create a shallow copy of prevCards

      // Remove the dragged card from its original position and store it
      const draggedCard = tempTags.splice(dragIndex, 1)[0];

      // Insert the dragged card at the hoverIndex
      tempTags.splice(hoverIndex, 0, draggedCard);

      setTags([...tempTags]);
    },
    [tags, setTags]
  );

  return (
    <Grid container spacing={2} sx={styles.drawer_container}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography sx={styles.drawer_header_text}>
          {ContractDocxLabels.FILLABLE_FIELDS}
        </Typography>
        <IconButton size="small" onClick={() => setFieldsDrawerOpen(false)}>
          <Close fontSize="small" />
        </IconButton>
      </Grid>
      {feeCalculatorAdded && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={[
                styles.field_label,
                { fontSize: "16px", mr: 1, fontWeight: 600 },
              ]}
            >
              Fee Calculator
            </Typography>
            <IconButton size="small" onClick={handleActionsOpen}>
              <MoreVert fontSize="small" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={actionsAnchor}
              open={openActions}
              onClose={handleActionsClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={styles.actions_menu}
              elevation={0}
            >
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setFeeCalculatorAdded(false);
                  handleActionsClose();
                }}
                sx={styles.actions_text}
              >
                {GlobalLabels.DELETE}
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      )}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_Acenter}>
          <Typography
            sx={[
              styles.field_label,
              { fontSize: "16px", mr: 1, fontWeight: 600 },
            ]}
          >
            {ContractDocxLabels.OVERALL_FIELDS}
          </Typography>
          <Chip sx={styles.chip} label={fillableFields.length} size="small" />
        </Box>
      </Grid>
      <DndProvider backend={HTML5Backend}>
        {fillableFields.map((item, index) => (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            key={item.id}
            sx={{ cursor: "pointer" }}
            component="div"
            onClick={() => {
              if (selectedFF === item.id) {
                setSelectedFF("");
              } else {
                setSelectedFF(item.id!);
              }
            }}
          >
            <FillableFieldItem
              item={item}
              index={index}
              setFillableFieldModal={setFillableFieldModal}
              setEditFillableFieldData={setEditFillableFieldData}
              selectedFF={selectedFF}
              ref={ref}
              moveFF={moveFF}
            />
          </Grid>
        ))}
      </DndProvider>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_Acenter}>
          <Typography
            sx={[
              styles.field_label,
              { fontSize: "16px", mr: 1, fontWeight: 600 },
            ]}
          >
            {ContractDocxLabels.OVERALL_TAGS}
          </Typography>
          <Chip sx={styles.chip} label={tags.length} size="small" />
        </Box>
      </Grid>
      <DndProvider backend={HTML5Backend}>
        {tags.map((item, index) => (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            key={item.id}
            sx={{ cursor: "pointer" }}
            component="div"
            onClick={() => {
              if (selectedT === item.id) {
                setSelectedT("");
              } else {
                setSelectedT(item.id!);
              }
            }}
          >
            <TagItem
              index={index}
              item={item}
              setTagsModal={setTagsModal}
              setEditTagData={setEditTagData}
              selectedT={selectedT}
              ref={ref}
              moveT={moveT}
            />
          </Grid>
        ))}
      </DndProvider>
    </Grid>
  );
});

export default FillableFields;
