import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Box,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
	menuItemClasses,
} from "@mui/material";
import {
	Add,
	ArrowDropDown,
	FiberManualRecord,
	InfoOutlined,
	Link,
} from "@mui/icons-material";
import {
	GridColDef,
	GridRenderCellParams,
	GridRowClassNameParams,
	GridRowParams,
} from "@mui/x-data-grid";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";

import Table from "common/Table";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import {
	GET_ALL_CHECKLIST_ITEMS,
	GET_CHECKLIST_DETAILS,
	GET_CHECKLIST_GRAPHS,
	GET_CHECKLIST_ITEM_NAME,
	GET_DUE_WEEK_ARR,
	UPDATE_CHECKLIST_ITEM,
} from "graphql/checklist";
import { GET_USERS_ARR } from "graphql/users";
import { useStore } from "utils/store";
import { IChecklist, IChecklistItem } from "models/checklists";
import { BlackTextButton } from "common/Buttons";
import { IArr } from "models/common";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

// import DeleteChecklistItemModal from "./DeleteModal";
import Header from "./Header";
import HeaderXs from "./HeaderXs";
import Metrices from "./Metrices";
import ProjectDetailsTabs from "..";
import ChecklistItemFolderDetails from "./ChecklistItemFolderDetails";
import ChecklistItemDetails from "./ChecklistItemDetails";
import AddChecklistFolderModal from "./AddChecklistFolderModal";
import AddChecklistItemModal from "./AddChecklistItemModal";
import LinkDRModal from "./LinkDRModal";
import { AssignToComponent, WeekDueComponent } from "./TableMenuComponents";
import { ProjectDetailsStyles as styles } from "../styles";
import "./styles.css";

export interface ISelectedChecklistItem {
	id: string;
	isFolder: boolean;
}

export interface IWeekDueArr {
	id: string;
	date: string;
}

export function DeliveryChecklistItem() {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const projectDetailsTab = useProjectDetailsTabs();
	const { setLoading } = useStore();
	const boxRef = useRef(null);
	const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
	const deliveryViewIndex = useMemo(
		() =>
			ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
				x.hasOwnProperty("DELIVERY_VIEW")
			),
		[ROLE_PERMISSIONS]
	);
	const deliveryEditIndex = useMemo(
		() =>
			ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
				x.hasOwnProperty("DELIVERY_EDIT")
			),
		[ROLE_PERMISSIONS]
	);
	const foldersViewIndex = useMemo(
		() =>
			ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
				x.hasOwnProperty("FOLDERS_VIEW")
			),
		[ROLE_PERMISSIONS]
	);

	const [checklistDetails, setChecklistDetails] = useState<IChecklist | null>(
		null
	);
	const [breadCrumbArr, setBreadCrumbArr] = useState<IArr[]>([]);
	const [nestedIdArr, setNestedIdArr] = useState<string[]>([]);
	const [counter, setCounter] = useState(1);
	const [addChecklistItemModal, setAddChecklistItemModal] = useState(false);
	const [addChecklistFolderModal, setAddChecklistFolderModal] =
		useState(false);
	const [linkDRModal, setLinkDRModal] = useState(false);
	const [checklistItems, setChecklistItems] = useState<IChecklistItem[]>([]);
	const [checklistItemCount, setChecklistItemCount] = useState(0);
	const [selectedChecklistItem, setSelectedChecklistItem] =
		useState<ISelectedChecklistItem | null>(null);
	// const [selectedChecklistItemName, setSelectedChecklistItemName] =
	//   useState("");
	// const [deleteModal, setDeleteModal] = useState(false);
	const [openButtonMenu, setOpenButtonMenu] = useState(false);
	const [buttonMenuAnchor, setButtonMenuAnchor] = useState<HTMLElement | null>(
		null
	);
	const [barGraphData, setBarGraphData] = useState<any>(null);
	const [selectedLinkDRItems, setSelectedLinkDRItems] = useState<any>([]);
	const [weekDueArr, setWeekDueArr] = useState<IWeekDueArr[]>([]);
	const [usersArr, setUsersArr] = useState<IArr[]>([]);
	const [openWeekDueMenu, setOpenWeekDueMenu] = useState(false);
	const [weekDueMenuAnchor, setWeekDueMenuAnchor] =
		useState<HTMLElement | null>(null);
	const [openAssignToMenu, setOpenAssignToMenu] = useState(false);
	const [assignToMenuAnchor, setAssignToMenuAnchor] =
		useState<HTMLElement | null>(null);
	const [weekDueFilter, setWeekDueFilter] = useState("");
	const [assignedToFilter, setAssignedToFilter] = useState("");

	const handleAssignToMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
		setAssignToMenuAnchor(e.currentTarget);
		setOpenAssignToMenu(true);
	};

	const handleAssignToMenuClose = () => {
		setAssignToMenuAnchor(null);
		setOpenAssignToMenu(false);
	};

	const handleWeekDueMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
		setWeekDueMenuAnchor(e.currentTarget);
		setOpenWeekDueMenu(true);
	};

	const handleWeekDueMenuClose = () => {
		setWeekDueMenuAnchor(null);
		setOpenWeekDueMenu(false);
	};

	const handleButtonMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
		setButtonMenuAnchor(e.currentTarget);
		setOpenButtonMenu(true);
	};

	const handleButtonMenuClose = () => {
		setButtonMenuAnchor(null);
		setOpenButtonMenu(false);
	};

	const { loading: gettingTitle } = useQuery(GET_CHECKLIST_DETAILS, {
		variables: { project_id: params?.project_id, id: params?.checklist_id },
		skip:
			!params?.project_id ||
			!params?.checklist_id ||
			!(
				Boolean(ROLE_PERMISSIONS) &&
				deliveryViewIndex !== undefined &&
				ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
			),
		onCompleted: (data) => {
			const { getChecklist } = data;
			const { status, checklist } = getChecklist;
			if (status) {
				setChecklistDetails(checklist);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const {} = useQuery(GET_DUE_WEEK_ARR, {
		variables: { id: params?.project_id },
		skip:
			!params?.project_id ||
			!(
				Boolean(ROLE_PERMISSIONS) &&
				deliveryViewIndex !== undefined &&
				ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
			),
		onCompleted: (data) => {
			const { getProjectWeeks } = data;
			const { status, dates } = getProjectWeeks;
			if (status) {
				setWeekDueArr(dates);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const {} = useQuery(GET_USERS_ARR, {
		variables: {
			filter: { projects__project__id__in: params?.project_id },
		},
		skip:
			!params.project_id ||
			!(
				Boolean(ROLE_PERMISSIONS) &&
				deliveryViewIndex !== undefined &&
				ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
			),
		onCompleted: (data) => {
			const { users } = data;
			const { count, users: rawUsers } = users;
			if (count > 0) {
				setUsersArr(
					rawUsers.map((x: any) => ({
						id: x.id,
						name: x.fullName,
						email: x.email,
					}))
				);
			} else {
				setUsersArr([]);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const { loading, refetch, fetchMore } = useQuery(GET_ALL_CHECKLIST_ITEMS, {
		variables: {
			project_id: params?.project_id,
			checklist_id: params?.checklist_id,
			filter: {
				parent_id:
					nestedIdArr.length > 0
						? nestedIdArr[nestedIdArr.length - 1]
						: null,
				...(Boolean(weekDueFilter) && {
					week_due: parseInt(weekDueFilter),
				}),
				...(Boolean(assignedToFilter) && {
					assigned_to_id: assignedToFilter,
				}),
			},
			page: 1,
			limit: 100,
		},
		skip:
			!params?.project_id ||
			!params?.checklist_id ||
			!(
				Boolean(ROLE_PERMISSIONS) &&
				deliveryViewIndex !== undefined &&
				ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
			),
		onCompleted: (data) => {
			const { getAllChecklistItems } = data;
			const { status, checklistItem, count } = getAllChecklistItems;
			if (status) {
				setChecklistItemCount(count);
				setChecklistItems(checklistItem);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const [getNestedFolderName, { loading: gettingNestedTitle }] = useLazyQuery(
		GET_CHECKLIST_ITEM_NAME,
		{
			onCompleted: (data) => {
				setLoading(false);
				const { getChecklistItem } = data;
				const { status, checklistItem } = getChecklistItem;
				if (status) {
					const { id, name } = checklistItem;
					const tempArr = [...breadCrumbArr];
					tempArr.push({ id, name });
					setBreadCrumbArr([...tempArr]);
					if (nestedIdArr.length > 1 && counter < nestedIdArr.length) {
						getNestedFolderName({
							variables: {
								id: nestedIdArr[counter],
								project_id: params?.project_id,
								checklist_id: params?.checklist_id,
							},
						});
					}
					setCounter(counter + 1);
				}
			},
			fetchPolicy: "network-only",
			nextFetchPolicy: "network-only",
		}
	);

	const { loading: gettingBarChart, refetch: refetchGraph } = useQuery(
		GET_CHECKLIST_GRAPHS,
		{
			variables: {
				id: "ALL",
				project_id: params?.project_id,
				checklist_id: params?.checklist_id,
			},
			skip:
				!params?.project_id ||
				!params?.checklist_id ||
				!(
					Boolean(ROLE_PERMISSIONS) &&
					deliveryViewIndex !== undefined &&
					ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]
						?.DELIVERY_VIEW
				),
			onCompleted: (data) => {
				const { getChecklistItemStatusGraph } = data;
				const { status, graph } = getChecklistItemStatusGraph;
				if (status) {
					const graphJSON = Boolean(graph) ? JSON.parse(graph) : null;
					setBarGraphData(graphJSON);
				}
			},
			fetchPolicy: "network-only",
			nextFetchPolicy: "network-only",
		}
	);

	const [fireUpdateChecklistItemApi, { loading: updating }] = useMutation(
		UPDATE_CHECKLIST_ITEM,
		{
			onCompleted: (data) => {
				const { updateChecklistItem } = data;
				const { status, message } = updateChecklistItem;
				if (status) {
					refetch();
					toast.success(message, { delay: 10 });
				} else {
					toast.error(message, { delay: 10 });
				}
			},
		}
	);

	const columns: GridColDef[] = useMemo(
		() => [
			{
				field: "id",
				renderHeader: () =>
					Boolean(ROLE_PERMISSIONS) &&
					deliveryEditIndex !== undefined &&
					ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
						?.DELIVERY_EDIT ? (
						<BlackTextButton
							size="small"
							endIcon={
								<Add
									fontSize="small"
									sx={{ color: colors.primaryBlack }}
								/>
							}
							sx={{ fontWeight: 600 }}
							onClick={handleButtonMenuOpen}
						>
							New
						</BlackTextButton>
					) : null,
				sortable: false,
				align: "center",
				width: 105,
				renderCell: (params1: GridRenderCellParams<any>) => {
					if (params1.row.isFolder) {
						return (
							<img
								src={images.FOLDER_NEW}
								alt="folder"
								style={{ height: 20 }}
							/>
						);
					} else if (
						params1?.row?.documents &&
						params1?.row?.documents?.length > 0
					) {
						if (params1?.row?.documents.length === 1) {
							if (params1?.row?.documents[0].type === "REGISTER") {
								return (
									<img
										src={images.DOC_LINKED}
										alt="folder"
										style={{ width: 19 }}
									/>
								);
							}
							return (
								<img
									src={images.CONTRACT_ICON_NEW}
									alt="folder"
									style={{ width: 20 }}
								/>
							);
						}
						return (
							<img
								src={images.DOC_LINKED}
								alt="folder"
								style={{ width: 19 }}
							/>
						);
					} else {
						return (
							<img
								src={images.NEW_DOC_NEW}
								alt="folder"
								style={{ width: 18 }}
							/>
						);
					}
				},
			},
			{
				field: "levelNo",
				headerName: "Item Id",
				headerAlign: "center",
				sortable: false,
				align: "center",
				width: 150,
				renderCell: (params1: GridRenderCellParams<any>) => (
					<Typography
						sx={[
							styles.cell_text,
							{ fontWeight: 600, color: `${colors.primaryBlack}70` },
						]}
					>
						{params1?.value}
					</Typography>
				),
			},
			{
				field: "name",
				headerName: "Name",
				sortable: false,
				flex: 1,
				minWidth: 150,
				renderCell: (params1: GridRenderCellParams<any>) => (
					<Box sx={[styles.flex_JCsb_Acenter, { width: "100%" }]}>
						<Typography
							sx={[
								styles.cell_text,
								{
									width:
										Boolean(ROLE_PERMISSIONS) &&
										deliveryEditIndex !== undefined &&
										ROLE_PERMISSIONS[0]?.permissions[
											deliveryEditIndex
										]?.DELIVERY_EDIT
											? "90%"
											: "100%",
									wordBreak: "break-word",
								},
							]}
						>
							{params1?.value}
						</Typography>
						{!params1?.row?.isFolder ? (
							!params1?.row?.documents ||
							(params1?.row?.documents &&
								params1?.row?.documents?.length === 0) ? (
								Boolean(ROLE_PERMISSIONS) &&
								deliveryEditIndex !== undefined &&
								ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
									?.DELIVERY_EDIT ? (
									<Box>
										<Tooltip
											title={
												Boolean(ROLE_PERMISSIONS) &&
												foldersViewIndex !== undefined &&
												ROLE_PERMISSIONS[0]?.permissions[
													foldersViewIndex
												]?.FOLDERS_VIEW
													? "Attach files/folders"
													: "Not permitted"
											}
										>
											<span>
												<IconButton
													size="small"
													onClick={(e) => {
														e.stopPropagation();
														setSelectedChecklistItem({
															id: params1?.row?.id,
															isFolder: params1?.row?.isFolder,
														});
														setLinkDRModal(true);
													}}
													disabled={
														!(
															Boolean(ROLE_PERMISSIONS) &&
															foldersViewIndex !== undefined &&
															ROLE_PERMISSIONS[0]?.permissions[
																foldersViewIndex
															]?.FOLDERS_VIEW
														)
													}
												>
													<Link
														fontSize="small"
														sx={{
															color: `${colors.primaryBlack}70`,
														}}
													/>
												</IconButton>
											</span>
										</Tooltip>
									</Box>
								) : (
									<></>
								)
							) : (
								<></>
							)
						) : (
							<Tooltip title="View Details">
								<span>
									<IconButton
										size="small"
										onClick={(e) => {
											e.stopPropagation();
											setSelectedChecklistItem({
												id: params1?.row?.id,
												isFolder: params1?.row?.isFolder,
											});
										}}
									>
										<InfoOutlined
											fontSize="small"
											sx={{ color: `${colors.primaryBlack}70` }}
										/>
									</IconButton>
								</span>
							</Tooltip>
						)}
					</Box>
				),
			},
			{
				field: "pending",
				renderHeader: () =>
					Boolean(selectedChecklistItem) ? (
						<Tooltip title="Pending">
							<FiberManualRecord
								sx={{ fontSize: 15, color: "#FFA027" }}
							/>
						</Tooltip>
					) : (
						<Typography
							sx={{
								color: `${colors.primaryBlack}70`,
								fontSize: "13px",
								fontWeight: 600,
								// textTransform: "uppercase",
							}}
						>
							Pending
						</Typography>
					),
				headerAlign: "center",
				sortable: false,
				width: Boolean(selectedChecklistItem) ? 70 : 135,
				align: "center",
				renderCell: (params1: GridRenderCellParams<any>) =>
					params1?.row?.isFolder ? (
						<Typography
							sx={[
								styles.cell_text,
								{ color: "#FFA027", fontWeight: 600 },
							]}
						>
							{params1?.value}
						</Typography>
					) : (
						<></>
					),
			},
			{
				field: "completed",
				renderHeader: () =>
					Boolean(selectedChecklistItem) ? (
						<Tooltip title="Completed">
							<FiberManualRecord
								sx={{ fontSize: 15, color: "#073577" }}
							/>
						</Tooltip>
					) : (
						<Typography
							sx={{
								color: `${colors.primaryBlack}70`,
								fontSize: "13px",
								fontWeight: 600,
								// textTransform: "uppercase",
							}}
						>
							Completed
						</Typography>
					),
				headerAlign: "center",
				sortable: false,
				width: Boolean(selectedChecklistItem) ? 70 : 135,
				align: "center",
				renderCell: (params1: GridRenderCellParams<any>) =>
					params1?.row?.isFolder ? (
						<Typography
							sx={[
								styles.cell_text,
								{ color: "#073577", fontWeight: 600 },
							]}
						>
							{params1?.value}
						</Typography>
					) : (
						<></>
					),
			},
			{
				field: "rejected",
				renderHeader: () =>
					Boolean(selectedChecklistItem) ? (
						<Tooltip title="Rejected">
							<FiberManualRecord
								sx={{ fontSize: 15, color: "#E3323B" }}
							/>
						</Tooltip>
					) : (
						<Typography
							sx={{
								color: `${colors.primaryBlack}70`,
								fontSize: "13px",
								fontWeight: 600,
								// textTransform: "uppercase",
							}}
						>
							Rejected
						</Typography>
					),
				headerAlign: "center",
				sortable: false,
				width: Boolean(selectedChecklistItem) ? 70 : 135,
				align: "center",
				renderCell: (params1: GridRenderCellParams<any>) =>
					params1?.row?.isFolder ? (
						<Typography
							sx={[
								styles.cell_text,
								{ color: "#E3323B", fontWeight: 600 },
							]}
						>
							{params1?.value || "0"}
						</Typography>
					) : (
						<></>
					),
			},
			{
				field: "weekDue",
				headerName: "Due",
				renderHeader: () => (
					<Box sx={styles.flex_Acenter}>
						<Typography
							sx={{
								color: `${colors.primaryBlack}70`,
								fontSize: "13px",
								fontWeight: 600,
								// textTransform: "uppercase",
							}}
						>
							Due
						</Typography>
						<IconButton
							sx={{ height: 20, width: 20, ml: 0.5 }}
							onClick={(e) => {
								e.stopPropagation();
								handleWeekDueMenuOpen(e);
							}}
						>
							<ArrowDropDown fontSize="small" />
						</IconButton>
						<Menu
							anchorEl={weekDueMenuAnchor}
							open={openWeekDueMenu}
							onClose={handleWeekDueMenuClose}
							elevation={0}
							sx={{
								"& .MuiMenu-paper": {
									mt: 1,
									border: "1px #00000030 solid",
									borderRadius: "15px",
									maxHeight: "400px",
									minWidth: "200px",
								},
							}}
						>
							{weekDueArr.length === 0 && (
								<MenuItem disabled>
									<Typography sx={[styles.wrap, { fontSize: "13px" }]}>
										Please specify the post production dates
									</Typography>
								</MenuItem>
							)}
							{weekDueArr.map((week) => (
								<MenuItem
									key={week.id}
									onClick={() => {
										if (weekDueFilter === week.id) {
											setWeekDueFilter("");
										} else {
											setWeekDueFilter(week.id);
										}
										handleWeekDueMenuClose();
									}}
									selected={week.id === weekDueFilter}
									sx={{
										display: "flex",
										alignItems: "center",
										[`&.${menuItemClasses.selected}`]: {
											backgroundColor: "#41B19931",
											"&:hover": { backgroundColor: "#41B19995" },
										},
										"&:hover": { backgroundColor: "#B6BCC330" },
									}}
								>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
											width: "100%",
										}}
									>
										<Typography
											sx={{
												fontSize: "14px",
												color: colors.primaryBlack,
												ml: 1,
												fontWeight: 600,
											}}
											textAlign="center"
										>
											{`Week ${week.id}`}
										</Typography>
										<Typography
											sx={{
												fontSize: "13px",
												color: `${colors.primaryBlack}80`,
												ml: 1,
											}}
										>{`Starting ${moment(week.date).format(
											"DD MMMM"
										)}`}</Typography>
									</Box>
								</MenuItem>
							))}
						</Menu>
					</Box>
				),
				headerAlign: "center",
				sortable: false,
				width: Boolean(selectedChecklistItem) ? 130 : 145,
				align: "center",
				renderCell: (params1: GridRenderCellParams<any>) => (
					<WeekDueComponent
						params={params}
						params1={params1}
						selectedChecklistItem={selectedChecklistItem}
						fireUpdateChecklistItemApi={fireUpdateChecklistItemApi}
						weekDueArr={weekDueArr}
					/>
				),
			},
			{
				field: "assignedTo",
				headerName: "Assignee",
				renderHeader: () => (
					<Box sx={styles.flex_Acenter}>
						<Typography
							sx={{
								color: `${colors.primaryBlack}70`,
								fontSize: "13px",
								fontWeight: 600,
								// textTransform: "uppercase",
							}}
						>
							Assignee
						</Typography>
						<IconButton
							sx={{ height: 20, width: 20, ml: 0.5 }}
							onClick={(e) => {
								e.stopPropagation();
								handleAssignToMenuOpen(e);
							}}
						>
							<ArrowDropDown fontSize="small" />
						</IconButton>
						<Menu
							anchorEl={assignToMenuAnchor}
							open={openAssignToMenu}
							onClose={handleAssignToMenuClose}
							elevation={0}
							sx={{
								"& .MuiMenu-paper": {
									mt: 1,
									border: "1px #00000030 solid",
									borderRadius: "15px",
									maxHeight: "400px",
									minWidth: "200px",
								},
							}}
						>
							{usersArr.length === 0 && (
								<MenuItem disabled>
									<Typography sx={[styles.wrap, { fontSize: "13px" }]}>
										Please assign this project to a user
									</Typography>
								</MenuItem>
							)}
							{usersArr.map((user) => (
								<MenuItem
									key={user.id}
									onClick={() => {
										if (assignedToFilter === user.id) {
											setAssignedToFilter("");
										} else {
											setAssignedToFilter(user.id);
										}
										handleAssignToMenuClose();
									}}
									selected={user.id === assignedToFilter}
									sx={{
										display: "flex",
										alignItems: "center",
										[`&.${menuItemClasses.selected}`]: {
											backgroundColor: "#41B19931",
											"&:hover": { backgroundColor: "#41B19995" },
										},
										"&:hover": { backgroundColor: "#B6BCC330" },
									}}
								>
									<Box sx={{ width: "100%" }}>
										<Typography
											sx={{
												fontSize: "14px",
												color: colors.primaryBlack,
												ml: 1,
											}}
											textAlign="center"
										>
											{user.name}
										</Typography>
									</Box>
								</MenuItem>
							))}
						</Menu>
					</Box>
				),
				headerAlign: "center",
				sortable: false,
				minWidth: 230,
				flex: 1,
				align: "center",
				renderCell: (params1: GridRenderCellParams<any>) => (
					<AssignToComponent
						params={params}
						params1={params1}
						fireUpdateChecklistItemApi={fireUpdateChecklistItemApi}
						usersArr={usersArr}
					/>
				),
			},
		],
		[
			ROLE_PERMISSIONS,
			assignToMenuAnchor,
			assignedToFilter,
			deliveryEditIndex,
			fireUpdateChecklistItemApi,
			foldersViewIndex,
			openAssignToMenu,
			openWeekDueMenu,
			params,
			selectedChecklistItem,
			usersArr,
			weekDueArr,
			weekDueFilter,
			weekDueMenuAnchor,
		]
	);

	useEffect(() => {
		setNestedIdArr([]);
		setBreadCrumbArr([]);
		setSelectedChecklistItem(null);
		setCounter(1);
		const fullString = location?.pathname;
		const substring = `delivery-checklist/${params.checklist_id}`;
		const position = fullString.indexOf(substring);
		let checklistItemIds = fullString.substring(position + substring.length);
		checklistItemIds = checklistItemIds.slice(1);
		setNestedIdArr(
			Boolean(checklistItemIds) ? checklistItemIds.split("/") : []
		);
	}, [location?.pathname, params.checklist_id]);

	useEffect(() => {
		if (
			nestedIdArr.length > 0 &&
			counter === 1 &&
			Boolean(ROLE_PERMISSIONS) &&
			deliveryViewIndex !== undefined &&
			ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
		) {
			getNestedFolderName({
				variables: {
					id: nestedIdArr[0],
					project_id: params?.project_id,
					checklist_id: params?.checklist_id,
				},
			});
		}
	}, [
		ROLE_PERMISSIONS,
		counter,
		deliveryViewIndex,
		getNestedFolderName,
		nestedIdArr,
		params?.checklist_id,
		params?.project_id,
	]);

	// useEffect(() => {
	//   const handleScroll = async () => {
	//     if (
	//       window.innerHeight + document.documentElement.scrollTop !==
	//       document.documentElement.offsetHeight
	//     ) {
	//       return;
	//     }
	//     if (checklistItems.length < checklistItemCount) {
	//       try {
	//         setLoading(true);
	//         await fetchMore({
	//           variables: {
	//             page: Math.ceil(checklistItems.length / 100) + 1,
	//             limit: 100,
	//           },
	//           updateQuery: (previousResult, { fetchMoreResult }) => {
	//             const newEntries =
	//               fetchMoreResult.getAllChecklistItems.checklistItem;
	//             return {
	//               getAllChecklistItems: {
	//                 ...fetchMoreResult?.getAllChecklistItems,
	//                 checklistItem: [...checklistItems, ...newEntries],
	//               },
	//             };
	//           },
	//         });
	//       } catch (error) {
	//         console.error("ERROR", error);
	//       } finally {
	//         setLoading(false);
	//       }
	//     }
	//   };

	//   window.addEventListener("scroll", handleScroll);
	//   return () => window.removeEventListener("scroll", handleScroll);
	// }, [checklistItemCount, checklistItems, fetchMore, setLoading]);
	useEffect(() => {
		const handleScroll = async () => {
			// Check if the user has scrolled to the bottom of the page with a tolerance
			const box = boxRef.current;
			const isAtBottom =
				(box as any).scrollHeight - (box as any).scrollTop <=
				(box as any).clientHeight + 100;

			if (!isAtBottom) {
				// If not at the bottom, return early
				return;
			}

			if (checklistItems.length < checklistItemCount) {
				try {
					setLoading(true);
					await fetchMore({
						variables: {
							page: Math.ceil(checklistItems.length / 100) + 1,
							limit: 100,
						},
						updateQuery: (previousResult, { fetchMoreResult }) => {
							const newEntries =
								fetchMoreResult.getAllChecklistItems.checklistItem;
							return {
								getAllChecklistItems: {
									...fetchMoreResult?.getAllChecklistItems,
									checklistItem: [...checklistItems, ...newEntries],
								},
							};
						},
					});
				} catch (error) {
					console.error("ERROR", error);
				} finally {
					setLoading(false);
				}
			}
		};

		// Add scroll event listener
		const box = boxRef.current;
		if (box) {
			(box as any).addEventListener("scroll", handleScroll);
		}

		// Clean up event listener on component unmount
		return () => {
			if (box) {
				(box as any).removeEventListener("scroll", handleScroll);
			}
		};
	}, [fetchMore, setLoading, checklistItems, checklistItemCount]);

	useEffect(() => {
		setLoading(
			gettingBarChart ||
				gettingNestedTitle ||
				gettingTitle ||
				loading ||
				updating
		);
	}, [
		gettingBarChart,
		gettingNestedTitle,
		gettingTitle,
		loading,
		setLoading,
		updating,
	]);

	useEffect(() => {
		projectDetailsTab.setSelectedTab(4);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ProjectDetailsTabs>
			<Grid container spacing={3} alignItems="center">
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ display: { xs: "flex", sm: "none" } }}
				>
					<HeaderXs
						checklistDetails={checklistDetails}
						breadCrumbArr={breadCrumbArr}
						barGraphData={barGraphData}
					/>
				</Grid>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ display: { xs: "none", sm: "flex" } }}
				>
					<Header
						checklistDetails={checklistDetails}
						breadCrumbArr={breadCrumbArr}
					/>
				</Grid>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ display: { xs: "none", sm: "block" } }}
				>
					<Box sx={{ pl: 4.75 }}>
						<Metrices
							checklistDetails={checklistDetails}
							barGraphData={barGraphData}
						/>
					</Box>
				</Grid>
				<Grid item lg={12} md={12} sm={12} xs={12}>
					<Box sx={{ mx: -3, mb: -3, mt: -1 }}>
						<Grid container>
							<Grid
								item
								lg={Boolean(selectedChecklistItem) ? 7 : 12}
								md={Boolean(selectedChecklistItem) ? 7 : 12}
								sm={Boolean(selectedChecklistItem) ? 7 : 12}
								xs={12}
							>
								<Box
									ref={boxRef}
									sx={{
										height: "calc(100vh - 250px)",
										overflow: "auto",
										position: "relative",
									}}
								>
									<Table
										rows={checklistItems}
										columns={columns}
										sx={{
											...styles.table,
											"& .MuiDataGrid-columnHeaders": {
												border: "none",
												backgroundColor: "#B6BCC320",
											},
											"& .MuiDataGrid-row": {
												borderBottom: "1px #e6e6e6 solid",
												cursor: "pointer",
												borderRadius: 0,
											},
											borderTop: "1px #e6e6e6 solid",
											borderRight: "1px #e6e6e6 solid",
											borderRadius: 0,
										}}
										hideFooter
										showCellVerticalBorder={false}
										showColumnVerticalBorder={false}
										disableColumnMenu
										onRowClick={(
											params1: GridRowParams,
											event: any,
											__
										) => {
											event.preventDefault();
											if (params1?.row?.isFolder) {
												event.preventDefault();
												navigate(
													`${location?.pathname}/${params1?.row?.id}`
												);
											} else {
												setSelectedChecklistItem({
													id: params1?.row?.id,
													isFolder: params1?.row?.isFolder,
												});
											}
										}}
										onRowDoubleClick={(
											params1: GridRowParams,
											event
										) => {
											if (params1?.row?.isFolder) {
												event.preventDefault();
												navigate(
													`${location?.pathname}/${params1?.row?.id}`
												);
											}
										}}
										getRowClassName={(
											params: GridRowClassNameParams<any>
										) =>
											params.row.id === selectedChecklistItem?.id
												? "selected"
												: "unselected"
										}
									/>
									{/* <Box sx={{ position: "absolute", top: 15, left: 17 }}>
                    <BlackTextButton
                      size="small"
                      endIcon={
                        <Add
                          fontSize="small"
                          sx={{ color: colors.primaryBlack }}
                        />
                      }
                      sx={{ fontWeight: 600 }}
                      onClick={handleButtonMenuOpen}
                    >
                      New
                    </BlackTextButton>
                  </Box> */}
									<Menu
										open={openButtonMenu}
										onClose={handleButtonMenuClose}
										anchorEl={buttonMenuAnchor}
										elevation={0}
										sx={{
											"& .MuiMenu-paper": {
												mt: 1,
												border: "1px #00000030 solid",
												borderRadius: "15px",
											},
										}}
									>
										<MenuItem
											onClick={() => {
												setAddChecklistFolderModal(true);
												handleButtonMenuClose();
											}}
											sx={{
												[`&.${menuItemClasses.selected}`]: {
													backgroundColor: "#41B19931",
													"&:hover": {
														backgroundColor: "#41B19995",
													},
												},
												"&:hover": { backgroundColor: "#B6BCC330" },
											}}
										>
											<Typography
												sx={{
													fontSize: "14px",
													color: colors.secondaryBlack,
												}}
											>
												New Delivery Folder
											</Typography>
										</MenuItem>
										<Divider />
										<MenuItem
											onClick={() => {
												setAddChecklistItemModal(true);
												handleButtonMenuClose();
											}}
											sx={{
												[`&.${menuItemClasses.selected}`]: {
													backgroundColor: "#41B19931",
													"&:hover": {
														backgroundColor: "#41B19995",
													},
												},
												"&:hover": { backgroundColor: "#B6BCC330" },
											}}
										>
											<Typography
												sx={{
													fontSize: "14px",
													color: colors.secondaryBlack,
												}}
											>
												New Delivery Item
											</Typography>
										</MenuItem>
									</Menu>
								</Box>
							</Grid>
							{Boolean(selectedChecklistItem) && (
								<Grid item lg={5} md={5} sm={5} xs={12}>
									<Box
										sx={{
											minHeight: "calc(100vh - 268px)",
											borderTop: "1px #e6e6e6 solid",
										}}
									>
										{/* {!Boolean(selectedChecklistItem) && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "calc(100vh - 268px)",
                      }}
                    >
                      <InfoOutlined
                        sx={{ fontSize: 70, color: `${colors.primaryBlack}20` }}
                      />
                      <Typography
                        sx={{
                          fontSize: "18px",
                          color: `${colors.primaryBlack}20`,
                          textAlign: "center",
                          mt: 1,
                        }}
                      >
                        Select an item to see the details
                      </Typography>
                    </Box>
                  )} */}
										{Boolean(selectedChecklistItem) &&
											selectedChecklistItem?.isFolder && (
												<Box
													sx={{
														height: "calc(100vh - 250px)",
														overflow: "auto",
														backgroundColor: "#B6BCC320",
													}}
												>
													<ChecklistItemFolderDetails
														id={selectedChecklistItem?.id || ""}
														refetch={() => {
															refetch();
															refetchGraph();
														}}
														setSelectedChecklistItem={
															setSelectedChecklistItem
														}
													/>
												</Box>
											)}
										{Boolean(selectedChecklistItem) &&
											!selectedChecklistItem?.isFolder && (
												<Box
													sx={{
														height: "calc(100vh - 250px)",
														overflowY: "auto",
														backgroundColor: "#B6BCC320",
													}}
												>
													<ChecklistItemDetails
														id={selectedChecklistItem?.id || ""}
														setLinkDRModal={setLinkDRModal}
														setSelectedLinkDRItems={
															setSelectedLinkDRItems
														}
														refetch={() => {
															refetch();
															refetchGraph();
														}}
														setSelectedChecklistItem={
															setSelectedChecklistItem
														}
													/>
												</Box>
											)}
									</Box>
								</Grid>
							)}
						</Grid>
					</Box>
				</Grid>
				{addChecklistFolderModal && (
					<AddChecklistFolderModal
						open={addChecklistFolderModal}
						setOpen={setAddChecklistFolderModal}
						refetch={() => {
							refetch();
							refetchGraph();
						}}
						nestedIdArr={nestedIdArr}
						weekDueArr={weekDueArr}
						usersArr={usersArr}
					/>
				)}
				{addChecklistItemModal && (
					<AddChecklistItemModal
						open={addChecklistItemModal}
						setOpen={setAddChecklistItemModal}
						refetch={() => {
							refetch();
							refetchGraph();
						}}
						nestedIdArr={nestedIdArr}
						weekDueArr={weekDueArr}
						usersArr={usersArr}
					/>
				)}
				{linkDRModal && (
					<LinkDRModal
						open={linkDRModal}
						setOpen={setLinkDRModal}
						refetch={() => {
							refetch();
							refetchGraph();
						}}
						id={selectedChecklistItem?.id || ""}
						selectedLinkDRItems={selectedLinkDRItems}
					/>
				)}
			</Grid>
		</ProjectDetailsTabs>
	);
}
