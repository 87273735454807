import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { ContractDocxLabels } from "common/AppConstants";
import { IUser } from "models/user";
import { GET_NOTES } from "graphql/contracts";
import { useStore } from "utils/store";

import AddNotesModal from "./AddNotesModal";
import { ContractDocxStyles as styles } from "./styles";

interface INote {
  id: string;
  comment: string;
  createdDate: string;
  createdBy: IUser;
}

interface IProps {
  setActionsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

function Notes(props: IProps) {
  const { setActionsDrawerOpen } = props;

  const params = useParams();
  const { setLoading } = useStore();

  const [addNotesModal, setAddNotesModal] = useState(false);
  const [notes, setNotes] = useState<INote[]>([]);

  const getDateTitle = (date: any) => {
    const today = moment();
    const newDate = moment(date);
    if (today.isSame(newDate, "date"))
      return `Today, ${moment(date).format("hh:mm a")}`;
    if (today.subtract(1, "day").isSame(newDate, "date"))
      return `Yesterday, ${moment(date).format("hh:mm a")}`;
    return newDate.format("DD MMMM YYYY, hh:mm a");
  };

  const { loading, refetch } = useQuery(GET_NOTES, {
    variables: {
      document_id: params?.contract_id,
      project_id: params?.project_id,
    },
    skip: !Boolean(params?.contract_id) || !Boolean(params?.project_id),
    onCompleted: (data) => {
      const { getDocumentComments } = data;
      const { status, comments } = getDocumentComments;
      if (status) {
        setNotes(comments);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={3} sx={styles.drawer_container}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_JCsb_Acenter}>
          <Typography sx={styles.drawer_header_text}>
            {ContractDocxLabels.NOTES}
          </Typography>
          <IconButton size="small" onClick={() => setActionsDrawerOpen(false)}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Grid>
      {notes.map((item, index) => (
        <Fragment key={item.id}>
          <Grid item lg={2} md={2} sm={3} xs={6}>
            <Avatar sx={styles.timeline_avatar} />
          </Grid>
          <Grid item lg={10} md={10} sm={9} xs={6}>
            <Typography sx={styles.timeline_name_text}>
              {item?.createdBy?.fullName}
            </Typography>
            <Typography sx={styles.timeline_time_text}>
              {getDateTitle(item.createdDate)}
            </Typography>
            <Typography
              sx={[styles.timeline_time_text, styles.overflow_text, { mt: 1 }]}
            >
              {item.comment}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider />
          </Grid>
        </Fragment>
      ))}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Button
          variant="contained"
          sx={styles.edit_cnct_btn}
          onClick={() => setAddNotesModal(true)}
        >
          Add Notes
        </Button>
      </Grid>
      {addNotesModal && (
        <AddNotesModal
          open={addNotesModal}
          setOpen={setAddNotesModal}
          refetch={refetch}
        />
      )}
    </Grid>
  );
}

export default Notes;
