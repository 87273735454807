import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import queryString from "query-string";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import Drawer from "common/ClientDrawer";
import { CONNECT_TO_DOCUSIGN, GET_MY_INTEGRATIONS } from "graphql/integrations";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";

import IntegrationCard from "./IntegrationCard";

export function Integrations() {
  const { setLoading } = useStore();
  const location = useLocation();
  const parseQueryString = queryString.parse(location.search);
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const integrationsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("INTEGRATIONS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [docuSignConnected, setDocuSignConnected] = useState(false);

  const { loading, refetch } = useQuery(GET_MY_INTEGRATIONS, {
    variables: {},
    onCompleted: (data) => {
      const { getMyIntegrations } = data;
      const { status, integrations } = getMyIntegrations;
      if (status) {
        setDocuSignConnected(integrations.docusignIntegrated);
      }
    },
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      integrationsViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[integrationsViewIndex]?.INTEGRATIONS_VIEW
    ),
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [connectToDS, { loading: connecting }] = useMutation(
    CONNECT_TO_DOCUSIGN,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { connectDocusign } = data;
        const { status, message } = connectDocusign;
        if (status) {
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  useEffect(() => {
    if (Boolean(parseQueryString) && Boolean(parseQueryString?.code)) {
      connectToDS({
        variables: { payload: { authorizationCode: parseQueryString?.code } },
      });
    }
  }, [connectToDS, parseQueryString]);

  useEffect(() => {
    setLoading(connecting || loading);
  }, [connecting, loading, setLoading]);

  return (
    <Drawer>
      <Grid container spacing={2} sx={{ mt: 0.25 }}>
        <Grid item>
          <IntegrationCard
            docuSignConnected={docuSignConnected}
            refetch={refetch}
          />
        </Grid>
      </Grid>
    </Drawer>
  );
}
