import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { IModalProps } from "models/common";
import { useStore } from "utils/store";
import { UPSERT_NOTES } from "graphql/contracts";

import { ContractDocxStyles as styles } from "./styles";

interface INotesFields {
  comment: string;
}

interface IAddNotesModalProps extends IModalProps {
  refetch: any;
}

function AddNotesModal(props: IAddNotesModalProps) {
  const { open, setOpen, refetch } = props;

  const params = useParams();
  const { setLoading } = useStore();

  const handleClose = () => setOpen(false);

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireAddNotesApi, { loading }] = useMutation(UPSERT_NOTES, {
    onCompleted: (data) => {
      setLoading(false);
      const { upsertDocumentComment } = data;
      const { status, message } = upsertDocumentComment;
      handleClose();
      if (status) {
        refetch();
        toast.success(message, { delay: 10 });
      } else {
        toast.error(message, { delay: 10 });
      }
    },
  });

  const handleAddNotes = (values: INotesFields) => {
    fireAddNotesApi({
      variables: {
        payload: {
          projectId: params?.project_id,
          fetchDocumentId: params?.contract_id,
          comment: values.comment,
        },
      },
    });
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Formik
      initialValues={{ comment: "" }}
      validationSchema={Yup.object().shape({
        comment: Yup.string().required("Please type in your note"),
      })}
      onSubmit={handleAddNotes}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
          <DialogTitle sx={styles.flex_JCsb_Acenter}>
            <Typography sx={styles.modal_header_text}>Add Notes</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={4} sx={{ p: 3 }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id="comment"
                    value={values.comment}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    multiline
                    minRows={4}
                    placeholder="Type something..."
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.comment && errors?.comment && (
                    <Typography sx={styles.error_text}>
                      {errors?.comment}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions sx={styles.modal_footer_container}>
            <Button
              variant="contained"
              type="submit"
              disabled={!isValid}
              sx={styles.disabled_btn}
              onClick={() => handleSubmit()}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

export default AddNotesModal;
