import React, { useState } from "react";
import { Box, Menu, MenuItem, Typography } from "@mui/material";

import { IArr } from "models/common";
import { colors } from "theme/colors";

interface ITableComponentProps {
  tempTypes: IArr[];
}

function TableComponent(props: ITableComponentProps) {
  const { tempTypes } = props;

  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [actionsMenuAnchor, setActionsMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleActionsMenuOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    setActionsMenuAnchor(e.currentTarget);
    setOpenActionsMenu(true);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchor(null);
    setOpenActionsMenu(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      {tempTypes.map((x, index) => {
        if (index < 3) {
          return (
            <Box
              key={x.id}
              sx={{
                backgroundColor: "#e6e6e6",
                borderRadius: "20px",
                py: 0.5,
                px: 1.5,
                ...(index <= 3 && { mr: 0.5 }),
                my: 0.25,
              }}
            >
              <Typography
                sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}
              >
                {x.name}
              </Typography>
            </Box>
          );
        }

        return null;
      })}
      {tempTypes.length > 3 && (
        <Box
          sx={{ ml: 0.5, cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            handleActionsMenuOpen(e);
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              textDecoration: "underline",
              color: "#00000080",
            }}
          >{`+${tempTypes.length - 3}`}</Typography>
          <Menu
            open={openActionsMenu}
            onClose={handleActionsMenuClose}
            anchorEl={actionsMenuAnchor}
            elevation={0}
            sx={{
              "& .MuiMenu-paper": {
                mt: 1,
                border: "1px #00000030 solid",
                borderRadius: "15px",
              },
            }}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
          >
            {tempTypes.map((x, index) => {
              if (index >= 3) {
                return (
                  <MenuItem disabled key={x.id}>
                    <Typography
                      sx={{ fontSize: "13px", color: colors.secondaryBlack }}
                    >
                      {x.name}
                    </Typography>
                  </MenuItem>
                );
              }
              return null;
            })}
          </Menu>
        </Box>
      )}
    </Box>
  );
}

export default TableComponent;
