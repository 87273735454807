import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { useStore } from "utils/store";
import { IEditProjectFields } from "models/projects";
import { GET_PROJECT_BY_ID, UPDATE_PROJECT } from "graphql/projects";
import { useProjectDetailsMenu } from "hooks/useProjectDetailsMenu";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { getPermissions } from "permissions/utils";

import LeftSideNav from "./LeftSideNav";
import ProjectDetailsForm from "./ProjectDetailsForm";
import KeyDatesForm from "./KeyDatesForm";
import SettingsForm from "./SettingsForm";
import TopNavBar from "../TopNavBar";
import ProjectDetailsTabs, { ProjectDetailsMenuItems } from "..";

export const ProjectDeets = () => {
  const { setLoading } = useStore();
  const projectId = useParams();
  const projectDetailsMenu = useProjectDetailsMenu();
  const projectDetailsTab = useProjectDetailsTabs();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("PROJECT_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [projectStatus, setProjectStatus] = useState(4);
  const [initialValues, setInitialValues] = useState<IEditProjectFields>({
    id: "",
    name: "",
    projectNumber: "",
    description: "",
    episodes: "",
    episodeDuration: "",
    projectType: "",
    projectCategory: "",
    categoryDescription: "",
    categoryLoading: "",
    categoryLoadingInputs: "",
    collectiveAgreementCast: "",
    collectiveAgreementCrew: "",
    projectStartDate: "",
    projectEndDate: "",
    shootDays: "",
    filmingDate: "",
    filmingLocation: "",
    projectMaterials: "",
    projectStatus: "",
    profile_picture: null,
    dateFormat: "",
    currency: "",
    startPreProduction: "",
    startPrincipalPhotography: "",
    startPostProduction: "",
    startRoughCut: "",
    startFineCut: "",
    completionDate: "",
    deliveryDate: "",
    script: null,
    scriptName: "",
  });
  const [selectedLeftNavOpt, setSelectedLeftNavOpt] = useState("1");

  const { loading, refetch } = useQuery(GET_PROJECT_BY_ID, {
    variables: { id: projectId?.project_id },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        projectViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[projectViewIndex]?.PROJECT_VIEW
      ),
    onCompleted: (data) => {
      setLoading(false);
      const { project } = data;
      const { status, project: rawProject } = project;
      if (status) {
        const {
          director,
          producer,
          writer,
          status,
          createdBy,
          company,
          categoryLoadingInputs,
          profilePicture,
          projectDates,
          shootDays,
          filmingDate,
          __typename,
          ...rest
        } = rawProject;
        let parsedCategoryLoadingInputs = "";
        if (categoryLoadingInputs) {
          parsedCategoryLoadingInputs = JSON.parse(categoryLoadingInputs);
        }
        let parsedProjectDates: any = {};
        if (projectDates) {
          parsedProjectDates = JSON.parse(projectDates);
        }
        setProjectStatus(rest.projectStatus);
        setInitialValues({
          ...rest,
          profile_picture: profilePicture || null,
          categoryLoadingInputs: Boolean(parsedCategoryLoadingInputs)
            ? parsedCategoryLoadingInputs.toString()
            : "",
          shootDays: shootDays === 0 ? "" : shootDays,
          filmingDate: filmingDate === null ? "" : filmingDate,
          ...(Boolean(parsedProjectDates)
            ? {
                startPreProduction:
                  parsedProjectDates?.startPreProduction || "",
                startPrincipalPhotography:
                  parsedProjectDates?.startPrincipalPhotography || "",
                startPostProduction:
                  parsedProjectDates?.startPostProduction || "",
                startRoughCut: parsedProjectDates?.startRoughCut || "",
                startFineCut: parsedProjectDates?.startFineCut || "",
                completionDate: parsedProjectDates?.completionDate || "",
                deliveryDate: parsedProjectDates?.deliveryDate || "",
              }
            : {
                startPreProduction: "",
                startPrincipalPhotography: "",
                startPostProduction: "",
                startRoughCut: "",
                startFineCut: "",
                completionDate: "",
                deliveryDate: "",
              }),
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireUpdateProjectApi, { loading: updating }] =
    useMutation(UPDATE_PROJECT);

  const handleUpdateProj = (
    values: IEditProjectFields,
    setEditProjectDetails: any
  ) => {
    const {
      episodes,
      episodeDuration,
      categoryLoading,
      shootDays,
      categoryLoadingInputs,
      projectStartDate,
      filmingDate,
      projectStatus,
      profile_picture,
      startPreProduction,
      startPrincipalPhotography,
      startPostProduction,
      startFineCut,
      startRoughCut,
      completionDate,
      deliveryDate,
      script,
      ...rest
    } = values;
    const projectDatesJSON = {
      startPreProduction,
      startPrincipalPhotography,
      startPostProduction,
      startRoughCut,
      startFineCut,
      completionDate,
      deliveryDate,
    };
    fireUpdateProjectApi({
      variables: {
        payload: {
          ...rest,
          ...(episodes !== "" && { episodes: parseInt(episodes as string) }),
          ...(episodeDuration !== "" && {
            episodeDuration: parseInt(episodeDuration as string),
          }),
          ...(categoryLoading !== "" && {
            categoryLoading: parseFloat(categoryLoading as string),
          }),
          ...(shootDays !== "" && {
            shootDays: parseInt(shootDays as string),
          }),
          ...(filmingDate !== "" && { filmingDate }),
          ...(projectStartDate !== "" && { projectStartDate }),
          categoryLoadingInputs: JSON.stringify(categoryLoadingInputs),
          // projectStatus: parseInt(projectStatus as string),
          projectDates: JSON.stringify(projectDatesJSON),
          ...(profile_picture === null && { removeProfilePhoto: true }),
        },
        ...(Boolean(profile_picture) &&
          typeof profile_picture !== "string" && { file: profile_picture }),
        ...(Boolean(script) &&
          typeof script !== "string" && { script: script }),
      },
      onCompleted: (data) => {
        setLoading(false);
        const { updateProject } = data;
        const { status, message } = updateProject;
        setEditProjectDetails(false);
        if (status) {
          toast.success(message);
          refetch();
        } else {
          toast.error(message);
        }
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [refetch, selectedLeftNavOpt]);

  useEffect(() => {
    setLoading(loading || updating);
  }, [loading, setLoading, updating]);

  useEffect(() => {
    projectDetailsMenu.onMenuItemClick(ProjectDetailsMenuItems[0]);
    projectDetailsTab.setSelectedTab(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <TopNavBar>
        <Grid container display="flex" flexDirection="row">
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
            display={{ xs: "none", md: "block" }}
          >
            <LeftSideNav
              proj={initialValues}
              selectedLeftNavOpt={selectedLeftNavOpt}
              setSelectedLeftNavOpt={setSelectedLeftNavOpt}
              fireUpdateProjectApi={fireUpdateProjectApi}
              refetch={refetch}
            />
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Box
              sx={{
                borderLeft: { xs: "none", md: "2px #e6e6e680 solid" },
                ml: { xs: 0, md: 4 },
                minHeight: "calc(100vh - 250px)",
                pl: { xs: 2, md: 4 },
                pr: { xs: 2, md: 4.5 },
                overflow: "auto",
              }}
            >
              {selectedLeftNavOpt === "1" && (
                <ProjectDetailsForm
                  initialValues={initialValues}
                  projectStatus={projectStatus}
                  setProjectStatus={setProjectStatus}
                  fireUpdateProjectApi={fireUpdateProjectApi}
                  refetch={refetch}
                />
              )}
              {selectedLeftNavOpt === "2" && (
                <KeyDatesForm
                  initialValues={initialValues}
                  projectStatus={projectStatus}
                  setProjectStatus={setProjectStatus}
                  fireUpdateProjectApi={fireUpdateProjectApi}
                  handleUpdateProj={handleUpdateProj}
                  refetch={refetch}
                />
              )}
              {selectedLeftNavOpt === "3" && (
                <SettingsForm
                  initialValues={initialValues}
                  projectStatus={projectStatus}
                  setProjectStatus={setProjectStatus}
                  fireUpdateProjectApi={fireUpdateProjectApi}
                  handleUpdateProj={handleUpdateProj}
                  refetch={refetch}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </TopNavBar>
    </ProjectDetailsTabs>
  );
};
