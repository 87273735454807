import { CountryCodeArr } from "utils/constants";

export const setAgencyDetails = (values: any, setFieldValue: any) => {
	const {
		id,
		// companyCity,
		// companyCountry,
		// companyState,
		agentFinancialDetails,
		spocMobile,
		photo,
		__typename,
		...rest
	} = values;
	setFieldValue("agencyDetails", {
		spocMobile: {
			countryCode: spocMobile?.countryCode || "61",
			nationalNumber: spocMobile?.nationalNumber || null,
			rawInput: spocMobile?.rawInput || "",
		},
		...rest,
		financialDetails: {
			accountName: agentFinancialDetails?.accountName || "",
			accountNumber: agentFinancialDetails?.accountNumber || "",
			accountBsb: agentFinancialDetails?.accountBsb || "",
			accountSwiftCode: agentFinancialDetails?.accountSwiftCode || "",
			bankName: agentFinancialDetails?.bankName || "",
			branchName: agentFinancialDetails?.branchName || "",
			accountInstructions: agentFinancialDetails?.accountInstructions || "",
			abn: agentFinancialDetails?.abn || "",
			superannuationFundName:
				agentFinancialDetails?.superannuationFundName || "",
			superannuationAccountNo:
				agentFinancialDetails?.superannuationAccountNo || "",
			taxFileNo: agentFinancialDetails?.taxFileNo || "",
		},
	});
};
