import React, { Dispatch, SetStateAction } from "react";
import { Avatar, Box, Dialog, Grid, Typography } from "@mui/material";

import { IModalProps } from "models/common";
import { NewOutlinedBtn, NewRedAlertContainedButton } from "common/Buttons";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { IRightBuysTable } from "./RightsBuy";

interface IWarningModalProps extends IModalProps {
  rights: IRightBuysTable[];
  setRights: Dispatch<SetStateAction<IRightBuysTable[]>>;
  initialRights: IRightBuysTable[];
  fireRightsIncludeApi: any;
  projectId: string;
}

const WarningModal = (props: IWarningModalProps) => {
  const {
    open,
    setOpen,
    rights,
    setRights,
    initialRights,
    fireRightsIncludeApi,
    projectId,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    fireRightsIncludeApi({
      variables: {
        payload: rights.map((x) => ({
          projectId: projectId,
          rightId: x.id,
          aggregateInclusion: x.aggregateInclusion,
          ...(Boolean(x.details) && { details: x.details }),
        })),
      },
    });
  };

  const AlertImage = () => (
    <img
      src={images.ALERT_NEW}
      alt="alert"
      height="24px"
      style={{ marginTop: -2 }}
    />
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: "24px", p: 3, width: "400px", height: "250px" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar
                src={images.ALERT_NEW}
                sx={{ backgroundColor: "#F4895F40", height: 45, width: 45 }}
                slots={{ img: AlertImage }}
              />
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 600,
                color: colors.primaryBlack,
                textAlign: "center",
              }}
            >
              Alert!
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              sx={{ fontSize: "13px", textAlign: "center", color: "#00000080" }}
            >
              Making changes to rights buy inclusion will affect the contracts
              created henceforth.
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <NewOutlinedBtn
                  variant="outlined"
                  sx={{
                    color: colors.primaryBlack,
                    fontWeight: 600,
                    fontSize: "13px",
                    borderRadius: "20px",
                    width: "120px",
                  }}
                  onClick={() => {
                    handleClose();
                    setRights(initialRights);
                  }}
                >
                  Cancel
                </NewOutlinedBtn>
              </Box>
              <Box sx={{ ml: 2 }}>
                <NewRedAlertContainedButton
                  sx={{
                    fontWeight: 600,
                    fontSize: "13px",
                    borderRadius: "20px",
                    width: "120px",
                    backgroundColor: "#F4895F40",
                    ":hover": { backgroundColor: "#F4895F70" },
                    color: "#000",
                  }}
                  onClick={handleChange}
                >
                  Update
                </NewRedAlertContainedButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default WarningModal;
