import { gql } from "@apollo/client";

export const GET_CONTACTS = gql`
	query getAllContacts(
		$filter: ContactFilterInput
		$limit: Int
		$page: Int
		$sorted: String
	) {
		getAllContacts(
			filter: $filter
			limit: $limit
			page: $page
			sorted: $sorted
		) {
			status
			message
			count
			contacts {
				id
				fullName
				emailId
				mobile {
					countryCode
					nationalNumber
					rawInput
				}
				contactDepartment {
					id
					name
				}
				createdDate
				assignedProjectsCount
				assignedProject {
					id
					name
				}
				isRestricted
				profile_picture
				profile
			}
		}
	}
`;

export const GET_CONTACT_ARR = gql`
	query getAllContacts($filter: ContactFilterInput, $sorted: String) {
		getAllContacts(filter: $filter, sorted: $sorted) {
			status
			message
			contacts {
				id
				fullName
				emailId
			}
		}
	}
`;

export const CREATE_CONTACT = gql`
	mutation createContact(
		$payload: ContactCreateInput!
		$profile_picture: Upload
		$shareForm: Boolean
	) {
		createContact(
			payload: $payload
			profile_picture: $profile_picture
			shareForm: $shareForm
		) {
			status
			message
		}
	}
`;

export const GET_CONTACT_BASIC_DETAILS = gql`
	query getContactBasicDetails($id: String!) {
		getContactBasicDetails(id: $id) {
			status
			message
			contactDetails {
				id
				firstName
				lastName
				fullName
				contactDepartment {
					id
					name
				}
				emailId
				mobile {
					countryCode
					nationalNumber
					rawInput
				}
				photo
				nationality
				isRestricted
				shareConsent
				profile
				licence
				abn
				wwcNumber
				equityNumber
				notes
				agentFirstName
				agentLastName
				agentEmailId
				agentMobile {
					countryCode
					nationalNumber
					rawInput
				}
				managerFirstName
				managerLastName
				managerEmailId
				managerMobile {
					countryCode
					nationalNumber
					rawInput
				}
				lenderCompanyDetails {
					id
					companyName
					spocName
					spocEmailId
					spocMobile {
						countryCode
						nationalNumber
						rawInput
					}
					website
					unit
					address
					street
					suburb
					companyCity
					companyState
					companyCountry
					pinCode
					abn
					acn
					registrationNumber
					publicLiabilityPolicy
					provider
					policyNumber
					insurancePeriod
					companyFinancialDetails {
						id
						accountName
						accountNumber
						accountBsb
						accountSwiftCode
						bankName
						branchName
						accountInstructions
						abn
						superannuationFundName
						superannuationAccountNo
						taxFileNo
						superannuationData
					}
				}
				agencyDetails {
					id
					companyName
					spocName
					spocEmailId
					spocMobile {
						countryCode
						nationalNumber
						rawInput
					}
					website
					unit
					address
					street
					suburb
					companyCity
					companyState
					companyCountry
					pinCode
					abn
					acn
					registrationNumber
					publicLiabilityPolicy
					provider
					policyNumber
					insurancePeriod
					companyFinancialDetails {
						id
						accountName
						accountNumber
						accountBsb
						accountSwiftCode
						bankName
						branchName
						accountInstructions
						abn
						superannuationFundName
						superannuationAccountNo
						taxFileNo
						superannuationData
					}
				}
				managementCompanyDetails {
					id
					companyName
					spocName
					spocEmailId
					spocMobile {
						countryCode
						nationalNumber
						rawInput
					}
					website
					unit
					address
					street
					suburb
					companyCity
					companyState
					companyCountry
					pinCode
					abn
					acn
					registrationNumber
					publicLiabilityPolicy
					provider
					policyNumber
					insurancePeriod
					companyFinancialDetails {
						id
						accountName
						accountNumber
						accountBsb
						accountSwiftCode
						bankName
						branchName
						accountInstructions
						abn
						superannuationFundName
						superannuationAccountNo
						taxFileNo
						superannuationData
					}
				}
				profilePicture
				unit
				address
				street
				suburb
				contactCity
				contactState
				contactCountry
				pincode
				financialDetails {
					id
					accountName
					accountNumber
					accountBsb
					accountSwiftCode
					bankName
					branchName
					accountInstructions
					abn
					superannuationFundName
					superannuationAccountNo
					taxFileNo
					memberNumber
					metaData
					superannuationData
				}
			}
		}
	}
`;

export const UPDATE_CONTACT_BASIC_DETAILS = gql`
	mutation updateContactBasicDetails(
		$payload: ContatactBasicDetailsUpdateInput!
		$profile_picture: Upload
	) {
		updateContactBasicDetails(
			payload: $payload
			profile_picture: $profile_picture
		) {
			status
			message
		}
	}
`;

export const GET_CONTACT_PROJECT_DETAILS = gql`
	query assignedProjects($id: String!, $filter: ContactProjectsFilterInput) {
		getContactProjects(id: $id, filter: $filter) {
			status
			message
			projects {
				id
				role
				artistType {
					id
					name
				}
				artistClass {
					id
					name
				}
				characterName
				project {
					id
					name
				}
			}
		}
	}
`;

export const UPDATE_CONTACT_PROJECT_DETAILS = gql`
	mutation assignProjectToContact($payload: AssignProjectToContact!) {
		assignProjectsToContact(payload: $payload) {
			status
			message
		}
	}
`;

export const GET_CONTACT_REGISTRATION_DETAILS = gql`
	query getRegistrationDetails($id: String!) {
		getContactRegistrationDetails(id: $id) {
			status
			message
			registrationDetails {
				wwcNumber
				equityNumber
				nationality
				notes
			}
		}
	}
`;

export const UPDATE_CONTACT_REGISTRATION_DETAILS = gql`
	mutation updateContactRegistrationDetails(
		$payload: ContactRegistrationDetailsUpdateInput!
	) {
		updateContactRegistrationDetails(payload: $payload) {
			status
			message
		}
	}
`;

export const GET_CONTACT_FINANCIAL_DETAILS = gql`
	query getFinacialDetails($id: String!) {
		getContactFinacialDetails(id: $id) {
			status
			message
			financialDetails {
				personalBankAccountDetails {
					id
					accountName
					accountNumber
					accountBsb
					accountSwiftCode
					accountInstructions
					bankName
					abn
					superannuationAccountNo
					superannuationFundName
					taxFileNo
				}
				lenderBankAccountDetails {
					id
					accountName
					accountNumber
					accountBsb
					accountSwiftCode
					accountInstructions
					bankName
					abn
					superannuationAccountNo
					superannuationFundName
					taxFileNo
				}
				agencyBankAccountDetails {
					id
					accountName
					accountNumber
					accountBsb
					accountSwiftCode
					accountInstructions
					bankName
					abn
					superannuationAccountNo
					superannuationFundName
					taxFileNo
				}
				managementBankAccountDetails {
					id
					accountName
					accountNumber
					accountBsb
					accountSwiftCode
					accountInstructions
					bankName
					abn
					superannuationAccountNo
					superannuationFundName
					taxFileNo
				}
			}
		}
	}
`;

export const UPDATE_CONTACT_FINANCIAL_DETAILS = gql`
	mutation updateContactFinancialDetails(
		$payload: ContactFinancialDetailsUpdateInput!
	) {
		updateContactFinacialDetails(payload: $payload) {
			status
			message
		}
	}
`;

export const GET_CONTACT_SOCIAL_ACC_DETAILS = gql`
	query getSocialAccountDetails($id: String!) {
		getContactSocialAccountDetails(id: $id) {
			status
			message
			socialAccountDetails {
				profile
			}
		}
	}
`;

export const UPDATE_CONTACT_SOCIAL_ACC_DETAILS = gql`
	mutation updateContactSocialAccountDetails(
		$payload: ContactSocialAccountDetailsUpdateInput!
	) {
		updateContactSocialAccountDetail(payload: $payload) {
			status
			message
		}
	}
`;

export const DELETE_CONTACT = gql`
	mutation deleteContact($id: String!) {
		deleteContact(id: $id) {
			status
			message
		}
	}
`;

export const SHARE_CONTACT_LINK = gql`
	mutation shareContactForm($contactId: String!) {
		shareContactForm(contactId: $contactId) {
			status
			message
		}
	}
`;

export const GET_EVERYONE_PROJECT_REPORT = gql`
	query everyoneReport($filter: EveryoneReportFilterInput!, $type: String) {
		everyoneReport(filter: $filter, type: $type) {
			status
			message
		}
	}
`;
