import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik } from "formik";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import { useStore } from "utils/store";
import { CREATE_INDEX, GET_INDEX_DETAILS, UPDATE_INDEX } from "graphql/indexes";
import {
  BlackActionContainedButton,
  NewRedAlertContainedButton,
} from "common/Buttons";
import { GET_TEMPLATE_TYPES } from "graphql/templates";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface IFolder {
  name: string;
  templateTypeIds: string[];
}

interface IAddEditFolderModalProps extends IModalProps {
  selectedIndexItem: string;
  setSelectedIndexItem: Dispatch<SetStateAction<string>>;
  nestedIdArr: string[];
  indexTempTypes: string[];
  refetchTableData: any;
  setDeleteModal: Dispatch<SetStateAction<boolean>>;
}

function AddEditFolderModal(props: IAddEditFolderModalProps) {
  const {
    open,
    setOpen,
    selectedIndexItem,
    setSelectedIndexItem,
    nestedIdArr,
    indexTempTypes,
    refetchTableData,
    setDeleteModal,
  } = props;

  const { setLoading } = useStore();
  const params = useParams();

  const [folderDetails, setFolderDetails] = useState<IFolder>({
    name: "",
    templateTypeIds: indexTempTypes || [],
  });
  const [templateTypeArr, setTemplateTypeArr] = useState<IArr[]>([]);

  const [getFolderName, { loading: gettingDetails }] = useLazyQuery(
    GET_INDEX_DETAILS,
    {
      onCompleted: (data) => {
        const { getRegister } = data;
        const { status, register } = getRegister;
        if (status) {
          setFolderDetails({
            name: register.name,
            templateTypeIds: register?.templateTypes
              ? register?.templateTypes.map((x: IArr) => x.id)
              : [],
          });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const { loading: gettingArr } = useQuery(GET_TEMPLATE_TYPES, {
    variables: {},
    onCompleted: (data) => {
      const { templateTypes } = data;
      const { status, templateTypes: rawTemplateTypes } = templateTypes;
      if (status) {
        setTemplateTypeArr(rawTemplateTypes);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setSelectedIndexItem("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireCreateNestedFolderApi, { loading: creating }] = useMutation(
    CREATE_INDEX,
    {
      onCompleted: (data) => {
        const { createRegister } = data;
        const { status, message } = createRegister;
        if (status) {
          toast.success(message, { delay: 10 });
          refetchTableData();
        } else {
          toast.error(message, { delay: 10 });
        }
        handleClose();
      },
    }
  );

  const [fireUpdateNestedFolderApi, { loading: updating }] = useMutation(
    UPDATE_INDEX,
    {
      onCompleted: (data) => {
        const { updateRegister } = data;
        const { status, message } = updateRegister;
        if (status) {
          toast.success(message, { delay: 10 });
          refetchTableData();
        } else {
          toast.error(message, { delay: 10 });
        }
        handleClose();
      },
    }
  );

  const handleUpsert = (values: IFolder) => {
    if (selectedIndexItem) {
      fireUpdateNestedFolderApi({
        variables: {
          payload: {
            id: selectedIndexItem,
            parentRegisterId: nestedIdArr[nestedIdArr.length - 1],
            name: values.name,
            templateTypeIds: values.templateTypeIds.map((x) => parseInt(x)),
          },
        },
      });
    } else {
      fireCreateNestedFolderApi({
        variables: {
          payload: {
            projectId: params?.project_id,
            parentRegisterId: nestedIdArr[nestedIdArr.length - 1],
            name: values.name,
            templateTypeIds: values.templateTypeIds.map((x) => parseInt(x)),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (selectedIndexItem) {
      getFolderName({
        variables: {
          payload: {
            projectId: params?.project_id,
            registerId: selectedIndexItem,
          },
        },
      });
    }
  }, [getFolderName, nestedIdArr, params?.project_id, selectedIndexItem]);

  useEffect(() => {
    setLoading(creating || gettingArr || gettingDetails || updating);
  }, [creating, gettingArr, gettingDetails, setLoading, updating]);

  return (
    <Formik
      initialValues={folderDetails}
      onSubmit={handleUpsert}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required("Please enter the folder name")
          .matches(/^[^/]*$/, "Folder name cannot contain '/'"),
        templateTypeIds: Yup.array()
          .of(Yup.string())
          .min(1, "Please select atleast 1 template type"),
      })}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        setFieldTouched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullWidth
          maxWidth={"sm"}
          PaperProps={{ sx: { borderRadius: "24px" } }}
        >
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  py: 2,
                  backgroundColor: "#B6BCC325",
                  position: "relative",
                  px: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      fontSize: "13px",
                      color: colors.primaryBlack,
                      fontWeight: 600,
                    }}
                  >
                    Document Folder
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                <IconButton size="small" onClick={handleClose}>
                  <Close sx={{ color: "#00000055" }} fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ pt: 4, px: 6, pb: 3 }}>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      Folder Name <span style={styles.error_text}>*</span>
                    </Typography>
                    <TextField
                      id="name"
                      size="small"
                      fullWidth
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.name && errors?.name && (
                      <Typography sx={styles.error_text}>
                        {errors?.name}
                      </Typography>
                    )}
                  </Grid>
                  {Boolean(selectedIndexItem) && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        Template Types <span style={styles.error_text}>*</span>
                      </Typography>
                      <Autocomplete
                        disabled
                        id="templateTypeIds"
                        multiple
                        value={
                          templateTypeArr.length > 0
                            ? templateTypeArr.filter((x) =>
                                values.templateTypeIds.includes(x.id)
                              )
                            : []
                        }
                        onChange={(_, newValue) =>
                          setFieldValue(
                            "templateTypeIds",
                            newValue.map((x) => x.id)
                          )
                        }
                        sx={styles.input_field}
                        size="small"
                        disableClearable
                        fullWidth
                        options={templateTypeArr}
                        getOptionLabel={(option) => option.name}
                        // ChipProps={{
                        //   sx: {
                        //     borderRadius: "4px",
                        //     backgroundColor: "#285EC720",
                        //     color: colors.primaryBlack,
                        //   },
                        //   deleteIcon: <Close fontSize="small" />,
                        // }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {!Boolean(selectedIndexItem) && (
                      <Box sx={styles.flex_JCfe}>
                        <BlackActionContainedButton
                          variant="contained"
                          size="small"
                          sx={{
                            borderRadius: "20px",
                            ml: 1,
                            fontSize: "13px",
                            fontWeight: 600,
                            width: "120px",
                          }}
                          disableElevation
                          disableRipple
                          onClick={() => handleSubmit()}
                        >
                          Save
                        </BlackActionContainedButton>
                      </Box>
                    )}
                    {Boolean(selectedIndexItem) && (
                      <Box sx={[styles.flex_JCsb, { alignItems: "center" }]}>
                        <NewRedAlertContainedButton
                          sx={{
                            fontWeight: 600,
                            fontSize: "13px",
                            borderRadius: "20px",
                            width: "120px",
                          }}
                          onClick={() => {
                            setOpen(false);
                            setDeleteModal(true);
                          }}
                        >
                          Delete
                        </NewRedAlertContainedButton>
                        <BlackActionContainedButton
                          variant="contained"
                          sx={{
                            borderRadius: "20px",
                            ml: 1,
                            fontSize: "13px",
                            fontWeight: 600,
                            width: "120px",
                          }}
                          disableElevation
                          disableRipple
                          onClick={() => handleSubmit()}
                        >
                          Save
                        </BlackActionContainedButton>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Formik>
  );
}

export default AddEditFolderModal;
