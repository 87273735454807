import { gql } from "@apollo/client";

export const GET_MY_INTEGRATIONS = gql`
  query getMyIntegrations {
    getMyIntegrations {
      status
      message
      integrations {
        docusignIntegrated
      }
    }
  }
`;

export const GET_CONNECT_DOCUSIGN_URL = gql`
  query getConnectUrlDocusign {
    getConnectUrlDocusign {
      status
      message
      url
    }
  }
`;

export const CONNECT_TO_DOCUSIGN = gql`
  mutation connectDocusign($payload: ConnectDocusignInput) {
    connectDocusign(payload: $payload) {
      status
      message
    }
  }
`;

export const DISCONNECT_DOCUSIGN = gql`
  mutation disconnectDocusign {
    disconnectDocusign {
      status
      message
    }
  }
`;
