import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutline, ModeEditOutline, MoreVert } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from "@mui/material";

import { IChecklist } from "models/checklists";
import { RouteNames } from "routes/routeNames";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

interface IDeliveryCardProps {
  checklist: IChecklist;
  setSelectedChecklist: Dispatch<SetStateAction<string>>;
  setAddEditChecklistModal: Dispatch<SetStateAction<boolean>>;
  setDeleteChecklistModal: Dispatch<SetStateAction<boolean>>;
  setSelectedChecklistName: Dispatch<SetStateAction<string>>;
}

function DeliveryCard(props: IDeliveryCardProps) {
  const {
    checklist,
    setAddEditChecklistModal,
    setDeleteChecklistModal,
    setSelectedChecklist,
    setSelectedChecklistName,
  } = props;

  const projectId = useParams();
  const navigate = useNavigate();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const deliveryEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("DELIVERY_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [actionsMenuAnchor, setActionsMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleActionsMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActionsMenuAnchor(e.currentTarget);
    setOpenActionsMenu(true);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchor(null);
    setOpenActionsMenu(false);
  };

  return (
    <Box
      sx={{
        my: 1,
        display: "flex",
        // justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        component="div"
        sx={{
          cursor: "pointer",
          width: 210,
          height: 147,
          aspectRatio: 1 / 0.7,
          backgroundColor: "#B6BCC320",
          borderRadius: "15px",
          position: "relative",
        }}
        onClick={() => {
          navigate(
            `${RouteNames.PROJECTS}/${projectId?.project_id}/delivery-checklist/${checklist.id}`
          );
        }}
      >
        {checklist.photo ? (
          <img
            src={checklist.photo}
            alt="netflix"
            style={{
              width: "210px",
              height: "147px",
              objectFit: "cover",
              borderRadius: "15px",
              aspectRatio: 1 / 0.7,
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              p: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: `${colors.primaryBlack}70`,
                fontWeight: 600,
              }}
              textAlign="center"
            >
              {checklist.name}
            </Typography>
          </Box>
        )}
        <Menu
          anchorEl={actionsMenuAnchor}
          open={openActionsMenu}
          onClose={handleActionsMenuClose}
          elevation={1}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <MenuList disablePadding>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setSelectedChecklist(checklist.id);
                setAddEditChecklistModal(true);
                handleActionsMenuClose();
              }}
            >
              <ListItemIcon>
                <ModeEditOutline fontSize="small" sx={{ color: "#00000055" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                >
                  Edit
                </Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setSelectedChecklist(checklist.id);
                setSelectedChecklistName(checklist.name);
                setDeleteChecklistModal(true);
              }}
            >
              <ListItemIcon>
                <DeleteOutline fontSize="small" sx={{ color: "#00000055" }} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                >
                  Delete
                </Typography>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <Box sx={{ mt: 1, width: 210, display: "flex" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Tooltip title={checklist?.name}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 700,
                color: colors.primaryBlack,
                whiteSpace: "normal",
              }}
              noWrap
              textAlign="center"
            >
              {checklist?.name}
            </Typography>
          </Tooltip>
        </Box>
        {Boolean(ROLE_PERMISSIONS) &&
          deliveryEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
            ?.DELIVERY_EDIT && (
            <Box>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleActionsMenuOpen(e);
                }}
              >
                <MoreVert fontSize="small" sx={{ color: "#00000055" }} />
              </IconButton>
            </Box>
          )}
      </Box>
    </Box>
  );
}

export default DeliveryCard;
