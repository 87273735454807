import React, { Dispatch, SetStateAction } from "react";
import {
	Box,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { RadioButtonChecked } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useStore } from "utils/store";
import { toast } from "react-toastify";

import { IUpdateContactFieldsNew } from "models/contacts";
import {
	BlackActionContainedButton,
	NewGreenPrimaryContainedButton,
	NewOutlinedBtn,
} from "common/Buttons";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface ISuperannuationFormProps {
	initialValues: IUpdateContactFieldsNew;
	refetch: any;
	updateContactApi: any;
	profilePicture: any;
	setActiveSection: Dispatch<SetStateAction<number>>;
}

function SuperannuationForm(props: ISuperannuationFormProps) {
	const {
		initialValues,
		refetch,
		updateContactApi,
		setActiveSection,
		profilePicture,
	} = props;

	const { setLoading } = useStore();

	const handleRadioChange = (e: any, setFieldValue: any) => {
		setFieldValue(
			"personalBankAccountDetails.haveSuperannuationAccount",
			e.target.value
		);
		if (e.target.value === "1") {
			setFieldValue("personalBankAccountDetails.smsfName", "");
			setFieldValue("personalBankAccountDetails.smsfAbn", "");
			setFieldValue("personalBankAccountDetails.smsfEsa", "");
			setFieldValue("personalBankAccountDetails.smsfFullName", "");
			setFieldValue("personalBankAccountDetails.smsfBankAccountName", "");
			setFieldValue("personalBankAccountDetails.smsfBsb", "");
			setFieldValue(
				"personalBankAccountDetails.superannuationAccountNo",
				""
			);
			setFieldValue("personalBankAccountDetails.superannuationFundName", "");
			setFieldValue("personalBankAccountDetails.memberNumber", "");
		}
		if (e.target.value === "2") {
			setFieldValue(
				"personalBankAccountDetails.superannuationFundName",
				"Self managed super fund"
			);
			setFieldValue(
				"personalBankAccountDetails.memberNumber",
				"Self managed super fund"
			);
		}
		if (e.target.value === "3") {
			setFieldValue("personalBankAccountDetails.smsfName", "");
			setFieldValue("personalBankAccountDetails.smsfAbn", "");
			setFieldValue("personalBankAccountDetails.smsfEsa", "");
			setFieldValue("personalBankAccountDetails.smsfFullName", "");
			setFieldValue("personalBankAccountDetails.smsfBankAccountName", "");
			setFieldValue("personalBankAccountDetails.smsfBsb", "");
			setFieldValue(
				"personalBankAccountDetails.superannuationAccountNo",
				""
			);
			setFieldValue(
				"personalBankAccountDetails.superannuationFundName",
				"Employer's default super fund"
			);
			setFieldValue(
				"personalBankAccountDetails.memberNumber",
				"Employer's default super fund"
			);
		}
	};

	const handleSubmitIndividualForm = (values: IUpdateContactFieldsNew) => {
		const { id, personalBankAccountDetails } = values;

		const {
			haveSuperannuationAccount,
			superannuationFundName,
			memberNumber,
			smsfName,
			smsfAbn,
			smsfEsa,
			smsfFullName,
			smsfBankAccountName,
			smsfBsb,
			superannuationAccountNo,
			...restFinancialDetails
		} = personalBankAccountDetails;
		const financialDetailsJSON = {
			haveSuperannuationAccount,
			superannuationFundName,
			memberNumber,
			smsfName,
			smsfAbn,
			smsfEsa,
			smsfFullName,
			smsfBankAccountName,
			smsfBsb,
			superannuationAccountNo,
		};

		updateContactApi({
			variables: {
				payload: {
					id,
					personalBankAccountDetails: {
						...restFinancialDetails,
						superannuationData: JSON.stringify(financialDetailsJSON),
					},
					...(profilePicture === null && { removeProfilePhoto: true }),
				},
				...(Boolean(profilePicture) &&
					typeof profilePicture !== "string" && {
						profile_picture: profilePicture,
					}),
			},
			onCompleted: (data: any) => {
				setLoading(false);
				const { updateContactBasicDetails } = data;
				const { status, message } = updateContactBasicDetails;
				if (status) {
					refetch();
					toast.success(message, { delay: 10 });
				} else {
					toast.error(message, { delay: 10 });
				}
			},
		});
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				personalBankAccountDetails: Yup.object().shape({
					smsfAbn: Yup.string()
						.matches(/^[0-9]{11}$/, "ABN must be 11 digits")
						.nullable(),
					smsfBsb: Yup.string()
						.matches(/^[0-9]{6}$/, "BSB must be 6 digits")
						.nullable(),
					superannuationAccountNo: Yup.string()
						.matches(/^[0-9]{10}$/, "Account number must be 10 digits")
						.nullable(),
				}),
			})}
			onSubmit={handleSubmitIndividualForm}
			enableReinitialize
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleBlur,
				touched,
				errors,
				handleSubmit,
			}) => (
				<Box
					sx={{
						pt: { xs: 2, sm: 14 },
						px: 4,
						pb: 1.5,
						display: "flex",
						flexDirection: "column",
						height: "100%",
					}}
				>
					<Grid container spacing={3}>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={{ display: { xs: "block", sm: "none" } }}
						>
							<Typography
								sx={{
									fontSize: "13px",
									color: "#000",
									fontWeight: 600,
								}}
							>
								Superannuation Details
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Do you have a Superannuation Account?
							</Typography>
							<RadioGroup
								value={
									values.personalBankAccountDetails
										.haveSuperannuationAccount
								}
								onChange={(e) => handleRadioChange(e, setFieldValue)}
							>
								<FormControlLabel
									value="1"
									control={
										<Radio
											size="small"
											checkedIcon={
												<RadioButtonChecked
													sx={{ color: "#6f6f6f" }}
												/>
											}
										/>
									}
									label={
										<Typography
											sx={[
												styles.field_label,
												{ fontWeight: 400, color: "#00000080" },
											]}
										>
											Yes, I know my superannuation fund details
										</Typography>
									}
									sx={{ mt: -0.5 }}
								/>
								<FormControlLabel
									value="2"
									control={
										<Radio
											size="small"
											checkedIcon={
												<RadioButtonChecked
													sx={{ color: "#6f6f6f" }}
												/>
											}
										/>
									}
									label={
										<Typography
											sx={[
												styles.field_label,
												{ fontWeight: 400, color: "#00000080" },
											]}
										>
											I have a self managed super fund
										</Typography>
									}
									sx={{ mt: -1 }}
								/>
								<FormControlLabel
									value="3"
									control={
										<Radio
											size="small"
											checkedIcon={
												<RadioButtonChecked
													sx={{ color: "#6f6f6f" }}
												/>
											}
										/>
									}
									label={
										<Typography
											sx={[
												styles.field_label,
												{ fontWeight: 400, color: "#00000080" },
											]}
										>
											No, use my employer's default super fund
										</Typography>
									}
									sx={{ mt: -1 }}
								/>
							</RadioGroup>
						</Grid>
						{Boolean(
							values.personalBankAccountDetails.haveSuperannuationAccount
						) &&
							values.personalBankAccountDetails
								.haveSuperannuationAccount === "1" && (
								<>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											Enter your super fund product name or USI
										</Typography>
										<TextField
											id="personalBankAccountDetails.superannuationFundName"
											value={
												values.personalBankAccountDetails
													.superannuationFundName
											}
											onChange={handleChange}
											onBlur={handleBlur}
											size="small"
											fullWidth
											inputProps={{ style: styles.input_field }}
										/>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											Member Number
										</Typography>
										<TextField
											id="personalBankAccountDetails.memberNumber"
											value={
												values.personalBankAccountDetails
													.memberNumber
											}
											onChange={handleChange}
											onBlur={handleBlur}
											size="small"
											fullWidth
											inputProps={{ style: styles.input_field }}
										/>
									</Grid>
								</>
							)}
						{Boolean(
							values.personalBankAccountDetails.haveSuperannuationAccount
						) &&
							values.personalBankAccountDetails
								.haveSuperannuationAccount === "2" && (
								<>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											SMSF Name
										</Typography>
										<TextField
											id="personalBankAccountDetails.smsfName"
											value={
												values.personalBankAccountDetails.smsfName
											}
											onChange={handleChange}
											onBlur={handleBlur}
											size="small"
											fullWidth
											inputProps={{ style: styles.input_field }}
										/>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											SMSF Australian Business Number (ABN)
										</Typography>
										<TextField
											id="personalBankAccountDetails.smsfAbn"
											value={
												values.personalBankAccountDetails.smsfAbn
											}
											onChange={(e) => {
												if (
													!isNaN(
														e.target.value as unknown as number
													)
												) {
													if (e.target.value.length <= 11) {
														setFieldValue(
															"personalBankAccountDetails.smsfAbn",
															e.target.value
														);
													}
												}
											}}
											onBlur={handleBlur}
											size="small"
											fullWidth
											inputProps={{ style: styles.input_field }}
										/>
										{touched?.personalBankAccountDetails?.smsfAbn && (
											<Typography sx={styles.error_text}>
												{
													errors?.personalBankAccountDetails
														?.smsfAbn
												}
											</Typography>
										)}
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											SMSF Electronic Service Address (ESA) <br />{" "}
											<span
												style={{
													fontSize: "11px",
													fontWeight: 400,
													fontStyle: "italic",
													marginTop: -10,
												}}
											>
												An ESA is used so the fund can receive
												electronic messages and payments from your
												employer using SuperStream. You can find
												your ESA by contacting your SMSF messaging
												provider or through your SMSF administrator,
												tax agent, accountant or bank.
											</span>
										</Typography>
										<TextField
											id="personalBankAccountDetails.smsfEsa"
											value={
												values.personalBankAccountDetails.smsfEsa
											}
											onChange={handleChange}
											onBlur={handleBlur}
											size="small"
											fullWidth
											inputProps={{ style: styles.input_field }}
										/>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography sx={styles.field_label}>
											Full Name (as it apperas on your account){" "}
											<br />{" "}
											<span
												style={{
													fontSize: "11px",
													fontWeight: 400,
													fontStyle: "italic",
													marginTop: -10,
												}}
											>
												This must match the name shown on your super
												account. This may be your current name, or a
												previous name.
											</span>
										</Typography>
										<TextField
											id="personalBankAccountDetails.smsfFullName"
											value={
												values.personalBankAccountDetails
													.smsfFullName
											}
											onChange={handleChange}
											onBlur={handleBlur}
											size="small"
											fullWidth
											inputProps={{ style: styles.input_field }}
										/>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Typography
											sx={[
												styles.field_label,
												{ textDecoration: "underline" },
											]}
										>
											SMSF Bank Account Details
										</Typography>
										<Grid container spacing={1.5}>
											<Grid item lg={12} md={12} sm={12} xs={12}>
												<Typography sx={styles.field_label}>
													Bank Account Name
												</Typography>
												<TextField
													id="personalBankAccountDetails.smsfBankAccountName"
													value={
														values.personalBankAccountDetails
															.smsfBankAccountName
													}
													onChange={handleChange}
													onBlur={handleBlur}
													size="small"
													fullWidth
													inputProps={{
														style: styles.input_field,
													}}
												/>
											</Grid>
											<Grid item lg={12} md={12} sm={12} xs={12}>
												<Typography sx={styles.field_label}>
													BSB Code
												</Typography>
												<TextField
													id="personalBankAccountDetails.smsfBsb"
													value={
														values.personalBankAccountDetails
															.smsfBsb
													}
													onChange={(e) => {
														if (
															!isNaN(
																e.target
																	.value as unknown as number
															)
														) {
															if (e.target.value.length <= 6) {
																setFieldValue(
																	"personalBankAccountDetails.smsfBsb",
																	e.target.value
																);
															}
														}
													}}
													onBlur={handleBlur}
													size="small"
													fullWidth
													inputProps={{
														style: styles.input_field,
													}}
												/>
												{touched?.personalBankAccountDetails
													?.smsfBsb && (
													<Typography sx={styles.error_text}>
														{
															errors?.personalBankAccountDetails
																?.smsfBsb
														}
													</Typography>
												)}
											</Grid>
											<Grid item lg={12} md={12} sm={12} xs={12}>
												<Typography sx={styles.field_label}>
													Account Number
												</Typography>
												<TextField
													id="personalBankAccountDetails.superannuationAccountNo"
													value={
														values.personalBankAccountDetails
															.superannuationAccountNo
													}
													onChange={(e) => {
														if (
															!isNaN(
																e.target
																	.value as unknown as number
															)
														) {
															if (e.target.value.length <= 10) {
																setFieldValue(
																	"personalBankAccountDetails.superannuationAccountNo",
																	e.target.value
																);
															}
														}
													}}
													onBlur={handleBlur}
													size="small"
													fullWidth
													inputProps={{
														style: styles.input_field,
													}}
												/>
												{touched?.personalBankAccountDetails
													?.superannuationAccountNo && (
													<Typography sx={styles.error_text}>
														{
															errors?.personalBankAccountDetails
																?.superannuationAccountNo
														}
													</Typography>
												)}
											</Grid>
										</Grid>
									</Grid>
								</>
							)}
					</Grid>
					<Box sx={{ flexGrow: 1, mt: 2 }}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
							}}
						>
							<Box sx={{ flexGrow: 1 }} />
							<Box
								sx={[
									styles.flex_Acenter,
									{ justifyContent: "space-between" },
								]}
							>
								<BlackActionContainedButton
									size="small"
									sx={{
										borderRadius: "20px",
										fontSize: "13px",
										fontWeight: 600,
										width: "120px",
										color: "#fff",
									}}
									disableElevation
									disableRipple
									onClick={() => setActiveSection(2)}
								>
									Back
								</BlackActionContainedButton>
								<Box sx={[styles.flex_JCfe, { alignItems: "center" }]}>
									<NewGreenPrimaryContainedButton
										variant="contained"
										size="small"
										sx={{
											borderRadius: "20px",
											fontSize: "13px",
											fontWeight: 600,
											width: "120px",
										}}
										disableElevation
										disableRipple
										onClick={() => handleSubmit()}
									>
										Save
									</NewGreenPrimaryContainedButton>
									<NewOutlinedBtn
										variant="outlined"
										size="small"
										sx={{
											borderRadius: "20px",
											fontSize: "13px",
											fontWeight: 600,
											width: "120px",
											color: "#000",
											ml: 1,
										}}
										disableElevation
										disableRipple
										onClick={() => setActiveSection(4)}
									>
										Next
									</NewOutlinedBtn>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</Formik>
	);
}

export default SuperannuationForm;
