import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import { FilterAltOutlined, Share } from "@mui/icons-material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import Table from "common/Table";
import { IArr } from "models/common";
import { useStore } from "utils/store";
import { IContact } from "models/contacts";
import {
  ChecklistItemStatusArr,
  ChecklistItemStatusMapping,
} from "utils/constants";
import {
  EXPORT_CHECKLIST_REPORT,
  GET_ALL_CHECKLIST_ARR,
  GET_ALL_CHECKLIST_ITEMS_ARR,
  GET_CHECKLIST_REPORT,
} from "graphql/checklist";
import { BlackActionContainedButton } from "common/Buttons";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

function ChecklistReport() {
  const { setLoading } = useStore();
  const projectId = useParams();
  const boxRef = useRef(null);

  const [documents, setDocuments] = useState<IContact[]>([]);
  const [documentRowCount, setDocumentRowCount] = useState(0);
  const [checklistArr, setChecklistArr] = useState<IArr[]>([]);
  const [checklistItemArr, setChecklistItemArr] = useState<IArr[]>([]);
  const [filterChecklist, setFilterChecklist] = useState("");
  const [filterChecklistItem, setFilterChecklistItem] = useState<IArr | null>(
    null
  );
  const [filterChecklistItemStatus, setFilterChecklistItemStatus] =
    useState("");
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  const { loading, fetchMore } = useQuery(GET_CHECKLIST_REPORT, {
    variables: {
      project_id: projectId?.project_id,
      page: 1,
      limit: 50,
      filter: {
        ...(Boolean(filterChecklist) && { checklist_id: filterChecklist }),
        ...(Boolean(filterChecklistItem) && {
          checklist_item_name: filterChecklistItem?.name,
        }),
        ...(Boolean(filterChecklistItemStatus) && {
          checklist_item_status: filterChecklistItemStatus,
        }),
      },
    },
    skip: !projectId?.project_id,
    onCompleted: (data) => {
      const { getAllChecklistReport } = data;
      const { status, count, checklist } = getAllChecklistReport;
      if (status) {
        setDocuments(checklist);
        setDocumentRowCount(count);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const {} = useQuery(GET_ALL_CHECKLIST_ARR, {
    variables: { project_id: projectId?.project_id },
    skip: !projectId?.project_id,
    onCompleted: (data) => {
      const { getAllChecklist } = data;
      const { status, checklist } = getAllChecklist;
      if (status) {
        setChecklistArr(checklist);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const {} = useQuery(GET_ALL_CHECKLIST_ITEMS_ARR, {
    variables: { project_id: projectId?.project_id },
    skip: !projectId?.project_id,
    onCompleted: (data) => {
      const { getAllChecklistItems } = data;
      const { status, checklistItem } = getAllChecklistItems;
      if (status) {
        setChecklistItemArr(checklistItem);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getReport, { loading: exporting }] = useLazyQuery(
    EXPORT_CHECKLIST_REPORT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { getAllChecklistReportExport } = data;
        if (Boolean(getAllChecklistReportExport?.report)) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href =
            "data:application/vnd.ms-excel;base64," +
            encodeURI(getAllChecklistReportExport?.report);
          hiddenElement.target = "_blank";
          hiddenElement.download = `Checklist Report.xlsx`;
          hiddenElement.click();
        } else {
          toast.error("Something went wrong, please try again.", { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "documentName",
        headerName: "Document Name",
        minWidth: 220,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "documentType",
        headerName: "Document Type",
        minWidth: 180,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "checklistName",
        headerName: "Checklist Name",
        minWidth: 250,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, styles.wrap]}>
            {params?.value}
          </Typography>
        ),
      },
      {
        field: "checklistItemName",
        headerName: "Checklist Item Name",
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "checklistItemStatus",
        headerName: "Checklist Item Status",
        headerAlign: "center",
        minWidth: 150,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) =>
          Boolean(params?.value) ? (
            <Chip
              label={
                params?.value
                  ? (ChecklistItemStatusMapping as any)[params?.value]?.name
                  : ""
              }
              sx={[
                styles.chip_variant,
                {
                  color: "#fff",
                  backgroundColor: (ChecklistItemStatusMapping as any)[
                    params?.value
                  ]?.color,
                  fontWeight: 600,
                },
              ]}
            />
          ) : (
            <></>
          ),
      },
    ],
    []
  );

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const box = boxRef.current;
      const isAtBottom =
        (box as any).scrollHeight - (box as any).scrollTop <=
        (box as any).clientHeight + 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (documents.length < documentRowCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(documents.length / 50) + 1,
              limit: 50,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries =
                fetchMoreResult.getAllChecklistReport.checklist;
              return {
                getAllChecklistReport: {
                  ...fetchMoreResult?.getAllChecklistReport,
                  checklist: [...documents, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    const box = boxRef.current;
    if (box) {
      (box as any).addEventListener("scroll", handleScroll);
    }

    // Clean up event listener on component unmount
    return () => {
      if (box) {
        (box as any).removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchMore, setLoading, documents, documentRowCount]);

  useEffect(() => {
    setLoading(exporting || loading);
  }, [exporting, loading, setLoading]);

  return (
    <>
      <Box
        sx={{
          p: 1,
          display: { xs: "flex", sm: "none" },
          mb: 1,
        }}
      >
        <Box
          sx={[
            styles.flex_Acenter,
            { justifyContent: "flex-end", width: "100%" },
          ]}
        >
          <Box>
            <BlackActionContainedButton
              variant="contained"
              disableElevation
              startIcon={<Share color="secondary" />}
              onClick={() =>
                getReport({
                  variables: {
                    project_id: projectId?.project_id,
                    filter: {
                      ...(Boolean(filterChecklist) && {
                        checklist_id: filterChecklist,
                      }),
                      ...(Boolean(filterChecklistItem) && {
                        checklist_item_name: filterChecklistItem?.name,
                      }),
                      ...(Boolean(filterChecklistItemStatus) && {
                        checklist_item_status: filterChecklistItemStatus,
                      }),
                    },
                  },
                })
              }
              sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
            >
              Export
            </BlackActionContainedButton>
          </Box>
          <Box sx={{ ml: 2 }}>
            <IconButton
              onClick={handleFilterMenuOpen}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <FilterAltOutlined
                fontSize="small"
                sx={{
                  color: openFilterMenu
                    ? "#41B199"
                    : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
            <Menu
              anchorEl={filterMenuAnchor}
              open={openFilterMenu}
              onClose={handleFilterMenuClose}
              elevation={1}
              sx={{ "& .MuiMenu-paper": { width: 300 } }}
            >
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000080",
                      fontWeight: 600,
                      mb: 0.75,
                    }}
                  >
                    Select Checklist
                  </Typography>
                  <Autocomplete
                    value={
                      Boolean(filterChecklist)
                        ? checklistArr.find((x) => x.id === filterChecklist)
                        : { id: "", name: "" }
                    }
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setFilterChecklist(newValue.id);
                      } else {
                        setFilterChecklist("");
                      }
                    }}
                    size="small"
                    fullWidth
                    options={checklistArr}
                    getOptionLabel={(option) => option.name}
                    placeholder="Select Status"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            borderRadius: "6px",
                            fontSize: "13px",
                            color: colors.primaryBlack,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000080",
                      fontWeight: 600,
                      mb: 0.75,
                    }}
                  >
                    Select Checklist Item
                  </Typography>
                  <Autocomplete
                    value={
                      Boolean(filterChecklistItem)
                        ? checklistItemArr.find(
                            (x) => x.id === filterChecklistItem!.id
                          )
                        : { id: "", name: "" }
                    }
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setFilterChecklistItem(newValue);
                      } else {
                        setFilterChecklistItem(null);
                      }
                    }}
                    size="small"
                    fullWidth
                    options={checklistItemArr}
                    getOptionLabel={(option) => option.name}
                    placeholder="Select Status"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            borderRadius: "6px",
                            fontSize: "13px",
                            color: colors.primaryBlack,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000080",
                      fontWeight: 600,
                      mb: 0.75,
                    }}
                  >
                    Select Status
                  </Typography>
                  <Autocomplete
                    value={
                      Boolean(filterChecklistItemStatus)
                        ? ChecklistItemStatusArr.find(
                            (x) => x.id === filterChecklistItemStatus
                          )
                        : { id: "", name: "" }
                    }
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setFilterChecklistItemStatus(newValue.id);
                      } else {
                        setFilterChecklistItemStatus("");
                      }
                    }}
                    size="small"
                    fullWidth
                    options={ChecklistItemStatusArr}
                    getOptionLabel={(option) => option.name}
                    placeholder="Select Status"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            borderRadius: "6px",
                            fontSize: "13px",
                            color: colors.primaryBlack,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box
        ref={boxRef}
        sx={{
          height: "calc(100vh - 245px)",
          overflow: "auto",
          mx: -3,
          mb: -3,
        }}
      >
        <Table
          rows={documents}
          columns={columns}
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          disableColumnMenu
          sx={{
            ...styles.table,
            "& .MuiDataGrid-columnHeaders": {
              border: "none",
              backgroundColor: "#B6BCC320",
            },
            "& .MuiDataGrid-row": {
              borderBottom: "1px #e6e6e6 solid",
              borderRadius: 0,
            },
            "& .MuiDataGrid-row:hover": {
              borderBottom: "transparent",
            },
            borderTop: "1px #e6e6e6 solid",
            borderRadius: 0,
          }}
          hideFooter
        />
        <Box
          sx={{
            position: "absolute",
            top: { xs: 160, sm: 195, md: 178 },
            right: 30,
          }}
        >
          <Box
            sx={[styles.flex_Acenter, { display: { xs: "none", sm: "flex" } }]}
          >
            <Box>
              <BlackActionContainedButton
                variant="contained"
                disableElevation
                startIcon={<Share color="secondary" />}
                onClick={() =>
                  getReport({
                    variables: {
                      project_id: projectId?.project_id,
                      filter: {
                        ...(Boolean(filterChecklist) && {
                          checklist_id: filterChecklist,
                        }),
                        ...(Boolean(filterChecklistItem) && {
                          checklist_item_name: filterChecklistItem?.name,
                        }),
                        ...(Boolean(filterChecklistItemStatus) && {
                          checklist_item_status: filterChecklistItemStatus,
                        }),
                      },
                    },
                  })
                }
                sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
              >
                Export
              </BlackActionContainedButton>
            </Box>
            <Box sx={{ ml: 2 }}>
              <IconButton
                onClick={handleFilterMenuOpen}
                size="small"
                sx={{ ":hover": { backgroundColor: "#41B19931" } }}
              >
                <FilterAltOutlined
                  fontSize="small"
                  sx={{
                    color: openFilterMenu
                      ? "#41B199"
                      : `${colors.primaryBlack}50`,
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={filterMenuAnchor}
                open={openFilterMenu}
                onClose={handleFilterMenuClose}
                elevation={1}
                sx={{ "& .MuiMenu-paper": { width: 300 } }}
              >
                <Grid container spacing={2} sx={{ p: 2 }}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#00000080",
                        fontWeight: 600,
                        mb: 0.75,
                      }}
                    >
                      Select Checklist
                    </Typography>
                    <Autocomplete
                      value={
                        Boolean(filterChecklist)
                          ? checklistArr.find((x) => x.id === filterChecklist)
                          : { id: "", name: "" }
                      }
                      onChange={(_, newValue) => {
                        if (newValue) {
                          setFilterChecklist(newValue.id);
                        } else {
                          setFilterChecklist("");
                        }
                      }}
                      size="small"
                      fullWidth
                      options={checklistArr}
                      getOptionLabel={(option) => option.name}
                      placeholder="Select Status"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              borderRadius: "6px",
                              fontSize: "13px",
                              color: colors.primaryBlack,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#00000080",
                        fontWeight: 600,
                        mb: 0.75,
                      }}
                    >
                      Select Checklist Item
                    </Typography>
                    <Autocomplete
                      value={
                        Boolean(filterChecklistItem)
                          ? checklistItemArr.find(
                              (x) => x.id === filterChecklistItem!.id
                            )
                          : { id: "", name: "" }
                      }
                      onChange={(_, newValue) => {
                        if (newValue) {
                          setFilterChecklistItem(newValue);
                        } else {
                          setFilterChecklistItem(null);
                        }
                      }}
                      size="small"
                      fullWidth
                      options={checklistItemArr}
                      getOptionLabel={(option) => option.name}
                      placeholder="Select Status"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              borderRadius: "6px",
                              fontSize: "13px",
                              color: colors.primaryBlack,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#00000080",
                        fontWeight: 600,
                        mb: 0.75,
                      }}
                    >
                      Select Status
                    </Typography>
                    <Autocomplete
                      value={
                        Boolean(filterChecklistItemStatus)
                          ? ChecklistItemStatusArr.find(
                              (x) => x.id === filterChecklistItemStatus
                            )
                          : { id: "", name: "" }
                      }
                      onChange={(_, newValue) => {
                        if (newValue) {
                          setFilterChecklistItemStatus(newValue.id);
                        } else {
                          setFilterChecklistItemStatus("");
                        }
                      }}
                      size="small"
                      fullWidth
                      options={ChecklistItemStatusArr}
                      getOptionLabel={(option) => option.name}
                      placeholder="Select Status"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              borderRadius: "6px",
                              fontSize: "13px",
                              color: colors.primaryBlack,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ChecklistReport;
