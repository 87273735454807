import { colors } from "theme/colors";

export const SettingsStyles = {
  tab_text: {
    minWidth: 182,
    fontSize: "14px",
    fontWeight: 400,
    color: colors.primaryBlack,
    "&.Mui-selected": {
      fontWeight: 600,
      color: colors.primaryBlack,
    },
    ":hover": {
      backgroundColor: "#B6BCC325",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
    },
  },
  contentMargin: {
    ml: { xs: 0, sm: 10, md: 15 },
    mr: { xs: 0, sm: 10, md: 45, lg: 60 },
  },
  flex_JCcenter_Acenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  my3: { my: 3 },
  profile_pic_avatar: { width: "100px", height: "100px", mx: 4 },
  field_label: {
    color: colors.primaryBlack,
    fontSize: "13px",
    fontWeight: 500,
    mb: 0.5,
  },
  input_field: {
    backgroundColor: "#e6e6e640",
    borderRadius: "6px",
    fontSize: "13px",
    color: colors.primaryBlack,
  },
  radio_btn_label: {
    fontSize: "16px",
    color: colors.primaryBlack,
    fontWeight: 500,
  },
  notification_header: {
    fontSize: "16px",
    color: colors.primaryBlack,
    fontWeight: 500,
    mt: 3,
  },
  notification_text: {
    fontSize: "14px",
    color: colors.secondaryBlack,
    fontWeight: 500,
  },
  recent_act_text: {
    fontSize: "16px",
    color: colors.primaryBlack,
    fontWeight: 500,
  },
  activity_header_text: {
    fontSize: "14px",
    color: colors.primaryBlack,
    mb: -0.75,
  },
  timeline_container: {
    pl: 2,
    pb: 4,
    position: "relative",
    ml: 2,
  },
  timeline_dot: {
    position: "absolute",
    left: -13,
    top: -5,
  },
  flex_JCsb_Afe: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  flex_JCsb_Acenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flex: { display: "flex" },
  timeline_avatar: { width: "36px", height: "36px", mr: 1 },
  timeline_name_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.primary,
  },
  timeline_activity_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: colors.whiteGrey500,
  },
  timeline_time_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: colors.whiteGrey500,
  },
  error_text: { fontSize: "12px", color: "#D3010E" },
  proj_meta_data_text: {
    fontSize: "13px",
    color: `${colors.primaryBlack}70`,
  },
};
