import React, { Dispatch, SetStateAction, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { IEditProjectFields } from "models/projects";
import { useStore } from "utils/store";

import ProjectDetailsFormFormik from "./ProjectDetailsFormFormik";

interface IProjectDetailsFormProps {
  initialValues: IEditProjectFields;
  projectStatus: number;
  setProjectStatus: Dispatch<SetStateAction<number>>;
  fireUpdateProjectApi: any;
  refetch: any;
}

function ProjectDetailsForm(props: IProjectDetailsFormProps) {
  const {
    projectStatus,
    initialValues,
    setProjectStatus,
    fireUpdateProjectApi,
    refetch,
  } = props;

  const { setLoading } = useStore();
  const [closeModalState, setCloseModalState] = useState(false);
  const [editModeState, setEditModeState] = useState(false);

  const handleUpdateProj = (values: IEditProjectFields) => {
    const {
      episodes,
      episodeDuration,
      categoryLoading,
      shootDays,
      categoryLoadingInputs,
      projectStartDate,
      filmingDate,
      projectStatus,
      profile_picture,
      startPreProduction,
      startPrincipalPhotography,
      startPostProduction,
      startFineCut,
      startRoughCut,
      completionDate,
      deliveryDate,
      script,
      ...rest
    } = values;
    const projectDatesJSON = {
      startPreProduction,
      startPrincipalPhotography,
      startPostProduction,
      startRoughCut,
      startFineCut,
      completionDate,
      deliveryDate,
    };
    fireUpdateProjectApi({
      variables: {
        payload: {
          ...rest,
          ...(episodes !== "" && { episodes: parseInt(episodes as string) }),
          ...(episodeDuration !== "" && {
            episodeDuration: parseInt(episodeDuration as string),
          }),
          ...(categoryLoading !== "" && {
            categoryLoading: parseFloat(categoryLoading as string),
          }),
          ...(shootDays !== "" && {
            shootDays: parseInt(shootDays as string),
          }),
          ...(filmingDate !== "" && { filmingDate }),
          ...(projectStartDate !== "" && { projectStartDate }),
          categoryLoadingInputs: JSON.stringify(categoryLoadingInputs),
          // projectStatus: parseInt(projectStatus as string),
          projectDates: JSON.stringify(projectDatesJSON),
          ...(profile_picture === null && { removeProfilePhoto: true }),
        },
        ...(Boolean(profile_picture) &&
          typeof profile_picture !== "string" && { file: profile_picture }),
        ...(Boolean(script) &&
          typeof script !== "string" && { script: script }),
      },
      onCompleted: (data: any) => {
        setLoading(false);
        const { updateProject } = data;
        const { status, message } = updateProject;
        if (status) {
          toast.success(message);
          setEditModeState(true);
          setCloseModalState(true);
          refetch();
        } else {
          toast.error(message);
        }
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: IEditProjectFields) => {
        handleUpdateProj(values);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Please enter the project name"),
        projectCategory: Yup.string().required(
          "Please select the project category"
        ),
        episodes: Yup.string()
          .required("Please enter the number of episodes")
          .test("negative test", "Please enter a positive value", (val) =>
            Boolean(val) ? val !== "" && parseInt(val as string) > 0 : true
          ),
        episodeDuration: Yup.string()
          .required("Please enter the duration of each episodes")
          .test("negative test", "Please enter a positive value", (val) =>
            Boolean(val) ? val !== "" && parseInt(val as string) > 0 : true
          ),
        shootDays: Yup.string().test(
          "negative test",
          "Please enter a positive value",
          (val) =>
            Boolean(val) ? val !== "" && parseInt(val as string) > 0 : true
        ),
        categoryLoading: Yup.string()
          .required("Please enter the category loading")
          .test("negative test", "Please enter a positive value", (val) =>
            Boolean(val) ? val !== "" && parseInt(val as string) >= 0 : true
          ),
      })}
      enableReinitialize
    >
      {(formikBag) => {
        return (
          <ProjectDetailsFormFormik
            formikBag={formikBag}
            projectStatus={projectStatus}
            setProjectStatus={setProjectStatus}
            fireUpdateProjectApi={fireUpdateProjectApi}
            refetch={refetch}
            closeModalState={closeModalState}
            setCloseModalState={setCloseModalState}
            editModeState={editModeState}
            setEditModeState={setEditModeState}
          />
        );
      }}
    </Formik>
  );
}

export default ProjectDetailsForm;
