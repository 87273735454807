import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Editor as TinyMCEEditor } from "tinymce";
import { Editor } from "@tinymce/tinymce-react";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { useContract } from "hooks/useContract";
import {
	GET_CONTRACT_FILE,
	GET_PROJ_DEETS_CURRENCY,
	GET_RESOLUTION_VALUES,
} from "graphql/contracts";
import { useStore } from "utils/store";
import { ITemplate } from "models/templates";
import { IPerson } from "models/projects";
import { CurrencyMapping } from "utils/constants";
import { getPermissions } from "permissions/utils";

import EditorFrame from "./EditorFrame";
import { ISignerArr } from "./utils";

export interface IContract {
	id: string;
	name: string;
	documentStatus: string;
	additionalDetails: string;
	contact: { id: string; fullName: string };
	template: ITemplate;
	isFolder: boolean;
	file: any;
	createdDate: string;
	modifiedDate: string;
	fileExists: boolean;
	signedFile: string;
}

export interface IFillableFields {
	id: string;
	fieldName: string;
	fieldDescription: string;
	fieldType: string;
	fieldValue: string;
	fieldResolution: string;
	fieldMultiple: boolean;
	fieldArr: IPerson[];
	resolveTo: string;
}

export interface ITags {
	id: string;
	tagName: string;
	tagDescription: string;
	preview: string;
}

export function ContractDocEditor() {
	const editorRef = useRef<TinyMCEEditor | null>(null);

	const params = useParams();
	const { setLoading } = useStore();
	const { fillableFields, setFillableFields, setTags } = useContract();
	const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
	const foldersViewIndex = useMemo(
		() =>
			ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
				x.hasOwnProperty("FOLDERS_VIEW")
			),
		[ROLE_PERMISSIONS]
	);

	const [initialContractDocContent, setInitialContractDocContent] =
		useState("");
	const [contractDocContent, setContractDocContent] = useState("<p></p>");
	const [contractData, setContractData] = useState<IContract | null>(null);
	const [resolutionFields, setResolutionFields] = useState<IFillableFields[]>(
		[]
	);
	const [selectedSigners, setSelectedSigners] = useState<ISignerArr[]>([]);
	const [conversionLoading, setConversionLoading] = useState(false);
	const [currency, setCurrency] = useState("");
	const [feeCalculatorAdded, setFeeCalculatorAdded] = useState(false);

	const { loading: gettingCurrency } = useQuery(GET_PROJ_DEETS_CURRENCY, {
		variables: { id: params?.project_id },
		skip:
			!params?.project_id ||
			!(
				Boolean(ROLE_PERMISSIONS) &&
				foldersViewIndex !== undefined &&
				ROLE_PERMISSIONS[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW
			),
		onCompleted: (data) => {
			const { project } = data;
			const { status, project: rawProject } = project;
			if (status && Boolean(rawProject)) {
				let currencyUnit = "AUD";
				if (rawProject?.currency) {
					currencyUnit = rawProject.currency;
				}

				setCurrency((CurrencyMapping as any)[currencyUnit]);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	const { loading, refetch } = useQuery(GET_CONTRACT_FILE, {
		variables: { payload: { documentId: params?.contract_id } },
		skip:
			!params?.contract_id ||
			!(
				Boolean(ROLE_PERMISSIONS) &&
				foldersViewIndex !== undefined &&
				ROLE_PERMISSIONS[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW
			),
		onCompleted: (data) => {
			setLoading(false);
			const { getDocumentFile } = data;
			const { status, document, file, fileExists, signedFile } =
				getDocumentFile;
			if (status) {
				setContractData({ ...document, file, fileExists, signedFile });
				if (Boolean(file)) {
					setConversionLoading(true);
					fetch(file!)
						.then((response) => {
							if (!response.ok) {
								throw new Error("Network response was not ok");
							}
							return response.text(); // Convert response to text
						})
						.then((htmlString) => {
							// Use the HTML string here
							setContractDocContent(htmlString);
							setInitialContractDocContent(htmlString);

							let parser = new DOMParser();
							const doc = parser.parseFromString(
								htmlString,
								"text/html"
							);

							if (Boolean(doc.getElementById("fillable-fields"))) {
								const fillableFieldsJSONStr =
									doc.getElementById("fillable-fields")?.innerText;
								let tempFillFields = JSON.parse(fillableFieldsJSONStr!);
								setFillableFields([...tempFillFields]);
								let tempResFields = JSON.parse(fillableFieldsJSONStr!);
								tempResFields = tempResFields.filter((x: any) =>
									Boolean(x.fieldResolution)
								);
								setResolutionFields([...tempResFields]);
								if (tempResFields.length > 0) {
									getResValues();
								}
							}

							if (Boolean(doc.getElementById("finka-tags"))) {
								const fillableFieldsJSONStr =
									doc.getElementById("finka-tags")?.innerText;
								setTags(JSON.parse(fillableFieldsJSONStr!));
							}

							if (Boolean(doc.getElementById("fee-calculator"))) {
								const feeCalculatorAddedJSON =
									doc.getElementById("fee-calculator")?.innerText;
								setFeeCalculatorAdded(
									JSON.parse(feeCalculatorAddedJSON!)
								);
							}
						})
						.catch((error) => {
							console.error(
								"There was a problem fetching the HTML file:",
								error
							);
							toast.error("File not loaded", { delay: 10 });
						});
					setConversionLoading(false);
				}
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	// TODO: skip when doc status is partially executed or fully executed
	const { loading: gettingResValues, refetch: getResValues } = useQuery(
		GET_RESOLUTION_VALUES,
		{
			variables: {
				payload: resolutionFields.map((x) => {
					const obj = {
						id: x.fieldResolution,
						is_multiple: x.fieldMultiple,
						field_type: x.fieldType,
					};
					return JSON.stringify(obj);
				}),
				project_id: params?.project_id,
				document_id: params?.contract_id,
			},
			skip:
				!Boolean(params?.project_id) ||
				!Boolean(params?.contract_id) ||
				resolutionFields.length === 0 ||
				contractData?.documentStatus === "FULLY_EXECUTED" ||
				contractData?.documentStatus === "PARTIALLY_EXECUTED" ||
				contractData?.documentStatus === "APPROVED" ||
				contractData?.documentStatus === "RESCINDED" ||
				contractData?.documentStatus === "ARCHIVED" ||
				!(
					Boolean(ROLE_PERMISSIONS) &&
					foldersViewIndex !== undefined &&
					ROLE_PERMISSIONS[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW
				),
			onCompleted: (data) => {
				const { getDocumentFillableFields } = data;
				const { status, fields } = getDocumentFillableFields;
				if (status) {
					const parsedFields = Boolean(fields) ? JSON.parse(fields) : null;
					if (Boolean(parsedFields)) {
						let tempFields = [...resolutionFields];
						tempFields = tempFields.map((x) => ({
							...x,
							fieldValue: parsedFields[x.fieldResolution],
						}));
						let tempFillFields = [...fillableFields];
						tempFillFields = tempFillFields.map((x) => {
							if (Boolean(x.fieldResolution)) {
								const ele = editorRef?.current?.dom.get(x.id)!;
								if (Boolean(ele)) {
									if (!x.fieldMultiple) {
										if (x.fieldType === "image") {
											if (
												Boolean(
													tempFields.find((y) => y.id === x.id)
														?.fieldValue!
												) &&
												tempFields.find((y) => y.id === x.id)
													?.fieldValue! !== "Not Provided"
											) {
												editorRef?.current?.dom?.setAttrib(
													ele,
													"src",
													`data:image/png;base64,${tempFields.find(
														(y) => y.id === x.id
													)?.fieldValue!}`
												);
											}
										} else if (x.fieldType === "table") {
											const rows = ele.getElementsByTagName("tr");

											Array.from(rows).forEach(
												(row: any, index: any) => {
													if (index !== 0) {
														row.remove();
													}
												}
											);

											const resValue = tempFields.find(
												(y) => y.id === x.id
											)?.fieldValue;

											if (Boolean(resValue)) {
												(resValue as any).forEach((item: any) => {
													if (Boolean(item.details)) {
														const subRights = JSON.parse(
															item.details
														);
														if (Boolean(subRights)) {
															let is_included = false;
															for (
																let i = 0;
																i < subRights.length;
																i++
															) {
																if (
																	subRights[i]
																		.aggregate_inclusion
																) {
																	is_included = true;
																	break;
																}
															}

															const newRow =
																document.createElement("tr");

															const rightsCell =
																document.createElement("td");
															rightsCell.style.cssText = `border:1px black solid; border-bottom:none; vertical-align:center; word-wrap: break-word; font-family:arial; font-size:10pt; ${
																!is_included &&
																"text-decoration: line-through;"
															}`;
															rightsCell.textContent =
																item.rightsUsage;
															newRow.appendChild(rightsCell);

															const loadingCell =
																document.createElement("td");
															loadingCell.style.cssText = `color:transparent; border:1px black solid; border-bottom:none; vertical-align:center; text-align:center; word-wrap: break-word; font-family:arial; font-size:10pt; ${
																!is_included &&
																"text-decoration: line-through;"
															}`;
															loadingCell.textContent =
																item.loading;
															newRow.appendChild(loadingCell);

															ele.appendChild(newRow);

															subRights.forEach((x: any) => {
																const newRow2 =
																	document.createElement("tr");

																const rightsCell2 =
																	document.createElement("td");
																rightsCell2.style.cssText = `padding-left:50px; border:none; border-right:1px black solid; vertical-align:center; word-wrap: break-word; font-family:arial; font-size:10pt; ${
																	!x.aggregate_inclusion &&
																	"text-decoration: line-through;"
																}`;
																rightsCell2.textContent =
																	x.title;
																newRow2.appendChild(
																	rightsCell2
																);

																const loadingCell2 =
																	document.createElement("td");
																loadingCell2.style.cssText = `border:none; vertical-align:center; text-align:center; word-wrap: break-word; font-family:arial; font-size:10pt; ${
																	!x.aggregate_inclusion &&
																	"text-decoration: line-through;"
																}`;
																loadingCell2.textContent =
																	x.loading;
																newRow2.appendChild(
																	loadingCell2
																);

																ele.appendChild(newRow2);
															});
														} else {
															const newRow =
																document.createElement("tr");

															// Create first cell
															const rightsCell =
																document.createElement("td");
															rightsCell.style.cssText = `border:1px black solid; vertical-align:center; word-wrap: break-word; font-family:arial; font-size:10pt; ${
																!item.aggregate_inclusion &&
																"text-decoration: line-through;"
															}`;
															rightsCell.textContent =
																item.rightsUsage;
															newRow.appendChild(rightsCell);

															// Create second cell
															const loadingCell =
																document.createElement("td");
															loadingCell.style.cssText = `border:1px black solid; vertical-align:center; text-align:center; word-wrap: break-word; font-family:arial; font-size:10pt; ${
																!item.aggregate_inclusion &&
																"text-decoration: line-through;"
															}`;
															loadingCell.textContent =
																item.loading;
															newRow.appendChild(loadingCell);

															ele.appendChild(newRow);
														}
													} else {
														const newRow =
															document.createElement("tr");

														// Create first cell
														const rightsCell =
															document.createElement("td");
														rightsCell.style.cssText = `border:1px black solid; vertical-align:center; word-wrap: break-word; font-family:arial; font-size:10pt; ${
															!item.aggregate_inclusion &&
															"text-decoration: line-through;"
														}`;
														rightsCell.textContent =
															item.rightsUsage;
														newRow.appendChild(rightsCell);

														// Create second cell
														const loadingCell =
															document.createElement("td");
														loadingCell.style.cssText = `border:1px black solid; vertical-align:center; text-align:center; word-wrap: break-word; font-family:arial; font-size:10pt; ${
															!item.aggregate_inclusion &&
															"text-decoration: line-through;"
														}`;
														loadingCell.textContent =
															item.loading;
														newRow.appendChild(loadingCell);

														ele.appendChild(newRow);
													}
												});
											}
										} else if (x.fieldType === "currency") {
											ele.innerText =
												tempFields.find((y) => y.id === x.id)
													?.fieldValue! === "Not Provided"
													? ""
													: `${currency}${parseFloat(
															tempFields.find(
																(y) => y.id === x.id
															)?.fieldValue!
													  ).toLocaleString()}`;
										} else if (x.fieldType === "percentage") {
											ele.innerText =
												tempFields.find((y) => y.id === x.id)
													?.fieldValue! === "Not Provided"
													? ""
													: `${parseFloat(
															tempFields.find(
																(y) => y.id === x.id
															)?.fieldValue!
													  )}%`;
										} else {
											ele.innerText = tempFields.find(
												(y) => y.id === x.id
											)?.fieldValue!;
										}
										return {
											...x,
											fieldValue:
												(x.fieldType === "currency" ||
													x.fieldType === "percentage") &&
												tempFields.find((y) => y.id === x.id)
													?.fieldValue! !== "Not Provided"
													? parseFloat(
															tempFields.find(
																(y) => y.id === x.id
															)?.fieldValue!
													  ).toFixed(2)
													: tempFields.find((y) => y.id === x.id)
															?.fieldValue!,
										};
									} else {
										if (
											tempFields.find((y) => y.id === x.id)
												?.fieldValue! === "Not Provided"
										) {
											ele.innerText = tempFields.find(
												(y) => y.id === x.id
											)?.fieldValue!;
										}
										return {
											...x,
											...(tempFields.find((y) => y.id === x.id)
												?.fieldValue! === "" ||
											tempFields.find((y) => y.id === x.id)
												?.fieldValue! === "Not Provided"
												? {
														fieldValue: tempFields.find(
															(y) => y.id === x.id
														)?.fieldValue!,
														fieldArr: [],
												  }
												: {
														fieldArr: tempFields.find(
															(y) => y.id === x.id
														)
															?.fieldValue! as unknown as IPerson[],
												  }),
										};
									}
								} else {
									return { ...x };
								}
							} else {
								return { ...x };
							}
						});
						setFillableFields([...tempFillFields]);
					}
				}
			},
			fetchPolicy: "network-only",
			nextFetchPolicy: "network-only",
		}
	);

	const checkFillableFields = (value: any, editor: TinyMCEEditor) => {
		setContractDocContent(value);
	};

	useEffect(() => {
		setLoading(
			conversionLoading || gettingCurrency || gettingResValues || loading
		);
	}, [
		conversionLoading,
		gettingCurrency,
		gettingResValues,
		loading,
		setLoading,
	]);

	useEffect(() => {
		setFillableFields([]);
		setTags([]);
	}, [setFillableFields, setTags]);

	return (
		<EditorFrame
			ref={editorRef}
			contractData={contractData}
			refetch={refetch}
			selectedSigners={selectedSigners}
			setSelectedSigners={setSelectedSigners}
			initialContractDocContent={initialContractDocContent}
			currency={currency}
			feeCalculatorAdded={feeCalculatorAdded}
		>
			<Editor
				tinymceScriptSrc={
					process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
				}
				// disabled={true}
				onInit={(evt, editor) => {
					editorRef.current = editor;
				}}
				onEditorChange={checkFillableFields}
				apiKey="gjhka7xjgv6i3e4hhrfl0xxyfm6xlptxnbkq1h4vh8ahqqw3"
				value={contractDocContent}
				init={{
					promotion: false,
					plugins:
						"preview importcss searchreplace autolink save directionality code visualblocks visualchars link codesample table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion",
					// editimage_cors_hosts: ["picsum.photos"],
					// menubar: false,
					// toolbar: false,
					// autosave_ask_before_unload: true,
					// autosave_interval: "30s",
					// autosave_prefix: "{path}{query}-{id}-",
					// autosave_restore_when_empty: false,
					// autosave_retention: "2m",
					// image_advtab: true,
					link_list: [
						{ title: "My page 1", value: "https://www.tiny.cloud" },
						{ title: "My page 2", value: "http://www.moxiecode.com" },
					],
					// image_list: [
					//   { title: "My page 1", value: "https://www.tiny.cloud" },
					//   { title: "My page 2", value: "http://www.moxiecode.com" },
					// ],
					// image_class_list: [
					//   { title: "None", value: "" },
					//   { title: "Some class", value: "class-name" },
					// ],
					insertdatetime_formats: [
						"%d %b %Y",
						"%d/%m/%Y",
						"%H:%M:%S",
						"%I:%M:%S %p",
					],
					importcss_append: true,
					// file_picker_callback: (callback, value, meta) => {
					//   if (meta.filetype === "file") {
					//     callback("https://www.google.com/logos/google.jpg", {
					//       text: "My text",
					//     });
					//   }

					//   if (meta.filetype === "image") {
					//     callback("https://www.google.com/logos/google.jpg", {
					//       alt: "My alt text",
					//     });
					//   }

					//   if (meta.filetype === "media") {
					//     callback("movie.mp4", {
					//       source2: "alt.ogg",
					//       poster: "https://www.google.com/logos/google.jpg",
					//     });
					//   }
					// },
					// templates: [
					//   {
					//     title: "Input Text Field",
					//     description: "Creates a new input field",
					//     content:
					//       '<div class="mceTmpl finka finka-textfield"><input id="finka-textfield" type="text" style="font-family:Helvetica,Arial,sans-serif; font-size:14px; padding:8px;" /></div>',
					//   },
					//   {
					//     title: "Input Date Field",
					//     description: "Creates a new date field",
					//     content:
					//       '<div class="mceTmpl finka finka-datefield"><input id="finka-datefield" type="date" style="font-family:Helvetica,Arial,sans-serif; font-size:14px; padding:8px;" /></div>',
					//   },
					//   {
					//     title: "Signature Field",
					//     description: "Creates a signature field",
					//     content:
					//       '<div class="mceTmpl finka finka-signfield"><input type="file" accept="image/*" /></div>',
					//   },
					// ],
					// image_caption: true,
					noneditable_class: "mceNonEditable",
					editable_class: "mceEditable",
					toolbar_mode: "sliding",
					skin: "oxide",
					content_css: "default",
					content_style: "body { font-family:arial; font-size:10pt }",
					branding: false,
					elementpath: false,
					resize: false,
					help_accessibility: false,
					height: "calc(100vh - 65px)",
					width: "100%",
				}}
			/>
		</EditorFrame>
	);
}
