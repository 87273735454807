export enum colors {
  primary = "#0176D3",
  primary2 = "#0B69B4",
  secondary = "#ffffff",
  whiteGrey400 = "#9A9494",
  whiteGrey500 = "#5E5B5B",
  iconColor = "#848588",
  primaryBlack = "#000000",
  secondaryBlack = "#0f0f0f",
  grey = "#737373",
  success = "#0E8752",
  error = "#E44258",
  borderColor = "#E8ECEE",
}
