import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { LoginLabels, ResetPwdLabels } from "common/AppConstants";
import { images } from "assets/images";
import { ResetPwdSuccessStyles as styles } from "./styles";

const SuccessAcknowledgement = () => {
	const navigate = useNavigate();

	return (
		<Grid
			container
			spacing={2}
			sx={{ px: { xs: 2, sm: "auto" }, py: { xs: 4, sm: "auto" } }}
		>
			<Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
				<img src={images.LOGO} alt="logo" height="30px" />
			</Grid>
			<Grid
				item
				lg={12}
				md={12}
				sm={12}
				xs={12}
				sx={[styles.content_center, { mt: 5 }]}
			>
				<img src={images.GREEN_TICK} alt="success" />
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
				<Typography sx={styles.success_text}>
					{ResetPwdLabels.SUCCESS}
				</Typography>
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
				<Box>
					<Typography sx={styles.congrats_text}>
						You can now use your new password to log in to your account.
					</Typography>
				</Box>
			</Grid>
			<Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
				<Box>
					<Button
						variant="contained"
						size="small"
						sx={styles.login_btn}
						onClick={() => navigate("/")}
					>
						{LoginLabels.LOGIN}
					</Button>
				</Box>
			</Grid>
		</Grid>
	);
};

export default SuccessAcknowledgement;
