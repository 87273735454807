import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Add, AddCircle } from "@mui/icons-material";
import { useQuery } from "@apollo/client";

import Drawer from "common/ClientDrawer";
import { NewOutlinedBtn } from "common/Buttons";
import { IAgent } from "models/agents";
import { GET_ALL_AGENTS } from "graphql/agents";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import AgentCard from "./AgentCard";
import AddAgentModal from "./AddAgentModal";
import EditAgentModal from "./EditAgentModal";
import DeleteAgentModal from "./DeleteAgentModal";

export function Agents() {
  const { setLoading } = useStore();

  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const agentsViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("AGENTS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const agentsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("AGENTS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [addAgentModal, setAddAgentModal] = useState(false);
  const [agents, setAgents] = useState<IAgent[]>([]);
  const [agentsCount, setAgentsCount] = useState(0);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [selectedAgentName, setSelectedAgentName] = useState("");
  const [deleteAgentModal, setDeleteAgentModal] = useState(false);
  const [agentInfoModal, setAgentInfoModal] = useState(false);
  const [editAgentModal, setEditAgentModal] = useState(false);

  const { loading, refetch, fetchMore } = useQuery(GET_ALL_AGENTS, {
    variables: {
      limit: 60,
      page: 1,
      sorted: "company_name",
    },
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      agentsViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[agentsViewIndex]?.AGENTS_VIEW
    ),
    onCompleted: (data) => {
      setLoading(false);
      const { getAllAgents } = data;
      const { status, agents, count } = getAllAgents;
      if (status) {
        setAgentsCount(count);
        setAgents(agents);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  // useEffect(() => {
  //   const handleScroll = async () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight
  //     ) {
  //       return;
  //     }
  //     if (agents.length < agentsCount) {
  //       try {
  //         setLoading(true);
  //         await fetchMore({
  //           variables: {
  //             page: Math.ceil(agents.length / 60) + 1,
  //             limit: 60,
  //           },
  //           updateQuery: (previousResult, { fetchMoreResult }) => {
  //             const newEntries = fetchMoreResult.getAllAgents.agents;
  //             return {
  //               getAllAgents: {
  //                 ...fetchMoreResult?.getAllAgents,
  //                 agents: [...agents, ...newEntries],
  //               },
  //             };
  //           },
  //         });
  //       } catch (error) {
  //         console.error("ERROR", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [agentsCount, fetchMore, agents, setLoading]);
  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (agents.length < agentsCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(agents.length / 60) + 1,
              limit: 60,
              sorted: "company_name",
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.getAllAgents.agents;
              return {
                getAllAgents: {
                  ...fetchMoreResult?.getAllAgents,
                  agents: [...agents, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchMore, agentsCount, agents, setLoading]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Drawer>
      <Grid container spacing={2}>
        {Boolean(ROLE_PERMISSIONS) &&
          agentsEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[agentsEditIndex]?.AGENTS_EDIT && (
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0.25 }}>
              <Box>
                <NewOutlinedBtn
                  disableElevation
                  startIcon={<Add sx={{ color: "#000" }} />}
                  sx={{
                    color: "#000",
                    py: 1,
                    px: 2,
                    fontSize: "14px",
                    borderRadius: "8px",
                  }}
                  onClick={() => setAddAgentModal(true)}
                >
                  New Agent
                </NewOutlinedBtn>
              </Box>
            </Grid>
          )}
        {!loading && agents.length === 0 ? (
          Boolean(ROLE_PERMISSIONS) &&
          agentsEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[agentsEditIndex]?.AGENTS_EDIT ? (
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  cursor: "pointer",
                  my: 1,
                }}
                onClick={() => {
                  setAddAgentModal(true);
                }}
              >
                <Box
                  sx={{
                    border: "3px #BFBDBC dotted",
                    borderRadius: "20px",
                    backgroundColor: `#BFBDBC22`,
                    flexDirection: "column",
                    px: 5,
                    aspectRatio: 0.8 / 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 210,
                    width: 168,
                  }}
                >
                  <AddCircle sx={{ color: "#BFBDBC", fontSize: 65, mb: 2 }} />
                  <Typography
                    sx={{
                      color: `#BFBDBC`,
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                    textAlign="center"
                  >
                    Add Agent
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  height: `calc(100vh - 115px)`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    height: "200px",
                    width: "550px",
                    backgroundColor: "#BFBDBC22",
                    borderRadius: " 24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: " column",
                  }}
                >
                  <img
                    src={images.AGENTS_SELECTED}
                    alt="agent"
                    style={{ width: 25 }}
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.primaryBlack,
                      my: 2,
                      fontWeight: 600,
                    }}
                  >
                    Its empty here!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Please reach out to your admin for access to create an
                    agent.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )
        ) : (
          <></>
        )}
        {!loading &&
          agents.length > 0 &&
          agents.map((agent) => (
            <Grid key={agent.id} item lg={2} md={3} sm={4} xs={6}>
              <AgentCard
                agent={agent}
                setSelectedAgentId={setSelectedAgentId}
                setEditAgentModal={setEditAgentModal}
                setDeleteAgentModal={setDeleteAgentModal}
                setAgentInfoModal={setAgentInfoModal}
                setSelectedAgentName={setSelectedAgentName}
                agentsEditIndex={agentsEditIndex}
                permissions={ROLE_PERMISSIONS}
              />
            </Grid>
          ))}
        {addAgentModal && (
          <AddAgentModal
            open={addAgentModal}
            setOpen={setAddAgentModal}
            refetch={refetch}
          />
        )}
        {editAgentModal && (
          <EditAgentModal
            open={editAgentModal}
            setOpen={setEditAgentModal}
            selectedAgentId={selectedAgentId}
            setSelectedAgentId={setSelectedAgentId}
            refetch={refetch}
          />
        )}
        {deleteAgentModal && (
          <DeleteAgentModal
            open={deleteAgentModal}
            setOpen={setDeleteAgentModal}
            selectedAgentId={selectedAgentId}
            setSelectedAgentId={setSelectedAgentId}
            selectedAgentName={selectedAgentName}
            setSelectedAgentName={setSelectedAgentName}
            refetch={refetch}
          />
        )}
      </Grid>
    </Drawer>
  );
}
