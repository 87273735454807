import jwt_decode from "jwt-decode";

export enum StorageConstants {
  TOKEN = "token",
  DEVICE = "device",
  LOGGED_IN_USER_ID = "current_user_id",
  ACCESS_PERMISSIONS = "permissions",
}

export const getJwtTokenDetails = () => {
  const token = sessionStorage.getItem(StorageConstants.TOKEN) || "";
  let hasExpired = true;
  let isVerified = false;
  let roles: string[] = [];
  let username = "";
  let userid = "";
  let permissions = "";

  if (Boolean(token)) {
    const decoded: any = jwt_decode(token);
    // console.log({ decoded });
    if (Date.now() < decoded.exp * 1000) {
      hasExpired = false;
      if (Boolean(decoded.verified)) {
        isVerified = true;
      }
      if (Boolean(decoded.roles)) {
        roles = decoded.roles;
      }
      if (Boolean(decoded.username)) {
        username = decoded.username;
      }
      if (Boolean(decoded.customer)) {
        userid = decoded.customer;
      }
      if (Boolean(decoded.permissions)) {
        permissions = decoded.permissions;
      }
    }
  }

  return { hasExpired, isVerified, roles, username, userid, permissions };
};

export const clearSession = () => {
  [
    StorageConstants.TOKEN,
    StorageConstants.LOGGED_IN_USER_ID,
    StorageConstants.ACCESS_PERMISSIONS,
  ].forEach((k) => sessionStorage.removeItem(k));
};
