import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import {
  FiberManualRecord,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import { GridRenderCellParams } from "@mui/x-data-grid";

import { GreenChipBtn, RedChipBtn, YellowChipBtn } from "common/Buttons";
import { IssueStatusArr } from "utils/constants";
import { getPermissions } from "permissions/utils";

import { ProjectDetailsStyles as styles } from "../styles";

interface ITableComponentProps {
  params: GridRenderCellParams<any>;
  fireIssueStatusUpdateApi: any;
}

function TableComponent(props: ITableComponentProps) {
  const { params, fireIssueStatusUpdateApi } = props;

  const projectId = useParams();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const AllIssuesEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("ISSUES_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [openStatusMenu, setOpenStatusMenu] = useState(false);
  const [statusAnchor, setStatusAnchor] = useState<HTMLElement | null>(null);

  const handleStatusClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    params1: any
  ) => {
    setOpenStatusMenu(true);
    setStatusAnchor(e.currentTarget);
  };

  const handleStatusClose = () => {
    setOpenStatusMenu(false);
    setStatusAnchor(null);
  };

  return (
    <>
      {Boolean(params?.value) ? (
        <>
          {params?.value === "OPEN" ? (
            <GreenChipBtn
              size="small"
              aria-controls={openStatusMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openStatusMenu ? "true" : undefined}
              onClick={(e) => {
                e.stopPropagation();
                handleStatusClick(e, params);
              }}
              disabled={
                !(
                  Boolean(ROLE_PERMISSIONS) &&
                  AllIssuesEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]
                    ?.ISSUES_EDIT
                )
              }
              endIcon={
                Boolean(ROLE_PERMISSIONS) &&
                AllIssuesEditIndex !== undefined &&
                ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]
                  ?.ISSUES_EDIT ? (
                  <KeyboardArrowDownOutlined
                    fontSize="small"
                    sx={{
                      color: "#28C76F",
                    }}
                  />
                ) : null
              }
              sx={{ "&.Mui-disabled": { color: "#28C76F" } }}
            >
              {IssueStatusArr.find((x) => x.id === params?.value)?.name || ""}
            </GreenChipBtn>
          ) : params?.value === "REOPEN" ? (
            <YellowChipBtn
              size="small"
              aria-controls={openStatusMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openStatusMenu ? "true" : undefined}
              onClick={(e) => {
                e.stopPropagation();
                handleStatusClick(e, params);
              }}
              disabled={
                !(
                  Boolean(ROLE_PERMISSIONS) &&
                  AllIssuesEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]
                    ?.ISSUES_EDIT
                )
              }
              endIcon={
                Boolean(ROLE_PERMISSIONS) &&
                AllIssuesEditIndex !== undefined &&
                ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]
                  ?.ISSUES_EDIT ? (
                  <KeyboardArrowDownOutlined
                    fontSize="small"
                    sx={{
                      color: "#FFA500",
                    }}
                  />
                ) : null
              }
              sx={{ "&.Mui-disabled": { color: "#FFA500" } }}
            >
              {IssueStatusArr.find((x) => x.id === params?.value)?.name || ""}
            </YellowChipBtn>
          ) : (
            <RedChipBtn
              size="small"
              aria-controls={openStatusMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openStatusMenu ? "true" : undefined}
              onClick={(e) => {
                e.stopPropagation();
                handleStatusClick(e, params);
              }}
              disabled={
                !(
                  Boolean(ROLE_PERMISSIONS) &&
                  AllIssuesEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]
                    ?.ISSUES_EDIT
                )
              }
              endIcon={
                Boolean(ROLE_PERMISSIONS) &&
                AllIssuesEditIndex !== undefined &&
                ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]
                  ?.ISSUES_EDIT ? (
                  <KeyboardArrowDownOutlined
                    fontSize="small"
                    sx={{
                      color: "#D72C51",
                    }}
                  />
                ) : null
              }
              sx={{ "&.Mui-disabled": { color: "#D72C51" } }}
            >
              {IssueStatusArr.find((x) => x.id === params?.value)?.name || ""}
            </RedChipBtn>
          )}
          <Menu
            id="basic-menu"
            anchorEl={statusAnchor}
            open={openStatusMenu}
            onClose={handleStatusClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={styles.menu_container}
            elevation={0}
          >
            <MenuList>
              {IssueStatusArr.map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={() => {
                    fireIssueStatusUpdateApi({
                      variables: {
                        payload: {
                          projectId: projectId?.project_id,
                          issueId: params?.row?.id,
                          status: item.id,
                        },
                      },
                    });
                    handleStatusClose();
                  }}
                >
                  <ListItemIcon>
                    <FiberManualRecord
                      fontSize="small"
                      sx={{
                        color:
                          item.id === "OPEN"
                            ? "#28C76F"
                            : item.id === "REOPEN"
                            ? "#FFA500"
                            : "#D72C51",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={styles.field_label}>
                    {item.name}
                  </ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default TableComponent;
