import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid } from "@mui/material";
import { FormikProps } from "formik";

import { ICreateContactFieldsNew } from "models/contacts";

import AgencyLeftSteps from "./AgencyLeftSteps";
import AgencyDetailsForm from "./AgencyDetailsForm";
import RegistrationForm from "./RegistrationForm";
import BankDetailsForm from "./BankDetailsForm";

interface IAgencyFormProps {
  formikBag: FormikProps<ICreateContactFieldsNew>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  activeSection: number;
  setActiveSection: Dispatch<SetStateAction<number>>;
  setPrevActiveSection: Dispatch<SetStateAction<number>>;
  setNewAgent: Dispatch<SetStateAction<boolean>>;
}

function AgencyForm(props: IAgencyFormProps) {
  const {
    formikBag,
    setActiveStep,
    activeSection,
    setActiveSection,
    setPrevActiveSection,
    setNewAgent,
  } = props;

  return (
    <Box sx={{ height: "100%" }}>
      <Grid container>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={3}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Box sx={{ pt: 3 }}>
            <AgencyLeftSteps
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              formikBag={formikBag}
            />
          </Box>
        </Grid>
        <Grid item lg={9} md={9} sm={9} xs={12}>
          <Box
            sx={{
              borderLeft: { xs: "none", sm: "1.5px #e6e6e6 solid" },
              height: { xs: "546.02px", sm: "731.02px" },
            }}
          >
            {activeSection === 0 && (
              <AgencyDetailsForm
                formikBag={formikBag}
                setActiveSection={setActiveSection}
                setActiveStep={setActiveStep}
                setPrevActiveSection={setPrevActiveSection}
                setNewAgent={setNewAgent}
              />
            )}
            {activeSection === 1 && (
              <RegistrationForm
                formikBag={formikBag}
                setActiveSection={setActiveSection}
              />
            )}
            {activeSection === 2 && (
              <BankDetailsForm
                formikBag={formikBag}
                setActiveSection={setActiveSection}
                setActiveStep={setActiveStep}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AgencyForm;
