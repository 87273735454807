import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Box, Dialog, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { IModalProps } from "models/common";
import { images } from "assets/images";
import { GlobalLabels, ManageTemplatesLabels } from "common/AppConstants";
import { OutlinedBtn, RedContainedBtn } from "common/Buttons";
import { colors } from "theme/colors";
import { DELETE_ADMIN } from "graphql/admin";
import { useStore } from "utils/store";

const styles = {
  alert_img: { width: "80px", alignSelf: " center", paddingTop: 40 },
  logout_text: {
    textAlign: "center",
    fontSize: "24px",
    color: colors.primaryBlack,
    fontWeight: 600,
    py: 2,
  },
  logout_desc: {
    textAlign: "center",
    fontSize: "18px",
    color: colors.secondaryBlack,
    pb: 2,
    px: 4,
  },
  actions_container: {
    display: "flex",
    justifyContent: "space-between",
    pb: 4,
    px: 4,
  },
  btn: { fontSize: "16px", width: "45%", fontWeight: 500 },
  noBtn: { color: colors.secondaryBlack },
};

interface IDeleteAdminModal extends IModalProps {
  selectedAdmin: string;
  selectedAdminName: string;
  refetchTableData: any;
  setSelectedAdmin: Dispatch<SetStateAction<string>>;
  setSelectedAdminName: Dispatch<SetStateAction<string>>;
}

const DeleteAdminModal = (props: IDeleteAdminModal) => {
  const {
    open,
    setOpen,
    selectedAdmin,
    selectedAdminName,
    refetchTableData,
    setSelectedAdmin,
    setSelectedAdminName,
  } = props;

  const { setLoading } = useStore();

  const handleClose = () => {
    setSelectedAdmin("");
    setSelectedAdminName("");
    setOpen(false);
  };

  const [fireDeleteAdminApi, { loading }] = useMutation(DELETE_ADMIN, {
    onCompleted: (data) => {
      setLoading(false);
      const { deleteUser } = data;
      const { status, error } = deleteUser;
      if (status) {
        refetchTableData();
        toast.success(error, { delay: 10 });
      } else {
        toast.error(error, { delay: 10 });
      }
      handleClose();
    },
  });

  const handleDelete = () => {
    fireDeleteAdminApi({ variables: { ids: [selectedAdmin] } });
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <img src={images.ALERT_RED} alt="alert" style={styles.alert_img} />
      <Typography sx={styles.logout_text}>
        {ManageTemplatesLabels.DEL_TEMP}
      </Typography>
      <Typography sx={styles.logout_desc}>
        Are you sure that you want to delete{" "}
        <span
          style={{ color: colors.primary }}
        >{`"${selectedAdminName}"`}</span>{" "}
        access?
      </Typography>
      <Box sx={styles.actions_container}>
        <OutlinedBtn
          variant="outlined"
          sx={[styles.btn, styles.noBtn]}
          onClick={handleClose}
        >
          {GlobalLabels.CANCEL}
        </OutlinedBtn>
        <RedContainedBtn
          variant="contained"
          sx={styles.btn}
          onClick={handleDelete}
        >
          {GlobalLabels.DELETE}
        </RedContainedBtn>
      </Box>
    </Dialog>
  );
};

export default DeleteAdminModal;
