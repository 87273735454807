import React, { useEffect, useMemo } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Add, FiberManualRecord, OpenInNew } from "@mui/icons-material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { useStore } from "utils/store";
import {
  DISCONNECT_DOCUSIGN,
  GET_CONNECT_DOCUSIGN_URL,
} from "graphql/integrations";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";

interface IIntegrationCardProps {
  docuSignConnected: boolean;
  refetch: any;
}

function IntegrationCard(props: IIntegrationCardProps) {
  const { docuSignConnected, refetch } = props;

  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const integrationsEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("INTEGRATIONS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [getDSuri, { loading: gettingDSuri }] = useLazyQuery(
    GET_CONNECT_DOCUSIGN_URL,
    {
      onCompleted: (data) => {
        const { getConnectUrlDocusign } = data;
        const { status, message, url } = getConnectUrlDocusign;
        if (status) {
          window.open(url);
        } else {
          toast.error(message, { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [disconnectDS, { loading: disconnecting }] = useMutation(
    DISCONNECT_DOCUSIGN,
    {
      onCompleted: (data) => {
        const { disconnectDocusign } = data;
        const { status, message } = disconnectDocusign;
        if (status) {
          refetch();
          toast.success(message);
        } else {
          toast.error(message);
        }
      },
    }
  );

  useEffect(() => {
    setLoading(gettingDSuri || disconnecting);
  }, [disconnecting, gettingDSuri, setLoading]);

  return (
    <Box
      sx={{
        border: "1px #D9D9D8 solid",
        borderRadius: "15px",
        p: 2,
        width: { xs: "100%", sm: "350px" },
      }}
    >
      <Grid container spacing={1}>
        <Grid item flexGrow={1}>
          <img src={images.DOCUSIGN_LOGO_NEW} alt="docusign" height="40px" />
        </Grid>
        <Grid item>
          <Box
            sx={{
              backgroundColor: docuSignConnected ? "#41B19931" : "#DE324C31",
              pt: 0.75,
              pb: 0.5,
              pl: 1,
              pr: 1.5,
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FiberManualRecord
              sx={{
                fontSize: 10,
                color: docuSignConnected ? "#41B19995" : "#DE324C95",
                mr: 1,
              }}
            />
            <Typography sx={{ fontSize: "11px", color: "#00000070" }}>
              {docuSignConnected ? "Connected" : "Not Connected"}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={{ fontSize: "22px", color: "#000", fontWeight: 600 }}>
            Docusign
          </Typography>
          <Box
            sx={{
              mt: 0.25,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => window.open("https://www.docusign.com/", "_blank")}
          >
            <Typography sx={{ fontSize: "12px", color: "#00000060" }}>
              docusign.com
            </Typography>
            <OpenInNew sx={{ color: "#00000060", fontSize: 12, ml: 0.5 }} />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={{ fontSize: "14px", color: "#00000075" }}>
            Securely send, sign, and manage your documents electronically
          </Typography>
        </Grid>
        {Boolean(ROLE_PERMISSIONS) &&
          integrationsEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[integrationsEditIndex]
            ?.INTEGRATIONS_EDIT && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {docuSignConnected ? (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    borderColor: "#DE324C95",
                    borderRadius: "20px",
                    ":hover": {
                      borderColor: "#DE324C95",
                      backgroundColor: "#DE324C10",
                    },
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 600,
                    px: 2,
                  }}
                  startIcon={
                    <img
                      src={images.NO_CONNECTION}
                      alt="no connection"
                      height="13px"
                    />
                  }
                  disableElevation
                  disableRipple
                  onClick={() => disconnectDS({ variables: {} })}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    borderColor: "#41B199",
                    borderRadius: "20px",
                    ":hover": {
                      borderColor: "#41B199",
                      backgroundColor: "#41B19910",
                    },
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: 600,
                    pr: 2,
                  }}
                  startIcon={<Add sx={{ color: "#41B199" }} />}
                  disableElevation
                  disableRipple
                  onClick={() => {
                    getDSuri({ variables: {} });
                  }}
                >
                  Connect
                </Button>
              )}
            </Grid>
          )}
      </Grid>
    </Box>
  );
}

export default IntegrationCard;
