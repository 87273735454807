import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useQuery } from "@apollo/client";

import { GET_GLOBAL_SEARCH_RESULTS } from "graphql/global_search";
import { IModalProps } from "models/common";
import { colors } from "theme/colors";

import ProjectSearchCard from "./ProjectSearchCard";
import ContactSearchCard from "./ContactSearchCard";
import DocumentSearchCard from "./DocumentSearchCard";
import FolderSearchCard from "./FolderSearchCard";
import ClearanceSearchCard from "./ClearanceSearchCard";
import { ISearchResults } from "./utils";

const filters = [
  "All",
  "Projects",
  "Contacts",
  "Documents",
  "Folders",
  "Clearances",
];

function GlobalSearchModal(props: IModalProps) {
  const { open, setOpen } = props;

  const [searchText, setSearchText] = useState("");
  const [searchTextApi, setSearchTextApi] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [searchResults, setSearchResults] = useState<ISearchResults>({
    projects: [],
    contacts: [],
    documents: [],
    folders: [],
    clearances: [],
  });

  const {} = useQuery(GET_GLOBAL_SEARCH_RESULTS, {
    variables: { query: searchTextApi },
    skip: searchTextApi.length < 3,
    onCompleted: (data) => {
      const { globalSearch } = data;
      const { status, projects, contacts, documents, registers, clearances } =
        globalSearch;
      if (status) {
        setSearchResults({
          projects: projects || [],
          contacts: contacts || [],
          documents: documents || [],
          folders: registers || [],
          clearances: clearances || [],
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setOpen(false);
    setSearchText("");
    setSearchTextApi("");
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchTextApi(e.target.value);
    }, 300);
  };

  useEffect(() => {
    if (searchTextApi.length < 3) {
      setSearchResults({
        projects: [],
        contacts: [],
        documents: [],
        folders: [],
        clearances: [],
      });
    }
  }, [searchTextApi]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      disableRestoreFocus
      PaperProps={{ sx: { borderRadius: "24px" } }}
    >
      <DialogTitle>
        <TextField
          value={searchText}
          onChange={handleSearchChange}
          autoFocus={open}
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  sx={{ color: `${colors.primaryBlack}30`, fontSize: 36 }}
                />
              </InputAdornment>
            ),
          }}
          inputProps={{ style: { fontSize: "32px" } }}
          placeholder="Search"
        />
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0, overflow: "hidden" }}>
        <Grid container spacing={1.5}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ mt: 2.5, mx: 2.5 }}>
              <Typography
                sx={{ fontSize: "14px", color: `${colors.primaryBlack}70` }}
              >
                Filter By
              </Typography>
            </Box>
          </Grid>
          {filters.map((x, index) => (
            <Grid item lg={2} md={2} sm={4} xs={6} key={x}>
              <Box
                component="div"
                onClick={() => setSelectedFilter(x)}
                sx={{
                  p: 1,
                  borderRadius: "8px",
                  cursor: "pointer",
                  ...(x !== selectedFilter && {
                    border: `1px ${colors.primaryBlack}15 solid`,
                    ":hover": { backgroundColor: "#B6BCC320" },
                  }),
                  ...(x === selectedFilter && {
                    backgroundColor: `#41B19930`,
                    ":hover": { backgroundColor: "#41B19995" },
                  }),
                  ...(index === 0 && { ml: 2.5 }),
                  ...(index === 5 && { mr: 2.5 }),
                  ...(index === 2 && { mr: { xs: 0, sm: 2.5, md: 0 } }),
                  ...(index === 3 && { ml: { xs: 0, sm: 2.5, md: 0 } }),
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color:
                      x === selectedFilter
                        ? colors.primaryBlack
                        : `${colors.primaryBlack}70`,
                    wordBreak: "break-word",
                  }}
                  textAlign="center"
                >
                  {x}
                </Typography>
              </Box>
            </Grid>
          ))}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box sx={{ height: 470, overflow: "auto", p: 2.5 }}>
              <Grid container spacing={1.5}>
                {searchResults.projects.length !== 0 &&
                  selectedFilter === "All" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: `${colors.primaryBlack}70`,
                          fontWeight: 600,
                        }}
                      >
                        Projects
                      </Typography>
                    </Grid>
                  )}
                {(selectedFilter === "All" || selectedFilter === "Projects") &&
                  searchResults.projects.map((sr) => (
                    <Grid item key={sr.id} lg={12} md={12} sm={12} xs={12}>
                      <ProjectSearchCard
                        projectData={sr}
                        handleClose={handleClose}
                      />
                    </Grid>
                  ))}
                {searchResults.contacts.length !== 0 &&
                  selectedFilter === "All" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: `${colors.primaryBlack}70`,
                          fontWeight: 600,
                        }}
                      >
                        Contacts
                      </Typography>
                    </Grid>
                  )}
                {(selectedFilter === "All" || selectedFilter === "Contacts") &&
                  searchResults.contacts.map((sr) => (
                    <Grid item key={sr.id} lg={12} md={12} sm={12} xs={12}>
                      <ContactSearchCard
                        contactData={sr}
                        handleClose={handleClose}
                      />
                    </Grid>
                  ))}
                {searchResults.documents.length !== 0 &&
                  selectedFilter === "All" &&
                  searchResults.documents.some((x) => !x.isFolder) && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: `${colors.primaryBlack}70`,
                          fontWeight: 600,
                        }}
                      >
                        Documents
                      </Typography>
                    </Grid>
                  )}
                {(selectedFilter === "All" || selectedFilter === "Documents") &&
                  searchResults.documents.map((sr) => {
                    if (sr.isFolder) {
                      return null;
                    }
                    return (
                      <Grid item key={sr.id} lg={12} md={12} sm={12} xs={12}>
                        <DocumentSearchCard
                          documentData={sr}
                          handleClose={handleClose}
                        />
                      </Grid>
                    );
                  })}
                {searchResults.folders.length !== 0 &&
                  selectedFilter === "All" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: `${colors.primaryBlack}70`,
                          fontWeight: 600,
                        }}
                      >
                        Folders
                      </Typography>
                    </Grid>
                  )}
                {(selectedFilter === "All" || selectedFilter === "Folders") &&
                  searchResults.folders.map((sr) => (
                    <Grid item key={sr.id} lg={12} md={12} sm={12} xs={12}>
                      <FolderSearchCard
                        folderData={sr}
                        handleClose={handleClose}
                      />
                    </Grid>
                  ))}
                {searchResults.clearances.length !== 0 &&
                  selectedFilter === "All" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: `${colors.primaryBlack}70`,
                          fontWeight: 600,
                        }}
                      >
                        Clearances
                      </Typography>
                    </Grid>
                  )}
                {(selectedFilter === "All" ||
                  selectedFilter === "Clearances") &&
                  searchResults.clearances.map((sr) => (
                    <Grid item key={sr.id} lg={12} md={12} sm={12} xs={12}>
                      <ClearanceSearchCard
                        clearanceData={sr}
                        handleClose={handleClose}
                      />
                    </Grid>
                  ))}
                {searchTextApi.length >= 3 &&
                  selectedFilter === "All" &&
                  searchResults.projects.length === 0 &&
                  searchResults.contacts.length === 0 &&
                  searchResults.documents.length === 0 &&
                  searchResults.folders.length === 0 &&
                  searchResults.clearances.length === 0 && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          height: "430px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Search
                          sx={{
                            color: `${colors.primaryBlack}30`,
                            fontSize: 80,
                          }}
                        />
                        <Typography
                          sx={{
                            mt: 2,
                            color: `${colors.primaryBlack}30`,
                            fontSize: "32px",
                            fontWeight: 500,
                          }}
                          textAlign="center"
                        >
                          No results found
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                {Boolean(searchText) &&
                  selectedFilter === "Projects" &&
                  searchResults.projects.length === 0 && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          height: "430px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Search
                          sx={{
                            color: `${colors.primaryBlack}30`,
                            fontSize: 80,
                          }}
                        />
                        <Typography
                          sx={{
                            mt: 2,
                            color: `${colors.primaryBlack}30`,
                            fontSize: "32px",
                            fontWeight: 500,
                          }}
                          textAlign="center"
                        >
                          No results found
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                {Boolean(searchText) &&
                  selectedFilter === "Contacts" &&
                  searchResults.contacts.length === 0 && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          height: "430px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Search
                          sx={{
                            color: `${colors.primaryBlack}30`,
                            fontSize: 80,
                          }}
                        />
                        <Typography
                          sx={{
                            mt: 2,
                            color: `${colors.primaryBlack}30`,
                            fontSize: "32px",
                            fontWeight: 500,
                          }}
                          textAlign="center"
                        >
                          No results found
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                {Boolean(searchText) &&
                  selectedFilter === "Documents" &&
                  searchResults.documents.length === 0 && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          height: "430px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Search
                          sx={{
                            color: `${colors.primaryBlack}30`,
                            fontSize: 80,
                          }}
                        />
                        <Typography
                          sx={{
                            mt: 2,
                            color: `${colors.primaryBlack}30`,
                            fontSize: "32px",
                            fontWeight: 500,
                          }}
                          textAlign="center"
                        >
                          No results found
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                {Boolean(searchText) &&
                  selectedFilter === "Folders" &&
                  searchResults.folders.length === 0 && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          height: "430px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Search
                          sx={{
                            color: `${colors.primaryBlack}30`,
                            fontSize: 80,
                          }}
                        />
                        <Typography
                          sx={{
                            mt: 2,
                            color: `${colors.primaryBlack}30`,
                            fontSize: "32px",
                            fontWeight: 500,
                          }}
                          textAlign="center"
                        >
                          No results found
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                {Boolean(searchText) &&
                  selectedFilter === "Clearances" &&
                  searchResults.clearances.length === 0 && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          height: "430px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Search
                          sx={{
                            color: `${colors.primaryBlack}30`,
                            fontSize: 80,
                          }}
                        />
                        <Typography
                          sx={{
                            mt: 2,
                            color: `${colors.primaryBlack}30`,
                            fontSize: "32px",
                            fontWeight: 500,
                          }}
                          textAlign="center"
                        >
                          No results found
                        </Typography>
                      </Box>
                    </Grid>
                  )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default GlobalSearchModal;
