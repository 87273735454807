import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import Drawer from "common/ClientDrawer";

import SupportForm from "./SupportForm";
import { images } from "assets/images";

export function Support() {
  return (
    <Drawer>
      <Box sx={{ mx: { xs: 2, sm: 4 } }}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: { xs: 3, sm: 2 },
                flexDirection: "column",
                height: { xs: "auto", sm: "250px" },
                backgroundColor: "#40909135",
                borderRadius: { xs: "15px", sm: "24px" },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={images.SUPPORT} alt="support" width="45" />
                <Typography
                  sx={{
                    ml: 2,
                    fontSize: { xs: "34px", sm: "52px" },
                    color: "#000000",
                  }}
                >
                  Help Centre
                </Typography>
              </Box>
              <Box sx={{ mt: 2.5 }}>
                <Typography
                  sx={{
                    fontSize: { xs: "18px", sm: "22px" },
                    color: "#00000070",
                  }}
                >
                  What do you need help with?
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SupportForm />
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
