import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import { IAgentFields } from "models/agents";
import { useStore } from "utils/store";
import { UPDATE_AGENT } from "graphql/agents";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface IRegistrationFormProps {
  setActiveSection: Dispatch<SetStateAction<number>>;
  profilePicture: any;
  initialValues: IAgentFields;
  refetch: any;
}

function RegistrationForm(props: IRegistrationFormProps) {
  const { setActiveSection, profilePicture, initialValues, refetch } = props;

  const { setLoading } = useStore();

  const [fireUpdateAgentApi, { loading: updating }] = useMutation(
    UPDATE_AGENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateAgentDetails } = data;
        const { status, message } = updateAgentDetails;
        if (status) {
          setActiveSection(2);
          refetch();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleUpdateAgent = (values: IAgentFields) => {
    const { id, abn, acn, companyName } = values;

    fireUpdateAgentApi({
      variables: {
        payload: {
          id,
          companyName,
          abn,
          acn,
          ...(profilePicture === null && { removeProfilePhoto: true }),
        },
        ...(Boolean(profilePicture) &&
          typeof profilePicture !== "string" && {
            profile_picture: profilePicture,
          }),
      },
    });
  };

  useEffect(() => {
    setLoading(updating);
  }, [setLoading, updating]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        abn: Yup.string()
          .matches(/^[0-9]{11}$/, "ABN must be 11 digits")
          .nullable(),
        acn: Yup.string()
          .matches(/^[0-9]{9}$/, "ACN must be 9 digits")
          .nullable(),
      })}
      onSubmit={handleUpdateAgent}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        isValid,
      }) => (
        <Box
          sx={{
            pt: { xs: 2, sm: 14 },
            px: 4,
            pb: 1.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Australian Company Number (ACN)
              </Typography>
              <TextField
                id="acn"
                value={values.acn}
                onChange={(e) => {
                  if (!isNaN(e.target.value as unknown as number)) {
                    if (e.target.value.length <= 9) {
                      setFieldValue("acn", e.target.value);
                    }
                  }
                }}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.acn && (
                <Typography sx={styles.error_text}>{errors?.acn}</Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Australian Business Number (ABN)
              </Typography>
              <TextField
                id="abn"
                value={values.abn}
                onChange={(e) => {
                  if (!isNaN(e.target.value as unknown as number)) {
                    if (e.target.value.length <= 11) {
                      setFieldValue("abn", e.target.value);
                    }
                  }
                }}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.abn && (
                <Typography sx={styles.error_text}>{errors?.abn}</Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
                <OutlinedBtn
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                    color: "#000",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(0)}
                >
                  Back
                </OutlinedBtn>
                <BlackActionContainedButton
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    ml: 1,
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                  }}
                  disableElevation
                  disableRipple
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                >
                  Save and Next
                </BlackActionContainedButton>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

export default RegistrationForm;
