import { colors } from "theme/colors";

export const DocumentReposirotyStyles = {
  structure: {
    border: `1px solid #D9D9D9`,
    borderRadius: "12px",
  },
  header_container: {
    backgroundColor: "#F9F9F9",
    px: 3,
    height: "64px",
    borderBottom: `1px solid #D9D9D9`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  content_container: { height: { xs: "auto", md: "650px" }, overflow: "auto" },
  contract_container: { borderBottom: `1px solid #D9D9D9`, px: 2, py: 0.5 },
  upload_file_container: {
    border: `3px dotted ${colors.borderColor}`,
    borderRadius: "4px",
    py: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  uploaded_file_container: {
    border: `1px solid ${colors.borderColor}`,
    py: 1.5,
    px: 2,
    borderRadius: "4px",
  },
  dropdown_item: { fontSize: "14px", color: colors.primaryBlack },
};
