import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Box, Dialog, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { IModalProps } from "models/common";
import { images } from "assets/images";
import { GlobalLabels, ClientProjectLabels } from "common/AppConstants";
import { OutlinedBtn, RedContainedBtn } from "common/Buttons";
import { DELETE_PROJECTs } from "graphql/projects";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

const styles = {
  alert_img: { width: "80px", alignSelf: " center", paddingTop: 40 },
  logout_text: {
    textAlign: "center",
    fontSize: "24px",
    color: colors.primaryBlack,
    fontWeight: 600,
    py: 2,
  },
  logout_desc: {
    textAlign: "center",
    fontSize: "18px",
    color: colors.secondaryBlack,
    pb: 2,
    px: 4,
  },
  actions_container: {
    display: "flex",
    justifyContent: "space-between",
    pb: 4,
    px: 4,
  },
  btn: { fontSize: "16px", width: "45%", fontWeight: 500 },
  noBtn: { color: colors.secondaryBlack },
};

interface IDeleteModalProps extends IModalProps {
  projectId: string;
  refetchProjects: any;
  setSelectedProjectId: Dispatch<SetStateAction<string>>;
}

const DeleteProjectModal = (props: IDeleteModalProps) => {
  const { open, setOpen, projectId, refetchProjects, setSelectedProjectId } =
    props;
  const { setLoading } = useStore();

  const handleClose = () => {
    setSelectedProjectId("");
    setOpen(false);
  };

  const [fireDeleteProjApi, { loading }] = useMutation(DELETE_PROJECTs, {
    onCompleted: (data) => {
      setLoading(false);
      const { deleteProject } = data;
      const { status, message } = deleteProject;
      if (status) {
        refetchProjects();
        toast.success(message, { delay: 10 });
      } else {
        toast.error(message, { delay: 10 });
      }
      handleClose();
    },
  });

  const handleDelete = () => {
    fireDeleteProjApi({ variables: { ids: [projectId] } });
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <img src={images.ALERT_RED} alt="alert" style={styles.alert_img} />
      <Typography sx={styles.logout_text}>
        {ClientProjectLabels.DEL_PROJ}
      </Typography>
      <Typography sx={styles.logout_desc}>
        Are you sure that you want to delete this project?
      </Typography>
      <Box sx={styles.actions_container}>
        <OutlinedBtn
          variant="outlined"
          sx={[styles.btn, styles.noBtn]}
          onClick={handleClose}
        >
          {GlobalLabels.CANCEL}
        </OutlinedBtn>
        <RedContainedBtn
          variant="contained"
          sx={styles.btn}
          onClick={handleDelete}
        >
          {GlobalLabels.DELETE}
        </RedContainedBtn>
      </Box>
    </Dialog>
  );
};

export default DeleteProjectModal;
