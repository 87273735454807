import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { RouteNames } from "routes/routeNames";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { IFolderSearchResult } from "./utils";

interface IFolderSearchCardProps {
  folderData: IFolderSearchResult;
  handleClose: () => void;
}

const FolderSearchCard = (props: IFolderSearchCardProps) => {
  const { folderData, handleClose } = props;

  const navigate = useNavigate();

  return (
    <Box
      sx={{ cursor: "pointer" }}
      onClick={() => {
        if (folderData?.path.length > 1) {
          navigate(
            `${RouteNames.PROJECTS}/${folderData?.project?.id}/indexes${folderData?.path}${folderData.id}`
          );
        } else {
          navigate(
            `${RouteNames.PROJECTS}/${folderData?.project?.id}/indexes/${folderData.id}`
          );
        }
        handleClose();
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <img
            src={images.FOLDER_NEW}
            style={{ width: "50px" }}
            alt="document"
          />
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography sx={{ color: colors.primaryBlack, fontSize: "14px" }}>
            {folderData?.name}
          </Typography>
          <Typography sx={{ color: "#00000060", fontSize: "13px" }}>
            {`${folderData?.project?.name}`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FolderSearchCard;
