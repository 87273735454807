import React from "react";
import { Box, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import { images } from "assets/images";
import theme from "theme/muiTheming";
import { colors } from "theme/colors";

const styles = {
  content_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  full_screen: { width: "100vw", height: "100vh" },
  ls_text: { color: colors.primaryBlack, fontSize: "40px", fontWeight: 500 },
  ls_desc: {
    color: colors.grey,
    fontSize: "20px",
    textAlign: "center",
    width: { xs: "100%", md: "666px" },
  },
};

export const NotAuthorizedPage = () => {
  const matchesXS = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box sx={[styles.content_center, styles.full_screen]}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <img
            src={images.NOT_AUTHORIZED}
            alt="not authorized"
            width={matchesXS ? "" : "100%"}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <Typography sx={styles.ls_text}>You are not authorized!</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.content_center}>
          <Typography sx={styles.ls_desc}>
            Please contact the <Link href={'mailto: finka@finka.me'}>Finka Admin</Link> for access.   
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
