import { colors } from "theme/colors";

export const SupportStyles = {
  title: { color: colors.primaryBlack, fontSize: "20px", fontWeight: 500 },
  flex_JCfe: { display: "flex", justifyContent: "flex-end" },
  btn: { width: 100 },
  inst_text: { color: colors.grey, fontSize: "16px" },
  error_text: { fontSize: "12px", color: "#D3010E" },
  field_label: {
    color: `${colors.secondaryBlack}70`,
    fontSize: "13px",
    fontWeight: 600,
    mb: 0.5,
  },
  input_field: {
    backgroundColor: "#e6e6e640",
    borderRadius: "6px",
    fontSize: "13px",
    color: colors.primaryBlack,
  },
};
