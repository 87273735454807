import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Dialog, Grid, IconButton, Typography } from "@mui/material";
import {
  ArrowBack,
  ChevronRight,
  Close,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { GET_ALL_INDEX_ITEMS } from "graphql/indexes";
import { DOWNLOAD_CONTRACT_AS_PDF, GET_CONTRACT_FILE } from "graphql/contracts";
import { IArr, IModalProps } from "models/common";
import { IChecklistItemDoc } from "models/checklists";
import { IIndexItem } from "models/indexes";
import { useStore } from "utils/store";
import { images } from "assets/images";
import { colors } from "theme/colors";

interface IViewLinkedDRItemsModalProps extends IModalProps {
  linkedDRItems: IChecklistItemDoc[];
}

function ViewLinkedDRItemsModal(props: IViewLinkedDRItemsModalProps) {
  const { open, setOpen, linkedDRItems } = props;

  const params = useParams();
  const { setLoading } = useStore();

  const [isSingleFolder, setIsSingleFolder] = useState(false);
  const [nestedFolderArr, setNestedFolderArr] = useState<IArr[]>([]);
  const [nestedItemsArr, setNestedItemsArr] = useState<IIndexItem[]>([]);

  const { loading: gettingNestedLevels } = useQuery(GET_ALL_INDEX_ITEMS, {
    variables: {
      filter: {
        project_id: params?.project_id,
        register_id: nestedFolderArr[nestedFolderArr.length - 1]?.id,
      },
      sorted: "name",
    },
    skip: !params?.project_id || nestedFolderArr.length === 0,
    onCompleted: (data) => {
      setLoading(false);
      const { getRegisterItems } = data;
      const { status, registerItems } = getRegisterItems;
      if (status) {
        setNestedItemsArr(registerItems);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [previewDocumentPDF, { loading: gettingPDFPreview }] = useLazyQuery(
    DOWNLOAD_CONTRACT_AS_PDF,
    {
      onCompleted: (data) => {
        const { getContractAsPDF } = data;
        const { status, contract } = getContractAsPDF;
        if (status) {
          if (Boolean(contract)) {
            var base64String = contract;
            var binaryString = atob(base64String);
            var byteArray = new Uint8Array(binaryString.length);
            for (var i = 0; i < binaryString.length; i++) {
              byteArray[i] = binaryString.charCodeAt(i);
            }
            var blob = new Blob([byteArray], { type: "application/pdf" });
            var url = URL.createObjectURL(blob);
            window.open(url);
          }
        } else {
          toast.error("Something went wrong, please try again", { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [getDocumentFile, { loading: gettingFile }] = useLazyQuery(
    GET_CONTRACT_FILE,
    {
      onCompleted: (data) => {
        const { getDocumentFile } = data;
        const {
          status,
          file,
          fileExists,
          signedFile,
          document: rawDocument,
        } = getDocumentFile;
        if (status) {
          if (fileExists) {
            if (rawDocument?.isUpload) {
              var hiddenElement = document.createElement("a");
              hiddenElement.href = file;
              hiddenElement.target = "_blank";
              hiddenElement.download = `${rawDocument?.name}.pdf`;
              hiddenElement.click();
            } else {
              if (signedFile) {
                var hiddenElement1 = document.createElement("a");
                hiddenElement1.href = signedFile;
                hiddenElement1.target = "_blank";
                hiddenElement1.download = `${rawDocument?.name}.pdf`;
                hiddenElement1.click();
              } else {
                previewDocumentPDF({ variables: { id: rawDocument?.id } });
              }
            }
          } else {
            toast.error("No File Found", { delay: 10 });
          }
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  useEffect(() => {
    if (linkedDRItems.length === 1 && linkedDRItems[0].type === "REGISTER") {
      setIsSingleFolder(true);
      setNestedFolderArr([
        { id: linkedDRItems[0].id, name: linkedDRItems[0].name },
      ]);
    }
  }, [linkedDRItems]);

  useEffect(() => {
    setLoading(gettingFile || gettingNestedLevels || gettingPDFPreview);
  }, [gettingFile, gettingNestedLevels, gettingPDFPreview, setLoading]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: "24px",
          overflow: "hidden",
        },
      }}
    >
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              py: 2,
              backgroundColor: "#B6BCC325",
              position: "relative",
              px: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  fontSize: "13px",
                  color: colors.primaryBlack,
                  fontWeight: 600,
                }}
              >
                Attached Files
              </Typography>
            </Box>
          </Box>
          <Box sx={{ position: "absolute", top: 10, right: 10 }}>
            <IconButton size="small" onClick={handleClose}>
              <Close sx={{ color: "#00000055" }} fontSize="small" />
            </IconButton>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              height: "80vh",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {nestedFolderArr.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  py: 1.5,
                  px: 1.5,
                }}
              >
                {!isSingleFolder && (
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => setNestedFolderArr([])}
                    >
                      <ArrowBack fontSize="small" sx={{ color: "#00000055" }} />
                    </IconButton>
                  </Box>
                )}
                {nestedFolderArr.map((x, index) => (
                  <Box
                    key={x.id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ...(index <= nestedFolderArr.length - 1 && { ml: 1 }),
                    }}
                  >
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        const tempArr = [...nestedFolderArr];
                        const index = tempArr.findIndex((y) => x.id === y.id);
                        if (index > -1) {
                          tempArr.splice(index + 1);
                        }
                        setNestedFolderArr([...tempArr]);
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          color: colors.primaryBlack,
                          fontWeight: 600,
                        }}
                      >
                        {x.name}
                      </Typography>
                    </Box>
                    {index !== nestedFolderArr.length - 1 && (
                      <ChevronRight
                        sx={{
                          color: "#00000055",
                          ml: 1,
                        }}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
            <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
              <Box
                sx={{
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <Grid container>
                  {isSingleFolder ? (
                    nestedItemsArr.length > 0 ? (
                      nestedItemsArr.map((item) => (
                        <Grid
                          key={item.id}
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              ":hover": {
                                backgroundColor: "#B6BCC330",
                              },
                            }}
                          >
                            <Box
                              key={item.id}
                              component="div"
                              sx={{
                                cursor: "pointer",
                                py: 1.5,
                                px: 2.5,
                                display: "flex",
                                alignItems: "center",
                                flexGrow: 1,
                                userSelect: "none",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                if (!item?.isRegister) {
                                  if (Boolean(item?.document)) {
                                    if (item.itemType !== "BLANK") {
                                      getDocumentFile({
                                        variables: {
                                          payload: {
                                            documentId: item?.document?.id,
                                          },
                                        },
                                      });
                                    }
                                  } else {
                                    toast.error("No File Found", { delay: 10 });
                                  }
                                } else {
                                  const tempArr = [...nestedFolderArr];
                                  tempArr.push({
                                    id: item.id,
                                    name: item.name,
                                  });
                                  setNestedFolderArr([...tempArr]);
                                }
                              }}
                            >
                              {item.isRegister ? (
                                <img
                                  src={images.FOLDER_NEW}
                                  alt="folder"
                                  style={{ width: 18 }}
                                />
                              ) : item.itemType === "BLANK" ? (
                                <img
                                  src={images.NEW_DOC_NEW}
                                  alt="folder"
                                  style={{
                                    width: 18,
                                    height: 18,
                                    objectFit: "contain",
                                  }}
                                />
                              ) : (
                                <img
                                  src={images.CONTRACT_ICON_NEW}
                                  alt="folder"
                                  style={{ width: 18 }}
                                />
                              )}
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  color: colors.primaryBlack,
                                  ml: 1,
                                  ...(!item?.isRegister && {
                                    ":hover": { textDecoration: "underline" },
                                  }),
                                }}
                                noWrap
                              >
                                {item.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))
                    ) : (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box
                          sx={{
                            height: "75vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ErrorOutlineOutlined
                              sx={{
                                color: `${colors.primaryBlack}55`,
                                fontSize: 30,
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "18px",
                                fontWeight: 600,
                                color: `${colors.primaryBlack}55`,
                                ml: 1,
                              }}
                            >
                              No items
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )
                  ) : nestedFolderArr.length === 0 ? (
                    linkedDRItems.map((item) => (
                      <Grid key={item.id} item lg={12} md={12} sm={12} xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            ":hover": {
                              backgroundColor: "#B6BCC330",
                            },
                          }}
                        >
                          <Box
                            key={item.id}
                            component="div"
                            sx={{
                              cursor: "pointer",
                              py: 1.5,
                              px: 2.5,
                              display: "flex",
                              alignItems: "center",
                              flexGrow: 1,
                              userSelect: "none",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (item.type === "REGISTERITEM") {
                                if (Boolean(item.documentId)) {
                                  getDocumentFile({
                                    variables: {
                                      payload: {
                                        documentId: item?.documentId,
                                      },
                                    },
                                  });
                                } else {
                                  toast.error("No File Found", { delay: 10 });
                                }
                              } else {
                                const tempArr = [...nestedFolderArr];
                                tempArr.push({ id: item.id, name: item.name });
                                setNestedFolderArr([...tempArr]);
                              }
                            }}
                          >
                            {item.type === "REGISTER" ? (
                              <img
                                src={images.FOLDER_NEW}
                                alt="folder"
                                style={{ width: 18 }}
                              />
                            ) : item.itemType === "BLANK" ? (
                              <img
                                src={images.NEW_DOC_NEW}
                                alt="folder"
                                style={{
                                  width: 18,
                                  height: 18,
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              <img
                                src={images.CONTRACT_ICON_NEW}
                                alt="folder"
                                style={{ width: 18 }}
                              />
                            )}
                            <Typography
                              sx={{
                                fontSize: "13px",
                                color: colors.primaryBlack,
                                ml: 1,
                                ":hover": {
                                  ...(item.itemType === "REGISTERITEM" && {
                                    textDecoration: "underline",
                                  }),
                                },
                              }}
                              noWrap
                            >
                              {item.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))
                  ) : nestedItemsArr.length > 0 ? (
                    nestedItemsArr.map((item) => (
                      <Grid key={item.id} item lg={12} md={12} sm={12} xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            ":hover": {
                              backgroundColor: "#B6BCC330",
                            },
                          }}
                        >
                          <Box
                            key={item.id}
                            component="div"
                            sx={{
                              cursor: "pointer",
                              py: 1.5,
                              px: 2.5,
                              display: "flex",
                              alignItems: "center",
                              flexGrow: 1,
                              userSelect: "none",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (!item?.isRegister) {
                                if (Boolean(item?.document)) {
                                  if (item.itemType !== "BLANK") {
                                    getDocumentFile({
                                      variables: {
                                        payload: {
                                          documentId: item?.document?.id,
                                        },
                                      },
                                    });
                                  }
                                } else {
                                  toast.error("No File Found", { delay: 10 });
                                }
                              } else {
                                const tempArr = [...nestedFolderArr];
                                tempArr.push({ id: item.id, name: item.name });
                                setNestedFolderArr([...tempArr]);
                              }
                            }}
                          >
                            {item.isRegister ? (
                              <img
                                src={images.FOLDER_NEW}
                                alt="folder"
                                style={{ width: 18 }}
                              />
                            ) : item.itemType === "BLANK" ? (
                              <img
                                src={images.NEW_DOC_NEW}
                                alt="folder"
                                style={{
                                  width: 18,
                                  height: 18,
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              <img
                                src={images.CONTRACT_ICON_NEW}
                                alt="folder"
                                style={{ width: 18 }}
                              />
                            )}
                            <Typography
                              sx={{
                                fontSize: "13px",
                                color: colors.primaryBlack,
                                ml: 1,
                                ...(!item?.isRegister && {
                                  ":hover": { textDecoration: "underline" },
                                }),
                              }}
                              noWrap
                            >
                              {item.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          height: "75vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <ErrorOutlineOutlined
                            sx={{
                              color: `${colors.primaryBlack}55`,
                              fontSize: 30,
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "18px",
                              fontWeight: 600,
                              color: `${colors.primaryBlack}55`,
                              ml: 1,
                            }}
                          >
                            No items
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ViewLinkedDRItemsModal;
