import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { RouteNames } from "routes/routeNames";
import { getPermissions, getProjectRedirection } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { IProjectSearchResult } from "./utils";

interface IProjectSearchCardProps {
  projectData: IProjectSearchResult;
  handleClose: () => void;
}

const ProjectSearchCard = (props: IProjectSearchCardProps) => {
  const { projectData, handleClose } = props;

  const navigate = useNavigate();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);

  return (
    <Box
      sx={{ cursor: "pointer" }}
      onClick={() => {
        navigate(
          `${RouteNames.PROJECTS}/${projectData?.id}${getProjectRedirection(
            ROLE_PERMISSIONS
          )}`
        );
        handleClose();
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {projectData?.profilePicture ? (
            <img
              src={images.PROJECT_1}
              alt="project"
              style={{
                width: "50px",
                height: "74.06px",
                aspectRatio: 0.675 / 1,
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          ) : (
            <Box
              sx={{
                width: "50px",
                height: "74.06px",
                aspectRatio: 0.675 / 1,
                borderRadius: "10px",
                backgroundColor: "#BFBDBC22",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={images.FILM_REEL_DARK}
                alt="reel"
                style={{ height: 18 }}
              />
            </Box>
          )}
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography sx={{ color: colors.primaryBlack, fontSize: "14px" }}>
            {projectData?.name}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectSearchCard;
