import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Box, Dialog, Grid, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { DELETE_CHECKLIST } from "graphql/checklist";
import { IModalProps } from "models/common";
import { useStore } from "utils/store";
import { NewOutlinedBtn, NewRedAlertContainedButton } from "common/Buttons";
import { images } from "assets/images";
import { colors } from "theme/colors";

interface IDeleteChecklistModalProps extends IModalProps {
  selectedChecklist: string;
  setSelectedChecklist: Dispatch<SetStateAction<string>>;
  selectedChecklistName: string;
  setSelectedChecklistName: Dispatch<SetStateAction<string>>;
  refetch: any;
}

const DeleteChecklistModal = (props: IDeleteChecklistModalProps) => {
  const {
    open,
    setOpen,
    selectedChecklist,
    setSelectedChecklist,
    selectedChecklistName,
    setSelectedChecklistName,
    refetch,
  } = props;

  const params = useParams();
  const { setLoading } = useStore();

  const handleClose = () => {
    setSelectedChecklist("");
    setSelectedChecklistName("");
    setOpen(false);
  };

  const [fireDeleteChecklistApi, { loading }] = useMutation(DELETE_CHECKLIST, {
    onCompleted: (data) => {
      setLoading(false);
      const { deleteChecklist } = data;
      const { status, message } = deleteChecklist;
      if (status) {
        refetch();
        toast.success(message, { delay: 10 });
      } else {
        toast.error(message, { delay: 10 });
      }
      handleClose();
    },
  });

  const handleDelete = () => {
    fireDeleteChecklistApi({
      variables: {
        project_id: params?.project_id,
        id: selectedChecklist,
      },
    });
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const AlertImage = () => (
    <img src={images.ALERT_NEW} alt="alert" height={22} />
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "24px", p: 3, width: "465px" } }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={images.ALERT_NEW}
              sx={{ backgroundColor: "#ED1C2420" }}
              slots={{ img: AlertImage }}
            />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              color: colors.primaryBlack,
              textAlign: "center",
            }}
          >
            {`Delete ${selectedChecklistName}?`}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{ fontSize: "13px", textAlign: "center", color: "#00000080" }}
          >
            Once <span style={{ fontWeight: 600 }}>deleted</span>, the delivery
            schedule and all its associated data cannot be recovered.
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <NewOutlinedBtn
                variant="outlined"
                sx={{
                  color: colors.primaryBlack,
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleClose}
              >
                Cancel
              </NewOutlinedBtn>
            </Box>
            <Box sx={{ ml: 2 }}>
              <NewRedAlertContainedButton
                sx={{
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleDelete}
              >
                Delete
              </NewRedAlertContainedButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteChecklistModal;
