import { colors } from "theme/colors";

export const ResetPwdSuccessStyles = {
  forgot_pwd_bg: { width: "100%", height: "100vh" },
  reset_pwd_container: {
    height: "100%",
    width: "100%",
  },
  content_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  success_text: {
    fontSize: "25px",
    fontWeight: 600,
    color: colors.primaryBlack,
  },
  congrats_text: { color: "#5B5C60", textAlign: "center", width: "300px" },
  login_btn: { width: { xs: "250px", md: "540px" }, fontSize: "20px", mt: 3 },
};
