import React from "react";
import { FormControlLabel, Radio, RadioProps, Typography } from "@mui/material";

interface IProps extends RadioProps {
  label: string;
  labelStyle?: any;
}

const RadioButtonWithLabel = (props: IProps) => {
  const { label, labelStyle = {}, ...rest } = props;
  return (
    <FormControlLabel
      control={<Radio {...rest} />}
      label={<Typography sx={labelStyle}>{label}</Typography>}
    />
  );
};

export default RadioButtonWithLabel;
