import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { colors } from "theme/colors";
import { IActivityLog } from "models/user";
import { useStore } from "utils/store";
import { GET_ADMIN_ACTIVITY_LOGS } from "graphql/users";
import CustomDatePicker from "common/DatePicker";
import { groupActivitiesByDate } from "utils/helpers";

import { SettingsStyles as styles } from "./styles";

interface IFilterProps {
  selectedStartDate: Date | null;
  setSelectedStartDate: Dispatch<SetStateAction<Date | null>>;
  selectedEndDate: Date | null;
  setSelectedEndDate: Dispatch<SetStateAction<Date | null>>;
  filterError: string;
  setFilterError: Dispatch<SetStateAction<string>>;
}

const Filters = (props: IFilterProps) => {
  const {
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    filterError,
    setFilterError,
  } = props;

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item lg={6} md={6} sm={12} xs={12} sx={styles.my3}>
        <Typography sx={styles.recent_act_text}>Recent Activity</Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Typography sx={styles.field_label}>Start Date</Typography>
        <CustomDatePicker
          textValue={
            selectedStartDate
              ? moment(selectedStartDate).format("DD MMMM YYYY")
              : "Start Date"
          }
          fullWidth
          value={selectedStartDate}
          onChange={(newDate) => {
            setFilterError("");
            setSelectedStartDate(newDate);
            if (!selectedEndDate) {
              setFilterError("Please select end date");
            } else {
              setFilterError("");
              if (moment(selectedEndDate).isSameOrBefore(newDate)) {
                setFilterError("Start date should be before End date");
              }
            }
          }}
          disableFuture
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Typography sx={styles.field_label}>End Date</Typography>
        <CustomDatePicker
          textValue={
            selectedEndDate
              ? moment(selectedEndDate).format("DD MMMM YYYY")
              : "End Date"
          }
          fullWidth
          value={selectedEndDate}
          onChange={(newDate) => {
            setFilterError("");
            setSelectedEndDate(newDate);
            if (!selectedStartDate) {
              setFilterError("Please select start date");
            } else {
              setFilterError("");
              if (moment(selectedStartDate).isSameOrAfter(newDate)) {
                setFilterError("Start date should be before End date");
              }
            }
          }}
          disableFuture
        />
      </Grid>
      {filterError && (
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -2.5 }}>
          <Typography textAlign="right" sx={styles.error_text}>
            {filterError}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const ActivityLogs = () => {
  const { setLoading } = useStore();

  const [activities, setActivities] = useState<any>({});
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [filterError, setFilterError] = useState("");

  const { loading } = useQuery(GET_ADMIN_ACTIVITY_LOGS, {
    variables: {
      ...(Boolean(selectedStartDate) &&
        Boolean(selectedEndDate) &&
        !filterError && {
          filter: {
            start: moment(selectedStartDate).format("YYYY-MM-DD"),
            end: moment(selectedEndDate).add(1, "day").format("YYYY-MM-DD"),
          },
        }),
    },
    onCompleted: (data) => {
      setLoading(false);
      const { getActivityLogs } = data;
      const { status, logs } = getActivityLogs;
      if (status) {
        groupActivitiesByDate(logs, setActivities);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const getDateTitle = (date: any) => {
    const today = moment();
    const newDate = moment(date);
    if (today.isSame(newDate, "date")) return "Today";
    if (today.subtract(1, "day").isSame(newDate, "date")) return "Yesterday";
    return newDate.format("DD MMMM YYYY");
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={2} sx={{ px: { xs: 0, sm: 5, md: 10, lg: 15 } }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Filters
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          filterError={filterError}
          setFilterError={setFilterError}
        />
      </Grid>
      {Object.keys(activities).map((item) => (
        <Grid key={item} item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={styles.activity_header_text}>
            {getDateTitle(item)}
          </Typography>
          {(activities as any)[item].map((act: IActivityLog, index: number) => (
            <Box
              key={act.historyDate}
              sx={[
                styles.timeline_container,
                {
                  borderLeft:
                    index === (activities as any)[item].length - 1
                      ? `3px dotted transparent`
                      : `3px dotted ${colors.primary}`,
                },
              ]}
            >
              <FiberManualRecord color="primary" sx={styles.timeline_dot} />
              <Box sx={styles.flex_JCsb_Afe}>
                <Box sx={styles.flex}>
                  {Boolean(act.changedByProfile) ? (
                    <Avatar
                      src={act.changedByProfile}
                      sx={styles.timeline_avatar}
                    />
                  ) : (
                    <Avatar sx={styles.timeline_avatar} />
                  )}
                  <Box>
                    <Typography sx={styles.timeline_name_text}>
                      {act.changedBy}
                    </Typography>
                    <Typography sx={styles.timeline_activity_text}>
                      {act.historyChangeReason}
                    </Typography>
                  </Box>
                </Box>
                <Typography sx={styles.timeline_time_text}>
                  {moment(act.historyDate).format("hh:mm a")}
                </Typography>
              </Box>
            </Box>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default ActivityLogs;
