import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { DeleteOutline, ModeEditOutline, MoreVert } from "@mui/icons-material";

import { IIndex } from "models/indexes";
import { RouteNames } from "routes/routeNames";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

interface IClearanceCardProps {
  data: IIndex;
  setSelectedIndex: Dispatch<SetStateAction<string>>;
  setSelectedIndexName: Dispatch<SetStateAction<string>>;
  setAddEditIndexModal: Dispatch<SetStateAction<boolean>>;
  setDeleteModal: Dispatch<SetStateAction<boolean>>;
}

function ClearanceCard(props: IClearanceCardProps) {
  const {
    data,
    setSelectedIndex,
    setSelectedIndexName,
    setAddEditIndexModal,
    setDeleteModal,
  } = props;

  const navigate = useNavigate();
  const projectId = useParams();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const clearanceEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("CLEARANCE_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [openActions, setOpenActions] = useState(false);
  const [actionsAnchor, setActionsAnchor] = useState<HTMLElement | null>(null);

  const handleActionsOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpenActions(true);
    setActionsAnchor(e.currentTarget);
  };

  const handleActionsClose = () => {
    setOpenActions(false);
    setActionsAnchor(null);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#41B19920",
        pl: 2.5,
        pr: 0.5,
        py: 1.25,
        borderRadius: "10px",
        width: "220px",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(
          `${RouteNames.PROJECTS}/${projectId?.project_id}/clearances/${data?.id}`
        );
      }}
    >
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box>
              <img
                src={images.FOLDER_NEW_BLACK}
                style={{ height: 50 }}
                alt="folder"
              />
            </Box>
            <Box>
              {Boolean(ROLE_PERMISSIONS) &&
                clearanceEditIndex !== undefined &&
                ROLE_PERMISSIONS[0]?.permissions[clearanceEditIndex]
                  ?.CLEARANCE_EDIT && (
                  <IconButton size="small" onClick={handleActionsOpen}>
                    <MoreVert fontSize="small" sx={{ color: "#00000055" }} />
                  </IconButton>
                )}
              <Menu
                anchorEl={actionsAnchor}
                open={openActions}
                onClose={handleActionsClose}
                elevation={1}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <MenuList disablePadding>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedIndex(data.id);
                      setAddEditIndexModal(true);
                      handleActionsClose();
                    }}
                  >
                    <ListItemIcon>
                      <ModeEditOutline
                        fontSize="small"
                        sx={{ color: "#00000055" }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                      >
                        Edit
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedIndex(data.id);
                      setSelectedIndexName(data.name);
                      setDeleteModal(true);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteOutline
                        fontSize="small"
                        sx={{ color: "#00000055" }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{ color: colors.secondaryBlack, fontSize: "14px" }}
                      >
                        Delete
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ pr: 2.5 }}>
            <Typography
              sx={{ fontSize: "13px", color: "#000", width: "100%" }}
              noWrap
            >
              {data?.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={{ fontSize: "12px", color: "#00000070" }}>
            {data?.items || "0"} items
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ClearanceCard;
