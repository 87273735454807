import React, { Dispatch, SetStateAction, useState } from "react";
import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import { CameraAlt, ModeEditOutlineOutlined } from "@mui/icons-material";

import ProfilePhotoCircularCropper from "common/ImageCropper/ProfilePhotoCircularCropper";
import { colors } from "theme/colors";

interface ICompanyLeftStepsProps {
  activeSection: number;
  setActiveSection: Dispatch<SetStateAction<number>>;
  profilePicture: any;
  setProfilePicture: Dispatch<SetStateAction<any>>;
}

const sections = ["Company Details", "Registration", "Bank Details"];

function CompanyLeftSteps(props: ICompanyLeftStepsProps) {
  const { activeSection, setActiveSection, profilePicture, setProfilePicture } =
    props;

  const [profilePictureHovered, setProfilePictureHovered] = useState(false);
  const [openImageCropUploader, setOpenImageCropUploader] = useState(false);

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            position: "relative",
          }}
          onClick={() => setOpenImageCropUploader(true)}
        >
          <Box
            component="div"
            onMouseEnter={() => {
              if (!profilePictureHovered) {
                setProfilePictureHovered(true);
              }
            }}
          >
            {profilePicture ? (
              <Avatar
                sx={{ height: 55, width: 55, cursor: "pointer" }}
                src={
                  typeof profilePicture === "string"
                    ? profilePicture
                    : URL.createObjectURL(profilePicture)
                }
              />
            ) : (
              <Avatar
                sx={{
                  height: 55,
                  width: 55,
                  cursor: "pointer",
                }}
              />
            )}
          </Box>
          {profilePictureHovered && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                top: 0,
              }}
            >
              <Box
                component="div"
                onMouseLeave={() => {
                  if (profilePictureHovered) {
                    setProfilePictureHovered(false);
                  }
                }}
              >
                <Avatar
                  sx={{
                    height: 55,
                    width: 55,
                    backgroundColor: "#000000",
                    cursor: "pointer",
                    opacity: 0.4,
                  }}
                >
                  <CameraAlt color="secondary" sx={{ opacity: 1 }} />
                </Avatar>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "center",
            position: "relative",
          }}
        >
          {profilePicture ? (
            <Avatar
              sx={{ height: 55, width: 55, cursor: "pointer" }}
              src={
                typeof profilePicture === "string"
                  ? profilePicture
                  : URL.createObjectURL(profilePicture)
              }
            />
          ) : (
            <Avatar
              sx={{
                height: 55,
                width: 55,
                cursor: "pointer",
              }}
            />
          )}
          <Box sx={{ position: "absolute", bottom: -10 }}>
            <IconButton
              size="small"
              sx={{
                backgroundColor: "#fff",
                border: "1px #e6e6e6 solid",
                height: 20,
                width: 20,
              }}
              onClick={() => setOpenImageCropUploader(true)}
            >
              <ModeEditOutlineOutlined sx={{ fontSize: 12 }} />
            </IconButton>
          </Box>
        </Box>
      </Grid>
      {sections.map((section, index) => (
        <Grid key={section} item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Box
              component="div"
              onClick={() => setActiveSection(index)}
              sx={{
                py: 1,
                borderRadius: "10px",
                backgroundColor:
                  activeSection === index ? "#41B19931" : "transparent",
                minWidth: 150,
                cursor: "pointer",
                ":hover": {
                  backgroundColor:
                    activeSection === index ? "#41B19995" : "#B6BCC325",
                },
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  fontSize: "13px",
                  color: colors.primaryBlack,
                  ...(activeSection === index && { fontWeight: 600 }),
                }}
              >
                {section}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
      {openImageCropUploader && (
        <ProfilePhotoCircularCropper
          open={openImageCropUploader}
          setOpen={setOpenImageCropUploader}
          width={55}
          imgSrc={profilePicture}
          setImgSrc={(file: any) => setProfilePicture(file)}
        />
      )}
    </Grid>
  );
}

export default CompanyLeftSteps;
