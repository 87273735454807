import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import {
  Add,
  CheckOutlined,
  ModeEditOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { NewPrimaryGreenTextButton } from "common/Buttons";
import { UPDATE_PROJECT } from "graphql/projects";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";

import { IKeyPersonnelFields, IPerson } from "./KeyPersonnel";
import { validateArr, validateArrErrors } from "./utils";
import { ProjectDetailsStyles as styles } from "../styles";

interface IAllFormsProps {
  personnelData: IKeyPersonnelFields;
  refetch: any;
}

function AllForms(props: IAllFormsProps) {
  const { personnelData, refetch } = props;

  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x?.hasOwnProperty("PROJECT_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [editDirectors, setEditDirectors] = useState(false);
  const [editProducers, setEditProducers] = useState(false);
  const [editWriters, setEditWriters] = useState(false);
  const [editExecutiveProds, setEditExecutiveProds] = useState(false);
  const [editLineProds, setEditLineProds] = useState(false);
  const [dirErrors, setDirErrors] = useState<string[]>([]);
  const [prodErrors, setProdErrors] = useState<string[]>([]);
  const [writerErrors, setWriterErrors] = useState<string[]>([]);
  const [executiveProdErrors, setExecutiveProdErrors] = useState<string[]>([]);
  const [lineProdErrors, setLineProdErrors] = useState<string[]>([]);

  const [fireUpdatePersonnelApi, { loading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: (data) => {
      setLoading(false);
      const { updateProject } = data;
      const { status, message } = updateProject;
      setEditDirectors(false);
      setEditProducers(false);
      setEditWriters(false);
      setEditExecutiveProds(false);
      setEditLineProds(false);
      if (status) {
        refetch();
        toast.success(message);
      } else {
        toast.error(message);
      }
    },
  });

  const handleUpdateDirectors = (values: IKeyPersonnelFields) => {
    const { id, directors } = values;

    const keyPersonalsJSON = {
      ...(directors.length > 0 && {
        directors: directors.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.writers?.length > 0 && {
        writers: personnelData?.writers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.producers.length > 0 && {
        producers: personnelData?.producers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.lineProducers.length > 0 && {
        lineProducers: personnelData?.lineProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.executiveProducers.length > 0 && {
        executiveProducers: personnelData?.executiveProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
    };

    const dirErrorsF: any = validateArr(directors, setDirErrors);

    if (validateArrErrors(dirErrorsF)) {
      fireUpdatePersonnelApi({
        variables: {
          payload: {
            id,
            keyPersonals: JSON.stringify(keyPersonalsJSON),
          },
        },
      });
    }
  };

  const handleUpdateProducers = (values: IKeyPersonnelFields) => {
    const { id, producers } = values;

    const keyPersonalsJSON = {
      ...(personnelData?.directors.length > 0 && {
        directors: personnelData?.directors.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.writers.length > 0 && {
        writers: personnelData?.writers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(producers.length > 0 && {
        producers: producers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.lineProducers.length > 0 && {
        lineProducers: personnelData?.lineProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.executiveProducers.length > 0 && {
        executiveProducers: personnelData?.executiveProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
    };

    const prodErrorsF: any = validateArr(producers, setProdErrors);

    if (validateArrErrors(prodErrorsF)) {
      fireUpdatePersonnelApi({
        variables: {
          payload: {
            id,
            keyPersonals: JSON.stringify(keyPersonalsJSON),
          },
        },
      });
    }
  };

  const handleUpdateWriters = (values: IKeyPersonnelFields) => {
    const { id, writers } = values;

    const keyPersonalsJSON = {
      ...(personnelData?.directors.length > 0 && {
        directors: personnelData?.directors.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(writers.length > 0 && {
        writers: writers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.producers.length > 0 && {
        producers: personnelData?.producers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.lineProducers.length > 0 && {
        lineProducers: personnelData?.lineProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.executiveProducers.length > 0 && {
        executiveProducers: personnelData?.executiveProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
    };

    const writerErrorsF: any = validateArr(writers, setWriterErrors);

    if (validateArrErrors(writerErrorsF)) {
      fireUpdatePersonnelApi({
        variables: {
          payload: {
            id,
            keyPersonals: JSON.stringify(keyPersonalsJSON),
          },
        },
      });
    }
  };

  const handleUpdateExecutiveProds = (values: IKeyPersonnelFields) => {
    const { id, executiveProducers } = values;

    const keyPersonalsJSON = {
      ...(personnelData?.directors.length > 0 && {
        directors: personnelData?.directors.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.writers.length > 0 && {
        writers: personnelData?.writers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.producers.length > 0 && {
        producers: personnelData?.producers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.lineProducers.length > 0 && {
        lineProducers: personnelData?.lineProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(executiveProducers.length > 0 && {
        executiveProducers: executiveProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
    };

    const executiveProdErrorsF: any = validateArr(
      executiveProducers,
      setExecutiveProdErrors
    );

    if (validateArrErrors(executiveProdErrorsF)) {
      fireUpdatePersonnelApi({
        variables: {
          payload: {
            id,
            keyPersonals: JSON.stringify(keyPersonalsJSON),
          },
        },
      });
    }
  };

  const handleUpdateLineProds = (values: IKeyPersonnelFields) => {
    const { id, lineProducers } = values;

    const keyPersonalsJSON = {
      ...(personnelData?.directors.length > 0 && {
        directors: personnelData?.directors.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.writers.length > 0 && {
        writers: personnelData?.writers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.producers.length > 0 && {
        producers: personnelData?.producers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(lineProducers.length > 0 && {
        lineProducers: lineProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(personnelData?.executiveProducers.length > 0 && {
        executiveProducers: personnelData?.executiveProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
    };

    const lineProdErrorsF: any = validateArr(lineProdErrors, setLineProdErrors);

    if (validateArrErrors(lineProdErrorsF)) {
      fireUpdatePersonnelApi({
        variables: {
          payload: {
            id,
            keyPersonals: JSON.stringify(keyPersonalsJSON),
          },
        },
      });
    }
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <>
      <Formik
        initialValues={personnelData}
        validationSchema={Yup.object().shape({
          directors: Yup.array().of(
            Yup.object().shape({
              email: Yup.string().email("Please enter a valid email id"),
            })
          ),
        })}
        onSubmit={handleUpdateDirectors}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          handleSubmit,
        }) => (
          <Box>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={styles.flex_Acenter}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 600, flexGrow: 1 }}
                  >
                    Directors
                  </Typography>
                  {Boolean(ROLE_PERMISSIONS) &&
                  projectEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                    ?.PROJECT_EDIT ? (
                    editDirectors ? (
                      <IconButton size="small" onClick={() => handleSubmit()}>
                        <CheckOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => setEditDirectors(true)}
                      >
                        <ModeEditOutlineOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={4}>
                  <FieldArray
                    name="directors"
                    render={(arrayHelpers: any) => (
                      <Fragment>
                        {values.directors.map((_, index) => (
                          <Fragment key={index}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <Typography sx={styles.field_label}>
                                Name
                              </Typography>
                              <TextField
                                id={`directors[${index}].name`}
                                value={values.directors[index].name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                disabled={!editDirectors}
                                inputProps={{ style: styles.input_field }}
                              />
                              {touched?.directors &&
                                errors?.directors &&
                                errors?.directors[index] && (
                                  <Typography sx={styles.error_text}>
                                    {(errors?.directors[index] as IPerson).name}
                                  </Typography>
                                )}
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              sx={{ position: "relative" }}
                            >
                              <Typography sx={styles.field_label}>
                                Email
                              </Typography>
                              <TextField
                                id={`directors[${index}].email`}
                                value={values.directors[index].email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                disabled={!editDirectors}
                                inputProps={{ style: styles.input_field }}
                              />
                              {touched?.directors &&
                                errors?.directors &&
                                errors?.directors[index] && (
                                  <Typography sx={styles.error_text}>
                                    {
                                      (errors?.directors[index] as IPerson)
                                        .email
                                    }
                                  </Typography>
                                )}
                              {editDirectors && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: -40,
                                    top: 60,
                                  }}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <RemoveCircleOutlineOutlined
                                      fontSize="small"
                                      sx={{ color: "#DF7D8C" }}
                                    />
                                  </IconButton>
                                </Box>
                              )}
                            </Grid>
                            {dirErrors && dirErrors.length > 0 && (
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{ mt: -2.5 }}>
                                  <Typography sx={styles.error_text}>
                                    {dirErrors[index]}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Fragment>
                        ))}
                        {editDirectors && (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box
                              sx={{
                                mt: values.directors.length > 0 ? -2.5 : 0,
                              }}
                            >
                              <NewPrimaryGreenTextButton
                                variant="text"
                                size="small"
                                startIcon={
                                  <Add
                                    fontSize="small"
                                    sx={{ color: "#41B19995" }}
                                  />
                                }
                                onClick={() =>
                                  arrayHelpers.push({ name: "", email: "" })
                                }
                              >
                                Add
                              </NewPrimaryGreenTextButton>
                            </Box>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
      <Formik
        initialValues={personnelData}
        validationSchema={Yup.object().shape({
          producers: Yup.array().of(
            Yup.object().shape({
              email: Yup.string().email("Please enter a valid email id"),
            })
          ),
        })}
        onSubmit={handleUpdateProducers}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          handleSubmit,
        }) => (
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={styles.flex_Acenter}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 600, flexGrow: 1 }}
                  >
                    Producers
                  </Typography>
                  {Boolean(ROLE_PERMISSIONS) &&
                  projectEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                    ?.PROJECT_EDIT ? (
                    editProducers ? (
                      <IconButton size="small" onClick={() => handleSubmit()}>
                        <CheckOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => setEditProducers(true)}
                      >
                        <ModeEditOutlineOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={4}>
                  <FieldArray
                    name="producers"
                    render={(arrayHelpers: any) => (
                      <Fragment>
                        {values.producers.map((_, index) => (
                          <Fragment key={index}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <Typography sx={styles.field_label}>
                                Name
                              </Typography>
                              <TextField
                                id={`producers[${index}].name`}
                                value={values.producers[index].name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                disabled={!editProducers}
                                inputProps={{ style: styles.input_field }}
                              />
                              {touched?.producers &&
                                errors?.producers &&
                                errors?.producers[index] && (
                                  <Typography sx={styles.error_text}>
                                    {(errors?.producers[index] as IPerson).name}
                                  </Typography>
                                )}
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              sx={{ position: "relative" }}
                            >
                              <Typography sx={styles.field_label}>
                                Email
                              </Typography>
                              <TextField
                                id={`producers[${index}].email`}
                                value={values.producers[index].email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                disabled={!editProducers}
                                inputProps={{ style: styles.input_field }}
                              />
                              {touched?.producers &&
                                errors?.producers &&
                                errors?.producers[index] && (
                                  <Typography sx={styles.error_text}>
                                    {
                                      (errors?.producers[index] as IPerson)
                                        .email
                                    }
                                  </Typography>
                                )}
                              {editProducers && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: -40,
                                    top: 60,
                                  }}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <RemoveCircleOutlineOutlined
                                      fontSize="small"
                                      sx={{ color: "#DF7D8C" }}
                                    />
                                  </IconButton>
                                </Box>
                              )}
                            </Grid>
                            {prodErrors && prodErrors.length > 0 && (
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{ mt: -2.5 }}>
                                  <Typography sx={styles.error_text}>
                                    {prodErrors[index]}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Fragment>
                        ))}
                        {editProducers && (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box
                              sx={{
                                mt: values.producers.length > 0 ? -2.5 : 0,
                              }}
                            >
                              <NewPrimaryGreenTextButton
                                variant="text"
                                size="small"
                                startIcon={
                                  <Add
                                    fontSize="small"
                                    sx={{ color: "#41B19995" }}
                                  />
                                }
                                onClick={() =>
                                  arrayHelpers.push({ name: "", email: "" })
                                }
                              >
                                Add
                              </NewPrimaryGreenTextButton>
                            </Box>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
      <Formik
        initialValues={personnelData}
        validationSchema={Yup.object().shape({
          writers: Yup.array().of(
            Yup.object().shape({
              email: Yup.string().email("Please enter a valid email id"),
            })
          ),
        })}
        onSubmit={handleUpdateWriters}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          handleSubmit,
        }) => (
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={styles.flex_Acenter}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 600, flexGrow: 1 }}
                  >
                    Writers
                  </Typography>
                  {Boolean(ROLE_PERMISSIONS) &&
                  projectEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                    ?.PROJECT_EDIT ? (
                    editWriters ? (
                      <IconButton size="small" onClick={() => handleSubmit()}>
                        <CheckOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => setEditWriters(true)}
                      >
                        <ModeEditOutlineOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={4}>
                  <FieldArray
                    name="writers"
                    render={(arrayHelpers: any) => (
                      <Fragment>
                        {values.writers.map((_, index) => (
                          <Fragment key={index}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <Typography sx={styles.field_label}>
                                Name
                              </Typography>
                              <TextField
                                id={`writers[${index}].name`}
                                value={values.writers[index].name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                disabled={!editWriters}
                                inputProps={{ style: styles.input_field }}
                              />
                              {touched?.writers &&
                                errors?.writers &&
                                errors?.writers[index] && (
                                  <Typography sx={styles.error_text}>
                                    {(errors?.writers[index] as IPerson).name}
                                  </Typography>
                                )}
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              sx={{ position: "relative" }}
                            >
                              <Typography sx={styles.field_label}>
                                Email
                              </Typography>
                              <TextField
                                id={`writers[${index}].email`}
                                value={values.writers[index].email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                disabled={!editWriters}
                                inputProps={{ style: styles.input_field }}
                              />
                              {touched?.writers &&
                                errors?.writers &&
                                errors?.writers[index] && (
                                  <Typography sx={styles.error_text}>
                                    {(errors?.writers[index] as IPerson).email}
                                  </Typography>
                                )}
                              {editWriters && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: -40,
                                    top: 60,
                                  }}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <RemoveCircleOutlineOutlined
                                      fontSize="small"
                                      sx={{ color: "#DF7D8C" }}
                                    />
                                  </IconButton>
                                </Box>
                              )}
                            </Grid>
                            {writerErrors && writerErrors.length > 0 && (
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{ mt: -2.5 }}>
                                  <Typography sx={styles.error_text}>
                                    {writerErrors[index]}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Fragment>
                        ))}
                        {editWriters && (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box
                              sx={{
                                mt: values.writers.length > 0 ? -2.5 : 0,
                              }}
                            >
                              <NewPrimaryGreenTextButton
                                variant="text"
                                size="small"
                                startIcon={
                                  <Add
                                    fontSize="small"
                                    sx={{ color: "#41B19995" }}
                                  />
                                }
                                onClick={() =>
                                  arrayHelpers.push({ name: "", email: "" })
                                }
                              >
                                Add
                              </NewPrimaryGreenTextButton>
                            </Box>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
      <Formik
        initialValues={personnelData}
        validationSchema={Yup.object().shape({
          executiveProducers: Yup.array().of(
            Yup.object().shape({
              email: Yup.string().email("Please enter a valid email id"),
            })
          ),
        })}
        onSubmit={handleUpdateExecutiveProds}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          handleSubmit,
        }) => (
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={styles.flex_Acenter}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 600, flexGrow: 1 }}
                  >
                    Executive Producers
                  </Typography>
                  {Boolean(ROLE_PERMISSIONS) &&
                  projectEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                    ?.PROJECT_EDIT ? (
                    editExecutiveProds ? (
                      <IconButton size="small" onClick={() => handleSubmit()}>
                        <CheckOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => setEditExecutiveProds(true)}
                      >
                        <ModeEditOutlineOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={4}>
                  <FieldArray
                    name="executiveProducers"
                    render={(arrayHelpers: any) => (
                      <Fragment>
                        {values.executiveProducers.map((_, index) => (
                          <Fragment key={index}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <Typography sx={styles.field_label}>
                                Name
                              </Typography>
                              <TextField
                                id={`executiveProducers[${index}].name`}
                                value={values.executiveProducers[index].name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                disabled={!editExecutiveProds}
                                inputProps={{ style: styles.input_field }}
                              />
                              {touched?.executiveProducers &&
                                errors?.executiveProducers &&
                                errors?.executiveProducers[index] && (
                                  <Typography sx={styles.error_text}>
                                    {
                                      (
                                        errors?.executiveProducers[
                                          index
                                        ] as IPerson
                                      ).name
                                    }
                                  </Typography>
                                )}
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              sx={{ position: "relative" }}
                            >
                              <Typography sx={styles.field_label}>
                                Email
                              </Typography>
                              <TextField
                                id={`executiveProducers[${index}].email`}
                                value={values.executiveProducers[index].email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                disabled={!editExecutiveProds}
                                inputProps={{ style: styles.input_field }}
                              />
                              {touched?.executiveProducers &&
                                errors?.executiveProducers &&
                                errors?.executiveProducers[index] && (
                                  <Typography sx={styles.error_text}>
                                    {
                                      (
                                        errors?.executiveProducers[
                                          index
                                        ] as IPerson
                                      ).email
                                    }
                                  </Typography>
                                )}
                              {editExecutiveProds && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: -40,
                                    top: 60,
                                  }}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <RemoveCircleOutlineOutlined
                                      fontSize="small"
                                      sx={{ color: "#DF7D8C" }}
                                    />
                                  </IconButton>
                                </Box>
                              )}
                            </Grid>
                            {executiveProdErrors &&
                              executiveProdErrors.length > 0 && (
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                  <Box sx={{ mt: -2.5 }}>
                                    <Typography sx={styles.error_text}>
                                      {executiveProdErrors[index]}
                                    </Typography>
                                  </Box>
                                </Grid>
                              )}
                          </Fragment>
                        ))}
                        {editExecutiveProds && (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box
                              sx={{
                                mt:
                                  values.executiveProducers.length > 0
                                    ? -2.5
                                    : 0,
                              }}
                            >
                              <NewPrimaryGreenTextButton
                                variant="text"
                                size="small"
                                startIcon={
                                  <Add
                                    fontSize="small"
                                    sx={{ color: "#41B19995" }}
                                  />
                                }
                                onClick={() =>
                                  arrayHelpers.push({ name: "", email: "" })
                                }
                              >
                                Add
                              </NewPrimaryGreenTextButton>
                            </Box>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
      <Formik
        initialValues={personnelData}
        validationSchema={Yup.object().shape({
          lineProducers: Yup.array().of(
            Yup.object().shape({
              email: Yup.string().email("Please enter a valid email id"),
            })
          ),
        })}
        onSubmit={handleUpdateLineProds}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          handleSubmit,
        }) => (
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={styles.flex_Acenter}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 600, flexGrow: 1 }}
                  >
                    Line Producers
                  </Typography>
                  {Boolean(ROLE_PERMISSIONS) &&
                  projectEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                    ?.PROJECT_EDIT ? (
                    editLineProds ? (
                      <IconButton size="small" onClick={() => handleSubmit()}>
                        <CheckOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => setEditLineProds(true)}
                      >
                        <ModeEditOutlineOutlined
                          fontSize="small"
                          sx={{ color: "#00000055" }}
                        />
                      </IconButton>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={4}>
                  <FieldArray
                    name="lineProducers"
                    render={(arrayHelpers: any) => (
                      <Fragment>
                        {values.lineProducers.map((_, index) => (
                          <Fragment key={index}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <Typography sx={styles.field_label}>
                                Name
                              </Typography>
                              <TextField
                                id={`lineProducers[${index}].name`}
                                value={values.lineProducers[index].name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                disabled={!editLineProds}
                                inputProps={{ style: styles.input_field }}
                              />
                              {touched?.lineProducers &&
                                errors?.lineProducers &&
                                errors?.lineProducers[index] && (
                                  <Typography sx={styles.error_text}>
                                    {
                                      (errors?.lineProducers[index] as IPerson)
                                        .name
                                    }
                                  </Typography>
                                )}
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              sx={{ position: "relative" }}
                            >
                              <Typography sx={styles.field_label}>
                                Email
                              </Typography>
                              <TextField
                                id={`lineProducers[${index}].email`}
                                value={values.lineProducers[index].email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                                fullWidth
                                disabled={!editLineProds}
                                inputProps={{ style: styles.input_field }}
                              />
                              {touched?.lineProducers &&
                                errors?.lineProducers &&
                                errors?.lineProducers[index] && (
                                  <Typography sx={styles.error_text}>
                                    {
                                      (errors?.lineProducers[index] as IPerson)
                                        .email
                                    }
                                  </Typography>
                                )}
                              {editLineProds && (
                                <Box
                                  sx={{
                                    position: "absolute",
                                    right: -40,
                                    top: 60,
                                  }}
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <RemoveCircleOutlineOutlined
                                      fontSize="small"
                                      sx={{ color: "#DF7D8C" }}
                                    />
                                  </IconButton>
                                </Box>
                              )}
                            </Grid>
                            {lineProdErrors && lineProdErrors.length > 0 && (
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{ mt: -2.5 }}>
                                  <Typography sx={styles.error_text}>
                                    {lineProdErrors[index]}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Fragment>
                        ))}
                        {editLineProds && (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box
                              sx={{
                                mt: values.lineProducers.length > 0 ? -2.5 : 0,
                              }}
                            >
                              <NewPrimaryGreenTextButton
                                variant="text"
                                size="small"
                                startIcon={
                                  <Add
                                    fontSize="small"
                                    sx={{ color: "#41B19995" }}
                                  />
                                }
                                onClick={() =>
                                  arrayHelpers.push({ name: "", email: "" })
                                }
                              >
                                Add
                              </NewPrimaryGreenTextButton>
                            </Box>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
}

export default AllForms;
