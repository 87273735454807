import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import { useStore } from "utils/store";
import { GET_TEMPLATE_TYPES } from "graphql/templates";
import { CREATE_INDEX, GET_INDEX_DETAILS, UPDATE_INDEX } from "graphql/indexes";
import { BlackActionContainedButton } from "common/Buttons";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface IIndexFields {
  name: string;
  templateTypeIds: string[];
}

interface IAddEditClearanceModalProps extends IModalProps {
  selectedIndex: string;
  setSelectedIndex: Dispatch<SetStateAction<string>>;
  refetchTableData: any;
}

const AddEditClearanceModal = (props: IAddEditClearanceModalProps) => {
  const { open, setOpen, selectedIndex, setSelectedIndex, refetchTableData } =
    props;

  const { setLoading } = useStore();
  const projectId = useParams();

  const fetchClearanceTemplateId = (templateTypesArrT: IArr[]) => {
    const findC = templateTypesArrT.find((x: any) => x.name === "Clearances");
    if (Boolean(findC)) {
      return [findC?.id] as string[];
    } else {
      return [""];
    }
  };

  const [initialValues, setInitialValues] = useState<IIndexFields>({
    name: "",
    templateTypeIds: [""],
  });
  const [templateTypeArr, setTemplateTypeArr] = useState<IArr[]>([]);

  const [getIndexDetails, { loading: gettingDetails }] = useLazyQuery(
    GET_INDEX_DETAILS,
    {
      onCompleted: (data) => {
        // setLoading(false);
        const { getRegister } = data;
        const { status, register } = getRegister;
        if (status) {
          const { name, templateTypes } = register;
          setInitialValues({
            name,
            templateTypeIds: templateTypes.map((x: IArr) => x.id),
          });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const { loading: gettingArr } = useQuery(GET_TEMPLATE_TYPES, {
    variables: {},
    onCompleted: (data) => {
      const { templateTypes } = data;
      const { status, templateTypes: rawTemplateTypes } = templateTypes;
      if (status) {
        setTemplateTypeArr(rawTemplateTypes);
        if (!Boolean(selectedIndex)) {
          setInitialValues({
            name: "",
            templateTypeIds: fetchClearanceTemplateId(rawTemplateTypes),
          });
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setSelectedIndex("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireCreateIndexApi, { loading: adding }] = useMutation(CREATE_INDEX, {
    onCompleted: (data) => {
      setLoading(false);
      const { createRegister } = data;
      const { status, message } = createRegister;
      handleClose();
      if (status) {
        toast.success(message);
        refetchTableData();
      } else {
        toast.error(message);
      }
    },
  });

  const [fireUpdateIndexApi, { loading: updating }] = useMutation(
    UPDATE_INDEX,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateRegister } = data;
        const { status, message } = updateRegister;
        handleClose();
        if (status) {
          toast.success(message);
          refetchTableData();
        } else {
          toast.error(message);
        }
      },
    }
  );

  const handleUpsert = (values: IIndexFields) => {
    const { templateTypeIds, ...rest } = values;
    if (Boolean(selectedIndex)) {
      fireUpdateIndexApi({
        variables: {
          payload: {
            id: selectedIndex,
            templateTypeIds: templateTypeIds.map((x) => parseInt(x)),
            ...rest,
          },
        },
      });
    } else {
      fireCreateIndexApi({
        variables: {
          payload: {
            projectId: projectId?.project_id,
            templateTypeIds: templateTypeIds.map((x) => parseInt(x)),
            ...rest,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (Boolean(selectedIndex)) {
      getIndexDetails({
        variables: {
          payload: {
            projectId: projectId?.project_id,
            registerId: selectedIndex,
          },
        },
      });
    }
  }, [getIndexDetails, projectId?.project_id, selectedIndex]);

  useEffect(() => {
    setLoading(adding || gettingArr || gettingDetails || updating);
  }, [adding, gettingArr, gettingDetails, setLoading, updating]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleUpsert}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required("Please enter the folder name")
          .matches(/^[^/]*$/, "Folder name cannot contain '/'"),
        templateTypeIds: Yup.array()
          .of(Yup.string())
          .min(1, "Please select atleast 1 template type"),
      })}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="sm"
          PaperProps={{ sx: { borderRadius: "24px" } }}
        >
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  py: 2,
                  backgroundColor: "#B6BCC325",
                  position: "relative",
                  px: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      fontSize: "13px",
                      color: colors.primaryBlack,
                      fontWeight: 600,
                    }}
                  >
                    Clearance Folder
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                <IconButton size="small" onClick={handleClose}>
                  <Close sx={{ color: "#00000055" }} fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ pt: 4, px: 6, pb: 3 }}>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      Folder Name <span style={styles.error_text}>*</span>
                    </Typography>
                    <TextField
                      id="name"
                      size="small"
                      fullWidth
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.name && errors?.name && (
                      <Typography sx={styles.error_text}>
                        {errors?.name}
                      </Typography>
                    )}
                  </Grid>
                  {Boolean(selectedIndex) && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        Template Types <span style={styles.error_text}>*</span>
                      </Typography>
                      <Autocomplete
                        disabled={Boolean(selectedIndex)}
                        id="templateTypeIds"
                        multiple
                        value={
                          templateTypeArr.length > 0
                            ? templateTypeArr.filter((x) =>
                                values.templateTypeIds.includes(x.id)
                              )
                            : []
                        }
                        onChange={(_, newValue) =>
                          setFieldValue(
                            "templateTypeIds",
                            newValue.map((x) => x.id)
                          )
                        }
                        sx={styles.input_field}
                        size="small"
                        disableClearable
                        fullWidth
                        options={templateTypeArr}
                        getOptionLabel={(option) => option.name}
                        // ChipProps={{
                        //   sx: {
                        //     borderRadius: "4px",
                        //     backgroundColor: "#285EC720",
                        //     color: colors.primaryBlack,
                        //   },
                        //   deleteIcon: <Close fontSize="small" />,
                        // }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                        )}
                      />
                      {touched?.templateTypeIds && errors?.templateTypeIds && (
                        <Typography sx={styles.error_text}>
                          {errors?.templateTypeIds}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box sx={styles.flex_JCfe}>
                      <BlackActionContainedButton
                        size="small"
                        variant="contained"
                        sx={{ width: "120px", borderRadius: "20px" }}
                        disableElevation
                        onClick={() => handleSubmit()}
                        disabled={!isValid}
                      >
                        Save
                      </BlackActionContainedButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddEditClearanceModal;
