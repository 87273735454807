export const initialValues = {
	id: "",
	companyName: "",
	spocName: "",
	spocMobile: {
		countryCode: "61",
		nationalNumber: null,
		rawInput: "",
	},
	spocEmailId: "",
	website: "",
	unit: "",
	address: null,
	companyCountry: "",
	companyState: "",
	companyCity: "",
	street: "",
	suburb: "",
	pinCode: "",
	abn: "",
	acn: "",
	registrationNumber: "",
	publicLiabilityPolicy: "",
	provider: "",
	policyNumber: "",
	insurancePeriod: "",
	financialDetails: {
		accountName: "",
		accountNumber: "",
		accountBsb: "",
		accountSwiftCode: "",
		bankName: "",
		branchName: "",
		accountInstructions: "",
		abn: "",
		taxFileNo: "",
		haveSuperannuationAccount: "",
		superannuationFundName: "",
		memberNumber: "",
		smsfName: "",
		smsfAbn: "",
		smsfEsa: "",
		smsfFullName: "",
		smsfBankAccountName: "",
		smsfBsb: "",
		superannuationAccountNo: "",
	},
	profile_picture: null,
};

export const setAgentDetails = (agencyDetails: any, setValues: any) => {
	setValues({
		id: agencyDetails?.id || "",
		companyName: agencyDetails?.companyName || "",
		spocName: agencyDetails?.spocName || "",
		spocMobile: {
			countryCode: agencyDetails?.spocMobile?.countryCode || "61",
			nationalNumber: agencyDetails?.spocMobile?.nationalNumber || null,
			rawInput: agencyDetails?.spocMobile?.rawInput || "",
		},
		spocEmailId: agencyDetails?.spocEmailId || "",
		website: agencyDetails?.website || "",
		unit: agencyDetails?.unit || "",
		address: Boolean(agencyDetails?.address) ? agencyDetails?.address : null,
		companyCountry: agencyDetails?.companyCountry || "",
		companyState: agencyDetails?.companyState || "",
		companyCity: agencyDetails?.companyCity || "",
		street: agencyDetails?.street || "",
		suburb: agencyDetails?.suburb || "",
		pinCode: agencyDetails?.pinCode || "",
		abn: agencyDetails?.abn || "",
		acn: agencyDetails?.acn || "",
		registrationNumber: agencyDetails?.registrationNumber || "",
		publicLiabilityPolicy: agencyDetails?.publicLiabilityPolicy || "",
		provider: agencyDetails?.provider || "",
		policyNumber: agencyDetails?.policyNumber || "",
		insurancePeriod: agencyDetails?.insurancePeriod || "",
		financialDetails: {
			accountName: agencyDetails?.agentFinancialDetails?.accountName || "",
			accountNumber:
				agencyDetails?.agentFinancialDetails?.accountNumber || "",
			accountBsb: agencyDetails?.agentFinancialDetails?.accountBsb || "",
			accountSwiftCode:
				agencyDetails?.agentFinancialDetails?.accountSwiftCode || "",
			bankName: agencyDetails?.agentFinancialDetails?.bankName || "",
			branchName: agencyDetails?.agentFinancialDetails?.branchName || "",
			accountInstructions:
				agencyDetails?.agentFinancialDetails?.accountInstructions || "",
			abn: agencyDetails?.agentFinancialDetails?.abn || "",
			superannuationFundName:
				agencyDetails?.agentFinancialDetails?.superannuationFundName || "",
			superannuationAccountNo:
				agencyDetails?.agentFinancialDetails?.superannuationAccountNo || "",
			taxFileNo: agencyDetails?.agentFinancialDetails?.taxFileNo || "",
		},
		profile_picture: agencyDetails?.photo || null,
	});
};
