import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { NotAuthorizedPage } from "pages";
import { clearSession, getJwtTokenDetails } from "utils/storage";

interface IProtectedRouteProps {
  roles: string[];
  children: React.ReactNode;
}

function ProtectedRoute(props: IProtectedRouteProps) {
  const { roles, children } = props;
  const { hasExpired, roles: tokenRoles } = getJwtTokenDetails();

  const [authorizedPage, setAuthorizedPage] = useState<any>(null);

  useEffect(() => {
    if (roles && roles.length > 0) {
      const roleIntersection = roles.filter((value: string) =>
        tokenRoles.includes(value)
      );
      if (roleIntersection.length === 0) {
        setAuthorizedPage(<NotAuthorizedPage />);
      } else setAuthorizedPage(children);
    }
  }, [children, roles, tokenRoles]);

  useEffect(() => {
    if (hasExpired) {
      clearSession();
    }
  }, [hasExpired]);

  return !hasExpired ? authorizedPage : <Navigate to="/" />;
}

export default ProtectedRoute;
