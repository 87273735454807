import { colors } from "theme/colors";

export const AdminDashboardStyles = {
  card: { p: 3, boxShadow: "1px 1px 8px #d3d3d3" },
  flex_Acenter: { display: "flex", alignItems: "center" },
  ml2: { ml: 2 },
  ml1: { ml: 1 },
  mt1: { mt: 1 },
  stat_title: {
    color: colors.secondaryBlack,
    fontSize: "16px",
    fontWeight: 500,
  },
  stat_count_text: { color: colors.primary, fontSize: "25px", fontWeight: 600 },
  titleText: { color: colors.primaryBlack, fontSize: "20px", fontWeight: 500 },
  subTitleText: { fontSize: "13px", fontWeight: 500, color: colors.grey },
  header_textfield: { width: "140px", mr: 2 },
  header_input: { fontSize: " 13px", padding: 4 },
  template_type_container: { height: 513, overflowX: "auto" },
  template_name_text: { color: "#5E5873", fontSize: "16px", fontWeight: 500 },
  template_count_text: { color: "#6E6B7B", fontSize: "16px", fontWeight: 500 },
  pending_approvals_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    p: 3,
  },
  table_header_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  table: {
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      backgroundColor: `${colors.primary}10`,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: colors.primaryBlack,
      fontSize: "16px",
      fontWeight: 500,
    },
  },
  text_input: { fontSize: "14px", color: colors.primaryBlack },
  cell_text: {
    fontSize: "13px",
    color: colors.secondaryBlack,
    fontWeight: 500,
  },
  chip: { color: "#18B370", backgroundColor: "#E0F9EE" },
  modal_header_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  flex_JCsb_Acenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modal_btn: { fontSize: "16px", fontWeight: 500 },
  divider: { color: colors.borderColor },
  project_status_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: colors.secondaryBlack,
  },
  project_status_count_text: {
    fontSize: "20px",
    fontWeight: 500,
    color: colors.primaryBlack,
  },
  project_type_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#5E5873",
  },
  max_h_scroll: {
    height: "380px",
    overflowY: "hidden",
    ":hover": { overflowY: "auto" },
  },
  all_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  total_clients_container: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    flexDirection: "column",
  },
  total_clients_text: {
    textAlign: "center",
    fontSize: "20px",
    color: colors.secondaryBlack,
    fontWeight: 500,
    mt: 1.5,
  },
  total_clients_count_text: {
    textAlign: "center",
    fontSize: "25px",
    color: colors.primary,
    fontWeight: 600,
  },
  clients_img_bg: { alignSelf: "center" },
};
