import { colors } from "theme/colors";

export const DashboardStyles = {
  card: { p: 3, boxShadow: "1px 1px 8px #d3d3d3" },
  titleText: {
    fontSize: "14px",
    color: `${colors.primaryBlack}50`,
  },
  subTitleText: { fontSize: "13px", fontWeight: 500, color: colors.grey },
  header_textfield: { width: "140px", mr: 2 },
  header_input: { fontSize: " 13px", padding: 4 },
  divider: { color: colors.borderColor, mb: 2 },
  avatar: {
    "& .MuiAvatar-root": {
      width: "32px",
      height: "32px",
      backgroundColor: "transparent",
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      mr: 0.35,
      fontSize: "11px",
    },
  },
  contract_reel_text_container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "50px",
  },
  project_name_text: {
    fontSize: "14px",
    fontWeight: 600,
    color: colors.primaryBlack,
  },
  proj_meta_data_text: {
    fontSize: "13px",
    color: `${colors.primaryBlack}70`,
  },
  project_details_text: {
    fontSize: "13px",
    fontWeight: 500,
    color: colors.primaryBlack,
  },
  height50_flexend: {
    display: "flex",
    alignItems: "flex-end",
    height: "50px",
  },
  ml2: { ml: 2 },
  progress_bar_container: { width: "240px" },
  progress_bar: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#dedede",
  },
  progress_bar_text_container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  progress_bar_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
  },
  project_stat_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: 2,
  },
  project_stat_title_text: {
    fontSize: "13px",
    color: colors.grey,
    fontWeight: 500,
  },
  project_stat_metric_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  text_align_center: { textAlign: "center" },
  contract_title_percent_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#5E5873",
  },
  avatar_renewal: {
    width: "32px",
    height: "32px",
    backgroundColor: "transparent",
    border: `1px solid ${colors.primary}`,
    color: colors.primary,
    mr: 0.35,
    fontSize: "11px",
  },
  project_text: { fontSize: "13px", fontWeight: 500, color: colors.grey },
  flex_Acenter: { display: "flex", alignItems: "center" },
  mt2: { mt: 2 },
  pie_chart_legend: { width: "40px", height: "40px" },
  ml1: { ml: 1 },
  legend_label: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.secondaryBlack,
  },
  legend_value: { fontSize: "18px", color: colors.primary },
  project_item_container: { cursor: "pointer" },
  recent_projects_container: {
    width: "100%",
    display: "flex",
    overflowX: "auto",
  },
  scroll_btn: {
    position: "absolute",
    top: "300px",
    display: { xs: "none", sm: "block" },
  },
  btn_avatar: { backgroundColor: colors.primary },
  proj_img: {
    height: "280px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  project_details_container: { p: 2 },
  project_title_text: {
    fontSize: "16px",
    fontWeight: 500,
    color: colors.primary,
  },
  proj_status_chip: { mt: 1.5, fontSize: "13px", fontWeight: 600 },
  flex: { display: "flex" },
  activity_text: {
    color: colors.secondaryBlack,
    fontSize: "16px",
    fontWeight: 500,
  },
  activity_author_text: {
    color: colors.primary,
    fontSize: "14px",
    fontWeight: 500,
  },
  activity_timestamp_text: {
    color: colors.grey,
    fontSize: "13px",
    fontWeight: 500,
    mt: 1,
  },
  start_date_chip: {
    fontSize: "13px",
    fontWeight: 600,
    backgroundColor: `#DE324C30`,
    color: "#D45659",
    px: 1,
    py: 0.5,
  },
  end_date_chip: {
    fontSize: "13px",
    fontWeight: 600,
    backgroundColor: `#B2D5EB70`,
    color: "#227BD4",
    px: 1,
    py: 0.5,
  },
  date_text: { color: colors.grey, fontSize: "13px", fontWeight: 500 },
  card_h_overflow: {
    height: "580px",
    overflowY: "hidden",
    ":hover": { overflowY: "auto" },
  },
  flex_JCfe: {
    display: "flex",
    justifyContent: { xs: "flex-start", sm: "flex-end" },
  },
};
