import { colors } from "theme/colors";

export const ManageAdminsStyles = {
  filter_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    px: 2.5,
    py: 3.5,
  },
  text_input: { fontSize: "14px", color: colors.primaryBlack },
  table: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      backgroundColor: `${colors.primary}10`,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: colors.primaryBlack,
      fontSize: "16px",
      fontWeight: 500,
    },
  },
  cell_text: {
    fontSize: "13px",
    color: colors.secondaryBlack,
    fontWeight: 500,
  },
  chip: { color: colors.primary, backgroundColor: `${colors.primary}20` },
  actions_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  flex_JCsb_Acenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  add_admin_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  profile_pic_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    my: 3,
  },
  profile_pic_avatar: { height: "100px", width: "100px", mx: 4 },
  field_label: {
    color: colors.grey,
    fontSize: "14px",
    fontWeight: 500,
    mb: 0.75,
  },
  error_text: { fontSize: "12px", color: "#D3010E" },
  flex_Acenter: { display: "flex", alignItems: "center" },
  disabled_btn: {
    "&.Mui-disabled": {
      backgroundColor: colors.grey,
      color: "#c0c0c0",
    },
  },
  modal_footer_container: { p: 2, backgroundColor: `${colors.primary}20` },
};
