import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import {
  Add,
  CheckOutlined,
  ModeEditOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { NewPrimaryGreenTextButton } from "common/Buttons";
import { UPDATE_PROJECT } from "graphql/projects";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";

import { IKeyPersonnelFields, IPerson } from "./KeyPersonnel";
import { validateArr, validateArrErrors } from "./utils";
import { ProjectDetailsStyles as styles } from "../styles";

interface IProducersFormProps {
  personnelData: IKeyPersonnelFields;
  refetch: any;
}

function ProducersForm(props: IProducersFormProps) {
  const { personnelData, refetch } = props;

  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x?.hasOwnProperty("PROJECT_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [editKeyPersonnel, setEditKeyPersonnel] = useState(false);
  const [prodErrors, setProdErrors] = useState<string[]>([]);

  const [fireUpdatePersonnelApi, { loading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: (data) => {
      setLoading(false);
      const { updateProject } = data;
      const { status, message } = updateProject;
      setEditKeyPersonnel(false);
      if (status) {
        refetch();
        toast.success(message);
      } else {
        toast.error(message);
      }
    },
  });

  const handleUpdatePersonnel = (values: IKeyPersonnelFields) => {
    const {
      id,
      director,
      producer,
      writer,
      directors,
      producers,
      writers,
      lineProducers,
      executiveProducers,
    } = values;

    const keyPersonalsJSON = {
      ...(directors.length > 0 && {
        directors: directors.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(writers.length > 0 && {
        writers: writers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(producers.length > 0 && {
        producers: producers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(lineProducers.length > 0 && {
        lineProducers: lineProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
      ...(executiveProducers.length > 0 && {
        executiveProducers: executiveProducers.map((x) => ({
          ...x,
          email: x.email.trim(),
        })),
      }),
    };

    const prodErrorsF: any = validateArr(producers, setProdErrors);

    if (validateArrErrors(prodErrorsF)) {
      fireUpdatePersonnelApi({
        variables: {
          payload: {
            id,
            keyPersonals: JSON.stringify(keyPersonalsJSON),
          },
        },
      });
    }
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Formik
      initialValues={personnelData}
      validationSchema={Yup.object().shape({
        producers: Yup.array().of(
          Yup.object().shape({
            email: Yup.string().email("Please enter a valid email id"),
          })
        ),
      })}
      onSubmit={handleUpdatePersonnel}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Box>
          <Box sx={{ position: "absolute", top: 165, right: 20 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {Boolean(ROLE_PERMISSIONS) &&
              projectEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]
                ?.PROJECT_EDIT ? (
                editKeyPersonnel ? (
                  <IconButton size="small" onClick={() => handleSubmit()}>
                    <CheckOutlined
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    onClick={() => setEditKeyPersonnel(true)}
                  >
                    <ModeEditOutlineOutlined
                      fontSize="small"
                      sx={{ color: "#00000055" }}
                    />
                  </IconButton>
                )
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Grid container spacing={4}>
            <FieldArray
              name="producers"
              render={(arrayHelpers: any) => (
                <Fragment>
                  {values.producers.map((_, index) => (
                    <Fragment key={index}>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Typography sx={styles.field_label}>Name</Typography>
                        <TextField
                          id={`producers[${index}].name`}
                          value={values.producers[index].name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          disabled={!editKeyPersonnel}
                          inputProps={{ style: styles.input_field }}
                        />
                        {touched?.producers &&
                          errors?.producers &&
                          errors?.producers[index] && (
                            <Typography sx={styles.error_text}>
                              {(errors?.producers[index] as IPerson).name}
                            </Typography>
                          )}
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        sx={{ position: "relative" }}
                      >
                        <Typography sx={styles.field_label}>Email</Typography>
                        <TextField
                          id={`producers[${index}].email`}
                          value={values.producers[index].email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          size="small"
                          fullWidth
                          disabled={!editKeyPersonnel}
                          inputProps={{ style: styles.input_field }}
                        />
                        {touched?.producers &&
                          errors?.producers &&
                          errors?.producers[index] && (
                            <Typography sx={styles.error_text}>
                              {(errors?.producers[index] as IPerson).email}
                            </Typography>
                          )}
                        {editKeyPersonnel && (
                          <Box
                            sx={{ position: "absolute", right: -40, top: 60 }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <RemoveCircleOutlineOutlined
                                fontSize="small"
                                sx={{ color: "#DF7D8C" }}
                              />
                            </IconButton>
                          </Box>
                        )}
                      </Grid>
                      {prodErrors && prodErrors.length > 0 && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Box sx={{ mt: -2.5 }}>
                            <Typography sx={styles.error_text}>
                              {prodErrors[index]}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    </Fragment>
                  ))}
                  {editKeyPersonnel && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box sx={{ mt: values.producers.length > 0 ? -2.5 : 0 }}>
                        <NewPrimaryGreenTextButton
                          variant="text"
                          size="small"
                          startIcon={
                            <Add fontSize="small" sx={{ color: "#41B19995" }} />
                          }
                          onClick={() =>
                            arrayHelpers.push({ name: "", email: "" })
                          }
                        >
                          Add
                        </NewPrimaryGreenTextButton>
                      </Box>
                    </Grid>
                  )}
                </Fragment>
              )}
            />
          </Grid>
        </Box>
      )}
    </Formik>
  );
}

export default ProducersForm;
