import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Chip, Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment";

import { RouteNames } from "routes/routeNames";
import { ProjectStatusMapping, ProjectTypeArr } from "utils/constants";
import { images } from "assets/images";
import { IProject } from "models/projects";
import { getPermissions, getProjectRedirection } from "permissions/utils";
import { colors } from "theme/colors";

import { ProjectStyles as styles } from "./styles";

interface IProjectCardProps {
  proj: IProject;
  setSelectedProjId: Dispatch<SetStateAction<string>>;
  setEditProjectModal: Dispatch<SetStateAction<boolean>>;
  setDeleteProjModal: Dispatch<SetStateAction<boolean>>;
}

function ProjectCard(props: IProjectCardProps) {
  const { proj } = props;

  const navigate = useNavigate();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);

  return (
    <Box
      sx={[
        styles.project_item_container,
        { display: "flex", justifyContent: "center", alignItems: " center" },
      ]}
      onClick={() =>
        navigate(
          `${RouteNames.PROJECTS}/${proj.id}${getProjectRedirection(
            ROLE_PERMISSIONS
          )}`
        )
      }
    >
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {proj?.profilePicture ? (
            <Box sx={styles.proj_img}>
              <img
                src={proj?.profilePicture}
                alt="proj_image"
                style={{
                  height: "300px",
                  width: "202.5px",
                  borderRadius: "20px",
                  objectFit: "cover",
                  aspectRatio: 0.675 / 1,
                  ...(proj?.projectStatus === 2 && {
                    filter: "grayscale(100%)",
                  }),
                }}
              />
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={[
                  styles.proj_img,
                  {
                    backgroundColor: `#BFBDBC22`,
                    aspectRatio: 0.675 / 1,
                    borderRadius: "20px",
                    width: "202.5px",
                  },
                ]}
              >
                <img src={images.FILM_REEL} alt="reel" style={{ height: 50 }} />
              </Box>
            </Box>
          )}
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Chip
            sx={[
              styles.proj_status_chip,
              {
                backgroundColor: (ProjectStatusMapping as any)[
                  proj.projectStatus
                ].bg,
                color: (ProjectStatusMapping as any)[proj.projectStatus].color,
              },
            ]}
            label={(ProjectStatusMapping as any)[proj.projectStatus].name}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Tooltip title={proj?.name}>
            <Typography
              textAlign="center"
              noWrap
              sx={[
                styles.project_name_text,
                {
                  ...(proj?.projectStatus === 2 && {
                    color: `${colors.primaryBlack}50`,
                  }),
                },
              ]}
            >
              {proj?.name}
            </Typography>
          </Tooltip>
          <Typography textAlign="center" noWrap sx={styles.proj_meta_data_text}>
            {`${
              Boolean(proj.projectStartDate)
                ? moment(proj.projectStartDate).format("YYYY")
                : ""
            } | ${
              Boolean(proj.projectType)
                ? ProjectTypeArr.find((x) => x.id === proj.projectType)?.name
                : ""
            }`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProjectCard;
