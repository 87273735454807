import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Dialog, Grid, Typography } from "@mui/material";

import { IModalProps } from "models/common";
import { clearSession } from "utils/storage";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { NewOutlinedBtn, NewRedAlertContainedButton } from "./Buttons";

const LogoutModal = (props: IModalProps) => {
  const { open, setOpen } = props;

  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const AlertImage = () => (
    <img src={images.ALERT_NEW} alt="alert" height={22} />
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "24px", p: 3, width: "320px" } }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={images.ALERT_NEW}
              sx={{ backgroundColor: "#ED1C2420" }}
              slots={{ img: AlertImage }}
            />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              color: colors.primaryBlack,
              textAlign: "center",
            }}
          >
            Logout
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{ fontSize: "13px", textAlign: "center", color: "#00000080" }}
          >
            Are you sure?
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <NewOutlinedBtn
                variant="outlined"
                sx={{
                  color: colors.primaryBlack,
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleClose}
              >
                Cancel
              </NewOutlinedBtn>
            </Box>
            <Box sx={{ ml: 2 }}>
              <NewRedAlertContainedButton
                sx={{
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={() => {
                  navigate("/");
                  clearSession();
                }}
              >
                Logout
              </NewRedAlertContainedButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default LogoutModal;
