import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add,
  FilterAltOutlined,
  HandymanOutlined,
  KeyboardArrowDown,
  PeopleAltOutlined,
  // FormatListBulletedOutlined,
  // GridViewOutlined,
  Search,
  Share,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { useLazyQuery } from "@apollo/client";

import { BlackActionContainedButton, NewOutlinedBtn } from "common/Buttons";
import { useStore } from "utils/store";
import { GET_ACCOUNTING_REPORT, GET_ZIPPED_FILE } from "graphql/indexes";
import { GET_TEMPLATE_EXPORT } from "graphql/templates";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import Filters from "./Filters";
import ExportFeedbackModal from "./ExportFeedbackModal";
import CastFeeCalculator from "./CastFeeCalculator";

interface IHeaderProps {
  setAddEditIndexModal: Dispatch<SetStateAction<boolean>>;
  view: number;
  setView: Dispatch<SetStateAction<number>>;
  setSearchText: Dispatch<SetStateAction<string>>;
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
}

function Header(props: IHeaderProps) {
  const { setAddEditIndexModal, setSearchText, sortBy, setSortBy } = props;

  const { setLoading } = useStore();
  const params = useParams();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const foldersEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );
  const [searchTextFieldValue, setSearchTextFieldValue] = useState("");
  const [exportFeedbackModal, setExportFeedbackModal] = useState(false);
  const [exportFeedbackMessage, setExportFeedbackMessage] = useState("");
  const [feeCalculatorModal, setFeeCalculatorModal] = useState(false);
  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [actionsMenuAnchor, setActionsMenuAnchor] =
    useState<HTMLElement | null>(null);
  const [openReportMenu, setOpenReportMenu] = useState(false);
  const [reportMenuAnchor, setReportMenuAnchor] = useState<HTMLElement | null>(
    null
  );

  const [sendDocumentsZip, { loading: sending }] = useLazyQuery(
    GET_ZIPPED_FILE,
    {
      onCompleted: (data) => {
        const { getRegisterZip } = data;
        const { status, message } = getRegisterZip;
        if (status) {
          setExportFeedbackMessage(message);
          setExportFeedbackModal(true);
        } else {
          toast.error(message);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [getAccountingReport, { loading: gettingReport }] = useLazyQuery(
    GET_ACCOUNTING_REPORT,
    {
      onCompleted: (data) => {
        const { accountingReport } = data;
        const { status, message } = accountingReport;
        if (status) {
          setExportFeedbackMessage(message);
          setExportFeedbackModal(true);
        } else {
          toast.error(message);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const [exportContractTemplates, { loading: exporting }] = useLazyQuery(
    GET_TEMPLATE_EXPORT,
    {
      onCompleted: (data) => {
        const { getContractTemplatesExport } = data;
        const { report } = getContractTemplatesExport;
        if (Boolean(report)) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href =
            "data:application/vnd.ms-excel;base64," + encodeURI(report);
          hiddenElement.target = "_blank";
          hiddenElement.download = `Template Directory.xlsx`;
          hiddenElement.click();
        } else {
          toast.error("Something went wrong, please try again.", { delay: 10 });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  const handleActionsMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setActionsMenuAnchor(e.currentTarget);
    setOpenActionsMenu(true);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchor(null);
    setOpenActionsMenu(false);
  };

  const handleReportMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setReportMenuAnchor(e.currentTarget);
    setOpenReportMenu(true);
  };

  const handleReportMenuClose = () => {
    setReportMenuAnchor(null);
    setOpenReportMenu(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextFieldValue(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchText(e.target.value);
    }, 300);
  };

  useEffect(() => {
    setLoading(exporting || gettingReport || sending);
  }, [exporting, gettingReport, sending, setLoading]);

  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        display={{ xs: "none", lg: "flex" }}
      >
        <Grid item>
          <Box sx={{ pl: 1.5 }}>
            {Boolean(ROLE_PERMISSIONS) &&
            foldersEditIndex !== undefined &&
            ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT ? (
              <NewOutlinedBtn
                disableElevation
                startIcon={<Add sx={{ color: "#000" }} />}
                sx={{
                  width: 80,
                  color: "#000",
                  py: 1,
                  px: 2,
                  fontSize: "14px",
                  borderRadius: "8px",
                }}
                onClick={() => setAddEditIndexModal(true)}
              >
                New
              </NewOutlinedBtn>
            ) : (
              <Box sx={{ width: 80, height: 42 }} />
            )}
          </Box>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#D2C7FF",
              color: "#000",
              ":hover": { backgroundColor: "#9E86FF90" },
              py: 1,
              px: 2,
              borderRadius: "8px",
              fontSize: "14px",
            }}
            disableElevation
            startIcon={
              <img src={images.CALCULATOR} alt="calculator" height={25} />
            }
            onClick={() => setFeeCalculatorModal(true)}
          >
            Cast Fee Calculator
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#CCD8C7",
              color: "#000",
              ":hover": { backgroundColor: "#B5C0B0" },
              py: 1,
              px: 2,
              borderRadius: "8px",
              fontSize: "14px",
            }}
            disableElevation
            startIcon={<img src={images.WIDGET} alt="widget" height={18} />}
            onClick={() => {
              exportContractTemplates({ variables: {} });
            }}
          >
            Template Directory
          </Button>
        </Grid>
        <Grid item>
          <BlackActionContainedButton
            variant="contained"
            disableElevation
            startIcon={<Share color="secondary" />}
            onClick={handleReportMenuOpen}
            sx={{ py: 1, px: 2, fontSize: "14px", borderRadius: "8px" }}
          >
            Export
          </BlackActionContainedButton>
          <Menu
            anchorEl={reportMenuAnchor}
            open={openReportMenu}
            onClose={handleReportMenuClose}
            sx={{
              "& .MuiMenu-paper": {
                border: "1px #00000030 solid",
                borderRadius: "8px",
              },
            }}
            elevation={0}
          >
            <MenuItem
              sx={{
                borderRadius: "6px",
                "&:hover": { backgroundColor: "#B6BCC330" },
              }}
              onClick={() => {
                sendDocumentsZip({
                  variables: {
                    payload: {
                      projectId: params?.project_id,
                    },
                  },
                });
                handleReportMenuClose();
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "#00000080" }}>
                Documents Zip
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              sx={{
                borderRadius: "6px",
                "&:hover": { backgroundColor: "#B6BCC330" },
              }}
              onClick={() => {
                getAccountingReport({
                  variables: {
                    filter: {
                      projectId: params?.project_id,
                    },
                    type: "Cast",
                  },
                });
                handleReportMenuClose();
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "#00000080" }}>
                Accounting Report (Cast)
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem
              sx={{
                borderRadius: "6px",
                "&:hover": { backgroundColor: "#B6BCC330" },
              }}
              onClick={() => {
                getAccountingReport({
                  variables: {
                    filter: {
                      projectId: params?.project_id,
                    },
                    type: "Crew",
                  },
                });
                handleReportMenuClose();
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "#00000080" }}>
                Accounting Report (Crew)
              </Typography>
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <IconButton
                  onClick={handleFilterMenuOpen}
                  size="small"
                  sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                >
                  <FilterAltOutlined
                    fontSize="small"
                    sx={{
                      color: openFilterMenu
                        ? "#41B199"
                        : `${colors.primaryBlack}50`,
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={filterMenuAnchor}
                  open={openFilterMenu}
                  onClose={handleFilterMenuClose}
                  elevation={1}
                  sx={{ "& .MuiMenu-paper": { width: 220 } }}
                >
                  <Filters sortBy={sortBy} setSortBy={setSortBy} />
                </Menu>
              </Box>
              {/* <Box sx={{ mx: 0.55 }}>
            <IconButton
              onClick={() => setView(0)}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <FormatListBulletedOutlined
                fontSize="small"
                sx={{
                  color: view === 0 ? "#41B199" : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              onClick={() => setView(1)}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <GridViewOutlined
                fontSize="small"
                sx={{
                  color: view === 1 ? "#41B199" : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
          </Box> */}
            </Box>
            <TextField
              value={searchTextFieldValue}
              onChange={handleSearchChange}
              size="small"
              sx={{ width: 220, ml: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" sx={{ color: "#00000070" }} />
                  </InputAdornment>
                ),
              }}
              inputProps={{ style: { fontSize: "13px" } }}
              placeholder="Keywords"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        display={{ xs: "flex", lg: "none" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Box sx={{ pl: 1.5 }}>
            {Boolean(ROLE_PERMISSIONS) &&
            foldersEditIndex !== undefined &&
            ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT ? (
              <NewOutlinedBtn
                disableElevation
                startIcon={<Add sx={{ color: "#000" }} />}
                sx={{
                  width: 80,
                  color: "#000",
                  py: 1,
                  px: 2,
                  fontSize: "14px",
                  borderRadius: "8px",
                }}
                onClick={() => setAddEditIndexModal(true)}
              >
                New
              </NewOutlinedBtn>
            ) : (
              <Box sx={{ width: 80, height: 42 }} />
            )}
          </Box>
        </Grid>
        <Grid item sx={{ display: { xs: "none", sm: "flex", lg: "none" } }}>
          <Box
            sx={{
              backgroundColor: "#000",
              borderRadius: "8px",
              py: 1.5,
              px: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleActionsMenuOpen}
          >
            <Typography color="#fff" fontSize="13px">
              Actions
            </Typography>
            <KeyboardArrowDown
              color="secondary"
              sx={{ fontSize: "15px", ml: 1 }}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{ display: "flex", alignItems: "center", position: "relative" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <IconButton
                  onClick={handleFilterMenuOpen}
                  size="small"
                  sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                >
                  <FilterAltOutlined
                    fontSize="small"
                    sx={{
                      color: openFilterMenu
                        ? "#41B199"
                        : `${colors.primaryBlack}50`,
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={filterMenuAnchor}
                  open={openFilterMenu}
                  onClose={handleFilterMenuClose}
                  elevation={1}
                  sx={{ "& .MuiMenu-paper": { width: 220 } }}
                >
                  <Filters sortBy={sortBy} setSortBy={setSortBy} />
                </Menu>
              </Box>
              {/* <Box sx={{ mx: 0.55 }}>
            <IconButton
              onClick={() => setView(0)}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <FormatListBulletedOutlined
                fontSize="small"
                sx={{
                  color: view === 0 ? "#41B199" : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
          </Box>
          <Box>
            <IconButton
              onClick={() => setView(1)}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <GridViewOutlined
                fontSize="small"
                sx={{
                  color: view === 1 ? "#41B199" : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
          </Box> */}
            </Box>
            <TextField
              value={searchTextFieldValue}
              onChange={handleSearchChange}
              size="small"
              sx={{ width: { xs: 170, sm: 200, md: 220 }, ml: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" sx={{ color: "#00000070" }} />
                  </InputAdornment>
                ),
              }}
              inputProps={{ style: { fontSize: "13px" } }}
              placeholder="Keywords"
            />
            <Box sx={{ position: "absolute", top: -35, right: 5 }}>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <Box
                  sx={{
                    backgroundColor: "#000",
                    borderRadius: "20px",
                    px: 1.5,
                    py: 0.5,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleActionsMenuOpen}
                >
                  <Typography color="#fff" fontSize="13px">
                    Actions
                  </Typography>
                  <KeyboardArrowDown
                    color="secondary"
                    sx={{ fontSize: "15px", ml: 1 }}
                  />
                </Box>
              </Box>
            </Box>
            <Menu
              anchorEl={actionsMenuAnchor}
              open={openActionsMenu}
              onClose={handleActionsMenuClose}
              elevation={1}
            >
              <MenuItem
                onClick={() => {
                  setFeeCalculatorModal(true);
                  handleActionsMenuClose();
                }}
              >
                <ListItemIcon>
                  <img
                    src={images.CALCULATOR}
                    alt="calc"
                    style={{ height: 20 }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: "12px", color: "#000" }}>
                    Fee Calculator
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  exportContractTemplates({ variables: {} });
                  handleActionsMenuClose();
                }}
              >
                <ListItemIcon>
                  <img src={images.WIDGET} alt="widget" height={15} />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: "12px", color: "#000" }}>
                    Template Directory
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  sendDocumentsZip({
                    variables: {
                      payload: {
                        projectId: params?.project_id,
                      },
                    },
                  });
                  handleActionsMenuClose();
                }}
              >
                <ListItemIcon>
                  <Share sx={{ fontSize: 19, color: "#000" }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: "12px", color: "#000" }}>
                    Download Documents Zip
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  getAccountingReport({
                    variables: {
                      filter: {
                        projectId: params?.project_id,
                      },
                      type: "Cast",
                    },
                  });
                  handleActionsMenuClose();
                }}
              >
                <ListItemIcon>
                  <PeopleAltOutlined sx={{ fontSize: 19, color: "#000" }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: "12px", color: "#000" }}>
                    Accounting Report (Cast)
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  getAccountingReport({
                    variables: {
                      filter: {
                        projectId: params?.project_id,
                      },
                      type: "Crew",
                    },
                  });
                  handleActionsMenuClose();
                }}
              >
                <ListItemIcon>
                  <HandymanOutlined sx={{ fontSize: 19, color: "#000" }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: "12px", color: "#000" }}>
                    Accounting Report (Crew)
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
      {exportFeedbackModal && (
        <ExportFeedbackModal
          open={exportFeedbackModal}
          setOpen={setExportFeedbackModal}
          message={exportFeedbackMessage}
          setMessage={setExportFeedbackMessage}
        />
      )}
      {feeCalculatorModal && (
        <CastFeeCalculator
          open={feeCalculatorModal}
          setOpen={setFeeCalculatorModal}
        />
      )}
    </>
  );
}

export default Header;
