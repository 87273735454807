import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, Typography } from "@mui/material";

import { colors } from "theme/colors";

interface ICompanyLeftStepsProps {
  activeSection: number;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

const sections = ["Company Details", "Registration", "Bank Details"];

function CompanyLeftSteps(props: ICompanyLeftStepsProps) {
  const { activeSection, setActiveSection } = props;

  return (
    <Grid container spacing={3}>
      {sections.map((section, index) => (
        <Grid
          key={section}
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ ...(index === 0 && { mt: 10 }) }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Box
              component="div"
              onClick={() => setActiveSection(index)}
              sx={{
                py: 1,
                borderRadius: "10px",
                backgroundColor:
                  activeSection === index ? "#41B19931" : "transparent",
                minWidth: 150,
                cursor: "pointer",
                ":hover": {
                  backgroundColor:
                    activeSection === index ? "#41B19995" : "#B6BCC325",
                },
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  fontSize: "13px",
                  color: colors.primaryBlack,
                  ...(activeSection === index && { fontWeight: 600 }),
                }}
              >
                {section}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default CompanyLeftSteps;
