import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useQuery } from "@apollo/client";

import { GET_KEY_PERSONNEL_DETAILS } from "graphql/projects";
import { useProjectDetailsMenu } from "hooks/useProjectDetailsMenu";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { useStore } from "utils/store";
import { getPermissions } from "permissions/utils";

import TopNavBar from "../TopNavBar";
import LeftSideNav from "./LeftSideNav";
import DirectorsForm from "./DirectorsForm";
import ProducersForm from "./ProducersForm";
import WritersForm from "./WritersForm";
import ExecutiveProducersForm from "./ExecutiveProducersForm";
import LineProducersForm from "./LineProducersForm";
import AllForms from "./AllForms";
import ProjectDetailsTabs, { ProjectDetailsMenuItems } from "..";

export interface IPerson {
  name: string;
  email: string;
}

export interface IKeyPersonnelFields {
  id: string;
  director: string;
  writer: string;
  producer: string;
  directors: IPerson[];
  producers: IPerson[];
  lineProducers: IPerson[];
  executiveProducers: IPerson[];
  writers: IPerson[];
}

export function KeyPersonnel() {
  const projectId = useParams();
  const { setLoading } = useStore();
  const projectDetailsMenu = useProjectDetailsMenu();
  const projectDetailsTab = useProjectDetailsTabs();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("PROJECT_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [personnelData, setPersonnelData] = useState<IKeyPersonnelFields>({
    id: "",
    director: "",
    writer: "",
    producer: "",
    directors: [{ name: "", email: "" }],
    producers: [{ name: "", email: "" }],
    writers: [{ name: "", email: "" }],
    lineProducers: [{ name: "", email: "" }],
    executiveProducers: [{ name: "", email: "" }],
  });
  const [selectedLeftNavOpt, setSelectedLeftNavOpt] = useState("1");

  const { loading, refetch } = useQuery(GET_KEY_PERSONNEL_DETAILS, {
    variables: { id: projectId?.project_id },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        projectViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[projectViewIndex]?.PROJECT_VIEW
      ),
    onCompleted: (data) => {
      setLoading(false);
      const { project } = data;
      const { status, project: rawProject } = project;
      if (status) {
        const { id, director, producer, writer, keyPersonals } = rawProject;
        let parsedKeyPersonnels = {
          lineProducers: [{ name: "", email: "" }],
          executiveProducers: [{ name: "", email: "" }],
          directors: [{ name: "", email: "" }],
          writers: [{ name: "", email: "" }],
          producers: [{ name: "", email: "" }],
        };
        if (keyPersonals) {
          parsedKeyPersonnels = Boolean(JSON.parse(keyPersonals))
            ? JSON.parse(keyPersonals)
            : {
                lineProducers: [{ name: "", email: "" }],
                executiveProducers: [{ name: "", email: "" }],
                directors: [{ name: "", email: "" }],
                writers: [{ name: "", email: "" }],
                producers: [{ name: "", email: "" }],
              };
        }
        setPersonnelData({
          id,
          director: director || "",
          writer: writer || "",
          producer: producer || "",
          lineProducers: parsedKeyPersonnels?.lineProducers || [
            { name: "", email: "" },
          ],
          executiveProducers: parsedKeyPersonnels?.executiveProducers || [
            { name: "", email: "" },
          ],
          directors: parsedKeyPersonnels?.directors || [
            { name: "", email: "" },
          ],
          writers: parsedKeyPersonnels?.writers || [{ name: "", email: "" }],
          producers: parsedKeyPersonnels?.producers || [
            { name: "", email: "" },
          ],
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    projectDetailsMenu.onMenuItemClick(ProjectDetailsMenuItems[1]);
    projectDetailsTab.setSelectedTab(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <TopNavBar>
        <Grid container display="flex" flexDirection="row">
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <LeftSideNav
              selectedLeftNavOpt={selectedLeftNavOpt}
              setSelectedLeftNavOpt={setSelectedLeftNavOpt}
            />
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Box
              sx={{
                borderLeft: { xs: "none", md: "2px #e6e6e680 solid" },
                ml: { xs: 0, md: 4 },
                minHeight: "calc(100vh - 250px)",
                pl: { xs: 2, md: 4 },
                pr: { xs: 2, md: 4.5 },
                overflow: "auto",
              }}
              display={{ xs: "none", md: "block" }}
            >
              {selectedLeftNavOpt === "1" && (
                <DirectorsForm
                  personnelData={personnelData}
                  refetch={refetch}
                />
              )}
              {selectedLeftNavOpt === "2" && (
                <ProducersForm
                  personnelData={personnelData}
                  refetch={refetch}
                />
              )}
              {selectedLeftNavOpt === "3" && (
                <WritersForm personnelData={personnelData} refetch={refetch} />
              )}
              {selectedLeftNavOpt === "4" && (
                <ExecutiveProducersForm
                  personnelData={personnelData}
                  refetch={refetch}
                />
              )}
              {selectedLeftNavOpt === "5" && (
                <LineProducersForm
                  personnelData={personnelData}
                  refetch={refetch}
                />
              )}
            </Box>
            <Box
              sx={{
                borderLeft: { xs: "none", md: "2px #e6e6e680 solid" },
                ml: { xs: 0, md: 4 },
                minHeight: "calc(100vh - 250px)",
                pl: { xs: 2, md: 4 },
                pr: { xs: 2, md: 4.5 },
                overflow: "auto",
              }}
              display={{ xs: "block", md: "none" }}
            >
              <AllForms personnelData={personnelData} refetch={refetch} />
            </Box>
          </Grid>
        </Grid>
      </TopNavBar>
    </ProjectDetailsTabs>
  );
}
