import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddCircle, ChevronLeft, ChevronRight } from "@mui/icons-material";
import moment from "moment";

import { ProjectStatusMapping, ProjectTypeArr } from "utils/constants";
import { images } from "assets/images";
import { IProject } from "models/projects";
import { RouteNames } from "routes/routeNames";
import { IPaginationModel } from "models/common";
import { useStore } from "utils/store";
import { useProjectCreateModal } from "hooks/useCreateProjectModal";
import { getPermissions, getProjectRedirection } from "permissions/utils";
import { colors } from "theme/colors";

import { DashboardStyles as styles } from "./styles";

interface IRecentProjectsProps {
  recentProjects: IProject[];
  paginationModel: IPaginationModel;
  setPaginationModel: Dispatch<SetStateAction<IPaginationModel>>;
  count: number;
}

interface IProjectCardProps {
  projectData: IProject;
}

function ProjectCard(props: IProjectCardProps) {
  const { projectData } = props;

  const navigate = useNavigate();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);

  return (
    <Box
      sx={[
        styles.project_item_container,
        { display: "flex", justifyContent: "center", alignItems: " center" },
      ]}
      onClick={() =>
        navigate(
          `${RouteNames.PROJECTS}/${projectData.id}${getProjectRedirection(
            ROLE_PERMISSIONS
          )}`
        )
      }
    >
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {projectData?.profilePicture ? (
            <Box sx={styles.proj_img}>
              <img
                src={projectData?.profilePicture}
                alt="proj_image"
                style={{
                  height: "280px",
                  width: "189px",
                  borderRadius: "20px",
                  objectFit: "cover",
                  aspectRatio: 0.675 / 1,
                  ...(projectData?.projectStatus === 2 && {
                    filter: "grayscale(100%)",
                  }),
                }}
              />
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={[
                  styles.proj_img,
                  {
                    backgroundColor: `#BFBDBC22`,
                    aspectRatio: 0.675 / 1,
                    borderRadius: "20px",
                    width: "189px",
                  },
                ]}
              >
                <img src={images.FILM_REEL} alt="reel" style={{ height: 50 }} />
              </Box>
            </Box>
          )}
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Chip
            sx={[
              styles.proj_status_chip,
              {
                backgroundColor: (ProjectStatusMapping as any)[
                  projectData.projectStatus
                ].bg,
                color: (ProjectStatusMapping as any)[projectData.projectStatus]
                  .color,
              },
            ]}
            label={
              (ProjectStatusMapping as any)[projectData.projectStatus].name
            }
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Tooltip title={projectData?.name}>
            <Typography
              textAlign="center"
              noWrap
              sx={[
                styles.project_name_text,
                {
                  ...(projectData?.projectStatus === 2 && {
                    color: `${colors.primaryBlack}50`,
                  }),
                },
              ]}
            >
              {projectData?.name}
            </Typography>
          </Tooltip>
          <Typography textAlign="center" noWrap sx={styles.proj_meta_data_text}>
            {`${
              Boolean(projectData.projectStartDate)
                ? moment(projectData.projectStartDate).format("YYYY")
                : ""
            } | ${
              Boolean(projectData.projectType)
                ? ProjectTypeArr.find((x) => x.id === projectData.projectType)
                    ?.name
                : ""
            }`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function RecentProjects(props: IRecentProjectsProps) {
  const { recentProjects, paginationModel, setPaginationModel, count } = props;

  const navigate = useNavigate();
  const projectCreateModal = useProjectCreateModal();
  const { loading } = useStore();

  return (
    <Grid container spacing={2}>
      {!loading && recentProjects.length === 0 && (
        <>
          <Grid
            item
            lg={0.5}
            md={1}
            sm={2}
            xs={2}
            sx={{ visibility: "hidden" }}
          >
            <IconButton
              sx={{ mt: "110px" }}
              onClick={() =>
                setPaginationModel({
                  page: paginationModel.page - 1,
                  pageSize: paginationModel.pageSize,
                })
              }
              disabled={paginationModel.page === 0}
            >
              <ChevronLeft
                sx={{
                  fontSize: 45,
                  color:
                    paginationModel.page === 0
                      ? `${colors.whiteGrey400}90`
                      : colors.primaryBlack,
                }}
              />
            </IconButton>
          </Grid>
          <Grid item lg={11.5} md={11} sm={10} xs={10}>
            <Box
              sx={styles.project_item_container}
              onClick={() => {
                projectCreateModal.onOpen();
                navigate(RouteNames.PROJECTS);
              }}
            >
              <Box
                sx={[
                  styles.proj_img,
                  {
                    border: "3px #BFBDBC dotted",
                    borderRadius: "20px",
                    backgroundColor: `#BFBDBC22`,
                    flexDirection: "column",
                    px: 5,
                    aspectRatio: 0.675 / 1,
                    width: "189px",
                  },
                ]}
              >
                <AddCircle sx={{ color: "#BFBDBC", fontSize: 65, mb: 2 }} />
                <Typography
                  sx={{
                    color: `${colors.primaryBlack}50`,
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                  textAlign="center"
                >
                  Create New Project
                </Typography>
              </Box>
            </Box>
          </Grid>
        </>
      )}
      {!loading && recentProjects?.length > 0 && (
        <>
          <Grid item lg={0.5} md={1} sm={2} xs={2}>
            <IconButton
              sx={{ mt: "110px" }}
              onClick={() =>
                setPaginationModel({
                  page: paginationModel.page - 1,
                  pageSize: paginationModel.pageSize,
                })
              }
              disabled={paginationModel.page === 0}
            >
              <ChevronLeft
                sx={{
                  fontSize: 45,
                  color:
                    paginationModel.page === 0
                      ? `${colors.whiteGrey400}90`
                      : colors.primaryBlack,
                }}
              />
            </IconButton>
          </Grid>
          <Grid item lg={11} md={10} sm={8} xs={8}>
            <Grid container spacing={2}>
              {recentProjects.map((proj) => (
                <Grid key={proj.id} item lg={3} md={6} sm={6} xs={12}>
                  <ProjectCard projectData={proj} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item lg={0.5} md={1} sm={2} xs={2}>
            <IconButton
              sx={{ mt: "110px" }}
              onClick={() =>
                setPaginationModel({
                  page: paginationModel.page + 1,
                  pageSize: paginationModel.pageSize,
                })
              }
              disabled={
                (paginationModel.page + 1) * paginationModel.pageSize >= count
              }
            >
              <ChevronRight
                sx={{
                  fontSize: 45,
                  color:
                    (paginationModel.page + 1) * paginationModel.pageSize >=
                    count
                      ? `${colors.whiteGrey400}90`
                      : colors.primaryBlack,
                }}
              />
            </IconButton>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default RecentProjects;
