import { images } from "assets/images";
import { colors } from "theme/colors";

export const ProjectStatusArr = [
	{ id: 4, name: "Concept" },
	{ id: 5, name: "Development" },
	{ id: 6, name: "Pre Production" },
	{ id: 7, name: "Production" },
	{ id: 8, name: "Post Production" },
	{ id: 9, name: "Delivered" },
	{ id: 2, name: "Archived" },
];

export const ProjectStatusMapping = {
	4: { name: "Concept", color: "#B43052", bg: "#DE324C30" },
	5: { name: "Development", color: "#BB3B17", bg: `#F4895F30` },
	6: { name: "Pre-production", color: "#D17609", bg: "#FFDE3230" },
	7: { name: "Production", color: "#32620E", bg: "#9DCD5A30" },
	8: { name: "Post-production", color: "#1264AB", bg: "#B2D5EB40" },
	9: { name: "Delivered", color: "#975AB6", bg: "#9656A230" },
	2: { name: "Archived", color: "#80624C", bg: "#6C584C30" },
};

export const ProjectTypeArr = [
	{ id: "FEATURE", name: "Feature" },
	{ id: "TV_SERIES", name: "TV Series" },
	{ id: "TELEMOVIE", name: "Telemovie" },
	{
		id: "DOCUMENTARY",
		name: "Documentary",
	},
	{
		id: "TVC",
		name: "TVC",
	},
	{
		id: "OTHER",
		name: "Other",
	},
];

export const CollectiveAgreementCastArr = [
	{ id: "ATTRA", name: "ATTRA" },
	{ id: "AFFCA", name: "AFFCA" },
];

export const CollectiveAgreementCrewArr = [{ id: "MPPCA", name: "MPPCA" }];

export const RightsAwardsArr = [
	{ id: "ATPA", name: "ATPA" },
	{ id: "ATTRA", name: "ATTRA" },
	{ id: "BRECA", name: "BRECA" },
	{ id: "AFFCA", name: "AFFCA" },
	{ id: "MATA", name: "MATA" },
	{ id: "SASA", name: "SASA" },
	{ id: "NONE", name: "NONE" },
];

export const RightsNameArr = [
	{ id: "SVOD", name: "SVOD" },
	{ id: "OZ_ANCILLARY", name: "OZ Ancillary" },
	{ id: "OZ_FREE_TV RIGHTS", name: "OZ Free TV Rights" },
	{ id: "WORLD_ANCILLARY", name: "World Ancillary" },
	{ id: "WORLD_THEATRICAL", name: "World Theatrical" },
	{ id: "WORLD_TV_RIGHTS_EX_US", name: "World TV Rights ex US" },
	{ id: "ELSEWHERE", name: "Elsewhere" },
	{ id: "FREE_STREAMING", name: "Free Streaming" },
	{ id: "ONLINE_RIGHTS", name: "Online Rights" },
	{
		id: "FEATURE_FILM_CATEGORY_LOADING",
		name: "Feature Film Category Loading",
	},
	{ id: "CO_PRODUCTION_LOADING", name: "Co-Production Loading" },
	{ id: "FOREIGN_PRODUCTION_LOADING", name: "Foreign Production Loading" },
];

export const RightsDescArr = [
	{ id: "SUBSCRIPTION_VOD_RIGHTS", name: "Subscription VOD Rights" },
	{ id: "ANCILLARY_RIGHTS_AUSTRALIA", name: "Ancillary Rights Australia" },
	{ id: "ADDITIONAL_RIGHTS", name: "Additional Rights" },
	{
		id: "FREE_VOD_STREAMING_IN_AUSTRALIA",
		name: "Free VOD streaming in Australia",
	},
	{ id: "LOADING_FOR_CATEGORY_RIGHTS", name: "Loading for category rights" },
	{ id: "FREE_TV_RIGHTS_AUSTRALIA", name: "Free TV Rights Australia" },
	{ id: "ANCILLARY_RIGHTS_WORLD", name: "Ancillary Rights World" },
	{ id: "WORLD_THEATRICAL_RIGHTS", name: "World Theatrical Rights" },
	{ id: "WORLD_TV_EX_US", name: "World TV ex US" },
];

export const YesNoArr = [
	{ id: "true", name: "Yes" },
	{ id: "false", name: "No" },
];

export const CurrencyArr = [
	{ id: "USD", name: "$ USD" },
	{ id: "EUR", name: "€ EUR" },
	{ id: "GBP", name: "£ GBP" },
	{ id: "JPY", name: "¥ JPY" },
	{ id: "AUD", name: "$ AUD" },
];

export const CurrencyMapping = {
	USD: "$",
	EUR: "€",
	GBP: "£",
	JPY: "¥",
	AUD: "$",
};

export const DateFormatArr = [
	{ id: "mm/dd/yyyy", name: "mm/dd/yyyy" },
	{ id: "yyyy/mm/dd", name: "yyyy/mm/dd" },
	{ id: "dd/mm/yyyy", name: "dd/mm/yyyy" },
	{ id: "yyyy/dd/mm", name: "yyyy/dd/mm" },
];

export const ProjectCategoryArr = [
	{
		id: "A",
		name: "A",
		description:
			"An Australian Film (subject to customary right of approval) and an all Australian cast.",
	},
	{
		id: "B",
		name: "B",
		description: 'A Category "A" film but with one or more overseas Artists',
	},
	{
		id: "C",
		name: "C",
		description:
			"A bona fide Co-Production between an Australian production company and an Offshore production company.",
	},
	{ id: "PD", name: "PD", description: "" },
	{ id: "NA", name: "NA", description: "Not subject to a category loading" },
];

export const ContractStatusMapping = {
	DRAFT: { color: "#FFA500", bg: "#FFA50020", name: "Draft" },
	APPROVED: { color: "#610E87", bg: "#F0E7F3", name: "Approved" },
	PARTIALLY_EXECUTED: {
		color: colors.primary,
		bg: "#E6F2FB",
		name: "Partially Executed",
	},
	FULLY_EXECUTED: { color: "#18B370", bg: "#E0F9EE", name: "Fully Executed" },
	RESCINDED: { color: "#D72C51", bg: "#F8E8EC", name: "Rescinded" },
	ARCHIVED: { color: "#B37818", bg: "#FDF4E5", name: "Archived" },
	APPROVAL_PENDING: {
		color: "#D72C51",
		bg: "#F8E8EC",
		name: "Approval Pending",
	},
	COMPLETED: { color: "#18B370", bg: "#E0F9EE", name: "Completed" },
};

export const ContractStatusArr = [
	{ id: "DRAFT", name: "Draft" },
	{ id: "APPROVAL_PENDING", name: "Approval Pending" },
	{ id: "APPROVED", name: "Approved" },
	{ id: "PARTIALLY_EXECUTED", name: "Partially Executed" },
	{ id: "FULLY_EXECUTED", name: "Fully Executed" },
	{ id: "RESCINDED", name: "Rescinded" },
	{ id: "ARCHIVED", name: "Archived" },
	// { id: "COMPLETED", name: "Completed" },
];

export const ContractStatusDraftUpdateArr = [
	{ id: "APPROVAL_PENDING", name: "Approval Pending" },
	{ id: "APPROVED", name: "Approved" },
	{ id: "RESCINDED", name: "Rescinded" },
	{ id: "ARCHIVED", name: "Archived" },
];
export const ContractStatusApprovalPendingUpdateArr = [
	{ id: "DRAFT", name: "Draft" },
	{ id: "APPROVED", name: "Approved" },
	{ id: "RESCINDED", name: "Rescinded" },
	{ id: "ARCHIVED", name: "Archived" },
];

export const ContractStatusArchiveUpdateArr = [{ id: "DRAFT", name: "Draft" }];

export const ContractStatusRescindedUpdateArr = [
	{ id: "DRAFT", name: "Draft" },
	{ id: "ARCHIVED", name: "Archived" },
];

export const TemplateStatusMapping = {
	10: { color: "#00CA72", bg: "#00CA7215", name: "Publish" },
	11: { color: colors.primary, bg: `${colors.primary}15`, name: "Draft" },
};

export const TemplateStatusArr = [
	{ id: 11, name: "Draft" },
	{ id: 10, name: "Publish" },
];

export const ComplexityMapping = {
	1: { name: "High", flag: images.COMPLEXITY_HIGH },
	2: { name: "Normal", flag: images.COMPLEXITY_NORMAL },
	3: { name: "Low", flag: images.COMPLEXITY_LOW },
};

export const ComplexityArr = [
	{ id: "1", name: "High" },
	{ id: "2", name: "Normal" },
	{ id: "3", name: "Low" },
];

export const IndexItemTypeArr = [
	{ id: "CONTRACTS", name: "Contract" },
	{ id: "DOCUMENT", name: "Document" },
	{ id: "BLANK", name: "Blank" },
];

export const EmploymentStatusArr = [
	{ id: "PAYE", name: "PAYE" },
	{ id: "CONTRACTOR", name: "Contractor (ABN)" },
	{ id: "COMPANY", name: "Company" },
	{ id: "VOLUNTEER", name: "Volunteer" },
];

export const EngagementStatusArr = [
	{ key: "Hourly", value: "Hours" },
	{ key: "Daily", value: "Days" },
	{ key: "Weekly", value: "Weeks" },
];

export const ChecklistItemStatusArr = [
	{ id: "PENDING", name: "Pending" },
	{ id: "COMPLETED", name: "Completed" },
	{ id: "ACCEPTED", name: "Accepted" },
	{ id: "REJECTED", name: "Rejected" },
	{ id: "NOT_REQUIRED", name: "Not Required" },
];

export const ChecklistItemStatusMapping = {
	PENDING: {
		name: "Pending",
		color: "#FFA027",
	},
	COMPLETED: {
		name: "Completed",
		color: "#073577",
	},
	ACCEPTED: { name: "Accepted", color: "#409091" },
	REJECTED: { name: "Rejected", color: "#E3323B" },
	NOT_REQUIRED: { name: "Not Required", color: "#910C00" },
};

export const IssueRelatedToArr = [
	{ id: "DELIVERY", name: "Delivery" },
	{ id: "CLEARANCE", name: "Clearance" },
	{ id: "OTHER", name: "Other" },
];

export const IssuePriorityArr = [
	{ id: "LOW", name: "Low" },
	{ id: "MEDIUM", name: "Medium" },
	{ id: "HIGH", name: "High" },
];

export const IssuePrirotyMapping = {
	HIGH: { name: "High", flag: images.COMPLEXITY_HIGH },
	MEDIUM: { name: "Medium", flag: images.COMPLEXITY_NORMAL },
	LOW: { name: "Low", flag: images.COMPLEXITY_LOW },
};

export const IssueStatusArr = [
	{ id: "OPEN", name: "Open" },
	{ id: "CLOSED", name: "Closed" },
	{ id: "REOPEN", name: "Re-Open" },
];

export const IssueStatusMapping = {
	OPEN: { name: "Open", color: "#28C76F", bg: "#28C76F20" },
	REOPEN: { name: "Re-Open", color: "#FFA500", bg: "#FFA50020" },
	CLOSED: { name: "Closed", color: "#D72C51", bg: "#D72C5120" },
};

export const GenderArr = [
	{ id: "M", name: "Male" },
	{ id: "F", name: "Female" },
	{ id: "NA", name: "Do Not Wish to Answer" },
];

export const CountryCodeArr = [
	{
		id: "61",
		name: "+61",
		flag: images.AUS_FLAG,
	},
	{
		id: "1",
		name: "+1",
		flag: images.US_FLAG,
	},
	{
		id: "44",
		name: "+44",
		flag: images.UK_FLAG,
	},
	{
		id: "66",
		name: "+66",
		flag: images.THAILAND_FLAG,
	},
];

//DUMMY

export const ProjectCategoryMapping = {
	1: { name: "A", color: colors.primary, bg: `${colors.primary}20` },
	2: { name: "B", color: "#00CA72", bg: "#00CA7220" },
	3: { name: "C", color: "#FFB131", bg: "#FFB13120" },
};

export const ContractPriorityMapping = {
	0: { name: "Urgent", flag: images.PRIORITY_URGENT },
	1: { name: "High", flag: images.PRIORITY_HIGH },
	2: { name: "Normal", flag: images.PRIORITY_NORMAL },
	3: { name: "Low", flag: images.PRIORITY_LOW },
};

export const AdminAccessMapping = {
	Owner: { color: "#0176D3", bg: "#E6F2FB" },
	Editor: { color: "#18B370", bg: "#E0F9EE" },
	Viewer: { color: "#B37818", bg: "#FDF4E5" },
};

export const ManageClientStatus = {
	0: "Pending Approval",
	1: "Approved",
	2: "Added by admin",
	3: "Rejected",
};

export const TemplateTypeArr = [
	{ id: 1, name: "Document Library" },
	{ id: 2, name: "Announcements" },
	{ id: 3, name: "Picture Library" },
	{ id: 4, name: "Custom List" },
];

export const ArtistTypeArr = [
	{ id: 1, name: "Consultant" },
	{ id: 2, name: "Participant" },
	{ id: 3, name: "Location" },
	{ id: 4, name: "Cast" },
	{ id: 5, name: "Crew" },
];

export const ContractTypeArr = [
	{ id: 1, name: "Third party" },
	{ id: 2, name: "In-house" },
];

export const ProjectsArr = [
	{ id: 1, name: "Extraction 2", status: "7" },
	{ id: 2, name: "Avengers: Endgame", status: "4" },
	{ id: 3, name: "The Dark Knight Rises", status: "6" },
	{ id: 4, name: "John Wick: Chapter 4", status: "8" },
	{ id: 5, name: "Morbius", status: "5" },
	{ id: 6, name: "Joker", status: "9" },
	{ id: 7, name: "Logan", status: "2" },
];

export const ContactDeptArr = [
	{ id: 1, name: "Participant" },
	{ id: 2, name: "Camera" },
	{ id: 3, name: "Sound" },
	{ id: 4, name: "Art" },
	{ id: 5, name: "Make-Up" },
	{ id: 6, name: "Visual effects" },
];

export const MonthMapping = {
	Jan: "January",
	Feb: "February",
	Mar: "March",
	Apr: "April",
	May: "May",
	Jun: "June",
	Jul: "July",
	Aug: "August",
	Sep: "September",
	Oct: "October",
	Nov: "November",
	Dec: "December",
};
