import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Avatar, Box, Dialog, Grid, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { DELETE_AGENT } from "graphql/agents";
import { IModalProps } from "models/common";
import { useStore } from "utils/store";
import { NewOutlinedBtn, NewRedAlertContainedButton } from "common/Buttons";
import { images } from "assets/images";
import { colors } from "theme/colors";

interface IDeleteAgentModalProps extends IModalProps {
  selectedAgentId: string;
  setSelectedAgentId: Dispatch<SetStateAction<string>>;
  selectedAgentName: string;
  setSelectedAgentName: Dispatch<SetStateAction<string>>;
  refetch: any;
}

const DeleteAgentModal = (props: IDeleteAgentModalProps) => {
  const {
    open,
    setOpen,
    refetch,
    selectedAgentId,
    setSelectedAgentId,
    selectedAgentName,
    setSelectedAgentName,
  } = props;

  const { setLoading } = useStore();

  const handleClose = () => {
    setSelectedAgentId("");
    setSelectedAgentName("");
    setOpen(false);
  };

  const [fireDeleteAgentApi, { loading }] = useMutation(DELETE_AGENT, {
    onCompleted: (data) => {
      setLoading(false);
      const { deleteAgent } = data;
      const { status, message } = deleteAgent;
      if (status) {
        refetch();
        toast.success(message, { delay: 10 });
      } else {
        toast.error(message, { delay: 10 });
      }
      handleClose();
    },
  });

  const handleDelete = () => {
    fireDeleteAgentApi({ variables: { id: selectedAgentId } });
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const AlertImage = () => (
    <img src={images.ALERT_NEW} alt="alert" height={22} />
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "24px", p: 3, width: "465px" } }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              src={images.ALERT_NEW}
              sx={{ backgroundColor: "#ED1C2420" }}
              slots={{ img: AlertImage }}
            />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 600,
              color: colors.primaryBlack,
              textAlign: "center",
            }}
          >
            {`Delete ${selectedAgentName}?`}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{ fontSize: "13px", textAlign: "center", color: "#00000080" }}
          >
            Once <span style={{ fontWeight: 600 }}>deleted</span>, the agent and
            all its associated data cannot be recovered.
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <NewOutlinedBtn
                variant="outlined"
                sx={{
                  color: colors.primaryBlack,
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleClose}
              >
                Cancel
              </NewOutlinedBtn>
            </Box>
            <Box sx={{ ml: 2 }}>
              <NewRedAlertContainedButton
                sx={{
                  fontWeight: 600,
                  fontSize: "13px",
                  borderRadius: "20px",
                  width: "120px",
                }}
                onClick={handleDelete}
              >
                Delete
              </NewRedAlertContainedButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteAgentModal;
