import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";

import { IArr, IModalProps } from "models/common";
import { BlackActionContainedButton } from "common/Buttons";
import {
  CREATE_CHECKLIST_ITEM,
  GET_CHECKLIST_ITEM_WEEK_ASSIGNED_TO,
} from "graphql/checklist";
import { useStore } from "utils/store";
import { colors } from "theme/colors";

import { IWeekDueArr } from "./DeliveryChecklistItem";
import { ProjectDetailsStyles as styles } from "../styles";

interface IChecklistItemFolderFields {
  name: string;
  description: string;
  // checklistId: string
  // projectId: string
  // registerId: string
  levelNo: string;
  note: string;
  // isFolder: Boolean;
  // parentId: string;
  weekDue: string;
  assignedToId: string;
}

interface IAddChecklistItemModal extends IModalProps {
  refetch: any;
  nestedIdArr: string[];
  weekDueArr: IWeekDueArr[];
  usersArr: IArr[];
}

function AddChecklistItemModal(props: IAddChecklistItemModal) {
  const { open, setOpen, refetch, nestedIdArr, weekDueArr, usersArr } = props;

  const params = useParams();
  const { setLoading } = useStore();

  const [initialValues, setInitialValues] =
    useState<IChecklistItemFolderFields>({
      name: "",
      description: "",
      levelNo: "",
      note: "",
      weekDue: "",
      assignedToId: "",
    });

  const immediateParent = useMemo(() => {
    const str = params["*"];
    var n = str!.lastIndexOf("/");
    return str!.substring(n + 1);
  }, [params]);

  const {} = useQuery(GET_CHECKLIST_ITEM_WEEK_ASSIGNED_TO, {
    variables: {
      id: immediateParent,
      project_id: params?.project_id,
      checklist_id: params?.checklist_id,
    },
    skip:
      !params?.project_id || !params?.checklist_id || !Boolean(immediateParent),
    onCompleted: (data) => {
      const { getChecklistItem } = data;
      const { status, checklistItem } = getChecklistItem;
      if (status) {
        const { weekDue, assignedTo } = checklistItem;
        setInitialValues({
          ...initialValues,
          weekDue: weekDue || "",
          assignedToId: assignedTo?.id || "",
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireCreateChecklistItemApi, { loading: creating }] = useMutation(
    CREATE_CHECKLIST_ITEM,
    {
      onCompleted: (data) => {
        const { createChecklistItem } = data;
        const { status, message } = createChecklistItem;
        setLoading(false);
        if (status) {
          toast.success(message, { delay: 10 });
          refetch();
          handleClose();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleCreateChecklistItem = (values: IChecklistItemFolderFields) => {
    const { weekDue, assignedToId, ...rest } = values;

    fireCreateChecklistItemApi({
      variables: {
        payload: {
          ...(Boolean(weekDue) && { weekDue: parseInt(weekDue) }),
          ...(Boolean(assignedToId) && { assignedToId: assignedToId }),
          ...rest,
          checklistId: params?.checklist_id,
          projectId: params?.project_id,
          isFolder: false,
          ...(nestedIdArr.length > 0 && {
            parentId: nestedIdArr[nestedIdArr.length - 1],
          }),
        },
      },
    });
  };

  useEffect(() => {
    setLoading(creating);
  }, [creating, setLoading]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required("Please enter the item name")
          .matches(/^[^/]*$/, "Item name cannot contain '/'"),
      })}
      onSubmit={handleCreateChecklistItem}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Dialog
            open={open}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{ sx: { borderRadius: "24px", overflow: "hidden" } }}
          >
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    py: 2,
                    backgroundColor: "#B6BCC325",
                    position: "relative",
                    px: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{
                        fontSize: "13px",
                        color: colors.primaryBlack,
                        fontWeight: 600,
                      }}
                    >
                      Delivery Item
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <IconButton size="small" onClick={handleClose}>
                    <Close sx={{ color: "#00000055" }} fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    pt: 4,
                    px: { xs: 2, sm: 6 },
                    pb: 3,
                    maxHeight: "calc(100vh - 120px)",
                    overflow: "auto",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <Typography sx={styles.field_label}>Item Id</Typography>
                      <TextField
                        id="levelNo"
                        value={values.levelNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{ style: styles.input_field }}
                      />
                    </Grid>
                    <Grid item lg={9} md={9} sm={6} xs={12}>
                      <Typography sx={styles.field_label}>
                        Item Name <span style={styles.error_text}>*</span>
                      </Typography>
                      <TextField
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{ style: styles.input_field }}
                      />
                      {touched.name && (
                        <Typography sx={styles.error_text}>
                          {errors.name}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>Due Week</Typography>
                      <Autocomplete
                        id="weekDue"
                        value={
                          weekDueArr.find(
                            (x) => x.id.toString() === values.weekDue.toString()
                          ) || {
                            id: "",
                            date: "",
                          }
                        }
                        onChange={(_, newValue) => {
                          setFieldValue("weekDue", newValue.id);
                        }}
                        onBlur={handleBlur}
                        sx={styles.input_field}
                        size="small"
                        disableClearable
                        fullWidth
                        options={weekDueArr}
                        getOptionLabel={(option) =>
                          values.weekDue ? `Week ${option.id}` : ""
                        }
                        renderOption={(props1, opt, _, __) => (
                          <li
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                            {...props1}
                          >
                            <Typography
                              sx={{ fontSize: "14px" }}
                            >{`Week ${opt.id}`}</Typography>
                            <Typography
                              sx={{ fontSize: "13px", color: "#00000070" }}
                            >{`Starting ${moment(opt.date).format(
                              "DD MMMM"
                            )}`}</Typography>
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            onBlur={handleBlur}
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                        )}
                      />
                      {/* {touched.name && (
                        <Typography sx={styles.error_text}>
                          {errors.name}
                        </Typography>
                      )} */}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>Assignee</Typography>
                      <Autocomplete
                        id="assignedToId"
                        value={
                          usersArr.find(
                            (x) => x.id === values.assignedToId
                          ) || {
                            id: "",
                            name: "",
                          }
                        }
                        onChange={(_, newValue) => {
                          setFieldValue("assignedToId", newValue.id);
                        }}
                        sx={styles.input_field}
                        size="small"
                        disableClearable
                        fullWidth
                        options={usersArr}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                ...styles.input_field,
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                        )}
                      />
                      {/* {touched.name && (
                        <Typography sx={styles.error_text}>
                          {errors.name}
                        </Typography>
                      )} */}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography sx={styles.field_label}>
                        Description
                      </Typography>
                      <TextField
                        id="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            ...styles.input_field,
                            backgroundColor: "transparent",
                          },
                        }}
                        multiline
                        minRows={3}
                        maxRows={3}
                        sx={{
                          "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            backgroundColor: "#e6e6e640",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box sx={styles.flex_JCfe}>
                        <BlackActionContainedButton
                          variant="contained"
                          size="small"
                          sx={{
                            borderRadius: "20px",
                            ml: 1,
                            fontSize: "13px",
                            fontWeight: 600,
                            width: "120px",
                          }}
                          disableElevation
                          disableRipple
                          onClick={() => handleSubmit()}
                        >
                          Save
                        </BlackActionContainedButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}

export default AddChecklistItemModal;
