import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from "@mui/material";
import { ChevronRight, Close, FiberManualRecord } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import moment from "moment";
import { toast } from "react-toastify";

import { IModalProps } from "models/common";
import { useStore } from "utils/store";
import { images } from "assets/images";
import {
  ClientProjectDetailsLabels,
  ClientProjectLabels,
  GlobalLabels,
} from "common/AppConstants";
import DatePicker from "common/DatePickerInput";
import {
  ProjectCategoryArr,
  ProjectStatusMapping,
  ProjectTypeArr,
} from "utils/constants";
import { colors } from "theme/colors";
import { IEditProjectFields } from "models/projects";
import { GET_PROJECT_BY_ID, UPDATE_PROJECT } from "graphql/projects";

import { ProjectStyles as styles } from "./styles";

interface IEditProjectModalProps extends IModalProps {
  projectId: string;
  refetchProjects: any;
  setSelectedProjectId: Dispatch<SetStateAction<string>>;
}

const EditProjectModal = (props: IEditProjectModalProps) => {
  const { open, setOpen, projectId, refetchProjects, setSelectedProjectId } =
    props;

  const { setLoading } = useStore();

  const [openProjStatusMenu, setOpenProjStatusMenu] = useState(false);
  const [projStatusAnchor, setProjStatusAnchor] = useState<HTMLElement | null>(
    null
  );
  const [initialValues, setInitialValues] = useState<IEditProjectFields>({
    id: "",
    name: "",
    projectNumber: "",
    description: "",
    episodes: "",
    episodeDuration: "",
    projectType: "",
    projectCategory: "",
    categoryDescription: "",
    categoryLoading: "",
    categoryLoadingInputs: "",
    collectiveAgreementCast: "",
    collectiveAgreementCrew: "",
    projectStartDate: "",
    projectEndDate: "",
    shootDays: "",
    filmingDate: "",
    filmingLocation: "",
    projectMaterials: "",
    projectStatus: "",
    profile_picture: null,
    script: null,
  });
  const [warningChecked, setWarningChecked] = useState(false);

  const { loading: projectDeetsLoading } = useQuery(GET_PROJECT_BY_ID, {
    variables: { id: projectId },
    skip: !projectId,
    onCompleted: (data) => {
      // setLoading(false);
      const { project } = data;
      const { status, project: rawProject } = project;
      if (status) {
        const {
          director,
          producer,
          writer,
          status,
          createdBy,
          company,
          categoryLoadingInputs,
          profilePicture,
          shootDays,
          filmingDate,
          __typename,
          ...rest
        } = rawProject;
        let parsedCategoryLoadingInputs = "";
        if (categoryLoadingInputs) {
          parsedCategoryLoadingInputs = JSON.parse(categoryLoadingInputs);
        }
        setInitialValues({
          ...rest,
          categoryLoadingInputs: Boolean(parsedCategoryLoadingInputs)
            ? parsedCategoryLoadingInputs.toString()
            : "",
          profile_picture: profilePicture || null,
          shootDays: shootDays === 0 ? "" : shootDays,
          filmingDate: filmingDate === null ? "" : filmingDate,
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleProjStatusClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpenProjStatusMenu(true);
    setProjStatusAnchor(e.currentTarget);
  };

  const handleProjStatusClose = () => {
    setOpenProjStatusMenu(false);
    setProjStatusAnchor(null);
  };

  const handleClose = () => {
    setSelectedProjectId("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireUpdateProjectApi, { loading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: (data) => {
      setLoading(false);
      const { updateProject } = data;
      const { status, message } = updateProject;
      handleClose();
      if (status) {
        toast.success(message);
        refetchProjects();
      } else {
        toast.error(message);
      }
    },
  });

  const handleUpdateProj = (values: IEditProjectFields) => {
    const {
      episodes,
      episodeDuration,
      categoryLoading,
      shootDays,
      categoryLoadingInputs,
      projectStartDate,
      filmingDate,
      projectEndDate,
      projectStatus,
      profile_picture,
      script,
      ...rest
    } = values;
    fireUpdateProjectApi({
      variables: {
        payload: {
          ...rest,
          ...(episodes !== "" && { episodes: parseInt(episodes as string) }),
          ...(episodeDuration !== "" && {
            episodeDuration: parseInt(episodeDuration as string),
          }),
          ...(categoryLoading !== "" && {
            categoryLoading: parseFloat(categoryLoading as string),
          }),
          ...(shootDays !== "" && {
            shootDays: parseInt(shootDays as string),
          }),
          ...(filmingDate !== "" && { filmingDate }),
          ...(projectStartDate !== "" && { projectStartDate }),
          ...(projectEndDate !== "" && { projectEndDate }),
          categoryLoadingInputs: JSON.stringify(categoryLoadingInputs),
          projectStatus: parseInt(projectStatus as string),
          ...(profile_picture === null && { removeProfilePhoto: true }),
        },
        ...(Boolean(profile_picture) &&
          typeof profile_picture !== "string" && { file: profile_picture }),
        ...(Boolean(script) &&
          typeof script !== "string" && { script: script }),
      },
    });
  };

  useEffect(() => {
    setLoading(loading || projectDeetsLoading);
  }, [loading, projectDeetsLoading, setLoading]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Please enter the project name"),
        projectCategory: Yup.string().required(
          "Please select the project category"
        ),
        projectType: Yup.string().required("Please select a project type"),
        projectStartDate: Yup.string().required(
          "Please select the project start date"
        ),
        projectEndDate: Yup.string()
          .required("Please select the project end date")
          .when("projectStartDate", (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "End Date should be after Start Date",
              test: (endDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(endDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(endDate);
                }
                return true;
              },
            });
          }),
        filmingDate: Yup.string().when(
          "projectStartDate",
          (startDate, schema) => {
            return schema.test({
              name: "start_date end_date test",
              message: "Release/ TX Date should be after Start Date",
              test: (filmDate) => {
                if (
                  Boolean(startDate) &&
                  startDate.length > 0 &&
                  Boolean(filmDate)
                ) {
                  const newStartDate = startDate[0];
                  return moment(newStartDate).isBefore(filmDate);
                }
                return true;
              },
            });
          }
        ),
        episodes: Yup.string()
          .required("Please enter the number of episodes")
          .test("negative test", "Please enter a positive value", (val) =>
            Boolean(val) ? val !== "" && parseInt(val as string) > 0 : true
          ),
        episodeDuration: Yup.string()
          .required("Please enter the duration of each episodes")
          .test("negative test", "Please enter a positive value", (val) =>
            Boolean(val) ? val !== "" && parseInt(val as string) > 0 : true
          ),
        shootDays: Yup.string().test(
          "negative test",
          "Please enter a positive value",
          (val) =>
            Boolean(val) ? val !== "" && parseInt(val as string) > 0 : true
        ),
        categoryLoading: Yup.string()
          .required("Please enter the category loading")
          .test("negative test", "Please enter a positive value", (val) =>
            Boolean(val) ? val !== "" && parseInt(val as string) >= 0 : true
          ),
      })}
      enableReinitialize
      onSubmit={handleUpdateProj}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        setFieldTouched,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => {
        return (
          <Dialog
            open={open}
            onClose={handleDialogClose}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle sx={styles.flex_JCsb_Acenter}>
              <Typography sx={styles.add_project_text}>
                {ClientProjectLabels.EDIT_PROJ}
              </Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ px: 3, pb: 3 }}>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    display={{ xs: "none", md: "block" }}
                  >
                    <Box sx={[styles.my3, styles.flex_Afe, { height: "66px" }]}>
                      <Box>
                        <Typography sx={styles.proj_status_text}>
                          {ClientProjectDetailsLabels.PROJ_FORM.PROJ_STATUS}
                        </Typography>
                        <Box sx={styles.proj_status_container}>
                          <Box
                            sx={[
                              styles.proj_status_label_container,
                              styles.flex_Acenter,
                              {
                                backgroundColor: Boolean(values.projectStatus)
                                  ? (ProjectStatusMapping as any)[
                                      values.projectStatus
                                    ].color
                                  : "",
                              },
                            ]}
                          >
                            <Typography sx={styles.proj_status_label_text}>
                              {Boolean(values?.projectStatus)
                                ? (ProjectStatusMapping as any)[
                                    values.projectStatus
                                  ].name
                                : ""}
                            </Typography>
                          </Box>
                          <Box
                            sx={[
                              styles.proj_stat_icon_container,
                              styles.flex_Acenter,
                              {
                                backgroundColor: Boolean(values.projectStatus)
                                  ? (ProjectStatusMapping as any)[
                                      values.projectStatus
                                    ].color
                                  : "",
                              },
                            ]}
                          >
                            <IconButton
                              size="small"
                              aria-controls={
                                openProjStatusMenu ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={
                                openProjStatusMenu ? "true" : undefined
                              }
                              onClick={handleProjStatusClick}
                            >
                              <ChevronRight
                                fontSize="small"
                                sx={{ color: colors.secondary }}
                              />
                            </IconButton>
                            <Menu
                              id="basic-menu"
                              anchorEl={projStatusAnchor}
                              open={openProjStatusMenu}
                              onClose={handleProjStatusClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={styles.menu_container}
                              elevation={0}
                            >
                              <MenuList>
                                {Object.keys(ProjectStatusMapping).map(
                                  (item) => (
                                    <MenuItem
                                      key={item}
                                      onClick={() => {
                                        setFieldValue(
                                          "projectStatus",
                                          parseInt(item)
                                        );
                                        handleProjStatusClose();
                                      }}
                                    >
                                      <ListItemIcon>
                                        <FiberManualRecord
                                          fontSize="small"
                                          sx={{
                                            color: Boolean(values.projectStatus)
                                              ? (ProjectStatusMapping as any)[
                                                  item
                                                ].color
                                              : "",
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText sx={styles.field_label}>
                                        {item}
                                      </ListItemText>
                                    </MenuItem>
                                  )
                                )}
                              </MenuList>
                            </Menu>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    md={8}
                    display={{ xs: "none", md: "block" }}
                  >
                    <Box sx={[styles.flex_Acenter, styles.my3]}>
                      <Box>
                        <Upload
                          accept=".jpeg, .jpg, .png"
                          beforeUpload={(file: RcFile, __) => {
                            setFieldValue("profile_picture", file);
                            return Promise.resolve();
                          }}
                        >
                          <IconButton>
                            <img src={images.CAMERA} alt="camera" />
                          </IconButton>
                        </Upload>
                      </Box>
                      {Boolean(values.profile_picture) ? (
                        <Avatar
                          sx={styles.reel_avatar}
                          src={
                            typeof values.profile_picture === "string"
                              ? values.profile_picture
                              : URL.createObjectURL(values.profile_picture)
                          }
                        />
                      ) : (
                        <Avatar sx={styles.reel_avatar}>
                          <img
                            src={images.REEL}
                            style={{ width: "66px" }}
                            alt="reel"
                          />
                        </Avatar>
                      )}
                      <Box>
                        <IconButton
                          onClick={() => setFieldValue("profile_picture", null)}
                        >
                          <img src={images.DELETE} alt="delete" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    display={{ xs: "block", md: "none" }}
                  >
                    <Box sx={styles.project_pic_container}>
                      <Upload
                        accept=".jpeg, .jpg, .png"
                        beforeUpload={(file: RcFile, __) => {
                          setFieldValue("profile_picture", file);
                          return Promise.resolve();
                        }}
                      >
                        <IconButton>
                          <img src={images.CAMERA} alt="camera" />
                        </IconButton>
                      </Upload>
                      {Boolean(values.profile_picture) ? (
                        <Avatar
                          sx={styles.reel_avatar}
                          src={
                            typeof values.profile_picture === "string"
                              ? values.profile_picture
                              : URL.createObjectURL(values.profile_picture)
                          }
                        />
                      ) : (
                        <Avatar sx={styles.reel_avatar}>
                          <img
                            src={images.REEL}
                            style={{ width: "66px" }}
                            alt="reel"
                          />
                        </Avatar>
                      )}
                      <IconButton>
                        <img src={images.DELETE} alt="delete" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    display={{ xs: "block", md: "none" }}
                  >
                    <Box sx={[styles.my3, styles.flex_Afe, { height: "66px" }]}>
                      <Box>
                        <Typography sx={styles.proj_status_text}>
                          {ClientProjectDetailsLabels.PROJ_FORM.PROJ_STATUS}
                        </Typography>
                        <Box sx={styles.proj_status_container}>
                          <Box
                            sx={[
                              styles.proj_status_label_container,
                              styles.flex_Acenter,
                              {
                                backgroundColor: Boolean(values.projectStatus)
                                  ? (ProjectStatusMapping as any)[
                                      values.projectStatus
                                    ].color
                                  : "",
                              },
                            ]}
                          >
                            <Typography sx={styles.proj_status_label_text}>
                              {Boolean(values.projectStatus)
                                ? (ProjectStatusMapping as any)[
                                    values.projectStatus
                                  ].name
                                : ""}
                            </Typography>
                          </Box>
                          <Box
                            sx={[
                              styles.proj_stat_icon_container,
                              styles.flex_Acenter,
                              {
                                backgroundColor: Boolean(values.projectStatus)
                                  ? (ProjectStatusMapping as any)[
                                      values.projectStatus
                                    ].color
                                  : "",
                              },
                            ]}
                          >
                            <IconButton
                              size="small"
                              aria-controls={
                                openProjStatusMenu ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={
                                openProjStatusMenu ? "true" : undefined
                              }
                              onClick={handleProjStatusClick}
                            >
                              <ChevronRight
                                fontSize="small"
                                sx={{ color: colors.secondary }}
                              />
                            </IconButton>
                            <Menu
                              id="basic-menu"
                              anchorEl={projStatusAnchor}
                              open={openProjStatusMenu}
                              onClose={handleProjStatusClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={styles.menu_container}
                              elevation={0}
                            >
                              <MenuList>
                                {Object.keys(ProjectStatusMapping).map(
                                  (item) => (
                                    <MenuItem
                                      key={item}
                                      onClick={() => {
                                        setFieldValue(
                                          "projectStatus",
                                          parseInt(item)
                                        );
                                        handleProjStatusClose();
                                      }}
                                    >
                                      <ListItemIcon>
                                        <FiberManualRecord
                                          fontSize="small"
                                          sx={{
                                            color: (
                                              ProjectStatusMapping as any
                                            )[item].color,
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText sx={styles.field_label}>
                                        {
                                          (ProjectStatusMapping as any)[item]
                                            .name
                                        }
                                      </ListItemText>
                                    </MenuItem>
                                  )
                                )}
                              </MenuList>
                            </Menu>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.PROJ_NAME}{" "}
                      <span style={{ color: "#D3010E" }}>*</span>
                    </Typography>
                    <TextField
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.name && errors?.name && (
                      <Typography sx={styles.error_text}>
                        {errors.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.PROJ_NUM}
                    </Typography>
                    <TextField
                      id="projectNumber"
                      value={values.projectNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.projectNumber && errors?.projectNumber && (
                      <Typography sx={styles.error_text}>
                        {errors.projectNumber}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.DESC}
                    </Typography>
                    <TextField
                      id="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      fullWidth
                      multiline
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.description && errors?.description && (
                      <Typography sx={styles.error_text}>
                        {errors.description}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.PROJ_TYPE}{" "}
                      <span style={{ color: "#D3010E" }}>*</span>
                    </Typography>
                    <Autocomplete
                      id="projectType"
                      value={
                        ProjectTypeArr.find(
                          (x) => x.id === values.projectType
                        ) || { id: "", name: "" }
                      }
                      disabled
                      onChange={(_, newValue) => {
                        setFieldValue(`projectType`, newValue.id);
                        setFieldValue("collectiveAgreementCrew", "MPPCA");
                        if (newValue.id !== "FEATURE") {
                          setFieldValue("projectCategory", "NA");
                          setFieldValue(
                            "categoryDescription",
                            "Not subject to a category loading"
                          );
                          setFieldValue(
                            "collectiveAgreementCast",
                            "ATPA/ATRRA"
                          );
                        } else {
                          setFieldValue("projectCategory", "");
                          setFieldValue("categoryDescription", "");
                          setFieldValue("collectiveAgreementCast", "AFFCA");
                        }
                      }}
                      onBlur={handleBlur}
                      disableClearable
                      size="small"
                      options={ProjectTypeArr}
                      getOptionLabel={(option) => option.name}
                      sx={styles.text_input}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: styles.text_input,
                          }}
                        />
                      )}
                    />
                    {touched?.projectType && errors?.projectType && (
                      <Typography sx={styles.error_text}>
                        {errors.projectType}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.PROJ_CATEGORY}{" "}
                      <span style={{ color: "#D3010E" }}>*</span>
                    </Typography>
                    <Autocomplete
                      id="projectCategory"
                      value={
                        ProjectCategoryArr.find(
                          (x) => x.id === values.projectCategory
                        ) || { id: "", name: "", description: "" }
                      }
                      onChange={(_, newValue) => {
                        setFieldValue(`projectCategory`, newValue.id);
                        setFieldValue(
                          "categoryDescription",
                          newValue.description
                        );
                      }}
                      disabled={
                        Boolean(values.projectType) &&
                        values.projectType !== "FEATURE"
                      }
                      onBlur={handleBlur}
                      disableClearable
                      size="small"
                      options={
                        Boolean(values?.projectType)
                          ? values?.projectType === "FEATURE"
                            ? ProjectCategoryArr.filter((x) => x.id !== "NA")
                            : ProjectCategoryArr
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      sx={styles.text_input}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            style: styles.text_input,
                          }}
                        />
                      )}
                    />
                    {touched?.projectCategory && errors?.projectCategory && (
                      <Typography sx={styles.error_text}>
                        {errors.projectCategory}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.CAT_DESC}
                    </Typography>
                    <TextField
                      id="categoryDescription"
                      value={values.categoryDescription}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      size="small"
                      fullWidth
                      multiline
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.categoryDescription &&
                      errors?.categoryDescription && (
                        <Typography sx={styles.error_text}>
                          {errors.categoryDescription}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.CAT_LOADING}{" "}
                      <span style={{ color: "#D3010E" }}>*</span>
                    </Typography>
                    <TextField
                      id="categoryLoading"
                      value={values.categoryLoading}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.input_field }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>%</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {touched?.categoryLoading && errors?.categoryLoading && (
                      <Typography sx={styles.error_text}>
                        {errors.categoryLoading}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {
                        ClientProjectDetailsLabels.PROJ_FORM
                          .COLLECTIVE_AGREE_CAST
                      }
                    </Typography>
                    <TextField
                      id="collectiveAgreementCast"
                      value={values.collectiveAgreementCast}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.collectiveAgreementCast &&
                      errors?.collectiveAgreementCast && (
                        <Typography sx={styles.error_text}>
                          {errors.collectiveAgreementCast}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {
                        ClientProjectDetailsLabels.PROJ_FORM
                          .COLLECTIVE_AGREE_CREW
                      }
                    </Typography>
                    <TextField
                      id="collectiveAgreementCrew"
                      value={values.collectiveAgreementCrew}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.collectiveAgreementCrew &&
                      errors?.collectiveAgreementCrew && (
                        <Typography sx={styles.error_text}>
                          {errors.collectiveAgreementCrew}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.EPISODE_NUM}{" "}
                      <span style={{ color: "#D3010E" }}>*</span>
                    </Typography>
                    <TextField
                      id="episodes"
                      value={values.episodes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.episodes && errors?.episodes && (
                      <Typography sx={styles.error_text}>
                        {errors.episodes}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {`${ClientProjectDetailsLabels.PROJ_FORM.EPISODE_DURATION} (in minutes)`}{" "}
                      <span style={{ color: "#D3010E" }}>*</span>
                    </Typography>
                    <TextField
                      id="episodeDuration"
                      value={values.episodeDuration}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.episodeDuration && errors?.episodeDuration && (
                      <Typography sx={styles.error_text}>
                        {errors.episodeDuration}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.PROJ_START_DATE}{" "}
                      <span style={{ color: "#D3010E" }}>*</span>
                    </Typography>
                    <DatePicker
                      textValue={
                        Boolean(values.projectStartDate)
                          ? moment(values.projectStartDate).format(
                              "DD MMMM YYYY"
                            )
                          : ""
                      }
                      value={
                        Boolean(values.projectStartDate)
                          ? new Date(values.projectStartDate)
                          : null
                      }
                      onChange={(newValue: any) => {
                        setFieldTouched("projectStartDate", true);
                        setFieldValue(
                          "projectStartDate",
                          moment(newValue).format("YYYY-MM-DD")
                        );
                      }}
                      onOpen={() => setFieldTouched("projectStartDate", true)}
                      onCloseCustom={() => {}}
                    />
                    {touched?.projectStartDate && errors?.projectStartDate && (
                      <Typography sx={styles.error_text}>
                        {errors.projectStartDate}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.PROJ_END_DATE}{" "}
                      <span style={{ color: "#D3010E" }}>*</span>
                    </Typography>
                    <DatePicker
                      textValue={
                        Boolean(values.projectEndDate)
                          ? moment(values.projectEndDate).format("DD MMMM YYYY")
                          : ""
                      }
                      value={
                        Boolean(values.projectEndDate)
                          ? new Date(values.projectEndDate)
                          : null
                      }
                      onChange={(newValue: any) => {
                        setFieldTouched("projectEndDate", true);
                        setFieldValue(
                          "projectEndDate",
                          moment(newValue).format("YYYY-MM-DD")
                        );
                      }}
                      onOpen={() => setFieldTouched("projectEndDate", true)}
                      onCloseCustom={() => {}}
                    />
                    {touched?.projectEndDate && errors?.projectEndDate && (
                      <Typography sx={styles.error_text}>
                        {errors.projectEndDate}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.FILMING_DATE}
                    </Typography>
                    <DatePicker
                      textValue={
                        Boolean(values.filmingDate)
                          ? moment(values.filmingDate).format("DD MMMM YYYY")
                          : ""
                      }
                      value={
                        Boolean(values.filmingDate)
                          ? new Date(values.filmingDate)
                          : null
                      }
                      onChange={(newValue: any) => {
                        setFieldTouched("filmingDate", true);
                        setFieldValue(
                          "filmingDate",
                          moment(newValue).format("YYYY-MM-DD")
                        );
                      }}
                      onOpen={() => setFieldTouched("filmingDate", true)}
                      onCloseCustom={() => {}}
                    />
                    {touched?.filmingDate && errors?.filmingDate && (
                      <Typography sx={styles.error_text}>
                        {errors.filmingDate}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.SHOOT_DAYS_NUM}
                    </Typography>
                    <TextField
                      id="shootDays"
                      value={values.shootDays}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.shootDays && errors?.shootDays && (
                      <Typography sx={styles.error_text}>
                        {errors.shootDays}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography sx={styles.field_label}>
                      {ClientProjectDetailsLabels.PROJ_FORM.FILMING_LOCATION}
                    </Typography>
                    <TextField
                      id="filmingLocation"
                      value={values.filmingLocation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      fullWidth
                      inputProps={{ style: styles.input_field }}
                    />
                    {touched?.filmingLocation && errors?.filmingLocation && (
                      <Typography sx={styles.error_text}>
                        {errors.filmingLocation}
                      </Typography>
                    )}
                  </Grid>
                  {/* <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -2.5 }}>
                  <Typography sx={styles.field_label}>
                    Script <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Upload
                    beforeUpload={(file: RcFile, __) => {
                      setScriptError("");
                      setFieldValue("script", file);
                      return Promise.resolve();
                    }}
                  >
                    <Box sx={styles.upload_file_container}>
                      <img src={images.UPLOAD} alt="upload" />
                      <Typography sx={styles.upload_text}>
                        Drag & drop, or{" "}
                        <span style={{ color: colors.primary }}>browse</span>{" "}
                        your files
                      </Typography>
                    </Box>
                  </Upload>
                  {Boolean(scriptError) && (
                    <Typography sx={styles.error_text}>
                      {scriptError}
                    </Typography>
                  )}
                </Grid>
                {Boolean(values.script) && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box
                      sx={[
                        styles.flex_JCsb_Acenter,
                        styles.uploaded_file_container,
                      ]}
                    >
                      <Box sx={styles.flex_Acenter}>
                        <img src={images.PDF} alt="pdf" />
                        <Typography sx={{ ml: 1 }}>
                          {values.script
                            ? typeof values.script !== "string"
                              ? (values?.script as any)["name"]
                              : "*{file_name}*"
                            : ""}
                        </Typography>
                      </Box>
                      <IconButton onClick={() => setFieldValue("script", null)}>
                        <Cancel sx={{ color: "#FF1640" }} />
                      </IconButton>
                    </Box>
                  </Grid>
                )} */}
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions
              sx={[styles.modal_footer_container, styles.flex_Acenter]}
            >
              <FormControlLabel
                label={
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                    I understand that making changes to project category and
                    project category loading will reflect changes in the
                    contracts created henceforth
                  </Typography>
                }
                control={
                  <Checkbox
                    checked={warningChecked}
                    onChange={(e) => setWarningChecked(e.target.checked)}
                  />
                }
              />
              <Button
                variant="contained"
                disabled={
                  projectDeetsLoading || loading || !warningChecked || !isValid
                }
                type="submit"
                sx={styles.disabled_btn}
                onClick={() => handleSubmit()}
              >
                {GlobalLabels.UPDATE}
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default EditProjectModal;
