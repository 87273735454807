import React, { Dispatch, SetStateAction } from "react";
import { Box, Typography } from "@mui/material";

import { colors } from "theme/colors";

interface ILeftSideNavProps {
  selectedLeftNavOpt: string;
  setSelectedLeftNavOpt: Dispatch<SetStateAction<string>>;
}

function LeftSideNav(props: ILeftSideNavProps) {
  const { selectedLeftNavOpt, setSelectedLeftNavOpt } = props;

  const leftNavOptions = [
    { id: "1", name: "Director" },
    { id: "2", name: "Producer" },
    { id: "3", name: "Writer" },
    { id: "4", name: "Executive Producer" },
    { id: "5", name: "Line Producer" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: " 148.5px" }}>
        {leftNavOptions.map((item) => (
          <Box
            key={item.id}
            sx={{
              mt: 5,
              py: 1,
              px: 2,
              borderRadius: "8px",
              cursor: "pointer",
              display: "inline-block", // Ensure the Box only takes the width of the text
              ":hover": {
                backgroundColor:
                  selectedLeftNavOpt === item.id ? "#41B19995" : "#e6e6e670",
              },
              ...(selectedLeftNavOpt === item.id && {
                backgroundColor: "#41B19931",
              }),
            }}
            onClick={() => {
              setSelectedLeftNavOpt(item.id);
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: selectedLeftNavOpt === item.id ? 600 : 550,
                color: colors.primaryBlack,
              }}
            >
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default LeftSideNav;
