import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { IUpdateContactFieldsNew } from "models/contacts";
import {
	BlackActionContainedButton,
	NewGreenPrimaryContainedButton,
	NewOutlinedBtn,
} from "common/Buttons";
import PhoneNumberInput from "common/PhoneNumberInput";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import { useStore } from "utils/store";
import { CountryCodeArr } from "utils/constants";

interface IEmergencyFormProps {
	initialValues: IUpdateContactFieldsNew;
	refetch: any;
	updateContactApi: any;
	profilePicture: any;
	setActiveSection: Dispatch<SetStateAction<number>>;
}

function EmergencyForm(props: IEmergencyFormProps) {
	const {
		initialValues,
		refetch,
		updateContactApi,
		setActiveSection,
		profilePicture,
	} = props;

	const { setLoading } = useStore();

	const handleSubmitIndividualForm = (values: IUpdateContactFieldsNew) => {
		const {
			id,
			project_role,
			dob,
			gender,
			next_of_kin_contact_number,
			next_of_kin_name,
			next_of_kin_relationship,
			food_allergies,
			medical_allergies,
			instagram,
			facebook,
			twitter,
			imdb,
		} = values;

		const profileJSON = {
			project_role,
			dob,
			gender,
			food_allergies,
			medical_allergies,
			next_of_kin_contact_number: Boolean(
				next_of_kin_contact_number?.nationalNumber
			)
				? `${
						CountryCodeArr.find(
							(x) => x.id === next_of_kin_contact_number?.countryCode
						)?.name
				  } ${next_of_kin_contact_number?.nationalNumber}`
				: "",
			next_of_kin_name,
			next_of_kin_relationship,
			instagram,
			facebook,
			twitter,
			imdb,
		};

		updateContactApi({
			variables: {
				payload: {
					id,
					profile: JSON.stringify(profileJSON),
					...(profilePicture === null && { removeProfilePhoto: true }),
				},
				...(Boolean(profilePicture) &&
					typeof profilePicture !== "string" && {
						profile_picture: profilePicture,
					}),
			},
			onCompleted: (data: any) => {
				setLoading(false);
				const { updateContactBasicDetails } = data;
				const { status, message } = updateContactBasicDetails;
				if (status) {
					refetch();
					toast.success(message, { delay: 10 });
				} else {
					toast.error(message, { delay: 10 });
				}
			},
		});
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				// next_of_kin_contact_number: Yup.string()
				//   .matches(
				//     /^[0-9]{4}\s[0-9]{3}\s[0-9]{3}$/,
				//     "Contact number must be of 10 digits"
				//   )
				//   .nullable(),
			})}
			onSubmit={handleSubmitIndividualForm}
			enableReinitialize
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleBlur,
				touched,
				errors,
				isValid,
				handleSubmit,
			}) => (
				<Box
					sx={{
						pt: { xs: 2, sm: 14 },
						px: 4,
						pb: 1.5,
						display: "flex",
						flexDirection: "column",
						height: "100%",
					}}
				>
					<Grid container spacing={3}>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={{ display: { xs: "block", sm: "none" } }}
						>
							<Typography
								sx={{
									fontSize: "13px",
									color: "#000",
									fontWeight: 600,
								}}
							>
								Emergency & Medical Details
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Emergency Contact Full Name
							</Typography>
							<TextField
								id="next_of_kin_name"
								value={values.next_of_kin_name}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Emergency Contact Phone Number
							</Typography>
							<PhoneNumberInput
								value={
									values.next_of_kin_contact_number.nationalNumber !==
									null
										? values.next_of_kin_contact_number.nationalNumber
										: Boolean(
												values.next_of_kin_contact_number.rawInput
										  )
										? values.next_of_kin_contact_number.rawInput.replaceAll(
												" ",
												""
										  )
										: ""
								}
								onChange={(e) => {
									setFieldValue(
										"next_of_kin_contact_number.nationalNumber",
										e.target.value
									);
								}}
								onBlur={handleBlur(
									"next_of_kin_contact_number.nationalNumber"
								)}
								countryCode={
									values.next_of_kin_contact_number.countryCode
								}
								setCountryCode={(code: string) =>
									setFieldValue(
										"next_of_kin_contact_number.countryCode",
										code
									)
								}
							/>
							{/* {touched?.next_of_kin_contact_number && (
                <Typography sx={styles.error_text}>
                  {errors?.next_of_kin_contact_number}
                </Typography>
              )} */}
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Emergency Contact Relationship
							</Typography>
							<TextField
								id="next_of_kin_relationship"
								value={values.next_of_kin_relationship}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Food Allergies
							</Typography>
							<TextField
								id="food_allergies"
								value={values.food_allergies}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Medical Allergies
							</Typography>
							<TextField
								id="medical_allergies"
								value={values.medical_allergies}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
						</Grid>
					</Grid>
					<Box sx={{ flexGrow: 1, mt: 2 }}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
							}}
						>
							<Box sx={{ flexGrow: 1 }} />
							<Box
								sx={[
									styles.flex_Acenter,
									{ justifyContent: "space-between" },
								]}
							>
								<BlackActionContainedButton
									size="small"
									sx={{
										borderRadius: "20px",
										fontSize: "13px",
										fontWeight: 600,
										width: "120px",
										color: "#fff",
									}}
									disableElevation
									disableRipple
									onClick={() => setActiveSection(3)}
								>
									Back
								</BlackActionContainedButton>
								<Box sx={[styles.flex_JCfe, { alignItems: "center" }]}>
									<NewGreenPrimaryContainedButton
										variant="contained"
										size="small"
										sx={{
											borderRadius: "20px",
											fontSize: "13px",
											fontWeight: 600,
											width: "120px",
										}}
										disableElevation
										disableRipple
										disabled={!isValid}
										onClick={() => handleSubmit()}
									>
										Save
									</NewGreenPrimaryContainedButton>
									<NewOutlinedBtn
										variant="outlined"
										size="small"
										sx={{
											borderRadius: "20px",
											fontSize: "13px",
											fontWeight: 600,
											width: "120px",
											color: "#000",
											ml: 1,
										}}
										disableElevation
										disableRipple
										onClick={() => setActiveSection(5)}
									>
										Next
									</NewOutlinedBtn>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</Formik>
	);
}

export default EmergencyForm;
