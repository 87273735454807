import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Close, FiberManualRecord } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { ContractDocxLabels } from "common/AppConstants";
import { GET_STATUS_HISTORY } from "graphql/contracts";
import { ContractStatusMapping } from "utils/constants";
import { useStore } from "utils/store";

import { ContractDocxStyles as styles } from "./styles";

interface IProps {
  setActionsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

interface IStatusHistory {
  id: string;
  historyDate: string;
  documentStatus: string;
}

function StatusRecord(props: IProps) {
  const { setActionsDrawerOpen } = props;

  const params = useParams();
  const { setLoading } = useStore();

  const [statusHistory, setStatusHistory] = useState<IStatusHistory[]>([]);

  const { loading } = useQuery(GET_STATUS_HISTORY, {
    variables: {
      document_id: params?.contract_id,
      project_id: params?.project_id,
    },
    skip: !params?.project_id || !params?.contract_id,
    onCompleted: (data) => {
      const { getStatusHistory } = data;
      const { status, history } = getStatusHistory;
      if (status) {
        setStatusHistory(history);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const getDateTitle = (date: any) => {
    const today = moment();
    const newDate = moment(date);
    if (today.isSame(newDate, "date"))
      return `Today, ${moment(date).format("hh:mm a")}`;
    if (today.subtract(1, "day").isSame(newDate, "date"))
      return `Yesterday, ${moment(date).format("hh:mm a")}`;
    return newDate.format("DD MMMM YYYY, hh:mm a");
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={3} sx={styles.drawer_container}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Box sx={styles.flex_JCsb_Acenter}>
          <Typography sx={styles.drawer_header_text}>
            {ContractDocxLabels.STATUS}
          </Typography>
          <IconButton size="small" onClick={() => setActionsDrawerOpen(false)}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={styles.mt2}>
        {statusHistory.map((status, index) => (
          <Box
            key={status.id}
            sx={[
              styles.timeline_container,
              {
                borderLeft:
                  index === statusHistory.length - 1
                    ? "3px dotted transparent"
                    : `3px dotted #4F67ED50`,
              },
            ]}
          >
            <FiberManualRecord
              sx={[
                styles.timeline_dot,
                {
                  color: (ContractStatusMapping as any)[status.documentStatus]
                    ?.color,
                },
              ]}
            />
            <Box>
              <Typography sx={styles.timeline_status_text}>
                {(ContractStatusMapping as any)[status.documentStatus]?.name}
              </Typography>
              <Typography sx={styles.timeline_time_text}>
                {getDateTitle(status.historyDate)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}

export default StatusRecord;
