import React, { Dispatch, SetStateAction } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";

import { ProjectCategoryArr, ProjectTypeArr } from "utils/constants";
import { colors } from "theme/colors";

import { ProjectStyles as styles } from "./styles";

interface IFiltersProps {
  selectedProjectCategory: string;
  setSelectedProjectCategory: Dispatch<SetStateAction<string>>;
  selectedProjectType: string;
  setSelectedProjectType: Dispatch<SetStateAction<string>>;
}

function Filters(props: IFiltersProps) {
  const {
    selectedProjectCategory,
    setSelectedProjectCategory,
    selectedProjectType,
    setSelectedProjectType,
  } = props;
  return (
    <Grid container spacing={2} sx={{ p: 1.5 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={styles.filter_field_label}>Project Type</Typography>
        <Autocomplete
          fullWidth
          value={
            ProjectTypeArr.find((x) => x.id === selectedProjectType) || {
              id: "",
              name: "",
            }
          }
          onChange={(_, newValue) => {
            if (newValue) {
              setSelectedProjectType(newValue.id);
            } else {
              setSelectedProjectType("");
            }
          }}
          size="small"
          options={ProjectTypeArr}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              inputProps={{
                ...params.inputProps,
                style: {
                  borderRadius: "6px",
                  fontSize: "13px",
                  color: colors.primaryBlack,
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={styles.filter_field_label}>Project Category</Typography>
        <Autocomplete
          fullWidth
          value={
            ProjectCategoryArr.find(
              (x) => x.id === selectedProjectCategory
            ) || {
              id: "",
              name: "",
              description: "",
            }
          }
          onChange={(_, newValue) => {
            if (newValue) {
              setSelectedProjectCategory(newValue.id);
            } else {
              setSelectedProjectCategory("");
            }
          }}
          size="small"
          options={ProjectCategoryArr}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              inputProps={{
                ...params.inputProps,
                style: {
                  borderRadius: "6px",
                  fontSize: "13px",
                  color: colors.primaryBlack,
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default Filters;
