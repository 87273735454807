import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Add, AddCircle, TextSnippet } from "@mui/icons-material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { useStore } from "utils/store";
import {
  GET_ALL_CHECKLISTS,
  GET_CHECKLIST_TEMPLATE_ARR,
} from "graphql/checklist";
import { IChecklist } from "models/checklists";
import { NewGreenPrimaryContainedButton, NewOutlinedBtn } from "common/Buttons";
import { EXPORT_DELIVERY_REPORT } from "graphql/reports";
import { IArr } from "models/common";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import DeliveryCard from "./DeliveryCard";
import AddEditChecklistModal from "./AddEditChecklistModal";
import DeleteChecklistModal from "./DeleteModal";
import { ProjectDetailsStyles as styles } from "../styles";
import ProjectDetailsTabs from "..";

export function DeliveryChecklist() {
  const projectId = useParams();
  const projectDetailsTab = useProjectDetailsTabs();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const deliveryViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("DELIVERY_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const deliveryEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("DELIVERY_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [addEditChecklistModal, setAddEditChecklistModal] = useState(false);
  const [checklists, setChecklists] = useState<IChecklist[]>([]);
  const [checklistCount, setChecklistCount] = useState(0);
  const [selectedChecklist, setSelectedChecklist] = useState("");
  const [selectedChecklistName, setSelectedChecklistName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [checklistTemplateArr, setChecklistTemplateArr] = useState<IArr[]>([]);
  const [selectedChecklistTemplate, setSelectedChecklistTemplate] =
    useState<IArr | null>(null);
  // const [openFilterMenu, setOpenFilterMenu] = useState(false);
  // const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
  //   null
  // );
  const [newChecklistMenuOpen, setNewChecklistMenuOpen] = useState(false);
  const [newChecklistMenuAnchor, setNewChecklistMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleNewChecklistMenuOpen = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    setNewChecklistMenuAnchor(e.currentTarget);
    setNewChecklistMenuOpen(true);
  };

  const handleNewChecklistMenuClose = () => {
    setNewChecklistMenuAnchor(null);
    setNewChecklistMenuOpen(false);
  };

  // const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   setFilterMenuAnchor(e.currentTarget);
  //   setOpenFilterMenu(true);
  // };

  // const handleFilterMenuClose = () => {
  //   setFilterMenuAnchor(null);
  //   setOpenFilterMenu(false);
  // };

  const { loading, refetch, fetchMore } = useQuery(GET_ALL_CHECKLISTS, {
    variables: {
      project_id: projectId?.project_id,
      page: 1,
      limit: 40,
    },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        deliveryViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
      ),
    onCompleted: (data) => {
      const { getAllChecklist } = data;
      const { status, checklist, count } = getAllChecklist;
      if (status) {
        setChecklistCount(count);
        setChecklists(checklist);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const {} = useQuery(GET_CHECKLIST_TEMPLATE_ARR, {
    variables: {},
    onCompleted: (data) => {
      const { checklistTemplates } = data;
      const { status, templates } = checklistTemplates;
      if (status) {
        setChecklistTemplateArr(templates);
      }
    },
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      deliveryViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
    ),
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [getDeliveryReport, { loading: generatingReport }] = useLazyQuery(
    EXPORT_DELIVERY_REPORT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { generateDeliveryReport } = data;
        if (generateDeliveryReport) {
          const { report } = generateDeliveryReport;
          if (Boolean(report)) {
            var hiddenElement = document.createElement("a");
            hiddenElement.href =
              "data:application/pdf;base64," + encodeURI(report);
            hiddenElement.target = "_blank";
            hiddenElement.download = `Internal Delivery Report.pdf`;
            hiddenElement.click();
          } else {
            toast.error("Something went wrong", { delay: 10 });
          }
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  // useEffect(() => {
  //   const handleScroll = async () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight
  //     ) {
  //       return;
  //     }
  //     if (checklists.length < checklistCount) {
  //       try {
  //         setLoading(true);
  //         await fetchMore({
  //           variables: {
  //             page: Math.ceil(checklists.length / 40) + 1,
  //             limit: 40,
  //           },
  //           updateQuery: (previousResult, { fetchMoreResult }) => {
  //             const newEntries = fetchMoreResult.getAllChecklist.checklist;
  //             return {
  //               getAllChecklist: {
  //                 ...fetchMoreResult?.getAllChecklist,
  //                 checklist: [...checklists, ...newEntries],
  //               },
  //             };
  //           },
  //         });
  //       } catch (error) {
  //         console.error("ERROR", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [checklistCount, checklists, fetchMore, setLoading]);
  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (checklists.length < checklistCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(checklists.length / 40) + 1,
              limit: 40,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.getAllChecklist.checklist;
              return {
                getAllChecklist: {
                  ...fetchMoreResult?.getAllChecklist,
                  checklist: [...checklists, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchMore, setLoading, checklists, checklistCount]);

  useEffect(() => {
    setLoading(generatingReport || loading);
  }, [generatingReport, loading, setLoading]);

  useEffect(() => {
    projectDetailsTab.setSelectedTab(4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2.25 }}>
          <Box sx={styles.view_container}>
            <Box>
              {Boolean(ROLE_PERMISSIONS) &&
              deliveryEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
                ?.DELIVERY_EDIT ? (
                <NewOutlinedBtn
                  disableElevation
                  startIcon={<Add sx={{ color: "#000" }} />}
                  sx={{
                    color: "#000",
                    py: 1,
                    px: 2,
                    fontSize: "14px",
                    borderRadius: "8px",
                  }}
                  onClick={handleNewChecklistMenuOpen}
                >
                  New Delivery
                </NewOutlinedBtn>
              ) : (
                <Box sx={{ height: 43, width: 145 }} />
              )}
              <Menu
                anchorEl={newChecklistMenuAnchor}
                open={newChecklistMenuOpen}
                onClose={handleNewChecklistMenuClose}
                elevation={0}
                sx={{
                  "& .MuiMenu-paper": {
                    width: "250px",
                    border: "1px #00000030 solid",
                    borderRadius: "15px",
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAddEditChecklistModal(true);
                    handleNewChecklistMenuClose();
                  }}
                >
                  <ListItemIcon sx={{ mr: 1 }}>
                    <AddCircle sx={{ color: "#BFBDBC", fontSize: 34 }} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: `${colors.primaryBlack}70`,
                      }}
                    >
                      Create Delivery Schedule
                    </Typography>
                  </ListItemText>
                </MenuItem>
                {checklistTemplateArr.length > 0 && <Divider />}
                {checklistTemplateArr.length > 0 &&
                  checklistTemplateArr.map((temp) => (
                    <MenuItem
                      key={temp.id}
                      onClick={() => {
                        setAddEditChecklistModal(true);
                        setSelectedChecklistTemplate(temp);
                        handleNewChecklistMenuClose();
                      }}
                    >
                      <ListItemIcon sx={{ mr: 1 }}>
                        <TextSnippet sx={{ color: "#BFBDBC", fontSize: 34 }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            color: `${colors.primaryBlack}`,
                            whiteSpace: "normal",
                          }}
                        >
                          {temp.name}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
            {checklistCount > 0 && (
              <NewGreenPrimaryContainedButton
                sx={{ fontSize: "14px", py: 1, px: 2, borderRadius: "8px" }}
                startIcon={
                  <img
                    src={images.DOWNLOAD_ICON}
                    alt="download"
                    style={{ height: 15 }}
                  />
                }
                onClick={() =>
                  getDeliveryReport({
                    variables: {
                      project_id: projectId?.project_id,
                      report_type: "internal",
                    },
                  })
                }
              >
                Generate Report
              </NewGreenPrimaryContainedButton>
            )}
            {/* <Box sx={styles.flex_Acenter}>
              <Box>
                <IconButton onClick={handleFilterMenuOpen} size="small">
                  <FilterAltOutlined
                    fontSize="small"
                    sx={{
                      color: openFilterMenu
                        ? "#41B199"
                        : `${colors.primaryBlack}50`,
                    }}
                  />
                </IconButton>
              </Box>
              <Menu
                anchorEl={filterMenuAnchor}
                open={openFilterMenu}
                onClose={handleFilterMenuClose}
                elevation={1}
                sx={{ "& .MuiMenu-paper": { width: 350 } }}
              >
                TODO: what filters?
                <Filters
                  setSearchText={setSearchText}
                  setProjRoleSearchText={setProjRoleSearchText}
                  projRoleSearchText={projRoleSearchText}
                  setAddContactModal={setAddContactModal}
                />
              </Menu>
              <Box sx={{ mx: 0.55 }}>
                <IconButton onClick={() => setView(0)} size="small">
                  <FormatListBulletedOutlined
                    fontSize="small"
                    sx={{
                      color:
                        view === 0 ? "#41B199" : `${colors.primaryBlack}50`,
                    }}
                  />
                </IconButton>
              </Box>
              <Box>
                <IconButton onClick={() => setView(1)} size="small">
                  <GridViewOutlined
                    fontSize="small"
                    sx={{
                      color:
                        view === 1 ? "#41B199" : `${colors.primaryBlack}50`,
                    }}
                  />
                </IconButton>
              </Box>
            </Box> */}
          </Box>
        </Grid>
        {!loading && checklists.length === 0 ? (
          Boolean(ROLE_PERMISSIONS) &&
          deliveryEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]?.DELIVERY_EDIT ? (
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  cursor: "pointer",
                  my: 1,
                }}
                onClick={() => {
                  setAddEditChecklistModal(true);
                }}
              >
                <Box
                  sx={{
                    border: "3px #BFBDBC dotted",
                    borderRadius: "20px",
                    backgroundColor: `#BFBDBC22`,
                    flexDirection: "column",
                    px: 5,
                    aspectRatio: 1 / 0.7,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 210,
                    height: 147,
                  }}
                >
                  <AddCircle sx={{ color: "#BFBDBC", fontSize: 65, mb: 2 }} />
                  <Typography
                    sx={{
                      color: `#BFBDBC`,
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                    textAlign="center"
                  >
                    New Delivery
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "calc(100vh - 270px)",
                }}
              >
                <Box
                  sx={{
                    height: "200px",
                    width: "550px",
                    backgroundColor: "#BFBDBC22",
                    borderRadius: " 24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: " column",
                  }}
                >
                  <TextSnippet sx={{ fontSize: 30, color: "#000" }} />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.primaryBlack,
                      my: 2,
                      fontWeight: 600,
                    }}
                  >
                    Its empty here!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Please reach out to your admin for access to create a
                    delivery schedule.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )
        ) : (
          <></>
        )}
        {/* TODO: no result view */}
        {/* {!loading && checklists.length === 0 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Search
                sx={{ color: `${colors.primaryBlack}30`, fontSize: 80 }}
              />
              <Typography
                sx={{
                  mt: 2,
                  color: `${colors.primaryBlack}30`,
                  fontSize: "32px",
                  fontWeight: 500,
                }}
                textAlign="center"
              >
                No results found
              </Typography>
            </Box>
          </Grid>
        )} */}
        {!loading &&
          checklists.length > 0 &&
          checklists.map((checklist) => (
            <Grid
              key={checklist.id}
              item
              lg={3}
              md={4}
              sm={4}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "flex-start" },
              }}
            >
              <DeliveryCard
                checklist={checklist}
                setSelectedChecklist={setSelectedChecklist}
                setAddEditChecklistModal={setAddEditChecklistModal}
                setDeleteChecklistModal={setDeleteModal}
                setSelectedChecklistName={setSelectedChecklistName}
              />
            </Grid>
          ))}
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Filters
            setAddEditChecklistModal={setAddEditChecklistModal}
            setSearchText={setSearchText}
          />
        </Grid>
        {!loading && !searchText && checklists.length === 0 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <NoDataView
              title="No checklists added."
              subtitle="Please add a checklist"
            />
          </Grid>
        )}
        {!loading && searchText && checklists.length === 0 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <NoResultsFoundView />
          </Grid>
        )}
        {checklists.length > 0 &&
          checklists.map((item, index) => (
            <Grid item key={item.id}>
              <ChecklistCard
                item={item}
                setAddEditChecklistModal={setAddEditChecklistModal}
                setSelectedChecklist={setSelectedChecklist}
                setDeleteModal={setDeleteModal}
                setSelectedChecklistName={setSelectedChecklistName}
              />
            </Grid>
          ))}
        {checklists.length > 0 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomPagination
              page={paginationModel.page}
              pageSize={paginationModel.pageSize}
              rowCount={checklistCount}
              onPaginationModelChange={setPaginationModel}
            />
          </Grid>
        )} */}
        {addEditChecklistModal && (
          <AddEditChecklistModal
            open={addEditChecklistModal}
            setOpen={setAddEditChecklistModal}
            selectedChecklist={selectedChecklist}
            setSelectedChecklist={setSelectedChecklist}
            refetch={refetch}
            setSelectedChecklistTemplate={setSelectedChecklistTemplate}
            selectedChecklistTemplate={selectedChecklistTemplate}
          />
        )}
        {deleteModal && (
          <DeleteChecklistModal
            open={deleteModal}
            setOpen={setDeleteModal}
            selectedChecklist={selectedChecklist}
            setSelectedChecklist={setSelectedChecklist}
            selectedChecklistName={selectedChecklistName}
            setSelectedChecklistName={setSelectedChecklistName}
            refetch={refetch}
          />
        )}
      </Grid>
    </ProjectDetailsTabs>
  );
}
