import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { AccessTimeOutlined } from "@mui/icons-material";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import Table from "common/Table";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { plurify } from "utils/helpers";
import { GET_PROJECT_DATES } from "graphql/projects";
import { useStore } from "utils/store";
import {
  GET_ALL_ISSUES,
  GET_ISSUE_DASHBOARD,
  UPDATE_ISSUE_STATUS,
} from "graphql/issues";
import { StorageConstants } from "utils/storage";
import { IIssue } from "models/issues";
import { IssuePrirotyMapping, IssueRelatedToArr } from "utils/constants";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import Filters from "./Filters";
import RaiseIssueModal from "./RaiseIssueModal";
import ViewIssueModal from "./ViewIssueModal";
import TableComponent from "./TableComponent";
import { IssueDashboardStyles as localStyles } from "./styles";
import { ProjectDetailsStyles as styles } from "../styles";
import ProjectDetailsTabs from "..";

export function IssueDashboard() {
  const projectDetailsTabs = useProjectDetailsTabs();
  const projectId = useParams();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const AllIssuesViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("ISSUES_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );

  const [issues, setIssues] = useState<IIssue[]>([]);
  const [issuesCount, setIssuesCount] = useState(0);
  const [raiseIssueModal, setRaiseIssueModal] = useState(false);
  const [viewIssueModal, setViewIssueModal] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [noOfDaysRemaining, setNoOfDaysRemaining] = useState(0);
  const [dashboardMetrics, setDashboardMetrics] = useState<any>(null);
  const [filterTable, setFilterTable] = useState("ALL");
  const [filterRelatedTo, setFilterRelatedTo] = useState("");
  const [filterIssueType, setFilterIssueType] = useState("");
  const [filterPriority, setFilterPriority] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterAssignedTo, setFilterAssignedTo] = useState("");
  const [selectedIssue, setSelectedIssue] = useState("");

  const { loading: gettingDeliveryDate } = useQuery(GET_PROJECT_DATES, {
    variables: { id: projectId?.project_id },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        AllIssuesViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[AllIssuesViewIndex]?.ISSUES_VIEW
      ),
    onCompleted: (data) => {
      const { project } = data;
      const { status, project: rawProject } = project;
      if (status) {
        const datesJSON = rawProject?.projectDates;
        const parsedDatesJSON = Boolean(datesJSON)
          ? JSON.parse(datesJSON)
          : null;
        if (Boolean(parsedDatesJSON)) {
          if (parsedDatesJSON?.deliveryDate) {
            setDeliveryDate(parsedDatesJSON?.deliveryDate);
          }
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: loadingDashboard, refetch: refetchMetrics } = useQuery(
    GET_ISSUE_DASHBOARD,
    {
      variables: { project_id: projectId?.project_id },
      skip:
        !projectId?.project_id ||
        !(
          Boolean(ROLE_PERMISSIONS) &&
          AllIssuesViewIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[AllIssuesViewIndex]?.ISSUES_VIEW
        ),
      onCompleted: (data) => {
        const { issuesMetrics } = data;
        const { status, issuesMetrics: rawIssueMetrics } = issuesMetrics;
        if (status) {
          setDashboardMetrics(rawIssueMetrics);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const { loading, refetch, fetchMore } = useQuery(GET_ALL_ISSUES, {
    variables: {
      project_id: projectId?.project_id,
      limit: 50,
      page: 1,
      filter: {
        ...(filterTable === "MY" && {
          assignedToId: sessionStorage.getItem(
            StorageConstants.LOGGED_IN_USER_ID
          ),
        }),
        ...(filterTable === "RAISED_BY_ME" && {
          createdById: sessionStorage.getItem(
            StorageConstants.LOGGED_IN_USER_ID
          ),
        }),
        ...(Boolean(filterRelatedTo) && { issueRelatedTo: filterRelatedTo }),
        ...(Boolean(filterIssueType) && { issueType: filterIssueType }),
        ...(Boolean(filterPriority) && { priority: filterPriority }),
        ...(Boolean(filterStatus) && { status: filterStatus }),
        ...(Boolean(filterAssignedTo) && { assignedToId: filterAssignedTo }),
      },
    },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        AllIssuesViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[AllIssuesViewIndex]?.ISSUES_VIEW
      ),
    onCompleted: (data) => {
      const { issues } = data;
      const { status, count, issues: rawIssues } = issues;
      if (status) {
        setIssuesCount(count);
        setIssues(rawIssues);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireIssueStatusUpdateApi, { loading: updatingIssueStatus }] =
    useMutation(UPDATE_ISSUE_STATUS, {
      onCompleted: (data) => {
        setLoading(false);
        const { updateIssueStatus } = data;
        const { status, message } = updateIssueStatus;
        if (status) {
          refetch();
          refetchMetrics();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    });

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (issues.length < issuesCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(issues.length / 50) + 1,
              limit: 50,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.issues.issues;
              return {
                issues: {
                  ...fetchMoreResult?.issues,
                  issues: [...issues, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchMore, setLoading, issues, issuesCount]);

  useEffect(() => {
    setLoading(
      gettingDeliveryDate || loadingDashboard || loading || updatingIssueStatus
    );
  }, [
    gettingDeliveryDate,
    loading,
    loadingDashboard,
    setLoading,
    updatingIssueStatus,
  ]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "Id",
        minWidth: 110,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value}</Typography>
        ),
      },
      {
        field: "description",
        headerName: "Description",
        minWidth: 250,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, styles.wrap]}>
            {params?.value?.description}
          </Typography>
        ),
      },
      {
        field: "issueType",
        headerName: "Type",
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>{params?.value?.name}</Typography>
        ),
      },
      {
        field: "issueRelatedTo",
        headerName: "Related To",
        minWidth: 180,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {params?.value
              ? IssueRelatedToArr.find((x) => x.id === params?.value)?.name
              : ""}
          </Typography>
        ),
      },
      {
        field: "priority",
        headerName: "Priority",
        headerAlign: "center",
        minWidth: 155,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) =>
          params?.value ? (
            <Tooltip title={(IssuePrirotyMapping as any)[params?.value].name}>
              <img
                src={(IssuePrirotyMapping as any)[params?.value].flag}
                alt="priority"
              />
            </Tooltip>
          ) : (
            <></>
          ),
      },
      {
        field: "status",
        headerName: "Status",
        headerAlign: "center",
        minWidth: 155,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <TableComponent
            params={params}
            fireIssueStatusUpdateApi={fireIssueStatusUpdateApi}
          />
        ),
      },
      {
        field: "createdDate",
        headerName: "Raised On",
        minWidth: 145,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {Boolean(params?.value)
              ? moment(params?.value).format("DD MMMM YYYY")
              : ""}
          </Typography>
        ),
      },
      {
        field: "assignedTo",
        headerName: "Assigned To",
        minWidth: 160,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {params?.value?.fullName}
          </Typography>
        ),
      },
    ],
    [fireIssueStatusUpdateApi]
  );

  useEffect(() => {
    if (Boolean(deliveryDate)) {
      setNoOfDaysRemaining(moment(deliveryDate).diff(moment(), "days"));
    }
  }, [deliveryDate]);

  useEffect(() => {
    projectDetailsTabs.setSelectedTab(6);
    sessionStorage.removeItem("redirect_url");
    if (projectId && projectId?.issue_id) {
      setSelectedIssue(projectId?.issue_id);
      setViewIssueModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <Grid container spacing={2}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: { xs: "flex", md: "none" } }}
          justifyContent="center"
        >
          <Box
            sx={{
              backgroundColor: "#B2D5EB50",
              py: 1,
              px: 2,
              borderRadius: "8px",
            }}
          >
            <Typography sx={{ color: colors.primary, fontSize: "13px" }}>
              {`Delivery Date: ${
                Boolean(deliveryDate)
                  ? moment(deliveryDate).format("DD MMMM YYYY")
                  : "Not specified"
              }`}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          lg={1.8}
          md={3}
          sm={3}
          xs={12}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Paper sx={[localStyles.metric_card, { height: "135px" }]}>
            <Box
              sx={[
                localStyles.card_header,
                { backgroundColor: "#B2D5EB", py: 1 },
              ]}
            >
              <Typography sx={[styles.cell_text, { color: colors.primary }]}>
                Delivery Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 600,
                  color: colors.secondary,
                }}
              >
                {Boolean(deliveryDate)
                  ? moment(deliveryDate).format("DD MMMM YYYY")
                  : "Not specified"}
              </Typography>
            </Box>
            <Box
              sx={{
                px: 3,
                py: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {Boolean(deliveryDate) && (
                <Box sx={styles.flex_Acenter}>
                  <AccessTimeOutlined fontSize="large" />
                  <Typography sx={localStyles.countdown_text}>
                    {`${Math.abs(noOfDaysRemaining)} ${plurify(
                      noOfDaysRemaining,
                      "day"
                    )} ${noOfDaysRemaining >= 0 ? "left" : "ago"}`}
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={3.4} md={3} sm={4} xs={12}>
          <Paper sx={localStyles.metric_card}>
            <Box sx={[localStyles.card_header, { backgroundColor: "#DF7D8C" }]}>
              <Typography sx={localStyles.header_text}>All Issues</Typography>
            </Box>
            <Box
              sx={[
                styles.flex_JCsb_Acenter,
                { mt: 2, flexWrap: "wrap", px: 3, pb: 2 },
              ]}
            >
              <Box>
                <Typography textAlign="center" sx={localStyles.metric_text}>
                  {dashboardMetrics?.allIssues?.open || 0}
                </Typography>
                <Typography
                  textAlign="center"
                  sx={localStyles.metric_value_text}
                >
                  Open
                </Typography>
              </Box>
              <Box>
                <Typography textAlign="center" sx={localStyles.metric_text}>
                  {dashboardMetrics?.allIssues?.reopen || 0}
                </Typography>
                <Typography
                  textAlign="center"
                  sx={localStyles.metric_value_text}
                >
                  Re-Open
                </Typography>
              </Box>
              <Box>
                <Typography textAlign="center" sx={localStyles.metric_text}>
                  {dashboardMetrics?.allIssues?.closed || 0}
                </Typography>
                <Typography
                  textAlign="center"
                  sx={localStyles.metric_value_text}
                >
                  Closed
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={3.4} md={3} sm={4} xs={12}>
          <Paper sx={localStyles.metric_card}>
            <Box sx={[localStyles.card_header, { backgroundColor: "#EE9E84" }]}>
              <Typography sx={localStyles.header_text}>My Issues</Typography>
            </Box>
            <Box
              sx={[
                styles.flex_JCsb_Acenter,
                { mt: 2, flexWrap: "wrap", px: 3, pb: 2 },
              ]}
            >
              <Box>
                <Typography textAlign="center" sx={localStyles.metric_text}>
                  {dashboardMetrics?.myIssues?.open || 0}
                </Typography>
                <Typography
                  textAlign="center"
                  sx={localStyles.metric_value_text}
                >
                  Open
                </Typography>
              </Box>
              <Box>
                <Typography textAlign="center" sx={localStyles.metric_text}>
                  {dashboardMetrics?.myIssues?.reopen || 0}
                </Typography>
                <Typography
                  textAlign="center"
                  sx={localStyles.metric_value_text}
                >
                  Re-Open
                </Typography>
              </Box>
              <Box>
                <Typography textAlign="center" sx={localStyles.metric_text}>
                  {dashboardMetrics?.myIssues?.closed || 0}
                </Typography>
                <Typography
                  textAlign="center"
                  sx={localStyles.metric_value_text}
                >
                  Closed
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={3.4} md={3} sm={4} xs={12}>
          <Paper sx={localStyles.metric_card}>
            <Box sx={[localStyles.card_header, { backgroundColor: "#41B199" }]}>
              <Typography sx={localStyles.header_text}>Raised by Me</Typography>
            </Box>
            <Box
              sx={[
                styles.flex_JCsb_Acenter,
                { mt: 2, flexWrap: "wrap", px: 3, pb: 2 },
              ]}
            >
              <Box>
                <Typography textAlign="center" sx={localStyles.metric_text}>
                  {dashboardMetrics?.raisedIssues?.open || 0}
                </Typography>
                <Typography
                  textAlign="center"
                  sx={localStyles.metric_value_text}
                >
                  Open
                </Typography>
              </Box>
              <Box>
                <Typography textAlign="center" sx={localStyles.metric_text}>
                  {dashboardMetrics?.raisedIssues?.reopen || 0}
                </Typography>
                <Typography
                  textAlign="center"
                  sx={localStyles.metric_value_text}
                >
                  Re-Open
                </Typography>
              </Box>
              <Box>
                <Typography textAlign="center" sx={localStyles.metric_text}>
                  {dashboardMetrics?.raisedIssues?.closed || 0}
                </Typography>
                <Typography
                  textAlign="center"
                  sx={localStyles.metric_value_text}
                >
                  Closed
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 2 }}>
          <Filters
            setRaiseIssueModal={setRaiseIssueModal}
            filterTable={filterTable}
            setFilterTable={setFilterTable}
            filterRelatedTo={filterRelatedTo}
            setFilterRelatedTo={setFilterRelatedTo}
            filterIssueType={filterIssueType}
            setFilterIssueType={setFilterIssueType}
            filterPriority={filterPriority}
            setFilterPriority={setFilterPriority}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            filterAssignedTo={filterAssignedTo}
            setFilterAssignedTo={setFilterAssignedTo}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Table
            columns={columns}
            rows={issues}
            rowCount={10}
            showCellVerticalBorder={false}
            showColumnVerticalBorder={false}
            disableColumnMenu
            sx={{
              ...styles.table,
              "& .MuiDataGrid-row": {
                cursor: "pointer",
              },
            }}
            onRowClick={(params) => {
              setSelectedIssue(params?.row?.id);
              setViewIssueModal(true);
            }}
            hideFooter
          />
        </Grid>
        {raiseIssueModal && (
          <RaiseIssueModal
            open={raiseIssueModal}
            setOpen={setRaiseIssueModal}
            refetch={refetch}
            refetchMetrics={refetchMetrics}
          />
        )}
        {viewIssueModal && (
          <ViewIssueModal
            open={viewIssueModal}
            setOpen={setViewIssueModal}
            selectedIssue={selectedIssue}
            setSelectedIssue={setSelectedIssue}
            refetch={refetch}
            refetchMetrics={refetchMetrics}
          />
        )}
      </Grid>
    </ProjectDetailsTabs>
  );
}
