import { create } from "zustand";
import { IFillableFields, ITags } from "pages/ClientScreens/ContractDocEditor";

interface IContractStore {
  fillableFields: IFillableFields[];
  setFillableFields: (arr: IFillableFields[]) => void;

  tags: ITags[];
  setTags: (arr: ITags[]) => void;
}

export const useContract = create<IContractStore>((set) => ({
  fillableFields: [],
  setFillableFields: (fillableFields: IFillableFields[]) =>
    set({ fillableFields }),

  tags: [],
  setTags: (tags: ITags[]) => set({ tags }),
}));
