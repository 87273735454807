import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import { ComplexityArr } from "utils/constants";
import { GlobalLabels, ManageTemplatesLabels } from "common/AppConstants";
import {
  CREATE_TEMPLATE,
  GET_TEMPLATE_DETAILS,
  GET_TEMPLATE_TYPES,
  UPDATE_TEMPLATE,
} from "graphql/templates";
import { useStore } from "utils/store";
import { IAddEditTemplateFields } from "models/templates";
import { RouteNames } from "routes/routeNames";

import { ManageTemplatesStyles as styles } from "./styles";

interface IAddEditTemplateModalProps extends IModalProps {
  selectedTemplate: string;
  setSelectedTemplate: Dispatch<SetStateAction<string>>;
  refetchTemplates: any;
}

const AddEditTemplateModal = (props: IAddEditTemplateModalProps) => {
  const {
    open,
    setOpen,
    selectedTemplate,
    setSelectedTemplate,
    refetchTemplates,
  } = props;

  const { setLoading } = useStore();
  const navigate = useNavigate();

  const [templateTypeArr, setTemplateTypeArr] = useState<IArr[]>([]);
  const [initialValues, setInitialValues] = useState<IAddEditTemplateFields>({
    id: "",
    templateId: "",
    name: "",
    templateTypeId: "",
    complexity: "",
    description: "",
  });

  const modalTitle = Boolean(selectedTemplate)
    ? `${GlobalLabels.EDIT} Template`
    : ManageTemplatesLabels.CREATE_TEMP;
  const btnTitle = Boolean(selectedTemplate)
    ? GlobalLabels.UPDATE
    : `${GlobalLabels.CREATE} Template`;

  const handleClose = () => {
    setSelectedTemplate("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [getTemplateDetails, { loading: gettingDetails }] = useLazyQuery(
    GET_TEMPLATE_DETAILS,
    {
      onCompleted: (data) => {
        // setLoading(false);
        const { getContractTemplate } = data;
        const { status, contractTemplate } = getContractTemplate;
        if (status) {
          const {
            id,
            name,
            additionalDetails,
            templateType,
            templateId,
            description,
          } = contractTemplate;
          const parsedJSON = Boolean(additionalDetails)
            ? JSON.parse(additionalDetails)
            : null;
          setInitialValues({
            id,
            templateId,
            name,
            templateTypeId: templateType?.id || "",
            complexity: Boolean(parsedJSON) ? parsedJSON.complexity : "",
            description: description || "",
          });
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const { loading: gettingArr } = useQuery(GET_TEMPLATE_TYPES, {
    variables: {},
    onCompleted: (data) => {
      const { templateTypes } = data;
      const { status, templateTypes: rawTemplateTypes } = templateTypes;
      if (status) {
        setTemplateTypeArr(rawTemplateTypes);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireCreateTemplateApi, { loading: creating }] = useMutation(
    CREATE_TEMPLATE,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { createContractTemplate } = data;
        const { status, message, id } = createContractTemplate;
        handleClose();
        if (status) {
          refetchTemplates();
          toast.success(message, { delay: 10 });
          navigate(`${RouteNames.TEMPLATE}/${id}`);
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const [fireUpdateTemplateApi, { loading: updating }] = useMutation(
    UPDATE_TEMPLATE,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateContractTemplate } = data;
        const { status, message } = updateContractTemplate;
        handleClose();
        if (status) {
          refetchTemplates();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleUpsertTemplate = (values: IAddEditTemplateFields) => {
    const { id, complexity, ...rest } = values;
    const additionalDetailsJSON = { complexity: complexity };
    if (Boolean(selectedTemplate)) {
      fireUpdateTemplateApi({
        variables: {
          payload: {
            id,
            additionalDetails: JSON.stringify(additionalDetailsJSON),
            ...rest,
          },
        },
      });
    } else {
      const blob = new Blob(["<p></p>"], { type: "text/html" });
      const file = new File([blob], `${values.name}.html`, {
        type: "text/html",
      });

      fireCreateTemplateApi({
        variables: {
          payload: {
            additionalDetails: JSON.stringify(additionalDetailsJSON),
            ...rest,
          },
          file: file,
        },
      });
    }
  };

  useEffect(() => {
    if (Boolean(selectedTemplate)) {
      getTemplateDetails({ variables: { id: selectedTemplate } });
    }
  }, [getTemplateDetails, selectedTemplate]);

  useEffect(() => {
    setLoading(creating || gettingArr || gettingDetails || updating);
  }, [creating, gettingArr, gettingDetails, setLoading, updating]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        templateId: Yup.string().required("Please enter the Finka ID"),
        name: Yup.string().required("Please enter the name of the template"),
        templateTypeId: Yup.string().required("Please select a template type"),
        complexity: Yup.string().required(
          "Please select the complexity of the template"
        ),
      })}
      onSubmit={handleUpsertTemplate}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog open={open} onClose={handleDialogClose}>
          <DialogTitle sx={styles.flex_JCsb_Acenter}>
            <Typography sx={styles.create_temp_text}>{modalTitle}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={4} sx={{ p: 3 }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.field_label}>
                    Finka ID
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  <TextField
                    id="templateId"
                    value={values.templateId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.templateId && errors?.templateId && (
                    <Typography sx={styles.error_text}>
                      {errors?.templateId}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.field_label}>
                    {ManageTemplatesLabels.TEMP_FORM.TEMP_NAME}{" "}
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  <TextField
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                  />
                  {touched?.name && errors?.name && (
                    <Typography sx={styles.error_text}>
                      {errors?.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.field_label}>Description</Typography>
                  <TextField
                    id="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    inputProps={{ style: styles.text_input }}
                    multiline
                    minRows={3}
                  />
                  {touched?.name && errors?.name && (
                    <Typography sx={styles.error_text}>
                      {errors?.name}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.field_label}>
                    {ManageTemplatesLabels.TEMP_FORM.TEMP_TYPE}{" "}
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  <Autocomplete
                    id="templateTypeId"
                    value={
                      templateTypeArr.length > 0
                        ? templateTypeArr.find(
                            (x) => x.id === values.templateTypeId
                          ) || { id: "", name: "" }
                        : { id: "", name: "" }
                    }
                    onChange={(_, newValue) =>
                      setFieldValue("templateTypeId", newValue.id)
                    }
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    disableClearable
                    options={templateTypeArr}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: styles.text_input,
                        }}
                      />
                    )}
                  />
                  {touched?.templateTypeId && errors?.templateTypeId && (
                    <Typography sx={styles.error_text}>
                      {errors?.templateTypeId}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.field_label}>
                    {ManageTemplatesLabels.TEMP_FORM.COMPLEXITY}{" "}
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  <Autocomplete
                    id="complexity"
                    value={
                      ComplexityArr.find((x) => x.id === values.complexity) || {
                        id: "",
                        name: "",
                      }
                    }
                    onChange={(_, newValue) =>
                      setFieldValue("complexity", newValue.id)
                    }
                    onBlur={handleBlur}
                    size="small"
                    fullWidth
                    disableClearable
                    options={ComplexityArr}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: styles.text_input,
                        }}
                      />
                    )}
                  />
                  {touched?.complexity && errors?.complexity && (
                    <Typography sx={styles.error_text}>
                      {errors?.complexity}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions sx={styles.modal_footer_container}>
            <Button
              variant="contained"
              type="submit"
              disabled={creating || updating || !isValid}
              sx={styles.disabled_btn}
              onClick={() => handleSubmit()}
            >
              {btnTitle}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddEditTemplateModal;
