import React, { Dispatch, SetStateAction, useState } from "react";
import {
	Avatar,
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	Typography,
} from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";

import { GET_CONTACT_DEPARTMENT } from "graphql/meta";
import { IUpdateContactFieldsNew } from "models/contacts";
import { IArr } from "models/common";
import { BlackActionContainedButton } from "common/Buttons";
import { CountryCodeArr, GenderArr } from "utils/constants";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface IPersonDetailsFormProps {
	setActiveSection: Dispatch<SetStateAction<number>>;
	initialValues: IUpdateContactFieldsNew;
	refetch: any;
	updateContactApi: any;
	profilePicture: any;
}

function PersonalDetailsForm(props: IPersonDetailsFormProps) {
	const { initialValues, setActiveSection } = props;

	const [departmentArr, setDepartmentArr] = useState<IArr[]>([]);

	const {} = useQuery(GET_CONTACT_DEPARTMENT, {
		variables: {},
		onCompleted: (data) => {
			const { departments } = data;
			const { status, departments: rawDepts } = departments;
			if (status) {
				setDepartmentArr(rawDepts);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				firstName: Yup.string().required("Please enter your first name"),
				lastName: Yup.string().required("Please enter your last name"),
				emailId: Yup.string()
					.required("Please enter the email id")
					.email("Please enter a valid email"),
				// mobile: Yup.string()
				// 	.required("Please enter your phone number")
				// 	.matches(
				// 		/^[0-9]{3}\s[0-9]{3}\s[0-9]{3}$/,
				// 		"Contact number must be of 9 digits"
				// 	),
				contactDepartmentId: Yup.string().required(
					"Please select a department"
				),
			})}
			onSubmit={() => {}}
			enableReinitialize
		>
			{({ values }) => (
				<Box
					sx={{
						pt: { xs: 2, sm: 14 },
						px: 4,
						pb: 1.5,
						display: "flex",
						flexDirection: "column",
						height: "100%",
					}}
				>
					<Grid container spacing={3}>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={{ display: { xs: "flex", sm: "none" } }}
							justifyContent="center"
						>
							<Box>
								{Boolean(values.profile_picture) ? (
									<Avatar
										sx={{ height: 70, width: 70, cursor: "pointer" }}
										src={
											typeof values.profile_picture === "string"
												? values.profile_picture
												: URL.createObjectURL(
														values.profile_picture
												  )
										}
									/>
								) : (
									<Avatar
										sx={{
											height: 70,
											width: 70,
											cursor: "pointer",
										}}
									/>
								)}
							</Box>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								First Name
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.firstName}
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Last Name
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.lastName}
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Email
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.emailId}
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Mobile
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{Boolean(values.mobile) &&
								Boolean(values.mobile.nationalNumber)
									? `${
											CountryCodeArr?.find(
												(x) => x.id === values.mobile?.countryCode
											)?.name || ""
									  } ${values.mobile?.nationalNumber || ""}`
									: Boolean(values.mobile.rawInput)
									? `+61 ${values.mobile.rawInput}`
									: ""}
							</Typography>
						</Grid>
						<Grid item lg={3} md={4} sm={5} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Unit/Suite Number
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.unit}
							</Typography>
						</Grid>
						<Grid item lg={9} md={8} sm={7} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Address
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{Boolean(values.address)
									? typeof values.address === "string"
										? values.address
										: ""
									: ""}
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Date of Birth
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{Boolean(values.dob)
									? moment(values?.dob).format("DD MMMM YYYY")
									: ""}
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Nationality
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.nationality}
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Gender
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{Boolean(values?.gender)
									? GenderArr.find((x) => x.id === values.gender)?.name
									: ""}
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Department
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{Boolean(values?.contactDepartmentId)
									? departmentArr.find(
											(x) => x.id === values.contactDepartmentId
									  )?.name || "TBA"
									: "TBA"}
							</Typography>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={6}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								Project Role
							</Typography>
							<Typography sx={[styles.field_label, { fontWeight: 400 }]}>
								{values?.project_role || "TBA"}
							</Typography>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, color: "#000" },
								]}
							>
								The Everyone Project
							</Typography>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, fontSize: "12px" },
								]}
							>
								The Production Company may need to share your name,
								role, and contact details with The Everyone Project.
							</Typography>
							<FormControlLabel
								control={
									<Checkbox
										checked={values.shareConsent}
										checkedIcon={
											<CheckBox
												fontSize="small"
												sx={{ color: "#6f6f6f" }}
											/>
										}
										icon={
											<CheckBoxOutlineBlank
												fontSize="small"
												sx={{ color: "#6f6f6f" }}
											/>
										}
										size="small"
										disabled
									/>
								}
								label={
									<Typography
										sx={[
											styles.field_label,
											{
												fontWeight: 400,
												fontSize: "12px",
											},
										]}
									>
										Yes, I consent to the Production Company sharing
										my details (unchecked box implies non-consent to
										share my details).
									</Typography>
								}
							/>
						</Grid>
					</Grid>
					<Box sx={{ flexGrow: 1, mt: 2 }}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
							}}
						>
							<Box sx={{ flexGrow: 1 }} />
							<Box
								sx={[
									styles.flex_Acenter,
									{ justifyContent: "flex-end" },
								]}
							>
								<BlackActionContainedButton
									variant="contained"
									size="small"
									sx={{
										borderRadius: "20px",
										fontSize: "13px",
										fontWeight: 600,
										width: "120px",
									}}
									disableElevation
									disableRipple
									onClick={() => setActiveSection(1)}
								>
									Next
								</BlackActionContainedButton>
							</Box>
						</Box>
					</Box>
				</Box>
			)}
		</Formik>
	);
}

export default PersonalDetailsForm;
