export const changeReasonMapping = {
  Create: "Created",
  Update: "Edited",
  Execute: "Executed",
  Emailed: "Emailed",
};

export const sentenceMapping = {
  Create: "created a new",
  Update: "edited a",
  Execute: "executed a",
  Emailed: "emailed a",
};

export const entityTypeMapping = {
  Project: "project",
  Contact: "contact",
  Document: "document",
};
