import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { GridRenderCellParams } from "@mui/x-data-grid";

import { colors } from "theme/colors";

interface IAddDocContractComponentProps {
  params1: GridRenderCellParams<any>;
  setSelectedIndexItem: Dispatch<SetStateAction<string>>;
  setUploadDocModal: Dispatch<SetStateAction<boolean>>;
  setCreateModal: Dispatch<SetStateAction<boolean>>;
  setUploadModal: Dispatch<SetStateAction<boolean>>;
}

export function AddDocContractComponent(props: IAddDocContractComponentProps) {
  const {
    params1,
    setSelectedIndexItem,
    setUploadDocModal,
    setCreateModal,
    setUploadModal,
  } = props;

  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const [actionsMenuAnchor, setActionsMenuAnchor] =
    useState<HTMLElement | null>(null);

  const handleActionsMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActionsMenuAnchor(e.currentTarget);
    setOpenActionsMenu(true);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchor(null);
    setOpenActionsMenu(false);
  };

  return (
    <Box>
      <Tooltip
        title={
          params1?.row?.itemType === "DOCUMENT"
            ? "Attach Document"
            : "Create Contract"
        }
      >
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            if (params1?.row?.itemType === "DOCUMENT") {
              setSelectedIndexItem(params1?.row?.id);
              setUploadDocModal(true);
            } else {
              handleActionsMenuOpen(e);
            }
          }}
        >
          <Add sx={{ color: "#a6a6a6", fontSize: 17 }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={actionsMenuAnchor}
        open={openActionsMenu}
        onClose={handleActionsMenuClose}
        elevation={1}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <MenuList disablePadding>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setSelectedIndexItem(params1?.row?.id);
              setCreateModal(true);
              handleActionsMenuClose();
            }}
          >
            <ListItemText>
              <Typography
                sx={{ color: colors.secondaryBlack, fontSize: "13px" }}
              >
                In-house Contract
              </Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setSelectedIndexItem(params1?.row?.id);
              setUploadModal(true);
              handleActionsMenuClose();
            }}
          >
            <ListItemText>
              <Typography
                sx={{ color: colors.secondaryBlack, fontSize: "13px" }}
              >
                Third-party Contract
              </Typography>
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
