import { colors } from "theme/colors";

export const SetPwdSuccessStyles = {
  set_pwd_bg: { width: "100%", height: "100%" },
  set_pwd_container: {
    height: "100%",
    width: "100%",
  },
  content_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  success_text: {
    fontSize: "24px",
    fontWeight: 700,
    color: `${colors.primaryBlack}95`,
  },
  congrats_text: { color: "#00000080", textAlign: "center", fontSize: "14px" },
  login_btn: {
    width: "100%",
    fontSize: "14px",
    mt: 3,
    // borderRadius: "24px",
    py: 1.25,
  },
};
