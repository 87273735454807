import { create } from "zustand";
import { IProjectDetailsMenuItem } from "models/common";

interface IProjectDetailsMenu {
  isOpen: boolean;
  anchor: null | HTMLElement;
  selectedMenuItem: IProjectDetailsMenuItem;
  onOpen: (target: any) => void;
  onClose: () => void;
  onMenuItemClick: (menuItem: IProjectDetailsMenuItem) => void;
}

export const useProjectDetailsMenu = create<IProjectDetailsMenu>((set) => ({
  isOpen: false,
  anchor: null,
  selectedMenuItem: { id: "0", name: "Project", path: "basic-details" },
  onOpen: (target) => set({ isOpen: true, anchor: target }),
  onClose: () => set({ isOpen: false, anchor: null }),
  onMenuItemClick: (menuItem: IProjectDetailsMenuItem) =>
    set({ selectedMenuItem: menuItem }),
}));
