import { gql } from "@apollo/client";

export const GET_ISSUE_DASHBOARD = gql`
  query getIssuesMetrics($project_id: String!) {
    issuesMetrics(project_id: $project_id) {
      status
      message
      issuesMetrics {
        raisedIssues {
          open
          reopen
          closed
        }
        myIssues {
          open
          reopen
          closed
        }
        allIssues {
          open
          reopen
          closed
        }
      }
    }
  }
`;

export const GET_PROJECT_USERS = gql`
  query getProjectUsers($project_id: String!) {
    getProjectUsers(project_id: $project_id) {
      status
      message
      projectUsers {
        id
        fullName
      }
    }
  }
`;

export const GET_ISSUE_TYPES = gql`
  query IssueTypes {
    issueTypes {
      id
      name
    }
  }
`;

export const RAISE_ISSUE = gql`
  mutation raiseIssue($payload: RaiseIssueCreateInput!, $documents: [Upload!]) {
    raiseIssue(payload: $payload, documents: $documents) {
      status
      message
    }
  }
`;

export const GET_ALL_ISSUES = gql`
  query getIssues(
    $filter: IssueFilterInput
    $project_id: String!
    $limit: Int
    $page: Int
    $sort_by: String
  ) {
    issues(
      filter: $filter
      project_id: $project_id
      limit: $limit
      page: $page
      sort_by: $sort_by
    ) {
      status
      message
      count
      issues {
        id
        issueRelatedTo
        issueType {
          id
          name
        }
        description {
          id
          description
        }
        priority
        status
        createdDate
        assignedTo {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_ISSUE_DETAILS = gql`
  query getIssue($issue_id: String!, $project_id: String!) {
    issueDetails(issue_id: $issue_id, project_id: $project_id) {
      status
      message
      issueDetails {
        id
        project {
          id
          name
        }
        issueRelatedTo
        issueType {
          id
          name
        }
        createdBy {
          id
          fullName
        }
        assignedTo {
          id
          fullName
        }
        priority
        issueHistory {
          id
          description
          documents {
            id
            document
            documentName
          }
          createdDate
          createdBy {
            id
            fullName
          }
        }
        status
        statusHistory {
          status
          historyDate
        }
      }
    }
  }
`;

export const GIVE_REPLY_TO_ISSUE = gql`
  mutation addComments($payload: IssueCommentInput!, $documents: [Upload!]) {
    addIssueComment(payload: $payload, documents: $documents) {
      status
      message
    }
  }
`;

export const UPDATE_ISSUE_STATUS = gql`
  mutation updateIssueStatus($payload: IssueStatusUpdateInput!) {
    updateIssueStatus(payload: $payload) {
      status
      message
    }
  }
`;

export const EXPORT_ISSUES = gql`
  query exportIssues($project_id: String!, $filter: IssueFilterInput) {
    generateIssueReport(project_id: $project_id, filter: $filter) {
      status
      message
      report
    }
  }
`;
