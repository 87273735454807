import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

interface IProps {
  loading: boolean;
}

const BackdropLoader = (props: IProps) => {
  const { loading } = props;
  return (
    <Backdrop
      open={loading}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 + theme.zIndex.modal }}
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default BackdropLoader;
