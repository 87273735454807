import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import {
  ErrorOutlineOutlined,
  FilterAltOutlined,
  Share,
} from "@mui/icons-material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IArr } from "models/common";
import {
  EXPORT_ISSUES,
  GET_ISSUE_TYPES,
  GET_PROJECT_USERS,
} from "graphql/issues";
import {
  IssuePriorityArr,
  IssueRelatedToArr,
  IssueStatusArr,
} from "utils/constants";
import { useStore } from "utils/store";
import { StorageConstants } from "utils/storage";
import { BlackActionContainedButton, NewOutlinedBtn } from "common/Buttons";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface IUArr {
  id: string;
  fullName: string;
}

interface IFiltersProps {
  setRaiseIssueModal: Dispatch<SetStateAction<boolean>>;
  filterTable: string;
  setFilterTable: Dispatch<SetStateAction<string>>;
  filterRelatedTo: string;
  setFilterRelatedTo: Dispatch<SetStateAction<string>>;
  filterIssueType: string;
  setFilterIssueType: Dispatch<SetStateAction<string>>;
  filterPriority: string;
  setFilterPriority: Dispatch<SetStateAction<string>>;
  filterStatus: string;
  setFilterStatus: Dispatch<SetStateAction<string>>;
  filterAssignedTo: string;
  setFilterAssignedTo: Dispatch<SetStateAction<string>>;
}

function Filters(props: IFiltersProps) {
  const {
    setRaiseIssueModal,
    filterTable,
    setFilterTable,
    filterRelatedTo,
    setFilterRelatedTo,
    filterIssueType,
    setFilterIssueType,
    filterPriority,
    setFilterPriority,
    filterStatus,
    setFilterStatus,
    filterAssignedTo,
    setFilterAssignedTo,
  } = props;

  const projectId = useParams();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const AllIssuesViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("ISSUES_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const AllIssuesEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("ISSUES_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );
  const [issueTypeArr, setIssueTypeArr] = useState<IArr[]>([]);
  const [assignedToArr, setAssignedToArr] = useState<IUArr[]>([]);

  const { loading: loadingTypes } = useQuery(GET_ISSUE_TYPES, {
    onCompleted: (data) => {
      const { issueTypes } = data;
      if (Boolean(issueTypes)) {
        setIssueTypeArr(issueTypes);
      } else {
        setIssueTypeArr([]);
      }
    },
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      AllIssuesViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[AllIssuesViewIndex]?.ISSUES_VIEW
    ),
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: loadingUsers } = useQuery(GET_PROJECT_USERS, {
    variables: { project_id: projectId?.project_id },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        AllIssuesViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[AllIssuesViewIndex]?.ISSUES_VIEW
      ),
    onCompleted: (data) => {
      const { getProjectUsers } = data;
      const { status, projectUsers } = getProjectUsers;
      if (status) {
        setAssignedToArr(projectUsers);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [exportIssues, { loading: exporting }] = useLazyQuery(EXPORT_ISSUES, {
    onCompleted: (data) => {
      setLoading(false);
      const { generateIssueReport } = data;
      const { status, report } = generateIssueReport;
      if (status) {
        if (Boolean(report)) {
          var hiddenElement = document.createElement("a");
          hiddenElement.href =
            "data:application/vnd.ms-excel;base64," + encodeURI(report);
          hiddenElement.target = "_blank";
          hiddenElement.download = `Issues.xlsx`;
          hiddenElement.click();
        } else {
          toast.error("Something went wrong, please try again.", { delay: 10 });
        }
      } else {
        toast.error("Something went wrong, please try again.", { delay: 10 });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  useEffect(() => {
    setLoading(exporting || loadingTypes || loadingUsers);
  }, [exporting, loadingTypes, loadingUsers, setLoading]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        {Boolean(ROLE_PERMISSIONS) &&
        AllIssuesEditIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[AllIssuesEditIndex]?.ISSUES_EDIT ? (
          <NewOutlinedBtn
            disableElevation
            startIcon={
              <ErrorOutlineOutlined fontSize="small" sx={{ color: "#000" }} />
            }
            sx={{
              color: "#000",
              py: 1,
              px: 2,
              fontSize: "14px",
              borderRadius: "8px",
            }}
            onClick={() => setRaiseIssueModal(true)}
          >
            Raise Issue
          </NewOutlinedBtn>
        ) : (
          <Box sx={{ height: 42, width: 128 }} />
        )}
      </Grid>
      <Grid item>
        <Box
          sx={[styles.flex_Acenter, { display: { xs: "none", sm: "flex" } }]}
        >
          <Box
            sx={{
              backgroundColor: filterTable === "ALL" ? "#000000" : "#B6BCC330",
              py: 1,
              px: 1.5,
              borderRadius: "6px",
              cursor: "pointer",
              ...(filterTable !== "ALL" && {
                ":hover": {
                  backgroundColor: `${colors.primaryBlack}15`,
                },
              }),
            }}
            onClick={() => setFilterTable("ALL")}
          >
            <Typography
              textAlign="center"
              sx={{
                color: filterTable === "ALL" ? "#ffffff" : "#000000",
                fontSize: "13px",
              }}
            >
              All Issues
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: filterTable === "MY" ? "#000000" : "#B6BCC330",
              py: 1,
              px: 1.5,
              ml: 2,
              borderRadius: "6px",
              cursor: "pointer",
              ...(filterTable !== "MY" && {
                ":hover": {
                  backgroundColor: `${colors.primaryBlack}15`,
                },
              }),
            }}
            onClick={() => setFilterTable("MY")}
          >
            <Typography
              textAlign="center"
              sx={{
                color: filterTable === "MY" ? "#ffffff" : "#000000",
                fontSize: "13px",
              }}
            >
              My Issues
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor:
                filterTable === "RAISED_BY_ME" ? "#000000" : "#B6BCC330",
              py: 1,
              px: 1,
              ml: 2,
              borderRadius: "6px",
              cursor: "pointer",
              ...(filterTable !== "RAISED_BY_ME" && {
                ":hover": {
                  backgroundColor: `${colors.primaryBlack}15`,
                },
              }),
            }}
            onClick={() => setFilterTable("RAISED_BY_ME")}
          >
            <Typography
              textAlign="center"
              sx={{
                color: filterTable === "RAISED_BY_ME" ? "#ffffff" : "#000000",
                fontSize: "13px",
              }}
            >
              Raised by Me
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={styles.flex_Acenter}>
          <Box>
            <BlackActionContainedButton
              sx={{ px: 2, py: 1, borderRadius: "8px", fontSize: "14px" }}
              disableElevation
              disableRipple
              onClick={() =>
                exportIssues({
                  variables: {
                    project_id: projectId?.project_id,
                    filter: {
                      ...(filterTable === "MY" && {
                        assignedToId: sessionStorage.getItem(
                          StorageConstants.LOGGED_IN_USER_ID
                        ),
                      }),
                      ...(filterTable === "RAISED_BY_ME" && {
                        createdById: sessionStorage.getItem(
                          StorageConstants.LOGGED_IN_USER_ID
                        ),
                      }),
                      ...(Boolean(filterRelatedTo) && {
                        issueRelatedTo: filterRelatedTo,
                      }),
                      ...(Boolean(filterIssueType) && {
                        issueType: filterIssueType,
                      }),
                      ...(Boolean(filterPriority) && {
                        priority: filterPriority,
                      }),
                      ...(Boolean(filterStatus) && {
                        issueStatus: filterStatus,
                      }),
                      ...(Boolean(filterAssignedTo) && {
                        assignedToId: filterAssignedTo,
                      }),
                    },
                  },
                })
              }
              startIcon={<Share fontSize="small" color="secondary" />}
            >
              Export
            </BlackActionContainedButton>
          </Box>
          <Box sx={{ ml: 2 }}>
            <IconButton
              onClick={handleFilterMenuOpen}
              size="small"
              sx={{ ":hover": { backgroundColor: "#41B19931" } }}
            >
              <FilterAltOutlined
                fontSize="small"
                sx={{
                  color: openFilterMenu
                    ? "#41B199"
                    : `${colors.primaryBlack}50`,
                }}
              />
            </IconButton>
            <Menu
              anchorEl={filterMenuAnchor}
              open={openFilterMenu}
              onClose={handleFilterMenuClose}
              elevation={1}
              sx={{ "& .MuiMenu-paper": { width: 300 } }}
            >
              <Grid container spacing={2} sx={{ p: 1.5 }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.field_label}>
                    Issue Related To
                  </Typography>
                  <Autocomplete
                    value={
                      IssueRelatedToArr.find(
                        (x) => x.id === filterRelatedTo
                      ) || {
                        id: "",
                        name: "",
                      }
                    }
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setFilterRelatedTo(newValue.id);
                      } else {
                        setFilterRelatedTo("");
                      }
                    }}
                    size="small"
                    options={IssueRelatedToArr}
                    getOptionLabel={(option) => option.name}
                    sx={styles.text_input}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: { ...styles.text_input, width: "100px" },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.field_label}>Issue Type</Typography>
                  <Autocomplete
                    value={
                      issueTypeArr.find((x) => x.id === filterIssueType) || {
                        id: "",
                        name: "",
                      }
                    }
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setFilterIssueType(newValue.id);
                      } else {
                        setFilterIssueType("");
                      }
                    }}
                    size="small"
                    options={issueTypeArr}
                    getOptionLabel={(option) => option.name}
                    sx={styles.text_input}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: { ...styles.text_input, width: "100px" },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.field_label}>Priority</Typography>
                  <Autocomplete
                    value={
                      IssuePriorityArr.find((x) => x.id === filterPriority) || {
                        id: "",
                        name: "",
                      }
                    }
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setFilterPriority(newValue.id);
                      } else {
                        setFilterPriority("");
                      }
                    }}
                    size="small"
                    options={IssuePriorityArr}
                    getOptionLabel={(option) => option.name}
                    sx={styles.text_input}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: { ...styles.text_input, width: "100px" },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.field_label}>Issue Status</Typography>
                  <Autocomplete
                    value={
                      IssueStatusArr.find((x) => x.id === filterStatus) || {
                        id: "",
                        name: "",
                      }
                    }
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setFilterStatus(newValue.id);
                      } else {
                        setFilterStatus("");
                      }
                    }}
                    size="small"
                    options={IssueStatusArr}
                    getOptionLabel={(option) => option.name}
                    sx={styles.text_input}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: { ...styles.text_input, width: "100px" },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.field_label}>Assigned To</Typography>
                  <Autocomplete
                    value={
                      assignedToArr.find((x) => x.id === filterAssignedTo) || {
                        id: "",
                        fullName: "",
                      }
                    }
                    onChange={(_, newValue) => {
                      if (newValue) {
                        setFilterAssignedTo(newValue.id);
                      } else {
                        setFilterAssignedTo("");
                      }
                    }}
                    size="small"
                    options={assignedToArr}
                    getOptionLabel={(option) => option.fullName}
                    sx={styles.text_input}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: { ...styles.text_input, width: "100px" },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Menu>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Filters;
