import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
	Autocomplete,
	Avatar,
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import {
	CheckBox,
	LocationOnOutlined,
	ModeEditOutlineOutlined,
} from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { GET_ADDRESS_AUTOCOMPLETE } from "graphql/meta";
import { IUpdateContactFieldsNew } from "models/contacts";
import { IGeoCoding } from "models/common";
import { BlackActionContainedButton } from "common/Buttons";
import PhoneNumberInput from "common/PhoneNumberInput";
import { CountryCodeArr, GenderArr } from "utils/constants";
import DatePicker from "common/DatePickerInput";
import { useStore } from "utils/store";
import ProfilePhotoCircularCropper from "common/ImageCropper/ProfilePhotoCircularCropper";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface IPersonDetailsFormProps {
	setActiveSection: Dispatch<SetStateAction<number>>;
	initialValues: IUpdateContactFieldsNew;
	refetch: any;
	updateContactApi: any;
	profilePicture: any;
	setProfilePicture: any;
}

function PersonalDetailsForm(props: IPersonDetailsFormProps) {
	const {
		initialValues,
		refetch,
		updateContactApi,
		setActiveSection,
		profilePicture,
		setProfilePicture,
	} = props;

	const { setLoading } = useStore();

	const [openImageCropUploader, setOpenImageCropUploader] = useState(false);
	const [addressArr, setAddressArr] = useState<IGeoCoding[]>([]);
	const [addressSearchText, setAddressSearchText] = useState("");
	const [addressField, setAddressField] = useState<IGeoCoding | string | null>(
		null
	);

	const [getGeoCodeAddress] = useLazyQuery(GET_ADDRESS_AUTOCOMPLETE, {
		onCompleted: (data) => {
			const { autocompleteAddress } = data;
			setAddressArr(autocompleteAddress);
			if (
				autocompleteAddress.length > 0 &&
				Boolean(addressField) &&
				typeof addressField === "string"
			) {
				setAddressField(autocompleteAddress[0]);
			}
		},
		fetchPolicy: "network-only",
		nextFetchPolicy: "network-only",
	});

	useEffect(() => {
		setAddressField(initialValues.address);
	}, [initialValues.address]);

	useEffect(() => {
		if (addressSearchText) {
			getGeoCodeAddress({ variables: { filter: addressSearchText } });
		}
	}, [addressSearchText, getGeoCodeAddress]);

	useEffect(() => {
		if (Boolean(addressField) && typeof addressField === "string") {
			setAddressSearchText(addressField);
		}
	}, [addressField]);

	const handleSubmitIndividualForm = (values: IUpdateContactFieldsNew) => {
		const {
			id,
			firstName,
			lastName,
			emailId,
			mobile,
			unit,
			address,
			nationality,
			shareConsent,
			contactDepartmentId,
			project_role,
			dob,
			gender,
			next_of_kin_contact_number,
			next_of_kin_name,
			next_of_kin_relationship,
			food_allergies,
			medical_allergies,
			instagram,
			facebook,
			twitter,
			imdb,
		} = values;

		const profileJSON = {
			project_role,
			dob,
			gender,
			food_allergies,
			medical_allergies,
			next_of_kin_contact_number: Boolean(
				next_of_kin_contact_number?.nationalNumber
			)
				? `${
						CountryCodeArr.find(
							(x) => x.id === next_of_kin_contact_number?.countryCode
						)?.name
				  } ${next_of_kin_contact_number?.nationalNumber}`
				: "",
			next_of_kin_name,
			next_of_kin_relationship,
			instagram,
			facebook,
			twitter,
			imdb,
		};

		updateContactApi({
			variables: {
				payload: {
					id,
					firstName,
					lastName,
					emailId,
					mobile: `${
						CountryCodeArr.find((x) => x.id === mobile?.countryCode)?.name
					} ${mobile?.nationalNumber}`,
					shareConsent,
					unit,
					nationality: nationality || "",
					...(Boolean(contactDepartmentId) &&
						contactDepartmentId !== "TBA" && { contactDepartmentId }),
					profile: JSON.stringify(profileJSON),
					...(typeof address !== "string" &&
						address !== null && {
							address: address?.freeformAddress,
							contactCountry: address?.country || "",
							contactState: address?.countrySubdivision || "",
							contactCity: address?.municipality || "",
							suburb: address?.municipalitySubdivision || "",
							street: address?.streetName || "",
							pincode: address?.postalCode || "",
						}),
					...(profilePicture === null && { removeProfilePhoto: true }),
				},
				...(Boolean(profilePicture) &&
					typeof profilePicture !== "string" && {
						profile_picture: profilePicture,
					}),
			},
			onCompleted: (data: any) => {
				setLoading(false);
				const { updateContactBasicDetails } = data;
				const { status, message } = updateContactBasicDetails;
				if (status) {
					refetch();
					toast.success(message, { delay: 10 });
					setActiveSection(1);
				} else {
					toast.error(message, { delay: 10 });
				}
			},
		});
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object().shape({
				firstName: Yup.string().required("Please enter your first name"),
				lastName: Yup.string().required("Please enter your last name"),
				emailId: Yup.string()
					.required("Please enter the email id")
					.email("Please enter a valid email"),
				mobile: Yup.object().shape({
					nationalNumber: Yup.string().required(
						"Please enter the contact number"
					),
				}),
				contactDepartmentId: Yup.string().required(
					"Please select a department"
				),
			})}
			onSubmit={handleSubmitIndividualForm}
			enableReinitialize
		>
			{({
				values,
				handleChange,
				setFieldValue,
				handleBlur,
				errors,
				touched,
				handleSubmit,
				isValid,
			}) => (
				<Box sx={{ pt: 2, px: 4, pb: 1.5 }}>
					<Grid container spacing={3}>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
							sx={{ display: { xs: "flex", sm: "none" } }}
							justifyContent="center"
							alignItems="center"
						>
							<Box
								sx={{
									display: { xs: "flex", md: "none" },
									justifyContent: "center",
									position: "relative",
								}}
							>
								{profilePicture ? (
									<Avatar
										sx={{ height: 70, width: 70, cursor: "pointer" }}
										src={
											typeof profilePicture === "string"
												? profilePicture
												: URL.createObjectURL(profilePicture)
										}
									/>
								) : (
									<Avatar
										sx={{
											height: 70,
											width: 70,
											cursor: "pointer",
										}}
									/>
								)}
								<Box sx={{ position: "absolute", bottom: -10 }}>
									<IconButton
										size="small"
										sx={{
											backgroundColor: "#fff",
											border: "1px #e6e6e6 solid",
											height: 25,
											width: 25,
										}}
										onClick={() => setOpenImageCropUploader(true)}
									>
										<ModeEditOutlineOutlined sx={{ fontSize: 15 }} />
									</IconButton>
								</Box>
							</Box>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<Typography sx={styles.field_label}>
								First Name <span style={styles.error_text}>*</span>
							</Typography>
							<TextField
								id="firstName"
								value={values.firstName}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
							{touched.firstName && (
								<Typography sx={styles.error_text}>
									{errors.firstName}
								</Typography>
							)}
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<Typography sx={styles.field_label}>
								Last Name <span style={styles.error_text}>*</span>
							</Typography>
							<TextField
								id="lastName"
								value={values.lastName}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
							{touched.lastName && (
								<Typography sx={styles.error_text}>
									{errors.lastName}
								</Typography>
							)}
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Email <span style={styles.error_text}>*</span>
							</Typography>
							<TextField
								id="emailId"
								value={values.emailId}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
							{touched.emailId && (
								<Typography sx={styles.error_text}>
									{errors.emailId}
								</Typography>
							)}
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Mobile <span style={styles.error_text}>*</span>
							</Typography>
							<PhoneNumberInput
								value={
									values.mobile.nationalNumber !== null
										? values.mobile.nationalNumber
										: Boolean(values.mobile.rawInput)
										? values.mobile.rawInput.replaceAll(" ", "")
										: ""
								}
								onChange={(e) => {
									setFieldValue(
										"mobile.nationalNumber",
										e.target.value
									);
								}}
								onBlur={handleBlur("mobile.nationalNumber")}
								countryCode={values.mobile.countryCode}
								setCountryCode={(code: string) =>
									setFieldValue("mobile.countryCode", code)
								}
							/>
							{/* {touched.mobile && (
                <Typography sx={styles.error_text}>{errors.mobile}</Typography>
              )} */}
						</Grid>
						<Grid item lg={3} md={4} sm={5} xs={12}>
							<Typography sx={styles.field_label}>
								Unit/Suite Number
							</Typography>
							<TextField
								id="unit"
								value={values.unit}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
						</Grid>
						<Grid item lg={9} md={8} sm={7} xs={12}>
							<Typography sx={styles.field_label}>Address</Typography>
							<Autocomplete
								id="address"
								getOptionLabel={(option) => option.freeformAddress}
								value={
									Boolean(values.address)
										? typeof values.address !== "string"
											? values.address!
											: {
													streetNumber: "",
													streetName: "",
													municipalitySubdivision: "",
													municipality: "",
													countrySubdivision: "",
													postalCode: "",
													country: "",
													freeformAddress: "",
											  }
										: undefined
								}
								onChange={(event, newValue) => {
									setFieldValue("address", newValue);
								}}
								inputValue={addressSearchText}
								onInputChange={(event: any, newInputValue) => {
									if (Boolean(event?.target)) {
										setAddressSearchText(newInputValue);
									}
								}}
								options={addressArr}
								popupIcon={
									<LocationOnOutlined
										fontSize="small"
										sx={{ color: "#00000050" }}
									/>
								}
								sx={[
									styles.input_field,
									{
										"& .MuiAutocomplete-popupIndicator": {
											transform: "none",
										},
									},
								]}
								size="small"
								disableClearable
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										size="small"
										fullWidth
										inputProps={{
											...params.inputProps,
											style: {
												...styles.input_field,
												backgroundColor: "transparent",
											},
										}}
									/>
								)}
								noOptionsText={
									<Typography
										sx={{ fontSize: "13px", color: "#0f0f0f80" }}
									>
										{addressSearchText ? "No match found" : ""}
									</Typography>
								}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								Date of Birth
							</Typography>
							<DatePicker
								textValue={
									Boolean(values?.dob)
										? moment(values.dob).format("DD MMMM YYYY")
										: ""
								}
								value={
									Boolean(values.dob) ? new Date(values.dob) : null
								}
								onChange={(newValue: any) => {
									setFieldValue(
										"dob",
										moment(newValue).format("YYYY-MM-DD")
									);
								}}
								onOpen={undefined}
								disabledFuture
							/>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<Typography sx={styles.field_label}>
								Nationality
							</Typography>
							<TextField
								id="nationality"
								value={values.nationality}
								onChange={handleChange}
								onBlur={handleBlur}
								size="small"
								fullWidth
								inputProps={{ style: styles.input_field }}
							/>
						</Grid>
						<Grid item lg={6} md={6} sm={6} xs={12}>
							<Typography sx={styles.field_label}>Gender</Typography>
							<Autocomplete
								id="gender"
								value={
									GenderArr.find((x) => x.id === values.gender) || {
										id: "",
										name: "",
									}
								}
								onChange={(_, newValue) => {
									setFieldValue("gender", newValue.id);
								}}
								sx={styles.input_field}
								size="small"
								disableClearable
								fullWidth
								options={GenderArr}
								getOptionLabel={(option) => option.name}
								renderInput={(params) => (
									<TextField
										{...params}
										size="small"
										fullWidth
										inputProps={{
											...params.inputProps,
											style: {
												...styles.input_field,
												backgroundColor: "transparent",
											},
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Typography sx={styles.field_label}>
								The Everyone Project
							</Typography>
							<Typography
								sx={[
									styles.field_label,
									{ fontWeight: 400, fontSize: "12px" },
								]}
							>
								The Production Company may need to share your name,
								role, and contact details with The Everyone Project.
							</Typography>
							<FormControlLabel
								control={
									<Checkbox
										checked={values.shareConsent}
										onChange={(e) =>
											setFieldValue("shareConsent", e.target.checked)
										}
										checkedIcon={
											<CheckBox
												fontSize="small"
												sx={{ color: "#6f6f6f" }}
											/>
										}
										size="small"
									/>
								}
								label={
									<Typography
										sx={[
											styles.field_label,
											{
												fontWeight: 400,
												fontSize: "12px",
											},
										]}
									>
										Yes, I consent to the Production Company sharing
										my details (unchecked box implies non-consent to
										share my details).
									</Typography>
								}
							/>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<Box sx={styles.flex_JCfe}>
								<BlackActionContainedButton
									variant="contained"
									size="small"
									sx={{
										borderRadius: "20px",
										fontSize: "13px",
										fontWeight: 600,
										width: "120px",
									}}
									disableElevation
									disableRipple
									disabled={!isValid}
									onClick={() => handleSubmit()}
								>
									Save and Next
								</BlackActionContainedButton>
							</Box>
						</Grid>
						{openImageCropUploader && (
							<ProfilePhotoCircularCropper
								open={openImageCropUploader}
								setOpen={setOpenImageCropUploader}
								width={70}
								imgSrc={profilePicture}
								setImgSrc={(file: any) => setProfilePicture(file)}
							/>
						)}
					</Grid>
				</Box>
			)}
		</Formik>
	);
}

export default PersonalDetailsForm;
