import React from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { CheckBoxOutlineBlankOutlined } from "@mui/icons-material";

interface IProps extends CheckboxProps {
  label: string;
  labelStyle?: any;
}

const CheckboxWithLabel = (props: IProps) => {
  const { label, labelStyle = {}, icon: customIcon, ...rest } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...rest}
          icon={
            Boolean(customIcon) ? (
              customIcon
            ) : (
              <CheckBoxOutlineBlankOutlined color="primary" />
            )
          }
        />
      }
      label={<Typography sx={labelStyle}>{label}</Typography>}
    />
  );
};

export default CheckboxWithLabel;
