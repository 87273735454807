import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

import { images } from "assets/images";
import { AdminRoleManagementLabels, GlobalLabels } from "common/AppConstants";
import { AdminUsersStyles as styles } from "./styles";

interface IFiltersProps {
  setAddRoleModal: Dispatch<SetStateAction<boolean>>;
  setSearchText: Dispatch<SetStateAction<string>>;
}

function Filters(props: IFiltersProps) {
  const { setAddRoleModal, setSearchText } = props;

  const [searchTextFieldValue, setSearchTextFieldValue] = useState("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextFieldValue(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchText(e.target.value);
    }, 300);
  };

  return (
    <Box sx={styles.filters_container}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Button
            variant="contained"
            startIcon={<img src={images.ADD_PROJECT_USER} alt="add user" />}
            onClick={() => setAddRoleModal(true)}
          >
            {`${GlobalLabels.ADD} ${AdminRoleManagementLabels.ROLE}`}
          </Button>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} sx={styles.flex_JCfe}>
          <TextField
            value={searchTextFieldValue}
            onChange={handleSearchChange}
            size="small"
            placeholder="Search"
            inputProps={{ style: styles.input_field }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" color="inherit" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSearchTextFieldValue("");
                      setSearchText("");
                    }}
                  >
                    <Clear fontSize="small" color="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Filters;
