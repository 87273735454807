import { create } from "zustand";

export default interface IFillableFields {
  id: string;
  display_name: string;
  field_type: string;
  field_value: string;
}

interface IContractStore {
  fillableFields: IFillableFields[];
  setFillableFields: (arr: IFillableFields[]) => void;
}

export const useThirdPartyContract = create<IContractStore>((set) => ({
  fillableFields: [],
  setFillableFields: (fillableFields: IFillableFields[]) =>
    set({ fillableFields }),
}));
