import React, { Dispatch, SetStateAction, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";

import { AccessStyles as styles } from "./styles";

interface IProps {
  setSearchText: Dispatch<SetStateAction<string>>;
  searchText: string;
}

const Filters = (props: IProps) => {
  const { searchText, setSearchText } = props;

  const [searchTextFieldValue, setSearchTextFieldValue] = useState(searchText);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTextFieldValue(e.target.value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchText(e.target.value);
    }, 300);
  };

  return (
    <Grid container spacing={2} sx={{ p: 1.5 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={styles.filter_field_label}>Name</Typography>
        <TextField
          size="small"
          fullWidth
          value={searchTextFieldValue}
          onChange={handleSearchChange}
          placeholder="Name"
          inputProps={{ style: { fontSize: "13px" } }}
        />
      </Grid>
    </Grid>
  );
};

export default Filters;
