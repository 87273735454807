import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import { IUpdateContactFieldsNew } from "models/contacts";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";
import { useStore } from "utils/store";

interface IRegistrationFormProps {
  initialValues: IUpdateContactFieldsNew;
  updateContactApi: any;
  refetch: any;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

function RegistrationForm(props: IRegistrationFormProps) {
  const { initialValues, updateContactApi, refetch, setActiveSection } = props;

  const { setLoading } = useStore();

  const handleSubmitAgencyForm = (values: IUpdateContactFieldsNew) => {
    const { id, agencyDetails } = values;
    const { companyName, abn, acn } = agencyDetails;

    updateContactApi({
      variables: {
        payload: {
          id,
          agencyDetails: {
            companyName,
            abn,
            acn,
          },
        },
      },
      onCompleted: (data: any) => {
        setLoading(false);
        const { updateContactBasicDetails } = data;
        const { status, message } = updateContactBasicDetails;
        if (status) {
          refetch();
          toast.success(message, { delay: 10 });
          setActiveSection(2);
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        agencyDetails: Yup.object().shape({
          abn: Yup.string()
            .matches(/^[0-9]{11}$/, "ABN must be 11 digits")
            .nullable(),
          acn: Yup.string()
            .matches(/^[0-9]{9}$/, "ACN must be 9 digits")
            .nullable(),
        }),
      })}
      onSubmit={handleSubmitAgencyForm}
      enableReinitialize
    >
      {({
        values,
        handleBlur,
        handleChange,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        isValid,
      }) => (
        <Box
          sx={{
            pt: { xs: 2, sm: 14 },
            px: 4,
            pb: 1.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <Typography
                sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}
              >
                Registration Details
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Australian Company Number (ACN)
              </Typography>
              <TextField
                id="agencyDetails.acn"
                value={values.agencyDetails.acn}
                onChange={(e) => {
                  if (!isNaN(e.target.value as unknown as number)) {
                    if (e.target.value.length <= 9) {
                      setFieldValue("agencyDetails.acn", e.target.value);
                    }
                  }
                }}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.agencyDetails?.acn && errors?.agencyDetails?.acn && (
                <Typography sx={styles.error_text}>
                  {errors?.agencyDetails?.acn}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography sx={styles.field_label}>
                Australian Business Number (ABN)
              </Typography>
              <TextField
                id="agencyDetails.abn"
                value={values.agencyDetails.abn}
                onChange={(e) => {
                  if (!isNaN(e.target.value as unknown as number)) {
                    if (e.target.value.length <= 11) {
                      setFieldValue("agencyDetails.abn", e.target.value);
                    }
                  }
                }}
                onBlur={handleBlur}
                size="small"
                fullWidth
                inputProps={{ style: styles.input_field }}
              />
              {touched?.agencyDetails?.abn && errors?.agencyDetails?.abn && (
                <Typography sx={styles.error_text}>
                  {errors?.agencyDetails?.abn}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
                <OutlinedBtn
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                    color: "#000",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(0)}
                >
                  Back
                </OutlinedBtn>
                <BlackActionContainedButton
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    ml: 1,
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                  }}
                  disableElevation
                  disableRipple
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                >
                  Save and Next
                </BlackActionContainedButton>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

export default RegistrationForm;
