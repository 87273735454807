import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  CheckBox,
  CheckBoxOutlineBlankOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { useQuery } from "@apollo/client";

import CheckboxWithLabel from "common/CheckboxWithLabel";
import { IArr } from "models/common";
import { GET_TEMPLATE_TYPES } from "graphql/templates";
import { colors } from "theme/colors";

import {
  ClientLevelPermissions,
  IPermissionArr,
  IRoleFields,
  ProjectLevelPermissions,
  checkIfAnyTempTypeUnChecked,
  getCheckedValue,
} from "./utils";
import { AdminUsersStyles as styles } from "./styles";

interface IAccordionProps {
  module: IPermissionArr;
  values: IRoleFields;
}

function Accordion(props: IAccordionProps) {
  const { module, values } = props;

  const [expanded, setExpanded] = useState(
    module.name === "Issues" ? true : false
  );
  const [issuesRadioValue, setIssuesRadioValue] = useState("all_issues");
  const [templateTypeArr, setTemplateTypeArr] = useState<IArr[]>([]);

  const {} = useQuery(GET_TEMPLATE_TYPES, {
    variables: {},
    onCompleted: (data) => {
      const { templateTypes } = data;
      const { status, templateTypes: rawTemplateTypes } = templateTypes;
      if (status) {
        setTemplateTypeArr(rawTemplateTypes);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      Boolean(values.my_issues_restrictions) &&
      (values?.my_issues_restrictions?.all_issues?.view ||
        values?.my_issues_restrictions?.all_issues?.edit)
    ) {
      setIssuesRadioValue("all_issues");
    } else if (
      Boolean(values.my_issues_restrictions) &&
      (values?.my_issues_restrictions?.my_issues?.view ||
        values?.my_issues_restrictions?.my_issues?.edit)
    ) {
      setIssuesRadioValue("my_issues");
    }
  }, [values.my_issues_restrictions]);

  return (
    <Fragment>
      <Box
        sx={{
          cursor: "pointer",
          width: "100%",
          display: "flex",
          alignItems: "center",
          pl: 6,
          pt: 2,
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Box sx={{ display: "flex", alignItems: "center", ml: -6 }}>
            <IconButton>
              {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Typography sx={[styles.actions_text, { color: colors.primary }]}>
              {module.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          {module.name === "Folders (ALL)" ? (
            <Box sx={[styles.flex_Acenter, { ml: -1 }]}>
              <CheckboxWithLabel
                disabled
                label="Read"
                sx={{ mr: 1 }}
                labelStyle={styles.actions_text}
                onClick={(e) => e.stopPropagation()}
                icon={
                  !Boolean(module.view) ? (
                    <CheckBoxOutlineBlankOutlined />
                  ) : (
                    <CheckBoxOutlineBlankOutlined color="primary" />
                  )
                }
                checkedIcon={<CheckBox color="primary" />}
                checked={
                  Boolean(values.folder_restrictions)
                    ? !checkIfAnyTempTypeUnChecked(
                        values.folder_restrictions,
                        "view"
                      )
                    : false
                }
              />
              <CheckboxWithLabel
                disabled
                label="Read & Write"
                labelStyle={styles.actions_text}
                onClick={(e) => e.stopPropagation()}
                icon={
                  !Boolean(module.edit) ? (
                    <CheckBoxOutlineBlankOutlined />
                  ) : (
                    <CheckBoxOutlineBlankOutlined color="primary" />
                  )
                }
                checkedIcon={<CheckBox color="primary" />}
                checked={
                  Boolean(values.folder_restrictions)
                    ? !checkIfAnyTempTypeUnChecked(
                        values.folder_restrictions,
                        "edit"
                      )
                    : false
                }
              />
            </Box>
          ) : (
            <Box sx={{ ml: -1 }}>
              <RadioGroup
                row
                onClick={(e) => {
                  e.stopPropagation();
                }}
                value={issuesRadioValue}
                onChange={(e) => {
                  setIssuesRadioValue(e.target.value);
                  setExpanded(true);
                }}
              >
                <FormControlLabel
                  disabled
                  value="all_issues"
                  control={
                    <Radio
                      icon={<RadioButtonUnchecked color="primary" />}
                      checkedIcon={<RadioButtonChecked color="primary" />}
                    />
                  }
                  label={
                    <Typography sx={styles.actions_text}>All Issues</Typography>
                  }
                />
                <FormControlLabel
                  disabled
                  value="my_issues"
                  control={
                    <Radio
                      icon={<RadioButtonUnchecked color="primary" />}
                      checkedIcon={<RadioButtonChecked color="primary" />}
                    />
                  }
                  label={
                    <Typography sx={styles.actions_text}>My Issues</Typography>
                  }
                />
              </RadioGroup>
            </Box>
          )}
        </Grid>
      </Box>
      {expanded &&
        module.name === "Folders (ALL)" &&
        templateTypeArr.map((tempType) => (
          <Box
            key={tempType.id}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              pl: 6,
              pt: 2,
            }}
          >
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={[styles.actions_text, { fontSize: "13px" }]}>
                  {tempType.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box sx={[styles.flex_Acenter, { ml: -1 }]}>
                <CheckboxWithLabel
                  disabled
                  label="Read"
                  sx={{ mr: 1 }}
                  labelStyle={{ ...styles.actions_text }}
                  icon={
                    !Boolean(module.view) ? (
                      <CheckBoxOutlineBlankOutlined />
                    ) : (
                      <CheckBoxOutlineBlankOutlined color="primary" />
                    )
                  }
                  checkedIcon={<CheckBox color="primary" />}
                  checked={
                    Boolean(values.folder_restrictions)
                      ? values.folder_restrictions![`${tempType.id}`]?.view ||
                        false
                      : false
                  }
                />
                <CheckboxWithLabel
                  disabled
                  label="Read & Write"
                  labelStyle={{ ...styles.actions_text }}
                  icon={
                    !Boolean(module.edit) ? (
                      <CheckBoxOutlineBlankOutlined />
                    ) : (
                      <CheckBoxOutlineBlankOutlined color="primary" />
                    )
                  }
                  checkedIcon={<CheckBox color="primary" />}
                  checked={
                    Boolean(values.folder_restrictions)
                      ? values.folder_restrictions![`${tempType.id}`]?.edit ||
                        false
                      : false
                  }
                />
              </Box>
            </Grid>
          </Box>
        ))}
      {expanded && module.name === "Issues" && (
        <Fragment>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              pl: 6,
              pt: 2,
            }}
          >
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={[styles.actions_text, { fontSize: "13px" }]}>
                  {issuesRadioValue === "my_issues"
                    ? "My Issues"
                    : "All Issues"}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              {issuesRadioValue === "all_issues" ? (
                <Box sx={[styles.flex_Acenter, { ml: -1 }]}>
                  <CheckboxWithLabel
                    disabled
                    label="Read"
                    sx={{ mr: 1 }}
                    labelStyle={styles.actions_text}
                    icon={
                      !Boolean(module.view) ? (
                        <CheckBoxOutlineBlankOutlined />
                      ) : (
                        <CheckBoxOutlineBlankOutlined color="primary" />
                      )
                    }
                    checkedIcon={<CheckBox color="primary" />}
                    checked={values.my_issues_restrictions?.all_issues?.view}
                  />
                  <CheckboxWithLabel
                    disabled
                    label="Read & Write"
                    labelStyle={styles.actions_text}
                    icon={
                      !Boolean(module.edit) ? (
                        <CheckBoxOutlineBlankOutlined
                          sx={{ color: "#d6d6d6" }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankOutlined color="primary" />
                      )
                    }
                    checkedIcon={<CheckBox color="primary" />}
                    checked={values.my_issues_restrictions?.all_issues?.edit}
                  />
                </Box>
              ) : (
                <Box sx={[styles.flex_Acenter, { ml: -1 }]}>
                  <CheckboxWithLabel
                    disabled
                    label="Read"
                    sx={{ mr: 1 }}
                    labelStyle={styles.actions_text}
                    icon={
                      !Boolean(module.view) ? (
                        <CheckBoxOutlineBlankOutlined />
                      ) : (
                        <CheckBoxOutlineBlankOutlined color="primary" />
                      )
                    }
                    checkedIcon={<CheckBox color="primary" />}
                    checked={values.my_issues_restrictions?.my_issues?.view}
                  />
                  <CheckboxWithLabel
                    disabled
                    label="Read & Write"
                    labelStyle={styles.actions_text}
                    icon={
                      !Boolean(module.edit) ? (
                        <CheckBoxOutlineBlankOutlined
                          sx={{ color: "#d6d6d6" }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankOutlined color="primary" />
                      )
                    }
                    checkedIcon={<CheckBox color="primary" />}
                    checked={values.my_issues_restrictions?.my_issues?.edit}
                  />
                </Box>
              )}
            </Grid>
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
}

interface IRolesListProps {
  values: IRoleFields;
}

function RolesList(props: IRolesListProps) {
  const { values } = props;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={styles.header_text}>
          Client Level Permissions
        </Typography>
      </Grid>
      {ClientLevelPermissions.map((module) => (
        <Fragment key={module.name}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box sx={{ pl: 3 }}>
              <Typography sx={[styles.actions_text, { color: colors.primary }]}>
                {module.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box sx={styles.flex_Acenter}>
              <CheckboxWithLabel
                label="Read"
                sx={{ mr: 1 }}
                labelStyle={styles.actions_text}
                disabled
                icon={
                  !Boolean(module.view) ? (
                    <CheckBoxOutlineBlankOutlined />
                  ) : (
                    <CheckBoxOutlineBlankOutlined color="primary" />
                  )
                }
                checkedIcon={<CheckBox color="primary" />}
                checked={getCheckedValue(values.permission, module.view)}
              />
              <CheckboxWithLabel
                label="Read & Write"
                labelStyle={styles.actions_text}
                disabled
                icon={
                  !Boolean(module.edit) ? (
                    <CheckBoxOutlineBlankOutlined sx={{ color: "#d6d6d6" }} />
                  ) : (
                    <CheckBoxOutlineBlankOutlined color="primary" />
                  )
                }
                checkedIcon={<CheckBox color="primary" />}
                checked={getCheckedValue(values.permission, module.edit)}
              />
            </Box>
          </Grid>
        </Fragment>
      ))}
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
        <Typography sx={styles.header_text}>
          Project Level Permissions
        </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box sx={{ pl: 3 }}>
          <Typography sx={[styles.actions_text, { color: colors.primary }]}>
            Project Setup
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box sx={styles.flex_Acenter}>
          <CheckboxWithLabel
            label="Read"
            sx={{ mr: 1 }}
            labelStyle={styles.actions_text}
            disabled
            icon={<CheckBoxOutlineBlankOutlined color="primary" />}
            checkedIcon={<CheckBox color="primary" />}
            checked={getCheckedValue(values.permission, "PROJECT_VIEW")}
          />
          <CheckboxWithLabel
            label="Read & Write"
            disabled
            labelStyle={styles.actions_text}
            icon={<CheckBoxOutlineBlankOutlined color="primary" />}
            checkedIcon={<CheckBox color="primary" />}
            checked={getCheckedValue(values.permission, "PROJECT_EDIT")}
          />
        </Box>
      </Grid>
      {ProjectLevelPermissions.map((module) =>
        module.name !== "Folders (ALL)" && module.name !== "Issues" ? (
          <Fragment key={module.name}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box sx={{ pl: 3 }}>
                <Typography
                  sx={[styles.actions_text, { color: colors.primary }]}
                >
                  {module.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box sx={styles.flex_Acenter}>
                <CheckboxWithLabel
                  label="Read"
                  sx={{ mr: 1 }}
                  labelStyle={styles.actions_text}
                  disabled
                  icon={
                    !Boolean(module.view) ? (
                      <CheckBoxOutlineBlankOutlined />
                    ) : (
                      <CheckBoxOutlineBlankOutlined color="primary" />
                    )
                  }
                  checkedIcon={<CheckBox color="primary" />}
                  checked={getCheckedValue(values.permission, module.view)}
                />
                <CheckboxWithLabel
                  label="Read & Write"
                  labelStyle={styles.actions_text}
                  disabled
                  icon={
                    !Boolean(module.edit) ? (
                      <CheckBoxOutlineBlankOutlined />
                    ) : (
                      <CheckBoxOutlineBlankOutlined color="primary" />
                    )
                  }
                  checkedIcon={<CheckBox color="primary" />}
                  checked={getCheckedValue(values.permission, module.edit)}
                />
              </Box>
            </Grid>
          </Fragment>
        ) : (
          <Accordion key={module.name} module={module} values={values} />
        )
      )}
    </Grid>
  );
}

export default RolesList;
