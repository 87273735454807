import { gql } from "@apollo/client";

export const GET_GLOBAL_SEARCH_RESULTS = gql`
  query globalSearch($query: String) {
    globalSearch(query: $query) {
      status
      message
      projects {
        id
        name
      }
      contacts {
        id
        fullName
        profile_picture
        assignedProject {
          id
          name
        }
      }
      documents {
        id
        name
        projectDetails {
          id
          name
        }
        isFolder
        isUpload
        documentType
        path
      }
      registers {
        id
        name
        project {
          id
          name
        }
        path
      }
      clearances {
        id
        name
        project {
          id
          name
        }
        path
      }
    }
  }
`;
