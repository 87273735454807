import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from "@mui/material";
import {
  AttachFile,
  Close,
  FiberManualRecord,
  KeyboardArrowDown,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IModalProps } from "models/common";
import { UPSERT_INDEX_ITEM_DOCUMENT } from "graphql/indexes";
import {
  GET_CONTRACT_FILE,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_STATUS,
} from "graphql/contracts";
import { useStore } from "utils/store";
import { ContractStatusMapping } from "utils/constants";
import { BlackActionContainedButton } from "common/Buttons";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

const IndexItemStatusArr = [
  { id: "APPROVAL_PENDING", name: "Pending", color: "#D72C51", bg: "#F8E8EC" },
  { id: "COMPLETED", name: "Completed", color: "#18B370", bg: "#E0F9EE" },
];

interface IUploadContractFields {
  file: any;
}

interface IUploadModalProps extends IModalProps {
  selectedIndexItem: string;
  setSelectedIndexItem: Dispatch<SetStateAction<string>>;
  selectedIndexItemName: string;
  setSelectedIndexItemName: Dispatch<SetStateAction<string>>;
  refetchTableData: any;
  reUploadDocId: string;
  setReUploadDocId: Dispatch<SetStateAction<string>>;
  nestedIdArr: string[];
}

const UploadDocModal = (props: IUploadModalProps) => {
  const {
    open,
    setOpen,
    selectedIndexItem,
    setSelectedIndexItem,
    selectedIndexItemName,
    setSelectedIndexItemName,
    refetchTableData,
    reUploadDocId,
    setReUploadDocId,
    nestedIdArr,
  } = props;

  const { setLoading } = useStore();
  const params = useParams();

  const [file, setFile] = useState<any>(null);
  const [fileError, setFileError] = useState("");
  const [fileName, setFileName] = useState("");
  const [documentStatus, setDocumentStatus] = useState("APPROVAL_PENDING");
  const [openCnctStatusMenu, setOpenCnctStatusMenu] = useState(false);
  const [cnctStatusAnchor, setCnctStatusAnchor] = useState<HTMLElement | null>(
    null
  );

  const handleCnctStatusClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setOpenCnctStatusMenu(true);
    setCnctStatusAnchor(e.currentTarget);
  };

  const handleCnctStatusClose = () => {
    setOpenCnctStatusMenu(false);
    setCnctStatusAnchor(null);
  };

  const handleClose = () => {
    setReUploadDocId("");
    setSelectedIndexItem("");
    setSelectedIndexItemName("");
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const { loading: gettingDetails, refetch } = useQuery(GET_CONTRACT_FILE, {
    variables: { payload: { documentId: reUploadDocId } },
    skip: !Boolean(reUploadDocId),
    onCompleted: (data) => {
      // setLoading(false);
      const { getDocumentFile } = data;
      const { status, document, file } = getDocumentFile;
      if (status) {
        setFileName(document?.fileName || "");
        setDocumentStatus(document?.documentStatus || "APPROVAL_PENDING");
        setFile(file);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireUploadContractApi, { loading }] = useMutation(
    UPSERT_INDEX_ITEM_DOCUMENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { linkRegisterItemDocument } = data;
        const { status, message } = linkRegisterItemDocument;
        if (status) {
          toast.success(message, { delay: 10 });
          refetchTableData();
          handleClose();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const [fireUpdateDocFileApi, { loading: updating }] = useMutation(
    UPDATE_DOCUMENT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateDocument } = data;
        const { status, message } = updateDocument;
        if (status) {
          toast.success(message, { delay: 10 });
          refetchTableData();
          handleClose();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const [fireUpdateCnctStatusApi, { loading: updatingStatus }] = useMutation(
    UPDATE_DOCUMENT_STATUS,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { updateDocumentStatus } = data;
        const { status, message } = updateDocumentStatus;
        if (status) {
          toast.success(message, { delay: 10 });
          refetch();
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleUploadContract = (values: IUploadContractFields) => {
    const { file } = values;
    if (Boolean(file)) {
      setFileError("");
      if (Boolean(reUploadDocId)) {
        if (typeof file !== "string") {
          fireUpdateDocFileApi({
            variables: {
              payload: {
                fetchDocumentId: reUploadDocId,
                name: selectedIndexItemName,
                isFolder: false,
                isUpload: true,
              },
              file: file,
            },
          });
        } else {
          handleClose();
        }
      } else {
        fireUploadContractApi({
          variables: {
            payload: {
              projectId: params?.project_id,
              registerId: nestedIdArr[nestedIdArr.length - 1],
              registerItemId: selectedIndexItem,
              isUpload: true,
              documentType: "DOCUMENT",
            },
            file: file,
          },
        });
      }
    } else {
      setFileError("Please upload a file");
    }
  };

  useEffect(() => {
    setLoading(gettingDetails || loading || updating || updatingStatus);
  }, [gettingDetails, loading, setLoading, updating, updatingStatus]);

  return (
    <Formik
      initialValues={{ contactId: "", inhouseCompanyId: "", file: file }}
      onSubmit={handleUploadContract}
      validationSchema={Yup.object().shape({})}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullWidth
          maxWidth={"sm"}
          PaperProps={{ sx: { borderRadius: "24px" } }}
        >
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ pt: 6, px: 6, pb: 3, position: "relative" }}>
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <IconButton size="small" onClick={handleClose}>
                    <Close sx={{ color: "#00000055" }} fontSize="small" />
                  </IconButton>
                </Box>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: -2 }}>
                    <Typography sx={styles.field_label}>
                      Attachment <span style={styles.error_text}>*</span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={Boolean(reUploadDocId) ? 6 : 8}
                    md={Boolean(reUploadDocId) ? 6 : 8}
                    sm={Boolean(reUploadDocId) ? 6 : 8}
                    xs={12}
                  >
                    <TextField
                      value={
                        Boolean(values.file)
                          ? typeof values.file === "string"
                            ? fileName
                            : (values.file as any)["name"]
                          : "Select a file"
                      }
                      disabled
                      size="small"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#3b3b3b80",
                        },
                      }}
                      fullWidth
                      inputProps={{
                        style: {
                          backgroundColor: "#e6e6e640",
                          borderRadius: "6px",
                          fontSize: "13px",
                          color: "#3b3b3b80",
                        },
                      }}
                    />
                    {Boolean(fileError) && (
                      <Typography sx={styles.error_text}>
                        {fileError}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    lg={Boolean(reUploadDocId) ? 6 : 4}
                    md={Boolean(reUploadDocId) ? 6 : 4}
                    sm={Boolean(reUploadDocId) ? 6 : 4}
                    xs={12}
                  >
                    <Box sx={[styles.flex_JCsb, { alignItems: "center" }]}>
                      <Box sx={styles.flex_Acenter}>
                        <Box>
                          <Upload
                            beforeUpload={(file: RcFile, __) => {
                              if (file.size <= 25000000) {
                                setFileError("");
                                setFieldValue("file", file);
                              } else {
                                setFileError(
                                  "Please select a file of less than 25KB"
                                );
                              }
                              return Promise.resolve();
                            }}
                          >
                            <IconButton size="small">
                              <AttachFile
                                sx={{ color: "#a6a6a6" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Upload>
                        </Box>
                        {Boolean(values.file) &&
                          typeof values.file === "string" && (
                            <Box>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  window.open(values.file, "_blank")
                                }
                              >
                                <VisibilityOutlined
                                  sx={{ color: "#a6a6a6" }}
                                  fontSize="small"
                                />
                              </IconButton>
                            </Box>
                          )}
                      </Box>
                      {Boolean(reUploadDocId) && (
                        <Box
                          component="div"
                          sx={[
                            styles.flex_JCsb,
                            {
                              alignItems: "center",
                              backgroundColor: (ContractStatusMapping as any)[
                                documentStatus
                              ].bg,
                              p: 1,
                              borderRadius: "20px",
                              cursor: "pointer",
                              minWidth: "120px",
                            },
                          ]}
                          onClick={handleCnctStatusClick}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: (ContractStatusMapping as any)[
                                documentStatus
                              ].color,
                              fontWeight: 600,
                              ml: 1,
                              flexGrow: 1,
                              textAlign: "center",
                            }}
                          >
                            {documentStatus === "APPROVAL_PENDING"
                              ? "Pending"
                              : (ContractStatusMapping as any)[documentStatus]
                                  .name}
                          </Typography>
                          <KeyboardArrowDown
                            fontSize="small"
                            sx={{
                              ml: 1,
                              color: (ContractStatusMapping as any)[
                                documentStatus
                              ].color,
                            }}
                          />
                        </Box>
                      )}
                      <Menu
                        anchorEl={cnctStatusAnchor}
                        open={openCnctStatusMenu}
                        onClose={handleCnctStatusClose}
                        sx={styles.menu_container}
                        elevation={0}
                      >
                        <MenuList>
                          {IndexItemStatusArr.map((item) => (
                            <MenuItem
                              key={item.id}
                              onClick={() => {
                                setDocumentStatus(item.id);
                                fireUpdateCnctStatusApi({
                                  variables: {
                                    payload: {
                                      documentId: reUploadDocId,
                                      documentStatus: item.id,
                                    },
                                  },
                                });
                                handleCnctStatusClose();
                              }}
                            >
                              <ListItemIcon>
                                <FiberManualRecord
                                  fontSize="small"
                                  sx={{
                                    color: (ContractStatusMapping as any)[
                                      item.id
                                    ].color,
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    color: `${colors.primaryBlack}80`,
                                  }}
                                >
                                  {item.name}
                                </Typography>
                              </ListItemText>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box sx={Boolean(reUploadDocId) ? styles.flex_JCfe : {}}>
                      <BlackActionContainedButton
                        variant="contained"
                        sx={{
                          borderRadius: "20px",
                          ml: 1,
                          fontSize: "13px",
                          fontWeight: 600,
                          width: "120px",
                        }}
                        disableElevation
                        disableRipple
                        onClick={() => handleSubmit()}
                      >
                        Save
                      </BlackActionContainedButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Formik>
  );
};

export default UploadDocModal;
