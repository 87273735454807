import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import { IUpdateContactFieldsNew } from "models/contacts";
import { BlackActionContainedButton, OutlinedBtn } from "common/Buttons";
import { ProjectDetailsStyles as styles } from "pages/ClientScreens/ProjectDetails/styles";

interface IBankDetailsProps {
  initialValues: IUpdateContactFieldsNew;
  refetch: any;
  updateContactApi: any;
  profilePicture: any;
  setActiveSection: Dispatch<SetStateAction<number>>;
}

function BankDetailsForm(props: IBankDetailsProps) {
  const { initialValues, setActiveSection } = props;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({})}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ values }) => (
        <Box
          sx={{
            pt: { xs: 2, sm: 14 },
            px: 4,
            pb: 1.5,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: { xs: "block", sm: "none" } }}
            >
              <Typography
                sx={{ fontSize: "13px", color: "#000", fontWeight: 600 }}
              >
                Bank Details
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                sx={[styles.field_label, { fontWeight: 400, color: "#000" }]}
              >
                Bank Name
              </Typography>
              <Typography sx={[styles.field_label, { fontWeight: 400 }]}>
                {values?.personalBankAccountDetails?.bankName}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                sx={[styles.field_label, { fontWeight: 400, color: "#000" }]}
              >
                Branch
              </Typography>
              <Typography sx={[styles.field_label, { fontWeight: 400 }]}>
                {values?.personalBankAccountDetails?.branchName}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                sx={[styles.field_label, { fontWeight: 400, color: "#000" }]}
              >
                Account Name
              </Typography>
              <Typography sx={[styles.field_label, { fontWeight: 400 }]}>
                {values?.personalBankAccountDetails?.accountName}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                sx={[styles.field_label, { fontWeight: 400, color: "#000" }]}
              >
                BSB
              </Typography>
              <Typography sx={[styles.field_label, { fontWeight: 400 }]}>
                {values?.personalBankAccountDetails?.accountBsb}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography
                sx={[styles.field_label, { fontWeight: 400, color: "#000" }]}
              >
                Account Number
              </Typography>
              <Typography sx={[styles.field_label, { fontWeight: 400 }]}>
                {values?.personalBankAccountDetails?.accountNumber}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                sx={[styles.field_label, { fontWeight: 400, color: "#000" }]}
              >
                SWIFT Code
              </Typography>
              <Typography sx={[styles.field_label, { fontWeight: 400 }]}>
                {values?.personalBankAccountDetails?.accountSwiftCode}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ flexGrow: 1, mt: 2 }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={[styles.flex_Acenter, { justifyContent: "flex-end" }]}>
                <OutlinedBtn
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                    color: "#000",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(1)}
                >
                  Back
                </OutlinedBtn>
                <BlackActionContainedButton
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    ml: 1,
                    fontSize: "13px",
                    fontWeight: 600,
                    width: "120px",
                  }}
                  disableElevation
                  disableRipple
                  onClick={() => setActiveSection(3)}
                >
                  Next
                </BlackActionContainedButton>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

export default BankDetailsForm;
