import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";

import { AdminDashboardLabels } from "common/AppConstants";
import { images } from "assets/images";

import { AdminDashboardStyles as styles } from "./styles";
import { IStatusCount } from ".";

const imageMap: any = {
  Concept: images.CONCEPT,
  Development: images.DEVELOPMENT,
  "Pre-Production": images.PRE_PRODUCTION,
  Production: images.PRODUCTION,
  "Post-Production": images.POST_PRODUCTION,
  Delivered: images.DELIVERED,
  Archived: images.ARCHIVED,
};

interface Props {
  stats: IStatusCount[];
}

function ProjectStatusVsCount(props: Props) {
  const { stats } = props;

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={styles.titleText}>
          {AdminDashboardLabels.PROJECTSTATUS_VS_COUNT}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Divider sx={styles.divider} />
      </Grid>
      {stats.map((item, i) => (
        <Grid key={i + 1} item lg={4} md={4} sm={6} xs={12}>
          <Box sx={[styles.flex_Acenter, { mt: 2 }]}>
            <img src={imageMap[item.status]} alt="stat-icon" />
            <Box sx={styles.ml1}>
              <Typography sx={styles.project_status_text}>
                {item.status}
              </Typography>
              <Typography sx={styles.project_status_count_text}>
                {item.count}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default ProjectStatusVsCount;
