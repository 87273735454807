import { colors } from "theme/colors";

export const TwoFASuccessStyles = {
  login_signup_bg: { width: "100%", height: "100vh" },
  two_fa_container: {
    height: "100%",
    width: "100%",
  },
  content_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  success_text: {
    fontSize: "60px",
    fontWeight: 700,
    color: colors.primaryBlack,
  },
  congrats_text: { color: "#5B5C60", textAlign: "center" },
  login_btn: {
    width: "100%",
    fontSize: "14px",
    mt: 3,
    // borderRadius: "24px",
    py: 1.25,
  },
  tandc_container: {
    border: `1px solid ${colors.whiteGrey400}`,
    borderRadius: "8px",
    p: 2,
    width: { xs: "auto", sm: 600 },
    mt: 2,
  },
  tc_text: { fontSize: "13px", fontWeight: 500, color: colors.primaryBlack },
};
