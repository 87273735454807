import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Chip, Grid, IconButton, Menu, Typography } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  AddCircle,
  FilterAltOutlined,
  FormatListBulletedOutlined,
  GridViewOutlined,
  Search,
} from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { RouteNames } from "routes/routeNames";
import { ProjectStatusMapping, ProjectTypeArr } from "utils/constants";
import Drawer from "common/ClientDrawer";
import Table from "common/Table";
import { useProjectCreateModal } from "hooks/useCreateProjectModal";
import { IProject } from "models/projects";
import { GET_ALL_PROJECTS } from "graphql/projects";
import { useStore } from "utils/store";
import { getPermissions, getProjectRedirection } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import Filters from "./Filters";
import ProjectCard from "./ProjectCard";
import AddProjectModal from "./AddProjectModal";
import DeleteProjectModal from "./DeleteProjectModal";
import EditProjectModal from "./EditProjectModal";
import { ProjectStyles as styles } from "./styles";

export const Projects = () => {
  const navigate = useNavigate();
  const { setLoading } = useStore();
  const projectCreateModal = useProjectCreateModal();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const projectViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("PROJECT_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const projectEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions.findIndex((x: any) =>
        x.hasOwnProperty("PROJECT_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [view, setView] = useState(1);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [projectCount, setProjectCount] = useState(0);
  const [addProjectModal, setAddProjectModal] = useState(false);
  // const [paginationModelGrid, setPaginationModelGrid] =
  //   useState<IPaginationModel>({
  //     page: 1,
  //     pageSize: 5,
  //   });
  // const [paginationModelTable, setPaginationModelTable] =
  //   useState<IPaginationModel>({
  //     page: 0,
  //     pageSize: 10,
  //   });
  const [deleteProjModal, setDeleteProjModal] = useState(false);
  const [editProjectModal, setEditProjectModal] = useState(false);
  const [selectedProjId, setSelectedProjId] = useState("");
  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [selectedProjectCategory, setSelectedProjectCategory] = useState("");
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLElement | null>(
    null
  );

  const handleFilterMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuAnchor(e.currentTarget);
    setOpenFilterMenu(true);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
    setOpenFilterMenu(false);
  };

  const {
    loading,
    refetch: refetchProjects,
    fetchMore,
  } = useQuery(GET_ALL_PROJECTS, {
    variables: {
      limit: 40,
      page: 1,
      ...((Boolean(selectedProjectCategory) ||
        Boolean(selectedProjectType)) && {
        filter: {
          ...(selectedProjectCategory && {
            projectCategory: selectedProjectCategory,
          }),
          ...(selectedProjectType && { projectType: selectedProjectType }),
        },
      }),
      sorted: "name",
    },
    skip: !(
      Boolean(ROLE_PERMISSIONS) &&
      projectViewIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[projectViewIndex]?.PROJECT_VIEW
    ),
    onCompleted: (data) => {
      setLoading(false);
      const { projects: rawProjectsResponse } = data;
      const { status, projects: rawProjects, count } = rawProjectsResponse;
      if (status) {
        setProjectCount(count);
        setProjects(rawProjects);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        sortable: false,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {params?.row?.profilePicture ? (
              <Box sx={[styles.proj_img, { height: "55px" }]}>
                <img
                  src={params?.row?.profilePicture}
                  alt="proj_image"
                  style={{
                    height: "55px",
                    width: "37.13px",
                    borderRadius: "8px",
                    objectFit: "cover",
                    aspectRatio: 0.675 / 1,
                    ...(params?.row?.projectStatus === 2 && {
                      filter: "grayscale(100%)",
                    }),
                  }}
                />
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={[
                    styles.proj_img,
                    {
                      backgroundColor: `#BFBDBC22`,
                      aspectRatio: 0.675 / 1,
                      borderRadius: "8px",
                      height: "55px",
                      width: "37.13px",
                    },
                  ]}
                >
                  <img
                    src={images.FILM_REEL_DARK}
                    alt="reel"
                    style={{ height: 15 }}
                  />
                </Box>
              </Box>
            )}
            <Typography sx={[styles.cell_text, { ml: 1 }]}>
              {params?.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: "projectType",
        headerName: "Type",
        sortable: false,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {Boolean(params?.value)
              ? ProjectTypeArr.find((x) => x.id === params?.value)?.name
              : ""}
          </Typography>
        ),
      },
      {
        field: "projectStatus",
        headerName: "Status",
        minWidth: 200,
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <Chip
            label={(ProjectStatusMapping as any)[params?.value].name}
            sx={[
              styles.chip_variant,
              {
                color: (ProjectStatusMapping as any)[params?.value].color,
                backgroundColor: (ProjectStatusMapping as any)[params?.value]
                  .bg,
              },
            ]}
          />
        ),
      },
      {
        field: "projectStartDate",
        headerName: "Start Date",
        sortable: false,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {Boolean(params?.value)
              ? moment(params?.value).format("DD MMMM YYYY")
              : ""}
          </Typography>
        ),
      },
      {
        field: "projectEndDate",
        headerName: "End Date",
        sortable: false,
        minWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={styles.cell_text}>
            {Boolean(params?.value)
              ? moment(params?.value).format("DD MMMM YYYY")
              : ""}
          </Typography>
        ),
      },
    ],
    []
  );

  // Attach event listener for scrolling
  // useEffect(() => {
  //   const handleScroll = async () => {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight
  //     ) {
  //       return;
  //     }
  //     if (projects.length < projectCount) {
  //       try {
  //         setLoading(true);
  //         await fetchMore({
  //           variables: {
  //             page: Math.ceil(projects.length / 40) + 1,
  //             limit: 40,
  //           },
  //           updateQuery: (previousResult, { fetchMoreResult }) => {
  //             const newEntries = fetchMoreResult.projects.projects;
  //             return {
  //               projects: {
  //                 ...fetchMoreResult?.projects,
  //                 projects: [...projects, ...newEntries],
  //               },
  //             };
  //           },
  //         });
  //       } catch (error) {
  //         console.error("ERROR", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [fetchMore, projectCount, projects, setLoading]);
  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.offsetHeight - 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (projects.length < projectCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(projects.length / 40) + 1,
              limit: 40,
              sorted: "name",
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              // Update the projects array with new entries
              const newEntries = fetchMoreResult.projects.projects;
              return {
                projects: {
                  ...fetchMoreResult?.projects,
                  projects: [...projects, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchMore, projectCount, projects, setLoading]);

  useEffect(() => {
    setSelectedProjectCategory("");
    setSelectedProjectType("");
  }, [view]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Drawer>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0.25 }}>
          <Box sx={styles.view_container}>
            <Box>
              <IconButton
                onClick={handleFilterMenuOpen}
                size="small"
                sx={{ ":hover": { backgroundColor: "#41B19931" } }}
              >
                <FilterAltOutlined
                  fontSize="small"
                  sx={{
                    color: openFilterMenu
                      ? "#41B199"
                      : `${colors.primaryBlack}50`,
                  }}
                />
              </IconButton>
            </Box>
            <Menu
              anchorEl={filterMenuAnchor}
              open={openFilterMenu}
              onClose={handleFilterMenuClose}
              elevation={1}
              sx={{ "& .MuiMenu-paper": { width: 350 } }}
            >
              <Filters
                selectedProjectType={selectedProjectType}
                setSelectedProjectType={setSelectedProjectType}
                selectedProjectCategory={selectedProjectCategory}
                setSelectedProjectCategory={setSelectedProjectCategory}
              />
            </Menu>
            <Box sx={{ mx: 0.55 }}>
              <IconButton
                onClick={() => setView(0)}
                size="small"
                sx={{ ":hover": { backgroundColor: "#41B19931" } }}
              >
                <FormatListBulletedOutlined
                  fontSize="small"
                  sx={{
                    color: view === 0 ? "#41B199" : `${colors.primaryBlack}50`,
                  }}
                />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                onClick={() => setView(1)}
                size="small"
                sx={{ ":hover": { backgroundColor: "#41B19931" } }}
              >
                <GridViewOutlined
                  fontSize="small"
                  sx={{
                    color: view === 1 ? "#41B199" : `${colors.primaryBlack}50`,
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        {!loading &&
        !selectedProjectCategory &&
        !selectedProjectType &&
        projects.length === 0 ? (
          Boolean(ROLE_PERMISSIONS) &&
          projectEditIndex !== undefined &&
          ROLE_PERMISSIONS[0]?.permissions[projectEditIndex]?.PROJECT_EDIT ? (
            <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box
                sx={[
                  styles.project_item_container,
                  { display: "flex", justifyContent: "center" },
                ]}
                onClick={() => {
                  projectCreateModal.onOpen();
                  navigate(RouteNames.PROJECTS);
                }}
              >
                <Box
                  sx={[
                    styles.proj_img,
                    {
                      border: "3px #BFBDBC dotted",
                      borderRadius: "20px",
                      backgroundColor: `#BFBDBC22`,
                      flexDirection: "column",
                      px: 5,
                      aspectRatio: 0.675 / 1,
                      width: "202.5px",
                    },
                  ]}
                >
                  <AddCircle sx={{ color: "#BFBDBC", fontSize: 65, mb: 2 }} />
                  <Typography
                    sx={{
                      color: `#BFBDBC`,
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                    textAlign="center"
                  >
                    Create New Project
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  height: `calc(100vh - 170px)`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    height: "200px",
                    width: "550px",
                    backgroundColor: "#BFBDBC22",
                    borderRadius: " 24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: " column",
                  }}
                >
                  <img
                    src={images.PROJECTS_SELECTED}
                    alt="agent"
                    style={{ width: 25 }}
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.primaryBlack,
                      my: 2,
                      fontWeight: 600,
                    }}
                  >
                    Its empty here!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Please reach out to your admin for access to create a
                    project.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )
        ) : (
          <></>
        )}
        {!loading &&
          (selectedProjectCategory || selectedProjectType) &&
          projects.length === 0 && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Search
                  sx={{ color: `${colors.primaryBlack}30`, fontSize: 80 }}
                />
                <Typography
                  sx={{
                    mt: 2,
                    color: `${colors.primaryBlack}30`,
                    fontSize: "32px",
                    fontWeight: 500,
                  }}
                  textAlign="center"
                >
                  No results found
                </Typography>
              </Box>
            </Grid>
          )}
        {!loading && projects.length > 0 && (
          <>
            {view === 1 &&
              projects.map((proj) => (
                <Grid key={proj.id} item lg={3} md={6} sm={6} xs={12}>
                  <ProjectCard
                    proj={proj}
                    setSelectedProjId={setSelectedProjId}
                    setEditProjectModal={setEditProjectModal}
                    setDeleteProjModal={setDeleteProjModal}
                  />
                </Grid>
              ))}
            {view === 0 && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ mx: -3, mb: -3 }}>
                  <Table
                    rows={projects}
                    columns={columns}
                    showCellVerticalBorder={false}
                    showColumnVerticalBorder={false}
                    disableColumnMenu
                    rowCount={projectCount}
                    onRowClick={(params: GridRowParams, _, __) =>
                      navigate(
                        `${RouteNames.PROJECTS}/${
                          params.row.id
                        }${getProjectRedirection(ROLE_PERMISSIONS)}`
                      )
                    }
                    sx={{
                      ...styles.table,
                      "& .MuiDataGrid-columnHeaders": {
                        border: "none",
                        backgroundColor: "#B6BCC320",
                      },
                      "& .MuiDataGrid-row": {
                        borderBottom: "1px #e6e6e6 solid",
                        cursor: "pointer",
                        borderRadius: 0,
                      },
                      borderTop: "1px #e6e6e6 solid",
                      borderRadius: 0,
                    }}
                    hideFooter
                  />
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {(addProjectModal || projectCreateModal.open) && (
        <AddProjectModal
          open={addProjectModal || projectCreateModal.open}
          handleClose={() => {
            if (projectCreateModal.open) {
              projectCreateModal.onClose();
            }
            setAddProjectModal(false);
          }}
          refetchProjects={refetchProjects}
        />
      )}
      {deleteProjModal && (
        <DeleteProjectModal
          open={deleteProjModal}
          setOpen={setDeleteProjModal}
          projectId={selectedProjId}
          refetchProjects={refetchProjects}
          setSelectedProjectId={setSelectedProjId}
        />
      )}
      {editProjectModal && (
        <EditProjectModal
          open={editProjectModal}
          setOpen={setEditProjectModal}
          projectId={selectedProjId}
          refetchProjects={refetchProjects}
          setSelectedProjectId={setSelectedProjId}
        />
      )}
    </Drawer>
  );
};
