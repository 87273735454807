import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { ArrowCircleLeft } from "@mui/icons-material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { FORGOT_PWD } from "graphql/users";
import {
	BlackTextButton,
	NewGreenPrimaryContainedButton,
} from "common/Buttons";
import { useStore } from "utils/store";
import { images } from "assets/images";

import { ForgotPwdStyles as styles } from "./styles";

interface ForgotPwdFields {
	email: string;
}

const ForgotPwdForm = () => {
	const navigate = useNavigate();
	const { setLoading } = useStore();

	const [fireForgotPwd, { loading }] = useMutation(FORGOT_PWD, {
		onCompleted: (data) => {
			setLoading(false);
			const { resetPasswordRequest } = data;
			const { status, error } = resetPasswordRequest;
			if (status) {
				// sessionStorage.setItem("email", enteredEmail);
				// navigate(RouteNames.FORGOT_PWD_OTP);
				toast.success(error, { delay: 10 });
			} else {
				toast.error(error, { delay: 10 });
			}
		},
	});

	useEffect(() => {
		setLoading(loading);
	}, [loading, setLoading]);

	const handleEmailVerifySubmit = (values: ForgotPwdFields) => {
		// setEnteredEmail(values.email);
		fireForgotPwd({
			variables: { payload: { ...values, email: values.email.trim() } },
		});
	};

	return (
		<Box
			sx={[
				styles.content_center,
				{
					width: { xs: "100%", sm: "75%", md: "48%" },
					py: { xs: 4, md: 0 },
					px: { xs: 4, sm: 8, md: 0 },
					backgroundColor: { xs: "#fff", md: "none" },
					borderRadius: { xs: "15px", md: 0 },
					mx: { xs: 4, md: 0 },
				},
			]}
		>
			<Formik
				initialValues={{ email: "" }}
				onSubmit={handleEmailVerifySubmit}
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.email("Please enter a valid email address")
						.required("Please enter your email address"),
				})}
				enableReinitialize
			>
				{({
					values,
					handleChange,
					handleBlur,
					touched,
					errors,
					handleSubmit,
					isValid,
				}) => (
					<Form onSubmit={handleSubmit}>
						<Grid container spacing={4}>
							<Grid
								item
								lg={12}
								md={12}
								sm={12}
								xs={12}
								sx={styles.content_center}
							>
								<Box sx={[styles.textfield, styles.content_center]}>
									<img src={images.LOGO} alt="logo" height="30px" />
								</Box>
							</Grid>
							<Grid
								item
								lg={12}
								md={12}
								sm={12}
								xs={12}
								sx={styles.content_center}
							>
								<Typography sx={styles.title_text}>
									Forgot Password
								</Typography>
							</Grid>
							<Grid
								item
								lg={12}
								md={12}
								sm={12}
								xs={12}
								sx={styles.content_center}
							>
								<Box sx={[styles.textfield, { mt: 5 }]}>
									<TextField
										variant="standard"
										id="email"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
										size="small"
										sx={[
											styles.textfield,
											{
												"& .MuiInputBase-root": {
													px: 1,
												},
												"& .MuiInput-underline:before": {
													borderBottomColor: "#00000030",
												},
												"& .MuiInput-underline:after": {
													borderBottomColor: "#000000",
												},
												"& .MuiInputBase-root ::placeholder": {
													color: "#0000060",
												},
											},
										]}
										inputProps={{
											style: styles.input_field,
										}}
										placeholder="Enter your email"
									/>
								</Box>
							</Grid>
							<Grid
								item
								lg={12}
								md={12}
								sm={12}
								xs={12}
								sx={[
									styles.content_center,
									{ flexDirection: "column" },
								]}
							>
								<NewGreenPrimaryContainedButton
									variant="contained"
									size="small"
									sx={styles.continue_btn}
									type="submit"
									disabled={!isValid}
									disableElevation
									disableRipple
								>
									Reset Password
								</NewGreenPrimaryContainedButton>
								<Box sx={{ mt: 1 }}>
									<Typography
										textAlign="center"
										sx={{ fontSize: "12px", color: "#6f6f6f" }}
									>
										Need help?{" "}
										<span style={{ fontWeight: 600 }}>Email us</span>{" "}
										at info@finka.me
									</Typography>
								</Box>
							</Grid>
							<Grid
								item
								lg={12}
								md={12}
								sm={12}
								xs={12}
								sx={styles.content_center}
							>
								<BlackTextButton
									sx={{
										fontSize: "13px",
										fontWeight: 600,
										color: "#a6a6a6",
									}}
									startIcon={
										<ArrowCircleLeft
											sx={{ Color: "#a6a6a6" }}
											fontSize="small"
										/>
									}
									onClick={() => navigate("/")}
								>
									Back to Login
								</BlackTextButton>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default ForgotPwdForm;
