import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";

import { images } from "assets/images";
import { GlobalLabels } from "common/AppConstants";
import { colors } from "theme/colors";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  successText: string;
  onClose?: () => void;
}

const styles = {
  alert_img: { width: "80px", alignSelf: " center", paddingTop: 40 },
  success_text: {
    textAlign: "center",
    fontSize: "24px",
    color: colors.primaryBlack,
    fontWeight: 600,
    py: 2,
  },
  success_desc: {
    textAlign: "center",
    fontSize: "18px",
    color: colors.secondaryBlack,
    pb: 2,
    px: 4,
  },
  actions_container: {
    display: "flex",
    justifyContent: "center",
    pb: 3,
    px: 4,
    mt: 3,
  },
  btn: { fontSize: "16px", width: "45%", fontWeight: 500 },
  noBtn: { color: colors.secondaryBlack },
};

const SuccessModal = (props: IProps) => {
  const { open, setOpen, successText, onClose } = props;

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <img src={images.GREEN_TICK} alt="alert" style={styles.alert_img} />
      <Typography sx={styles.success_text}>{GlobalLabels.SUCCESS}</Typography>
      <Typography sx={styles.success_desc}>{successText}</Typography>
      <Box sx={styles.actions_container}>
        <Button variant="contained" sx={styles.btn} onClick={handleClose}>
          Okay
        </Button>
      </Box>
    </Dialog>
  );
};

export default SuccessModal;
