import { gql } from "@apollo/client";

export const GET_DOCUMENT_FOLDERS = gql`
  query getDocuments(
    $filter: DocumentsFilterInput!
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getDocuments(filter: $filter, limit: $limit, page: $page, sorted: $sorted) {
      status
      message
      count
      documents {
        id
        name
        additionalDetails
        isUpload
        isFolder
        documentStatus
      }
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation createDocument($payload: CreateDocumentInput!, $file: Upload) {
    createDocument(payload: $payload, file: $file) {
      id
      status
      message
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation updateDocument($payload: UpdateDocumentInput!, $file: Upload) {
    updateDocument(payload: $payload, file: $file) {
      status
      message
    }
  }
`;

export const UPDATE_DOCUMENT_STATUS = gql`
  mutation updateDocumentStatus($payload: UpdateDocumentStatusInput!) {
    updateDocumentStatus(payload: $payload) {
      status
      message
    }
  }
`;

export const GET_CONTRACT_FILE = gql`
  query getDocumentFile($payload: FetchDocumentFileInput) {
    getDocumentFile(payload: $payload) {
      status
      message
      file
      fileExists
      fileType
      signedFile
      document {
        id
        name
        documentStatus
        isUpload
        isFolder
        additionalDetails
        contact {
          id
          fullName
        }
        inhouseCompany {
          id
        }
        template {
          id
          name
          templateType {
            name
          }
          additionalDetails
        }
        createdDate
        modifiedDate
        fileName
      }
    }
  }
`;

export const GET_PROJ_DEETS_FEE_CALCULATOR = gql`
  query project($id: String!) {
    project(id: $id) {
      status
      project {
        id
        name
        projectType
        projectCategory
        categoryLoading
        collectiveAgreementCast
        collectiveAgreementCrew
        currency
      }
      inAggregate
    }
  }
`;

export const GET_PROJ_DEETS_CURRENCY = gql`
  query project($id: String!) {
    project(id: $id) {
      status
      project {
        id
        currency
      }
    }
  }
`;

export const GET_DOCUMENT_ARR = gql`
  query getDocuments(
    $filter: DocumentsFilterInput!
    $limit: Int
    $page: Int
    $sorted: String
  ) {
    getDocuments(filter: $filter, limit: $limit, page: $page, sorted: $sorted) {
      status
      documents {
        id
        name
      }
    }
  }
`;

export const GET_VERSION_HISTORY = gql`
  query versionHistory(
    $document_id: String!
    $project_id: String!
    $limit: Int
    $page: Int
  ) {
    getVersionHistory(
      document_id: $document_id
      project_id: $project_id
      limit: $limit
      page: $page
    ) {
      status
      message
      count
      history {
        historyDate
        historyUserId {
          fullName
          profilePicture
        }
      }
    }
  }
`;

export const GET_NOTES = gql`
  query getDocumentComments($document_id: String!, $project_id: String!) {
    getDocumentComments(document_id: $document_id, project_id: $project_id) {
      status
      message
      count
      comments {
        id
        comment
        createdDate
        createdBy {
          fullName
        }
      }
    }
  }
`;

export const UPSERT_NOTES = gql`
  mutation upsertDocumentComment($payload: UpsertDocumentComment) {
    upsertDocumentComment(payload: $payload) {
      status
      message
    }
  }
`;

export const COLLABORATE_CONTRACT = gql`
  mutation assignDocumentToUser($payload: AssignDocumentToUser) {
    assignDocumentToUser(payload: $payload) {
      status
      message
    }
  }
`;

export const CALCULATE_FEE = gql`
  mutation calculateFee($payload: FeeCalculatorInput) {
    calculateFee(payload: $payload) {
      status
      message
      response
    }
  }
`;

export const GET_RESOLUTION_VALUES = gql`
  query getDocumentFillableFields(
    $payload: [String!]
    $document_id: String!
    $project_id: String!
  ) {
    getDocumentFillableFields(
      payload: $payload
      document_id: $document_id
      project_id: $project_id
    ) {
      status
      message
      fields
    }
  }
`;

export const DOWNLOAD_CONTRACT_AS_PDF = gql`
  query getContractAsPDF($id: String!) {
    getContractAsPDF(id: $id) {
      status
      message
      contract
    }
  }
`;

export const GET_DOCUMENT_SIGNERS_ARR = gql`
  query getDocumentSigners($document_id: String!, $project_id: String!) {
    getDocumentSigners(document_id: $document_id, project_id: $project_id) {
      status
      message
      fields
    }
  }
`;

export const GET_STATUS_HISTORY = gql`
  query getStatusHistory($document_id: String!, $project_id: String!) {
    getStatusHistory(document_id: $document_id, project_id: $project_id) {
      status
      message
      history {
        id
        historyChangeReason
        documentStatus
        historyDate
      }
    }
  }
`;

export const SEND_CONTRACT = gql`
  mutation shareDocument($payload: ShareDocumentViaEmailPayload!) {
    shareDocument(payload: $payload) {
      status
      message
    }
  }
`;

export const GET_EMIALS_ARR = gql`
  query getUsersForShareDocument($project_id: String!) {
    getUsersForShareDocument(project_id: $project_id) {
      status
      message
      users {
        id
        name
        email
      }
    }
  }
`;

export const GET_CONTRACT_FILE_GUEST = gql`
  query getDocumentFileGuest($documentId: String!) {
    getDocumentFileGuest(documentId: $documentId) {
      status
      message
      file
      fileExists
      fileType
      signedFile
      document {
        id
        name
        documentStatus
        isUpload
        isFolder
        additionalDetails
        contact {
          id
          fullName
        }
        inhouseCompany {
          id
        }
        template {
          name
          templateType {
            name
          }
          additionalDetails
        }
        createdDate
        modifiedDate
      }
    }
  }
`;

export const GET_FIELDS_FOR_THIRD_PARTY_DOCUMENT = gql`
  query getFieldsForThirdPartyDocument(
    $document_id: String!
    $project_id: String!
  ) {
    getFieldsForThirdPartyDocument(
      project_id: $project_id
      document_id: $document_id
    ) {
      status
      message
      fields
    }
  }
`;
