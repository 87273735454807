import { colors } from "theme/colors";

export const ProjectDetailsStyles = {
  view_container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tab_text: {
    width: "150px",
    fontSize: "14px",
    fontWeight: 400,
    color: colors.primaryBlack,
    "&.Mui-selected": {
      fontWeight: 600,
      color: colors.primaryBlack,
    },
    ":hover": {
      backgroundColor: "#B6BCC325",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
    },
  },
  filters_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    px: 2.5,
    py: 3.5,
  },
  text_input: { fontSize: "14px", color: colors.primaryBlack },
  avatar: { width: "24px", height: "24px" },
  cell_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
  },
  chip: {
    color: colors.primary,
    backgroundColor: `${colors.primary}20`,
  },
  chip_variant: { fontWeight: 500, fontSize: "13px" },
  action_text: {
    color: colors.secondaryBlack,
    fontSize: "14px",
  },
  ml1: { ml: 1 },
  ml2: { ml: 2 },
  project_pic_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    my: 3,
  },
  reel_avatar: {
    height: "100px",
    width: "100px",
    backgroundColor: "#F5F6FF",
    mr: 4,
  },
  field_label: {
    color: `${colors.secondaryBlack}70`,
    fontSize: "13px",
    fontWeight: 600,
    mb: 0.5,
  },
  input_field: {
    backgroundColor: "#e6e6e640",
    borderRadius: "6px",
    fontSize: "13px",
    color: colors.primaryBlack,
  },
  actions_container: {
    mt: 2,
    display: "flex",
    justifyContent: "space-between",
  },
  btn: { width: "98px" },
  reel_name_container: { display: "flex", alignItems: "center" },
  project_title_text: {
    color: colors.primary,
    fontSize: "20px",
    fontWeight: 500,
    // wordBreak: "break-word",
    // flex: 1,
  },
  project_metadata_text: {
    color: colors.primaryBlack,
    fontSize: "14px",
    fontWeight: 500,
  },
  progress_bar_container: { width: "240px", mb: 1.5 },
  progress_bar: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#dedede",
  },
  progress_bar_text_container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  progress_bar_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
  },
  flex_JCsb_Acenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flex_JCsb: {
    display: "flex",
    justifyContent: "space-between",
  },
  flex_Acenter: {
    display: "flex",
    alignItems: "center",
  },
  stat_count_text: {
    fontSize: "30px",
    color: colors.primaryBlack,
    fontWeight: 500,
  },
  stat_label_text: {
    fontSize: "20px",
    color: colors.secondaryBlack,
    fontWeight: 500,
  },
  add_cnct_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  menu_container: {
    "& .MuiMenu-paper": {
      mt: 1,
      border: "1px #00000030 solid",
      borderRadius: "15px",
    },
  },
  my3: { my: 3 },
  flex_Afe: { display: "flex", alignItems: "flex-end" },
  proj_status_container: { display: "flex", mt: 1.5 },
  proj_status_label_container: {
    px: 1.5,
    height: "30px",
    borderRadius: "6px",
  },
  proj_stat_icon_container: {
    height: "30px",
    borderRadius: "0px 6px 6px 0px",
    ml: 0.5,
  },
  field_value_text: {
    color: colors.primaryBlack,
    fontSize: "16px",
    fontWeight: 500,
  },
  proj_status_label_text: {
    color: colors.secondary,
    fontSize: "13px",
    fontWeight: 500,
  },
  upload_cnct_container: {
    border: `1px dotted ${colors.primary}`,
    borderRadius: "4px",
    backgroundColor: "#F8F8FF",
    py: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  upload_text: {
    textAlign: "center",
    color: colors.primaryBlack,
    fontSize: "16px",
    my: 1,
  },
  upload_supported_type_text: {
    textAlign: "center",
    color: colors.secondaryBlack,
    fontSize: "12px",
  },
  actions_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },

  contracts_folder_struct_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "12px",
  },
  divider: { color: colors.borderColor, mb: 2 },
  folders_container: {
    borderRight: { xs: "none", sm: `1px solid ${colors.borderColor}` },
  },
  contracts_container: {},
  header: {
    borderBottom: { xs: "none", sm: `1px solid ${colors.borderColor}` },
    backgroundColor: "#D9D9D950",
    p: 1.5,
  },
  toggle_container: {},
  toggle_btn_container: {
    px: 2,
    pt: 1,
    pb: 0.5,
    cursor: "pointer",
    backgroundColor: colors.secondary,
  },
  selected_toggle_btn: {
    px: 2,
    pt: 1,
    pb: 0.5,
    cursor: "pointer",
    backgroundColor: colors.primary,
  },
  folder_collapse_container: {
    p: 1.5,
    borderRight: { xs: "none", sm: `1px solid ${colors.borderColor}` },
  },
  folder_chevron_icon: { color: colors.iconColor },
  folder_icon: { marginRight: 10, marginLeft: 10 },
  folder_name_text: { fontSize: "14px", color: colors.primaryBlack },
  folder_contract_container: {
    px: 1.5,
    py: 0.75,
  },
  folder_contract_name_text: {
    fontSize: "16px",
    color: colors.primaryBlack,
    fontWeight: 500,
  },
  card: { p: 3, boxShadow: "1px 1px 8px #d3d3d3" },
  tab_title_text: { fontSize: "20px", fontWeight: 500, color: colors.primary },
  flex_JCfe: { display: "flex", justifyContent: "flex-end" },
  add_project_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  proj_status_text: {
    color: colors.primaryBlack,
    fontSize: "16px",
    fontWeight: 500,
  },
  table: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      borderRadius: "10px",
      backgroundColor: "#B6BCC320",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: `${colors.primaryBlack}70`,
      fontSize: "13px",
      fontWeight: 600,
      // textTransform: "uppercase",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#b6bcc330",
      borderRadius: "0px",
    },
  },
  project_avatar: {
    width: "64px",
    height: "64px",
    backgroundColor: `${colors.primary}15`,
  },
  date_text: { color: colors.grey, fontSize: "16px", fontWeight: 500 },
  contact_deets_text_grid: {
    color: colors.secondaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  contact_grid_item_container: {
    p: 2,
    height: "230px",
    width: "280px",
  },
  name_category_grid_container: { display: "flex" },
  actions_menu: {
    "& .MuiMenu-paper": { border: `1px solid ${colors.borderColor}` },
  },
  avatar_grid: { height: "64px", width: "64px" },
  name_container_grid: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    mx: 1,
    width: "130px",
  },
  category_text_grid: {
    fontSize: "13px",
    color: colors.grey,
  },
  mt2: { mt: 2 },
  icon_avatar: {
    height: "32px",
    width: "32px",
    backgroundColor: `${colors.primary}20`,
    mr: 1,
  },
  contact_pic_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mb: 3,
  },
  add_contact_avatar: {
    height: "100px",
    width: "100px",
    mx: 4,
  },
  info_text: { color: colors.primary, fontSize: "16px", fontWeight: 500 },
  total_rights_container: {
    backgroundColor: colors.primary,
    p: 3,
    borderRadius: "8px",
  },
  totals_divider: { backgroundColor: colors.secondary },
  totals_label_text: {
    color: colors.secondary,
    fontSize: "16px",
    fontWeight: 500,
  },
  totals_perct_text: {
    color: colors.secondary,
    fontSize: "16px",
    fontWeight: 700,
  },
  error_text: { fontSize: "12px", color: "#D3010E" },
  agg_container: {
    mt: 1,
    border: `1px solid ${colors.borderColor}`,
    p: 2,
    borderRadius: "6px",
  },
  agg_text: { color: colors.primaryBlack, fontSize: " 13px", fontWeight: 500 },
  proj_details_menu: { cursor: "pointer" },
  modal_header_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  more_icon_container: {
    width: "46px",
    display: "flex",
    justifyContent: "flex-end",
  },
  border: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    p: 1.5,
  },
  box: { width: "200px", flexWrap: "wrap", wordBreak: "break-word" },
  upload_file_container: {
    border: `3px dotted ${colors.borderColor}`,
    borderRadius: "4px",
    py: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  uploaded_file_container: {
    border: `1px solid ${colors.borderColor}`,
    py: 1.5,
    px: 2,
    borderRadius: "4px",
  },
  table_avatar: {
    height: "32px",
    width: "32px",
    backgroundColor: `${colors.primary}20`,
    mr: 1,
  },
  wrap: { width: "100%", wordBreak: "break-word" },
  disabled_btn: {
    "&.Mui-disabled": {
      backgroundColor: colors.grey,
      color: "#c0c0c0",
    },
  },
  modal_footer_container: { p: 2, backgroundColor: `${colors.primary}20` },
  filter_field_label: {
    color: `${colors.primaryBlack}80`,
    fontSize: "13px",
    mb: 0.75,
  },
};
