import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  CheckOutlined,
  Close,
  ModeEditOutlineOutlined,
  Send,
} from "@mui/icons-material";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartEvent,
  TooltipItem,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";

import { IChecklistItem } from "models/checklists";
import {
  GET_CHECKLIST_GRAPHS,
  GET_CHECKLIST_ITEM_DETAILS,
  UPDATE_CHECKLIST_ITEM,
} from "graphql/checklist";
import { useStore } from "utils/store";
import { truncateText } from "utils/helpers";
import { getPermissions } from "permissions/utils";
import { colors } from "theme/colors";
import { images } from "assets/images";

import { ISelectedChecklistItem } from "./DeliveryChecklistItem";
import DeleteChecklistItemModal from "./DeleteModal";
import { ProjectDetailsStyles as styles } from "../styles";
import "./styles.css";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IChecklistItemFolderDetails {
  id: string;
  refetch: any;
  setSelectedChecklistItem: Dispatch<
    SetStateAction<ISelectedChecklistItem | null>
  >;
}

function ChecklistItemFolderDetails(props: IChecklistItemFolderDetails) {
  const { id, refetch: refetchTableData, setSelectedChecklistItem } = props;

  const params = useParams();
  const { setLoading } = useStore();
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const deliveryViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("DELIVERY_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const deliveryEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("DELIVERY_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [editMode, setEditMode] = useState(false);
  const [checklistItemDetails, setChecklistItemDetails] =
    useState<IChecklistItem>({
      id: "",
      name: "",
      description: "",
      levelNo: "",
      note: "",
      itemStatus: "",
      status: 0,
      createdDate: "",
      modifiedDate: "",
      completed: 0,
      pending: 0,
      isFolder: true,
      documents: [],
    });
  const [doughnutChartData, setDoughnutChartData] = useState<any>(null);
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [readMoreDescription, setReadMoreDescription] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);

  const { loading, refetch } = useQuery(GET_CHECKLIST_ITEM_DETAILS, {
    variables: {
      id: id,
      project_id: params?.project_id,
      checklist_id: params?.checklist_id,
    },
    skip:
      !params?.project_id ||
      !params?.checklist_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        deliveryViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
      ),
    onCompleted: (data) => {
      const { getChecklistItem } = data;
      const { status, checklistItem } = getChecklistItem;
      if (status) {
        const {
          id,
          name,
          description,
          levelNo,
          note,
          itemStatus,
          status,
          createdDate,
          modifiedDate,
          completed,
          pending,
          isFolder,
          documents,
        } = checklistItem;
        setChecklistItemDetails({
          id: id || "",
          name: name || "",
          description: description || "",
          levelNo: levelNo || "",
          note: note || "",
          itemStatus: itemStatus || "",
          status: status || 0,
          createdDate: createdDate || "",
          modifiedDate: modifiedDate || "",
          completed: completed || 0,
          pending: pending || 0,
          isFolder: isFolder,
          documents: documents || [],
        });
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [fireUpdateFolderApi, { loading: updating }] = useMutation(
    UPDATE_CHECKLIST_ITEM,
    {
      onCompleted: (data) => {
        const { updateChecklistItem } = data;
        const { status, message } = updateChecklistItem;
        setEditMode(false);
        if (status) {
          refetch();
          refetchTableData();
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const { loading: gettingBarChart } = useQuery(GET_CHECKLIST_GRAPHS, {
    variables: {
      id: id,
      project_id: params?.project_id,
      checklist_id: params?.checklist_id,
    },
    skip:
      !params?.project_id ||
      !params?.checklist_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        deliveryViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[deliveryViewIndex]?.DELIVERY_VIEW
      ),
    onCompleted: (data) => {
      const { getChecklistItemStatusGraph } = data;
      const { status, graph } = getChecklistItemStatusGraph;
      if (status) {
        const graphJSON = Boolean(graph) ? JSON.parse(graph) : null;
        setDoughnutChartData(graphJSON);
        if (Boolean(graphJSON)) {
          if (graphJSON?.total === 0) {
            setIsEmptyData(true);
          }
        }
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleUpdateFolderDetails = (values: IChecklistItem) => {
    const { id, name, description, levelNo } = values;
    fireUpdateFolderApi({
      variables: {
        payload: {
          projectId: params?.project_id,
          checklistId: params?.checklist_id,
          id,
          name,
          description,
          levelNo,
        },
      },
    });
  };

  useEffect(() => {
    setEditMode(false);
    setIsEmptyData(false);
    setReadMoreDescription(true);
  }, [id]);

  useEffect(() => {
    setLoading(gettingBarChart || loading || updating);
  }, [gettingBarChart, loading, setLoading, updating]);

  const data = useMemo(
    () => ({
      labels: ["Pending", "Completed", "Accepted", "Rejected", "Not Required"],
      datasets: [
        {
          data: isEmptyData
            ? [100]
            : [
                doughnutChartData?.pending || 0,
                doughnutChartData?.completed || 0,
                doughnutChartData?.accepted || 0,
                doughnutChartData?.rejected || 0,
                doughnutChartData?.not_required || 0,
              ],
          backgroundColor: isEmptyData
            ? ["#e6e6e6"]
            : ["#FFA027", "#073577", "#409091", "#E3323B", "#910C00"],
          borderWidth: 0,
        },
      ],
    }),
    [
      doughnutChartData?.accepted,
      doughnutChartData?.completed,
      doughnutChartData?.not_required,
      doughnutChartData?.pending,
      doughnutChartData?.rejected,
      isEmptyData,
    ]
  );

  return (
    <Formik
      initialValues={checklistItemDetails}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required("Please enter the folder name")
          .matches(/^[^/]*$/, "Folder name cannot contain '/'"),
      })}
      onSubmit={handleUpdateFolderDetails}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        handleSubmit,
      }) => {
        return (
          <Box sx={{ p: 2.5 }}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {editMode ? (
                    <Box sx={styles.flex_Acenter}>
                      <TextField
                        id="levelNo"
                        value={values?.levelNo}
                        onChange={handleChange}
                        size="small"
                        inputProps={{
                          style: {
                            ...styles.input_field,
                            backgroundColor: "#fff",
                          },
                        }}
                        sx={{
                          width: 70,
                          "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            borderRadius: "8px",
                          },
                        }}
                      />
                      <TextField
                        id="name"
                        value={values?.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                        inputProps={{
                          style: {
                            ...styles.input_field,
                            backgroundColor: "#fff",
                          },
                        }}
                        sx={{
                          ml: 0.5,
                          "& .MuiInputBase-root.MuiOutlinedInput-root": {
                            borderRadius: "8px",
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: colors.primaryBlack,
                        fontWeight: 600,
                        maxWidth: "90%",
                        wordBreak: "break-word",
                      }}
                    >
                      {Boolean(values?.levelNo)
                        ? `${values?.levelNo} - ${values?.name}`
                        : values?.name}
                    </Typography>
                  )}
                  <Box>
                    {Boolean(ROLE_PERMISSIONS) &&
                    deliveryEditIndex !== undefined &&
                    ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
                      ?.DELIVERY_EDIT ? (
                      editMode ? (
                        <IconButton
                          size="small"
                          onClick={() => {
                            // setEditMode(false);
                            handleSubmit();
                          }}
                          // sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                        >
                          <CheckOutlined
                            sx={{ fontSize: 18, color: "#909090" }}
                          />
                        </IconButton>
                      ) : (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            size="small"
                            onClick={() => setEditMode(true)}
                            // sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                          >
                            <ModeEditOutlineOutlined
                              sx={{ fontSize: 18, color: "#909090" }}
                            />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => setDeleteModal(true)}
                            // sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                          >
                            <img
                              src={images.DELETE_ICON_NEW}
                              alt="delete"
                              height={15}
                            />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => setSelectedChecklistItem(null)}
                            // sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                          >
                            <Close sx={{ fontSize: 18, color: "#909090" }} />
                          </IconButton>
                        </Box>
                      )
                    ) : (
                      <IconButton
                        size="small"
                        onClick={() => setSelectedChecklistItem(null)}
                        // sx={{ ":hover": { backgroundColor: "#41B19931" } }}
                      >
                        <Close sx={{ fontSize: 18, color: "#909090" }} />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Grid>
              {editMode && touched?.name && (
                <Typography sx={[styles.error_text, { ml: 2.5 }]}>
                  {errors?.name}
                </Typography>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {editMode ? (
                  <TextField
                    id="description"
                    size="small"
                    value={values.description}
                    onChange={handleChange}
                    fullWidth
                    inputProps={{
                      style: {
                        ...styles.input_field,
                        backgroundColor: "#fff",
                      },
                    }}
                    multiline
                    minRows={3}
                    maxRows={3}
                    sx={{
                      "& .MuiInputBase-root.MuiOutlinedInput-root": {
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                      },
                    }}
                  />
                ) : (
                  <Box sx={{ px: 1.5 }}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: colors.primaryBlack,
                        textAlign: "justify",
                        wordBreak: "break-word",
                      }}
                    >
                      {readMoreDescription
                        ? truncateText(values.description, 300)
                        : values.description}
                      {readMoreDescription &&
                        values.description.length > 300 && (
                          <span
                            style={{ cursor: "pointer", color: "#41B199" }}
                            onClick={() =>
                              setReadMoreDescription(!readMoreDescription)
                            }
                          >
                            &nbsp;read more
                          </span>
                        )}
                      {!readMoreDescription &&
                        values.description.length > 300 && (
                          <span
                            style={{ cursor: "pointer", color: "#41B199" }}
                            onClick={() =>
                              setReadMoreDescription(!readMoreDescription)
                            }
                          >
                            &nbsp;read less
                          </span>
                        )}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{ px: 1.5, display: "flex", alignItems: "center", mt: 2 }}
                >
                  <Box sx={{ flexGrow: 1, mr: 6 }}>
                    <Divider />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 600,
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Folder Status
                  </Typography>
                  <Box sx={{ flexGrow: 1, ml: 6 }}>
                    <Divider />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="chart-container"
                    style={{ position: "relative" }}
                  >
                    <Doughnut
                      data={data}
                      options={{
                        backgroundColor: " red",
                        plugins: {
                          legend: {
                            display: false,
                            position: "bottom",
                            labels: {
                              font: { size: 11, family: "Roboto" },
                              boxWidth: 10,
                              boxHeight: 10,
                              color: colors.primaryBlack,
                            },
                          },
                          tooltip: {
                            displayColors: false,
                            titleAlign: "center",
                            bodySpacing: 0,
                            callbacks: {
                              title: (tooltipItems: TooltipItem<any>[]) => {
                                return `${tooltipItems[0].label}: ${tooltipItems[0].formattedValue}`;
                              },
                            },
                            bodyFont: {
                              size: 0,
                            },
                          },
                        },
                        radius: 90,
                        onHover: (event: ChartEvent, elements: any) => {
                          if (
                            event?.native?.target instanceof HTMLCanvasElement
                          ) {
                            const canvas = event.native.target;
                            canvas.style.cursor =
                              elements.length > 0 ? "pointer" : "default";
                          }
                        },
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 125,
                        left: 115,
                      }}
                    >
                      <Typography
                        sx={{
                          color: `${colors.primaryBlack}80`,
                          fontWeight: 600,
                          fontSize: "11px",
                        }}
                        textAlign="center"
                      >
                        {`${
                          Boolean(doughnutChartData) &&
                          doughnutChartData?.total > 0
                            ? Math.round(
                                (doughnutChartData?.completed /
                                  doughnutChartData?.total) *
                                  100
                              )
                            : "0"
                        }%`}
                      </Typography>
                      <Typography
                        sx={{
                          color: `${colors.primaryBlack}80`,
                          fontWeight: 600,
                          fontSize: "11px",
                        }}
                        textAlign="center"
                      >
                        Completed
                      </Typography>
                    </Box>
                  </div>
                </Box>
              </Grid>
              {/* <Grid item lg={6} md={6} sm={12} xs={12}>
          <LinearProgress
            variant="determinate"
            value={15}
            sx={{
              height: 7,
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: "#FFA027",
              },
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: "#B6BCC320",
                borderRadius: "7px",
              },
            }}
          />
          <Typography sx={{ fontSize: "10px", color: colors.primaryBlack }}>
            Pending: 15
          </Typography>
          <LinearProgress
            variant="determinate"
            value={15}
            sx={{
              mt: 1,
              height: 7,
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: "#073577",
              },
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: "#B6BCC320",
                borderRadius: "7px",
              },
            }}
          />
          <Typography sx={{ fontSize: "10px", color: colors.primaryBlack }}>
            Completed: 15
          </Typography>
          <LinearProgress
            variant="determinate"
            value={30}
            sx={{
              mt: 1,
              height: 7,
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: "#409091",
              },
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: "#B6BCC320",
                borderRadius: "7px",
              },
            }}
          />
          <Typography sx={{ fontSize: "10px", color: colors.primaryBlack }}>
            Accepted: 30
          </Typography>
          <LinearProgress
            variant="determinate"
            value={20}
            sx={{
              mt: 1,
              height: 7,
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: "#E3323B",
              },
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: "#B6BCC320",
                borderRadius: "7px",
              },
            }}
          />
          <Typography sx={{ fontSize: "10px", color: colors.primaryBlack }}>
            Rejected: 20
          </Typography>
          <LinearProgress
            variant="determinate"
            value={20}
            sx={{
              mt: 1,
              height: 7,
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: "#910C00",
              },
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: "#B6BCC320",
                borderRadius: "7px",
              },
            }}
          />
          <Typography sx={{ fontSize: "10px", color: colors.primaryBlack }}>
            Not Required: 20
          </Typography>
        </Grid> */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{ px: 1.5, display: "flex", alignItems: "center", mt: 2 }}
                >
                  <Box sx={{ flexGrow: 1, mr: 6 }}>
                    <Divider />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 600,
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Comments
                  </Typography>
                  <Box sx={{ flexGrow: 1, ml: 6 }}>
                    <Divider />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  {Boolean(ROLE_PERMISSIONS) &&
                  deliveryEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
                    ?.DELIVERY_EDIT ? (
                    <Typography
                      sx={{
                        textAlign: "right",
                        fontSize: "12px",
                        color: `${colors.secondaryBlack}40`,
                      }}
                    >
                      {`Last updated: ${
                        values.modifiedDate
                          ? moment(values.modifiedDate).format("DD MMMM YYYY")
                          : ""
                      }`}
                    </Typography>
                  ) : Boolean(values?.note) ? (
                    <Typography
                      sx={{
                        textAlign: "right",
                        fontSize: "12px",
                        color: `${colors.secondaryBlack}40`,
                      }}
                    >
                      {`Last updated: ${
                        values.modifiedDate
                          ? moment(values.modifiedDate).format("DD MMMM YYYY")
                          : ""
                      }`}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {Boolean(ROLE_PERMISSIONS) &&
                  deliveryEditIndex !== undefined &&
                  ROLE_PERMISSIONS[0]?.permissions[deliveryEditIndex]
                    ?.DELIVERY_EDIT ? (
                    <TextField
                      id="note"
                      value={values.note}
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      inputProps={{
                        style: {
                          ...styles.input_field,
                          backgroundColor: "#fff",
                        },
                      }}
                      multiline
                      minRows={values.note ? 2 : 1}
                      maxRows={4}
                      placeholder="Write a comment..."
                      sx={{
                        "& .MuiInputBase-root.MuiOutlinedInput-root": {
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                        },
                      }}
                      InputProps={{
                        ...(values.note && {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{
                                  backgroundColor: colors.primaryBlack,
                                  "&:hover": {
                                    backgroundColor: `${colors.primaryBlack}80`,
                                  },
                                }}
                                onClick={() => {
                                  fireUpdateFolderApi({
                                    variables: {
                                      payload: {
                                        projectId: params?.project_id,
                                        checklistId: params?.checklist_id,
                                        id,
                                        note: values.note,
                                      },
                                    },
                                  });
                                }}
                              >
                                <Send color="secondary" sx={{ fontSize: 18 }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }),
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: colors.primaryBlack,
                        wordBreak: "break-word",
                      }}
                    >
                      {values.note}
                    </Typography>
                  )}
                </Box>
              </Grid>
              {deleteModal && (
                <DeleteChecklistItemModal
                  open={deleteModal}
                  setOpen={setDeleteModal}
                  selectedChecklistItem={checklistItemDetails?.id}
                  selectedChecklistItemName={checklistItemDetails?.name}
                  setSelectedChecklistItem={setSelectedChecklistItem}
                  refetch={refetchTableData}
                />
              )}
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
}

export default ChecklistItemFolderDetails;
