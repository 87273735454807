import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Cancel, Close } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { IArr, IModalProps } from "models/common";
import { IRaiseIssueFields } from "models/issues";
import { IssuePriorityArr, IssueRelatedToArr } from "utils/constants";
import {
  GET_ISSUE_TYPES,
  GET_PROJECT_USERS,
  RAISE_ISSUE,
} from "graphql/issues";
import { GET_PROJECT_NAME_BREADCRUMB } from "graphql/projects";
import { GET_PROFILE_PICTURE } from "graphql/users";
import { StorageConstants } from "utils/storage";
import { useStore } from "utils/store";
import { BlackActionContainedButton } from "common/Buttons";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { ProjectDetailsStyles as styles } from "../styles";

interface IUArr {
  id: string;
  fullName: string;
}

interface IRaiseIssueModalProps extends IModalProps {}

function RaiseIssueModal(props: IRaiseIssueModalProps) {
  const { open, setOpen } = props;

  const projectId = useParams();
  const { setLoading } = useStore();

  const [projectName, setProjectName] = useState("");
  const [raisedByName, setRaisedByName] = useState("");
  const [issueTypeArr, setIssueTypeArr] = useState<IArr[]>([]);
  const [assignedToArr, setAssignedToArr] = useState<IUArr[]>([]);

  const { loading: gettingProjectTitle } = useQuery(
    GET_PROJECT_NAME_BREADCRUMB,
    {
      variables: {
        id: projectId?.project_id,
      },
      skip: !projectId?.project_id,
      onCompleted: (data) => {
        const { project } = data;
        const { status, project: rawProject } = project;
        if (status) {
          setProjectName(rawProject.name);
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  const { loading: raisedByLoading } = useQuery(GET_PROFILE_PICTURE, {
    variables: {
      id: sessionStorage.getItem(StorageConstants.LOGGED_IN_USER_ID),
    },
    onCompleted: (data) => {
      const { user } = data;
      const { fullName } = user;
      setRaisedByName(Boolean(fullName) ? fullName : "");
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: loadingTypes } = useQuery(GET_ISSUE_TYPES, {
    onCompleted: (data) => {
      const { issueTypes } = data;
      if (Boolean(issueTypes)) {
        setIssueTypeArr(issueTypes);
      } else {
        setIssueTypeArr([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const { loading: loadingUsers } = useQuery(GET_PROJECT_USERS, {
    variables: { project_id: projectId?.project_id },
    skip: !projectId?.project_id,
    onCompleted: (data) => {
      const { getProjectUsers } = data;
      const { status, projectUsers } = getProjectUsers;
      if (status) {
        setAssignedToArr(projectUsers);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireRaiseIssueApi, { loading: raising }] = useMutation(RAISE_ISSUE, {
    onCompleted: (data) => {
      setLoading(false);
      const { raiseIssue } = data;
      const { status, message } = raiseIssue;
      handleClose();
      if (status) {
        toast.success(message, { delay: 10 });
      } else {
        toast.error(message, { delay: 10 });
      }
    },
  });

  const handleRaiseIssue = (values: IRaiseIssueFields) => {
    const { files, ...rest } = values;
    fireRaiseIssueApi({
      variables: {
        payload: { projectId: projectId?.project_id, ...rest },
        ...(files.length > 0 && { documents: files }),
      },
    });
  };

  useEffect(() => {
    setLoading(
      gettingProjectTitle ||
        loadingTypes ||
        loadingUsers ||
        raisedByLoading ||
        raising
    );
  }, [
    gettingProjectTitle,
    loadingTypes,
    loadingUsers,
    raisedByLoading,
    raising,
    setLoading,
  ]);

  return (
    <Formik
      initialValues={{
        issueRelatedTo: "",
        issueTypeId: "",
        assignedToId: "",
        priority: "",
        description: "",
        files: [],
      }}
      onSubmit={handleRaiseIssue}
      validationSchema={Yup.object().shape({
        issueRelatedTo: Yup.string().required(
          "Please mention what the issue is related to"
        ),
        issueTypeId: Yup.string().required("Please select an issue type"),
        assignedToId: Yup.string().required("Please select an issue assignee"),
        priority: Yup.string().required("Please select the priority"),
        description: Yup.string().required("Please describe the issue"),
      })}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
          PaperProps={{ sx: { borderRadius: "24px", overflow: "hidden" } }}
        >
          <Box
            sx={{
              py: 2,
              backgroundColor: "#B6BCC325",
              position: "relative",
              px: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  fontSize: "13px",
                  color: colors.primaryBlack,
                  fontWeight: 600,
                }}
              >
                Raise Issue
              </Typography>
            </Box>
          </Box>
          <Box sx={{ position: "absolute", top: 10, right: 10 }}>
            <IconButton size="small" onClick={handleClose}>
              <Close sx={{ color: "#00000055" }} fontSize="small" />
            </IconButton>
          </Box>
          <Box
            sx={{
              pt: 3,
              px: 4,
              pb: 2,
              maxHeight: "calc(100vh - 120px)",
              overflow: "auto",
            }}
          >
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>Project Name</Typography>
                <TextField
                  value={projectName}
                  size="small"
                  fullWidth
                  disabled
                  inputProps={{ style: styles.input_field }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>
                  Issue Related to <span style={{ color: "#D3010E" }}>*</span>
                </Typography>
                <Autocomplete
                  id="issueRelatedTo"
                  disableClearable
                  value={
                    IssueRelatedToArr.find(
                      (x) => x.id === values.issueRelatedTo
                    ) || { id: "", name: "" }
                  }
                  onChange={(_, newValue) =>
                    setFieldValue("issueRelatedTo", newValue.id)
                  }
                  onBlur={handleBlur}
                  size="small"
                  fullWidth
                  options={IssueRelatedToArr}
                  getOptionLabel={(opt) => opt.name}
                  sx={styles.input_field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          ...styles.input_field,
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  )}
                />
                {touched?.issueRelatedTo && errors?.issueRelatedTo && (
                  <Typography sx={styles.error_text}>
                    {errors?.issueRelatedTo}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>
                  Select Issue Type <span style={{ color: "#D3010E" }}>*</span>
                </Typography>
                <Autocomplete
                  id="issueTypeId"
                  disableClearable
                  value={
                    issueTypeArr.find((x) => x.id === values.issueTypeId) || {
                      id: "",
                      name: "",
                    }
                  }
                  onChange={(_, newValue) =>
                    setFieldValue("issueTypeId", newValue.id)
                  }
                  onBlur={handleBlur}
                  size="small"
                  fullWidth
                  options={issueTypeArr}
                  getOptionLabel={(opt) => opt.name}
                  sx={styles.input_field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          ...styles.input_field,
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  )}
                />
                {touched?.issueTypeId && errors?.issueTypeId && (
                  <Typography sx={styles.error_text}>
                    {errors?.issueTypeId}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>Raised by</Typography>
                <TextField
                  size="small"
                  value={raisedByName}
                  fullWidth
                  disabled
                  inputProps={{ style: styles.input_field }}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>
                  Assign To <span style={{ color: "#D3010E" }}>*</span>
                </Typography>
                <Autocomplete
                  id="assignedToId"
                  disableClearable
                  value={
                    assignedToArr.find((x) => x.id === values.assignedToId) || {
                      id: "",
                      fullName: "",
                    }
                  }
                  onChange={(_, newValue) =>
                    setFieldValue("assignedToId", newValue.id)
                  }
                  onBlur={handleBlur}
                  size="small"
                  fullWidth
                  options={assignedToArr}
                  getOptionLabel={(opt) => opt.fullName}
                  sx={styles.input_field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          ...styles.input_field,
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  )}
                />
                {touched?.assignedToId && errors?.assignedToId && (
                  <Typography sx={styles.error_text}>
                    {errors?.assignedToId}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Typography sx={styles.field_label}>
                  Priority <span style={{ color: "#D3010E" }}>*</span>
                </Typography>
                <Autocomplete
                  id="priority"
                  disableClearable
                  value={
                    IssuePriorityArr.find((x) => x.id === values.priority) || {
                      id: "",
                      name: "",
                    }
                  }
                  onChange={(_, newValue) =>
                    setFieldValue("priority", newValue.id)
                  }
                  onBlur={handleBlur}
                  size="small"
                  fullWidth
                  options={IssuePriorityArr}
                  getOptionLabel={(opt) => opt.name}
                  sx={styles.input_field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          ...styles.input_field,
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                  )}
                />
                {touched?.priority && errors?.priority && (
                  <Typography sx={styles.error_text}>
                    {errors?.priority}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography sx={styles.field_label}>
                  Issue Description <span style={{ color: "#D3010E" }}>*</span>
                </Typography>
                <TextField
                  id="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  fullWidth
                  minRows={4}
                  maxRows={4}
                  inputProps={{
                    style: {
                      ...styles.input_field,
                      backgroundColor: "transparent",
                    },
                  }}
                  multiline
                  sx={{
                    "& .MuiInputBase-root.MuiOutlinedInput-root": {
                      backgroundColor: "#e6e6e640",
                    },
                  }}
                />
                {touched?.description && errors?.description && (
                  <Typography sx={styles.error_text}>
                    {errors?.description}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography sx={styles.field_label}>Attach Files</Typography>
                <Upload
                  beforeUpload={(_, files: RcFile[]) => {
                    if (values.files.length > 0) {
                      let tempFiles: RcFile[] = [...values.files];
                      tempFiles = [...files, ...tempFiles];
                      setFieldValue("files", tempFiles);
                    } else {
                      setFieldValue("files", files);
                    }
                    return Promise.resolve();
                  }}
                  multiple
                >
                  <Box sx={styles.upload_file_container}>
                    <img src={images.UPLOAD} alt="upload" />
                    <Typography sx={styles.upload_text}>
                      Drag & drop, or{" "}
                      <span style={{ color: colors.primary }}>browse</span> your
                      files
                    </Typography>
                    {/* <Typography
                                    sx={[
                                      styles.upload_text,
                                      { fontSize: "13px", color: colors.grey },
                                    ]}
                                  >
                                    Supported files type:{" "}
                                    <span style={{ color: `${colors.primary}90` }}>
                                      .PDF
                                    </span>
                                  </Typography> */}
                  </Box>
                </Upload>
              </Grid>
              {Boolean(values.files) &&
                values.files.map((cFile: RcFile, index: number) => (
                  <Grid key={cFile.uid} item lg={12} md={12} sm={12} xs={12}>
                    <Box
                      sx={[
                        styles.flex_JCsb_Acenter,
                        styles.uploaded_file_container,
                      ]}
                    >
                      <Box sx={styles.flex_Acenter}>
                        {/* <img src={images.PDF} alt="pdf" /> */}
                        <Typography sx={styles.ml1}>
                          {cFile ? (cFile as any)["name"] : ""}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={() => {
                          let tempFiles = [...values.files];
                          tempFiles.splice(index, 1);
                          setFieldValue("files", [...tempFiles]);
                        }}
                      >
                        <Cancel sx={{ color: "#FF1640" }} />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <BlackActionContainedButton
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: "20px",
                      fontSize: "13px",
                      fontWeight: 600,
                      width: "120px",
                    }}
                    disableElevation
                    disableRipple
                    disabled={!isValid}
                    onClick={() => handleSubmit()}
                  >
                    Raise Issue
                  </BlackActionContainedButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      )}
    </Formik>
  );
}

export default RaiseIssueModal;
