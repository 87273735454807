import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { IArr } from "models/common";
import Table from "common/Table";
import { useProjectDetailsTabs } from "hooks/useProjectDetailsTabs";
import { RouteNames } from "routes/routeNames";
import { GET_ALL_INDEXES } from "graphql/indexes";
import { useStore } from "utils/store";
import { IIndex } from "models/indexes";
import { getPermissions } from "permissions/utils";
import { images } from "assets/images";
import { colors } from "theme/colors";

import AddEditIndexModal from "./AddEditIndexModal";
import Header from "./Header";
import TableComponent from "./TableComponent";
import DeleteIndexModal from "./DeleteIndexModal";
import { ProjectDetailsStyles as styles } from "../styles";
import ProjectDetailsTabs from "..";

export function Indexes() {
  const projectDetailsTab = useProjectDetailsTabs();
  const navigate = useNavigate();
  const projectId = useParams();
  const { setLoading } = useStore();
  const boxRef = useRef(null);
  const ROLE_PERMISSIONS = useMemo(() => getPermissions(), []);
  const foldersViewIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_VIEW")
      ),
    [ROLE_PERMISSIONS]
  );
  const foldersEditIndex = useMemo(
    () =>
      ROLE_PERMISSIONS[0]?.permissions?.findIndex((x: any) =>
        x.hasOwnProperty("FOLDERS_EDIT")
      ),
    [ROLE_PERMISSIONS]
  );

  const [view, setView] = useState(0);
  const [indexes, setIndexes] = useState<IIndex[]>([]);
  const [indexCount, setIndexCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [addEditIndexModal, setAddEditIndexModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedIndexName, setSelectedIndexName] = useState("");
  const [sortBy, setSortBy] = useState("name");

  const { loading, refetch, fetchMore } = useQuery(GET_ALL_INDEXES, {
    variables: {
      limit: 50,
      page: 1,
      filter: {
        project_id: projectId?.project_id,
        ...(Boolean(searchText) && { name__icontains: searchText }),
      },
      ...(Boolean(sortBy) && { sorted: sortBy }),
    },
    skip:
      !projectId?.project_id ||
      !(
        Boolean(ROLE_PERMISSIONS) &&
        foldersViewIndex !== undefined &&
        ROLE_PERMISSIONS[0]?.permissions[foldersViewIndex]?.FOLDERS_VIEW
      ),
    onCompleted: (data) => {
      setLoading(false);
      const { getRegisters } = data;
      const { status, registers, count } = getRegisters;
      if (status) {
        setIndexes(registers);
        setIndexCount(count);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Folder Name",
        minWidth: 220,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Box sx={styles.flex_Acenter}>
            <img src={images.FOLDER_NEW} alt="index" style={{ height: 20 }} />
            <Typography
              sx={[styles.cell_text, { ml: 1, wordBreak: "break-word" }]}
            >
              {params?.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: "templateTypes",
        headerName: "Template Type",
        minWidth: 350,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => {
          let tempTypes: IArr[] = [];
          tempTypes = Boolean(params?.value)
            ? params?.value?.map((x: any) => ({ id: x.id, name: x.name }))
            : [];
          return <TableComponent tempTypes={tempTypes} />;
        },
      },
      {
        field: "items",
        headerName: "Items",
        headerAlign: "center",
        minWidth: 120,
        flex: 1,
        sortable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, { color: "#00000070" }]}>
            {params?.value || "0"}
          </Typography>
        ),
      },
      {
        field: "modifiedDate",
        headerName: "Last Modified",
        minWidth: 120,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any>) => (
          <Typography sx={[styles.cell_text, { color: "#00000070" }]}>
            {moment(params?.value).format("DD MMMM YYYY")}
          </Typography>
        ),
      },
      ...(Boolean(ROLE_PERMISSIONS) &&
      foldersEditIndex !== undefined &&
      ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT
        ? [
            {
              field: "actions",
              type: "actions",
              headerName: "",
              sortable: false,
              width: 110,
              getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                  showInMenu
                  label="Edit"
                  onClick={() => {
                    setSelectedIndex(params.row.id);
                    setAddEditIndexModal(true);
                  }}
                  sx={styles.actions_text}
                />,
                <GridActionsCellItem
                  showInMenu
                  label="Delete"
                  onClick={() => {
                    setSelectedIndex(params.row.id);
                    setSelectedIndexName(params.row.name);
                    setDeleteModal(true);
                  }}
                  sx={styles.actions_text}
                />,
              ],
            },
          ]
        : []),
    ],
    [ROLE_PERMISSIONS, foldersEditIndex]
  );

  useEffect(() => {
    const handleScroll = async () => {
      // Check if the user has scrolled to the bottom of the page with a tolerance
      const box = boxRef.current;
      const isAtBottom =
        (box as any).scrollHeight - (box as any).scrollTop <=
        (box as any).clientHeight + 100;

      if (!isAtBottom) {
        // If not at the bottom, return early
        return;
      }

      if (indexes.length < indexCount) {
        try {
          setLoading(true);
          await fetchMore({
            variables: {
              page: Math.ceil(indexes.length / 50) + 1,
              limit: 50,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEntries = fetchMoreResult.getRegisters.registers;
              return {
                getRegisters: {
                  ...fetchMoreResult?.getRegisters,
                  registers: [...indexes, ...newEntries],
                },
              };
            },
          });
        } catch (error) {
          console.error("ERROR", error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Add scroll event listener
    const box = boxRef.current;
    if (box) {
      (box as any).addEventListener("scroll", handleScroll);
    }

    // Clean up event listener on component unmount
    return () => {
      if (box) {
        (box as any).removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchMore, setLoading, indexes, indexCount]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    projectDetailsTab.setSelectedTab(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectDetailsTabs>
      <Grid container spacing={2.5}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2.25 }}>
          <Header
            setAddEditIndexModal={setAddEditIndexModal}
            view={view}
            setView={setView}
            setSearchText={setSearchText}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            ref={boxRef}
            sx={{
              height: "calc(100vh - 245px)",
              overflow: "auto",
              position: "relative",
              mx: -3,
              mb: -3,
            }}
          >
            {indexes.length === 0 &&
            !(
              Boolean(ROLE_PERMISSIONS) &&
              foldersEditIndex !== undefined &&
              ROLE_PERMISSIONS[0]?.permissions[foldersEditIndex]?.FOLDERS_EDIT
            ) ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    height: "200px",
                    width: "550px",
                    backgroundColor: "#BFBDBC22",
                    borderRadius: " 24px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: " column",
                  }}
                >
                  <img src={images.FOLDER_NEW_BLACK} alt="folder" width={25} />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: colors.primaryBlack,
                      my: 2,
                      fontWeight: 600,
                    }}
                  >
                    Its empty here!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: `${colors.primaryBlack}70`,
                    }}
                  >
                    Please reach out to your admin for access to create a
                    document folder.
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Table
                rows={indexes}
                columns={columns}
                showCellVerticalBorder={false}
                showColumnVerticalBorder={false}
                disableColumnMenu
                onRowClick={(params: GridRowParams, _, __) => {
                  setSelectedIndex(params.row.id);
                  navigate(
                    `${RouteNames.PROJECTS}/${projectId?.project_id}/indexes/${params.row.id}`
                  );
                }}
                sx={{
                  ...styles.table,
                  "& .MuiDataGrid-columnHeaders": {
                    border: "none",
                    backgroundColor: "#B6BCC320",
                  },
                  "& .MuiDataGrid-row": {
                    borderBottom: "1px #e6e6e6 solid",
                    cursor: "pointer",
                    borderRadius: 0,
                  },
                  borderTop: "1px #e6e6e6 solid",
                  borderRadius: 0,
                }}
                hideFooter
              />
            )}
          </Box>
        </Grid>
        {addEditIndexModal && (
          <AddEditIndexModal
            open={addEditIndexModal}
            setOpen={setAddEditIndexModal}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            refetchTableData={refetch}
          />
        )}
        {deleteModal && (
          <DeleteIndexModal
            open={deleteModal}
            setOpen={setDeleteModal}
            selectedIndexName={selectedIndexName}
            setSelectedIndexName={setSelectedIndexName}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            refetch={refetch}
          />
        )}
      </Grid>
    </ProjectDetailsTabs>
  );
}
