import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { colors } from "./colors";
import { stepIconClasses } from "@mui/material/StepIcon";
import { listItemButtonClasses, stepLabelClasses } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    fontFamily: "Roboto",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: colors.secondary,
            width: "0.4rem",
            height: "0.4rem",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: `${colors.grey}70`,
            width: "0.4rem",
            height: "0.4rem",
          },
        },
      },
    },
    MuiTypography: { styleOverrides: { root: { fontFamily: "Roboto" } } },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          [`&.${stepLabelClasses.completed}`]: { color: colors.success },
          [`&.${stepLabelClasses.active}`]: { color: colors.primary },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          [`&.${stepIconClasses.completed}`]: { color: colors.success },
          fontSize: "30px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: "24px",
          "&:hover": { backgroundColor: "#41B19995" },
          [`&.${listItemButtonClasses.selected}`]: {
            backgroundColor: "#41B19931",
            "&:hover": { backgroundColor: "#41B19995" },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          color: colors.secondaryBlack,
          fontSize: "13px",
          '&[aria-selected="true"]': {
            backgroundColor: "#41B19931 !important",
            "&:hover": {
              backgroundColor: "#41B19995 !important",
            },
          },
          "&:hover": {
            backgroundColor: "#B6BCC330 !important",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input::placeholder": {
            color: "#BFBDBC", // Change this to the desired color for the placeholder text
          },
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: `${colors.primaryBlack}30`,
          },
          "&:hover:not(.Mui-focused)": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.primaryBlack,
            },
          },
          "& .MuiOutlinedInput-root.Mui-disabled": {
            borderColor: `${colors.primaryBlack}30`,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: `${colors.primaryBlack}`,
              borderWidth: 1,
            },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
