import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { IModalProps } from "models/common";
import { ContractDocxLabels, GlobalLabels } from "common/AppConstants";
import { useStore } from "utils/store";
import { GET_USERS_ARR } from "graphql/users";
import { COLLABORATE_CONTRACT } from "graphql/contracts";

import { ContractDocxStyles as styles } from "./styles";

interface IUserArr {
  id: string;
  name: string;
  email: string;
}

interface IInviteFields {
  userId: string;
  comment: string;
}

function CollabrationModal(props: IModalProps) {
  const { open, setOpen } = props;

  const { setLoading } = useStore();
  const params = useParams();

  const [usersArr, setUsersArr] = useState<IUserArr[]>([]);

  const { loading } = useQuery(GET_USERS_ARR, {
    variables: { filter: { projects__project__id__in: [params?.project_id] } },
    skip: !Boolean(params?.project_id),
    onCompleted: (data) => {
      // setLoading(false);
      const { users } = data;
      const { count, users: rawUsers } = users;
      if (count > 0) {
        setUsersArr(
          rawUsers.map((x: any) => ({
            id: x.id,
            name: x.fullName,
            email: x.email,
          }))
        );
      } else {
        setUsersArr([]);
      }
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const handleClose = () => setOpen(false);

  const handleDialogClose = (_: any, reason: string) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      handleClose();
    }
  };

  const [fireSendInviteApi, { loading: sending }] = useMutation(
    COLLABORATE_CONTRACT,
    {
      onCompleted: (data) => {
        setLoading(false);
        const { assignDocumentToUser } = data;
        const { status, message } = assignDocumentToUser;
        handleClose();
        if (status) {
          toast.success(message, { delay: 10 });
        } else {
          toast.error(message, { delay: 10 });
        }
      },
    }
  );

  const handleSendInvite = (values: IInviteFields) => {
    fireSendInviteApi({
      variables: {
        payload: {
          projectId: params?.project_id,
          fetchDocumentId: params?.contract_id,
          userId: values.userId,
          comment: values.comment,
        },
      },
    });
  };

  useEffect(() => {
    setLoading(loading || sending);
  }, [loading, sending, setLoading]);

  return (
    <Formik
      initialValues={{ userId: "", comment: "" }}
      validationSchema={Yup.object().shape({
        userId: Yup.string().required("Please select a project user"),
        comment: Yup.string(),
      })}
      onSubmit={handleSendInvite}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        isValid,
        handleSubmit,
      }) => (
        <Dialog open={open} onClose={handleDialogClose} maxWidth="sm" fullWidth>
          <DialogTitle sx={styles.flex_JCsb_Acenter}>
            <Typography sx={styles.modal_header_text}>
              {ContractDocxLabels.COLLAB}
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ p: 3 }} alignItems="center">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.template_name_text}>
                    {ContractDocxLabels.INVITE}{" "}
                    <span style={{ color: "#D3010E" }}>*</span>
                  </Typography>
                  <Autocomplete
                    id="userId"
                    value={
                      usersArr.find((x) => x.id === values.userId) || {
                        id: "",
                        name: "",
                        email: "",
                      }
                    }
                    onChange={(_, newValue) => {
                      setFieldValue("userId", newValue?.id || "");
                    }}
                    onBlur={handleBlur}
                    disableClearable
                    size="small"
                    fullWidth
                    options={usersArr}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props1, opt, _, __) => (
                      <li
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                        {...props1}
                      >
                        <Typography textAlign="center">{opt.name}</Typography>
                        <Typography
                          sx={[styles.field_label, { fontSize: "13px" }]}
                        >{`(${opt.email})`}</Typography>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          style: styles.text_input,
                        }}
                      />
                    )}
                  />
                  {touched?.userId && errors?.userId && (
                    <Typography sx={styles.error_text}>
                      {errors?.userId}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={styles.template_name_text}>
                    Comments
                  </Typography>
                  <TextField
                    id="comment"
                    value={values.comment}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    multiline
                    minRows={3}
                    inputProps={{ style: styles.text_input }}
                  />
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions sx={styles.modal_footer_container}>
            <Button
              variant="contained"
              type="submit"
              disabled={!isValid || Object.keys(touched).length === 0}
              sx={styles.disabled_btn}
              onClick={() => handleSubmit()}
            >
              {GlobalLabels.INVITE}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

export default CollabrationModal;
