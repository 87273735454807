export const images = {
	LOGO: require("./images/logo.png"),
	LOGO_BALLS: require("./images/logo_balls.png"),
	LOGO_SMALL: require("./images/logo_small.svg").default,
	PAGE_NOT_FOUND: require("./images/page_not_found.svg").default,
	NO_INTERNET: require("./images/no_internet.svg").default,
	UNDER_MAINTENANCE: require("./images/under_maintenance.svg").default,
	LAUNCHING_SOON: require("./images/launching_soon.svg").default,
	NOT_AUTHORIZED: require("./images/not_authorized.svg").default,
	NOTIFICATION: require("./images/notifications.svg").default,
	LOGIN_SIGNUP_BG: require("./images/LoginSignUpBg.png"),
	SIGNUP_BG: require("./images/SignUpBg.png"),
	FORGOT_PWD_BG: require("./images/ForgotPwdBg.png"),
	SET_PWD_BG: require("./images/set-password-bg.png"),
	GOOGLE: require("./images/Google.svg").default,
	MICROSOFT: require("./images/Microsoft.svg").default,
	GREEN_TICK: require("./images/green_tick.svg").default,
	OTP_VERIFICATION: require("./images/otp_verify.svg").default,
	DASHBOARD: require("./images/dashboard.png"),
	DASHBOARD_SELECTED: require("./images/dashboard_selected.png"),
	CONTRACTS: require("./images/contracts.svg").default,
	CONTRACTS_SELECTED: require("./images/contracts_selected.svg").default,
	CONTACTS: require("./images/contacts.svg").default,
	CONTACTS_SELECTED: require("./images/contacts_selected.svg").default,
	PROJECTS: require("./images/projects.png"),
	PROJECTS_SELECTED: require("./images/projects_selected.png"),
	AGENTS: require("./images/agents.png"),
	AGENTS_SELECTED: require("./images/agents_selected.png"),
	REPORTS: require("./images/reports.png"),
	REPORTS_SELECTED: require("./images/reports_selected.png"),
	INTEGRATIONS: require("./images/integrations.png"),
	INTEGRATIONS_SELECTED: require("./images/integrations_selected.png"),
	ACCESS: require("./images/access.svg").default,
	ACCESS_SELECTED: require("./images/access_selected.svg").default,
	SUPPORT: require("./images/support.png"),
	SUPPORT_SELECTED: require("./images/support_selected.png"),
	ROLE_MGMT: require("./images/role-management.svg").default,
	ROLE_MGMT_SELECTED: require("./images/role-management-selected.svg").default,
	SETTINGS: require("./images/settings.png"),
	SETTINGS_SELECTED: require("./images/settings_selected.png"),
	TEMPLATES: require("./images/templates.svg").default,
	TEMPLATES_SELECTED: require("./images/templates_selected.svg").default,
	MANAGE_CLIENTS: require("./images/manage-clients.svg").default,
	MANAGE_CLIENTS_SELECTED: require("./images/manage-clients_selected.svg")
		.default,
	PROFILE: require("./images/profile.svg").default,
	LOGOUT: require("./images/logout.svg").default,
	ALERT_RED: require("./images/alert-red.svg").default,
	DELETE_ALERT: require("./images/delete_alert.svg").default,
	CONCEPT: require("./images/concept.svg").default,
	DEVELOPMENT: require("./images/development.svg").default,
	PRE_PRODUCTION: require("./images/pre-production.svg").default,
	PRODUCTION: require("./images/production.svg").default,
	POST_PRODUCTION: require("./images/post-production.svg").default,
	DELIVERED: require("./images/delivered.svg").default,
	ARCHIVED: require("./images/archieved.svg").default,
	DRAFT: require("./images/draft.svg").default,
	REVIEW: require("./images/review.svg").default,
	PENDING: require("./images/pending.svg").default,
	APPROVED: require("./images/approved.svg").default,
	NEGOTIATION: require("./images/negotiation.svg").default,
	SIGNED: require("./images/signed.svg").default,
	ACTIVE: require("./images/active.svg").default,
	ENDED: require("./images/ended.svg").default,
	TOTAL: require("./images/total.svg").default,
	ONGOING: require("./images/ongoing.svg").default,
	COMPLETED: require("./images/completed.svg").default,
	PARTIALLY_EXECUTED: require("./images/partially-executed.svg").default,
	FULLY_EXECUTED: require("./images/fully-executed.svg").default,
	RESCINDED: require("./images/rescinded.svg").default,
	NDA: require("./images/nda.svg").default,
	E_CONTRACTS: require("./images/e-contracts.svg").default,
	WRITTEN_CONTRACTS: require("./images/written-contracts.svg").default,
	VOID_CONTRACTS: require("./images/void-contracts.svg").default,
	VERBAL_CONTRACTS: require("./images/verbal-contracts.svg").default,
	QUASI_CONTRACTS: require("./images/quasi-contracts.svg").default,
	QUIZZ_PROGRAM: require("./images/quizz_program.svg").default,
	PLUS: require("./images/plus.svg").default,
	REEL: require("./images/reel.svg").default,
	ADD_PROJECT: require("./images/add_project.svg").default,
	ADD_PROJECT_USER: require("./images/add_project_user.svg").default,
	ADD_PROJECT_REEL: require("./images/add-project-reel.svg").default,
	BTN_ADD_ICON: require("./images/btn_add_icon.svg").default,
	BTN_UPLOAD_ICON: require("./images/btn_upload_icon.svg").default,
	NOTES: require("./images/notes.svg").default,
	REMINDER: require("./images/reminder.svg").default,
	HISTORY: require("./images/history.svg").default,
	PRIORITY_URGENT: require("./images/priotiy_urgent_flag.svg").default,
	PRIORITY_HIGH: require("./images/priotiy_high_flag.svg").default,
	PRIORITY_NORMAL: require("./images/priotiy_normal_flag.svg").default,
	PRIORITY_LOW: require("./images/priotiy_low_flag.svg").default,
	CAMERA: require("./images/camera.svg").default,
	DELETE: require("./images/delete.svg").default,
	CONTRACT_ICON: require("./images/contract_icon.svg").default,
	CALL: require("./images/call.svg").default,
	EMAIL: require("./images/email.svg").default,
	UPLOAD: require("./images/upload.svg").default,
	ADD_CONTACT_ICON: require("./images/btn_add_contact.svg").default,
	CLIENTS_DASHBOARD: require("./images/clients.svg").default,
	TEMPLATES_DASHBOARD: require("./images/template_dashboard.svg").default,
	TEMPLATE_ICON: require("./images/template_icon.svg").default,
	VERIFY: require("./images/verified.svg").default,
	REAPPROVE: require("./images/reapprove.svg").default,
	ADD_TEMPLATE_ICON: require("./images/add-template-icon.svg").default,
	TEMPLATE_GRID_ICON: require("./images/template_grid_icon.svg").default,
	COMPLEXITY_HIGH: require("./images/complexity_high.svg").default,
	COMPLEXITY_NORMAL: require("./images/complexity_normal.svg").default,
	COMPLEXITY_LOW: require("./images/complexity_low.svg").default,
	BTN_TEAM_ICON: require("./images/add_team_btn_icon.svg").default,
	CONTRACT_FILLABLE_FIELDS: require("./images/contract_fillable_fields.svg")
		.default,
	CONTRACT_FILLABLE_FIELDS_SELECTED:
		require("./images/contract_fillable_fields_selected.svg").default,
	CONTRACT_INFO: require("./images/contract_information.svg").default,
	CONTRACT_INFO_SEELCTED: require("./images/contract_information_selected.svg")
		.default,
	CONTRACT_REMINDER: require("./images/contract_reminder.svg").default,
	CONTRACT_REMINDER_SELECTED:
		require("./images/contract_reminder_selected.svg").default,
	CONTRACT_STATUS: require("./images/contract_status.svg").default,
	CONTRACT_STATUS_SELECTED: require("./images/contract_status_selected.svg")
		.default,
	CONTRACT_VERSION_HISTORY: require("./images/contract_version_history.svg")
		.default,
	CONTRACT_VERSION_HISTORY_SEELCTED:
		require("./images/contract_version_history_selected.svg").default,
	CONTRACT_NOTES: require("./images/contract_notes.svg").default,
	CONTRACT_NOTES_SELECTED: require("./images/contract_notes_selected.svg")
		.default,
	CONTRACT_TRACK_CHANGES: require("./images/contract_track_changes.svg")
		.default,
	CONTRACT_TRACK_CHANGES_SELECTED:
		require("./images/contract_track_changes_selected.svg").default,
	CONTRACT_COLLABRATION: require("./images/contract_collabration.svg").default,
	CONTRACT_COLLABRATION_SELECTED:
		require("./images/contract_collabration_selected.svg").default,
	CONTRACT_COMMENTS: require("./images/contract_comments.svg").default,
	CONTRACT_COMMENTS_SELECTED:
		require("./images/contract_comments_selected.svg").default,
	DOWNLOAD: require("./images/download.svg").default,
	RENAME: require("./images/rename.svg").default,
	DUPLICATE: require("./images/duplicate.svg").default,
	TRASH: require("./images/trash.svg").default,
	RENAME_COLORED: require("./images/rename_colored.svg").default,
	DUPLICATE_COLORED: require("./images/duplicate_colored.svg").default,
	DELETE_COLORED: require("./images/delete_colored.svg").default,
	LOCK: require("./images/lock.svg").default,
	LINK_ICON: require("./images/link-icon.svg").default,
	VERSION_HISTORY: require("./images/version_history.png"),
	NOTES_D: require("./images/notes.png"),
	TRACK_CHANGES: require("./images/track-changes.png"),
	INHOUSE_CONTRACT_ICON: require("./images/in-house-contract-icon.svg")
		.default,
	INHOUSE_CONTRACT_ICON_SELECTED:
		require("./images/in-house-contract-icon-selected.svg").default,
	THIRDPARTY_CONTRACT_ICON: require("./images/third-party-contract-icon.svg")
		.default,
	THIRDPARTY_CONTRACT_ICON_SELECTED:
		require("./images/third-party-contract-icon-selected.svg").default,
	CONTRACT_DOC_ICON: require("./images/contract-icon.svg").default,
	CONTRACT_DOC_ICON_GREY: require("./images/icon-contract-grey.svg").default,
	CONTRACT_UPLOADED_DOC_ICON:
		require("./images/contract-uploaded-doc-icon.svg").default,
	CONTRACT_UPLOADED_DOC_ICON_GREY:
		require("./images/icon-uploaded-doc-grey.svg").default,
	CONTRACT_FOLDER_ICON: require("./images/contract-folder-icon.svg").default,
	BTN_UPLOAD_ICON_BLUE: require("./images/btn_upload_icon_blue.svg").default,
	CLIENT_COUNT_BG: require("./images/client_count_bg.svg").default,
	VARIETY_SHOW_ICON: require("./images/variety_show_icon.svg").default,
	CLIENTS_ICON: require("./images/clients_icon.svg").default,
	NO_RESULTS_FOUND: require("./images/no_results_found.svg").default,
	NO_DATA: require("./images/no_data.svg").default,
	MEDAL: require("./images/medal.svg").default,
	DOWNLOAD_BTN_ICON: require("./images/download-btn-icon.svg").default,
	UPLOAD_BTN_ICON: require("./images/upload_btn_icon.svg").default,
	PDF: require("./images/pdf.svg").default,
	REEL_AVATAR: require("./images/reel_avatar.svg").default,
	FOLDER_AVATAR: require("./images/folder_avatar.svg").default,
	CHECKLIST_AVATAR: require("./images/checklist_avatar.svg").default,
	TEMPLATE_AVATAR: require("./images/template_avatar.svg").default,
	INTEGRATIONS_BLUE: require("./images/integrations_blue.svg").default,
	FEE_CALCULATOR_PROJECT: require("./images/fee_calculator_project.svg")
		.default,
	FEE_CALCULATOR_ARTIST: require("./images/fee_calculator_artist.svg").default,
	FEE_CALCULATOR_FEE: require("./images/fee_calculator_fee.svg").default,
	INDEX_ICON: require("./images/indexes_icon.svg").default,
	GOOGLE_DRIVE: require("./images/google-drive.svg").default,
	DOCUSIGN: require("./images/docusign.svg").default,
	UPLOAD_BTN_ICON_INDEX: require("./images/upload_btn_icon1.svg").default,
	UPLOAD_BTN_ICON_INDEX_GREY: require("./images/icon-upload-grey.svg").default,
	CREATE_BTN_ICON: require("./images/create_btn_icon.svg").default,
	VIEW_BTN_ICON: require("./images/view_btn_icon.svg").default,
	VIEW_BTN_ICON_GREY: require("./images/view-icon-grey.svg").default,
	FILE_LINK_BTN_ICON: require("./images/file_link_btn_icon.svg").default,
	FILE_LINK_BTN_ICON_GREY: require("./images/icon-link-grey.svg").default,
	NO_DATA_DR: require("./images/no_data_DR.svg").default,
	CHECKLIST_EMPTY_ICON: require("./images/checklist_empty.svg").default,
	SCRIPT_ICON: require("./images/script.png"),
	CREATE_PROJECT: require("./images/create_project.png"),
	MENU: require("./images/menu.png"),
	FILM_REEL: require("./images/film-roll.png"),
	FILM_REEL_DARK: require("./images/film-roll_dark.png"),
	DOC_PLACEHOLDER: require("./images/doc.png"),
	AUS_FLAG: require("./images/australia.png"),
	US_FLAG: require("./images/usa.png"),
	UK_FLAG: require("./images/uk.png"),
	THAILAND_FLAG: require("./images/thailand.png"),
	DOWNLOAD_ICON: require("./images/download_icon.png"),
	FOLDER_NEW: require("./images/folder_new.png"),
	FOLDER_NEW_BLACK: require("./images/folder_new_black.png"),
	PDF_NEW: require("./images/pdf_new.png"),
	NEW_DOC_NEW: require("./images/new-document_new.png"),
	DOC_LINKED: require("./images/document_linked.png"),
	COMMENT_ICON: require("./images/comment.png"),
	SHARE_ICON: require("./images/share.png"),
	CONTRACT_ICON_NEW: require("./images/contract_new.png"),
	DOWNLOAD_ICON_NEW: require("./images/download_icon_new.png"),
	DELETE_ICON_NEW: require("./images/delete_icon_new.png"),
	ACCOUNT_ICON_NEW: require("./images/account_icon_new.png"),
	CALENDAR: require("./images/calendar.png"),
	ALERT_NEW: require("./images/alert_new.png"),
	DOCUSIGN_LOGO_NEW: require("./images/docusign_logo.png"),
	NO_CONNECTION: require("./images/no-connection.png"),
	WIDGET: require("./images/widget.png"),
	CALCULATOR: require("./images/calculator.png"),
	SEND_WHITE: require("./images/send_white.png"),
	SEND_GREY: require("./images/send_grey.png"),
	UPLOAD_SCRIPT_NEW: require("./images/upload_script_new.png"),
	CONTRACT_PLACEHOLDER_NEW: require("./images/contract_type_icon_new.png"),
	DOCUMENT_PLACEHOLDER_NEW: require("./images/document_type_icon_new.png"),

	//temporary
	BOSCH: require("./images/bosch.svg").default,
	CAUGHT: require("./images/caught.svg").default,
	TEST_TV_SERIES: require("./images/test_tv_series.svg").default,
	BSU: require("./images/bsu.svg").default,
	STARVILLE: require("./images/starville.svg").default,
	AVATAR: require("./images/avatar.svg").default,
	QR_CODE: require("./images/qr_code.svg").default,
	PROJECT_1: require("./images/project1.png"),
	HUBSPOT: require("./images/hubspot.svg").default,
	NETFLIX: require("./images/netflix.png"),
};
