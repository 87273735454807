import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { RouteNames } from "routes/routeNames";
import { GET_CONTRACT_FILE } from "graphql/contracts";
import { useStore } from "utils/store";
import { images } from "assets/images";
import { colors } from "theme/colors";

import { IDocumentSearchResult } from "./utils";

interface IDocumentSearchCardProps {
  documentData: IDocumentSearchResult;
  handleClose: () => void;
}

const DocumentSearchCard = (props: IDocumentSearchCardProps) => {
  const { documentData, handleClose } = props;

  const navigate = useNavigate();
  const { setLoading } = useStore();

  const [getDocumentFile, { loading: gettingFile }] = useLazyQuery(
    GET_CONTRACT_FILE,
    {
      onCompleted: (data) => {
        const { getDocumentFile } = data;
        const {
          status,
          file,
          fileExists,
          document: rawDocument,
        } = getDocumentFile;
        if (status) {
          if (fileExists) {
            if (rawDocument?.isUpload) {
              var hiddenElement = document.createElement("a");
              hiddenElement.href = file;
              hiddenElement.target = "_blank";
              hiddenElement.download = `${rawDocument?.name}.pdf`;
              hiddenElement.click();
            }
          } else {
            toast.error("No File Found", { delay: 10 });
          }
        }
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    setLoading(gettingFile);
  }, [gettingFile, setLoading]);

  return (
    <Box
      sx={{ cursor: "pointer" }}
      onClick={() => {
        if (documentData.isUpload) {
          getDocumentFile({
            variables: { payload: { documentId: documentData?.id } },
          });
        } else {
          navigate(
            `${RouteNames.PROJECTS}/${documentData?.projectDetails?.id}/contract/${documentData?.id}`
          );
          handleClose();
        }
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {documentData.documentType === "DOCUMENT" ? (
            <img
              src={images.DOCUMENT_PLACEHOLDER_NEW}
              alt="pdf"
              style={{ width: "50px" }}
            />
          ) : (
            <img
              src={images.CONTRACT_PLACEHOLDER_NEW}
              alt="pdf"
              style={{ width: "50px" }}
            />
          )}
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography sx={{ color: colors.primaryBlack, fontSize: "14px" }}>
            {documentData?.name}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: "#00000060", fontSize: "13px" }}>
              {`${documentData?.projectDetails?.name}`}
            </Typography>
            <Box>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  let path = documentData?.path;
                  path = path.slice(0, -1);
                  navigate(
                    `${RouteNames.PROJECTS}/${documentData?.projectDetails?.id}/indexes${path}`
                  );
                }}
              >
                <OpenInNew sx={{ color: "#00000060", fontSize: 15 }} />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentSearchCard;
