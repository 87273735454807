import { colors } from "theme/colors";

export const AccessStyles = {
  filter_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    px: 2.5,
    py: 3.5,
  },
  text_input: { fontSize: "14px", color: colors.primaryBlack },
  flex_aCenter: { display: "flex", alignItems: "center" },
  mr1: { mr: 1 },
  ml1: { ml: 1 },
  avatar: { fontSize: "13px" },
  cell_text: {
    color: colors.primaryBlack,
    fontSize: "13px",
  },
  chip: { color: colors.primary, backgroundColor: `${colors.primary}20` },
  action_text: {
    color: colors.secondaryBlack,
    fontSize: "13px",
    fontWeight: 500,
  },
  flex_JCsb_Acenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modal_header_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  read_chip: { color: "#D30101", backgroundColor: "#D3010E20" },
  write_chip: { color: colors.primary, backgroundColor: `${colors.primary}20` },
  name_container: {
    border: `1px solid ${colors.borderColor}`,
    borderRadius: "8px",
    p: 2,
  },
  name_text: { color: colors.primaryBlack, fontSize: "16px", fontWeight: 500 },
  category_text: { color: colors.grey, fontSize: "13px", fontWeight: 500 },
  flex_JCfe: { display: "flex", justifyContent: "flex-end" },
  my3: { my: 3 },
  flex_Acenter: { display: "flex", alignItems: "center" },
  field_label: {
    color: `${colors.secondaryBlack}70`,
    fontSize: "13px",
    fontWeight: 600,
    mb: 0.5,
  },
  input_field: {
    backgroundColor: "#e6e6e640",
    borderRadius: "6px",
    fontSize: "13px",
    color: colors.primaryBlack,
  },
  error_text: { fontSize: "12px", color: "#D3010E" },
  add_admin_text: {
    color: colors.primaryBlack,
    fontSize: "20px",
    fontWeight: 500,
  },
  table: {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      border: "none",
      borderRadius: "10px",
      backgroundColor: "#B6BCC320",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: `${colors.primaryBlack}70`,
      fontSize: "13px",
      fontWeight: 600,
      // textTransform: "uppercase",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "#b6bcc330",
      borderRadius: "0px",
    },
  },
  disabled_btn: {
    "&.Mui-disabled": {
      backgroundColor: colors.grey,
      color: "#c0c0c0",
    },
  },
  modal_footer_container: { p: 2, backgroundColor: `${colors.primary}20` },
  filter_field_label: {
    color: `${colors.primaryBlack}80`,
    fontSize: "13px",
    mb: 0.75,
  },
};
